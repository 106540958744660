import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type CoreBaseEntity = AuditEntity & {
  __typename?: 'CoreBaseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};


export type DocumentFolderEntity = AuditEntity & {
  __typename?: 'DocumentFolderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  siteID: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<DocumentsEntity>>;
};

export type DocumentsEntity = AuditEntity & {
  __typename?: 'DocumentsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  mediaType: Scalars['String'];
  refTable: RefTable;
  documentFolderID?: Maybe<Scalars['String']>;
  documentsFolder?: Maybe<DocumentFolderEntity>;
  associateID?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileURL?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  bucketFileName?: Maybe<Scalars['String']>;
  opacity?: Maybe<Scalars['String']>;
  isPrivate: Scalars['Boolean'];
};

export enum RefTable {
  Contract = 'CONTRACT',
  Client = 'CLIENT',
  Subcontract = 'SUBCONTRACT',
  Supplier = 'SUPPLIER',
  NegotiatedSupply = 'NEGOTIATED_SUPPLY',
  Miscexpense = 'MISCEXPENSE'
}

/** JsonType */
export type Address = {
  __typename?: 'Address';
  siteName?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  AddressType?: Maybe<AddressType>;
};

export enum AddressType {
  Primary = 'PRIMARY',
  Delivery = 'DELIVERY',
  Site = 'SITE'
}

/** JsonType */
export type ContactPerson = {
  __typename?: 'ContactPerson';
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type JobTypeEntity = AuditEntity & {
  __typename?: 'JobTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  marketRate: Scalars['String'];
  worker?: Maybe<Array<WorkerEntity>>;
  commonStatus: CommonStatus;
};

export enum CommonStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Block = 'BLOCK'
}

export type ApprovalPolicyEntity = AuditEntity & {
  __typename?: 'ApprovalPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  approvalList?: Maybe<Array<Scalars['JSON']>>;
  category: Scalars['String'];
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  approvalPolicyAssignment?: Maybe<Array<ApprovalPolicyAssignmentEntity>>;
  latestLimit: Scalars['JSON'];
};


export type ApprovalPolicyAssignmentEntity = AuditEntity & {
  __typename?: 'ApprovalPolicyAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  approvalPolicyID: Scalars['String'];
  approvalPolicy?: Maybe<ApprovalPolicyEntity>;
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  approvalList?: Maybe<Array<Scalars['JSON']>>;
  category: Scalars['String'];
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  latestLimitAssignment: Scalars['JSON'];
};

export type CoreContractEntity = AuditEntity & {
  __typename?: 'CoreContractEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  taxSchemeID: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  progressRetentionPerc: Scalars['Float'];
  maxRetentionPerc: Scalars['Float'];
  ladAmt?: Maybe<Scalars['Float']>;
  ladRate?: Maybe<RateUomType>;
  targetProfitPerc?: Maybe<Scalars['Float']>;
  cmgdDate?: Maybe<Scalars['DateTime']>;
  cpcDate?: Maybe<Scalars['DateTime']>;
  dlpDate?: Maybe<Scalars['DateTime']>;
  creditTerm?: Maybe<Scalars['Float']>;
  extensionDate?: Maybe<Scalars['JSON']>;
  closureDate?: Maybe<Scalars['DateTime']>;
  closureRemarks?: Maybe<Scalars['String']>;
  completionPerc?: Maybe<Scalars['Float']>;
  contactPerson?: Maybe<Array<Scalars['JSON']>>;
};

export enum RateUomType {
  Hourly = 'HOURLY',
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Lumpsum = 'LUMPSUM'
}

export type CoreVoEntity = AuditEntity & {
  __typename?: 'CoreVOEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef: Scalars['String'];
  rejectedDate?: Maybe<Scalars['DateTime']>;
  submittedDate: Scalars['DateTime'];
  docDate?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  architectNo: Scalars['String'];
  engineeringNo: Scalars['String'];
  VOType: VoType;
  VOStatus: VoStatus;
  submissionAmt?: Maybe<Scalars['Float']>;
  approvedAmt?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
};

export enum VoType {
  Addition = 'ADDITION',
  Omission = 'OMISSION'
}

export enum VoStatus {
  Active = 'ACTIVE',
  Approved = 'APPROVED',
  Cancel = 'CANCEL',
  Partial = 'PARTIAL',
  Submit = 'SUBMIT',
  Rejected = 'REJECTED',
  Close = 'CLOSE',
  Complete = 'COMPLETE',
  Pending = 'PENDING'
}

export type CoreGuarInsEntity = AuditEntity & {
  __typename?: 'CoreGuarInsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRef: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  issuer: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['DateTime'];
  expiryDate: Scalars['DateTime'];
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['DateTime']>;
  rejectedDate?: Maybe<Scalars['DateTime']>;
};

export type CoreGuaranteeEntity = AuditEntity & {
  __typename?: 'CoreGuaranteeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRef: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  issuer: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['DateTime'];
  expiryDate: Scalars['DateTime'];
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['DateTime']>;
  rejectedDate?: Maybe<Scalars['DateTime']>;
  guaranteeTypeID: Scalars['String'];
  collateralAmt: Scalars['Float'];
  guaranteeStatus: GrntInsStatus;
};

export enum GrntInsStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Submit = 'SUBMIT',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
  Pending = 'PENDING'
}

export type CoreInsuranceEntity = AuditEntity & {
  __typename?: 'CoreInsuranceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRef: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  issuer: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['DateTime'];
  expiryDate: Scalars['DateTime'];
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['DateTime']>;
  rejectedDate?: Maybe<Scalars['DateTime']>;
  insuranceTypeID: Scalars['String'];
  insuranceStatus: GrntInsStatus;
};

export type BankDetails = {
  __typename?: 'BankDetails';
  name?: Maybe<Scalars['String']>;
  acctHolder?: Maybe<Scalars['String']>;
  acctNo?: Maybe<Scalars['String']>;
  branch?: Maybe<Scalars['String']>;
  swiftCode?: Maybe<Scalars['String']>;
};

export type TaxDetails = {
  __typename?: 'TaxDetails';
  taxRefNo?: Maybe<Scalars['String']>;
  taxExpDate?: Maybe<Scalars['DateTime']>;
};

export type ExtensionDate = {
  __typename?: 'ExtensionDate';
  remarks?: Maybe<Scalars['String']>;
  cncDate?: Maybe<Scalars['DateTime']>;
  extensionDate?: Maybe<Scalars['DateTime']>;
  eDocument?: Maybe<Scalars['String']>;
};

export type CidbDetails = {
  __typename?: 'CidbDetails';
  registrationNo?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
};

export type PkkDetails = {
  __typename?: 'PkkDetails';
  registrationNo?: Maybe<Scalars['String']>;
  class?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
};

export type BudgetAmt = {
  __typename?: 'BudgetAmt';
  budgetAmt?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdT?: Maybe<Scalars['DateTime']>;
  modTs?: Maybe<Scalars['DateTime']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};


export type TaxEffective = {
  __typename?: 'TaxEffective';
  date?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
};

export type ExchangeLog = {
  __typename?: 'ExchangeLog';
  exchangeRate?: Maybe<Scalars['Float']>;
  modTs?: Maybe<Scalars['DateTime']>;
  modBy?: Maybe<Scalars['DateTime']>;
};

export type ActivityLog = {
  __typename?: 'ActivityLog';
  remarks?: Maybe<Scalars['String']>;
  logDate?: Maybe<Scalars['DateTime']>;
  defectStatus?: Maybe<DefectStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export enum DefectStatus {
  Open = 'OPEN',
  Closed = 'CLOSED',
  Cancelled = 'CANCELLED',
  InProgress = 'IN_PROGRESS',
  Resolved = 'RESOLVED',
  Assigned = 'ASSIGNED',
  Reassigned = 'REASSIGNED',
  SelfAssigned = 'SELF_ASSIGNED'
}

export type RoleUser = {
  __typename?: 'roleUser';
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type DoGrnMatch = {
  __typename?: 'DoGrnMatch';
  ID?: Maybe<Scalars['String']>;
  doID?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  doAmt?: Maybe<Scalars['Float']>;
  doDate?: Maybe<Scalars['DateTime']>;
};

export type DefectLog = {
  __typename?: 'DefectLog';
  costCategoryID?: Maybe<Scalars['Float']>;
  contractID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdT?: Maybe<Scalars['DateTime']>;
  defectStatus?: Maybe<DefectStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type HomeCalendarOutput = {
  __typename?: 'HomeCalendarOutput';
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  contractName?: Maybe<Scalars['String']>;
  contractNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  contractDate?: Maybe<Scalars['DateTime']>;
  cpcDate?: Maybe<Scalars['DateTime']>;
  cmgdDate?: Maybe<Scalars['DateTime']>;
  dlpDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  voStartDate?: Maybe<Scalars['DateTime']>;
  voEndDate?: Maybe<Scalars['DateTime']>;
  publicHolidayOffDayDate?: Maybe<Scalars['DateTime']>;
};

export type MonthPeriod = {
  __typename?: 'MonthPeriod';
  month?: Maybe<Scalars['DateTime']>;
  outstandingAmt?: Maybe<Scalars['Float']>;
  dueAmt?: Maybe<Scalars['Float']>;
};

export type PnmCostCat = {
  __typename?: 'PNMCostCat';
  quantity?: Maybe<Scalars['Boolean']>;
  roadTax?: Maybe<Scalars['Boolean']>;
  warranty?: Maybe<Scalars['Boolean']>;
  insurance?: Maybe<Scalars['Boolean']>;
};

export type TrackingLog = {
  __typename?: 'TrackingLog';
  cost?: Maybe<Scalars['Float']>;
  returnQty?: Maybe<Scalars['Float']>;
  returnDate?: Maybe<Scalars['DateTime']>;
  returnRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['DateTime']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  trackingLogStatus?: Maybe<Scalars['String']>;
};

export type ApprovalLog = {
  __typename?: 'ApprovalLog';
  approvalID?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['DateTime']>;
  remarks?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};

export enum TransactionStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
  Retention = 'RETENTION',
  Pending = 'PENDING',
  Pendingclose = 'PENDINGCLOSE'
}

export type NationalityTypes = {
  __typename?: 'NationalityTypes';
  nationality: Nationality;
};

export enum Nationality {
  Afghan = 'Afghan',
  Albanian = 'Albanian',
  Algerian = 'Algerian',
  American = 'American',
  Argentine = 'Argentine',
  Argentinian = 'Argentinian',
  Australian = 'Australian',
  Austrian = 'Austrian',
  Bangladeshi = 'Bangladeshi',
  Batswana = 'Batswana',
  Belgian = 'Belgian',
  Bolivian = 'Bolivian',
  Brazilian = 'Brazilian',
  British = 'British',
  Bulgarian = 'Bulgarian',
  Burmese = 'Burmese',
  Cambodian = 'Cambodian',
  Cameroonian = 'Cameroonian',
  Canadian = 'Canadian',
  Chilean = 'Chilean',
  Chinese = 'Chinese',
  Colombian = 'Colombian',
  CostaRican = 'Costa_Rican',
  Croatian = 'Croatian',
  Cuban = 'Cuban',
  Czech = 'Czech',
  Danish = 'Danish',
  Dominican = 'Dominican',
  Dutch = 'Dutch',
  Ecuadorian = 'Ecuadorian',
  Egyptian = 'Egyptian',
  Emirati = 'Emirati',
  English = 'English',
  Estonian = 'Estonian',
  Ethiopian = 'Ethiopian',
  Fijian = 'Fijian',
  Finnish = 'Finnish',
  French = 'French',
  German = 'German',
  Ghanaian = 'Ghanaian',
  Greek = 'Greek',
  Guatemalan = 'Guatemalan',
  Haitian = 'Haitian',
  Honduran = 'Honduran',
  Hungarian = 'Hungarian',
  Icelandic = 'Icelandic',
  Indian = 'Indian',
  Indonesian = 'Indonesian',
  Iranian = 'Iranian',
  Iraqi = 'Iraqi',
  Irish = 'Irish',
  Israeli = 'Israeli',
  Italian = 'Italian',
  Jamaican = 'Jamaican',
  Japanese = 'Japanese',
  Jordanian = 'Jordanian',
  Kenyan = 'Kenyan',
  Korean = 'Korean',
  Kuwaiti = 'Kuwaiti',
  Lao = 'Lao',
  Latvian = 'Latvian',
  Lebanese = 'Lebanese',
  Libyan = 'Libyan',
  Lithuanian = 'Lithuanian',
  Malagasy = 'Malagasy',
  Malaysian = 'Malaysian',
  Malian = 'Malian',
  Maltese = 'Maltese',
  Mexican = 'Mexican',
  Mongolian = 'Mongolian',
  Moroccan = 'Moroccan',
  Mozambican = 'Mozambican',
  Namibian = 'Namibian',
  Nepalese = 'Nepalese',
  NewZealand = 'New_Zealand',
  Nicaraguan = 'Nicaraguan',
  Nigerian = 'Nigerian',
  Norwegian = 'Norwegian',
  Pakistani = 'Pakistani',
  Panamanian = 'Panamanian',
  Paraguayan = 'Paraguayan',
  Peruvian = 'Peruvian',
  Philippine = 'Philippine',
  Polish = 'Polish',
  Portuguese = 'Portuguese',
  Romanian = 'Romanian',
  Russian = 'Russian',
  Salvadorian = 'Salvadorian',
  Saudi = 'Saudi',
  Scottish = 'Scottish',
  Senegalese = 'Senegalese',
  Serbian = 'Serbian',
  Singaporean = 'Singaporean',
  Slovak = 'Slovak',
  SouthAfrican = 'South_African',
  Spanish = 'Spanish',
  SriLankan = 'Sri_Lankan',
  Sudanese = 'Sudanese',
  Swedish = 'Swedish',
  Swiss = 'Swiss',
  Syrian = 'Syrian',
  Taiwanese = 'Taiwanese',
  Tajikistani = 'Tajikistani',
  Thai = 'Thai',
  Tongan = 'Tongan',
  Tunisian = 'Tunisian',
  Turkish = 'Turkish',
  Ukrainian = 'Ukrainian',
  Uruguayan = 'Uruguayan',
  Venezuelan = 'Venezuelan',
  Vietnamese = 'Vietnamese',
  Welsh = 'Welsh',
  Zambian = 'Zambian',
  Zimbabwean = 'Zimbabwean'
}

export type RelationshipTypes = {
  __typename?: 'RelationshipTypes';
  relationship: Relationship;
};

export enum Relationship {
  Supervisor = 'Supervisor',
  Spouse = 'Spouse',
  Family = 'Family',
  Friends = 'Friends'
}

export type HolidayTypeRate = {
  __typename?: 'HolidayTypeRate';
  holidayTypeID: Scalars['String'];
  rate: Scalars['Float'];
};

export type WorkerHour = {
  __typename?: 'WorkerHour';
  ID: Scalars['String'];
  workerID?: Maybe<Scalars['String']>;
  verifiedHour: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
};

export type WorkerWageCycle = {
  __typename?: 'WorkerWageCycle';
  workerID: Scalars['String'];
  totalWage: Scalars['Float'];
};

export type Weekend = {
  __typename?: 'Weekend';
  monday?: Maybe<Scalars['Boolean']>;
  tuesday?: Maybe<Scalars['Boolean']>;
  wednesday?: Maybe<Scalars['Boolean']>;
  thursday?: Maybe<Scalars['Boolean']>;
  friday?: Maybe<Scalars['Boolean']>;
  saturday?: Maybe<Scalars['Boolean']>;
  sunday?: Maybe<Scalars['Boolean']>;
};

export type ApprovalList = {
  __typename?: 'ApprovalList';
  stepNo?: Maybe<Scalars['Float']>;
  roleID?: Maybe<Scalars['String']>;
  predicate?: Maybe<Scalars['String']>;
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
};

export type ReminderDetail = {
  __typename?: 'ReminderDetail';
  reminderSeq?: Maybe<Scalars['Float']>;
  numOfReminder?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

export type CoreAssociateEntity = AuditEntity & {
  __typename?: 'CoreAssociateEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxTypeID?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description: Scalars['String'];
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  taxDetails?: Maybe<Scalars['JSON']>;
  address: Scalars['JSON'];
  platformAccess: Scalars['Boolean'];
  associatedStatus: AssociatedStatus;
};

export enum AssociatedStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Blacklisted = 'BLACKLISTED'
}

export type CoreAllocationEntity = AuditEntity & {
  __typename?: 'CoreAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  debitRefTable?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
  creditRefTable?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
  allocationAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
};

export type CoreAdjItemEntity = AuditEntity & {
  __typename?: 'CoreAdjItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  costItemID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  docAmt: Scalars['Float'];
  taxAmt: Scalars['Float'];
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
};

export type DocRef = AuditEntity & {
  __typename?: 'DocRef';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
};

export type Forex = AuditEntity & {
  __typename?: 'Forex';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
};

export type CoreTransactionEntity = AuditEntity & {
  __typename?: 'CoreTransactionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
};

export type CoreReceiptPaymentEntity = AuditEntity & {
  __typename?: 'CoreReceiptPaymentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
};

export enum ReceiptStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
  Pending = 'PENDING',
  Pendingclose = 'PENDINGCLOSE'
}

export type CoreClaimEntity = AuditEntity & {
  __typename?: 'CoreClaimEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  claimDocNo?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  claimAmt?: Maybe<Scalars['Float']>;
  sequenceNo?: Maybe<Scalars['Float']>;
  revisedNo?: Maybe<Scalars['Float']>;
  submittedDate: Scalars['String'];
  voDisplayAmt?: Maybe<Scalars['Float']>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  previousMosAmt?: Maybe<Scalars['Float']>;
  submittedMosAmt?: Maybe<Scalars['Float']>;
  currentMosAmt?: Maybe<Scalars['Float']>;
  submittedRetentionAmt?: Maybe<Scalars['Float']>;
  retentionAmt?: Maybe<Scalars['Float']>;
  balanceRetentionAmt?: Maybe<Scalars['Float']>;
  submittedDayWorkAmt?: Maybe<Scalars['Float']>;
  currentDayWorkAmt?: Maybe<Scalars['Float']>;
  balanceAdvanceAmt?: Maybe<Scalars['Float']>;
  currentAdvRecoupmentAmt?: Maybe<Scalars['Float']>;
  currentDeductionAmt?: Maybe<Scalars['Float']>;
  submittedDeductionAmt?: Maybe<Scalars['Float']>;
  balanceDeductionAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  rejectedDate?: Maybe<Scalars['String']>;
  isRetention: Scalars['Boolean'];
};

export type ProjectBudgetEntity = AuditEntity & {
  __typename?: 'ProjectBudgetEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  costCategoryID: Scalars['String'];
  budgetAmt?: Maybe<Array<Scalars['JSON']>>;
  contract: ContractEntity;
  costCateg: CostCategoryEntity;
};

export type MiscExpenseEntity = AuditEntity & {
  __typename?: 'MiscExpenseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  status: MiscExpenseStatus;
  accountID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
  costCategoryID: Scalars['String'];
  docRef: Scalars['String'];
  baseAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  docDate: Scalars['DateTime'];
  transactionDate?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  contract?: Maybe<ContractEntity>;
  costCategory?: Maybe<CostCategoryEntity>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
};

export enum MiscExpenseStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED'
}

export type PnmRentalEntity = AuditEntity & {
  __typename?: 'PNMRentalEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  costCategoryID: Scalars['String'];
  supplierID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  regNo?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  cost?: Maybe<Scalars['Float']>;
  rentalRate: RateUomType;
  returnDate?: Maybe<Scalars['DateTime']>;
  returnRemarks?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  plantMachineryStatus: PlantMachineryStatus;
  contract?: Maybe<ContractEntity>;
  costCategory?: Maybe<CostCategoryEntity>;
  supplier?: Maybe<SupplierEntity>;
};

export enum PlantMachineryStatus {
  Active = 'ACTIVE',
  Assigned = 'ASSIGNED',
  PartiallyAssigned = 'PARTIALLY_ASSIGNED',
  Maintenance = 'MAINTENANCE',
  OutOfOrder = 'OUT_OF_ORDER',
  Returned = 'RETURNED'
}

export type ContractAllocationEntity = AuditEntity & {
  __typename?: 'ContractAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  debitRefTable?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
  creditRefTable?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
  allocationAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  allocationStatus: AllocationStatus;
  contractCN: Array<ContractCnEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
};

export enum AllocationStatus {
  Credit = 'CREDIT',
  Retention = 'RETENTION',
  Pending = 'PENDING'
}

export type ContractRetentionAllocationEntity = AuditEntity & {
  __typename?: 'ContractRetentionAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  debitRefTable?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
  creditRefTable?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
  allocationAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  allocationStatus: AllocationStatus;
};

export type ClientContractAccReceipt = AuditEntity & {
  __typename?: 'ClientContractAccReceipt';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
};

export type ContractReceiptEntity = AuditEntity & {
  __typename?: 'ContractReceiptEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  contractAllocation: Array<ContractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  bankAccount?: Maybe<BankAccountEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};

export type ContractAdvanceEntity = AuditEntity & {
  __typename?: 'ContractAdvanceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  isRecoupment: Scalars['Boolean'];
  contractAllocation: Array<ContractAllocationEntity>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  bankAccount?: Maybe<BankAccountEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};

export type ContractRefundEntity = AuditEntity & {
  __typename?: 'ContractRefundEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  contractAllocation: Array<ContractAllocationEntity>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  bankAccount?: Maybe<BankAccountEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};

export type SubcontractAllocationEntity = AuditEntity & {
  __typename?: 'SubcontractAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  debitRefTable?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
  creditRefTable?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
  allocationAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  allocationStatus: AllocationStatus;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
};

export type SubcontractRetentionAllocationEntity = AuditEntity & {
  __typename?: 'SubcontractRetentionAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  debitRefTable?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
  creditRefTable?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
  allocationAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  allocationStatus: AllocationStatus;
};

export type GlInterfaceDetailEntity = AuditEntity & {
  __typename?: 'GLInterfaceDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  glInterfaceMasterID: Scalars['String'];
  glInterfaceMaster?: Maybe<GlInterfaceMasterEntity>;
  debitAccountCode1: Scalars['String'];
  debitAccountCode2?: Maybe<Scalars['String']>;
  creditAccountCode1: Scalars['String'];
  creditAccountCode2?: Maybe<Scalars['String']>;
};

export type GlInterfaceMasterEntity = AuditEntity & {
  __typename?: 'GLInterfaceMasterEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  glAssociationID: Scalars['String'];
  glTransactionID: Scalars['String'];
  index?: Maybe<Scalars['Float']>;
  debitAccountCode1: Scalars['String'];
  debitAccountCode2?: Maybe<Scalars['String']>;
  creditAccountCode1: Scalars['String'];
  creditAccountCode2?: Maybe<Scalars['String']>;
  glInterfaceDetail?: Maybe<GlInterfaceDetailEntity>;
  glAssociation?: Maybe<GlAssociationEntity>;
  glTransaction?: Maybe<GlTransactionEntity>;
};

export type GlAssociationEntity = AuditEntity & {
  __typename?: 'GLAssociationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  commonStatus: CommonStatus;
  glInterfaceMaster?: Maybe<Array<GlInterfaceMasterEntity>>;
};

export type GlTransactionEntity = AuditEntity & {
  __typename?: 'GLTransactionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  commonStatus: CommonStatus;
  glInterfaceMaster?: Maybe<Array<GlInterfaceMasterEntity>>;
};

export type GlTaxDynamicEntity = AuditEntity & {
  __typename?: 'GLTaxDynamicEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxTypeID: Scalars['String'];
  accountCode: Scalars['String'];
  commonStatus: CommonStatus;
  taxType?: Maybe<ConTaxTypeEntity>;
};

export type GlAdjustmentDynamicEntity = AuditEntity & {
  __typename?: 'GLAdjustmentDynamicEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  adjustmentTypeID: Scalars['String'];
  accountCode: Scalars['String'];
  commonStatus: CommonStatus;
  adjustmentType?: Maybe<AdjustmentTypeEntity>;
};

export type Tax = {
  __typename?: 'Tax';
  taxRate?: Maybe<Scalars['Float']>;
  taxDate?: Maybe<Scalars['DateTime']>;
};

export type GoodReturnNoteEntity = AuditEntity & {
  __typename?: 'GoodReturnNoteEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  supplierID: Scalars['String'];
  siteID: Scalars['String'];
  contractID: Scalars['String'];
  docDate: Scalars['String'];
  docNo: Scalars['String'];
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  grtnStatus: PurchaseStatus;
  rejectionDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  supplier?: Maybe<SupplierEntity>;
  contract?: Maybe<ContractEntity>;
  site?: Maybe<SiteEntity>;
  goodReturnNoteItem?: Maybe<Array<GoodReturnNoteItemEntity>>;
};

export enum PurchaseStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Cancelled = 'CANCELLED',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Closed = 'CLOSED',
  Pending = 'PENDING',
  Pendingclose = 'PENDINGCLOSE'
}

export type GoodReturnNoteItemEntity = AuditEntity & {
  __typename?: 'GoodReturnNoteItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  grtnID: Scalars['String'];
  doItemID: Scalars['String'];
  returnedQty: Scalars['Float'];
  replacement: Scalars['Boolean'];
  goodReturnNote?: Maybe<GoodReturnNoteEntity>;
  doItem?: Maybe<DoItemEntity>;
};

export type PurchaseOrderEntity = AuditEntity & {
  __typename?: 'PurchaseOrderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  siteID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  supplierID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  contactPerson?: Maybe<ContactPerson>;
  exchangeRate: Scalars['Float'];
  totalAmt: Scalars['Float'];
  taxAmt?: Maybe<Scalars['Float']>;
  markupAmt?: Maybe<Scalars['Float']>;
  docDate?: Maybe<Scalars['DateTime']>;
  creditTerm: Scalars['Float'];
  instructions?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['DateTime']>;
  remarks?: Maybe<Scalars['String']>;
  cancelCloseDate?: Maybe<Scalars['String']>;
  cancelCloseRemark?: Maybe<Scalars['String']>;
  deliveryAddress?: Maybe<SiteEntity>;
  purchaseType: PurchaseType;
  purchaseStatus: PurchaseStatus;
  posConnection?: Maybe<Array<DoItemEntity>>;
  poItem?: Maybe<Array<PoItemEntity>>;
  subcontract?: Maybe<SubcontractEntity>;
  supplier?: Maybe<SupplierEntity>;
  toDo: Array<ContractToDoEntity>;
};

export enum PurchaseType {
  Main = 'MAIN',
  Sub = 'SUB'
}

export type PoBudgetExceptionEntity = AuditEntity & {
  __typename?: 'POBudgetExceptionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  remarks: Scalars['String'];
};

export type DoEntity = AuditEntity & {
  __typename?: 'DOEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  supplierID: Scalars['String'];
  siteID?: Maybe<Scalars['String']>;
  deliveryAddress?: Maybe<Scalars['JSON']>;
  docDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  doNo: Scalars['String'];
  contactPerson: Scalars['JSON'];
  glExportID?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  DODetails?: Maybe<Scalars['JSON']>;
  doItem?: Maybe<Array<DoItemEntity>>;
  poItemConnection: Array<DoItemEntity>;
  supplier?: Maybe<SupplierEntity>;
  site?: Maybe<SiteEntity>;
  contract?: Maybe<ContractEntity>;
  doStatus: DoStatus;
  POs: Array<PurchaseOrderEntity>;
};

export enum DoStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Rejected = 'REJECTED',
  Approved = 'APPROVED',
  Closed = 'CLOSED',
  Cancelled = 'CANCELLED',
  Pendingclose = 'PENDINGCLOSE'
}

export type DoItemEntity = AuditEntity & {
  __typename?: 'DOItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  deliveryOrderID: Scalars['String'];
  purchaseOrderID: Scalars['String'];
  poItemID: Scalars['String'];
  acceptedQty: Scalars['Float'];
  /** CustomFieldResolver */
  doItemAmt?: Maybe<Scalars['Float']>;
  DO?: Maybe<DoEntity>;
  POs?: Maybe<PurchaseOrderEntity>;
  poItem?: Maybe<PoItemEntity>;
  apInvoiceItem?: Maybe<Array<ApInvoiceItemEntity>>;
  goodReturnNoteItem?: Maybe<Array<GoodReturnNoteItemEntity>>;
  /** CustomFieldResolver */
  doItemSubmitted?: Maybe<Scalars['JSON']>;
};

export type NegotiatedSupplyEntity = AuditEntity & {
  __typename?: 'NegotiatedSupplyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  supplierID: Scalars['String'];
  contractID: Scalars['String'];
  docNo: Scalars['String'];
  negoSupplyDate?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  cancellationDate?: Maybe<Scalars['DateTime']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  status: PurchaseStatus;
  supplier?: Maybe<SupplierEntity>;
  negoSupplyItem?: Maybe<Array<NegotiatedSupplyItemEntity>>;
};

export type NegotiatedSupplyItemEntity = AuditEntity & {
  __typename?: 'NegotiatedSupplyItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  negotiatedSupplyID: Scalars['String'];
  costItemID: Scalars['String'];
  uomID: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  balanceQty?: Maybe<Scalars['Float']>;
  unitPrice: Scalars['Float'];
  costItem?: Maybe<CostItemEntity>;
  poItem?: Maybe<Array<PoItemEntity>>;
  UOM?: Maybe<UomEntity>;
  negoSupply?: Maybe<NegotiatedSupplyEntity>;
};

export type RfqSupplierInvitationEntity = AuditEntity & {
  __typename?: 'RFQSupplierInvitationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  supplierID: Scalars['String'];
  supplier: SupplierEntity;
  rfqID: Scalars['String'];
  rfq?: Maybe<RfqEntity>;
};

export type RfqSupplierSubmissionEntity = AuditEntity & {
  __typename?: 'RFQSupplierSubmissionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  rfqID: Scalars['String'];
  rfq?: Maybe<RfqEntity>;
  supplierID: Scalars['String'];
  supplier: SupplierEntity;
  quotationNo: Scalars['String'];
  docAmt: Scalars['Float'];
  creditTerm: Scalars['Float'];
  submittedDate: Scalars['String'];
  effectiveDate: Scalars['String'];
  expiryDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  quotationStatus: QuotationStatus;
  rfqSubmissionItem: Array<RfqSubmissionItemEntity>;
};

export enum QuotationStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Inactive = 'INACTIVE'
}

export type RfqSubmissionItemEntity = AuditEntity & {
  __typename?: 'RFQSubmissionItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  rfqSupplierSubmissionID: Scalars['String'];
  rfqSubmission: RfqSupplierSubmissionEntity;
  rfqItemID: Scalars['String'];
  rfqItem?: Maybe<RfqItemEntity>;
  unitPrice: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  cheapest?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  selected?: Maybe<Scalars['JSON']>;
};


export type RfqSubmissionItemEntityCheapestArgs = {
  rfqID?: Maybe<Scalars['String']>;
};

export type RfqEntity = AuditEntity & {
  __typename?: 'RFQEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  purchaseReqID: Scalars['String'];
  purchaseReq?: Maybe<PurchaseReqEntity>;
  description: Scalars['String'];
  docNo: Scalars['String'];
  docDate: Scalars['String'];
  expectedDate: Scalars['String'];
  rejectedDate?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  cancelCloseDate?: Maybe<Scalars['String']>;
  cancelCloseRemark?: Maybe<Scalars['String']>;
  rfqStatus: PurchaseStatus;
  rfqItem?: Maybe<Array<RfqItemEntity>>;
  invitedSupplier?: Maybe<Array<RfqSupplierInvitationEntity>>;
  rfqSupplierSubmission?: Maybe<Array<RfqSupplierSubmissionEntity>>;
  supplierRFQInvite: Scalars['JSON'];
  supplier: Array<SupplierEntity>;
  /** CustomFieldResolver */
  submittedSupplier?: Maybe<Scalars['Float']>;
};


export type RfqEntitySupplierRfqInviteArgs = {
  supplierID?: Maybe<Scalars['String']>;
};

export type RfqItemEntity = AuditEntity & {
  __typename?: 'RFQItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  rfqID: Scalars['String'];
  prItemID: Scalars['String'];
  uomID: Scalars['String'];
  quantity: Scalars['Float'];
  rfq?: Maybe<RfqEntity>;
  uom?: Maybe<UomEntity>;
  prItem?: Maybe<PrItemEntity>;
  rfqSubmissionItem?: Maybe<Array<RfqSubmissionItemEntity>>;
  poItem?: Maybe<Array<PoItemEntity>>;
};

export type RfqItemSubmissionSelectedEntity = AuditEntity & {
  __typename?: 'RFQItemSubmissionSelectedEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  rfqItemID: Scalars['String'];
  rfqSubmissionItemID: Scalars['String'];
};

export type PurchaseReqEntity = AuditEntity & {
  __typename?: 'PurchaseReqEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  purchaseType: PurchaseType;
  submittedDate?: Maybe<Scalars['DateTime']>;
  prDate?: Maybe<Scalars['DateTime']>;
  docDate?: Maybe<Scalars['String']>;
  expectedDate: Scalars['DateTime'];
  rejectionDate?: Maybe<Scalars['DateTime']>;
  cancelCloseDate?: Maybe<Scalars['String']>;
  cancelCloseRemark?: Maybe<Scalars['String']>;
  requestedBy: Scalars['String'];
  contactPerson: Scalars['JSON'];
  instructions: Scalars['String'];
  purchaseStatus: PurchaseStatus;
  deliveryAddress?: Maybe<SiteEntity>;
  prItem?: Maybe<Array<PrItemEntity>>;
  rfq?: Maybe<Array<RfqEntity>>;
  contract?: Maybe<ContractEntity>;
  subcontract?: Maybe<SubcontractEntity>;
  toDo: Array<ContractToDoEntity>;
  purchaseRFQItem: Array<PrItemEntity>;
};

export type PrItemEntity = AuditEntity & {
  __typename?: 'PRItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  purchaseReqID: Scalars['String'];
  costItemID: Scalars['String'];
  uomID: Scalars['String'];
  markupPerc?: Maybe<Scalars['Float']>;
  requestedQty: Scalars['Float'];
  outstandingQty: Scalars['Float'];
  negotiatedItemStatus: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  purchaseReq?: Maybe<PurchaseReqEntity>;
  costItem?: Maybe<CostItemEntity>;
  uom?: Maybe<UomEntity>;
  poItem?: Maybe<Array<PoItemEntity>>;
  rfqItem?: Maybe<Array<RfqItemEntity>>;
};

export type PoItemEntity = AuditEntity & {
  __typename?: 'POItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  purchaseOrderID: Scalars['String'];
  prItemID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  costItemID?: Maybe<Scalars['String']>;
  negotiatedSupplyItemID?: Maybe<Scalars['String']>;
  rfqItemID?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  orderedQty: Scalars['Float'];
  outstandingQty?: Maybe<Scalars['Float']>;
  unitPrice: Scalars['Float'];
  taxAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  markupAmt?: Maybe<Scalars['Float']>;
  totalAmt?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  rfqItem?: Maybe<RfqItemEntity>;
  costItem?: Maybe<CostItemEntity>;
  purchaseOrder?: Maybe<PurchaseOrderEntity>;
  nego?: Maybe<NegotiatedSupplyItemEntity>;
  uom?: Maybe<UomEntity>;
  prItem?: Maybe<PrItemEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  doItem?: Maybe<Array<DoItemEntity>>;
  /** CustomFieldResolver */
  poItemVariance?: Maybe<Scalars['JSON']>;
};

export type StockMovementEntity = AuditEntity & {
  __typename?: 'StockMovementEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  doID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  submittedDate?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  uomID: Scalars['String'];
  baseUomID?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  rejectionRemark?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  stockMovementStatus: StockMovementStatus;
  stockMovementType: StockMovementType;
  siteMaterial?: Maybe<SiteMaterialEntity>;
  costItem?: Maybe<CostItemEntity>;
  uom?: Maybe<UomEntity>;
};

export enum StockMovementStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Approved = 'APPROVED'
}

export enum StockMovementType {
  Received = 'RECEIVED',
  Issue = 'ISSUE',
  TransferIn = 'TRANSFER_IN',
  TransferOut = 'TRANSFER_OUT',
  Adjustment = 'ADJUSTMENT'
}

export type SiteMaterialEntity = AuditEntity & {
  __typename?: 'SiteMaterialEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  uomID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  stockCode?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  uom?: Maybe<UomEntity>;
  stockMovement?: Maybe<Array<StockMovementEntity>>;
  grnItem?: Maybe<Array<GrnItemEntity>>;
};

export type GrnEntity = AuditEntity & {
  __typename?: 'GRNEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  supplier: Scalars['String'];
  siteID: Scalars['String'];
  doID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  docNo: Scalars['String'];
  docRef: Scalars['String'];
  cancelRejectDate?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  contactPerson?: Maybe<Scalars['JSON']>;
  grnStatus: DoStatus;
  site?: Maybe<SiteEntity>;
  grnItem?: Maybe<Array<GrnItemEntity>>;
};

export type GrnItemEntity = AuditEntity & {
  __typename?: 'GRNItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  grnID: Scalars['String'];
  costItemID?: Maybe<Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
  uomID: Scalars['String'];
  quantity: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  grnAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  grn?: Maybe<GrnEntity>;
  siteMaterial?: Maybe<SiteMaterialEntity>;
  uom?: Maybe<UomEntity>;
  costItem?: Maybe<CostItemEntity>;
};

export type SiteStockLedgerEntity = AuditEntity & {
  __typename?: 'SiteStockLedgerEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  costItemID: Scalars['String'];
  uomID: Scalars['String'];
  quantity: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  trackingDate: Scalars['DateTime'];
  trxType: TrxType;
  costItem?: Maybe<CostItemEntity>;
  uom?: Maybe<UomEntity>;
  site?: Maybe<SiteEntity>;
};

export enum TrxType {
  Grn = 'GRN',
  IssueOut = 'ISSUE_OUT',
  TransferOut = 'TRANSFER_OUT',
  TransferIn = 'TRANSFER_IN',
  Wastage = 'WASTAGE'
}

export type UomEntity = AuditEntity & {
  __typename?: 'UOMEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  commonStatus: CommonStatus;
  poItem?: Maybe<Array<PoItemEntity>>;
  costItem?: Maybe<Array<CostItemEntity>>;
  prItem?: Maybe<Array<PrItemEntity>>;
  rfqItem?: Maybe<Array<RfqItemEntity>>;
  negoItem?: Maybe<Array<NegotiatedSupplyItemEntity>>;
  siteStockLedger?: Maybe<Array<SiteStockLedgerEntity>>;
  siteMaterial?: Maybe<Array<SiteMaterialEntity>>;
  stockMovement?: Maybe<Array<StockMovementEntity>>;
  grnItem?: Maybe<Array<GrnItemEntity>>;
};

export type PricingSupplierEntity = AuditEntity & {
  __typename?: 'PricingSupplierEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  costItemID: Scalars['String'];
  supplierID: Scalars['String'];
  price: Scalars['Float'];
  supplier?: Maybe<SupplierEntity>;
  costItem: CostItemEntity;
};

export type CostItemEntity = AuditEntity & {
  __typename?: 'CostItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  costCategoryID: Scalars['String'];
  uomID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  variancePerc?: Maybe<Scalars['Float']>;
  markupPerc?: Maybe<Scalars['Float']>;
  onBehalf: Scalars['Boolean'];
  commonStatus: CommonStatus;
  poItem?: Maybe<Array<PoItemEntity>>;
  UOM?: Maybe<UomEntity>;
  costCategory?: Maybe<CostCategoryEntity>;
  prItem?: Maybe<Array<PrItemEntity>>;
  apInvoiceItem?: Maybe<Array<ApInvoiceItemEntity>>;
  grnItem?: Maybe<Array<GrnItemEntity>>;
  negotiatedSupplyItem?: Maybe<Array<NegotiatedSupplyItemEntity>>;
  siteStockLedger?: Maybe<Array<SiteStockLedgerEntity>>;
  supplierConnection: Array<PricingSupplierEntity>;
  stockMovement?: Maybe<Array<StockMovementEntity>>;
  /** CustomFieldResolver */
  latestTax?: Maybe<Tax>;
  /** CustomFieldResolver */
  latestPurchase?: Maybe<Scalars['JSON']>;
};

export type ApAllocationEntity = AuditEntity & {
  __typename?: 'APAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  debitRefTable?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
  creditRefTable?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
  allocationAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  allocationStatus: AllocationStatus;
};

export type SupplierAccTransaction = AuditEntity & {
  __typename?: 'SupplierAccTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  supplierID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
};

export type SupplierAdjustmentTransaction = AuditEntity & {
  __typename?: 'SupplierAdjustmentTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  supplierID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  deductionTypeID?: Maybe<Scalars['String']>;
};

export type ApInvoiceEntity = AuditEntity & {
  __typename?: 'APInvoiceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  supplierID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  discountAmt: Scalars['Float'];
  otherChargeAmt: Scalars['Float'];
  /** CustomFieldResolver */
  invoiceAmt?: Maybe<Scalars['Float']>;
  grnMatch?: Maybe<Array<Scalars['JSON']>>;
  supplierAllocation: Array<ApAllocationEntity>;
  APInvoiceItem?: Maybe<Array<ApInvoiceItemEntity>>;
  supplier?: Maybe<SupplierEntity>;
  contract?: Maybe<ContractEntity>;
  subcontract?: Maybe<SubcontractEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  invoiceDo?: Maybe<Array<DoEntity>>;
  /** CustomFieldResolver */
  outstanding?: Maybe<Scalars['JSON']>;
  toDo: Array<ContractToDoEntity>;
};

export type ApInvoiceItemEntity = AuditEntity & {
  __typename?: 'APInvoiceItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  APInvoiceID: Scalars['String'];
  doItemID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  APInvoice?: Maybe<ApInvoiceEntity>;
  doItem?: Maybe<DoItemEntity>;
  costItem?: Maybe<CostItemEntity>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
};

export type ApDebitNoteEntity = AuditEntity & {
  __typename?: 'APDebitNoteEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  supplierID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  deductionTypeID?: Maybe<Scalars['String']>;
  supplierAllocation: Array<ApAllocationEntity>;
  supplier?: Maybe<SupplierEntity>;
  contract?: Maybe<ContractEntity>;
  subcontract?: Maybe<SubcontractEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  APDebitNoteItem?: Maybe<Array<ApDebitNoteItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  toDo: Array<ContractToDoEntity>;
};

export type ApDebitNoteItemEntity = AuditEntity & {
  __typename?: 'APDebitNoteItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  costItemID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  docAmt: Scalars['Float'];
  taxAmt: Scalars['Float'];
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  APDebitNoteID: Scalars['String'];
  APDebitNote?: Maybe<ApDebitNoteEntity>;
};

export type ApCreditNoteEntity = AuditEntity & {
  __typename?: 'APCreditNoteEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  supplierID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  deductionTypeID?: Maybe<Scalars['String']>;
  supplierAllocation: Array<ApAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  supplier?: Maybe<SupplierEntity>;
  contract?: Maybe<ContractEntity>;
  subcontract?: Maybe<SubcontractEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  APCreditNoteItem?: Maybe<Array<ApCreditNoteItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  toDo: Array<ContractToDoEntity>;
};

export type ApCreditNoteItemEntity = AuditEntity & {
  __typename?: 'APCreditNoteItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  costItemID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  docAmt: Scalars['Float'];
  taxAmt: Scalars['Float'];
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  APCreditNoteID: Scalars['String'];
  APCreditNote?: Maybe<ApCreditNoteEntity>;
};

export type DNtoSupplierEntity = AuditEntity & {
  __typename?: 'DNtoSupplierEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  supplierID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  deductionTypeID?: Maybe<Scalars['String']>;
  supplierAllocation: Array<ApAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  supplier?: Maybe<SupplierEntity>;
  contract?: Maybe<ContractEntity>;
  subcontract?: Maybe<SubcontractEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  DNtoSupplierItem?: Maybe<Array<DNtoSupplierItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  toDo: Array<ContractToDoEntity>;
};

export type DNtoSupplierItemEntity = AuditEntity & {
  __typename?: 'DNtoSupplierItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  costItemID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  docAmt: Scalars['Float'];
  taxAmt: Scalars['Float'];
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  DNtoSupplierID: Scalars['String'];
  DNtoSupplier?: Maybe<DNtoSupplierEntity>;
};

export type CNtoSupplierEntity = AuditEntity & {
  __typename?: 'CNtoSupplierEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  supplierID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  deductionTypeID?: Maybe<Scalars['String']>;
  supplierAllocation: Array<ApAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  supplier?: Maybe<SupplierEntity>;
  contract?: Maybe<ContractEntity>;
  subcontract?: Maybe<SubcontractEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  CNtoSupplierItem?: Maybe<Array<CNtoSupplierItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  toDo: Array<ContractToDoEntity>;
};

export type CNtoSupplierItemEntity = AuditEntity & {
  __typename?: 'CNtoSupplierItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  costItemID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  docAmt: Scalars['Float'];
  taxAmt: Scalars['Float'];
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  CNtoSupplierID: Scalars['String'];
  CNtoSupplier?: Maybe<CNtoSupplierEntity>;
};

export type ConTaxTypeEntity = AuditEntity & {
  __typename?: 'ConTaxTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  taxScheme?: Maybe<Array<ConTaxSchemeEntity>>;
  glTaxDynamic?: Maybe<Array<GlTaxDynamicEntity>>;
};

export type ConTaxSchemeEntity = AuditEntity & {
  __typename?: 'ConTaxSchemeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxTypeID: Scalars['String'];
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  taxCategory: TaxCategory;
  taxClass: TaxClass;
  commonStatus: CommonStatus;
  /** CustomFieldResolver */
  latestTax?: Maybe<Tax>;
  taxType?: Maybe<ConTaxTypeEntity>;
  contract?: Maybe<Array<ContractEntity>>;
  subcontract?: Maybe<Array<SubcontractEntity>>;
  taxEffective?: Maybe<Array<ConTaxEffectiveDateEntity>>;
  poItem?: Maybe<Array<PoItemEntity>>;
  apInvoiceItem?: Maybe<Array<ApInvoiceItemEntity>>;
};


export type ConTaxSchemeEntityLatestTaxArgs = {
  docDate?: Maybe<Scalars['String']>;
};

export enum TaxCategory {
  Inclusive = 'INCLUSIVE',
  Exclusive = 'EXCLUSIVE'
}

export enum TaxClass {
  Input = 'INPUT',
  Output = 'OUTPUT'
}

export type ConTaxEffectiveDateEntity = AuditEntity & {
  __typename?: 'ConTaxEffectiveDateEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  date?: Maybe<Scalars['DateTime']>;
  taxRate: Scalars['Float'];
  taxScheme?: Maybe<ConTaxSchemeEntity>;
};

export type SubcontractVoEntity = AuditEntity & {
  __typename?: 'SubcontractVOEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef: Scalars['String'];
  rejectedDate?: Maybe<Scalars['DateTime']>;
  submittedDate: Scalars['DateTime'];
  docDate?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  architectNo: Scalars['String'];
  engineeringNo: Scalars['String'];
  VOType: VoType;
  VOStatus: VoStatus;
  submissionAmt?: Maybe<Scalars['Float']>;
  approvedAmt?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
  subcontract?: Maybe<SubcontractEntity>;
  toDo: Array<ContractToDoEntity>;
};

export type SubcontractorInvitationEntity = AuditEntity & {
  __typename?: 'SubcontractorInvitationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  subcontractorID: Scalars['String'];
  subcontractTenderID: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  subconTender?: Maybe<SubcontractTenderEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  /** CustomFieldResolver */
  RFQInfo: Scalars['JSON'];
};

export type SubconRfqSubmissionEntity = AuditEntity & {
  __typename?: 'SubconRFQSubmissionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  subcontractTenderID: Scalars['String'];
  subcontractTender?: Maybe<SubcontractTenderEntity>;
  subcontractorID: Scalars['String'];
  subcontractor?: Maybe<SubcontractorEntity>;
  submissionDate: Scalars['DateTime'];
  quotationNo: Scalars['String'];
  quotationAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
};

export type SubcontractTenderEntity = AuditEntity & {
  __typename?: 'SubcontractTenderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  siteID: Scalars['String'];
  costCategoryID: Scalars['String'];
  refNo: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  budgetAmt: Scalars['Float'];
  tenderDate: Scalars['String'];
  startDate: Scalars['String'];
  startTime: Scalars['String'];
  closeDate: Scalars['String'];
  endTime: Scalars['String'];
  subconTenderStatus: SubconTenderStatus;
  costCategory: CostCategoryEntity;
  subconInvitation?: Maybe<Array<SubcontractorInvitationEntity>>;
  mainContractDetail?: Maybe<ContractEntity>;
  subconRfqSubmission?: Maybe<Array<SubconRfqSubmissionEntity>>;
  subcontract?: Maybe<Array<SubcontractEntity>>;
};

export enum SubconTenderStatus {
  Active = 'ACTIVE',
  Approved = 'APPROVED',
  Closed = 'CLOSED'
}

export type SubcontractEntity = AuditEntity & {
  __typename?: 'SubcontractEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  taxSchemeID: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  progressRetentionPerc: Scalars['Float'];
  maxRetentionPerc: Scalars['Float'];
  ladAmt?: Maybe<Scalars['Float']>;
  ladRate?: Maybe<RateUomType>;
  targetProfitPerc?: Maybe<Scalars['Float']>;
  cmgdDate?: Maybe<Scalars['DateTime']>;
  cpcDate?: Maybe<Scalars['DateTime']>;
  dlpDate?: Maybe<Scalars['DateTime']>;
  creditTerm?: Maybe<Scalars['Float']>;
  extensionDate?: Maybe<Scalars['JSON']>;
  closureDate?: Maybe<Scalars['DateTime']>;
  closureRemarks?: Maybe<Scalars['String']>;
  completionPerc?: Maybe<Scalars['Float']>;
  contactPerson?: Maybe<Array<Scalars['JSON']>>;
  contractID: Scalars['String'];
  subcontractorID: Scalars['String'];
  costCategoryID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  subcontractTenderID?: Maybe<Scalars['String']>;
  subcontractNo: Scalars['String'];
  subcontractDate: Scalars['DateTime'];
  subcontractSum: Scalars['Float'];
  nominatedSC: Scalars['Boolean'];
  /** CustomFieldResolver */
  maxRetentionSum?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  claimToDate?: Maybe<Scalars['Float']>;
  subcontractStatus: ContractStatus;
  mainContractDetail?: Maybe<ContractEntity>;
  purchaseOrder?: Maybe<Array<PurchaseOrderEntity>>;
  subcontractorDetail?: Maybe<SubcontractorEntity>;
  subcontractTender?: Maybe<SubcontractTenderEntity>;
  tradeDetail: CostCategoryEntity;
  taxDetail?: Maybe<ConTaxSchemeEntity>;
  site?: Maybe<SiteEntity>;
  VO?: Maybe<Array<SubcontractVoEntity>>;
  purchaseReq?: Maybe<Array<PurchaseReqEntity>>;
  subcontractClaim?: Maybe<Array<SubcontractClaimEntity>>;
  subcontractMiscInvoice?: Maybe<Array<SubcontractMiscInvoiceEntity>>;
  subcontractDN?: Maybe<Array<SubcontractDnEntity>>;
  subcontractCN?: Maybe<Array<SubcontractCnEntity>>;
  dnToSubcon?: Maybe<Array<DNtoSubconEntity>>;
  cnToSubcon?: Maybe<Array<CNtoSubconEntity>>;
  payment?: Maybe<Array<SubcontractPaymentEntity>>;
  advance?: Maybe<Array<SubcontractAdvanceEntity>>;
  refund?: Maybe<Array<SubcontractRefundEntity>>;
  /** CustomFieldResolver */
  latestRunNum?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  docNumChecking?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  SubcontractvoSum?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  revisedSubcontractSum?: Maybe<Scalars['Float']>;
};

export enum ContractStatus {
  Active = 'ACTIVE',
  Closed = 'CLOSED',
  Terminated = 'TERMINATED'
}

export type SubcontractorSubconAccPayment = AuditEntity & {
  __typename?: 'SubcontractorSubconAccPayment';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  subcontractorID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
};

export type SubcontractAdvanceEntity = AuditEntity & {
  __typename?: 'SubcontractAdvanceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  subcontractorID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
  isRecoupment: Scalars['Boolean'];
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  subcontractor?: Maybe<SubcontractorEntity>;
  subcontract?: Maybe<SubcontractEntity>;
  bankAccount?: Maybe<BankAccountEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};

export type SubcontractPaymentEntity = AuditEntity & {
  __typename?: 'SubcontractPaymentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  subcontractorID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
  paymentTo: Scalars['String'];
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  subcontract?: Maybe<SubcontractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  bankAccount?: Maybe<BankAccountEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};

export type SubcontractRefundEntity = AuditEntity & {
  __typename?: 'SubcontractRefundEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  subcontractorID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  subcontract?: Maybe<SubcontractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  bankAccount?: Maybe<BankAccountEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};

export type BankAccountEntity = AuditEntity & {
  __typename?: 'BankAccountEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  status: BankAccountStatus;
  accountID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  accountHolder: Scalars['String'];
  accountNo: Scalars['String'];
  branch: Scalars['String'];
  swiftCode: Scalars['String'];
  contactNo: Scalars['String'];
  address: Scalars['JSON'];
  contactPerson: Array<Scalars['JSON']>;
  cancellationDate?: Maybe<Scalars['DateTime']>;
  remarks?: Maybe<Scalars['String']>;
  glAccountCode?: Maybe<Scalars['String']>;
  contractReceipt?: Maybe<Array<ContractReceiptEntity>>;
  contractAdvance?: Maybe<Array<ContractAdvanceEntity>>;
  contractRefund?: Maybe<Array<ContractRefundEntity>>;
  subcontractAdvance?: Maybe<Array<SubcontractAdvanceEntity>>;
  subcontractPayment?: Maybe<Array<SubcontractPaymentEntity>>;
  subcontractRefund?: Maybe<Array<SubcontractRefundEntity>>;
  APAdvance?: Maybe<Array<ApAdvanceEntity>>;
  APPayment?: Maybe<Array<ApPaymentEntity>>;
};

export enum BankAccountStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED'
}

export type SupplierAccPaymentEntity = AuditEntity & {
  __typename?: 'SupplierAccPaymentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  supplierID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
};

export type ApAdvanceEntity = AuditEntity & {
  __typename?: 'APAdvanceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  supplierID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  supplierAllocation: Array<ApAllocationEntity>;
  supplier?: Maybe<SupplierEntity>;
  contract?: Maybe<ContractEntity>;
  subcontract?: Maybe<SubcontractEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  bankAccount?: Maybe<BankAccountEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  toDo: Array<ContractToDoEntity>;
};

export type ApPaymentEntity = AuditEntity & {
  __typename?: 'APPaymentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  supplierID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  supplierAllocation: Array<ApAllocationEntity>;
  supplier?: Maybe<SupplierEntity>;
  contract?: Maybe<ContractEntity>;
  subcontract?: Maybe<SubcontractEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  bankAccount?: Maybe<BankAccountEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  toDo: Array<ContractToDoEntity>;
};

export type ApRefundEntity = AuditEntity & {
  __typename?: 'APRefundEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  supplierID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  supplierAllocation: Array<ApAllocationEntity>;
  supplier?: Maybe<SupplierEntity>;
  contract?: Maybe<ContractEntity>;
  subcontract?: Maybe<SubcontractEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
};

export type SupplierEntity = AuditEntity & {
  __typename?: 'SupplierEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxTypeID?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description: Scalars['String'];
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  taxDetails?: Maybe<Scalars['JSON']>;
  address: Scalars['JSON'];
  platformAccess: Scalars['Boolean'];
  associatedStatus: AssociatedStatus;
  creditorAC?: Maybe<Scalars['String']>;
  bankDetails?: Maybe<Scalars['JSON']>;
  ratingType: RatingType;
  costConnection?: Maybe<Array<SupplierCCategoryEntity>>;
  costItemConnection?: Maybe<Array<PricingSupplierEntity>>;
  purchaseOrder?: Maybe<Array<PurchaseOrderEntity>>;
  SupplierProduct?: Maybe<Array<SupplierCCategoryEntity>>;
  supplierItems?: Maybe<Array<PricingSupplierEntity>>;
  deliveryOrder?: Maybe<Array<DoEntity>>;
  negotiatedSupplyItem?: Maybe<Array<NegotiatedSupplyEntity>>;
  plantMachinery?: Maybe<Array<PlantMachineryEntity>>;
  contactPerson: Array<ContactEntity>;
  rfqSubmission: Array<RfqSupplierSubmissionEntity>;
  invitedRFQ: Array<RfqSupplierInvitationEntity>;
  supplierInv?: Maybe<Array<ApInvoiceEntity>>;
  supplierDN?: Maybe<Array<ApDebitNoteEntity>>;
  supplierCN?: Maybe<Array<ApCreditNoteEntity>>;
  supplierDNS?: Maybe<Array<DNtoSupplierEntity>>;
  cnToSupplier?: Maybe<Array<CNtoSupplierEntity>>;
  supplierAdvance?: Maybe<Array<ApPaymentEntity>>;
  supplierPayment?: Maybe<Array<ApPaymentEntity>>;
  supplierRefund?: Maybe<Array<ApRefundEntity>>;
  pnmRental?: Maybe<Array<PnmRentalEntity>>;
  goodReturnNote?: Maybe<Array<GoodReturnNoteEntity>>;
  costCategory: Array<CostCategoryEntity>;
  latestSubmittedQuotation?: Maybe<RfqSupplierSubmissionEntity>;
  rfq: Array<RfqEntity>;
};


export type SupplierEntityRfqSubmissionArgs = {
  rfqID?: Maybe<Scalars['String']>;
};


export type SupplierEntityLatestSubmittedQuotationArgs = {
  rfqID?: Maybe<Scalars['String']>;
};

export enum RatingType {
  Gold = 'GOLD',
  Diamond = 'DIAMOND',
  Platinum = 'PLATINUM'
}

export type SupplierCCategoryEntity = AuditEntity & {
  __typename?: 'SupplierCCategoryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
  supplierID: Scalars['String'];
  costCategoryID: Scalars['String'];
  supplier?: Maybe<SupplierEntity>;
  costCategory?: Maybe<CostCategoryEntity>;
};

export type WorkshopEntity = AuditEntity & {
  __typename?: 'WorkshopEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  contactNo: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  address: Scalars['JSON'];
  contactPerson: Array<Scalars['JSON']>;
  workshopStatus: WorkshopStatus;
  ratingType: RatingType;
  costConnection?: Maybe<Array<PnmCategoryWorkshopEntity>>;
  maintenance?: Maybe<Array<MaintenanceEntity>>;
};

export enum WorkshopStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Blacklisted = 'BLACKLISTED'
}

export type PnmCategoryWorkshopEntity = AuditEntity & {
  __typename?: 'PNMCategoryWorkshopEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  costCategoryID?: Maybe<Scalars['String']>;
  workshopID?: Maybe<Scalars['String']>;
  workshop: WorkshopEntity;
  costCategory: CostCategoryEntity;
};

export type MaintenanceEntity = AuditEntity & {
  __typename?: 'MaintenanceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  plantMachineryID: Scalars['String'];
  workshopID: Scalars['String'];
  assignmentID?: Maybe<Scalars['String']>;
  assignedQty?: Maybe<Scalars['Float']>;
  outstandingQty?: Maybe<Scalars['Float']>;
  assignmentDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  returnDate?: Maybe<Scalars['DateTime']>;
  cancellationDate?: Maybe<Scalars['DateTime']>;
  usageReading?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  returnRemarks?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  trackingLog?: Maybe<Array<Scalars['JSON']>>;
  maintenanceStatus: AsgMainStatus;
  plantMachinery?: Maybe<PlantMachineryEntity>;
  workshop?: Maybe<WorkshopEntity>;
  assignment?: Maybe<AssignmentEntity>;
};

export enum AsgMainStatus {
  Active = 'ACTIVE',
  Returned = 'RETURNED',
  PartiallyReturned = 'PARTIALLY_RETURNED',
  Closed = 'CLOSED',
  Cancelled = 'CANCELLED'
}

export type AssignmentEntity = AuditEntity & {
  __typename?: 'AssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  plantMachineryID: Scalars['String'];
  costCategoryID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  assignedQty?: Maybe<Scalars['Float']>;
  outstandingQty?: Maybe<Scalars['Float']>;
  assignmentDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  cost?: Maybe<Scalars['Float']>;
  trackingLog?: Maybe<Array<Scalars['JSON']>>;
  assignmentRate: RateUomType;
  returnDate?: Maybe<Scalars['DateTime']>;
  returnRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['DateTime']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  assignmentStatus: AsgMainStatus;
  plantMachinery?: Maybe<PlantMachineryEntity>;
  contract?: Maybe<ContractEntity>;
  maintenance?: Maybe<Array<MaintenanceEntity>>;
};

export type PlantMachineryEntity = AuditEntity & {
  __typename?: 'PlantMachineryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  supplierID?: Maybe<Scalars['String']>;
  costCategoryID: Scalars['String'];
  regNo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  purchaseDate: Scalars['DateTime'];
  quantity?: Maybe<Scalars['Float']>;
  availableQty?: Maybe<Scalars['Float']>;
  serialNo?: Maybe<Scalars['String']>;
  warrantyDate?: Maybe<Scalars['DateTime']>;
  roadTaxDate?: Maybe<Scalars['DateTime']>;
  insuranceDate?: Maybe<Scalars['DateTime']>;
  cancellationDate?: Maybe<Scalars['DateTime']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  plantMachineryStatus: PlantMachineryStatus;
  costCategory?: Maybe<CostCategoryEntity>;
  supplier?: Maybe<SupplierEntity>;
  assignment?: Maybe<Array<AssignmentEntity>>;
  maintenance?: Maybe<Array<MaintenanceEntity>>;
};

export type PmStatus = {
  __typename?: 'pmStatus';
  available?: Maybe<Scalars['Float']>;
  assigned?: Maybe<Scalars['Float']>;
  outOfOrder?: Maybe<Scalars['Float']>;
  maintenance?: Maybe<Scalars['Float']>;
};

export type CostCategoryEntity = AuditEntity & {
  __typename?: 'CostCategoryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  pnmCostCat?: Maybe<Scalars['JSON']>;
  costToDate?: Maybe<Scalars['Float']>;
  committedCost?: Maybe<Scalars['Float']>;
  costClass: CostClass;
  commonStatus: CommonStatus;
  glAccountCode?: Maybe<Scalars['String']>;
  supplierConnection: Array<SupplierCCategoryEntity>;
  contractConnection: Array<ProjectBudgetEntity>;
  workshopConnection?: Maybe<Array<PnmCategoryWorkshopEntity>>;
  subcontractTrade: Array<SubcontractEntity>;
  miscExpenses?: Maybe<Array<MiscExpenseEntity>>;
  costItem?: Maybe<Array<CostItemEntity>>;
  plantMachinery?: Maybe<Array<PlantMachineryEntity>>;
  budget: Array<ProjectBudgetEntity>;
  /** CustomFieldResolver */
  plantMachineryStatus?: Maybe<PmStatus>;
  subconTender: Array<SubcontractTenderEntity>;
  pnmRental?: Maybe<Array<PnmRentalEntity>>;
  defect?: Maybe<Array<DefectEntity>>;
  supplier: Array<SupplierEntity>;
  subcontractor: Array<SubcontractorEntity>;
};


export type CostCategoryEntityBudgetArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export enum CostClass {
  Main = 'MAIN',
  Subcontract = 'SUBCONTRACT',
  Labour = 'LABOUR',
  Misc = 'MISC',
  Material = 'MATERIAL',
  Plant = 'PLANT'
}

export type DefectEntity = AuditEntity & {
  __typename?: 'DefectEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  reportedDate: Scalars['DateTime'];
  expectedDate?: Maybe<Scalars['DateTime']>;
  completionDate?: Maybe<Scalars['DateTime']>;
  docNo: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  costCategoryID: Scalars['String'];
  activityLog?: Maybe<Array<Scalars['JSON']>>;
  defectStatus: DefectStatus;
  defectAssignment?: Maybe<Array<DefectAssignmentEntity>>;
  site?: Maybe<SiteEntity>;
  trade?: Maybe<CostCategoryEntity>;
};

export type DefectAssignmentEntity = AuditEntity & {
  __typename?: 'DefectAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  defectID: Scalars['String'];
  subcontractorID: Scalars['String'];
  assignmentDate: Scalars['DateTime'];
  expectedDate?: Maybe<Scalars['DateTime']>;
  remarks?: Maybe<Scalars['String']>;
  defect?: Maybe<DefectEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
};

export type DefectManagementEntity = AuditEntity & {
  __typename?: 'DefectManagementEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  subcontractorID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  expectedDate?: Maybe<Scalars['DateTime']>;
  docNo: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  defectLog?: Maybe<Array<Scalars['JSON']>>;
  defectStatus: DefectStatus;
  contract?: Maybe<ContractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
};

export type SubcontractorEntity = AuditEntity & {
  __typename?: 'SubcontractorEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxTypeID?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description: Scalars['String'];
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  taxDetails?: Maybe<Scalars['JSON']>;
  address: Scalars['JSON'];
  platformAccess: Scalars['Boolean'];
  associatedStatus: AssociatedStatus;
  nominatedSC: Scalars['Boolean'];
  paymentTo: Scalars['String'];
  creditorAC?: Maybe<Scalars['String']>;
  statusRemarks?: Maybe<Scalars['String']>;
  ratingType: RatingType;
  cidbDetails: Scalars['JSON'];
  pkkDetails?: Maybe<Scalars['JSON']>;
  bankDetails?: Maybe<Scalars['JSON']>;
  contactPerson: Array<ContactEntity>;
  SubconPackage?: Maybe<Array<SubcontractorCCategoryEntity>>;
  subcontract?: Maybe<Array<SubcontractEntity>>;
  subconClaim: Array<SubcontractClaimEntity>;
  subconAdvance?: Maybe<Array<SubcontractAdvanceEntity>>;
  defect: Array<DefectManagementEntity>;
  subconInvitation?: Maybe<Array<SubcontractorInvitationEntity>>;
  subconRfqSubmission?: Maybe<Array<SubconRfqSubmissionEntity>>;
  defectAssignment?: Maybe<Array<DefectAssignmentEntity>>;
  subcontractClaim?: Maybe<Array<SubcontractClaimEntity>>;
  subcontractorMiscInvoice?: Maybe<Array<SubcontractMiscInvoiceEntity>>;
  subcontractorDN?: Maybe<Array<SubcontractDnEntity>>;
  subcontractorCN?: Maybe<Array<SubcontractCnEntity>>;
  dnToSubcontractor?: Maybe<Array<DNtoSubconEntity>>;
  cnToSubcontractor?: Maybe<Array<CNtoSubconEntity>>;
  payment?: Maybe<Array<SubcontractPaymentEntity>>;
  refund?: Maybe<Array<SubcontractRefundEntity>>;
  /** CustomFieldResolver */
  overdueInvInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  overdueInvInfoNoBound?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  overdueAdvInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  activeSubcontract?: Maybe<Scalars['JSON']>;
};

export type SubcontractorCCategoryEntity = AuditEntity & {
  __typename?: 'SubcontractorCCategoryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
  subcontractorID: Scalars['String'];
  costCategoryID: Scalars['String'];
  subcontractor?: Maybe<SubcontractorEntity>;
  subconPackagesData: Array<Scalars['JSON']>;
};

export type SubcontractorSubconAccTransaction = AuditEntity & {
  __typename?: 'SubcontractorSubconAccTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  subcontractorID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
};

export type SubconAdjustmentTransaction = AuditEntity & {
  __typename?: 'SubconAdjustmentTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  subcontractorID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
};

export type SubcontractClaimEntity = AuditEntity & {
  __typename?: 'SubcontractClaimEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  claimDocNo?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  claimAmt?: Maybe<Scalars['Float']>;
  sequenceNo?: Maybe<Scalars['Float']>;
  revisedNo?: Maybe<Scalars['Float']>;
  submittedDate: Scalars['String'];
  voDisplayAmt?: Maybe<Scalars['Float']>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  previousMosAmt?: Maybe<Scalars['Float']>;
  submittedMosAmt?: Maybe<Scalars['Float']>;
  currentMosAmt?: Maybe<Scalars['Float']>;
  submittedRetentionAmt?: Maybe<Scalars['Float']>;
  retentionAmt?: Maybe<Scalars['Float']>;
  balanceRetentionAmt?: Maybe<Scalars['Float']>;
  submittedDayWorkAmt?: Maybe<Scalars['Float']>;
  currentDayWorkAmt?: Maybe<Scalars['Float']>;
  balanceAdvanceAmt?: Maybe<Scalars['Float']>;
  currentAdvRecoupmentAmt?: Maybe<Scalars['Float']>;
  currentDeductionAmt?: Maybe<Scalars['Float']>;
  submittedDeductionAmt?: Maybe<Scalars['Float']>;
  balanceDeductionAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  rejectedDate?: Maybe<Scalars['String']>;
  isRetention: Scalars['Boolean'];
  subcontractID: Scalars['String'];
  subcontract?: Maybe<SubcontractEntity>;
  subcontractorID: Scalars['String'];
  subcontractor?: Maybe<SubcontractorEntity>;
  contractID?: Maybe<Scalars['String']>;
  contract?: Maybe<ContractEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  outstandingCertifiedAmt?: Maybe<Scalars['Float']>;
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  adjustmentInfo?: Maybe<Scalars['JSON']>;
  toDo: Array<ContractToDoEntity>;
};

export type SubcontractMiscInvoiceEntity = AuditEntity & {
  __typename?: 'SubcontractMiscInvoiceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  subcontractorID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  subcontract?: Maybe<SubcontractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};

export type SubcontractDnEntity = AuditEntity & {
  __typename?: 'SubcontractDNEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  subcontractorID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  subcontract?: Maybe<SubcontractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  subcontractDNItem?: Maybe<Array<SubcontractDnItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};

export type SubcontractDnItemEntity = AuditEntity & {
  __typename?: 'SubcontractDNItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  costItemID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  docAmt: Scalars['Float'];
  taxAmt: Scalars['Float'];
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  subcontractDNID: Scalars['String'];
  subcontractDN?: Maybe<SubcontractDnEntity>;
};

export type SubcontractCnEntity = AuditEntity & {
  __typename?: 'SubcontractCNEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  subcontractorID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  subcontract?: Maybe<SubcontractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  subcontractCNItem?: Maybe<Array<SubcontractCnItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};

export type SubcontractCnItemEntity = AuditEntity & {
  __typename?: 'SubcontractCNItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  costItemID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  docAmt: Scalars['Float'];
  taxAmt: Scalars['Float'];
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  subcontractCNID: Scalars['String'];
  subcontractCN?: Maybe<SubcontractCnEntity>;
};

export type DNtoSubconEntity = AuditEntity & {
  __typename?: 'DNtoSubconEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  subcontractorID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  subcontract?: Maybe<SubcontractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  DNtoSubconItem?: Maybe<Array<DNtoSubconItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};

export type DNtoSubconItemEntity = AuditEntity & {
  __typename?: 'DNtoSubconItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  costItemID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  docAmt: Scalars['Float'];
  taxAmt: Scalars['Float'];
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  DNtoSubconID: Scalars['String'];
  DNtoSubcon?: Maybe<DNtoSubconEntity>;
};

export type CNtoSubconEntity = AuditEntity & {
  __typename?: 'CNtoSubconEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  subcontractorID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  subcontract?: Maybe<SubcontractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  CNtoSubconItem?: Maybe<Array<CNtoSubconItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};

export type CNtoSubconItemEntity = AuditEntity & {
  __typename?: 'CNtoSubconItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  costItemID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  docAmt: Scalars['Float'];
  taxAmt: Scalars['Float'];
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  CNtoSubconID: Scalars['String'];
  CNtoSubcon?: Maybe<CNtoSubconEntity>;
};

export type AdjustmentTypeEntity = AuditEntity & {
  __typename?: 'AdjustmentTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  code: Scalars['String'];
  description: Scalars['String'];
  commonStatus: CommonStatus;
  glAdjustmentDynamic?: Maybe<Array<GlAdjustmentDynamicEntity>>;
  contractDn?: Maybe<Array<ContractDnEntity>>;
  contractCn?: Maybe<Array<ContractCnEntity>>;
  clientDn?: Maybe<Array<ClientDnEntity>>;
  clientCn?: Maybe<Array<ClientCnEntity>>;
  subcontractDn?: Maybe<Array<SubcontractDnEntity>>;
  subcontractCn?: Maybe<Array<SubcontractCnEntity>>;
  dnToSubcon?: Maybe<Array<DNtoSubconEntity>>;
  cnToSubcon?: Maybe<Array<CNtoSubconEntity>>;
  apDn?: Maybe<Array<ApDebitNoteEntity>>;
  apCn?: Maybe<Array<ApCreditNoteEntity>>;
  dnToSupplier?: Maybe<Array<DNtoSupplierEntity>>;
  cnToSupplier?: Maybe<Array<CNtoSupplierEntity>>;
};

export type ClientContractAccTransaction = AuditEntity & {
  __typename?: 'ClientContractAccTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
};

export type ClientConAdjustmentTransaction = AuditEntity & {
  __typename?: 'ClientConAdjustmentTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
};

export type ContractClaimEntity = AuditEntity & {
  __typename?: 'ContractClaimEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  claimDocNo?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  claimAmt?: Maybe<Scalars['Float']>;
  sequenceNo?: Maybe<Scalars['Float']>;
  revisedNo?: Maybe<Scalars['Float']>;
  submittedDate: Scalars['String'];
  voDisplayAmt?: Maybe<Scalars['Float']>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  previousMosAmt?: Maybe<Scalars['Float']>;
  submittedMosAmt?: Maybe<Scalars['Float']>;
  currentMosAmt?: Maybe<Scalars['Float']>;
  submittedRetentionAmt?: Maybe<Scalars['Float']>;
  retentionAmt?: Maybe<Scalars['Float']>;
  balanceRetentionAmt?: Maybe<Scalars['Float']>;
  submittedDayWorkAmt?: Maybe<Scalars['Float']>;
  currentDayWorkAmt?: Maybe<Scalars['Float']>;
  balanceAdvanceAmt?: Maybe<Scalars['Float']>;
  currentAdvRecoupmentAmt?: Maybe<Scalars['Float']>;
  currentDeductionAmt?: Maybe<Scalars['Float']>;
  submittedDeductionAmt?: Maybe<Scalars['Float']>;
  balanceDeductionAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  rejectedDate?: Maybe<Scalars['String']>;
  isRetention: Scalars['Boolean'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  customerID: Scalars['String'];
  customer?: Maybe<CustomerEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  outstandingCertifiedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  contractAllocation: Array<ContractAllocationEntity>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  adjustmentInfo?: Maybe<Scalars['JSON']>;
  toDo: Array<ContractToDoEntity>;
};

export type ContractMiscInvoiceEntity = AuditEntity & {
  __typename?: 'ContractMiscInvoiceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  contractAllocation: Array<ContractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};

export type ContractDnEntity = AuditEntity & {
  __typename?: 'ContractDNEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  contractAllocation: Array<ContractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  contractDNItem?: Maybe<Array<ContractDnItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};

export type ContractDnItemEntity = AuditEntity & {
  __typename?: 'ContractDNItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  costItemID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  docAmt: Scalars['Float'];
  taxAmt: Scalars['Float'];
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  contractDNID: Scalars['String'];
  contractDN?: Maybe<ContractDnEntity>;
};

export type ContractCnEntity = AuditEntity & {
  __typename?: 'ContractCNEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  contractAllocation: Array<ContractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  contractCNItem?: Maybe<Array<ContractCnItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};

export type ContractCnItemEntity = AuditEntity & {
  __typename?: 'ContractCNItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  costItemID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  docAmt: Scalars['Float'];
  taxAmt: Scalars['Float'];
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  contractCNID: Scalars['String'];
  contractCN?: Maybe<ContractCnEntity>;
};

export type ClientDnEntity = AuditEntity & {
  __typename?: 'ClientDNEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  contractAllocation: Array<ContractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  clientDNItem?: Maybe<Array<ClientDnItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};

export type ClientDnItemEntity = AuditEntity & {
  __typename?: 'ClientDNItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  costItemID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  docAmt: Scalars['Float'];
  taxAmt: Scalars['Float'];
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  clientDNID: Scalars['String'];
  clientDN?: Maybe<ClientDnEntity>;
};

export type ClientCnEntity = AuditEntity & {
  __typename?: 'ClientCNEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  contractAllocation: Array<ContractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  clientCNItem?: Maybe<Array<ClientCnItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};

export type ClientCnItemEntity = AuditEntity & {
  __typename?: 'ClientCNItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  costItemID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  docAmt: Scalars['Float'];
  taxAmt: Scalars['Float'];
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  clientCNID: Scalars['String'];
  clientCN?: Maybe<ClientCnEntity>;
};

export type FollowUpEntity = AuditEntity & {
  __typename?: 'FollowUpEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  customerID: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  contactPerson: Scalars['JSON'];
  followUpStatus: FollowUpStatus;
  customer?: Maybe<CustomerEntity>;
};

export enum FollowUpStatus {
  Call = 'CALL',
  Whatsapp = 'WHATSAPP',
  Audio = 'AUDIO',
  Email = 'EMAIL',
  Message = 'MESSAGE'
}

export type PaymentReminderEntity = AuditEntity & {
  __typename?: 'PaymentReminderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  customerID: Scalars['String'];
  reminderDate: Scalars['DateTime'];
  balanceAmt: Scalars['Float'];
  generationBatch: Scalars['String'];
  commonStatus: CommonStatus;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  docReminder?: Maybe<Array<DocReminderEntity>>;
};

export type DocReminderEntity = AuditEntity & {
  __typename?: 'DocReminderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  paymentReminderID: Scalars['String'];
  docRefTable: Scalars['String'];
  docRefID: Scalars['String'];
  reminderSeq: Scalars['Float'];
  numOfReminder: Scalars['Float'];
  reminderDetail: ReminderDetail;
  outstandingAmt: Scalars['Float'];
  dueDate: Scalars['DateTime'];
  paymentReminder?: Maybe<PaymentReminderEntity>;
};

export type BillInterestEntity = AuditEntity & {
  __typename?: 'BillInterestEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  customerID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  docID: Scalars['String'];
  billDate: Scalars['DateTime'];
  billAmt: Scalars['Float'];
  interestAmt: Scalars['Float'];
  waiveAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
};

export type CustomerEntity = AuditEntity & {
  __typename?: 'CustomerEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxTypeID?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description: Scalars['String'];
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  taxDetails?: Maybe<Scalars['JSON']>;
  address: Scalars['JSON'];
  platformAccess: Scalars['Boolean'];
  associatedStatus: AssociatedStatus;
  contactPerson: Array<ContactEntity>;
  contract?: Maybe<Array<ContractEntity>>;
  followUp?: Maybe<Array<FollowUpEntity>>;
  contractClaim?: Maybe<Array<ContractClaimEntity>>;
  customerMiscInvoice?: Maybe<Array<ContractMiscInvoiceEntity>>;
  customerDN?: Maybe<Array<ContractDnEntity>>;
  customerCN?: Maybe<Array<ContractCnEntity>>;
  customerClientDN?: Maybe<Array<ClientDnEntity>>;
  customerClientCN?: Maybe<Array<ClientCnEntity>>;
  customerReceipt?: Maybe<Array<ContractReceiptEntity>>;
  customerAdvance?: Maybe<Array<ContractAdvanceEntity>>;
  customerRefund?: Maybe<Array<ContractRefundEntity>>;
  paymentReminder?: Maybe<Array<PaymentReminderEntity>>;
  billInterest?: Maybe<Array<BillInterestEntity>>;
  /** CustomFieldResolver */
  activeContract?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  overdueInvInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  overdueInvInfoNoBound?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  clientReceiptInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  clientAgingInfo?: Maybe<Scalars['JSON']>;
};

export type GlExportEntity = AuditEntity & {
  __typename?: 'GLExportEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  transactionDate: Scalars['String'];
  commonStatus: CommonStatus;
  glExportDetail?: Maybe<Array<GlExportDetailEntity>>;
  glExportDetailSource?: Maybe<Array<GlExportDetailSourceEntity>>;
};

export type GlExportDetailEntity = AuditEntity & {
  __typename?: 'GLExportDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  glExportID: Scalars['String'];
  accountCode: Scalars['String'];
  type: Scalars['String'];
  associateRefTable: Scalars['String'];
  docRefTable: Scalars['String'];
  amount: Scalars['Float'];
  negative: Scalars['Boolean'];
  commonStatus: CommonStatus;
  glExport?: Maybe<GlExportEntity>;
};

export type GlExportDetailSourceEntity = AuditEntity & {
  __typename?: 'GLExportDetailSourceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  glExportID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docAmt?: Maybe<Scalars['Float']>;
  claimDocAmt?: Maybe<Scalars['Float']>;
  docRefID: Scalars['String'];
  docRefTable: Scalars['String'];
  docDate: Scalars['String'];
  docNo: Scalars['String'];
  doNo?: Maybe<Scalars['String']>;
  claimDocNo?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  glExport?: Maybe<GlExportEntity>;
  contract?: Maybe<ContractEntity>;
};

export type CostCenterEntity = AuditEntity & {
  __typename?: 'CostCenterEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  code: Scalars['String'];
  description: Scalars['String'];
  costCenterType: CostCenterType;
  commonStatus: CommonStatus;
  contract?: Maybe<Array<ContractEntity>>;
};

export enum CostCenterType {
  Company = 'COMPANY',
  Contract = 'CONTRACT',
  Bank = 'BANK'
}

export type ProfitRecogGlAccountCodeEntity = AuditEntity & {
  __typename?: 'ProfitRecogGLAccountCodeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  wipCostRecognized: Scalars['String'];
  wipBillingRecognized: Scalars['String'];
  revenuePL: Scalars['String'];
  costPL: Scalars['String'];
};

export type ProfitRecogGlJournalEntity = AuditEntity & {
  __typename?: 'ProfitRecogGLJournalEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  profitRecogGenerationID: Scalars['String'];
  wipCostRecAccCode: Scalars['String'];
  wipBillingRecAccCode: Scalars['String'];
  revenuePLAccCode: Scalars['String'];
  costPLAccCode: Scalars['String'];
  profitRecogGeneration: ProfitRecogGenerationEntity;
};

export type ProfitRecogFormulaEntity = AuditEntity & {
  __typename?: 'ProfitRecogFormulaEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  title: Scalars['String'];
  percOfCompletion: Scalars['String'];
  cost: Scalars['String'];
  revenue: Scalars['String'];
  commonStatus: CommonStatus;
  profitRecogAssgmt?: Maybe<Array<ProfitRecogAssignmentEntity>>;
  contract?: Maybe<Array<ContractEntity>>;
};

export type ProfitRecogParameterEntity = AuditEntity & {
  __typename?: 'ProfitRecogParameterEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  sqlScript: Scalars['String'];
  commonStatus: CommonStatus;
  profitRecogGenParamAssgmt: Array<ProfitRecogGenParamAssgmtEntity>;
};

export type ProfitRecogAssignmentEntity = AuditEntity & {
  __typename?: 'ProfitRecogAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  profitRecogFormulaID: Scalars['String'];
  commonStatus: CommonStatus;
  profitRecogFormula?: Maybe<ProfitRecogFormulaEntity>;
  contract?: Maybe<ContractEntity>;
};

export type ProfitRecogGenerationEntity = AuditEntity & {
  __typename?: 'ProfitRecogGenerationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  docNo: Scalars['String'];
  docDate: Scalars['DateTime'];
  remarks: Scalars['String'];
  percOfCompletion: Scalars['Float'];
  cost: Scalars['Float'];
  revenue: Scalars['Float'];
  profitRecogStatus: ProfitRecogStatus;
  profitRecogGLJournal: Array<ProfitRecogGlJournalEntity>;
  profitRecogGenParamAssgmt: Array<ProfitRecogGenParamAssgmtEntity>;
  contract?: Maybe<ContractEntity>;
};

export enum ProfitRecogStatus {
  Active = 'ACTIVE',
  Recognized = 'RECOGNIZED',
  Cancelled = 'CANCELLED'
}

export type ProfitRecogGenParamAssgmtEntity = AuditEntity & {
  __typename?: 'ProfitRecogGenParamAssgmtEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  profitRecogGenerationID: Scalars['String'];
  profitRecogParameterID: Scalars['String'];
  amount: Scalars['Float'];
  profitRecogParameter: ProfitRecogParameterEntity;
  profitRecogGeneration: ProfitRecogGenerationEntity;
};

export type SiteProgressEntity = AuditEntity & {
  __typename?: 'SiteProgressEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  siteID?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  docDate?: Maybe<Scalars['DateTime']>;
  contract?: Maybe<ContractEntity>;
  /** CustomFieldResolver */
  imagesInfo?: Maybe<Scalars['JSON']>;
};

export type ContractVoEntity = AuditEntity & {
  __typename?: 'ContractVOEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef: Scalars['String'];
  rejectedDate?: Maybe<Scalars['DateTime']>;
  submittedDate: Scalars['DateTime'];
  docDate?: Maybe<Scalars['DateTime']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  architectNo: Scalars['String'];
  engineeringNo: Scalars['String'];
  VOType: VoType;
  VOStatus: VoStatus;
  submissionAmt?: Maybe<Scalars['Float']>;
  approvedAmt?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  contract: ContractEntity;
  toDo: Array<ContractToDoEntity>;
};

export type ContractEntity = AuditEntity & {
  __typename?: 'ContractEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  taxSchemeID: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  progressRetentionPerc: Scalars['Float'];
  maxRetentionPerc: Scalars['Float'];
  ladAmt?: Maybe<Scalars['Float']>;
  ladRate?: Maybe<RateUomType>;
  targetProfitPerc?: Maybe<Scalars['Float']>;
  cmgdDate?: Maybe<Scalars['DateTime']>;
  cpcDate?: Maybe<Scalars['DateTime']>;
  dlpDate?: Maybe<Scalars['DateTime']>;
  creditTerm?: Maybe<Scalars['Float']>;
  extensionDate?: Maybe<Scalars['JSON']>;
  closureDate?: Maybe<Scalars['DateTime']>;
  closureRemarks?: Maybe<Scalars['String']>;
  completionPerc?: Maybe<Scalars['Float']>;
  contactPerson?: Maybe<Array<Scalars['JSON']>>;
  customerID: Scalars['String'];
  customerDetail?: Maybe<CustomerEntity>;
  costCenterID?: Maybe<Scalars['String']>;
  costCenter?: Maybe<CostCenterEntity>;
  profitRecogFormulaID?: Maybe<Scalars['String']>;
  profitRecogFormula?: Maybe<ProfitRecogFormulaEntity>;
  contractNo: Scalars['String'];
  contractDate: Scalars['DateTime'];
  contractSum: Scalars['Float'];
  contractStatus: ContractStatus;
  VO: Array<ContractVoEntity>;
  deliveryOrder?: Maybe<Array<DoEntity>>;
  miscExpenses?: Maybe<Array<MiscExpenseEntity>>;
  purchaseReq?: Maybe<Array<PurchaseReqEntity>>;
  rfq?: Maybe<Array<RfqEntity>>;
  goodReturnNote?: Maybe<Array<GoodReturnNoteEntity>>;
  subcontractTenderDetail?: Maybe<Array<SubcontractTenderEntity>>;
  subcontractDetail?: Maybe<Array<SubcontractEntity>>;
  glExportDetailSource?: Maybe<Array<GlExportDetailSourceEntity>>;
  approvalPolicyAssignment?: Maybe<Array<ApprovalPolicyAssignmentEntity>>;
  ccConecction: Array<ProjectBudgetEntity>;
  taxDetail?: Maybe<ConTaxSchemeEntity>;
  site?: Maybe<Array<SiteEntity>>;
  assignment?: Maybe<Array<AssignmentEntity>>;
  defect?: Maybe<Array<DefectManagementEntity>>;
  siteProgress?: Maybe<Array<SiteProgressEntity>>;
  pnmRental?: Maybe<Array<PnmRentalEntity>>;
  profitRecogAssgmt?: Maybe<Array<ProfitRecogAssignmentEntity>>;
  profitRecogGeneration?: Maybe<Array<ProfitRecogGenerationEntity>>;
  billInterest?: Maybe<Array<BillInterestEntity>>;
  paymentReminder?: Maybe<Array<PaymentReminderEntity>>;
  contractClaim?: Maybe<Array<ContractClaimEntity>>;
  subcontractClaim?: Maybe<Array<SubcontractClaimEntity>>;
  contractMiscInvoice?: Maybe<Array<ContractMiscInvoiceEntity>>;
  contractDN?: Maybe<Array<ContractDnEntity>>;
  contractCN?: Maybe<Array<ContractCnEntity>>;
  contractClientDN?: Maybe<Array<ClientDnEntity>>;
  contractClientCN?: Maybe<Array<ClientCnEntity>>;
  contractInv?: Maybe<Array<ApInvoiceEntity>>;
  contractDebitNote?: Maybe<Array<ApDebitNoteEntity>>;
  contractCreditNote?: Maybe<Array<ApCreditNoteEntity>>;
  contractDNSupplier?: Maybe<Array<DNtoSupplierEntity>>;
  contractCNSupplier?: Maybe<Array<CNtoSupplierEntity>>;
  contractReceipt?: Maybe<Array<ContractReceiptEntity>>;
  contractAdvance?: Maybe<Array<ContractAdvanceEntity>>;
  contractRefund?: Maybe<Array<ContractRefundEntity>>;
  apPayment?: Maybe<Array<ApPaymentEntity>>;
  apAdvance?: Maybe<Array<ApAdvanceEntity>>;
  apRefund?: Maybe<Array<ApRefundEntity>>;
  /** CustomFieldResolver */
  latestRunNum?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  docNumChecking?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  subcontractSummary?: Maybe<Scalars['JSON']>;
  costCateg: Array<CostCategoryEntity>;
  /** CustomFieldResolver */
  contractRolesUsersAssigned?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  defectStatusByContract?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  voSum?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  revisedContractSum?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  contractPOCounts?: Maybe<Scalars['JSON']>;
  maxRetentionSum: Scalars['Float'];
  /** CustomFieldResolver */
  contractDefectCounts?: Maybe<Scalars['JSON']>;
};


export type ContractEntityCostCategArgs = {
  costCategoryID?: Maybe<Scalars['String']>;
  costClass?: Maybe<CostClass>;
};


export type ContractEntityContractRolesUsersAssignedArgs = {
  softwareCode?: Maybe<Scalars['String']>;
};

export type SiteImageEntity = AuditEntity & {
  __typename?: 'SiteImageEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  title: Scalars['String'];
  docDate?: Maybe<Scalars['DateTime']>;
  remark?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  site?: Maybe<SiteEntity>;
};

export type VideoCamEntity = AuditEntity & {
  __typename?: 'VideoCamEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  name: Scalars['String'];
  ip?: Maybe<Scalars['String']>;
  site?: Maybe<SiteEntity>;
};

export type WageCycleDetailEntity = AuditEntity & {
  __typename?: 'WageCycleDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  wageCycleID: Scalars['String'];
  workerID: Scalars['String'];
  wageAmt: Scalars['Float'];
  wageCycle?: Maybe<WageCycleEntity>;
  worker?: Maybe<WorkerEntity>;
};

export type WorkerAttendanceEntity = AuditEntity & {
  __typename?: 'WorkerAttendanceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  workDate: Scalars['String'];
  temperature: Scalars['Float'];
  workerID: Scalars['String'];
  siteID: Scalars['String'];
  timeIn: Scalars['String'];
  timeOut?: Maybe<Scalars['String']>;
  worker?: Maybe<WorkerEntity>;
  workerWage: WorkerWageEntity;
};

export type WorkerWageEntity = AuditEntity & {
  __typename?: 'WorkerWageEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  workerID: Scalars['String'];
  workerAttendanceID?: Maybe<Scalars['String']>;
  workDate: Scalars['String'];
  workHour: Scalars['Float'];
  verifiedHour?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  wageCycleID?: Maybe<Scalars['String']>;
  wageStatus: WageStatus;
  site?: Maybe<SiteEntity>;
  worker?: Maybe<WorkerEntity>;
  workerAttendance?: Maybe<WorkerAttendanceEntity>;
  wageCycle?: Maybe<WageCycleEntity>;
};

export enum WageStatus {
  Draft = 'DRAFT',
  Submit = 'SUBMIT',
  Reject = 'REJECT',
  Approved = 'APPROVED'
}

export type WageCycleEntity = AuditEntity & {
  __typename?: 'WageCycleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  workerWageID?: Maybe<Scalars['String']>;
  closedBy: Scalars['String'];
  docDate: Scalars['DateTime'];
  site?: Maybe<SiteEntity>;
  wageCycleDetail?: Maybe<Array<WageCycleDetailEntity>>;
  workerWage?: Maybe<Array<WorkerWageEntity>>;
};

export type SiteEntity = AuditEntity & {
  __typename?: 'SiteEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  stateID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  address: Scalars['JSON'];
  contactNo?: Maybe<Scalars['String']>;
  contract?: Maybe<ContractEntity>;
  worker?: Maybe<Array<WorkerEntity>>;
  siteStockLedger?: Maybe<Array<SiteStockLedgerEntity>>;
  purchaseReq?: Maybe<Array<PurchaseReqEntity>>;
  purchaseOrder?: Maybe<Array<PurchaseOrderEntity>>;
  subcontract?: Maybe<Array<SubcontractEntity>>;
  siteImage?: Maybe<Array<SiteImageEntity>>;
  videoCam?: Maybe<Array<VideoCamEntity>>;
  wageCycle?: Maybe<Array<WageCycleEntity>>;
  deliveryOrder?: Maybe<Array<DoEntity>>;
  workerWage?: Maybe<Array<WorkerWageEntity>>;
  grn?: Maybe<Array<GrnEntity>>;
  defect?: Maybe<Array<DefectEntity>>;
  goodReturnNote?: Maybe<Array<GoodReturnNoteEntity>>;
  workers: SiteEntity;
};

export type WorkerAdvanceEntity = AuditEntity & {
  __typename?: 'WorkerAdvanceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  workerID: Scalars['String'];
  docDate: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  wageCycleID?: Maybe<Scalars['String']>;
  wageStatus: WageStatus;
  worker?: Maybe<WorkerEntity>;
};

export type WorkerDocuments = {
  __typename?: 'WorkerDocuments';
  fileURL?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  mediaType?: Maybe<Scalars['String']>;
};

export type WorkerEntity = AuditEntity & {
  __typename?: 'WorkerEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  contactID: Scalars['String'];
  jobTypeID: Scalars['String'];
  identityNo: Scalars['String'];
  nationality: Nationality;
  workPermitNo?: Maybe<Scalars['String']>;
  docExpiryDate?: Maybe<Scalars['DateTime']>;
  address: Scalars['JSON'];
  wageRate: Scalars['Float'];
  wageType: WageType;
  commonStatus: CommonStatus;
  site?: Maybe<SiteEntity>;
  jobType?: Maybe<JobTypeEntity>;
  workerAttendance?: Maybe<Array<WorkerAttendanceEntity>>;
  workerAdvance?: Maybe<Array<WorkerAdvanceEntity>>;
  wageCycleDetail?: Maybe<Array<WageCycleDetailEntity>>;
  workerWage?: Maybe<Array<WorkerWageEntity>>;
  contact: ContactEntity;
  attachment?: Maybe<WorkerDocuments>;
};

export enum WageType {
  Hourly = 'HOURLY',
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  None = 'NONE'
}

export type ContactEntity = AuditEntity & {
  __typename?: 'ContactEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  associateID: Scalars['String'];
  name: Scalars['String'];
  designation?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  contactNo: Scalars['String'];
  email: Scalars['String'];
  relationship?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
  userDetail?: Maybe<PlatformUserEntity>;
  worker: WorkerEntity;
};

export type PlatformUserEntity = AuditEntity & {
  __typename?: 'PlatformUserEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  associateID: Scalars['String'];
  contactID: Scalars['String'];
  userName: Scalars['String'];
  commonStatus: CommonStatus;
  confirmed: Scalars['Boolean'];
  avatar?: Maybe<Scalars['String']>;
  superUser: Scalars['Boolean'];
  softwareCode: Scalars['String'];
  loginAttempt: Scalars['Float'];
  blockDuration: Scalars['Float'];
  blockCount: Scalars['Float'];
  loginTs?: Maybe<Scalars['DateTime']>;
  deviceIP?: Maybe<Scalars['String']>;
  contactDetail: ContactEntity;
};

export type PlatformUserResponse = {
  __typename?: 'PlatformUserResponse';
  ID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  userName: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  confirmed: Scalars['Boolean'];
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  deviceIP?: Maybe<Scalars['String']>;
  loginTs?: Maybe<Scalars['DateTime']>;
  blockDuration?: Maybe<Scalars['Float']>;
  blockCount?: Maybe<Scalars['Float']>;
  loginAttempt?: Maybe<Scalars['Float']>;
  commonStatus: CommonStatus;
};

export type PlatformLoginResponse = {
  __typename?: 'PlatformLoginResponse';
  accessToken: Scalars['String'];
  user: PlatformUserResponse;
};

export type CorePermissionEntity = {
  __typename?: 'CorePermissionEntity';
  index: Scalars['String'];
  name: Scalars['String'];
};

export type CoreRoleEntity = AuditEntity & {
  __typename?: 'CoreRoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
};

export type CoreRolePermissionAssignmentEntity = AuditEntity & {
  __typename?: 'CoreRolePermissionAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  roleID: Scalars['String'];
  permissionID: Scalars['Float'];
};

export type CoreEntityRoleUserAssignmentEntity = AuditEntity & {
  __typename?: 'CoreEntityRoleUserAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  entityID: Scalars['String'];
  roleID: Scalars['String'];
};

export type RoleEntity = AuditEntity & {
  __typename?: 'RoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  softwareCode: SoftwareCode;
  rolePerm: Array<RolePermissionAssignmentEntity>;
  conRolePerm: Array<RolePermissionAssignmentEntity>;
  siteRolePerm: Array<RolePermissionAssignmentEntity>;
};

export enum SoftwareCode {
  Contract = 'CONTRACT',
  Site = 'SITE',
  Client = 'CLIENT',
  Subcontractor = 'SUBCONTRACTOR',
  Supplier = 'SUPPLIER',
  Hr = 'HR',
  Property = 'PROPERTY',
  Rent = 'RENT',
  Hotel = 'HOTEL',
  Account = 'ACCOUNT',
  Subscription = 'SUBSCRIPTION',
  User = 'USER',
  Tenant = 'TENANT',
  LeaseTodo = 'LEASE_TODO',
  LeaseOperation = 'LEASE_OPERATION',
  LeaseSales = 'LEASE_SALES',
  Menu = 'MENU',
  PosCustomer = 'POS_CUSTOMER',
  Probation = 'PROBATION'
}

export type RolePermissionAssignmentEntity = AuditEntity & {
  __typename?: 'RolePermissionAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  roleID: Scalars['String'];
  permissionID: Scalars['Float'];
  accountID?: Maybe<Scalars['String']>;
  permName?: Maybe<Scalars['String']>;
};

export type RoleUserEntAssignmentEntity = AuditEntity & {
  __typename?: 'RoleUserEntAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  entityID: Scalars['String'];
  roleID: Scalars['String'];
  accountID: Scalars['String'];
  userID: Scalars['String'];
};

export type UserRoleId = {
  __typename?: 'userRoleID';
  roleID: Scalars['String'];
  userID: Scalars['String'];
};

export type CoreDocTypeEntity = AuditEntity & {
  __typename?: 'CoreDocTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  docName: Scalars['String'];
  commonStatus: CommonStatus;
};

export type CoreNumberFormatEntity = AuditEntity & {
  __typename?: 'CoreNumberFormatEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  docTypeID: Scalars['String'];
  numberFormat: Scalars['String'];
  numberLength: Scalars['Float'];
  prefix: Scalars['String'];
  commonStatus: CommonStatus;
};

export type CoreRunningNumberSetupEntity = AuditEntity & {
  __typename?: 'CoreRunningNumberSetupEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  numberFormatID: Scalars['String'];
  nextNumber: Scalars['Float'];
};

export type ConDocTypeEntity = AuditEntity & {
  __typename?: 'ConDocTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  docName: Scalars['String'];
  commonStatus: CommonStatus;
  docCode: ContractDocNum;
  code?: Maybe<Scalars['String']>;
  numberFormat: Array<ConNumberFormatEntity>;
};

export enum ContractDocNum {
  ArClaim = 'AR_CLAIM',
  ArMiscIv = 'AR_MISC_IV',
  ArCn = 'AR_CN',
  ArDn = 'AR_DN',
  ArAdvanceRecoup = 'AR_ADVANCE_RECOUP',
  ArPayment = 'AR_PAYMENT',
  ArReceipt = 'AR_RECEIPT',
  ArInvoice = 'AR_INVOICE',
  ApClaim = 'AP_CLAIM',
  ApMiscIv = 'AP_MISC_IV',
  ApCn = 'AP_CN',
  ApDn = 'AP_DN',
  ApAdvanceRecoup = 'AP_ADVANCE_RECOUP',
  ApPayment = 'AP_PAYMENT',
  ApReceipt = 'AP_RECEIPT',
  ApInvoice = 'AP_INVOICE',
  Default = 'DEFAULT',
  Df = 'DF',
  StockMvmt = 'STOCK_MVMT',
  Grn = 'GRN',
  Grtn = 'GRTN',
  Po = 'PO',
  Pr = 'PR',
  Rfq = 'RFQ',
  Vo = 'VO',
  Svo = 'SVO'
}

export type ConNumberFormatEntity = AuditEntity & {
  __typename?: 'ConNumberFormatEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  docTypeID: Scalars['String'];
  numberFormat: Scalars['String'];
  numberLength: Scalars['Float'];
  prefix: Scalars['String'];
  commonStatus: CommonStatus;
  softwareCode: Scalars['String'];
  documentDivider: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  prefixExtra?: Maybe<Scalars['String']>;
  docType: ConDocTypeEntity;
  runningNum: Array<ConRunningNumberSetupEntity>;
};

export type ConRunningNumberSetupEntity = AuditEntity & {
  __typename?: 'ConRunningNumberSetupEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  numberFormatID: Scalars['String'];
  nextNumber: Scalars['Float'];
  accountID: Scalars['String'];
  numberFormat: ConNumberFormatEntity;
};

export type SubcontractGuaranteeEntity = AuditEntity & {
  __typename?: 'SubcontractGuaranteeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRef: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  issuer: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['DateTime'];
  expiryDate: Scalars['DateTime'];
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['DateTime']>;
  rejectedDate?: Maybe<Scalars['DateTime']>;
  guaranteeTypeID: Scalars['String'];
  collateralAmt: Scalars['Float'];
  guaranteeStatus: GrntInsStatus;
  subcontractID: Scalars['String'];
  guaranteeType?: Maybe<GuaranteeTypeEntity>;
};

export type SubcontractInsuranceEntity = AuditEntity & {
  __typename?: 'SubcontractInsuranceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRef: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  issuer: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['DateTime'];
  expiryDate: Scalars['DateTime'];
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['DateTime']>;
  rejectedDate?: Maybe<Scalars['DateTime']>;
  insuranceTypeID: Scalars['String'];
  insuranceStatus: GrntInsStatus;
  subcontractID: Scalars['String'];
  insuranceType?: Maybe<InsuranceTypeEntity>;
};

export type GuaranteeTypeEntity = AuditEntity & {
  __typename?: 'GuaranteeTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  commonStatus: CommonStatus;
  contract?: Maybe<Array<ContractGuaranteeEntity>>;
  subcontract?: Maybe<Array<SubcontractGuaranteeEntity>>;
};

export type InsuranceTypeEntity = AuditEntity & {
  __typename?: 'InsuranceTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  commonStatus: CommonStatus;
  contract: Array<ContractInsuranceEntity>;
  subcontract?: Maybe<Array<SubcontractInsuranceEntity>>;
};

export type ContractGuaranteeEntity = AuditEntity & {
  __typename?: 'ContractGuaranteeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRef: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  issuer: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['DateTime'];
  expiryDate: Scalars['DateTime'];
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['DateTime']>;
  rejectedDate?: Maybe<Scalars['DateTime']>;
  guaranteeTypeID: Scalars['String'];
  collateralAmt: Scalars['Float'];
  guaranteeStatus: GrntInsStatus;
  contractID: Scalars['String'];
  guaranteeType?: Maybe<GuaranteeTypeEntity>;
};

export type ContractInsuranceEntity = AuditEntity & {
  __typename?: 'ContractInsuranceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRef: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  issuer: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['DateTime'];
  expiryDate: Scalars['DateTime'];
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['DateTime']>;
  rejectedDate?: Maybe<Scalars['DateTime']>;
  insuranceTypeID: Scalars['String'];
  insuranceStatus: GrntInsStatus;
  contractID: Scalars['String'];
  insuranceType?: Maybe<InsuranceTypeEntity>;
};

export type UomExchangeEntity = AuditEntity & {
  __typename?: 'UomExchangeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  fromUomID: Scalars['String'];
  toUomID: Scalars['String'];
  fromAmt: Scalars['Float'];
  toAmt: Scalars['Float'];
  exchangeRate: Scalars['Float'];
  uomLog?: Maybe<Array<Scalars['JSON']>>;
  fromUOM: UomEntity;
  toUOM: UomEntity;
};

export type CoreToDoEntity = AuditEntity & {
  __typename?: 'CoreToDoEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  submitterID: Scalars['String'];
  docRefID: Scalars['String'];
  docRefTable: Scalars['String'];
  notifiedDate?: Maybe<Scalars['DateTime']>;
  subject: Scalars['String'];
  body: Scalars['String'];
  toDoStatus: ToDoStatus;
  approvalLevel?: Maybe<ApprovalLevel>;
};

export enum ToDoStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Submit = 'SUBMIT',
  Cancelled = 'CANCELLED',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Pending = 'PENDING',
  Closed = 'CLOSED'
}

export enum ApprovalLevel {
  First = 'FIRST',
  Second = 'SECOND',
  Third = 'THIRD',
  Fourth = 'FOURTH',
  Fifth = 'FIFTH'
}

export type CoreApprovalLogEntity = AuditEntity & {
  __typename?: 'CoreApprovalLogEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  toDoID: Scalars['String'];
  approverID: Scalars['String'];
  logDate?: Maybe<Scalars['DateTime']>;
  notifiedDate?: Maybe<Scalars['DateTime']>;
  toDoStatus: ToDoStatus;
  remarks?: Maybe<Scalars['String']>;
};

export type CoreNotificationEntity = AuditEntity & {
  __typename?: 'CoreNotificationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  recipientID?: Maybe<Scalars['String']>;
  senderID?: Maybe<Scalars['String']>;
  docRefID: Scalars['String'];
  docRefTable: Scalars['String'];
  subject: Scalars['String'];
  body: Scalars['String'];
  readStatus: ReadStatus;
};

export enum ReadStatus {
  Seen = 'SEEN',
  Read = 'READ',
  Unread = 'UNREAD'
}

export type CoreNewsEntity = AuditEntity & {
  __typename?: 'CoreNewsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  postStatus: PostStatus;
  image?: Maybe<Scalars['String']>;
};

export enum PostStatus {
  Draft = 'DRAFT',
  Posted = 'POSTED'
}

export type CorePolicyGuidelineEntity = AuditEntity & {
  __typename?: 'CorePolicyGuidelineEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  docRefTable: Scalars['String'];
  commonStatus: CommonStatus;
};

export type CorePolicyRoleAssignmentEntity = AuditEntity & {
  __typename?: 'CorePolicyRoleAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  policyID: Scalars['String'];
  roleID: Scalars['String'];
  approvalLevel: ApprovalLevel;
};

export type CoreNotificationGuidelineEntity = AuditEntity & {
  __typename?: 'CoreNotificationGuidelineEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRefTable: Scalars['String'];
  commonStatus: CommonStatus;
};

export type CoreNotiRoleUserAssignmentEntity = AuditEntity & {
  __typename?: 'CoreNotiRoleUserAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  notiPolicyID: Scalars['String'];
  roleID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
};

export type CoreNewsReadEntity = AuditEntity & {
  __typename?: 'CoreNewsReadEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  userID: Scalars['String'];
};

export type PolicyGuidelineEntity = AuditEntity & {
  __typename?: 'PolicyGuidelineEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  docRefTable: Scalars['String'];
  commonStatus: CommonStatus;
  policyRoles: Array<PolicyRoleAssignmentEntity>;
};

export type PolicyRoleAssignmentEntity = AuditEntity & {
  __typename?: 'PolicyRoleAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  policyID: Scalars['String'];
  roleID: Scalars['String'];
  approvalLevel: ApprovalLevel;
  policy?: Maybe<PolicyGuidelineEntity>;
};

export type NotificationGuidelineEntity = AuditEntity & {
  __typename?: 'NotificationGuidelineEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRefTable: Scalars['String'];
  commonStatus: CommonStatus;
  policyID?: Maybe<Scalars['String']>;
  notiRoleUsers?: Maybe<Array<NotiRoleUserAssignmentEntity>>;
  /** CustomFieldResolver */
  roles: Scalars['JSON'];
};

export type NotiRoleUserAssignmentEntity = AuditEntity & {
  __typename?: 'NotiRoleUserAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  notiPolicyID: Scalars['String'];
  roleID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  notification?: Maybe<NotificationGuidelineEntity>;
};

export type HuaweiStorageOutput = {
  __typename?: 'HuaweiStorageOutput';
  BucketFileName: Scalars['String'];
  FileName: Scalars['String'];
  Url: Scalars['String'];
  MediaType: Scalars['String'];
  IsPrivate?: Maybe<Scalars['Boolean']>;
  PrivateUrl?: Maybe<Scalars['String']>;
};

export type AzureStorageOutput = {
  __typename?: 'AzureStorageOutput';
  BucketFileName: Scalars['String'];
  FileName: Scalars['String'];
  Url: Scalars['String'];
  MediaType: Scalars['String'];
  IsPrivate?: Maybe<Scalars['Boolean']>;
  PrivateUrl?: Maybe<Scalars['String']>;
};

export type WagesRatePolicyEntity = AuditEntity & {
  __typename?: 'WagesRatePolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  holidayTypeID: Scalars['String'];
  rate: Scalars['Float'];
  holidayType?: Maybe<HolidayTypeEntity>;
};

export type WorkingHourPolicyEntity = AuditEntity & {
  __typename?: 'WorkingHourPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  holidayTypeID: Scalars['String'];
  shiftStartTime: Scalars['String'];
  shiftEndTime: Scalars['String'];
  halfStartTime: Scalars['String'];
  halfEndTime: Scalars['String'];
  commonStatus: CommonStatus;
  holidayType?: Maybe<HolidayTypeEntity>;
};

export type HolidayTypeEntity = AuditEntity & {
  __typename?: 'HolidayTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  wagesRatePolicy?: Maybe<Array<WagesRatePolicyEntity>>;
  commonStatus: CommonStatus;
  calendar?: Maybe<Array<CalendarEntity>>;
  calendarPHP?: Maybe<Array<CalendarPhPolicyEntity>>;
  workingHourPolicy?: Maybe<Array<WorkingHourPolicyEntity>>;
};

export type CalendarEntity = AuditEntity & {
  __typename?: 'CalendarEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  stateID: Scalars['String'];
  holidayTypeID: Scalars['String'];
  state: Scalars['String'];
  year: Scalars['String'];
  isDefault: Scalars['Boolean'];
  weekend: Scalars['JSON'];
  commonStatus: CommonStatus;
  publicHolConnection?: Maybe<Array<CalendarPhPolicyEntity>>;
  holidayType?: Maybe<HolidayTypeEntity>;
  publicHoliday: Array<PublicHolidayEntity>;
  costCategory: Array<CalendarEntity>;
};

export type PublicHolidayEntity = AuditEntity & {
  __typename?: 'PublicHolidayEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['DateTime']>;
  calendarConnection?: Maybe<Array<CalendarPhPolicyEntity>>;
  commonStatus: CommonStatus;
};

export type CalendarPhPolicyEntity = AuditEntity & {
  __typename?: 'CalendarPHPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  calendarID: Scalars['String'];
  publicHolidayID: Scalars['String'];
  holidayTypeID: Scalars['String'];
  calendar?: Maybe<CalendarEntity>;
  publicHoliday?: Maybe<PublicHolidayEntity>;
  holidayType?: Maybe<HolidayTypeEntity>;
};

export type ContractNotificationEntity = AuditEntity & {
  __typename?: 'ContractNotificationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRefID: Scalars['String'];
  docRefTable: Scalars['String'];
  subject?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  notificationRecipient?: Maybe<Array<ContractNotificationRecipientEntity>>;
};

export type ContractNotificationRecipientEntity = AuditEntity & {
  __typename?: 'ContractNotificationRecipientEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  notificationID?: Maybe<Scalars['String']>;
  recipientID: Scalars['String'];
  isRead: Scalars['Boolean'];
  notification?: Maybe<ContractNotificationEntity>;
};

export type ContractToDoEntity = AuditEntity & {
  __typename?: 'ContractToDoEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  submitterID: Scalars['String'];
  docRefID: Scalars['String'];
  docRefTable: Scalars['String'];
  notifiedDate?: Maybe<Scalars['DateTime']>;
  subject: Scalars['String'];
  body: Scalars['String'];
  toDoStatus: ToDoStatus;
  approvalLevel?: Maybe<ApprovalLevel>;
  docRefAmt?: Maybe<Scalars['Float']>;
  approvalLog?: Maybe<Array<ContractApprovalLogEntity>>;
};

export type ContractApprovalLogEntity = AuditEntity & {
  __typename?: 'ContractApprovalLogEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  toDoID: Scalars['String'];
  approverID: Scalars['String'];
  logDate?: Maybe<Scalars['DateTime']>;
  notifiedDate?: Maybe<Scalars['DateTime']>;
  toDoStatus: ToDoStatus;
  remarks?: Maybe<Scalars['String']>;
  toDo?: Maybe<ContractToDoEntity>;
};

export type ContractNewsEntity = AuditEntity & {
  __typename?: 'ContractNewsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  postStatus: PostStatus;
  image?: Maybe<Scalars['String']>;
  newsRead?: Maybe<Array<ContractNewsReadEntity>>;
};

export type ContractNewsReadEntity = AuditEntity & {
  __typename?: 'ContractNewsReadEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  userID: Scalars['String'];
  newsID: Scalars['String'];
  news?: Maybe<ContractNewsEntity>;
};

export type UserEntity = AuditEntity & {
  __typename?: 'UserEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  userName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  contactNo: Scalars['String'];
  is2FA?: Maybe<Scalars['Boolean']>;
  commonStatus: CommonStatus;
  confirmed: Scalars['Boolean'];
  avatar?: Maybe<Scalars['String']>;
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  softwareCode: Scalars['String'];
  loginAttempt: Scalars['Float'];
  blockDuration: Scalars['Float'];
  blockCount: Scalars['Float'];
  loginTs?: Maybe<Scalars['DateTime']>;
  deviceIP?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
};

export type ShareAttachmentEntity = AuditEntity & {
  __typename?: 'ShareAttachmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  bucketFileName?: Maybe<Scalars['String']>;
  fileURL?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
};

export type CashFlowEntity = AuditEntity & {
  __typename?: 'CashFlowEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  cashFlowSum: Scalars['Float'];
  inflowAmt: Scalars['Float'];
  outFlowAmt: Scalars['Float'];
  netCashFlowAmt: Scalars['Float'];
  projectedCashFlowAmt: Scalars['Float'];
};

export type ProjectCostingEntity = AuditEntity & {
  __typename?: 'ProjectCostingEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  projectSum: Scalars['Float'];
  balanceToSpend: Scalars['Float'];
  projectCostAmt: Scalars['Float'];
};

export type ProjectPlanningEntity = AuditEntity & {
  __typename?: 'ProjectPlanningEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  projectSum: Scalars['Float'];
  totalIncome: Scalars['Float'];
  totalCost: Scalars['Float'];
};

export type CustomerReminderEntity = AuditEntity & {
  __typename?: 'CustomerReminderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  customerID: Scalars['String'];
  paymentReminder1: Scalars['Float'];
  paymentReminder2: Scalars['Float'];
  paymentReminder3: Scalars['Float'];
  paymentReminder4: Scalars['Float'];
  minimumAmt: Scalars['Float'];
  reminderFor: ReminderFor;
  printedCopy: Scalars['Boolean'];
  emailCopy: Scalars['Boolean'];
  commonStatus: CommonStatus;
};

export enum ReminderFor {
  AllBill = 'ALL_BILL',
  ProgressClaim = 'PROGRESS_CLAIM'
}

export type SAccountEntity = AuditEntity & {
  __typename?: 'SAccountEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  companyCode?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  currencyName?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['DateTime']>;
  watermarkImplementation: Scalars['Boolean'];
  isoImplementation: Scalars['Boolean'];
  watermarkWording?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['JSON']>;
  logoPosition: LogoPosition;
  isoLogoPosition: LogoPosition;
};

export enum LogoPosition {
  Left = 'LEFT',
  Center = 'CENTER',
  Right = 'RIGHT'
}

export type CbExportEntity = AuditEntity & {
  __typename?: 'CBExportEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  transactionDate?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  cbExportDetail?: Maybe<Array<CbExportDetailEntity>>;
};

export type CbExportDetailEntity = AuditEntity & {
  __typename?: 'CBExportDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  cbExportID: Scalars['String'];
  bankAccountID: Scalars['String'];
  accountHolder: Scalars['String'];
  accountNo: Scalars['String'];
  branch: Scalars['String'];
  clientName: Scalars['String'];
  docRefID: Scalars['String'];
  docRefTable?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  docDate: Scalars['String'];
  docNo: Scalars['String'];
  docRef?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  cbExport?: Maybe<CbExportEntity>;
};

export type CurrencyExchangeEntity = AuditEntity & {
  __typename?: 'CurrencyExchangeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  fromCurrencyID: Scalars['String'];
  toCurrencyID: Scalars['String'];
  effectiveDate: Scalars['DateTime'];
  fromAmt: Scalars['Float'];
  toAmt: Scalars['Float'];
  exchangeRate: Scalars['Float'];
  currencyLog?: Maybe<Array<Scalars['JSON']>>;
  /** CustomFieldResolver */
  fromCurrency: Scalars['JSON'];
  /** CustomFieldResolver */
  toCurrency: Scalars['JSON'];
};

export type DocNumHeaderEntity = AuditEntity & {
  __typename?: 'DocNumHeaderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  sampleOutput: Scalars['String'];
  commonStatus: CommonStatus;
  docNumDetail: Array<DocNumDetailEntity>;
};

export type DocNumDetailEntity = AuditEntity & {
  __typename?: 'DocNumDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNumHeaderID: Scalars['String'];
  sequence: Scalars['Float'];
  formatType: Scalars['String'];
  formatValue: Scalars['String'];
  startFrom?: Maybe<Scalars['Float']>;
  nextNumber?: Maybe<Scalars['Float']>;
  commonStatus: CommonStatus;
  docNumHeader: DocNumHeaderEntity;
};

export type ReportDevEntity = {
  __typename?: 'ReportDevEntity';
  ID: Scalars['String'];
  ParentID: Scalars['String'];
  ReportName: Scalars['String'];
  FilePath?: Maybe<Scalars['String']>;
  IsSystem?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Module?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
};

export type CalendarWeekendPolicyEntity = AuditEntity & {
  __typename?: 'CalendarWeekendPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  calendarID: Scalars['String'];
  daysOfWeek: DaysofWeek;
};

export enum DaysofWeek {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY'
}

export type SiteMaterialPolicyEntity = AuditEntity & {
  __typename?: 'SiteMaterialPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  costItemID: Scalars['String'];
  siteMaterialID: Scalars['String'];
};

export type SiteVideoEntity = AuditEntity & {
  __typename?: 'SiteVideoEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  videoCamID: Scalars['String'];
};

export type WorkFlowEntity = AuditEntity & {
  __typename?: 'WorkFlowEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  approvalPolicyAssignmentID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  refID: Scalars['String'];
  refTable: Scalars['String'];
  submitterID: Scalars['String'];
  submitDate: Scalars['String'];
  workFlowStatus: WorkFlowStatus;
  workFlowStep?: Maybe<Array<WorkFlowStepEntity>>;
};

export enum WorkFlowStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Completed = 'COMPLETED',
  Pending = 'PENDING',
  Pendingclose = 'PENDINGCLOSE',
  Cancelled = 'CANCELLED',
  Closed = 'CLOSED'
}

export type WorkFlowStepEntity = AuditEntity & {
  __typename?: 'WorkFlowStepEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  workFlowID: Scalars['String'];
  approvalRoleID: Scalars['String'];
  notifiedDT: Scalars['String'];
  stepNo: Scalars['Float'];
  approvalStatus: WorkFlowStatus;
  statusRemarks: Scalars['String'];
  workFlow?: Maybe<WorkFlowEntity>;
};

export type WorkFlowApprovedStepEntity = AuditEntity & {
  __typename?: 'WorkFlowApprovedStepEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  workFlowStepID: Scalars['String'];
  approverID: Scalars['String'];
  approvalDT: Scalars['String'];
  notifiedDT: Scalars['String'];
  approvalStatus: WorkFlowStatus;
  statusRemarks: Scalars['String'];
};

export type TestPermissionEntity = AuditEntity & {
  __typename?: 'TestPermissionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  contractPermission: ContractPermission;
};

export enum ContractPermission {
  HomeExecSummaryView = 'HOME_EXEC_SUMMARY_____________VIEW',
  HomeContractView = 'HOME_CONTRACT_____________VIEW',
  HomeContractCreate = 'HOME_CONTRACT_____________CREATE',
  HomeContractExecSummaryView = 'HOME_CONTRACT_EXEC_SUMMARY__________VIEW',
  HomeContractExecSummaryContractBudgetView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_BUDGET_______VIEW',
  HomeContractExecSummaryContractInfoView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_INFO_______VIEW',
  HomeContractExecSummaryContractInfoUpdate = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_INFO_______UPDATE',
  HomeContractExecSummaryContractGuaranteeView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_GUARANTEE_______VIEW',
  HomeContractExecSummaryContractGuaranteeCreate = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_GUARANTEE_______CREATE',
  HomeContractExecSummaryContractGuaranteeUpdate = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_GUARANTEE_______UPDATE',
  HomeContractExecSummaryContractGuaranteeCancel = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_GUARANTEE_______CANCEL',
  HomeContractExecSummaryContractInsuranceView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_INSURANCE_______VIEW',
  HomeContractExecSummaryContractInsuranceCreate = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_INSURANCE_______CREATE',
  HomeContractExecSummaryContractInsuranceUpdate = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_INSURANCE_______UPDATE',
  HomeContractExecSummaryContractInsuranceCancel = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_INSURANCE_______CANCEL',
  HomeContractExecSummaryContractVoView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_______VIEW',
  HomeContractExecSummaryContractVoCreate = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_______CREATE',
  HomeContractExecSummaryContractVoDraft = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_______DRAFT',
  HomeContractExecSummaryContractVoUpdate = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_______UPDATE',
  HomeContractExecSummaryContractVoApprove = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_______APPROVE',
  HomeContractExecSummaryContractVoReject = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_______REJECT',
  HomeContractExecSummaryContractVoHealthIndexView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_HEALTH_INDEX_______VIEW',
  HomeContractExecSummaryContractVoHealthIndexDownload = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_HEALTH_INDEX_______DOWNLOAD',
  HomeContractExecSummaryContractVoHealthIndexExport = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_HEALTH_INDEX_______EXPORT',
  HomeContractExecSummaryContractAccountStatusView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS____VIEW',
  HomeContractExecSummaryContractAccountStatusClientProfileView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_CLIENT_PROFILE____VIEW',
  HomeContractExecSummaryContractAccountStatusAdvancesView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_ADVANCES____VIEW',
  HomeContractExecSummaryContractAccountStatusProgressClaimView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_PROGRESS_CLAIM____VIEW',
  HomeContractExecSummaryContractAccountStatusRetentionClaimView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_RETENTION_CLAIM____VIEW',
  HomeContractExecSummaryContractAccountStatusDebitNotesView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_DEBIT_NOTES____VIEW',
  HomeContractExecSummaryContractAccountStatusCreditNotesView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_CREDIT_NOTES____VIEW',
  HomeContractExecSummaryContractAccountStatusReceiptsView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_RECEIPTS____VIEW',
  HomeContractExecSummaryContractAccountStatusRefundsView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_REFUNDS____VIEW',
  HomeContractExecSummaryContractAccountStatusClientDebitNotesView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_CLIENT_DEBIT_NOTES____VIEW',
  HomeContractExecSummaryContractAccountStatusOsReceivableView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_OS_RECEIVABLE____VIEW',
  HomeContractExecSummaryMiscExpensesView = 'HOME_CONTRACT_EXEC_SUMMARY_MISC_EXPENSES_______VIEW',
  HomeContractExecSummaryMiscExpensesCreate = 'HOME_CONTRACT_EXEC_SUMMARY_MISC_EXPENSES_______CREATE',
  HomeContractExecSummaryMiscExpensesUpdate = 'HOME_CONTRACT_EXEC_SUMMARY_MISC_EXPENSES_______UPDATE',
  HomeContractExecSummaryMiscExpensesCancel = 'HOME_CONTRACT_EXEC_SUMMARY_MISC_EXPENSES_______CANCEL',
  HomeContractExecSummaryRetentionView = 'HOME_CONTRACT_EXEC_SUMMARY_RETENTION_______VIEW',
  HomeContractExecSummaryRetentionCreate = 'HOME_CONTRACT_EXEC_SUMMARY_RETENTION_______CREATE',
  HomeContractExecSummaryRetentionUpdate = 'HOME_CONTRACT_EXEC_SUMMARY_RETENTION_______UPDATE',
  HomeContractExecSummaryRetentionAttachmentDownload = 'HOME_CONTRACT_EXEC_SUMMARY_RETENTION_ATTACHMENT____DOWNLOAD',
  HomeContractExecSummaryExtensionOfTimeView = 'HOME_CONTRACT_EXEC_SUMMARY_EXTENSION_OF_TIME_______VIEW',
  HomeContractExecSummaryExtensionOfTimeCreate = 'HOME_CONTRACT_EXEC_SUMMARY_EXTENSION_OF_TIME_______CREATE',
  HomeContractExecSummaryExtensionOfTimeAttachmentDownload = 'HOME_CONTRACT_EXEC_SUMMARY_EXTENSION_OF_TIME_ATTACHMENT____DOWNLOAD',
  HomeContractExecSummaryContractClosureView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_CLOSURE_______VIEW',
  HomeContractExecSummaryContractTerminationView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_TERMINATION_______VIEW',
  HomeCustomerView = 'HOME_CUSTOMER_____________VIEW',
  HomeCustomerExecSummaryView = 'HOME_CUSTOMER_EXEC_SUMMARY__________VIEW',
  HomeCustomerExecSummaryCustomerProfileView = 'HOME_CUSTOMER_EXEC_SUMMARY_CUSTOMER_PROFILE_______VIEW',
  HomeCustomerExecSummaryCustomerProfileUpdate = 'HOME_CUSTOMER_EXEC_SUMMARY_CUSTOMER_PROFILE_______UPDATE',
  HomeCustomerExecSummaryCustomerProfileCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_CUSTOMER_PROFILE_______CREATE',
  HomeCustomerExecSummaryAccountTransactionAdvancesCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_ADVANCES____CREATE',
  HomeCustomerExecSummaryAccountTransactionAdvancesView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_ADVANCES____VIEW',
  HomeCustomerExecSummaryAccountTransactionAdvancesCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_ADVANCES____CANCEL',
  HomeCustomerExecSummaryAccountTransactionProgressClaimView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____VIEW',
  HomeCustomerExecSummaryAccountTransactionProgressClaimApprove = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____APPROVE',
  HomeCustomerExecSummaryAccountTransactionProgressClaimReject = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____REJECT',
  HomeCustomerExecSummaryAccountTransactionProgressClaimCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____CANCEL',
  HomeCustomerExecSummaryAccountTransactionProgressClaimCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____CREATE',
  HomeCustomerExecSummaryAccountTransactionProgressClaimResubmit = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____RESUBMIT',
  HomeCustomerExecSummaryAccountTransactionProgressClaimDraft = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____DRAFT',
  HomeCustomerExecSummaryAccountTransactionProgressClaimDownload = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____DOWNLOAD',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____VIEW',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimApprove = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____APPROVE',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimReject = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____REJECT',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____CANCEL',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____CREATE',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimResubmit = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____RESUBMIT',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimDraft = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____DRAFT',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimDownload = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____DOWNLOAD',
  HomeCustomerExecSummaryAccountTransactionDebitNotesView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_DEBIT_NOTES____VIEW',
  HomeCustomerExecSummaryAccountTransactionDebitNotesCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_DEBIT_NOTES____CANCEL',
  HomeCustomerExecSummaryAccountTransactionDebitNotesCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_DEBIT_NOTES____CREATE',
  HomeCustomerExecSummaryAccountTransactionDebitNotesDownload = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_DEBIT_NOTES____DOWNLOAD',
  HomeCustomerExecSummaryAccountTransactionCreditNotesView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CREDIT_NOTES____VIEW',
  HomeCustomerExecSummaryAccountTransactionCreditNotesCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CREDIT_NOTES____CANCEL',
  HomeCustomerExecSummaryAccountTransactionCreditNotesCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CREDIT_NOTES____CREATE',
  HomeCustomerExecSummaryAccountTransactionCreditNotesDownload = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CREDIT_NOTES____DOWNLOAD',
  HomeCustomerExecSummaryAccountTransactionCreditNotesAllocate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CREDIT_NOTES____ALLOCATE',
  HomeCustomerExecSummaryAccountTransactionReceiptsView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RECEIPTS____VIEW',
  HomeCustomerExecSummaryAccountTransactionReceiptsAllocate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RECEIPTS____ALLOCATE',
  HomeCustomerExecSummaryAccountTransactionReceiptsCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RECEIPTS____CANCEL',
  HomeCustomerExecSummaryAccountTransactionReceiptsCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RECEIPTS____CREATE',
  HomeCustomerExecSummaryAccountTransactionReceiptsDownload = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RECEIPTS____DOWNLOAD',
  HomeCustomerExecSummaryAccountTransactionRefundsView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_REFUNDS____VIEW',
  HomeCustomerExecSummaryAccountTransactionRefundsDraft = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_REFUNDS____DRAFT',
  HomeCustomerExecSummaryAccountTransactionRefundsCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_REFUNDS____CANCEL',
  HomeCustomerExecSummaryAccountTransactionRefundsCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_REFUNDS____CREATE',
  HomeCustomerExecSummaryAccountTransactionRefundsApprove = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_REFUNDS____APPROVE',
  HomeCustomerExecSummaryAccountTransactionRefundsReject = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_REFUNDS____REJECT',
  HomeCustomerExecSummaryAccountTransactionRefundsDownload = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_REFUNDS____DOWNLOAD',
  HomeCustomerExecSummaryAccountTransactionClientDebitNotesView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CLIENT_DEBIT_NOTES____VIEW',
  HomeCustomerExecSummaryAccountTransactionClientDebitNotesCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CLIENT_DEBIT_NOTES____CANCEL',
  HomeCustomerExecSummaryAccountTransactionClientDebitNotesCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CLIENT_DEBIT_NOTES____CREATE',
  HomeCustomerExecSummaryAccountTransactionOsReceivableView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_OS_RECEIVABLE____VIEW',
  HomeCustomerExecSummaryCreditControlCustomerListingView = 'HOME_CUSTOMER_EXEC_SUMMARY_CREDIT_CONTROL_CUSTOMER_LISTING____VIEW',
  HomeCustomerExecSummaryStatementOfAccountCustomerAccountListingView = 'HOME_CUSTOMER_EXEC_SUMMARY_STATEMENT_OF_ACCOUNT_CUSTOMER_ACCOUNT_LISTING____VIEW',
  HomeSubcontractView = 'HOME_SUBCONTRACT_____________VIEW',
  HomeSubcontractCreate = 'HOME_SUBCONTRACT_____________CREATE',
  HomeSubcontractExecSummaryView = 'HOME_SUBCONTRACT_EXEC_SUMMARY__________VIEW',
  HomeSubcontractExecSummarySubcontractInfoView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_INFO_______VIEW',
  HomeSubcontractExecSummarySubcontractInfoUpdate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_INFO_______UPDATE',
  HomeSubcontractExecSummarySubcontractGuaranteeCreate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_GUARANTEE_______CREATE',
  HomeSubcontractExecSummarySubcontractGuaranteeView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_GUARANTEE_______VIEW',
  HomeSubcontractExecSummarySubcontractGuaranteeUpdate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_GUARANTEE_______UPDATE',
  HomeSubcontractExecSummarySubcontractGuaranteeCancel = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_GUARANTEE_______CANCEL',
  HomeSubcontractExecSummarySubcontractInsuracneCreate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_INSURACNE_______CREATE',
  HomeSubcontractExecSummarySubcontractInsuracneView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_INSURACNE_______VIEW',
  HomeSubcontractExecSummarySubcontractInsuracneCancel = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_INSURACNE_______CANCEL',
  HomeSubcontractExecSummarySubcontractInsuracneUpdate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_INSURACNE_______UPDATE',
  HomeSubcontractExecSummarySubcontractVoCreate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_VO_______CREATE',
  HomeSubcontractExecSummarySubcontractVoUpdate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_VO_______UPDATE',
  HomeSubcontractExecSummarySubcontractVoApprove = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_VO_______APPROVE',
  HomeSubcontractExecSummarySubcontractVoReject = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_VO_______REJECT',
  HomeSubcontractExecSummarySubcontractVoDraft = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_VO_______DRAFT',
  HomeSubcontractExecSummarySubcontractVoView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_VO_______VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusSubcontractorProfileView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_SUBCONTRACTOR_PROFILE____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusAdvancesView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_ADVANCES____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusProgressClaimView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_PROGRESS_CLAIM____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusRetentionClaimView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_RETENTION_CLAIM____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusDebitNotesView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_DEBIT_NOTES____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusCreditNotesView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_CREDIT_NOTES____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusPaymentsView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_PAYMENTS____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusRefundsView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_REFUNDS____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusDnToSubconView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_DN_TO_SUBCON____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusOsPayableView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_OS_PAYABLE____VIEW',
  HomeSubcontractExecSummaryRetentionView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_RETENTION_______VIEW',
  HomeSubcontractExecSummaryRetentionCreate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_RETENTION_______CREATE',
  HomeSubcontractExecSummaryRetentionUpdate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_RETENTION_______UPDATE',
  HomeSubcontractExecSummaryRetentionAttachmentDownload = 'HOME_SUBCONTRACT_EXEC_SUMMARY_RETENTION_ATTACHMENT____DOWNLOAD',
  HomeSubcontractExecSummaryExtensionOfTimeView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_EXTENSION_OF_TIME_______VIEW',
  HomeSubcontractExecSummaryExtensionOfTimeCreate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_EXTENSION_OF_TIME_______CREATE',
  HomeSubcontractExecSummaryExtensionOfTimeAttachmentDownload = 'HOME_SUBCONTRACT_EXEC_SUMMARY_EXTENSION_OF_TIME_ATTACHMENT____DOWNLOAD',
  HomeSubcontractExecSummarySubcontractClosureCreate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_CLOSURE_______CREATE',
  HomeSubcontractExecSummarySubcontractTerminationCreate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_TERMINATION_______CREATE',
  HomeSubcontractorAccountCreate = 'HOME_SUBCONTRACTOR_ACCOUNT_____________CREATE',
  HomeSubcontractorAccountView = 'HOME_SUBCONTRACTOR_ACCOUNT_____________VIEW',
  HomeSubcontractorAccountExecSummaryView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY__________VIEW',
  HomeSubcontractorAccountExecSummarySubcontractorProfileView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_SUBCONTRACTOR_PROFILE_______VIEW',
  HomeSubcontractorAccountExecSummarySubcontractorProfileUpdate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_SUBCONTRACTOR_PROFILE_______UPDATE',
  HomeSubcontractorAccountExecSummaryAdvancesCreate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_ADVANCES_______CREATE',
  HomeSubcontractorAccountExecSummaryAdvancesView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_ADVANCES_______VIEW',
  HomeSubcontractorAccountExecSummaryAdvancesUpdate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_ADVANCES_______UPDATE',
  HomeSubcontractorAccountExecSummaryAdvancesCancel = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_ADVANCES_______CANCEL',
  HomeSubcontractorAccountExecSummaryProgressClaimView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______VIEW',
  HomeSubcontractorAccountExecSummaryProgressClaimApprove = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______APPROVE',
  HomeSubcontractorAccountExecSummaryProgressClaimReject = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______REJECT',
  HomeSubcontractorAccountExecSummaryProgressClaimCancel = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______CANCEL',
  HomeSubcontractorAccountExecSummaryProgressClaimCreate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______CREATE',
  HomeSubcontractorAccountExecSummaryProgressClaimResubmit = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______RESUBMIT',
  HomeSubcontractorAccountExecSummaryProgressClaimDraft = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______DRAFT',
  HomeSubcontractorAccountExecSummaryProgressClaimDownload = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______DOWNLOAD',
  HomeSubcontractorAccountExecSummaryRetentionClaimView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______VIEW',
  HomeSubcontractorAccountExecSummaryRetentionClaimApprove = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______APPROVE',
  HomeSubcontractorAccountExecSummaryRetentionClaimReject = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______REJECT',
  HomeSubcontractorAccountExecSummaryRetentionClaimCancel = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______CANCEL',
  HomeSubcontractorAccountExecSummaryRetentionClaimCreate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______CREATE',
  HomeSubcontractorAccountExecSummaryRetentionClaimResubmit = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______RESUBMIT',
  HomeSubcontractorAccountExecSummaryRetentionClaimDraft = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______DRAFT',
  HomeSubcontractorAccountExecSummaryRetentionClaimDownload = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______DOWNLOAD',
  HomeSubcontractorAccountExecSummaryDebitNotesCreate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DEBIT_NOTES_______CREATE',
  HomeSubcontractorAccountExecSummaryDebitNotesView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DEBIT_NOTES_______VIEW',
  HomeSubcontractorAccountExecSummaryDebitNotesUpdate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DEBIT_NOTES_______UPDATE',
  HomeSubcontractorAccountExecSummaryDebitNotesCancel = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DEBIT_NOTES_______CANCEL',
  HomeSubcontractorAccountExecSummaryCreditNotesCreate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_CREDIT_NOTES_______CREATE',
  HomeSubcontractorAccountExecSummaryCreditNotesAllocate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_CREDIT_NOTES_______ALLOCATE',
  HomeSubcontractorAccountExecSummaryCreditNotesUpdate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_CREDIT_NOTES_______UPDATE',
  HomeSubcontractorAccountExecSummaryCreditNotesView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_CREDIT_NOTES_______VIEW',
  HomeSubcontractorAccountExecSummaryCreditNotesCancel = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_CREDIT_NOTES_______CANCEL',
  HomeSubcontractorAccountExecSummaryPaymentsCreate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PAYMENTS_______CREATE',
  HomeSubcontractorAccountExecSummaryPaymentsAttachmentDownload = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PAYMENTS_ATTACHMENT____DOWNLOAD',
  HomeSubcontractorAccountExecSummaryPaymentsView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PAYMENTS_______VIEW',
  HomeSubcontractorAccountExecSummaryPaymentsDraft = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PAYMENTS_______DRAFT',
  HomeSubcontractorAccountExecSummaryPaymentsApprove = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PAYMENTS_______APPROVE',
  HomeSubcontractorAccountExecSummaryPaymentsReject = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PAYMENTS_______REJECT',
  HomeSubcontractorAccountExecSummaryPaymentsCancel = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PAYMENTS_______CANCEL',
  HomeSubcontractorAccountExecSummaryDnToSubconView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DN_TO_SUBCON_______VIEW',
  HomeSubcontractorAccountExecSummaryDnToSubconUpdate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DN_TO_SUBCON_______UPDATE',
  HomeSubcontractorAccountExecSummaryDnToSubconCancel = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DN_TO_SUBCON_______CANCEL',
  HomeSubcontractorAccountExecSummaryDnToSubconAttachmentDownload = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DN_TO_SUBCON_ATTACHMENT____DOWNLOAD',
  HomeSubcontractorAccountExecSummaryOsPayableView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_OS_PAYABLE_______VIEW',
  HomeProjectPurchasesContractListingView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING__________VIEW',
  HomeProjectPurchasesContractListingExecSummaryView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_______VIEW',
  HomeProjectPurchasesContractListingExecSummaryNegotiatedSupplyCreate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_NEGOTIATED_SUPPLY____CREATE',
  HomeProjectPurchasesContractListingExecSummaryNegotiatedSupplyView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_NEGOTIATED_SUPPLY____VIEW',
  HomeProjectPurchasesContractListingExecSummaryNegotiatedSupplyUpdate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_NEGOTIATED_SUPPLY____UPDATE',
  HomeProjectPurchasesContractListingExecSummaryNegotiatedSupplyCancel = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_NEGOTIATED_SUPPLY____CANCEL',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionCreate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION____CREATE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION____VIEW',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionUpdate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION____UPDATE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionCancel = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION____CANCEL',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionApprove = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION____APPROVE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionAttachmentDownload = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ATTACHMENT_DOWNLOAD',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionReject = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION____REJECT',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER____VIEW',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderCreate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER____CREATE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderCancel = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER____CANCEL',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderApprove = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER____APPROVE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderReject = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER____REJECT',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderAttachmentDownload = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER_ATTACHMENT_DOWNLOAD',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionOnbehalfCreate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ONBEHALF____CREATE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionOnbehalfView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ONBEHALF____VIEW',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionOnbehalfUpdate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ONBEHALF____UPDATE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionOnbehalfCancel = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ONBEHALF____CANCEL',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionOnbehalfApprove = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ONBEHALF____APPROVE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionOnbehalfAttachmentDownload = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ONBEHALF_ATTACHMENT_DOWNLOAD',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionOnbehalfReject = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ONBEHALF____REJECT',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderOnbehalfView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER_ONBEHALF____VIEW',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderOnbehalfCreate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER_ONBEHALF____CREATE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderOnbehalfCancel = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER_ONBEHALF____CANCEL',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderOnbehalfApprove = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER_ONBEHALF____APPROVE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderOnbehalfReject = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER_ONBEHALF____REJECT',
  HomeProjectPurchasesContractListingExecSummaryDeliveryOrderCreate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_DELIVERY_ORDER____CREATE',
  HomeProjectPurchasesContractListingExecSummaryDeliveryOrderView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_DELIVERY_ORDER____VIEW',
  HomeProjectPurchasesContractListingExecSummaryDeliveryOrderUpdate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_DELIVERY_ORDER____UPDATE',
  HomeSupplierAccountView = 'HOME_SUPPLIER_ACCOUNT_____________VIEW',
  HomeSupplierAccountCreate = 'HOME_SUPPLIER_ACCOUNT_____________CREATE',
  HomeSupplierAccountExecSummaryView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY__________VIEW',
  HomeSupplierAccountExecSummarySupplierProfileView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_PROFILE_______VIEW',
  HomeSupplierAccountExecSummarySupplierProfileUpdate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_PROFILE_______UPDATE',
  HomeSupplierAccountExecSummaryPrefSupplierProductsView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_PREF_SUPPLIER_PRODUCTS_______VIEW',
  HomeSupplierAccountExecSummaryPrefSupplierProductsCreate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_PREF_SUPPLIER_PRODUCTS_______CREATE',
  HomeSupplierAccountExecSummaryPrefSupplierProductsDelete = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_PREF_SUPPLIER_PRODUCTS_______DELETE',
  HomeSupplierAccountExecSummarySupplierAccountsView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_______VIEW',
  HomeSupplierAccountExecSummarySupplierAccountsDepositsCreate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEPOSITS____CREATE',
  HomeSupplierAccountExecSummarySupplierAccountsDepositsCancel = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEPOSITS____CANCEL',
  HomeSupplierAccountExecSummarySupplierAccountsDepositsAllocate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEPOSITS____ALLOCATE',
  HomeSupplierAccountExecSummarySupplierAccountsDepositsAttachmentDownload = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEPOSITS_ATTACHMENT_DOWNLOAD',
  HomeSupplierAccountExecSummarySupplierAccountsDepositsApprove = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEPOSITS____APPROVE',
  HomeSupplierAccountExecSummarySupplierAccountsDepositsReject = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEPOSITS____REJECT',
  HomeSupplierAccountExecSummarySupplierAccountsDepositsView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEPOSITS____VIEW',
  HomeSupplierAccountExecSummarySupplierAccountsInvoicesCreate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_INVOICES____CREATE',
  HomeSupplierAccountExecSummarySupplierAccountsInvoicesView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_INVOICES____VIEW',
  HomeSupplierAccountExecSummarySupplierAccountsInvoicesCancel = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_INVOICES____CANCEL',
  HomeSupplierAccountExecSummarySupplierAccountsDebitNotesCreate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEBIT_NOTES____CREATE',
  HomeSupplierAccountExecSummarySupplierAccountsDebitNotesView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEBIT_NOTES____VIEW',
  HomeSupplierAccountExecSummarySupplierAccountsDebitNotesUpdate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEBIT_NOTES____UPDATE',
  HomeSupplierAccountExecSummarySupplierAccountsDebitNotesCancel = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEBIT_NOTES____CANCEL',
  HomeSupplierAccountExecSummarySupplierAccountsCreditNotesCreate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_CREDIT_NOTES____CREATE',
  HomeSupplierAccountExecSummarySupplierAccountsCreditNotesView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_CREDIT_NOTES____VIEW',
  HomeSupplierAccountExecSummarySupplierAccountsCreditNotesAllocate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_CREDIT_NOTES____ALLOCATE',
  HomeSupplierAccountExecSummarySupplierAccountsCreditNotesUpdate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_CREDIT_NOTES____UPDATE',
  HomeSupplierAccountExecSummarySupplierAccountsCreditNotesCancel = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_CREDIT_NOTES____CANCEL',
  HomeSupplierAccountExecSummarySupplierAccountsPaymentsCreate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_PAYMENTS____CREATE',
  HomeSupplierAccountExecSummarySupplierAccountsPaymentsUpdate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_PAYMENTS____UPDATE',
  HomeSupplierAccountExecSummarySupplierAccountsPaymentsView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_PAYMENTS____VIEW',
  HomeSupplierAccountExecSummarySupplierAccountsPaymentsCancel = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_PAYMENTS____CANCEL',
  HomeSupplierAccountExecSummarySupplierAccountsOsPayableView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_OS_PAYABLE____VIEW',
  HomePlantAndMachineryExecSummaryView = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY__________VIEW',
  HomePlantAndMachineryExecSummaryOwnedPnmView = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_OWNED_PNM_______VIEW',
  HomePlantAndMachineryExecSummaryOwnedPnmCreate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_OWNED_PNM_______CREATE',
  HomePlantAndMachineryExecSummaryOwnedPnmUpdate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_OWNED_PNM_______UPDATE',
  HomePlantAndMachineryExecSummaryOwnedPnmAssignmentHistoryView = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_OWNED_PNM_ASSIGNMENT_HISTORY____VIEW',
  HomePlantAndMachineryExecSummaryOwnedPnmMaintenanceHistoryView = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_OWNED_PNM_MAINTENANCE_HISTORY____VIEW',
  HomePlantAndMachineryExecSummaryRentedPnmCreate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_RENTED_PNM_______CREATE',
  HomePlantAndMachineryExecSummaryRentedPnmView = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_RENTED_PNM_______VIEW',
  HomePlantAndMachineryExecSummaryRentedPnmUpdate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_RENTED_PNM_______UPDATE',
  HomePlantAndMachineryExecSummaryRentedPnmReturn = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_RENTED_PNM_______RETURN',
  HomePlantAndMachineryExecSummaryAssignmentRecordsCreate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_ASSIGNMENT_RECORDS_______CREATE',
  HomePlantAndMachineryExecSummaryAssignmentRecordsView = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_ASSIGNMENT_RECORDS_______VIEW',
  HomePlantAndMachineryExecSummaryAssignmentRecordsUpdate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_ASSIGNMENT_RECORDS_______UPDATE',
  HomePlantAndMachineryExecSummaryAssignmentRecordsCancel = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_ASSIGNMENT_RECORDS_______CANCEL',
  HomePlantAndMachineryExecSummaryAssignmentRecordsReturn = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_ASSIGNMENT_RECORDS_______RETURN',
  HomePlantAndMachineryExecSummaryMaintenanceRecordsCreate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_MAINTENANCE_RECORDS_______CREATE',
  HomePlantAndMachineryExecSummaryMaintenanceRecordsView = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_MAINTENANCE_RECORDS_______VIEW',
  HomePlantAndMachineryExecSummaryMaintenanceRecordsUpdate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_MAINTENANCE_RECORDS_______UPDATE',
  HomePlantAndMachineryExecSummaryMaintenanceRecordsCancel = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_MAINTENANCE_RECORDS_______CANCEL',
  HomePlantAndMachineryExecSummaryMaintenanceRecordsReturn = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_MAINTENANCE_RECORDS_______RETURN',
  HomeBusinessInsightView = 'HOME_BUSINESS_INSIGHT_____________VIEW',
  HomeBusinessInsightContractListingProjectCostingView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_______VIEW',
  HomeBusinessInsightContractListingProjectCostingAttachmentAttachmentExport = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_ATTACHMENT_ATTACHMENT_EXPORT',
  HomeBusinessInsightContractListingProjectCostingAttachmentAttachmentDownload = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_ATTACHMENT_ATTACHMENT_DOWNLOAD',
  HomeBusinessInsightContractListingProjectCostingSubcontractCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_SUBCONTRACT_COST____VIEW',
  HomeBusinessInsightContractListingProjectCostingMaterialCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_MATERIAL_COST____VIEW',
  HomeBusinessInsightContractListingProjectCostingPmCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_PM_COST____VIEW',
  HomeBusinessInsightContractListingProjectCostingLabourCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_LABOUR_COST____VIEW',
  HomeBusinessInsightContractListingProjectCostingMiscCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_MISC_COST____VIEW',
  HomeBusinessInsightContractListingProjectPLAttachmentView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_ATTACHMENT____VIEW',
  HomeBusinessInsightContractListingProjectPLAttachmentAttachmentExport = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_ATTACHMENT_ATTACHMENT_EXPORT',
  HomeBusinessInsightContractListingProjectPLAttachmentAttachmentDownload = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_ATTACHMENT_ATTACHMENT_DOWNLOAD',
  HomeBusinessInsightContractListingProjectPLRevisedContractSumView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_REVISED_CONTRACT_SUM____VIEW',
  HomeBusinessInsightContractListingProjectPLProgressIncomeView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_PROGRESS_INCOME____VIEW',
  HomeBusinessInsightContractListingProjectPLSubcontractCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_SUBCONTRACT_COST____VIEW',
  HomeBusinessInsightContractListingProjectPLMaterialCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_MATERIAL_COST____VIEW',
  HomeBusinessInsightContractListingProjectPLPmCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_PM_COST____VIEW',
  HomeBusinessInsightContractListingProjectPLLabourCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_LABOUR_COST____VIEW',
  HomeBusinessInsightContractListingProjectPLMiscCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_MISC_COST____VIEW',
  HomeBusinessInsightContractListingProjectCashFlowAttachmentAttachmentExport = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_ATTACHMENT_ATTACHMENT_EXPORT',
  HomeBusinessInsightContractListingProjectCashFlowAttachmentAttachmentDownload = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_ATTACHMENT_ATTACHMENT_DOWNLOAD',
  HomeBusinessInsightContractListingProjectCashFlowView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_______VIEW',
  HomeBusinessInsightContractListingProjectCashFlowReceivedFromClientView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_RECEIVED_FROM_CLIENT____VIEW',
  HomeBusinessInsightContractListingProjectCashFlowPaidToSubcontractorView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_PAID_TO_SUBCONTRACTOR____VIEW',
  HomeBusinessInsightContractListingProjectCashFlowPaidToSupplierView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_PAID_TO_SUPPLIER____VIEW',
  HomeBusinessInsightContractListingProjectCashFlowOtherExpensesView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_OTHER_EXPENSES____VIEW',
  HomeBusinessInsightContractListingProjectCashFlowNetCashFlowView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_NET_CASH_FLOW____VIEW',
  HomeBusinessInsightContractListingProjectCostPerformanceView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COST_PERFORMANCE_______VIEW',
  HomeBusinessInsightContractListingProjectCostPerformanceAttachmentAttachmentExport = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COST_PERFORMANCE_ATTACHMENT_ATTACHMENT_EXPORT',
  HomeBusinessInsightContractListingProjectCostPerformanceAttachmentAttachmentDownload = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COST_PERFORMANCE_ATTACHMENT_ATTACHMENT_DOWNLOAD',
  HomeBusinessInsightContractListingProjectCostPerformanceCompletionPercCreate = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COST_PERFORMANCE_COMPLETION_PERC____CREATE',
  HomeBusinessInsightContractListingProjectFinancialStatusView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_______VIEW',
  HomeBusinessInsightContractListingProjectFinancialStatusAttachmentAttachmentExport = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_ATTACHMENT_ATTACHMENT_EXPORT',
  HomeBusinessInsightContractListingProjectFinancialStatusAttachmentAttachmentDownload = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_ATTACHMENT_ATTACHMENT_DOWNLOAD',
  HomeBusinessInsightContractListingProjectFinancialStatusCompletionPercCreate = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_COMPLETION_PERC____CREATE',
  HomeBusinessInsightContractListingProjectFinancialStatusBillingToDateView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_BILLING_TO_DATE____VIEW',
  HomeBusinessInsightContractListingProjectFinancialStatusCollectionToDateView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_COLLECTION_TO_DATE____VIEW',
  HomeBusinessInsightContractListingProjectFinancialStatusCostToDateView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_COST_TO_DATE____VIEW',
  HomeBusinessInsightContractListingProjectFinancialStatusPaymentToDateView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_PAYMENT_TO_DATE____VIEW',
  HomeBusinessInsightContractListingProjectBudgetAnalysisAttachmentAttachmentExport = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_BUDGET_ANALYSIS_ATTACHMENT_ATTACHMENT_EXPORT',
  HomeBusinessInsightContractListingProjectBudgetAnalysisAttachmentAttachmentDownload = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_BUDGET_ANALYSIS_ATTACHMENT_ATTACHMENT_DOWNLOAD',
  HomeBusinessInsightContractListingProjectBudgetAnalysisOriginalBudgetView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_BUDGET_ANALYSIS_ORIGINAL_BUDGET____VIEW',
  HomeBusinessInsightContractListingProjectBudgetAnalysisRevisedBudgetView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_BUDGET_ANALYSIS_REVISED_BUDGET____VIEW',
  HomeBusinessInsightContractListingProjectBudgetAnalysisBudgetVarianceView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_BUDGET_ANALYSIS_BUDGET_VARIANCE____VIEW',
  HomeBusinessInsightContractListingBudgetVsActualAnalysisView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_BUDGET_VS_ACTUAL_ANALYSIS_______VIEW',
  HomeBusinessInsightContractListingBudgetVsActualAnalysisAttachmentAttachmentExport = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_BUDGET_VS_ACTUAL_ANALYSIS_ATTACHMENT_ATTACHMENT_EXPORT',
  HomeBusinessInsightContractListingBudgetVsActualAnalysisAttachmentAttachmentDownload = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_BUDGET_VS_ACTUAL_ANALYSIS_ATTACHMENT_ATTACHMENT_DOWNLOAD',
  HomeBusinessInsightContractListingContractBudgetOriginalBudgetView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_CONTRACT_BUDGET_ORIGINAL_BUDGET____VIEW',
  HomeBusinessInsightContractListingContractBudgetOriginalBudgetCreate = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_CONTRACT_BUDGET_ORIGINAL_BUDGET____CREATE',
  HomeBusinessInsightContractListingContractBudgetRevisedBudgetView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_CONTRACT_BUDGET_REVISED_BUDGET____VIEW',
  HomeBusinessInsightContractListingContractBudgetRevisedBudgetCreate = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_CONTRACT_BUDGET_REVISED_BUDGET____CREATE',
  HomeSiteManagementSiteListingExecSummaryView = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_______VIEW',
  HomeSiteManagementSiteListingExecSummarySiteWorkerAttendanceView = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_SITE_WORKER_ATTENDANCE____VIEW',
  HomeSiteManagementSiteListingExecSummaryGrnView = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_GRN____VIEW',
  HomeSiteManagementSiteListingExecSummaryGrnCreate = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_GRN____CREATE',
  HomeSiteManagementSiteListingExecSummaryGrnUpdate = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_GRN____UPDATE',
  HomeSiteManagementSiteListingExecSummarySiteStockTrackingView = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_SITE_STOCK_TRACKING____VIEW',
  HomeSiteManagementSiteListingExecSummarySiteStockTrackingCreate = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_SITE_STOCK_TRACKING____CREATE',
  HomeSiteManagementSiteListingExecSummarySiteProgressCreate = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_SITE_PROGRESS____CREATE',
  HomeSiteManagementSiteListingExecSummarySiteProgressView = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_SITE_PROGRESS____VIEW',
  HomeSiteManagementSiteListingExecSummarySiteProgressUpdate = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_SITE_PROGRESS____UPDATE',
  HomeSiteManagementSiteListingExecSummarySiteProgressDelete = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_SITE_PROGRESS____DELETE',
  HomeSiteManagementSiteListingExecSummaryLiveVideoCamView = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_LIVE_VIDEO_CAM____VIEW',
  HomeSiteManagementSiteListingExecSummaryLiveVideoCamRecord = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_LIVE_VIDEO_CAM____RECORD',
  HomeDefectManagementContractListingView = 'HOME_DEFECT_MANAGEMENT_CONTRACT_LISTING__________VIEW',
  HomeDefectManagementContractListingDefectListingCreate = 'HOME_DEFECT_MANAGEMENT_CONTRACT_LISTING_DEFECT_LISTING_______CREATE',
  HomeDefectManagementContractListingDefectListingUpdate = 'HOME_DEFECT_MANAGEMENT_CONTRACT_LISTING_DEFECT_LISTING_______UPDATE',
  HomeDefectManagementContractListingDefectListingView = 'HOME_DEFECT_MANAGEMENT_CONTRACT_LISTING_DEFECT_LISTING_______VIEW',
  HomeDefectManagementContractListingDefectListingReAssignDefectCreate = 'HOME_DEFECT_MANAGEMENT_CONTRACT_LISTING_DEFECT_LISTING_RE_ASSIGN_DEFECT____CREATE',
  HomeDefectManagementContractListingDefectListingActivityLogCreate = 'HOME_DEFECT_MANAGEMENT_CONTRACT_LISTING_DEFECT_LISTING_ACTIVITY_LOG____CREATE',
  HomeGlInterfaceView = 'HOME_GL_INTERFACE_____________VIEW',
  HomeGlInterfaceAttachmentDownload = 'HOME_GL_INTERFACE__________ATTACHMENT_DOWNLOAD',
  HomeGlInterfaceContractView = 'HOME_GL_INTERFACE_CONTRACT__________VIEW',
  HomeGlInterfaceSubcontractView = 'HOME_GL_INTERFACE_SUBCONTRACT__________VIEW',
  HomeGlInterfaceSupplierView = 'HOME_GL_INTERFACE_SUPPLIER__________VIEW',
  HomeWorkDeskToDoView = 'HOME_WORK_DESK_TO_DO__________VIEW',
  HomeWorkDeskToDoApprove = 'HOME_WORK_DESK_TO_DO__________APPROVE',
  HomeWorkDeskToDoReject = 'HOME_WORK_DESK_TO_DO__________REJECT',
  HomeWorkDeskNotificationView = 'HOME_WORK_DESK_NOTIFICATION__________VIEW',
  GeneralSettingExecSummaryView = 'GENERAL_SETTING_EXEC_SUMMARY_____________VIEW',
  GeneralSettingExecSummaryPoliciesAndGuidelinesView = 'GENERAL_SETTING_EXEC_SUMMARY_POLICIES_AND_GUIDELINES__________VIEW',
  GeneralSettingExecSummaryPoliciesAndGuidelinesPrApprovalPolicyExecSummaryView = 'GENERAL_SETTING_EXEC_SUMMARY_POLICIES_AND_GUIDELINES_PR_APPROVAL_POLICY_EXEC_SUMMARY____VIEW',
  GeneralSettingExecSummaryPoliciesAndGuidelinesPrApprovalPolicyExecSummaryCreate = 'GENERAL_SETTING_EXEC_SUMMARY_POLICIES_AND_GUIDELINES_PR_APPROVAL_POLICY_EXEC_SUMMARY____CREATE',
  GeneralSettingExecSummaryPoliciesAndGuidelinesPrApprovalPolicyExecSummaryEdit = 'GENERAL_SETTING_EXEC_SUMMARY_POLICIES_AND_GUIDELINES_PR_APPROVAL_POLICY_EXEC_SUMMARY____EDIT',
  GeneralSettingExecSummaryPoliciesAndGuidelinesPoApprovalPolicyExecSummaryView = 'GENERAL_SETTING_EXEC_SUMMARY_POLICIES_AND_GUIDELINES_PO_APPROVAL_POLICY_EXEC_SUMMARY____VIEW',
  GeneralSettingExecSummaryPoliciesAndGuidelinesPoApprovalPolicyExecSummaryCreate = 'GENERAL_SETTING_EXEC_SUMMARY_POLICIES_AND_GUIDELINES_PO_APPROVAL_POLICY_EXEC_SUMMARY____CREATE',
  GeneralSettingExecSummaryPoliciesAndGuidelinesPoApprovalPolicyExecSummaryUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_POLICIES_AND_GUIDELINES_PO_APPROVAL_POLICY_EXEC_SUMMARY____UPDATE',
  GeneralSettingExecSummarySubcontractPackagesView = 'GENERAL_SETTING_EXEC_SUMMARY_SUBCONTRACT_PACKAGES__________VIEW',
  GeneralSettingExecSummarySubcontractPackagesCreate = 'GENERAL_SETTING_EXEC_SUMMARY_SUBCONTRACT_PACKAGES__________CREATE',
  GeneralSettingExecSummarySubcontractPackagesUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_SUBCONTRACT_PACKAGES__________UPDATE',
  GeneralSettingExecSummarySubcontractPackagesDelete = 'GENERAL_SETTING_EXEC_SUMMARY_SUBCONTRACT_PACKAGES__________DELETE',
  GeneralSettingExecSummaryPmCategoryView = 'GENERAL_SETTING_EXEC_SUMMARY_PM_CATEGORY__________VIEW',
  GeneralSettingExecSummaryPmCategoryCreate = 'GENERAL_SETTING_EXEC_SUMMARY_PM_CATEGORY__________CREATE',
  GeneralSettingExecSummaryPmCategoryUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_PM_CATEGORY__________UPDATE',
  GeneralSettingExecSummaryPmCategoryDelete = 'GENERAL_SETTING_EXEC_SUMMARY_PM_CATEGORY__________DELETE',
  GeneralSettingExecSummaryBuildingMaterialsCreate = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS__________CREATE',
  GeneralSettingExecSummaryBuildingMaterialsView = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS__________VIEW',
  GeneralSettingExecSummaryBuildingMaterialsUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS__________UPDATE',
  GeneralSettingExecSummaryBuildingMaterialsDelete = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS__________DELETE',
  GeneralSettingExecSummaryBuildingMaterialsItemListingCreate = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS_ITEM_LISTING_______CREATE',
  GeneralSettingExecSummaryBuildingMaterialsItemListingView = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS_ITEM_LISTING_______VIEW',
  GeneralSettingExecSummaryBuildingMaterialsItemListingUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS_ITEM_LISTING_______UPDATE',
  GeneralSettingExecSummaryBuildingMaterialsItemListingDelete = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS_ITEM_LISTING_______DELETE',
  GeneralSettingExecSummaryLabourCategoryCreate = 'GENERAL_SETTING_EXEC_SUMMARY_LABOUR_CATEGORY__________CREATE',
  GeneralSettingExecSummaryLabourCategoryView = 'GENERAL_SETTING_EXEC_SUMMARY_LABOUR_CATEGORY__________VIEW',
  GeneralSettingExecSummaryLabourCategoryUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_LABOUR_CATEGORY__________UPDATE',
  GeneralSettingExecSummaryLabourCategoryDelete = 'GENERAL_SETTING_EXEC_SUMMARY_LABOUR_CATEGORY__________DELETE',
  GeneralSettingExecSummaryMiscExpenseCreate = 'GENERAL_SETTING_EXEC_SUMMARY_MISC_EXPENSE__________CREATE',
  GeneralSettingExecSummaryMiscExpenseView = 'GENERAL_SETTING_EXEC_SUMMARY_MISC_EXPENSE__________VIEW',
  GeneralSettingExecSummaryMiscExpenseUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_MISC_EXPENSE__________UPDATE',
  GeneralSettingExecSummaryMiscExpenseDelete = 'GENERAL_SETTING_EXEC_SUMMARY_MISC_EXPENSE__________DELETE',
  GeneralSettingExecSummaryDocumentNumberingView = 'GENERAL_SETTING_EXEC_SUMMARY_DOCUMENT_NUMBERING__________VIEW',
  GeneralSettingExecSummaryDocumentNumberingCreate = 'GENERAL_SETTING_EXEC_SUMMARY_DOCUMENT_NUMBERING__________CREATE',
  GeneralSettingExecSummaryDocumentNumberingUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_DOCUMENT_NUMBERING__________UPDATE',
  GeneralSettingExecSummaryBankAccountView = 'GENERAL_SETTING_EXEC_SUMMARY_BANK_ACCOUNT__________VIEW',
  GeneralSettingExecSummaryBankAccountCreate = 'GENERAL_SETTING_EXEC_SUMMARY_BANK_ACCOUNT__________CREATE',
  GeneralSettingExecSummaryBankAccountUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_BANK_ACCOUNT__________UPDATE',
  GeneralSettingExecSummaryBankAccountDelete = 'GENERAL_SETTING_EXEC_SUMMARY_BANK_ACCOUNT__________DELETE',
  GeneralSettingPlatformCostCenterView = 'GENERAL_SETTING_PLATFORM_COST_CENTER__________VIEW',
  GeneralSettingPlatformCostCenterCreate = 'GENERAL_SETTING_PLATFORM_COST_CENTER__________CREATE',
  GeneralSettingPlatformCostCenterUpdate = 'GENERAL_SETTING_PLATFORM_COST_CENTER__________UPDATE',
  GeneralSettingPlatformCostCenterDelete = 'GENERAL_SETTING_PLATFORM_COST_CENTER__________DELETE',
  GeneralSettingExecSummaryUomView = 'GENERAL_SETTING_EXEC_SUMMARY_UOM__________VIEW',
  GeneralSettingPlatformGuaranteeTypeView = 'GENERAL_SETTING_PLATFORM_GUARANTEE_TYPE__________VIEW',
  GeneralSettingPlatformInsuranceTypeView = 'GENERAL_SETTING_PLATFORM_INSURANCE_TYPE__________VIEW',
  GeneralSettingExecSummaryTaxSetupView = 'GENERAL_SETTING_EXEC_SUMMARY_TAX_SETUP__________VIEW'
}

export type VoOmission = {
  __typename?: 'VoOmission';
  omission: Scalars['Float'];
};

export type UserResponse = AuditEntity & {
  __typename?: 'UserResponse';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  userName: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  address?: Maybe<Address>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  confirmed: Scalars['Boolean'];
  avatar?: Maybe<Scalars['String']>;
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  lastestAccessedEntity?: Maybe<Scalars['String']>;
  deviceIP?: Maybe<Scalars['String']>;
  loginTs?: Maybe<Scalars['DateTime']>;
  blockDuration?: Maybe<Scalars['Float']>;
  blockCount?: Maybe<Scalars['Float']>;
  loginAttempt?: Maybe<Scalars['Float']>;
  is2FA?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<FieldError>>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String'];
  user: UserResponse;
};

export type FieldError = {
  __typename?: 'FieldError';
  field: Scalars['String'];
  message: Scalars['String'];
};

export type TotpSecretTyes = {
  __typename?: 'TotpSecretTyes';
  is2FA?: Maybe<Scalars['Boolean']>;
  totpSecret?: Maybe<Scalars['String']>;
  dataURL?: Maybe<Scalars['String']>;
  otpURL?: Maybe<Scalars['String']>;
};

export type AccountSummary = {
  __typename?: 'AccountSummary';
  VOSum: Scalars['String'];
  claimSum: Scalars['String'];
  originalClaimSum: Scalars['String'];
  adjustmentSum: Scalars['String'];
  retentionSum: Scalars['String'];
  contractSum: Scalars['String'];
  progressRetention: Scalars['String'];
  maxRetention: Scalars['String'];
  expectedRetentionReleaseDate: Scalars['String'];
  cpcDate: Scalars['String'];
  cmgdDate: Scalars['String'];
  penaltyAmt: Scalars['String'];
  revisedContractSum: Scalars['String'];
  billtoDate: Scalars['String'];
  maxRetentionAmt: Scalars['String'];
  balancetoClaim: Scalars['String'];
  name: Scalars['String'];
  retentionReleaseSum: Scalars['String'];
  taxCode: Scalars['String'];
  customerID: Scalars['String'];
};

export type ClaimSummary = {
  __typename?: 'ClaimSummary';
  previousFinalClaim?: Maybe<Scalars['Float']>;
  previousClaim?: Maybe<Scalars['Float']>;
  previousMOS?: Maybe<Scalars['Float']>;
  previousVOClaim?: Maybe<Scalars['Float']>;
  previousDeduction?: Maybe<Scalars['Float']>;
  previousDayWork?: Maybe<Scalars['Float']>;
  retentionPerc?: Maybe<Scalars['Float']>;
  previousRetentionAmt?: Maybe<Scalars['Float']>;
  previousRetentionSum?: Maybe<Scalars['Float']>;
  retentionBalance?: Maybe<Scalars['Float']>;
  advanceSum?: Maybe<Scalars['Float']>;
  advanceRecouped?: Maybe<Scalars['Float']>;
  advanceBalance?: Maybe<Scalars['Float']>;
  deductionSum?: Maybe<Scalars['Float']>;
  deductionRecouped?: Maybe<Scalars['Float']>;
  deductionBalance?: Maybe<Scalars['Float']>;
  checkPendingSubmit?: Maybe<Scalars['Boolean']>;
  previousDocDate?: Maybe<Scalars['DateTime']>;
  submittedDocDate?: Maybe<Scalars['DateTime']>;
};

export type LedgerCheckingSummary = {
  __typename?: 'LedgerCheckingSummary';
  isApprovable?: Maybe<Scalars['Boolean']>;
  hasNextLevel?: Maybe<Scalars['Boolean']>;
  hasGuideline?: Maybe<Scalars['Boolean']>;
  validFlow?: Maybe<Scalars['Boolean']>;
};

export type Os = {
  __typename?: 'OS';
  amt?: Maybe<Scalars['Float']>;
  baseAmt?: Maybe<Scalars['Float']>;
};

export type TotalDoAmt = {
  __typename?: 'totalDOAmt';
  total?: Maybe<Scalars['Float']>;
};

export type VoSum = {
  __typename?: 'VoSUM';
  sum?: Maybe<Scalars['Float']>;
};

export type ContractInfo = {
  __typename?: 'ContractInfo';
  contractSum?: Maybe<Scalars['Float']>;
  contractDate?: Maybe<Scalars['DateTime']>;
};

export type ContractAccountInfo = {
  __typename?: 'ContractAccountInfo';
  outstandingAdvance?: Maybe<Scalars['Float']>;
  outstandingClaim?: Maybe<Scalars['Float']>;
};

export type Budgeting = {
  __typename?: 'budgeting';
  originalBudgetSum?: Maybe<Scalars['Float']>;
  revisedBudgetSum?: Maybe<Scalars['Float']>;
};

export type ContractVo = {
  __typename?: 'ContractVO';
  voSum?: Maybe<Scalars['Float']>;
  voOmission?: Maybe<Scalars['Float']>;
  voSumCount?: Maybe<Scalars['Float']>;
  voOmissionCount?: Maybe<Scalars['Float']>;
  totalVoAmt?: Maybe<Scalars['Float']>;
  voClientCount?: Maybe<Scalars['Float']>;
  voSubconCount?: Maybe<Scalars['Float']>;
};

export type RetentionInfo = {
  __typename?: 'RetentionInfo';
  retentionSum?: Maybe<Scalars['Float']>;
  releasedSum?: Maybe<Scalars['Float']>;
  retentionBalance?: Maybe<Scalars['Float']>;
};

export type SubontractInfo = {
  __typename?: 'SubontractInfo';
  subcontractSum?: Maybe<Scalars['Float']>;
  subcontractDate?: Maybe<Scalars['DateTime']>;
};

export type SubcontractVo = {
  __typename?: 'SubcontractVO';
  voSum?: Maybe<Scalars['Float']>;
  voOmission?: Maybe<Scalars['Float']>;
  voSumCount?: Maybe<Scalars['Float']>;
  voOmissionCount?: Maybe<Scalars['Float']>;
  totalVoAmt?: Maybe<Scalars['Float']>;
};

export type AssociatedDetail = {
  __typename?: 'AssociatedDetail';
  revisedBudgetSubcontractSum?: Maybe<Scalars['Float']>;
  subcontCostTotal?: Maybe<Scalars['Float']>;
  subcontractBalance?: Maybe<Scalars['Float']>;
  revisedBudgetMaterialSum?: Maybe<Scalars['Float']>;
  materialCostTotal?: Maybe<Scalars['Float']>;
  materialBalance?: Maybe<Scalars['Float']>;
  revisedBudgetPlantSum?: Maybe<Scalars['Float']>;
  plantCostTotal?: Maybe<Scalars['Float']>;
  plantBalance?: Maybe<Scalars['Float']>;
  revisedBudgetLabourSum?: Maybe<Scalars['Float']>;
  labourCostTotal?: Maybe<Scalars['Float']>;
  labourBalance?: Maybe<Scalars['Float']>;
  revisedBudgetMiscSum?: Maybe<Scalars['Float']>;
  miscCostTotal?: Maybe<Scalars['Float']>;
  miscBalance?: Maybe<Scalars['Float']>;
  revisedBudgetTotalSum?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  totalActualCost?: Maybe<Scalars['Float']>;
  totalBalance?: Maybe<Scalars['Float']>;
  latestDate?: Maybe<Scalars['DateTime']>;
  originalBudgetTotalSum?: Maybe<Scalars['Float']>;
  budgetVarianceAmt?: Maybe<Scalars['Float']>;
  budgetVariancePerc?: Maybe<Scalars['Float']>;
  origMaterialItemBudget?: Maybe<Scalars['JSON']>;
  origSubconItemBudget?: Maybe<Scalars['JSON']>;
  origPlantItemBudget?: Maybe<Scalars['JSON']>;
  origLabourItemBudget?: Maybe<Scalars['JSON']>;
  origMiscItemBudget?: Maybe<Scalars['JSON']>;
  revMaterialItemBudget?: Maybe<Scalars['JSON']>;
  revSubconItemBudget?: Maybe<Scalars['JSON']>;
  revPlantItemBudget?: Maybe<Scalars['JSON']>;
  revLabourItemBudget?: Maybe<Scalars['JSON']>;
  revMiscItemBudget?: Maybe<Scalars['JSON']>;
  doSumPerCC?: Maybe<Scalars['JSON']>;
  originalBudgetLabourSum?: Maybe<Scalars['Float']>;
  originalBudgetMaterialSum?: Maybe<Scalars['Float']>;
  originalBudgetMiscSum?: Maybe<Scalars['Float']>;
  originalBudgetPlantSum?: Maybe<Scalars['Float']>;
  originalBudgetSubcontractSum?: Maybe<Scalars['Float']>;
  subconCommCost?: Maybe<Scalars['Float']>;
  subconBalCommCost?: Maybe<Scalars['Float']>;
  subcontractClaimSum?: Maybe<Scalars['Float']>;
  materialCommCost?: Maybe<Scalars['Float']>;
  labourCommCost?: Maybe<Scalars['Float']>;
  plantCommCost?: Maybe<Scalars['Float']>;
  miscCommCost?: Maybe<Scalars['Float']>;
  subconCostToDate?: Maybe<Scalars['Float']>;
  materialCostToDate?: Maybe<Scalars['Float']>;
  plantCostToDate?: Maybe<Scalars['Float']>;
  labourCostToDate?: Maybe<Scalars['Float']>;
  miscCostToDate?: Maybe<Scalars['Float']>;
  totalCostToDate?: Maybe<Scalars['Float']>;
  subconBalToSpend?: Maybe<Scalars['Float']>;
  materialBalToSpend?: Maybe<Scalars['Float']>;
  materialBalToSpendBudgeting?: Maybe<Scalars['Float']>;
  plantBalToSpend?: Maybe<Scalars['Float']>;
  plantBalToSpendBudgeting?: Maybe<Scalars['Float']>;
  labourBalToSpend?: Maybe<Scalars['Float']>;
  labourBalToSpendBudgeting?: Maybe<Scalars['Float']>;
  miscBalToSpend?: Maybe<Scalars['Float']>;
  miscBalToSpendBudgeting?: Maybe<Scalars['Float']>;
  earnedValue?: Maybe<Scalars['Float']>;
  costVariance?: Maybe<Scalars['Float']>;
  cpi?: Maybe<Scalars['Float']>;
  completionEst?: Maybe<Scalars['Float']>;
  completionPerc?: Maybe<Scalars['Float']>;
  subcontractCommBalance?: Maybe<Scalars['Float']>;
  materialCommBalance?: Maybe<Scalars['Float']>;
  plantCommBalance?: Maybe<Scalars['Float']>;
  labourCommBalance?: Maybe<Scalars['Float']>;
  miscCommBalance?: Maybe<Scalars['Float']>;
  totalCommBalance?: Maybe<Scalars['Float']>;
  materialCostTotalBudgetAnalysis?: Maybe<Scalars['Float']>;
  plantCostTotalBudgetAnalysis?: Maybe<Scalars['Float']>;
  labourCostTotalBudgetAnalysis?: Maybe<Scalars['Float']>;
  miscCostTotalBudgetAnalysis?: Maybe<Scalars['Float']>;
};

export type AdvanceFromClient = {
  __typename?: 'advanceFromClient';
  advClientListing?: Maybe<Scalars['JSON']>;
  latestAdvClientDate?: Maybe<Scalars['DateTime']>;
  advClientAmt?: Maybe<Scalars['Float']>;
};

export type CostingAssociatedDetail = {
  __typename?: 'CostingAssociatedDetail';
  originalBudgetSubcontractAmt?: Maybe<Scalars['Float']>;
  revisedBudgetSubcontractAmt?: Maybe<Scalars['Float']>;
  originalBudgetMaterialAmt?: Maybe<Scalars['Float']>;
  revisedBudgetMaterialAmt?: Maybe<Scalars['Float']>;
  originalBudgetPlantAmt?: Maybe<Scalars['Float']>;
  revisedBudgetPlantAmt?: Maybe<Scalars['Float']>;
  originalBudgetLabourAmt?: Maybe<Scalars['Float']>;
  revisedBudgetLabourAmt?: Maybe<Scalars['Float']>;
  originalBudgetMiscAmt?: Maybe<Scalars['Float']>;
  revisedBudgetMiscAmt?: Maybe<Scalars['Float']>;
  subContractCommittedCostAmt?: Maybe<Scalars['Float']>;
  materialCommittedCostAmt?: Maybe<Scalars['Float']>;
  plantCommittedCostAmt?: Maybe<Scalars['Float']>;
  labourCommittedCostAmt?: Maybe<Scalars['Float']>;
  miscCommittedCostAmt?: Maybe<Scalars['Float']>;
  balancedToSpendSubcon?: Maybe<Scalars['Float']>;
  balancedToSpendMaterial?: Maybe<Scalars['Float']>;
  balancedToSpendPlant?: Maybe<Scalars['Float']>;
  balancedToSpendLabour?: Maybe<Scalars['Float']>;
  balancedToSpendMisc?: Maybe<Scalars['Float']>;
  latestDate?: Maybe<Scalars['DateTime']>;
};

export type FinancialStatusDetail = {
  __typename?: 'FinancialStatusDetail';
  completionPercentage?: Maybe<Scalars['JSON']>;
  collectionListing?: Maybe<Scalars['JSON']>;
  advClientListing?: Maybe<Scalars['JSON']>;
  latestAdvClientDate?: Maybe<Scalars['DateTime']>;
  advClientAmt?: Maybe<Scalars['Float']>;
  collectionSum?: Maybe<Scalars['Float']>;
  latestCollectionDate?: Maybe<Scalars['DateTime']>;
  conBilling?: Maybe<Scalars['JSON']>;
  latestBillingDate?: Maybe<Scalars['DateTime']>;
  billToDateSum?: Maybe<Scalars['Float']>;
  subconPaymentRefund?: Maybe<Scalars['JSON']>;
  subconPaymentRefundSum?: Maybe<Scalars['Float']>;
  latestSubconPaymentRefundDate?: Maybe<Scalars['JSON']>;
  subconPayment?: Maybe<Scalars['JSON']>;
  subconPaymentSum?: Maybe<Scalars['Float']>;
  latestSubconPaymentDate?: Maybe<Scalars['JSON']>;
  subconRefund?: Maybe<Scalars['JSON']>;
  subconRefundSum?: Maybe<Scalars['Float']>;
  latestSubconRefundDate?: Maybe<Scalars['JSON']>;
  supplierPayment?: Maybe<Scalars['JSON']>;
  supplierPaymentStatus?: Maybe<Scalars['JSON']>;
  supplierPaymentSum?: Maybe<Scalars['Float']>;
  supplierPaymentSumStatus?: Maybe<Scalars['Float']>;
  latestSupplierPaymentDate?: Maybe<Scalars['DateTime']>;
  latestSupplierPaymentDateStatus?: Maybe<Scalars['DateTime']>;
  miscExpense?: Maybe<Scalars['JSON']>;
  miscExpSum?: Maybe<Scalars['Float']>;
  miscExpenseStatus?: Maybe<Scalars['JSON']>;
  miscExpSumStatus?: Maybe<Scalars['Float']>;
  latestMiscExpDate?: Maybe<Scalars['DateTime']>;
  latestMiscExpStatusDate?: Maybe<Scalars['DateTime']>;
  subconBilling?: Maybe<Scalars['JSON']>;
  subconBillingSum?: Maybe<Scalars['Float']>;
  latestSubconBillDate?: Maybe<Scalars['DateTime']>;
  supplierInvoice?: Maybe<Scalars['JSON']>;
  supplierInvSum?: Maybe<Scalars['Float']>;
  latestSupplierInvDate?: Maybe<Scalars['DateTime']>;
  costToDateSum?: Maybe<Scalars['Float']>;
  paymentToDateSum?: Maybe<Scalars['Float']>;
  refundToDateSum?: Maybe<Scalars['Float']>;
  mainBilling?: Maybe<Scalars['Float']>;
  mainCost?: Maybe<Scalars['Float']>;
};

export type PlDetail = {
  __typename?: 'PLDetail';
  subconCostTotal?: Maybe<Scalars['Float']>;
  materialCostTotal?: Maybe<Scalars['Float']>;
  labourCostTotal?: Maybe<Scalars['Float']>;
  miscCostTotal?: Maybe<Scalars['Float']>;
  plantCostTotal?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  progressIncome?: Maybe<Scalars['Float']>;
  origContractSum?: Maybe<Scalars['Float']>;
  voSum?: Maybe<Scalars['Float']>;
  revisedContractSum?: Maybe<Scalars['Float']>;
  totalPL?: Maybe<Scalars['Float']>;
  grossTotal?: Maybe<Scalars['Float']>;
  totalProgressClaim?: Maybe<Scalars['Float']>;
  subcontractClaimSum?: Maybe<Scalars['Float']>;
  totalSubconSum?: Maybe<Scalars['Float']>;
  voListing?: Maybe<Scalars['JSON']>;
  materialInvListing?: Maybe<Scalars['JSON']>;
  labourInvListing?: Maybe<Scalars['JSON']>;
  plantInvListing?: Maybe<Scalars['JSON']>;
  miscInvListing?: Maybe<Scalars['JSON']>;
  subconClaimListing?: Maybe<Scalars['JSON']>;
  doSumPerCC?: Maybe<Scalars['JSON']>;
  progressClaimListing?: Maybe<Scalars['JSON']>;
  materialDOListing?: Maybe<Scalars['JSON']>;
  labourDOListing?: Maybe<Scalars['JSON']>;
  plantDOListing?: Maybe<Scalars['JSON']>;
  miscDOListing?: Maybe<Scalars['JSON']>;
};

export type Vo = {
  __typename?: 'VO';
  voSum?: Maybe<Scalars['Float']>;
  voOmission?: Maybe<Scalars['Float']>;
  contractSum?: Maybe<Scalars['Float']>;
  revisedContractSum?: Maybe<Scalars['Float']>;
  totalVoAmt?: Maybe<Scalars['Float']>;
};

export type DigitalDocuments = AuditEntity & {
  __typename?: 'DigitalDocuments';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  mediaType: Scalars['String'];
  refTable: RefTable;
  documentFolderID?: Maybe<Scalars['String']>;
  documentsFolder?: Maybe<DocumentFolderEntity>;
  associateID?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileURL?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  bucketFileName?: Maybe<Scalars['String']>;
  opacity?: Maybe<Scalars['String']>;
  isPrivate: Scalars['Boolean'];
  size: Scalars['String'];
};

export type GlMonth = {
  __typename?: 'GLMonth';
  start_date?: Maybe<Scalars['DateTime']>;
  end_date?: Maybe<Scalars['DateTime']>;
};

export type BudgetInfo = {
  __typename?: 'BudgetInfo';
  isExceeded?: Maybe<Scalars['Boolean']>;
  isAllocated?: Maybe<Scalars['Boolean']>;
  budgetAmt?: Maybe<Scalars['Float']>;
  totalAmt?: Maybe<Scalars['Float']>;
};

export type GrnItem = {
  __typename?: 'GRNItem';
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  productCode?: Maybe<Scalars['String']>;
  uom?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
};

export type GoodReceiveNoteRpaResult = {
  __typename?: 'GoodReceiveNoteRPAResult';
  customerAddress?: Maybe<Scalars['String']>;
  customerAddressRecipient?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  grnDate?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  invoiceDate?: Maybe<Scalars['String']>;
  invoiceID?: Maybe<Scalars['String']>;
  invoiceTotal?: Maybe<Scalars['Float']>;
  purchaseOrder?: Maybe<Scalars['String']>;
  remittanceAddressRecipient?: Maybe<Scalars['String']>;
  subTotal?: Maybe<Scalars['Float']>;
  vendorAddress?: Maybe<Scalars['String']>;
  vendorName?: Maybe<Scalars['String']>;
  invoiceItems?: Maybe<Array<GrnItem>>;
};

export type InvoiceRpaResult = {
  __typename?: 'InvoiceRPAResult';
  invoiceDate?: Maybe<Scalars['String']>;
  invoiceNo?: Maybe<Scalars['String']>;
  purchaseOrder?: Maybe<Scalars['String']>;
};

export type DeliveryOrder = {
  __typename?: 'DeliveryOrder';
  doNo?: Maybe<Scalars['String']>;
};

export type SubconVo = {
  __typename?: 'subconVO';
  voSum?: Maybe<Scalars['Float']>;
  voOmission?: Maybe<Scalars['Float']>;
  subcontractSum?: Maybe<Scalars['Float']>;
  revisedSubcontractSum?: Maybe<Scalars['Float']>;
  totalVoAmt?: Maybe<Scalars['Float']>;
  totalPendingVo?: Maybe<Scalars['Float']>;
};

export type PrItemInfo = {
  __typename?: 'prItemInfo';
  prItemID?: Maybe<Scalars['String']>;
  taxCode?: Maybe<Scalars['String']>;
  purchaseReqID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  uomCode?: Maybe<Scalars['String']>;
  rfqItemID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  siteName?: Maybe<Scalars['String']>;
  negoSupplyItemID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  costItemName?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  requestedQty?: Maybe<Scalars['Float']>;
  outstandingQty?: Maybe<Scalars['Float']>;
  contactPerson?: Maybe<Scalars['JSON']>;
  address?: Maybe<Scalars['JSON']>;
  prDate?: Maybe<Scalars['DateTime']>;
  docDate?: Maybe<Scalars['DateTime']>;
  expectedDate?: Maybe<Scalars['DateTime']>;
  negotiatedItemStatus?: Maybe<Scalars['Boolean']>;
  supplier?: Maybe<Array<SupplierEntity>>;
};

export type WageAdvanceLedger = {
  __typename?: 'WageAdvanceLedger';
  docAmt?: Maybe<Scalars['Float']>;
  docDate?: Maybe<Scalars['String']>;
  certHour?: Maybe<Scalars['Float']>;
  timeIn?: Maybe<Scalars['String']>;
  timeOut?: Maybe<Scalars['String']>;
};

export type AuditEntity = {
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
};

export type CreateAuditInput = {
  createdBy?: Maybe<Scalars['String']>;
};

export type UpdateAuditInput = {
  modBy?: Maybe<Scalars['String']>;
};

export type BaseInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type DocumentFolderInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  siteID: Scalars['String'];
};

export type DocumentsInput = {
  fileType?: Maybe<FileType>;
  refTable?: Maybe<RefTable>;
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  attachment?: Maybe<Scalars['Upload']>;
  status?: Maybe<CommonStatus>;
  bucketFileName?: Maybe<Scalars['String']>;
  fileURL?: Maybe<Scalars['String']>;
};

export enum FileType {
  Image = 'IMAGE',
  Logo = 'LOGO',
  Pdf = 'PDF'
}

export type AddressInput = {
  siteName?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  AddressType?: Maybe<AddressType>;
};

export type ContactPersonInput = {
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type BankDetailsInput = {
  name?: Maybe<Scalars['String']>;
  acctHolder?: Maybe<Scalars['String']>;
  acctNo?: Maybe<Scalars['String']>;
  branch?: Maybe<Scalars['String']>;
  swiftCode?: Maybe<Scalars['String']>;
};

export type TaxDetailsInput = {
  taxRefNo?: Maybe<Scalars['String']>;
  taxExpDate?: Maybe<Scalars['DateTime']>;
};

export type ExtensionDateInput = {
  remarks?: Maybe<Scalars['String']>;
  cncDate?: Maybe<Scalars['DateTime']>;
  extensionDate?: Maybe<Scalars['DateTime']>;
  eDocument?: Maybe<Scalars['String']>;
};

export type CidbDetailsInput = {
  registrationNo?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
};

export type PkkDetailsInput = {
  registrationNo?: Maybe<Scalars['String']>;
  class?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
};

export type BudgetAmtInput = {
  budgetAmt?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdT?: Maybe<Scalars['DateTime']>;
  modTs?: Maybe<Scalars['DateTime']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type TaxEffectiveInput = {
  date?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
};

export type ExchangeLogInput = {
  exchangeRate?: Maybe<Scalars['Float']>;
  modTs?: Maybe<Scalars['DateTime']>;
  modBy?: Maybe<Scalars['DateTime']>;
};

export type ActivityLogInput = {
  remarks?: Maybe<Scalars['String']>;
  logDate?: Maybe<Scalars['DateTime']>;
  defectStatus?: Maybe<DefectStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type RoleUserInput = {
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type DoGrnMatchInput = {
  ID?: Maybe<Scalars['String']>;
  doID?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  doAmt?: Maybe<Scalars['Float']>;
  doDate?: Maybe<Scalars['DateTime']>;
};

export type DefectLogInput = {
  costCategoryID?: Maybe<Scalars['Float']>;
  contractID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdT?: Maybe<Scalars['DateTime']>;
  defectStatus?: Maybe<DefectStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type PnmCostCatInput = {
  quantity?: Maybe<Scalars['Boolean']>;
  roadTax?: Maybe<Scalars['Boolean']>;
  warranty?: Maybe<Scalars['Boolean']>;
  insurance?: Maybe<Scalars['Boolean']>;
};

export type TrackingLogInput = {
  cost?: Maybe<Scalars['Float']>;
  returnQty?: Maybe<Scalars['Float']>;
  returnDate?: Maybe<Scalars['DateTime']>;
  returnRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['DateTime']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  trackingLogStatus?: Maybe<Scalars['String']>;
};

export type ApprovalLogInput = {
  approvalID?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['DateTime']>;
  remarks?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};

export type HolidayTypeRateInput = {
  holidayTypeID: Scalars['String'];
  rate: Scalars['Float'];
};

export type WorkerHourInput = {
  ID: Scalars['String'];
  workerID?: Maybe<Scalars['String']>;
  verifiedHour: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
};

export type WorkerWageCycleInput = {
  workerID: Scalars['String'];
  totalWage: Scalars['Float'];
};

export type WeekendInput = {
  monday?: Maybe<Scalars['Boolean']>;
  tuesday?: Maybe<Scalars['Boolean']>;
  wednesday?: Maybe<Scalars['Boolean']>;
  thursday?: Maybe<Scalars['Boolean']>;
  friday?: Maybe<Scalars['Boolean']>;
  saturday?: Maybe<Scalars['Boolean']>;
  sunday?: Maybe<Scalars['Boolean']>;
};

export type ApprovalListInput = {
  stepNo?: Maybe<Scalars['Float']>;
  roleID?: Maybe<Scalars['String']>;
  predicate?: Maybe<Scalars['String']>;
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
};

export type ReminderDetailInput = {
  reminderSeq?: Maybe<Scalars['Float']>;
  numOfReminder?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

export type RoleInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
};

export type CreatePermRoleAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  permId: Scalars['String'];
  roleId: Scalars['String'];
};

export type EntityRoleUserAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type ListEntityRoleUserAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  roleUserIDs: Array<RoleUserInput>;
};

export type ApprovalPolicyList = {
  stepNo?: Maybe<Scalars['Float']>;
  predicate?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  roleID?: Maybe<Scalars['String']>;
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
};

export type ApprovalPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  category?: Maybe<Scalars['String']>;
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  approvalList: Array<ApprovalPolicyList>;
};

export type ApprovalPolicyDeleteInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type ApprovalPolicyAssignmentInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  approvalPolicyID?: Maybe<Scalars['String']>;
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  approvalList: Array<ApprovalPolicyList>;
  contractID?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
};

export type ApprovalPolicyAssignmentDeleteInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  listlength?: Maybe<Scalars['Float']>;
  contractID?: Maybe<Scalars['String']>;
};

export type LedgerInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  debitRefTable?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
  creditRefTable?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
  docAmt?: Maybe<Scalars['Float']>;
  allocationAmt?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  allocationStatus?: Maybe<AllocationStatus>;
  doStatus?: Maybe<DoStatus>;
};

export type LedgerPdfInput = {
  refID: Scalars['String'];
  refTable: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  file: Array<Scalars['Upload']>;
};

export type DocRefInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type ForexInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
};

export type CoreTransactionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  deductionTypeID?: Maybe<Scalars['String']>;
};

export type CoreReceiptPaymentInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt: Scalars['Float'];
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  bankDetails?: Maybe<BankDetailsInput>;
  bankAccountID: Scalars['String'];
  receiptStatus: ReceiptStatus;
  rejectedDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
};

export type CoreClaimInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  deductionTypeID?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  submittedDate?: Maybe<Scalars['String']>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  previousMosAmt?: Maybe<Scalars['Float']>;
  submittedMosAmt?: Maybe<Scalars['Float']>;
  currentMosAmt?: Maybe<Scalars['Float']>;
  retentionAmt?: Maybe<Scalars['Float']>;
  submittedRetentionAmt?: Maybe<Scalars['Float']>;
  balanceRetentionAmt?: Maybe<Scalars['Float']>;
  submittedDayWorkAmt?: Maybe<Scalars['Float']>;
  currentDayWorkAmt?: Maybe<Scalars['Float']>;
  currentAdvRecoupmentAmt?: Maybe<Scalars['Float']>;
  currentAdvBalance?: Maybe<Scalars['Float']>;
  currentDeductionAmt?: Maybe<Scalars['Float']>;
  currentDeductionBalance?: Maybe<Scalars['Float']>;
  submittedDeductionAmt?: Maybe<Scalars['Float']>;
  voDisplayAmt?: Maybe<Scalars['Float']>;
};

export type CoreClaimRetentionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docDate: Scalars['DateTime'];
  retentionAmt: Scalars['Float'];
  taxSchemeID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmt: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CancelInput = {
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
};

export type RejectInput = {
  rejectedDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
};

export type ContactInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  associateID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
};

export type DocTypeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docName: Scalars['String'];
  code: Scalars['String'];
  docCode: ContractDocNum;
};

export type DocNumInput = {
  contractID?: Maybe<Scalars['String']>;
  nextNumber?: Maybe<Scalars['Float']>;
  prefix?: Maybe<Scalars['String']>;
  numberLength?: Maybe<Scalars['Float']>;
  runNumID?: Maybe<Scalars['String']>;
  numFormatID?: Maybe<Scalars['String']>;
};

export type NumberFormatInput = {
  softwareID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  documentDivider?: Maybe<Scalars['String']>;
  prefixExtra?: Maybe<Scalars['String']>;
  docTypeID: Scalars['String'];
  numberFormat: Scalars['String'];
  numberLength: Scalars['Float'];
};

export type RunningNumberSetupInput = {
  numberFormatID: Scalars['String'];
  nextNumber: Scalars['Float'];
};

export type SAccountInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['DateTime']>;
  sstRegNo?: Maybe<Scalars['String']>;
  companyCode?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  currencyName?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  watermarkWording?: Maybe<Scalars['String']>;
  isoImplementation?: Maybe<Scalars['Boolean']>;
  watermarkImplementation?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Scalars['JSON']>;
  logoPosition?: Maybe<LogoPosition>;
  isoLogoPosition?: Maybe<LogoPosition>;
};

export type AdjustmentTypeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  code: Scalars['String'];
  description: Scalars['String'];
};

export type UserInput = {
  ID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  address?: Maybe<AddressInput>;
  superUser?: Maybe<Scalars['Boolean']>;
  avatar?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
};

export type PasswordInfo = {
  ID?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  confirmedPassword: Scalars['String'];
};

export type UpdateUserInput = {
  ID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  address?: Maybe<AddressInput>;
  superUser?: Maybe<Scalars['Boolean']>;
  avatar?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  confirmedPassword: Scalars['String'];
};

export type CorePolicyGuidelineInput = {
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  docRefTable: Scalars['String'];
};

export type CorePolicyAssignmentInput = {
  roleID: Scalars['String'];
  approvalLevel: ApprovalLevel;
};

export type CoreNotificationGuidelineInput = {
  roleID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
};

export type PolicyGuidelineInput = {
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  docRefTable: Scalars['String'];
};

export type PolicyAssignmentInput = {
  roleID: Scalars['String'];
  approvalLevel: ApprovalLevel;
};

export type NotificationGuidelineInput = {
  roleID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
};

export type UpdateProfitRecogFormulaInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  profitRecogFormulaID: Scalars['String'];
};

export type ProfitRecogAssignmentInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type ProfitRecogGenerationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  docDate: Scalars['DateTime'];
  remarks: Scalars['String'];
  percOfCompletion: Scalars['Float'];
  cost: Scalars['Float'];
  revenue: Scalars['Float'];
  profitRecogStatus?: Maybe<Scalars['String']>;
};

export type ProfitRecogGenParamAssgmtInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type ContractToDoInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  senderID: Scalars['String'];
  recipientID: Scalars['String'];
  toDoType: ToDoType;
  documentID: Scalars['String'];
  docRefTable: Scalars['String'];
  subject: Scalars['String'];
  body: Scalars['String'];
  toDoStatus: ToDoStatus;
};

export enum ToDoType {
  ArClaim = 'AR_CLAIM',
  ArCn = 'AR_CN',
  ArDn = 'AR_DN',
  ArMiscInv = 'AR_MISC_INV',
  ArPayment = 'AR_PAYMENT',
  ArReceipt = 'AR_RECEIPT',
  ApClaim = 'AP_CLAIM',
  ApCn = 'AP_CN',
  ApDn = 'AP_DN',
  ApMiscInv = 'AP_MISC_INV',
  ApPayment = 'AP_PAYMENT',
  ApReceipt = 'AP_RECEIPT',
  Po = 'PO',
  Pr = 'PR',
  Rfq = 'RFQ',
  Grn = 'GRN',
  Grtn = 'GRTN',
  Vo = 'VO',
  Svo = 'SVO',
  Df = 'DF',
  Default = 'DEFAULT',
  Rental = 'RENTAL',
  Assignment = 'ASSIGNMENT',
  Maintenance = 'MAINTENANCE'
}

export type ContractNewsInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  title: Scalars['String'];
  content: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  postStatus: PostStatus;
};

export type ContractNotificationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  senderID: Scalars['String'];
  recipientID: Scalars['String'];
  documentID: Scalars['String'];
  docRefTable: Scalars['String'];
  subject: Scalars['String'];
  body: Scalars['String'];
  readStatus: ReadStatus;
};

export type ContractNewsReadInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  newsID: Scalars['String'];
  userID: Scalars['String'];
};

export type WorkDeskInput = {
  ID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  statusName: Scalars['String'];
  refTable: Scalars['String'];
  approvalStatus: Scalars['String'];
  approveRejectDate: Scalars['DateTime'];
};

export type CashFlowInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  cashFlowSum: Scalars['Float'];
  inflowAmt: Scalars['Float'];
  outFlowAmt: Scalars['Float'];
  netCashFlowAmt: Scalars['Float'];
  projectedCashFlowAmt: Scalars['Float'];
};

export type CompletionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  completionPerc: Scalars['Float'];
  contractID?: Maybe<Scalars['String']>;
};

export type CoreConsubconInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  title: Scalars['String'];
  description: Scalars['String'];
  taxSchemeID: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  progressRetentionPerc: Scalars['Float'];
  maxRetentionPerc: Scalars['Float'];
  creditTerm: Scalars['Float'];
  ladAmt?: Maybe<Scalars['Float']>;
  ladRate?: Maybe<RateUomType>;
  claimRunNum?: Maybe<Scalars['Float']>;
  claimPrefix?: Maybe<Scalars['String']>;
  voRunNum?: Maybe<Scalars['Float']>;
  voPrefix?: Maybe<Scalars['String']>;
  targetProfitPerc?: Maybe<Scalars['Float']>;
  contactPerson?: Maybe<Array<ContactPersonInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreConsubconCertificateInput = {
  cmgdDate?: Maybe<Scalars['DateTime']>;
  cpcDate?: Maybe<Scalars['DateTime']>;
  dlpDate?: Maybe<Scalars['DateTime']>;
  currentSubmittedStatus?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreConsubconActionInput = {
  closureRemarks?: Maybe<Scalars['String']>;
  closureDate?: Maybe<Scalars['DateTime']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreConsubconExtensionInput = {
  extensionDate?: Maybe<Array<ExtensionDateInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreConsubconRetentionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  submittedDate: Scalars['String'];
  retentionAmt: Scalars['Float'];
  taxSchemeID: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreConsubconVoInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  description: Scalars['String'];
  architectNo: Scalars['String'];
  engineeringNo: Scalars['String'];
  submissionAmt: Scalars['Float'];
  submittedDate: Scalars['DateTime'];
  docDate?: Maybe<Scalars['DateTime']>;
  VOType?: Maybe<VoType>;
  VOStatus: VoStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreConsubconVoActionInput = {
  contractID?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  approvedAmt?: Maybe<Scalars['Float']>;
  docDate?: Maybe<Scalars['DateTime']>;
  rejectedDate?: Maybe<Scalars['DateTime']>;
  VOStatus: VoStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreConsubconVoDeleteInput = {
  ID?: Maybe<Scalars['String']>;
};

export type CoreConsubconGuarInsInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef: Scalars['String'];
  issuer: Scalars['String'];
  description: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['DateTime'];
  expiryDate: Scalars['DateTime'];
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreConsubconGuarInsCancellationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  remarks?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['DateTime']>;
  cancellationDate?: Maybe<Scalars['DateTime']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type ContractInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  title: Scalars['String'];
  description: Scalars['String'];
  taxSchemeID: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  progressRetentionPerc: Scalars['Float'];
  maxRetentionPerc: Scalars['Float'];
  creditTerm: Scalars['Float'];
  ladAmt?: Maybe<Scalars['Float']>;
  ladRate?: Maybe<RateUomType>;
  claimRunNum?: Maybe<Scalars['Float']>;
  claimPrefix?: Maybe<Scalars['String']>;
  voRunNum?: Maybe<Scalars['Float']>;
  voPrefix?: Maybe<Scalars['String']>;
  targetProfitPerc?: Maybe<Scalars['Float']>;
  contactPerson?: Maybe<Array<ContactPersonInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
  customerID: Scalars['String'];
  contractNo: Scalars['String'];
  contractDate: Scalars['DateTime'];
  address?: Maybe<Array<AddressInput>>;
  contractSum: Scalars['Float'];
  contractStatus?: Maybe<ContractStatus>;
  LADFile?: Maybe<Array<Scalars['Upload']>>;
};

export type ContractCertificateInput = {
  cmgdDate?: Maybe<Scalars['DateTime']>;
  cpcDate?: Maybe<Scalars['DateTime']>;
  dlpDate?: Maybe<Scalars['DateTime']>;
  currentSubmittedStatus?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type ContractActionInput = {
  closureRemarks?: Maybe<Scalars['String']>;
  closureDate?: Maybe<Scalars['DateTime']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  contractStatus: ContractStatus;
};

export type ContractExtensionInput = {
  extensionDate?: Maybe<Array<ExtensionDateInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type ContractRetentionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  submittedDate: Scalars['String'];
  retentionAmt: Scalars['Float'];
  taxSchemeID: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
  customerID: Scalars['String'];
};

export type PaymentReminderInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  contractID: Scalars['String'];
  customerID: Scalars['String'];
  reminderDate: Scalars['DateTime'];
};

export type DocReminderInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  paymentReminderID?: Maybe<Scalars['String']>;
  refTable: Scalars['String'];
  refID: Scalars['String'];
  reminderSeq: Scalars['Float'];
  balanceAmt: Scalars['Float'];
  dueDate: Scalars['DateTime'];
};

export type BillInterestInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
  docID: Scalars['String'];
  billDate: Scalars['DateTime'];
  billAmt: Scalars['Float'];
  interestAmt: Scalars['Float'];
  waiveAmt: Scalars['Float'];
  remarks: Scalars['String'];
};

export type CustomerReminderInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  customerID: Scalars['String'];
  paymentReminder1: Scalars['Float'];
  paymentReminder2: Scalars['Float'];
  paymentReminder3: Scalars['Float'];
  paymentReminder4: Scalars['Float'];
  minimumAmt: Scalars['Float'];
  reminderFor: ReminderFor;
  printedCopy: Scalars['Boolean'];
  emailCopy: Scalars['Boolean'];
};

export type ProjectHeaderInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  description: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  contractID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  registrationNo?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
};

export type SiteInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  stateID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
};

export type SiteDeleteInput = {
  ID: Scalars['String'];
};

export type DigitalDocumentInput = {
  associateID: Scalars['String'];
  refTable: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  file: Array<Scalars['Upload']>;
};

export type ContractGuaranteeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef: Scalars['String'];
  issuer: Scalars['String'];
  description: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['DateTime'];
  expiryDate: Scalars['DateTime'];
  file?: Maybe<Array<Scalars['Upload']>>;
  contractID: Scalars['String'];
  guaranteeTypeID: Scalars['String'];
  collateralAmt: Scalars['Float'];
};

export type ContractInsuranceInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef: Scalars['String'];
  issuer: Scalars['String'];
  description: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['DateTime'];
  expiryDate: Scalars['DateTime'];
  file?: Maybe<Array<Scalars['Upload']>>;
  contractID: Scalars['String'];
  insuranceTypeID: Scalars['String'];
};

export type ContractGuaranteeCancellationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  remarks?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['DateTime']>;
  cancellationDate?: Maybe<Scalars['DateTime']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  contractID?: Maybe<Scalars['String']>;
};

export type ContractInsuranceCancellationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  remarks?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['DateTime']>;
  cancellationDate?: Maybe<Scalars['DateTime']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  contractID?: Maybe<Scalars['String']>;
};

export type ProjectBudgetInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  costCategoryID: Scalars['String'];
  budgetAmt?: Maybe<Array<BudgetAmtInput>>;
};

export type ProjectBudgetDeleteInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type ContractVoInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  description: Scalars['String'];
  architectNo: Scalars['String'];
  engineeringNo: Scalars['String'];
  submissionAmt: Scalars['Float'];
  submittedDate: Scalars['DateTime'];
  docDate?: Maybe<Scalars['DateTime']>;
  VOType?: Maybe<VoType>;
  VOStatus: VoStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
  contractID: Scalars['String'];
};

export type ContractVoActionInput = {
  contractID?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  approvedAmt?: Maybe<Scalars['Float']>;
  docDate?: Maybe<Scalars['DateTime']>;
  rejectedDate?: Maybe<Scalars['DateTime']>;
  VOStatus: VoStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type ContractVoDeleteInput = {
  ID?: Maybe<Scalars['String']>;
};

export type ContractClaimInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  deductionTypeID?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  submittedDate?: Maybe<Scalars['String']>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  previousMosAmt?: Maybe<Scalars['Float']>;
  submittedMosAmt?: Maybe<Scalars['Float']>;
  currentMosAmt?: Maybe<Scalars['Float']>;
  retentionAmt?: Maybe<Scalars['Float']>;
  submittedRetentionAmt?: Maybe<Scalars['Float']>;
  balanceRetentionAmt?: Maybe<Scalars['Float']>;
  submittedDayWorkAmt?: Maybe<Scalars['Float']>;
  currentDayWorkAmt?: Maybe<Scalars['Float']>;
  currentAdvRecoupmentAmt?: Maybe<Scalars['Float']>;
  currentAdvBalance?: Maybe<Scalars['Float']>;
  currentDeductionAmt?: Maybe<Scalars['Float']>;
  currentDeductionBalance?: Maybe<Scalars['Float']>;
  submittedDeductionAmt?: Maybe<Scalars['Float']>;
  voDisplayAmt?: Maybe<Scalars['Float']>;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
};

export type ContractTransactionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  deductionTypeID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
  customerID: Scalars['String'];
};

export type ContractReceiptInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt: Scalars['Float'];
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  bankDetails?: Maybe<BankDetailsInput>;
  bankAccountID: Scalars['String'];
  receiptStatus: ReceiptStatus;
  rejectedDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
};

export type ClaimRetentionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docDate: Scalars['DateTime'];
  retentionAmt: Scalars['Float'];
  taxSchemeID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmt: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};

export type CoreAssociateInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  platformAccess?: Maybe<Scalars['Boolean']>;
  taxDetails?: Maybe<TaxDetailsInput>;
  address: AddressInput;
  associatedStatus?: Maybe<AssociatedStatus>;
  contactInput?: Maybe<Array<ContactInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CustomerInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  platformAccess?: Maybe<Scalars['Boolean']>;
  taxDetails?: Maybe<TaxDetailsInput>;
  address: AddressInput;
  associatedStatus?: Maybe<AssociatedStatus>;
  contactInput?: Maybe<Array<ContactInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CustomerDeleteInput = {
  ID: Scalars['String'];
};

export type FollowUpInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  customerID: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  contactPerson: ContactPersonInput;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type BankAccountInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  accountHolder: Scalars['String'];
  accountNo: Scalars['String'];
  contactNo: Scalars['String'];
  swiftCode: Scalars['String'];
  branch: Scalars['String'];
  address: AddressInput;
  contactPerson: Array<ContactPersonInput>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type BankAccountCancellationInput = {
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['DateTime']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type BankAccountDeleteInput = {
  ID: Scalars['String'];
};

export type GlInterfaceMasterInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  associationType: AssociationType;
  transactionType: TransactionType;
  debitAccountCode1: Scalars['String'];
  debitAccountCode2?: Maybe<Scalars['String']>;
  creditAccountCode1: Scalars['String'];
  creditAccountCode2?: Maybe<Scalars['String']>;
};

export enum AssociationType {
  ClientAccount = 'CLIENT_ACCOUNT',
  SubcontractorAccount = 'SUBCONTRACTOR_ACCOUNT',
  SupplierAccount = 'SUPPLIER_ACCOUNT',
  ProjectPurchasing = 'PROJECT_PURCHASING',
  PurchasingOnBehalf = 'PURCHASING_ON_BEHALF'
}

export enum TransactionType {
  Advances = 'ADVANCES',
  AdvanceRecoupment = 'ADVANCE_RECOUPMENT',
  Claims = 'CLAIMS',
  ClaimsRetention = 'CLAIMS_RETENTION',
  ClaimsMos = 'CLAIMS_MOS',
  ClaimsDaywork = 'CLAIMS_DAYWORK',
  ClaimReversal = 'CLAIM_REVERSAL',
  RetentionRelease = 'RETENTION_RELEASE',
  DebitNote = 'DEBIT_NOTE',
  CreditNote = 'CREDIT_NOTE',
  Receipt = 'RECEIPT',
  Refunds = 'REFUNDS',
  ClientDebitNotes = 'CLIENT_DEBIT_NOTES'
}

export type GlInterfaceDetailInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  glInterfaceMasterID: Scalars['String'];
  debitAccountCode1: Scalars['String'];
  debitAccountCode2?: Maybe<Scalars['String']>;
  creditAccountCode1: Scalars['String'];
  creditAccountCode2?: Maybe<Scalars['String']>;
};

export type GlInterfaceDynamicInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  taxTypeID?: Maybe<Scalars['String']>;
  adjustmentTypeID?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  accountCode: Scalars['String'];
};

export type GlTaxDynamicInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  taxTypeID: Scalars['String'];
  accountCode: Scalars['String'];
};

export type GlAdjustmentDynamicInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  adjustmentTypeID: Scalars['String'];
  accountCode: Scalars['String'];
};

export type GlInterfaceSetupDeleteInput = {
  ID: Scalars['String'];
};

export type CbExportInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};

export type CbExportDetailInput = {
  advCustomerID: Array<Scalars['String']>;
  advCustomerRefTable: Scalars['String'];
  recCustomerID: Array<Scalars['String']>;
  recCustomerRefTable: Scalars['String'];
  refundCustomerID: Array<Scalars['String']>;
  refundCustomerRefTable: Scalars['String'];
  advSubcontractorID: Array<Scalars['String']>;
  advSubcontractorRefTable: Scalars['String'];
  recSubcontractorID: Array<Scalars['String']>;
  recSubcontractorRefTable: Scalars['String'];
  refundSubcontractorID: Array<Scalars['String']>;
  refundSubcontractorRefTable: Scalars['String'];
  advSupplierID: Array<Scalars['String']>;
  advSupplierRefTable: Scalars['String'];
  recSupplierID: Array<Scalars['String']>;
  recSupplierRefTable: Scalars['String'];
  refundSupplierID: Array<Scalars['String']>;
  refundSupplierRefTable: Scalars['String'];
};

export type CbLedgerInput = {
  IDs: Array<Scalars['String']>;
  docRefTable: Scalars['String'];
};

export type CbExportDeleteInput = {
  ID: Scalars['String'];
};

export type GlExportInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  transactionDate: Scalars['String'];
};

export type GlExportDetailInput = {
  accountCode: Scalars['String'];
  type: Scalars['String'];
  amount: Scalars['Float'];
  negative: Scalars['Boolean'];
  associateRefTable: Scalars['String'];
  claimColumn?: Maybe<Scalars['String']>;
};

export type GlLedgerInput = {
  IDs: Array<Scalars['String']>;
  docRefTable: Scalars['String'];
  GLExportDetailInput: Array<GlExportDetailInput>;
};

export type GlExportDeleteInput = {
  ID: Scalars['String'];
};

export type GlDetailLedgerBatchInput = {
  IDs: Array<Scalars['String']>;
  docRefTable: Scalars['String'];
};

export type GuarInsTypeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type GuarInsTypeDeleteInput = {
  ID: Scalars['String'];
};

export type MiscExpenseInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  costCategoryID: Scalars['String'];
  docRef: Scalars['String'];
  baseAmt: Scalars['Float'];
  docAmt: Scalars['Float'];
  taxSchemeID?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docDate?: Maybe<Scalars['DateTime']>;
  transactionDate?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<MiscExpenseStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type MiscExpenseCancellationInput = {
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['DateTime']>;
  contractID: Scalars['String'];
};

export type CostCenterInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  code: Scalars['String'];
  description: Scalars['String'];
  costCenterType?: Maybe<CostCenterType>;
};

export type CostCenterDeleteInput = {
  ID: Scalars['String'];
};

export type CurrencyExchangeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  fromCurrencyID?: Maybe<Scalars['String']>;
  toCurrencyID?: Maybe<Scalars['String']>;
  effectiveDate?: Maybe<Scalars['DateTime']>;
  fromAmt?: Maybe<Scalars['Float']>;
  toAmt?: Maybe<Scalars['Float']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  currencyLog?: Maybe<Array<ExchangeLogInput>>;
};

export type ConTaxTypeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type ConTaxSchemeInput = {
  ID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  taxTypeID?: Maybe<Scalars['String']>;
  taxCategory?: Maybe<TaxCategory>;
  taxClass?: Maybe<TaxClass>;
  commonStatus?: Maybe<CommonStatus>;
};

export type ConTaxEffectiveDateInput = {
  taxSchemeID: Scalars['String'];
  date: Scalars['DateTime'];
  description: Scalars['String'];
};

export type ConTaxEffectiveDateRateInput = {
  effectiveDate?: Maybe<Scalars['DateTime']>;
  taxRate?: Maybe<Scalars['Float']>;
};

export type ConTaxSchemeEffectiveInput = {
  ID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  taxTypeID?: Maybe<Scalars['String']>;
  taxCategory?: Maybe<TaxCategory>;
  taxClass?: Maybe<TaxClass>;
  commonStatus?: Maybe<CommonStatus>;
  taxSchemeID: Scalars['String'];
  effectiveDateRate: Array<ConTaxEffectiveDateRateInput>;
};

export type DocNumHeaderInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  sampleOutput: Scalars['String'];
};

export type DocNumHeaderDeleteInput = {
  ID: Scalars['String'];
};

export type DocNumDetailInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docNumHeaderID?: Maybe<Scalars['String']>;
  sequence: Scalars['Float'];
  formatType: Scalars['String'];
  formatValue: Scalars['String'];
  nextNumber?: Maybe<Scalars['Float']>;
  startFrom?: Maybe<Scalars['Float']>;
};

export type DocNumDetailDeleteInput = {
  ID: Scalars['String'];
};

export type ProfitRecogFormulaInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  title: Scalars['String'];
  percOfCompletion: Scalars['String'];
  cost: Scalars['String'];
  revenue: Scalars['String'];
};

export type ProfitRecogFormulaDeleteInput = {
  ID: Scalars['String'];
};

export type ProfitRecogParameterInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type ProfitRecogGlAccountCodeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  wipCostRecognized?: Maybe<Scalars['String']>;
  wipBillingRecognized?: Maybe<Scalars['String']>;
  revenuePL?: Maybe<Scalars['String']>;
  costPL?: Maybe<Scalars['String']>;
};

export type ProfitRecogGlJournalInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type SubcontractorInvitationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  subcontractTenderID: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type SubconRfqSubmissionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  subcontractorInvitationID?: Maybe<Scalars['String']>;
  submissionDate?: Maybe<Scalars['DateTime']>;
  quotationAmt?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  subcontractorID?: Maybe<Scalars['String']>;
  subcontractTenderID?: Maybe<Scalars['String']>;
  quotationNo?: Maybe<Scalars['String']>;
};

export type SubcontractTenderInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  refNo?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  budgetAmt?: Maybe<Scalars['Float']>;
  tenderDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  closeDate?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type SubconTenderGenerationInput = {
  subcontractorID: Scalars['String'];
  subcontractDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  taxSchemeID: Scalars['String'];
  creditTerm: Scalars['Float'];
  subcontractSum: Scalars['Float'];
  progressRetentionPerc: Scalars['Float'];
  maxRetentionPerc: Scalars['Float'];
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type SubcontractGuaranteeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef: Scalars['String'];
  issuer: Scalars['String'];
  description: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['DateTime'];
  expiryDate: Scalars['DateTime'];
  file?: Maybe<Array<Scalars['Upload']>>;
  subcontractID: Scalars['String'];
  guaranteeTypeID: Scalars['String'];
  collateralAmt: Scalars['Float'];
  guaranteeStatus?: Maybe<GrntInsStatus>;
  remarks?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['DateTime']>;
};

export type SubcontractInsuranceInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef: Scalars['String'];
  issuer: Scalars['String'];
  description: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['DateTime'];
  expiryDate: Scalars['DateTime'];
  file?: Maybe<Array<Scalars['Upload']>>;
  subcontractID: Scalars['String'];
  insuranceTypeID: Scalars['String'];
  insuranceStatus?: Maybe<GrntInsStatus>;
  remarks?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['DateTime']>;
};

export type SubcontractGuaranteeCancellationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  remarks?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['DateTime']>;
  cancellationDate?: Maybe<Scalars['DateTime']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  subcontractID?: Maybe<Scalars['String']>;
};

export type SubcontractInsuranceCancellationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  remarks?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['DateTime']>;
  cancellationDate?: Maybe<Scalars['DateTime']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  subcontractID?: Maybe<Scalars['String']>;
};

export type SubcontractInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  title: Scalars['String'];
  description: Scalars['String'];
  taxSchemeID: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  progressRetentionPerc: Scalars['Float'];
  maxRetentionPerc: Scalars['Float'];
  creditTerm: Scalars['Float'];
  ladAmt?: Maybe<Scalars['Float']>;
  ladRate?: Maybe<RateUomType>;
  claimRunNum?: Maybe<Scalars['Float']>;
  claimPrefix?: Maybe<Scalars['String']>;
  voRunNum?: Maybe<Scalars['Float']>;
  voPrefix?: Maybe<Scalars['String']>;
  targetProfitPerc?: Maybe<Scalars['Float']>;
  contactPerson?: Maybe<Array<ContactPersonInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
  subcontractorID: Scalars['String'];
  contractID: Scalars['String'];
  subcontractNo: Scalars['String'];
  subcontractDate: Scalars['DateTime'];
  siteID?: Maybe<Scalars['String']>;
  subcontractSum: Scalars['Float'];
  costCategoryID?: Maybe<Scalars['String']>;
  nominatedSC: Scalars['Boolean'];
  LADFile?: Maybe<Array<Scalars['Upload']>>;
};

export type SubcontractCertificateInput = {
  cmgdDate?: Maybe<Scalars['DateTime']>;
  cpcDate?: Maybe<Scalars['DateTime']>;
  dlpDate?: Maybe<Scalars['DateTime']>;
  currentSubmittedStatus?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type SubcontractActionInput = {
  closureRemarks?: Maybe<Scalars['String']>;
  closureDate?: Maybe<Scalars['DateTime']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  subcontractStatus: ContractStatus;
};

export type SubcontractExtensionInput = {
  extensionDate?: Maybe<Array<ExtensionDateInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type SubcontractRetentionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  submittedDate: Scalars['String'];
  retentionAmt: Scalars['Float'];
  taxSchemeID: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
  subcontractID: Scalars['String'];
  subcontractorID: Scalars['String'];
};

export type SubcontractVoInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  description: Scalars['String'];
  architectNo: Scalars['String'];
  engineeringNo: Scalars['String'];
  submissionAmt: Scalars['Float'];
  submittedDate: Scalars['DateTime'];
  docDate?: Maybe<Scalars['DateTime']>;
  VOType?: Maybe<VoType>;
  VOStatus: VoStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
  subcontractID: Scalars['String'];
  contractID: Scalars['String'];
};

export type SubcontractVoActionInput = {
  contractID?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  approvedAmt?: Maybe<Scalars['Float']>;
  docDate?: Maybe<Scalars['DateTime']>;
  rejectedDate?: Maybe<Scalars['DateTime']>;
  VOStatus: VoStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type SubcontractVoDeleteInput = {
  ID?: Maybe<Scalars['String']>;
};

export type SubcontractClaimInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  deductionTypeID?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  submittedDate?: Maybe<Scalars['String']>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  previousMosAmt?: Maybe<Scalars['Float']>;
  submittedMosAmt?: Maybe<Scalars['Float']>;
  currentMosAmt?: Maybe<Scalars['Float']>;
  retentionAmt?: Maybe<Scalars['Float']>;
  submittedRetentionAmt?: Maybe<Scalars['Float']>;
  balanceRetentionAmt?: Maybe<Scalars['Float']>;
  submittedDayWorkAmt?: Maybe<Scalars['Float']>;
  currentDayWorkAmt?: Maybe<Scalars['Float']>;
  currentAdvRecoupmentAmt?: Maybe<Scalars['Float']>;
  currentAdvBalance?: Maybe<Scalars['Float']>;
  currentDeductionAmt?: Maybe<Scalars['Float']>;
  currentDeductionBalance?: Maybe<Scalars['Float']>;
  submittedDeductionAmt?: Maybe<Scalars['Float']>;
  voDisplayAmt?: Maybe<Scalars['Float']>;
  subcontractorID: Scalars['String'];
  subcontractID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
};

export type SubcontractTransactionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  deductionTypeID?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
  subcontractorID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
};

export type SubcontractPaymentInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt: Scalars['Float'];
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  bankDetails?: Maybe<BankDetailsInput>;
  bankAccountID: Scalars['String'];
  receiptStatus: ReceiptStatus;
  rejectedDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  paymentTo?: Maybe<Scalars['String']>;
};

export type SubconClaimRetentionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docDate: Scalars['DateTime'];
  retentionAmt: Scalars['Float'];
  taxSchemeID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmt: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
  docNo?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};

export type SubcontractorInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  platformAccess?: Maybe<Scalars['Boolean']>;
  taxDetails?: Maybe<TaxDetailsInput>;
  address: AddressInput;
  associatedStatus?: Maybe<AssociatedStatus>;
  contactInput?: Maybe<Array<ContactInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
  ratingType: RatingType;
  cidbDetails: CidbDetailsInput;
  paymentTo?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Array<Scalars['String']>>;
  pkkDetails?: Maybe<PkkDetailsInput>;
  statusRemarks?: Maybe<Scalars['String']>;
  bankDetails?: Maybe<BankDetailsInput>;
  subconAppSelected?: Maybe<Scalars['Boolean']>;
  nominatedSC: Scalars['Boolean'];
  creditorAC?: Maybe<Scalars['String']>;
};

export type BulkSubcontractorInput = {
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  paymentTo?: Maybe<Scalars['String']>;
  registrationNo: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description: Scalars['String'];
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  platformAccess?: Maybe<Scalars['Boolean']>;
  taxDetails?: Maybe<TaxDetailsInput>;
  address: AddressInput;
  associatedStatus?: Maybe<AssociatedStatus>;
  contactInput?: Maybe<ContactInput>;
  ratingType: RatingType;
  cidbDetails: CidbDetailsInput;
  costCategoryID?: Maybe<Scalars['String']>;
  pkkDetails?: Maybe<PkkDetailsInput>;
  statusRemarks?: Maybe<Scalars['String']>;
  bankDetails?: Maybe<BankDetailsInput>;
  subconAppSelected?: Maybe<Scalars['Boolean']>;
  nominatedSC?: Maybe<Scalars['Boolean']>;
  creditorAC?: Maybe<Scalars['String']>;
};

export type UpdateSubcontractorInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name?: Maybe<Scalars['String']>;
  paymentTo?: Maybe<Scalars['String']>;
  registrationNo?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  nominatedSC: Scalars['Boolean'];
  ratingType?: Maybe<RatingType>;
  cidbDetails?: Maybe<CidbDetailsInput>;
  pkkDetails?: Maybe<PkkDetailsInput>;
  bankDetails?: Maybe<BankDetailsInput>;
  address?: Maybe<AddressInput>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type SubcontractorCCategoryInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  subcontractorID?: Maybe<Scalars['String']>;
  costCategoryID: Scalars['String'];
};

export type SubcontractorDeleteId = {
  ID?: Maybe<Scalars['String']>;
};

export type CostCategoryInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  costItems?: Maybe<Array<CostItems>>;
  costClass?: Maybe<CostClass>;
  pnmCostCat?: Maybe<PnmCostCatInput>;
};

export type CostItems = {
  costCategoryID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  onBehalf?: Maybe<Scalars['Boolean']>;
  markupPerc?: Maybe<Scalars['Float']>;
  code?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  variancePerc?: Maybe<Scalars['Float']>;
};

export type CostCategoryUpdateInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name?: Maybe<Scalars['String']>;
};

export type CostItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  costCategoryID: Scalars['String'];
  uomID: Scalars['String'];
  name: Scalars['String'];
  unitPrice: Scalars['Float'];
  onBehalf: Scalars['Boolean'];
  markupPerc?: Maybe<Scalars['Float']>;
  variancePerc?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type CostItemDeleteInput = {
  ID?: Maybe<Scalars['String']>;
};

export type ReturnInput = {
  returnDate?: Maybe<Scalars['DateTime']>;
  returnRemarks?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  cost?: Maybe<Scalars['Float']>;
  returnQty?: Maybe<Scalars['Float']>;
};

export type ReturnmaintenanceCostInput = {
  returnDate?: Maybe<Scalars['DateTime']>;
  returnRemarks?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  cost?: Maybe<Scalars['Float']>;
  returnQty?: Maybe<Scalars['Float']>;
};

export type PlantMachineryInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  supplierID?: Maybe<Scalars['String']>;
  costCategoryID: Scalars['String'];
  regNo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  purchaseDate: Scalars['DateTime'];
  quantity?: Maybe<Scalars['Float']>;
  availableQty?: Maybe<Scalars['Float']>;
  serialNo?: Maybe<Scalars['String']>;
  warrantyDate?: Maybe<Scalars['DateTime']>;
  roadTaxDate?: Maybe<Scalars['DateTime']>;
  insuranceDate?: Maybe<Scalars['DateTime']>;
  remarks?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  plantMachineryStatus?: Maybe<PlantMachineryStatus>;
};

export type AssignmentInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  plantMachineryID: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  costCategoryID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  assignedQty?: Maybe<Scalars['Float']>;
  assignmentDate?: Maybe<Scalars['DateTime']>;
  cost?: Maybe<Scalars['Float']>;
  assignmentRate: RateUomType;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type MaintenanceInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  plantMachineryID: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  usageReading: Scalars['Float'];
  cost: Scalars['Float'];
  assignedQty?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  workshopID?: Maybe<Scalars['String']>;
  assignmentID?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type WorkshopInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  contactNo: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  creditTerm?: Maybe<Scalars['Float']>;
  address?: Maybe<AddressInput>;
  contactPerson?: Maybe<Array<ContactPersonInput>>;
  workshopStatus?: Maybe<WorkshopStatus>;
  ratingType: RatingType;
};

export type PnmRentalInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  costCategoryID: Scalars['String'];
  name: Scalars['String'];
  regNo?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  cost: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  returnRemarks?: Maybe<Scalars['String']>;
  returnDate?: Maybe<Scalars['DateTime']>;
  plantMachineryStatus?: Maybe<Scalars['String']>;
  rentalRate: RateUomType;
  file?: Maybe<Array<Scalars['Upload']>>;
  supplierID: Scalars['String'];
};

export type DoInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  supplierID: Scalars['String'];
  doNo: Scalars['String'];
  siteID: Scalars['String'];
  doStatus: Scalars['String'];
  remarks: Scalars['String'];
  contractID: Scalars['String'];
  docDate?: Maybe<Scalars['String']>;
  contactPerson?: Maybe<ContactPersonInput>;
  deliveryAddress?: Maybe<AddressInput>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type UpdateDoInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contactPerson?: Maybe<ContactPersonInput>;
  supplierID?: Maybe<Scalars['String']>;
  siteID: Scalars['String'];
  docDate?: Maybe<Scalars['String']>;
  doNo: Scalars['String'];
  contractID: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type DoItemInput = {
  purchaseOrderID: Scalars['String'];
  POItemsInput: Array<PoItemsInput>;
};

export type PoItemsInput = {
  doItemID?: Maybe<Scalars['String']>;
  poItemID: Scalars['String'];
  acceptedQty: Scalars['Float'];
};

export type DOsInput = {
  purchaseOrderID: Scalars['String'];
  poItemID: Scalars['String'];
  receivedQty: Scalars['Float'];
};

export type DoRejectInput = {
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
};

export type GrtnItemInputGrn = {
  doItemID: Scalars['String'];
  DOItemInput: Array<DoItemInput>;
};

export type GoodReturnNoteInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  supplierID: Scalars['String'];
  siteID: Scalars['String'];
  contractID: Scalars['String'];
  docDate: Scalars['String'];
  docRef: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  grtnStatus?: Maybe<PurchaseStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type GrtnDeleteInput = {
  ID: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type GrtnItemInput = {
  grtnID?: Maybe<Scalars['String']>;
  doItemID: Scalars['String'];
  returnedQty: Scalars['Float'];
  replacement: Scalars['Boolean'];
};

export type GrtnItemDeleteInput = {
  ID: Scalars['String'];
};

export type GrtnRejectInput = {
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
};

export type NegotiatedSupplyInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  supplierID: Scalars['String'];
  contractID: Scalars['String'];
  docNo: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type NegotiatedSupplyItemInput = {
  ID?: Maybe<Scalars['String']>;
  costItemID: Scalars['String'];
  uomID: Scalars['String'];
  quantity: Scalars['Float'];
  unitPrice: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
};

export type NegoSupplyCancellationInput = {
  cancellationRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['DateTime']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type PurchaseReqInput = {
  purchaseType: PurchaseType;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  prDate?: Maybe<Scalars['DateTime']>;
  expectedDate: Scalars['DateTime'];
  siteID?: Maybe<Scalars['String']>;
  contactPerson: ContactPersonInput;
  creditTerm?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  instructions: Scalars['String'];
  requestedBy: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
  purchaseStatus?: Maybe<PurchaseStatus>;
};

export type PurchaseActionInput = {
  remarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['DateTime']>;
  cancelCloseDate?: Maybe<Scalars['String']>;
  purchaseStatus: PurchaseStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type PoItemInput = {
  createdBy?: Maybe<Scalars['String']>;
  orderedQty: Scalars['Float'];
  unitPrice: Scalars['Float'];
  prItemID?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  negotiatedSupplyItemID?: Maybe<Scalars['String']>;
  rfqItemID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  taxAmt?: Maybe<Scalars['Float']>;
  totalAmt?: Maybe<Scalars['Float']>;
};

export type PurchaseBudgetInput = {
  remarks: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type PurchaseOrderInput = {
  supplierID: Scalars['String'];
  totalAmt: Scalars['Float'];
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  purchaseReqID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['DateTime']>;
  purchaseStatus?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  purchaseType?: Maybe<PurchaseType>;
  contactPerson?: Maybe<Scalars['JSON']>;
  address?: Maybe<Scalars['JSON']>;
  poItems: Array<PoItemInput>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type PoCancellationInput = {
  supplierID: Scalars['String'];
  docDate?: Maybe<Scalars['DateTime']>;
  totalAmt: Scalars['Float'];
  instructions?: Maybe<Scalars['String']>;
  poItems: Array<PoItemInput>;
  remarks?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type PrInfo = {
  prItemID: Scalars['String'];
  totalAmt?: Maybe<Scalars['Float']>;
};

export type PrItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  costItemID: Scalars['String'];
  uomID: Scalars['String'];
  requestedQty: Scalars['Float'];
  markupPerc?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  negotiatedItemStatus?: Maybe<Scalars['Boolean']>;
};

export type RfqInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  purchaseReqID: Scalars['String'];
  description: Scalars['String'];
  expectedDate: Scalars['String'];
  contractID: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
  rfqStatus?: Maybe<PurchaseStatus>;
};

export type RfqEvalInput = {
  ID?: Maybe<Scalars['String']>;
  rfqStatus?: Maybe<PurchaseStatus>;
};

export type RfqItemInput = {
  ID?: Maybe<Scalars['String']>;
  prItemID: Scalars['String'];
  uomID: Scalars['String'];
  quantity: Scalars['Float'];
};

export type EvalSelectInput = {
  rfqItemID?: Maybe<Scalars['String']>;
  rfqSubmissionItemID?: Maybe<Scalars['String']>;
};

export type RfqRejectInput = {
  rejectedDate: Scalars['String'];
  remark: Scalars['String'];
};

export type RfqSupplierInvitationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  rfqID: Scalars['String'];
};

export type RfqSubmissionInput = {
  ID?: Maybe<Scalars['String']>;
  rfqID: Scalars['String'];
  supplierID: Scalars['String'];
  quotationNo: Scalars['String'];
  docAmt: Scalars['Float'];
  creditTerm: Scalars['Float'];
  submittedDate: Scalars['String'];
  effectiveDate: Scalars['String'];
  expiryDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  quotationStatus: Scalars['String'];
  purchaseStatus?: Maybe<PurchaseStatus>;
  rfqSubmissionItem: Array<RfqSubmissionItemInput>;
};

export type RfqSubmissionItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  rfqItemID: Scalars['String'];
  unitPrice: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  purchaseStatus?: Maybe<PurchaseStatus>;
};

export type SupplierInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  platformAccess?: Maybe<Scalars['Boolean']>;
  taxDetails?: Maybe<TaxDetailsInput>;
  address: AddressInput;
  associatedStatus?: Maybe<AssociatedStatus>;
  contactInput?: Maybe<Array<ContactInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
  bankDetails?: Maybe<BankDetailsInput>;
  ratingType: RatingType;
};

export type BulkSupplierInput = {
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description: Scalars['String'];
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  platformAccess?: Maybe<Scalars['Boolean']>;
  taxDetails?: Maybe<TaxDetailsInput>;
  address: AddressInput;
  associatedStatus?: Maybe<AssociatedStatus>;
  contactInput?: Maybe<ContactInput>;
  ratingType: RatingType;
  bankDetails?: Maybe<BankDetailsInput>;
  supplierAppSelected?: Maybe<Scalars['Boolean']>;
  creditorAC?: Maybe<Scalars['String']>;
};

export type SupplierDeleteInput = {
  ID: Scalars['String'];
};

export type SupplierCCategoryInput = {
  supplierID: Scalars['String'];
  costCategoryID: Scalars['String'];
};

export type ApTransactionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  deductionTypeID?: Maybe<Scalars['String']>;
  supplierID: Scalars['String'];
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  glItem?: Maybe<Array<ApInvoiceItemInput>>;
};

export type ApInvoiceInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  deductionTypeID?: Maybe<Scalars['String']>;
  supplierID: Scalars['String'];
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  glItem?: Maybe<Array<ApInvoiceItemInput>>;
  discountAmt?: Maybe<Scalars['Float']>;
  otherChargeAmt?: Maybe<Scalars['Float']>;
  doAmt?: Maybe<Scalars['Float']>;
};

export type ApInvoiceItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  doID?: Maybe<Scalars['String']>;
  doItemID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  docAmt?: Maybe<Scalars['Float']>;
  baseAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
};

export type ApPaymentInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt: Scalars['Float'];
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  bankDetails?: Maybe<BankDetailsInput>;
  bankAccountID: Scalars['String'];
  receiptStatus: ReceiptStatus;
  rejectedDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  supplierID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
};

export type UomInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type UomDeleteInput = {
  ID: Scalars['String'];
};

export type UomExchangeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  fromUomID?: Maybe<Scalars['String']>;
  toUomID?: Maybe<Scalars['String']>;
  fromAmt?: Maybe<Scalars['Float']>;
  toAmt?: Maybe<Scalars['Float']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  uomLog?: Maybe<Array<ExchangeLogInput>>;
};

export type SubcontractorHeaderInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  subcontractorName?: Maybe<Scalars['String']>;
  regNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  emailAddr?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
};

export type DefectInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  defectID?: Maybe<Scalars['String']>;
  siteID: Scalars['String'];
  costCategoryID?: Maybe<Scalars['String']>;
  reportedDate?: Maybe<Scalars['DateTime']>;
  expectedDate?: Maybe<Scalars['DateTime']>;
  completionDate?: Maybe<Scalars['DateTime']>;
  subcontractID?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  defectStatus?: Maybe<DefectStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type DefectAssignmentInput = {
  defectID?: Maybe<Scalars['String']>;
  costCategoryID: Scalars['String'];
  subcontractorID?: Maybe<Scalars['String']>;
  expectedDate?: Maybe<Scalars['String']>;
  assignmentDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  defectStatus?: Maybe<DefectStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type ReAssignDefectInput = {
  remarks?: Maybe<Scalars['String']>;
  defectStatus?: Maybe<DefectStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type DefectManagementInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  title: Scalars['String'];
  expectedDate?: Maybe<Scalars['DateTime']>;
  subcontractID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  defectStatus?: Maybe<DefectStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type ReOpenDefectInput = {
  remarks?: Maybe<Scalars['String']>;
  defectStatus?: Maybe<DefectStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type WorkerInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  siteID?: Maybe<Scalars['String']>;
  jobTypeID?: Maybe<Scalars['String']>;
  identityNo?: Maybe<Scalars['String']>;
  workPermitNo?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  wageRate?: Maybe<Scalars['Float']>;
  docExpiryDate?: Maybe<Scalars['DateTime']>;
  address?: Maybe<AddressInput>;
  wageType?: Maybe<WageType>;
  nationality?: Maybe<Nationality>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type WorkerDeleteInput = {
  ID: Scalars['String'];
};

export type WorkerAttendanceInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  workDate?: Maybe<Scalars['String']>;
  timeIn: Scalars['String'];
  timeOut?: Maybe<Scalars['String']>;
  workerID?: Maybe<Scalars['String']>;
  temperature: Scalars['Float'];
  siteID?: Maybe<Scalars['String']>;
  wageStatus?: Maybe<WageStatus>;
};

export type WorkerAttendanceDeleteInput = {
  ID: Scalars['String'];
};

export type GrnInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  siteID?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  cancelRejectDate?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  grnAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  totalAmt?: Maybe<Scalars['Float']>;
  grnStatus?: Maybe<DoStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type GrnDeleteInput = {
  ID: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type GrnItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  grnID?: Maybe<Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  totalAmt?: Maybe<Scalars['Float']>;
};

export type GrnItemDeleteInput = {
  ID: Scalars['String'];
};

export type StockMovementInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docDate?: Maybe<Scalars['String']>;
  doID?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  baseUomID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemark?: Maybe<Scalars['String']>;
  stockMovementType?: Maybe<StockMovementType>;
  stockMovementStatus?: Maybe<StockMovementStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type StockMovementDeleteInput = {
  ID: Scalars['String'];
};

export type SiteProgressInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docDate?: Maybe<Scalars['DateTime']>;
  contractID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type SiteStockLedgerInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  siteID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  doItemID?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  docRef?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  trackingDate?: Maybe<Scalars['DateTime']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  trxType?: Maybe<TrxType>;
};

export type SiteWorkerAttendanceInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  siteID?: Maybe<Scalars['String']>;
  timeIn?: Maybe<Scalars['String']>;
  timeOut?: Maybe<Scalars['String']>;
  siteWorkerID?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type SiteWorkersInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID?: Maybe<Scalars['String']>;
  siteID: Scalars['String'];
  name: Scalars['String'];
  nationality?: Maybe<Nationality>;
  contactNo: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<Scalars['JSON']>;
  timeIn?: Maybe<Scalars['DateTime']>;
  timeOut?: Maybe<Scalars['DateTime']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CalendarInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  state?: Maybe<Scalars['String']>;
  stateID?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  weekend?: Maybe<Scalars['JSON']>;
};

export type CalendarDeleteInput = {
  ID: Scalars['String'];
};

export type CalendarPhPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  publicHolidayID?: Maybe<Scalars['String']>;
  calendarID?: Maybe<Scalars['String']>;
};

export type CalendarPhPolicyDeleteInput = {
  ID: Scalars['String'];
};

export type CalendarWeekendPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  siteID?: Maybe<Scalars['String']>;
  calendarID?: Maybe<Scalars['String']>;
  daysOfWeek: DaysofWeek;
};

export type CalendarWeekendPolicyDeleteInput = {
  ID: Scalars['String'];
};

export type HolidayTypeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name?: Maybe<Scalars['String']>;
};

export type HolidayTypeDeleteInput = {
  ID: Scalars['String'];
};

export type JobTypeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name?: Maybe<Scalars['String']>;
  marketRate?: Maybe<Scalars['String']>;
};

export type JobTypeDeleteInput = {
  ID: Scalars['String'];
};

export type PublicHolidayInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['DateTime']>;
};

export type PublicHolidayDeleteInput = {
  ID: Scalars['String'];
};

export type SiteMaterialInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  siteID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['String']>;
  stockCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type SiteMaterialDeleteInput = {
  ID: Scalars['String'];
};

export type VideoCamInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  siteID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ip?: Maybe<Scalars['String']>;
};

export type VideoCamDeleteInput = {
  ID: Scalars['String'];
};

export type WagesRatePolicyInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  holidayTypeID?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Float']>;
};

export type WagesRatePolicyDeleteInput = {
  ID: Scalars['String'];
};

export type WorkingHourPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  shiftStartTime?: Maybe<Scalars['String']>;
  shiftEndTime?: Maybe<Scalars['String']>;
  halfStartTime?: Maybe<Scalars['String']>;
  halfEndTime?: Maybe<Scalars['String']>;
};

export type WorkingHourPolicyDeleteInput = {
  ID: Scalars['String'];
};

export type SiteImageInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  siteID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['DateTime']>;
  balanceQty?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type SiteImageDeleteInput = {
  ID: Scalars['String'];
};

export type WageCycleInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docDate: Scalars['DateTime'];
  siteID: Scalars['String'];
};

export type WageCycleDeleteInput = {
  ID: Scalars['String'];
};

export type WageCycleDetailInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  wageCycleID?: Maybe<Scalars['String']>;
  workerID?: Maybe<Scalars['String']>;
  wageAmt?: Maybe<Scalars['Float']>;
};

export type WageCycleDetailDeleteInput = {
  ID: Scalars['String'];
};

export type WorkerAdvanceInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  workerID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  wageCycleID?: Maybe<Scalars['String']>;
  modBy?: Maybe<Scalars['String']>;
};

export type WorkerAdvanceDeleteInput = {
  ID: Scalars['String'];
};

export type WorkerWageInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  siteID?: Maybe<Scalars['String']>;
  workerID?: Maybe<Scalars['String']>;
  workerAttendanceID?: Maybe<Scalars['String']>;
  workDate: Scalars['String'];
  workHour: Scalars['Float'];
  verifiedHour?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  wageCycleID?: Maybe<Scalars['String']>;
  wageStatus?: Maybe<WageStatus>;
  remarks?: Maybe<Scalars['String']>;
  modBy?: Maybe<Scalars['String']>;
};

export type WorkerWageDeleteInput = {
  ID: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  getRole: Array<RoleEntity>;
  getEntityRoleUserAssignment: Array<RoleUserEntAssignmentEntity>;
  getPolicyRole: Array<RoleEntity>;
  userRoleIDs: Array<UserRoleId>;
  getContractPermission: Scalars['JSON'];
  getSitePermission: Scalars['JSON'];
  getRolePermission: Scalars['JSON'];
  getUsersRoleContract: Scalars['JSON'];
  Check: Scalars['Boolean'];
  UserEntities: Array<Scalars['String']>;
  testPermission: Scalars['String'];
  siteTestPermission: Scalars['String'];
  testConPermission: Scalars['String'];
  testSampleEnum: Scalars['String'];
  getApprovalPolicyGuideline: Array<ApprovalPolicyEntity>;
  getApprovalPolicyAssignmentGuideline: Array<ApprovalPolicyAssignmentEntity>;
  getApprovalPolicy?: Maybe<Array<ApprovalPolicyEntity>>;
  getApprovalPolicyAssignment?: Maybe<Array<ApprovalPolicyAssignmentEntity>>;
  DocumentListing?: Maybe<Array<DocumentsEntity>>;
  cmgdAttachment?: Maybe<Array<DocumentsEntity>>;
  getCashFlowLineChartData: Scalars['JSON'];
  getCashFlowGraphData: Scalars['JSON'];
  getContact: Array<ContactEntity>;
  getNumberFormat: Array<ConNumberFormatEntity>;
  getDocumentType: Array<ConDocTypeEntity>;
  getDocType: Array<Scalars['JSON']>;
  fetchNextDocNum: Scalars['String'];
  fetchMultipleDocNum: Scalars['JSON'];
  getRunningNumberSetupWithLock: Scalars['Float'];
  /** if true, runningNum is editable */
  runningNumChecking: Scalars['Boolean'];
  getAdjustmentType: Array<AdjustmentTypeEntity>;
  getDocumentFolder?: Maybe<Array<DocumentFolderEntity>>;
  getSAccount: Array<SAccountEntity>;
  loggedInPlatformUserProfile: PlatformUserEntity;
  activePlatformUser: Scalars['Float'];
  isPlatformUserValid: Scalars['Boolean'];
  getPolicyGuideLine: Array<PolicyGuidelineEntity>;
  getPolicyRoleAssignment: Array<PolicyRoleAssignmentEntity>;
  getNotificationGuideLine: Array<NotificationGuidelineEntity>;
  getNotiRoleUserAssignment: Array<NotiRoleUserAssignmentEntity>;
  getProfitRecognition: Array<ProfitRecogFormulaEntity>;
  getContractListingProfitRecog: Scalars['JSON'];
  getProfitRecogGenerationListing: Scalars['JSON'];
  infoLineProfitRecog: Scalars['JSON'];
  profitRecognitionCalculation: Scalars['JSON'];
  C1_actualCost: Scalars['Float'];
  C2_budgetCost: Scalars['Float'];
  C3_actualRevenue: Scalars['Float'];
  C4_reviseContractSum: Scalars['Float'];
  C5_totalPreviousRecognisedRevenue: Scalars['Float'];
  C6_totalPreviousRecognisedCost: Scalars['Float'];
  C7_percOfCompletion: Scalars['Float'];
  getDate: Scalars['JSON'];
  submittedRetClaimChecking: Scalars['Boolean'];
  submittedClaimChecking?: Maybe<Scalars['JSON']>;
  approvedClaimChecking: ClaimSummary;
  cancellableClaims: Array<Scalars['String']>;
  approvableLedgerChecking: LedgerCheckingSummary;
  associateMenu: Scalars['JSON'];
  totalClients: Scalars['Float'];
  clientNotifications: Scalars['Float'];
  totalDebitClient: Scalars['Float'];
  totalCreditClient: Scalars['Float'];
  totalOutstandingClients: Scalars['Float'];
  totalOutstandingAmtClients?: Maybe<Scalars['Float']>;
  supplierProfileDate?: Maybe<Scalars['DateTime']>;
  PrefSupplierProductDate?: Maybe<Scalars['DateTime']>;
  SupplierAccDate?: Maybe<Scalars['DateTime']>;
  contractInfo: ContractInfo;
  contractGuarantee: Scalars['Float'];
  contractInsurance: Scalars['Float'];
  submittedVONotification: Scalars['Float'];
  contractVO: ContractVo;
  outstandingAdvances: Array<ContractAdvanceEntity>;
  outstandingClaims: Array<ContractClaimEntity>;
  outstandingMiscInvs: Array<ContractMiscInvoiceEntity>;
  retention: RetentionInfo;
  lastSiteProgressDate?: Maybe<Scalars['DateTime']>;
  lastMiscExpDate?: Maybe<Scalars['DateTime']>;
  lastExtensionDate?: Maybe<Scalars['String']>;
  contractClosureDate?: Maybe<Scalars['DateTime']>;
  subcontractInfo: SubontractInfo;
  subconGuarantee: Scalars['Float'];
  subconInsurance: Scalars['Float'];
  submittedVOSubconNotification: Scalars['Float'];
  subcontractVO: SubcontractVo;
  subconOutstandingAdvances: Array<SubcontractAdvanceEntity>;
  subconOutstandingClaims: Array<SubcontractClaimEntity>;
  subconLastExtensionDate?: Maybe<Scalars['String']>;
  subconClosureDate?: Maybe<Scalars['DateTime']>;
  latestDefectDate?: Maybe<Scalars['DateTime']>;
  latestAssignmentDate?: Maybe<Scalars['DateTime']>;
  siteWorkerAttendanceInfo: Scalars['JSON'];
  DOCount: Scalars['Float'];
  MOSCount: Scalars['Float'];
  costCategoryDates?: Maybe<Scalars['JSON']>;
  guaranteeTypeDate?: Maybe<Scalars['DateTime']>;
  insuranceTypeDate?: Maybe<Scalars['DateTime']>;
  workshopDate?: Maybe<Scalars['DateTime']>;
  clientAccDate?: Maybe<Scalars['DateTime']>;
  subconAccDate?: Maybe<Scalars['DateTime']>;
  supplierAccDate?: Maybe<Scalars['DateTime']>;
  purchaseAccDate?: Maybe<Scalars['DateTime']>;
  pobAccDate?: Maybe<Scalars['DateTime']>;
  bankAccDate?: Maybe<Scalars['DateTime']>;
  taxAccDate?: Maybe<Scalars['DateTime']>;
  tradeAccDate?: Maybe<Scalars['DateTime']>;
  materialAccDate?: Maybe<Scalars['DateTime']>;
  plantAccDate?: Maybe<Scalars['DateTime']>;
  labourAccDate?: Maybe<Scalars['DateTime']>;
  miscAccDate?: Maybe<Scalars['DateTime']>;
  journalAccDate?: Maybe<Scalars['DateTime']>;
  userDate: Scalars['DateTime'];
  roleDate?: Maybe<Scalars['DateTime']>;
  conAuthDate?: Maybe<Scalars['DateTime']>;
  docNumDate?: Maybe<Scalars['DateTime']>;
  bankAccountDate?: Maybe<Scalars['DateTime']>;
  taxSchemeDate?: Maybe<Scalars['DateTime']>;
  uomDate?: Maybe<Scalars['DateTime']>;
  uomConversionDate?: Maybe<Scalars['DateTime']>;
  companyDate?: Maybe<Scalars['DateTime']>;
  policyDate?: Maybe<Scalars['DateTime']>;
  notificationDate?: Maybe<Scalars['DateTime']>;
  costCenterDate?: Maybe<Scalars['DateTime']>;
  currencyExchangeDate?: Maybe<Scalars['DateTime']>;
  activeContractCount: Scalars['Float'];
  activeClientCount: Scalars['Float'];
  activeSubcontractCount: Scalars['Float'];
  activeSubcontractTenderCount: Scalars['Float'];
  activeSubcontractorCount: Scalars['Float'];
  activeSupplierCount: Scalars['Float'];
  lastPurchaseDate?: Maybe<Scalars['DateTime']>;
  activeProjectPurchase?: Maybe<Scalars['JSON']>;
  ownedPNMInfo: Scalars['JSON'];
  rentedPNMInfo: Scalars['JSON'];
  assignmentRecord: Scalars['Float'];
  maintenanceRecord: Scalars['Float'];
  clientProfileDate: Scalars['DateTime'];
  submittedCusAVNotification: Scalars['Float'];
  advancesInfo?: Maybe<Scalars['JSON']>;
  claimsInfo?: Maybe<Scalars['JSON']>;
  submittedClaimNotification: Scalars['Float'];
  progressClaimsInfo?: Maybe<Scalars['JSON']>;
  submittedRetClaimNotification: Scalars['Float'];
  retentionClaimsInfo?: Maybe<Scalars['JSON']>;
  submittedCNNotification: Scalars['Float'];
  CNInfo?: Maybe<Scalars['JSON']>;
  submittedDNNotification: Scalars['Float'];
  DNInfo?: Maybe<Scalars['JSON']>;
  submittedMiscInvNotification: Scalars['Float'];
  miscInvInfo?: Maybe<Scalars['JSON']>;
  submittedClientDNNotification: Scalars['Float'];
  clientDNInfo?: Maybe<Scalars['JSON']>;
  submittedClientCNNotification: Scalars['Float'];
  clientCNInfo?: Maybe<Scalars['JSON']>;
  submittedReceiptNotification: Scalars['Float'];
  receiptsInfo?: Maybe<Scalars['JSON']>;
  submittedRefundNotification: Scalars['Float'];
  refundsInfo?: Maybe<Scalars['JSON']>;
  outstandingClient?: Maybe<Scalars['JSON']>;
  unallocatedClient?: Maybe<Scalars['JSON']>;
  conClientProfileDate: Scalars['DateTime'];
  conAdvancesInfo?: Maybe<Scalars['JSON']>;
  conClaimsInfo?: Maybe<Scalars['JSON']>;
  conDNInfo?: Maybe<Scalars['JSON']>;
  conCNInfo?: Maybe<Scalars['JSON']>;
  conReceiptsInfo?: Maybe<Scalars['JSON']>;
  conRefundsInfo?: Maybe<Scalars['JSON']>;
  conClientDNInfo?: Maybe<Scalars['JSON']>;
  conOutstandingClient?: Maybe<Scalars['JSON']>;
  subconProfileDate?: Maybe<Scalars['DateTime']>;
  submittedSubconAVNotification: Scalars['Float'];
  subconAdvancesInfo?: Maybe<Scalars['JSON']>;
  subconClaimsInfo?: Maybe<Scalars['JSON']>;
  submittedSubconClaimNotification: Scalars['Float'];
  subconProgressClaimsInfo?: Maybe<Scalars['JSON']>;
  submittedSubconRetClaimNotification: Scalars['Float'];
  subconRetentionClaimsInfo?: Maybe<Scalars['JSON']>;
  submittedSubconMiscInvNotification: Scalars['Float'];
  subconMiscInvInfo?: Maybe<Scalars['JSON']>;
  submittedSubconDNNotification: Scalars['Float'];
  subconDNInfo?: Maybe<Scalars['JSON']>;
  submittedSubconCNNotification: Scalars['Float'];
  subconCNInfo?: Maybe<Scalars['JSON']>;
  submittedPaymentNotification: Scalars['Float'];
  subconPaymentInfo?: Maybe<Scalars['JSON']>;
  submittedSubconRefundNotification: Scalars['Float'];
  subconRefundsInfo?: Maybe<Scalars['JSON']>;
  submittedDNtoSubconNotification: Scalars['Float'];
  DNtoSubconInfo?: Maybe<Scalars['JSON']>;
  submittedCNtoSubconNotification: Scalars['Float'];
  CNtoSubconInfo?: Maybe<Scalars['JSON']>;
  outstandingSubcon?: Maybe<Scalars['JSON']>;
  unallocatedSubcon?: Maybe<Scalars['JSON']>;
  suppProfileDate: Scalars['DateTime'];
  submittedDepositNotification: Scalars['Float'];
  supplierDepositsInfo?: Maybe<Scalars['JSON']>;
  submittedSupplierInvNotification: Scalars['Float'];
  supplierInvInfo?: Maybe<Scalars['JSON']>;
  submittedSupplierDNNotification: Scalars['Float'];
  supplierDNInfo?: Maybe<Scalars['JSON']>;
  submittedSupplierCNNotification: Scalars['Float'];
  supplierCNInfo?: Maybe<Scalars['JSON']>;
  submittedDNToSupplierNotification: Scalars['Float'];
  DNtoSupplierInfo?: Maybe<Scalars['JSON']>;
  submittedCNToSupplierNotification: Scalars['Float'];
  CNtoSupplierInfo?: Maybe<Scalars['JSON']>;
  submittedSupplierPaymentNotification: Scalars['Float'];
  supplierPaymentsInfo?: Maybe<Scalars['JSON']>;
  submittedSupplierRefundNotification: Scalars['Float'];
  supplierRefundsInfo?: Maybe<Scalars['JSON']>;
  outstandingSupplier?: Maybe<Scalars['JSON']>;
  unallocatedSupplier?: Maybe<Scalars['JSON']>;
  negoItemInfo: Scalars['JSON'];
  negoItemCount: Scalars['Float'];
  lastPRDate?: Maybe<Scalars['DateTime']>;
  lastPRDateOB?: Maybe<Scalars['DateTime']>;
  lastPODate?: Maybe<Scalars['DateTime']>;
  lastPODateOB?: Maybe<Scalars['DateTime']>;
  lastRFQDate?: Maybe<Scalars['DateTime']>;
  lastDODate?: Maybe<Scalars['DateTime']>;
  lastGRTNDate?: Maybe<Scalars['DateTime']>;
  workerCount?: Maybe<Scalars['Float']>;
  workerAttendanceCount?: Maybe<Scalars['JSON']>;
  lastGRNDate?: Maybe<Scalars['DateTime']>;
  lastStockMovementDates?: Maybe<Scalars['JSON']>;
  lastStockMovementDate?: Maybe<Scalars['DateTime']>;
  siteImagesCount: Scalars['JSON'];
  videoCamCount: Scalars['Float'];
  getDailyRegisteredWorkHours?: Maybe<Scalars['JSON']>;
  getDailyWagesVerification?: Maybe<Scalars['JSON']>;
  getWagesBook?: Maybe<Scalars['JSON']>;
  wageCycleClosureInfo?: Maybe<Scalars['JSON']>;
  testPath: Scalars['JSON'];
  loggedInUserProfile: UserEntity;
  activeUser: Scalars['Float'];
  getUsersByAccount: Array<UserEntity>;
  getUser: UserEntity;
  getUsersbyIds: Array<UserEntity>;
  isUserValid: Scalars['Boolean'];
  getUsersBySoftware: Array<UserEntity>;
  getUsersByAccountAndSoftware: Array<UserEntity>;
  getSuperUsers: Array<UserEntity>;
  getContractNotification: Array<ContractNotificationEntity>;
  getContractNews: Array<ContractNewsEntity>;
  getContractNewsRead: Array<ContractNewsReadEntity>;
  getNotificationListing: Array<Scalars['JSON']>;
  getContractToDo: Array<ContractToDoEntity>;
  getToDoListing?: Maybe<Array<Scalars['JSON']>>;
  workDeskCount: Scalars['Float'];
  toDoCount: Scalars['Float'];
  notificationCount: Scalars['Float'];
  getToDoWorkflowSummary: Scalars['JSON'];
  getToDoAllPendingDocs: Scalars['JSON'];
  policyChecking?: Maybe<Scalars['JSON']>;
  policyCheckerAPPayment?: Maybe<Scalars['JSON']>;
  getNotificationWorkflowListing: Array<Scalars['JSON']>;
  getApprovalLog?: Maybe<Array<Scalars['JSON']>>;
  getContractPLAdjustmentTotal: Scalars['Float'];
  getSubcontractPLAdjustmentTotal: Scalars['Float'];
  getSupplierPLAdjustmentTotal: Scalars['Float'];
  getSubcontractPLAdjustmentStatusTotal: Scalars['Float'];
  getContractPLAdjustmentStatusTotal: Scalars['Float'];
  getSupplierPLAdjustmentStatusTotal: Scalars['Float'];
  getAdjustmentByCostClass: Scalars['JSON'];
  associateCalculation: AssociatedDetail;
  advanceClient: AdvanceFromClient;
  receivedFromClient: Scalars['JSON'];
  paidToSubCon: Scalars['JSON'];
  paidToSupplier: Scalars['JSON'];
  advancesOutflowAmt: Scalars['JSON'];
  miscExpense: Scalars['JSON'];
  osReceivableFromClient: Array<ContractClaimEntity>;
  clientLastReceipt?: Maybe<Scalars['DateTime']>;
  subconLastPayment?: Maybe<Scalars['DateTime']>;
  receivableClientLastPayment?: Maybe<Scalars['DateTime']>;
  outstandingAmtClient?: Maybe<Scalars['JSON']>;
  paidSubconSupplierListing: Scalars['JSON'];
  paidSubconSupplierAmount: Scalars['JSON'];
  OSReceivableFromClientListing: Scalars['JSON'];
  subconOSPayableAmount: Scalars['JSON'];
  OSPayableToSubconListing: Scalars['JSON'];
  supplierLastPayment?: Maybe<Scalars['DateTime']>;
  supplierOSPayableAmount: Scalars['JSON'];
  OSPayableToSupplierListing: Scalars['JSON'];
  outstandingReceivableFromClient?: Maybe<Scalars['JSON']>;
  outstandingReceivableFromClientListing?: Maybe<Scalars['JSON']>;
  outstandingPayableSubcon?: Maybe<Scalars['JSON']>;
  outstandingPayableSubconListing?: Maybe<Scalars['JSON']>;
  outstandingPayableSupplier?: Maybe<Scalars['JSON']>;
  outstandingPayableSupplierListing?: Maybe<Scalars['JSON']>;
  getCostingCalculation: Scalars['JSON'];
  financialStatus: FinancialStatusDetail;
  projectPL: PlDetail;
  getProjectCostingAmt: Scalars['JSON'];
  getProjectCostingDetail: Scalars['JSON'];
  getAdjustmentItemByCostCateg: Scalars['JSON'];
  getCostCategCount: Scalars['JSON'];
  getPOsByCategory: Array<PurchaseOrderEntity>;
  getDOsByCategory: Array<DoEntity>;
  getCommCostAmt: Scalars['JSON'];
  getDocReminder: Array<DocReminderEntity>;
  getBillInterest: Array<BillInterestEntity>;
  getStatementAcctMonths: Scalars['JSON'];
  getClaimRecListByMonth: Scalars['JSON'];
  getCustContractIDs: Scalars['JSON'];
  getPaymentReminder: Array<PaymentReminderEntity>;
  getPaymentReminderClaim: Scalars['JSON'];
  getDebitDoc: Scalars['JSON'];
  getCustomerReminder: Array<CustomerReminderEntity>;
  getContract: Array<ContractEntity>;
  contractVoCalculation: Scalars['JSON'];
  maxRetention: Scalars['Float'];
  associateChecking: Scalars['Boolean'];
  voChecking: Scalars['Boolean'];
  retentionChecking: Scalars['Boolean'];
  getContractWithActiveDO: Array<ContractEntity>;
  subcontractVOListing: Scalars['JSON'];
  getDigitalDocument: Scalars['JSON'];
  getTableDocuments: Array<DigitalDocuments>;
  totalDigitalDocuments: Scalars['JSON'];
  getContractGuarantee: Array<ContractGuaranteeEntity>;
  getContractInsurance: Array<ContractInsuranceEntity>;
  getProjectBudget: Array<ProjectBudgetEntity>;
  deleteBudgetChecking: Scalars['JSON'];
  budgetCalculation?: Maybe<Scalars['JSON']>;
  getContractVO: Array<ContractVoEntity>;
  getContractAllocation: Array<ContractAllocationEntity>;
  getContractAdvanceAllocation: Scalars['JSON'];
  getContractReceiptAllocation: Scalars['JSON'];
  getContractRefundAllocation: Scalars['JSON'];
  getClaimAllocationLedgers: Scalars['JSON'];
  getContractRetentionClaimAllocation: Scalars['JSON'];
  getContractRetClaimAllocation: Scalars['JSON'];
  getContractCNAllocation: Scalars['JSON'];
  getContractDNAllocation: Scalars['JSON'];
  getContractMiscInvoiceAllocation: Scalars['JSON'];
  getClientCNAllocation: Scalars['JSON'];
  getClientDNAllocation: Scalars['JSON'];
  getContractClaim: Array<ContractClaimEntity>;
  getRetentionClaim: Array<ContractClaimEntity>;
  fetchUnallocatedClaimRetention: Scalars['JSON'];
  getProgressClaim: Array<ContractClaimEntity>;
  getContractAdvance: Array<ContractAdvanceEntity>;
  getContractReceipt: Array<ContractReceiptEntity>;
  getContractRefund: Array<ContractRefundEntity>;
  getContractMiscInvoice: Array<ContractMiscInvoiceEntity>;
  getContractDN: Array<ContractDnEntity>;
  getContractCN: Array<ContractCnEntity>;
  getClientDN: Array<ClientDnEntity>;
  getClientCN: Array<ClientCnEntity>;
  getCustomer: Array<CustomerEntity>;
  getFollowUp: Array<FollowUpEntity>;
  getBankAccount: Array<BankAccountEntity>;
  logoView: DocumentsEntity;
  WatermarkView: DocumentsEntity;
  getGLInterfaceMaster: Array<GlInterfaceMasterEntity>;
  getGLInterfaceDetail: Array<GlInterfaceDetailEntity>;
  getGLTaxDynamic: Array<GlTaxDynamicEntity>;
  getGLAdjustmentDynamic: Array<GlAdjustmentDynamicEntity>;
  getCBExport: Array<CbExportEntity>;
  getContractCB?: Maybe<Scalars['JSON']>;
  getContractCBInfo?: Maybe<Scalars['JSON']>;
  getGLTaxType: Array<Scalars['JSON']>;
  getGLAdjustmentType: Array<Scalars['JSON']>;
  getGLExport: Array<GlExportEntity>;
  GLMonths: Array<GlMonth>;
  getContractGL?: Maybe<Scalars['JSON']>;
  getSubconGL?: Maybe<Scalars['JSON']>;
  getSupplierGL?: Maybe<Scalars['JSON']>;
  getPurchaseGL?: Maybe<Scalars['JSON']>;
  getPOBGL?: Maybe<Scalars['JSON']>;
  getGLDetailLedger: Scalars['JSON'];
  getDODetailLedger: Array<DoEntity>;
  getBatchGLDetailLedger: Scalars['JSON'];
  checkedGLEditable: Array<Scalars['String']>;
  getGLSetupFile: Scalars['JSON'];
  getGuaranteeType: Array<GuaranteeTypeEntity>;
  getInsuranceType: Array<InsuranceTypeEntity>;
  getMiscExpense: Array<MiscExpenseEntity>;
  miscExpenseBudgetChecking: BudgetInfo;
  getCostCenter: Array<CostCenterEntity>;
  getCurrencyExchange: Array<CurrencyExchangeEntity>;
  getCurrency: Scalars['JSON'];
  getConTaxScheme: Array<ConTaxSchemeEntity>;
  getConTaxEffectiveDate: Array<ConTaxEffectiveDateEntity>;
  getConTaxType: Array<ConTaxTypeEntity>;
  verifyTaxType: Scalars['String'];
  getDocNumHeader: Array<DocNumHeaderEntity>;
  getDocNumDetail: Array<DocNumDetailEntity>;
  getDocumentNumberHeaderAndDetail: Array<DocNumDetailEntity>;
  getProfitRecogFormula: Array<ProfitRecogFormulaEntity>;
  getProfitRecogParameter: Array<ProfitRecogParameterEntity>;
  getProfitRecogAssignment: Array<ProfitRecogAssignmentEntity>;
  getProfitRecogGeneration: Scalars['JSON'];
  getProfitRecogGenParamAssignment: Array<ProfitRecogGenParamAssgmtEntity>;
  getProfitRecogGLAccountCode: Array<ProfitRecogGlAccountCodeEntity>;
  getProfitRecogGLJournal: Array<ProfitRecogGlJournalEntity>;
  getSubcontractorInvitation: Array<SubcontractorInvitationEntity>;
  getSuggestedSubcontractor: Array<SubcontractorEntity>;
  getInvitedSubcontractor?: Maybe<Scalars['JSON']>;
  getSubconRFQSubmission: Array<SubconRfqSubmissionEntity>;
  getSubcontractTender: Array<SubcontractTenderEntity>;
  getSubcontractGuarantee: Array<SubcontractGuaranteeEntity>;
  getSubcontractInsurance: Array<SubcontractInsuranceEntity>;
  getSubcontract: Array<SubcontractEntity>;
  getSubcontractRelated: Array<SubcontractEntity>;
  subconBudgetChecking: BudgetInfo;
  subcontractVoCalculation: Scalars['JSON'];
  getSubcontractVO: Array<SubcontractVoEntity>;
  getSubconPaymentAllocation: Scalars['JSON'];
  getSubconRefundAllocation: Scalars['JSON'];
  getSubconAdvanceAllocation: Scalars['JSON'];
  getSubconClaimAllocationLedgers: Scalars['JSON'];
  getSubcontractRetentionClaimAllocation: Scalars['JSON'];
  getSubconCNAllocation: Scalars['JSON'];
  getSubconRetClaimAllocation: Scalars['JSON'];
  getSubconDNAllocation: Scalars['JSON'];
  getDNtoSubconAllocation: Scalars['JSON'];
  getCNtoSubconAllocation: Scalars['JSON'];
  getSubcontractClaim: Array<SubcontractClaimEntity>;
  getSubconRetentionClaim: Array<SubcontractClaimEntity>;
  getSubconProgressClaim: Array<SubcontractClaimEntity>;
  getSubcontractAdvance: Array<SubcontractAdvanceEntity>;
  getSubcontractPayment: Array<SubcontractPaymentEntity>;
  getSubcontractRefund: Array<SubcontractRefundEntity>;
  getSubcontractMiscInvoice: Array<SubcontractMiscInvoiceEntity>;
  getSubcontractDN: Array<SubcontractDnEntity>;
  getSubcontractCN: Array<SubcontractCnEntity>;
  getDNtoSubcon: Array<DNtoSubconEntity>;
  getCNtoSubcon: Array<CNtoSubconEntity>;
  getSubcontractor: Array<SubcontractorEntity>;
  getSubcontractorCCategory: Array<SubcontractorCCategoryEntity>;
  getSubcontractorCostCategory: Scalars['JSON'];
  getCostCategoryBySubcon: Scalars['JSON'];
  getCostCategory: Array<CostCategoryEntity>;
  getCostItem: Array<CostItemEntity>;
  getCostItemBySupplier: Array<CostItemEntity>;
  getItemByClass: Array<CostItemEntity>;
  getCostItemByClasses: Array<CostItemEntity>;
  getPlantMachinery: Array<PlantMachineryEntity>;
  getAssignment: Array<AssignmentEntity>;
  getMaintenance: Array<MaintenanceEntity>;
  getWorkshop: Array<WorkshopEntity>;
  getPNMRental: Array<PnmRentalEntity>;
  getPNMStatus: Scalars['Boolean'];
  getDO: Array<DoEntity>;
  getDOItem: Array<DoItemEntity>;
  getPOs: Array<PurchaseOrderEntity>;
  totalDOAmt: Scalars['Float'];
  getSupplierByDOStatus: Array<SupplierEntity>;
  getGoodReturnNote: Array<GoodReturnNoteEntity>;
  getGoodReturnNoteItem: Array<GoodReturnNoteItemEntity>;
  getNegotiatedSupply: Array<NegotiatedSupplyEntity>;
  getNegotiatedSupplyItem: Array<NegotiatedSupplyItemEntity>;
  getNegoSupplyListing: Array<NegotiatedSupplyEntity>;
  getPurchaseOrder: Array<PurchaseOrderEntity>;
  purchaseBudget: Scalars['JSON'];
  purchaseBudgetAllocation: Scalars['JSON'];
  getSupplierQuotes: Scalars['JSON'];
  closePOChecking: Scalars['Boolean'];
  getPOBySupplier: Array<PurchaseOrderEntity>;
  getSiteBySupplier: Array<SiteEntity>;
  getSupplierByPOStatus: Array<SupplierEntity>;
  getPurchaseReq: Array<PurchaseReqEntity>;
  GetPRItemsbyIDs: Array<PrItemInfo>;
  getOutstandingPR: Array<PurchaseReqEntity>;
  getRFQ: Array<RfqEntity>;
  getRFQItem: Array<RfqItemEntity>;
  getSuggestedSupplier: Array<SupplierEntity>;
  getRFQSupplierInvited: Array<RfqEntity>;
  getRFQSupplierInvitation: Array<RfqSupplierInvitationEntity>;
  getInvitedSupplier?: Maybe<Scalars['JSON']>;
  getRFQSupplierSubmission: Array<RfqSupplierSubmissionEntity>;
  getPRItemByPRIDForRFQ: Array<Scalars['JSON']>;
  getPurchaseReqWithPR: Array<PurchaseReqEntity>;
  getQuotedRFQ: Array<RfqSupplierSubmissionEntity>;
  getSupplier: Array<SupplierEntity>;
  getSupplierCCategory: Array<SupplierCCategoryEntity>;
  getActiveSupplier: Scalars['JSON'];
  getSupplierCostCategory: Scalars['JSON'];
  getSupplierDepositAllocation: Scalars['JSON'];
  getDNtoSupplierAllocation: Scalars['JSON'];
  getCNtoSupplierAllocation: Scalars['JSON'];
  getSupplierCNAllocation: Scalars['JSON'];
  getSupplierDNAllocation: Scalars['JSON'];
  getSupplierInvoiceAllocation: Scalars['JSON'];
  getSupplierPaymentAllocation: Scalars['JSON'];
  getAPInvoice: Array<ApInvoiceEntity>;
  getDOforInvoice?: Maybe<Array<DoEntity>>;
  getAPAdvance: Array<ApAdvanceEntity>;
  getAPPayment: Array<ApPaymentEntity>;
  getAPRefund: Array<ApRefundEntity>;
  getSupplierPaymentByContract: Scalars['JSON'];
  getAPDebitNote: Array<ApDebitNoteEntity>;
  getAPCreditNote: Array<ApCreditNoteEntity>;
  getDNtoSupplier: Array<DNtoSupplierEntity>;
  getCNtoSupplier: Array<CNtoSupplierEntity>;
  getUOM: Array<UomEntity>;
  getUOMExchange: Array<UomExchangeEntity>;
  getUomExchangeData: Scalars['JSON'];
  defectLodgeOverTimeSummary: Scalars['JSON'];
  defectStatusAnalysisSummary: Scalars['JSON'];
  defectSubconAnalysisSummary: Scalars['JSON'];
  defectTradeAnalysisSummary: Scalars['JSON'];
  getDefect: Array<DefectEntity>;
  getDefectAssignment: Array<DefectAssignmentEntity>;
  getDefectMgmt: Array<DefectManagementEntity>;
  getSubconDefect: Array<DefectManagementEntity>;
  getWorker: Array<WorkerEntity>;
  checkingWageType: Scalars['Boolean'];
  getWorkerAttendance: Array<WorkerAttendanceEntity>;
  getGRN: Array<GrnEntity>;
  getGRNItem: Array<GrnItemEntity>;
  getUOMExchangeList: Scalars['JSON'];
  getCostItemMovementCategories: Scalars['JSON'];
  getCostItemMovementAnalysis: Scalars['JSON'];
  costItemMovementChecking: Scalars['Float'];
  getCostItemMovement: Scalars['JSON'];
  getCostItemInStock: Scalars['JSON'];
  getCostItemCategory: Scalars['JSON'];
  getStockMovement: Array<StockMovementEntity>;
  getStockMovementCategories: Scalars['JSON'];
  getStockMovementAnalysis: Scalars['JSON'];
  stockMovementChecking: Scalars['Float'];
  getSiteMaterialInStock: Scalars['JSON'];
  getCheckInStock: Scalars['Boolean'];
  getStockCategory: Scalars['JSON'];
  getSiteProgress: Array<SiteProgressEntity>;
  getSiteStockLedger: Array<SiteStockLedgerEntity>;
  getSiteStockItems: Scalars['JSON'];
  getStockItemDetail: Scalars['JSON'];
  getDOBySiteID: Scalars['JSON'];
  getSiteWorkerAttendance: Array<WorkerAttendanceEntity>;
  getSiteWorkers: Array<WorkerEntity>;
  getCalendar: Array<CalendarEntity>;
  getCalendarPHPolicy: Array<CalendarPhPolicyEntity>;
  getCalendarWeekendPolicy: Array<CalendarWeekendPolicyEntity>;
  getHolidayType: Array<HolidayTypeEntity>;
  getJobType: Array<JobTypeEntity>;
  getPublicHoliday: Array<PublicHolidayEntity>;
  getPublicHolidayByStateYear: Scalars['JSON'];
  getSite: Array<SiteEntity>;
  getProjectSite: Array<ContractEntity>;
  calendarTypeDate?: Maybe<Scalars['DateTime']>;
  holidayTypeDate?: Maybe<Scalars['DateTime']>;
  jobTypeDate?: Maybe<Scalars['DateTime']>;
  projectSiteDate?: Maybe<Scalars['DateTime']>;
  publicHolidayDate?: Maybe<Scalars['DateTime']>;
  siteMaterialDate?: Maybe<Scalars['DateTime']>;
  wagesRatePolicyDate?: Maybe<Scalars['DateTime']>;
  workingHourPolicyDate?: Maybe<Scalars['DateTime']>;
  videoCamDate?: Maybe<Scalars['DateTime']>;
  getSiteMaterial: Array<SiteMaterialEntity>;
  getVideoCam: Array<VideoCamEntity>;
  getWagesRatePolicy: Array<WagesRatePolicyEntity>;
  getWorkingHourPolicy: Array<WorkingHourPolicyEntity>;
  getSiteImage: Array<SiteImageEntity>;
  getWageCycle: Array<WageCycleEntity>;
  getWorkerWageAdvance: Scalars['JSON'];
  getUnpaidWageAdvance: Scalars['JSON'];
  getUnpaidWorkerJson: Scalars['JSON'];
  getUnpaidWorker: Array<WorkerEntity>;
  getPaidWorker: Array<WorkerEntity>;
  getWorkingSchedule: Scalars['Boolean'];
  getWageCycleDetail: Array<WageCycleDetailEntity>;
  getWorkerCycleDetail: Scalars['JSON'];
  getWorkerAdvance: Array<WorkerAdvanceEntity>;
  getWorkerWage: Array<WorkerWageEntity>;
  getWorkerWageVerification: Array<WorkerWageEntity>;
  getWorkerWageBook: Array<WorkerEntity>;
  getWorkerSalary: Scalars['JSON'];
};


export type QueryGetRoleArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  softwareCode?: Maybe<SoftwareCode>;
};


export type QueryGetEntityRoleUserAssignmentArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  entityID?: Maybe<Scalars['String']>;
};


export type QueryUserRoleIDsArgs = {
  contractID: Scalars['String'];
};


export type QueryGetRolePermissionArgs = {
  siteManagement?: Maybe<Scalars['Boolean']>;
};


export type QueryGetUsersRoleContractArgs = {
  contractID: Scalars['String'];
  roleID: Scalars['String'];
};


export type QueryCheckArgs = {
  permIds: Array<Scalars['Float']>;
  userID: Scalars['String'];
};


export type QueryUserEntitiesArgs = {
  userID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
};


export type QueryTestPermissionArgs = {
  conPerm: ContractPermission;
};


export type QuerySiteTestPermissionArgs = {
  sitePerm: SitePermission;
};


export type QueryTestConPermissionArgs = {
  contPerm: ContPermission;
};


export type QueryTestSampleEnumArgs = {
  SampleEnum: SampleEnum;
};


export type QueryGetApprovalPolicyGuidelineArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
};


export type QueryGetApprovalPolicyAssignmentGuidelineArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  approvalPolicyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
};


export type QueryGetApprovalPolicyArgs = {
  category: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetApprovalPolicyAssignmentArgs = {
  contractID: Scalars['String'];
  category: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
};


export type QueryDocumentListingArgs = {
  hasDescription?: Maybe<Scalars['Boolean']>;
  notDescription?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
};


export type QueryCmgdAttachmentArgs = {
  refID?: Maybe<Scalars['String']>;
};


export type QueryGetCashFlowLineChartDataArgs = {
  contractID: Scalars['String'];
};


export type QueryGetCashFlowGraphDataArgs = {
  contractID: Scalars['String'];
};


export type QueryGetContactArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
};


export type QueryGetNumberFormatArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  docTypeID?: Maybe<Scalars['String']>;
};


export type QueryGetDocumentTypeArgs = {
  commonStatus?: Maybe<CommonStatus>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryFetchNextDocNumArgs = {
  claimOnly?: Maybe<Scalars['String']>;
  consubconID?: Maybe<Scalars['String']>;
  docCode: ContractDocNum;
};


export type QueryFetchMultipleDocNumArgs = {
  supplierID?: Maybe<Array<Scalars['String']>>;
  numOfDocs?: Maybe<Scalars['Float']>;
  docCode: ContractDocNum;
};


export type QueryGetRunningNumberSetupWithLockArgs = {
  contractID?: Maybe<Scalars['String']>;
  numberFormatID: Scalars['String'];
};


export type QueryRunningNumCheckingArgs = {
  docCode: ContractDocNum;
};


export type QueryGetAdjustmentTypeArgs = {
  commonStatus?: Maybe<CommonStatus>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetDocumentFolderArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetSAccountArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
};


export type QueryIsPlatformUserValidArgs = {
  userID: Scalars['String'];
};


export type QueryGetPolicyGuideLineArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
};


export type QueryGetPolicyRoleAssignmentArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  policyID?: Maybe<Scalars['String']>;
  roleID?: Maybe<Scalars['String']>;
};


export type QueryGetNotificationGuideLineArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  policyID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
};


export type QueryGetNotiRoleUserAssignmentArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  notiPolicyID?: Maybe<Scalars['String']>;
  roleID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
};


export type QueryGetProfitRecognitionArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetProfitRecogGenerationListingArgs = {
  contractID: Scalars['String'];
};


export type QueryInfoLineProfitRecogArgs = {
  contractID: Scalars['String'];
};


export type QueryProfitRecognitionCalculationArgs = {
  inputDate: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryC1_ActualCostArgs = {
  inputDate: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryC2_BudgetCostArgs = {
  inputDate?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
};


export type QueryC3_ActualRevenueArgs = {
  inputDate: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryC4_ReviseContractSumArgs = {
  inputDate: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryC5_TotalPreviousRecognisedRevenueArgs = {
  inputDate: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryC6_TotalPreviousRecognisedCostArgs = {
  inputDate: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryC7_PercOfCompletionArgs = {
  inputDate: Scalars['String'];
  contractID: Scalars['String'];
};


export type QuerySubmittedRetClaimCheckingArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedClaimCheckingArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryApprovedClaimCheckingArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryCancellableClaimsArgs = {
  identifier: Scalars['String'];
};


export type QueryApprovableLedgerCheckingArgs = {
  docAmt?: Maybe<Scalars['Float']>;
  docRefTable: Scalars['String'];
  docRefID?: Maybe<Scalars['String']>;
};


export type QueryAssociateMenuArgs = {
  associate: Scalars['String'];
};


export type QuerySupplierProfileDateArgs = {
  ID: Scalars['String'];
};


export type QueryPrefSupplierProductDateArgs = {
  supplierID: Scalars['String'];
};


export type QuerySupplierAccDateArgs = {
  supplierID: Scalars['String'];
};


export type QueryContractInfoArgs = {
  contractID: Scalars['String'];
};


export type QueryContractGuaranteeArgs = {
  contractID: Scalars['String'];
};


export type QueryContractInsuranceArgs = {
  contractID: Scalars['String'];
};


export type QuerySubmittedVoNotificationArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryContractVoArgs = {
  contractID: Scalars['String'];
};


export type QueryOutstandingAdvancesArgs = {
  contractID: Scalars['String'];
};


export type QueryOutstandingClaimsArgs = {
  contractID: Scalars['String'];
};


export type QueryOutstandingMiscInvsArgs = {
  contractID: Scalars['String'];
};


export type QueryRetentionArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastSiteProgressDateArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryLastMiscExpDateArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastExtensionDateArgs = {
  contractID: Scalars['String'];
};


export type QueryContractClosureDateArgs = {
  contractID: Scalars['String'];
};


export type QuerySubcontractInfoArgs = {
  subcontractID: Scalars['String'];
};


export type QuerySubconGuaranteeArgs = {
  subcontractID: Scalars['String'];
};


export type QuerySubconInsuranceArgs = {
  subcontractID: Scalars['String'];
};


export type QuerySubmittedVoSubconNotificationArgs = {
  subcontractID?: Maybe<Scalars['String']>;
};


export type QuerySubcontractVoArgs = {
  subcontractID: Scalars['String'];
};


export type QuerySubconOutstandingAdvancesArgs = {
  subcontractID: Scalars['String'];
};


export type QuerySubconOutstandingClaimsArgs = {
  subcontractID: Scalars['String'];
};


export type QuerySubconLastExtensionDateArgs = {
  subcontractID: Scalars['String'];
};


export type QuerySubconClosureDateArgs = {
  subcontractID: Scalars['String'];
};


export type QueryLatestDefectDateArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryLatestAssignmentDateArgs = {
  defectID?: Maybe<Scalars['String']>;
};


export type QuerySiteWorkerAttendanceInfoArgs = {
  siteID: Scalars['String'];
};


export type QueryDoCountArgs = {
  siteID: Scalars['String'];
};


export type QueryMosCountArgs = {
  siteID: Scalars['String'];
};


export type QueryClientProfileDateArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedCusAvNotificationArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryAdvancesInfoArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryClaimsInfoArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedClaimNotificationArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryProgressClaimsInfoArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedRetClaimNotificationArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryRetentionClaimsInfoArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedCnNotificationArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryCnInfoArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedDnNotificationArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryDnInfoArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedMiscInvNotificationArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryMiscInvInfoArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedClientDnNotificationArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryClientDnInfoArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedClientCnNotificationArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryClientCnInfoArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedReceiptNotificationArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryReceiptsInfoArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedRefundNotificationArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryRefundsInfoArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryOutstandingClientArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryUnallocatedClientArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryConClientProfileDateArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryConAdvancesInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryConClaimsInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryConDnInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryConCnInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryConReceiptsInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryConRefundsInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryConClientDnInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryConOutstandingClientArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QuerySubconProfileDateArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSubconAvNotificationArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconAdvancesInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconClaimsInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSubconClaimNotificationArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconProgressClaimsInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSubconRetClaimNotificationArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconRetentionClaimsInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSubconMiscInvNotificationArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconMiscInvInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSubconDnNotificationArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconDnInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSubconCnNotificationArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconCnInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedPaymentNotificationArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconPaymentInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSubconRefundNotificationArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconRefundsInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedDNtoSubconNotificationArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QueryDNtoSubconInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedCNtoSubconNotificationArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QueryCNtoSubconInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QueryOutstandingSubconArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QueryUnallocatedSubconArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySuppProfileDateArgs = {
  supplierID: Scalars['String'];
};


export type QuerySubmittedDepositNotificationArgs = {
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySupplierDepositsInfoArgs = {
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSupplierInvNotificationArgs = {
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySupplierInvInfoArgs = {
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSupplierDnNotificationArgs = {
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySupplierDnInfoArgs = {
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSupplierCnNotificationArgs = {
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySupplierCnInfoArgs = {
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedDnToSupplierNotificationArgs = {
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryDNtoSupplierInfoArgs = {
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedCnToSupplierNotificationArgs = {
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryCNtoSupplierInfoArgs = {
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSupplierPaymentNotificationArgs = {
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySupplierPaymentsInfoArgs = {
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSupplierRefundNotificationArgs = {
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySupplierRefundsInfoArgs = {
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryOutstandingSupplierArgs = {
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryUnallocatedSupplierArgs = {
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryNegoItemInfoArgs = {
  contractID: Scalars['String'];
};


export type QueryNegoItemCountArgs = {
  contractID: Scalars['String'];
};


export type QueryLastPrDateArgs = {
  contractID: Scalars['String'];
};


export type QueryLastPrDateObArgs = {
  contractID: Scalars['String'];
};


export type QueryLastPoDateArgs = {
  contractID: Scalars['String'];
};


export type QueryLastPoDateObArgs = {
  contractID: Scalars['String'];
};


export type QueryLastRfqDateArgs = {
  contractID: Scalars['String'];
};


export type QueryLastDoDateArgs = {
  contractID: Scalars['String'];
};


export type QueryLastGrtnDateArgs = {
  contractID: Scalars['String'];
};


export type QueryWorkerCountArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryWorkerAttendanceCountArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryLastGrnDateArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryLastStockMovementDatesArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QuerySiteImagesCountArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryVideoCamCountArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetDailyRegisteredWorkHoursArgs = {
  siteID: Scalars['String'];
};


export type QueryGetDailyWagesVerificationArgs = {
  siteID: Scalars['String'];
};


export type QueryGetWagesBookArgs = {
  siteID: Scalars['String'];
};


export type QueryWageCycleClosureInfoArgs = {
  siteID: Scalars['String'];
};


export type QueryGetUsersByAccountArgs = {
  accountID: Scalars['String'];
};


export type QueryGetUserArgs = {
  ID: Scalars['String'];
};


export type QueryGetUsersbyIdsArgs = {
  userIDs: Array<Scalars['String']>;
};


export type QueryIsUserValidArgs = {
  userID: Scalars['String'];
};


export type QueryGetUsersByAccountAndSoftwareArgs = {
  superUserBool?: Maybe<UserSelection>;
  status?: Maybe<CommonStatus>;
};


export type QueryGetContractNotificationArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  senderID?: Maybe<Scalars['String']>;
  recipientID?: Maybe<Scalars['String']>;
  readStatus?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  docRefID?: Maybe<Scalars['String']>;
  createdTs?: Maybe<Scalars['DateTime']>;
};


export type QueryGetContractNewsArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  postStatus?: Maybe<Scalars['String']>;
};


export type QueryGetContractNewsReadArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  newsID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
};


export type QueryGetNotificationListingArgs = {
  unread?: Maybe<Scalars['Boolean']>;
};


export type QueryGetContractToDoArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  docRefID?: Maybe<Scalars['String']>;
};


export type QueryGetToDoListingArgs = {
  approved?: Maybe<Scalars['Boolean']>;
};


export type QueryGetToDoWorkflowSummaryArgs = {
  length?: Maybe<Scalars['Boolean']>;
};


export type QueryGetToDoAllPendingDocsArgs = {
  category: Scalars['String'];
};


export type QueryPolicyCheckingArgs = {
  category: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryPolicyCheckerApPaymentArgs = {
  category: Scalars['String'];
};


export type QueryGetNotificationWorkflowListingArgs = {
  length?: Maybe<Scalars['Boolean']>;
  unread?: Maybe<Scalars['Boolean']>;
};


export type QueryGetApprovalLogArgs = {
  refID: Scalars['String'];
};


export type QueryGetContractPlAdjustmentTotalArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractPlAdjustmentTotalArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetSupplierPlAdjustmentTotalArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractPlAdjustmentStatusTotalArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetContractPlAdjustmentStatusTotalArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetSupplierPlAdjustmentStatusTotalArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetAdjustmentByCostClassArgs = {
  contractID: Scalars['String'];
};


export type QueryAssociateCalculationArgs = {
  contractID: Scalars['String'];
};


export type QueryAdvanceClientArgs = {
  contractID: Scalars['String'];
};


export type QueryReceivedFromClientArgs = {
  contractID: Scalars['String'];
};


export type QueryPaidToSubConArgs = {
  contractID: Scalars['String'];
};


export type QueryPaidToSupplierArgs = {
  contractID: Scalars['String'];
};


export type QueryAdvancesOutflowAmtArgs = {
  contractID: Scalars['String'];
};


export type QueryMiscExpenseArgs = {
  contractID: Scalars['String'];
};


export type QueryOsReceivableFromClientArgs = {
  contractID: Scalars['String'];
};


export type QueryClientLastReceiptArgs = {
  contractID: Scalars['String'];
};


export type QuerySubconLastPaymentArgs = {
  contractID: Scalars['String'];
};


export type QueryReceivableClientLastPaymentArgs = {
  contractID: Scalars['String'];
};


export type QueryOutstandingAmtClientArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryPaidSubconSupplierListingArgs = {
  contractID: Scalars['String'];
};


export type QueryPaidSubconSupplierAmountArgs = {
  contractID: Scalars['String'];
};


export type QueryOsReceivableFromClientListingArgs = {
  contractID: Scalars['String'];
};


export type QuerySubconOsPayableAmountArgs = {
  contractID: Scalars['String'];
};


export type QueryOsPayableToSubconListingArgs = {
  contractID: Scalars['String'];
};


export type QuerySupplierLastPaymentArgs = {
  contractID: Scalars['String'];
};


export type QuerySupplierOsPayableAmountArgs = {
  contractID: Scalars['String'];
};


export type QueryOsPayableToSupplierListingArgs = {
  contractID: Scalars['String'];
};


export type QueryOutstandingReceivableFromClientArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryOutstandingReceivableFromClientListingArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryOutstandingPayableSubconArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryOutstandingPayableSubconListingArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryOutstandingPayableSupplierArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryOutstandingPayableSupplierListingArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryGetCostingCalculationArgs = {
  contractID: Scalars['String'];
};


export type QueryFinancialStatusArgs = {
  contractID: Scalars['String'];
};


export type QueryProjectPlArgs = {
  contractID: Scalars['String'];
};


export type QueryGetProjectCostingAmtArgs = {
  contractID: Scalars['String'];
};


export type QueryGetProjectCostingDetailArgs = {
  costClass: CostClass;
  contractID: Scalars['String'];
};


export type QueryGetAdjustmentItemByCostCategArgs = {
  costCategoryID: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryGetCostCategCountArgs = {
  contractID: Scalars['String'];
};


export type QueryGetPOsByCategoryArgs = {
  purchaseStatus?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
};


export type QueryGetDOsByCategoryArgs = {
  doStatus?: Maybe<Scalars['String']>;
  purchaseOrderID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
};


export type QueryGetCommCostAmtArgs = {
  subcontract?: Maybe<Scalars['Boolean']>;
  costCategoryID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetDocReminderArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  paymentReminderID?: Maybe<Scalars['String']>;
  claimID?: Maybe<Scalars['String']>;
};


export type QueryGetBillInterestArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  customerID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  docID?: Maybe<Scalars['String']>;
};


export type QueryGetStatementAcctMonthsArgs = {
  ID: Scalars['String'];
};


export type QueryGetClaimRecListByMonthArgs = {
  ID: Scalars['String'];
  month: Scalars['DateTime'];
};


export type QueryGetCustContractIDsArgs = {
  customerID?: Maybe<Scalars['String']>;
};


export type QueryGetPaymentReminderArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  customerID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetPaymentReminderClaimArgs = {
  paymentReminderID: Scalars['String'];
};


export type QueryGetDebitDocArgs = {
  customerID: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryGetCustomerReminderArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<Scalars['String']>;
};


export type QueryGetContractArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  contractDate?: Maybe<Scalars['DateTime']>;
  contractSum?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  contractStatus?: Maybe<ContractStatus>;
};


export type QueryContractVoCalculationArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryMaxRetentionArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryAssociateCheckingArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryVoCheckingArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryRetentionCheckingArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetContractWithActiveDoArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  contractDate?: Maybe<Scalars['DateTime']>;
  contractSum?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  contractStatus?: Maybe<ContractStatus>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySubcontractVoListingArgs = {
  contractID: Scalars['String'];
};


export type QueryGetDigitalDocumentArgs = {
  type: Scalars['String'];
  associateID: Scalars['String'];
};


export type QueryGetTableDocumentsArgs = {
  description?: Maybe<Scalars['String']>;
  refTable: Scalars['String'];
  associateID: Scalars['String'];
  refID: Array<Scalars['String']>;
};


export type QueryTotalDigitalDocumentsArgs = {
  type?: Maybe<Scalars['String']>;
  associateID: Scalars['String'];
};


export type QueryGetContractGuaranteeArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetContractInsuranceArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetProjectBudgetArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  contractID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryDeleteBudgetCheckingArgs = {
  contractID: Scalars['String'];
  costCategoryIDs: Array<Scalars['String']>;
};


export type QueryBudgetCalculationArgs = {
  contractID: Scalars['String'];
};


export type QueryGetContractVoArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  VOStatus?: Maybe<VoStatus>;
};


export type QueryGetContractAllocationArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  debitID?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
  creditRefTable?: Maybe<Scalars['String']>;
  debitRefTable?: Maybe<Scalars['String']>;
};


export type QueryGetContractAdvanceAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetContractReceiptAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetContractRefundAllocationArgs = {
  receiptStatus?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
};


export type QueryGetClaimAllocationLedgersArgs = {
  contractID?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
};


export type QueryGetContractRetentionClaimAllocationArgs = {
  contractID?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
};


export type QueryGetContractRetClaimAllocationArgs = {
  debitID: Scalars['String'];
};


export type QueryGetContractCnAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetContractDnAllocationArgs = {
  debitID: Scalars['String'];
};


export type QueryGetContractMiscInvoiceAllocationArgs = {
  debitID: Scalars['String'];
};


export type QueryGetClientCnAllocationArgs = {
  debitID: Scalars['String'];
};


export type QueryGetClientDnAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetContractClaimArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetRetentionClaimArgs = {
  customerID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryFetchUnallocatedClaimRetentionArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetProgressClaimArgs = {
  subcontractorID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetContractAdvanceArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetContractReceiptArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetContractRefundArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetContractMiscInvoiceArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetContractDnArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetContractCnArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetClientDnArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetClientCnArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetCustomerArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  taxTypeID?: Maybe<Scalars['String']>;
  associatedStatus?: Maybe<Scalars['String']>;
};


export type QueryGetFollowUpArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryGetBankAccountArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<BankAccountStatus>;
};


export type QueryLogoViewArgs = {
  description?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
};


export type QueryWatermarkViewArgs = {
  opacity?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
};


export type QueryGetGlInterfaceMasterArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  associationType?: Maybe<AssociationType>;
  transactionType?: Maybe<TransactionType>;
};


export type QueryGetGlInterfaceDetailArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  glInterfaceMasterID?: Maybe<Scalars['String']>;
};


export type QueryGetGlTaxDynamicArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  refID?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
};


export type QueryGetGlAdjustmentDynamicArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  refID?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
};


export type QueryGetCbExportArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetContractCbArgs = {
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type QueryGetContractCbInfoArgs = {
  cbExportID: Scalars['String'];
};


export type QueryGetGlExportArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGlMonthsArgs = {
  contractID: Scalars['String'];
};


export type QueryGetContractGlArgs = {
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  glExportID?: Maybe<Scalars['String']>;
};


export type QueryGetSubconGlArgs = {
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  glExportID?: Maybe<Scalars['String']>;
};


export type QueryGetSupplierGlArgs = {
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  glExportID?: Maybe<Scalars['String']>;
};


export type QueryGetPurchaseGlArgs = {
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  glExportID?: Maybe<Scalars['String']>;
};


export type QueryGetPobglArgs = {
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  glExportID?: Maybe<Scalars['String']>;
};


export type QueryGetGlDetailLedgerArgs = {
  claimColumn?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
  IDs: Array<Scalars['String']>;
};


export type QueryGetDoDetailLedgerArgs = {
  IDs: Array<Scalars['String']>;
};


export type QueryGetBatchGlDetailLedgerArgs = {
  batchInput: Array<GlDetailLedgerBatchInput>;
};


export type QueryCheckedGlEditableArgs = {
  type: Scalars['String'];
};


export type QueryGetGlSetupFileArgs = {
  accountType: Scalars['String'];
};


export type QueryGetGuaranteeTypeArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetInsuranceTypeArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetMiscExpenseArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
};


export type QueryMiscExpenseBudgetCheckingArgs = {
  amount: Scalars['Float'];
  contractID: Scalars['String'];
  costCategoryID: Scalars['String'];
};


export type QueryGetCostCenterArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  status?: Maybe<CostCenterType>;
};


export type QueryGetCurrencyExchangeArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  fromCurrencyID?: Maybe<Scalars['String']>;
  toCurrencyID?: Maybe<Scalars['String']>;
};


export type QueryGetConTaxSchemeArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  taxTypeID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<Scalars['String']>;
  taxClass?: Maybe<TaxClass>;
};


export type QueryGetConTaxEffectiveDateArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  claimable?: Maybe<Scalars['Boolean']>;
};


export type QueryGetConTaxTypeArgs = {
  commonStatus?: Maybe<CommonStatus>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryVerifyTaxTypeArgs = {
  taxSchemeID: Scalars['String'];
};


export type QueryGetDocNumHeaderArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetDocNumDetailArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetDocumentNumberHeaderAndDetailArgs = {
  contractID: Scalars['String'];
};


export type QueryGetProfitRecogFormulaArgs = {
  commonStatus?: Maybe<CommonStatus>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetProfitRecogParameterArgs = {
  commonStatus?: Maybe<CommonStatus>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetProfitRecogAssignmentArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
};


export type QueryGetProfitRecogGenerationArgs = {
  contractID: Scalars['String'];
};


export type QueryGetProfitRecogGenParamAssignmentArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
};


export type QueryGetProfitRecogGlAccountCodeArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
};


export type QueryGetProfitRecogGlJournalArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
};


export type QueryGetSubcontractorInvitationArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetSuggestedSubcontractorArgs = {
  subcontractTenderID: Scalars['String'];
};


export type QueryGetInvitedSubcontractorArgs = {
  contractID: Scalars['String'];
  subcontractTenderID: Scalars['String'];
};


export type QueryGetSubconRfqSubmissionArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractorInvitationID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  subcontractTenderID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractTenderArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractGuaranteeArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractInsuranceArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  subcontractSum?: Maybe<Scalars['Float']>;
  costCategoryID?: Maybe<Scalars['String']>;
  subcontractStatus?: Maybe<ContractStatus>;
};


export type QueryGetSubcontractRelatedArgs = {
  subcontractID?: Maybe<Scalars['String']>;
};


export type QuerySubconBudgetCheckingArgs = {
  amount: Scalars['Float'];
  contractID: Scalars['String'];
  costCategoryID: Scalars['String'];
};


export type QuerySubcontractVoCalculationArgs = {
  subcontractID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractVoArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  VOStatus?: Maybe<VoStatus>;
};


export type QueryGetSubconPaymentAllocationArgs = {
  receiptStatus?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
};


export type QueryGetSubconRefundAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetSubconAdvanceAllocationArgs = {
  debitID: Scalars['String'];
};


export type QueryGetSubconClaimAllocationLedgersArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractRetentionClaimAllocationArgs = {
  contractID?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
};


export type QueryGetSubconCnAllocationArgs = {
  debitID: Scalars['String'];
};


export type QueryGetSubconRetClaimAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetSubconDnAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetDNtoSubconAllocationArgs = {
  debitID: Scalars['String'];
};


export type QueryGetCNtoSubconAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetSubcontractClaimArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetSubconRetentionClaimArgs = {
  subcontractorID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
};


export type QueryGetSubconProgressClaimArgs = {
  subcontractorID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractAdvanceArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetSubcontractPaymentArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetSubcontractRefundArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetSubcontractMiscInvoiceArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetSubcontractDnArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetSubcontractCnArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetDNtoSubconArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetCNtoSubconArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetSubcontractorArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  associatedStatus?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractorCCategoryArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  subcontractorID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractorCostCategoryArgs = {
  costCategoryID: Scalars['String'];
};


export type QueryGetCostCategoryBySubconArgs = {
  subcontractorID: Scalars['String'];
};


export type QueryGetCostCategoryArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  costClass?: Maybe<CostClass>;
  commonStatus?: Maybe<CommonStatus>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QueryGetCostItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetCostItemBySupplierArgs = {
  supplierID: Scalars['String'];
};


export type QueryGetItemByClassArgs = {
  costClass: CostClass;
};


export type QueryGetCostItemByClassesArgs = {
  costClass: Array<CostClass>;
};


export type QueryGetPlantMachineryArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  plantMachineryStatus?: Maybe<Scalars['String']>;
};


export type QueryGetAssignmentArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  plantMachineryID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  assignmentStatus?: Maybe<Scalars['String']>;
};


export type QueryGetMaintenanceArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  plantMachineryID?: Maybe<Scalars['String']>;
  maintenanceStatus?: Maybe<Scalars['String']>;
  workshopID?: Maybe<Scalars['String']>;
};


export type QueryGetWorkshopArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  workshopStatus?: Maybe<WorkshopStatus>;
};


export type QueryGetPnmRentalArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetPnmStatusArgs = {
  plantMachineryID: Scalars['String'];
};


export type QueryGetDoArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  doStatus?: Maybe<DoStatus>;
  siteID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['DateTime']>;
};


export type QueryGetDoItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetPOsArgs = {
  docDate?: Maybe<Scalars['DateTime']>;
  siteID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryTotalDoAmtArgs = {
  doID?: Maybe<Scalars['String']>;
};


export type QueryGetSupplierByDoStatusArgs = {
  doStatus: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryGetGoodReturnNoteArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  supplierID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetGoodReturnNoteItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryGetNegotiatedSupplyArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};


export type QueryGetNegotiatedSupplyItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  negotiatedSupplyID?: Maybe<Scalars['String']>;
};


export type QueryGetNegoSupplyListingArgs = {
  contractID: Scalars['String'];
};


export type QueryGetPurchaseOrderArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  purchaseType?: Maybe<PurchaseType>;
};


export type QueryPurchaseBudgetArgs = {
  contractID: Scalars['String'];
  prInfo: Array<PrInfo>;
};


export type QueryPurchaseBudgetAllocationArgs = {
  contractID: Scalars['String'];
  prItemIDs: Array<Scalars['String']>;
};


export type QueryGetSupplierQuotesArgs = {
  prItemID: Scalars['String'];
  contractID: Scalars['String'];
  costItemID: Scalars['String'];
};


export type QueryClosePoCheckingArgs = {
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetPoBySupplierArgs = {
  siteID: Scalars['String'];
};


export type QueryGetSupplierByPoStatusArgs = {
  purchaseStatus: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryGetPurchaseReqArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  prItemID?: Maybe<Scalars['String']>;
  purchaseStatus?: Maybe<PurchaseStatus>;
  purchaseType?: Maybe<PurchaseType>;
};


export type QueryGetPrItemsbyIDsArgs = {
  submitMode: Scalars['String'];
  poID?: Maybe<Scalars['String']>;
  IDs: Array<Scalars['String']>;
};


export type QueryGetOutstandingPrArgs = {
  purchaseType: PurchaseType;
  contractID: Scalars['String'];
};


export type QueryGetRfqArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  purchaseReqID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};


export type QueryGetRfqItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  rfqID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  prItemID?: Maybe<Scalars['String']>;
};


export type QueryGetSuggestedSupplierArgs = {
  rfqID: Scalars['String'];
};


export type QueryGetRfqSupplierInvitedArgs = {
  supplierID: Scalars['String'];
};


export type QueryGetRfqSupplierInvitationArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetInvitedSupplierArgs = {
  contractID: Scalars['String'];
  rfqID: Scalars['String'];
};


export type QueryGetRfqSupplierSubmissionArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  rfqID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryGetPrItemByPridForRfqArgs = {
  contractID: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryGetPurchaseReqWithPrArgs = {
  contractID: Scalars['String'];
};


export type QueryGetQuotedRfqArgs = {
  supplierID: Scalars['String'];
  rfqID: Scalars['String'];
};


export type QueryGetSupplierArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  creditorAC?: Maybe<Scalars['String']>;
  associatedStatus?: Maybe<AssociatedStatus>;
};


export type QueryGetSupplierCCategoryArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  supplierID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
};


export type QueryGetSupplierCostCategoryArgs = {
  costCategoryID: Scalars['String'];
};


export type QueryGetSupplierDepositAllocationArgs = {
  debitID: Scalars['String'];
};


export type QueryGetDNtoSupplierAllocationArgs = {
  debitID: Scalars['String'];
};


export type QueryGetCNtoSupplierAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetSupplierCnAllocationArgs = {
  debitID: Scalars['String'];
};


export type QueryGetSupplierDnAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetSupplierInvoiceAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetSupplierPaymentAllocationArgs = {
  receiptStatus?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
};


export type QueryGetApInvoiceArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetDOforInvoiceArgs = {
  formMode?: Maybe<Scalars['String']>;
  invoiceID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
  supplierID: Scalars['String'];
};


export type QueryGetApAdvanceArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetApPaymentArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetApRefundArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetSupplierPaymentByContractArgs = {
  contractID: Scalars['String'];
};


export type QueryGetApDebitNoteArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetApCreditNoteArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetDNtoSupplierArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetCNtoSupplierArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetUomArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetUomExchangeArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  fromUomID?: Maybe<Scalars['String']>;
  toUomID?: Maybe<Scalars['String']>;
};


export type QueryDefectLodgeOverTimeSummaryArgs = {
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryDefectStatusAnalysisSummaryArgs = {
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryDefectSubconAnalysisSummaryArgs = {
  siteID: Scalars['String'];
};


export type QueryDefectTradeAnalysisSummaryArgs = {
  siteID: Scalars['String'];
};


export type QueryGetDefectArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  defectStatus?: Maybe<DefectStatus>;
};


export type QueryGetDefectAssignmentArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  defectID?: Maybe<Scalars['String']>;
};


export type QueryGetDefectMgmtArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  defectStatus?: Maybe<DefectStatus>;
};


export type QueryGetSubconDefectArgs = {
  subcontractID: Scalars['String'];
};


export type QueryGetWorkerArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryCheckingWageTypeArgs = {
  workerID?: Maybe<Scalars['String']>;
};


export type QueryGetWorkerAttendanceArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  workDate?: Maybe<Scalars['DateTime']>;
};


export type QueryGetGrnArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetGrnItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetUomExchangeListArgs = {
  uomID: Scalars['String'];
};


export type QueryGetCostItemMovementCategoriesArgs = {
  siteID: Scalars['String'];
};


export type QueryGetCostItemMovementAnalysisArgs = {
  siteID: Scalars['String'];
  costItemID?: Maybe<Scalars['String']>;
};


export type QueryCostItemMovementCheckingArgs = {
  uomID: Scalars['String'];
  costItemID: Scalars['String'];
  siteID: Scalars['String'];
};


export type QueryGetCostItemMovementArgs = {
  stockMovementType: Scalars['String'];
  siteID: Scalars['String'];
};


export type QueryGetCostItemInStockArgs = {
  siteID: Scalars['String'];
};


export type QueryGetCostItemCategoryArgs = {
  costID?: Maybe<Scalars['String']>;
  siteID: Scalars['String'];
};


export type QueryGetStockMovementArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
  stockMovementType?: Maybe<StockMovementType>;
};


export type QueryGetStockMovementCategoriesArgs = {
  siteID: Scalars['String'];
};


export type QueryGetStockMovementAnalysisArgs = {
  siteID: Scalars['String'];
  siteMaterialID?: Maybe<Scalars['String']>;
};


export type QueryStockMovementCheckingArgs = {
  uomID: Scalars['String'];
  siteMaterialID: Scalars['String'];
  siteID: Scalars['String'];
};


export type QueryGetSiteMaterialInStockArgs = {
  siteID: Scalars['String'];
};


export type QueryGetCheckInStockArgs = {
  siteID: Scalars['String'];
  costItemID?: Maybe<Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
};


export type QueryGetStockCategoryArgs = {
  siteMaterialID?: Maybe<Scalars['String']>;
  siteID: Scalars['String'];
};


export type QueryGetSiteProgressArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetSiteStockLedgerArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
};


export type QueryGetSiteStockItemsArgs = {
  siteID: Scalars['String'];
};


export type QueryGetStockItemDetailArgs = {
  uomID: Scalars['String'];
  siteID: Scalars['String'];
  costItemID: Scalars['String'];
};


export type QueryGetDoBySiteIdArgs = {
  siteID: Scalars['String'];
};


export type QueryGetSiteWorkerAttendanceArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  siteWorkerID?: Maybe<Scalars['String']>;
  createdTs?: Maybe<Scalars['DateTime']>;
};


export type QueryGetSiteWorkersArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetCalendarArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
};


export type QueryGetCalendarPhPolicyArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  calendarID?: Maybe<Scalars['String']>;
  publicHolidayID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetCalendarWeekendPolicyArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetHolidayTypeArgs = {
  commonStatus?: Maybe<CommonStatus>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetJobTypeArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetPublicHolidayArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['DateTime']>;
};


export type QueryGetPublicHolidayByStateYearArgs = {
  year: Scalars['String'];
};


export type QueryGetSiteArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetProjectSiteArgs = {
  siteID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetSiteMaterialArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetVideoCamArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetWagesRatePolicyArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetWorkingHourPolicyArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetSiteImageArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetWageCycleArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
};


export type QueryGetWorkerWageAdvanceArgs = {
  workerID: Scalars['String'];
};


export type QueryGetUnpaidWageAdvanceArgs = {
  workerID: Scalars['String'];
};


export type QueryGetUnpaidWorkerJsonArgs = {
  siteID: Scalars['String'];
};


export type QueryGetUnpaidWorkerArgs = {
  siteID: Scalars['String'];
};


export type QueryGetPaidWorkerArgs = {
  wageCycleID: Scalars['String'];
  siteID: Scalars['String'];
};


export type QueryGetWageCycleDetailArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetWorkerCycleDetailArgs = {
  workerID: Scalars['String'];
  wageCycleID: Scalars['String'];
};


export type QueryGetWorkerAdvanceArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
};


export type QueryGetWorkerWageArgs = {
  workDate?: Maybe<Scalars['DateTime']>;
  siteID?: Maybe<Scalars['String']>;
  workerID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetWorkerWageVerificationArgs = {
  workDate?: Maybe<Scalars['DateTime']>;
  siteID?: Maybe<Scalars['String']>;
  workerID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetWorkerWageBookArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetWorkerSalaryArgs = {
  workerWageType?: Maybe<Scalars['String']>;
  workerWageRate?: Maybe<Scalars['Float']>;
  workDate?: Maybe<Scalars['DateTime']>;
  workHour?: Maybe<Scalars['Float']>;
  siteID?: Maybe<Scalars['String']>;
};

export enum SitePermission {
  DefectMgmtIcon = 'DEFECT_MGMT__________ICON',
  DefectMgmtView = 'DEFECT_MGMT__________VIEW',
  DefectMgmtCreate = 'DEFECT_MGMT__________CREATE',
  DefectMgmtUpdate = 'DEFECT_MGMT__________UPDATE',
  DefectMgmtActivityLogCreate = 'DEFECT_MGMT_ACTIVITY_LOG_______CREATE',
  DefectMgmtActivityLogView = 'DEFECT_MGMT_ACTIVITY_LOG_______VIEW',
  DefectMgmtActivityLogUpdate = 'DEFECT_MGMT_ACTIVITY_LOG_______UPDATE',
  DefectMgmtDefectAssignmentView = 'DEFECT_MGMT_DEFECT_ASSIGNMENT_______VIEW',
  DefectMgmtDefectAssignmentCreate = 'DEFECT_MGMT_DEFECT_ASSIGNMENT_______CREATE',
  DefectMgmtGeneralSettingsIcon = 'DEFECT_MGMT_GENERAL_SETTINGS_______ICON',
  DefectMgmtGeneralSettingsSubcontractPackageView = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____VIEW',
  DefectMgmtGeneralSettingsSubcontractPackageCreate = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____CREATE',
  DefectMgmtGeneralSettingsSubcontractPackageUpdate = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____UPDATE',
  DefectMgmtGeneralSettingsSubcontractPackageDelete = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____DELETE',
  DefectMgmtGeneralSettingsSubcontractPackageDownload = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____DOWNLOAD',
  DefectMgmtGeneralSettingsSubcontractPackageUpload = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____UPLOAD',
  DefectMgmtContractSettingsIcon = 'DEFECT_MGMT_CONTRACT_SETTINGS_______ICON',
  SiteAttendanceIcon = 'SITE_ATTENDANCE__________ICON',
  SiteAttendanceView = 'SITE_ATTENDANCE__________VIEW',
  SiteAttendanceWorkerProfileCreate = 'SITE_ATTENDANCE_WORKER_PROFILE_______CREATE',
  SiteAttendanceWorkerProfileView = 'SITE_ATTENDANCE_WORKER_PROFILE_______VIEW',
  SiteAttendanceWorkerProfileUpdate = 'SITE_ATTENDANCE_WORKER_PROFILE_______UPDATE',
  SiteAttendanceWorkerProfileDelete = 'SITE_ATTENDANCE_WORKER_PROFILE_______DELETE',
  SiteAttendanceWorkerProfileDownload = 'SITE_ATTENDANCE_WORKER_PROFILE_______DOWNLOAD',
  SiteAttendanceWorkerProfileUpload = 'SITE_ATTENDANCE_WORKER_PROFILE_______UPLOAD',
  SiteAttendanceWorkerAttendanceCreate = 'SITE_ATTENDANCE_WORKER_ATTENDANCE_______CREATE',
  SiteAttendanceWorkerAttendanceView = 'SITE_ATTENDANCE_WORKER_ATTENDANCE_______VIEW',
  SiteAttendanceWorkerAttendanceExport = 'SITE_ATTENDANCE_WORKER_ATTENDANCE_______EXPORT',
  SiteAttendanceGeneralSettingsIcon = 'SITE_ATTENDANCE_GENERAL_SETTINGS_______ICON',
  SiteAttendanceGeneralSettingsPublicHolidayCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____CREATE',
  SiteAttendanceGeneralSettingsPublicHolidayView = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____VIEW',
  SiteAttendanceGeneralSettingsPublicHolidayUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____UPDATE',
  SiteAttendanceGeneralSettingsPublicHolidayDelete = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____DELETE',
  SiteAttendanceGeneralSettingsPublicHolidayCancel = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____CANCEL',
  SiteAttendanceGeneralSettingsPublicHolidayActive = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____ACTIVE',
  SiteAttendanceGeneralSettingsCalendarPolicyCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_CALENDAR_POLICY____CREATE',
  SiteAttendanceGeneralSettingsCalendarPolicyView = 'SITE_ATTENDANCE_GENERAL_SETTINGS_CALENDAR_POLICY____VIEW',
  SiteAttendanceGeneralSettingsCalendarPolicyUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_CALENDAR_POLICY____UPDATE',
  SiteAttendanceGeneralSettingsWorkingHoursPolicyCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_WORKING_HOURS_POLICY____CREATE',
  SiteAttendanceGeneralSettingsWorkingHoursPolicyUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_WORKING_HOURS_POLICY____UPDATE',
  SiteAttendanceGeneralSettingsJobTypeCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____CREATE',
  SiteAttendanceGeneralSettingsJobTypeView = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____VIEW',
  SiteAttendanceGeneralSettingsJobTypeUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____UPDATE',
  SiteAttendanceGeneralSettingsJobTypeDelete = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____DELETE',
  SiteAttendanceGeneralSettingsJobTypeCancel = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____CANCEL',
  SiteAttendanceGeneralSettingsJobTypeActive = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____ACTIVE',
  SiteAttendanceContractSettingsIcon = 'SITE_ATTENDANCE_CONTRACT_SETTINGS_______ICON',
  SiteWagesIcon = 'SITE_WAGES__________ICON',
  SiteWagesView = 'SITE_WAGES__________VIEW',
  SiteWagesDailyRegisteredWorkHoursCreate = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______CREATE',
  SiteWagesDailyRegisteredWorkHoursView = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______VIEW',
  SiteWagesDailyRegisteredWorkHoursUpdate = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______UPDATE',
  SiteWagesDailyRegisteredWorkHoursDelete = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______DELETE',
  SiteWagesDailyWagesVerificationCreate = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______CREATE',
  SiteWagesDailyWagesVerificationView = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______VIEW',
  SiteWagesDailyWagesVerificationUpdate = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______UPDATE',
  SiteWagesDailyWagesVerificationDelete = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______DELETE',
  SiteWagesWagesBookView = 'SITE_WAGES_WAGES_BOOK_______VIEW',
  SiteWagesWagesBookCreate = 'SITE_WAGES_WAGES_BOOK_______CREATE',
  SiteWagesWagesBookUpdate = 'SITE_WAGES_WAGES_BOOK_______UPDATE',
  SiteWagesWagesBookDelete = 'SITE_WAGES_WAGES_BOOK_______DELETE',
  SiteWagesWageCycleClosureCreate = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______CREATE',
  SiteWagesWageCycleClosureView = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______VIEW',
  SiteWagesWageCycleClosureUpdate = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______UPDATE',
  SiteWagesWageCycleClosureDelete = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______DELETE',
  SiteWagesGeneralSettingsIcon = 'SITE_WAGES_GENERAL_SETTINGS_______ICON',
  SiteWagesGeneralSettingsPublicHolidayCreate = 'SITE_WAGES_GENERAL_SETTINGS_PUBLIC_HOLIDAY____CREATE',
  SiteWagesGeneralSettingsPublicHolidayView = 'SITE_WAGES_GENERAL_SETTINGS_PUBLIC_HOLIDAY____VIEW',
  SiteWagesGeneralSettingsPublicHolidayUpdate = 'SITE_WAGES_GENERAL_SETTINGS_PUBLIC_HOLIDAY____UPDATE',
  SiteWagesGeneralSettingsPublicHolidayDelete = 'SITE_WAGES_GENERAL_SETTINGS_PUBLIC_HOLIDAY____DELETE',
  SiteWagesGeneralSettingsPublicHolidayCancel = 'SITE_WAGES_GENERAL_SETTINGS_PUBLIC_HOLIDAY____CANCEL',
  SiteWagesGeneralSettingsPublicHolidayActive = 'SITE_WAGES_GENERAL_SETTINGS_PUBLIC_HOLIDAY____ACTIVE',
  SiteWagesGeneralSettingsCalendarPolicyCreate = 'SITE_WAGES_GENERAL_SETTINGS_CALENDAR_POLICY____CREATE',
  SiteWagesGeneralSettingsCalendarPolicyView = 'SITE_WAGES_GENERAL_SETTINGS_CALENDAR_POLICY____VIEW',
  SiteWagesGeneralSettingsCalendarPolicyUpdate = 'SITE_WAGES_GENERAL_SETTINGS_CALENDAR_POLICY____UPDATE',
  SiteWagesGeneralSettingsWorkingHoursPolicyCreate = 'SITE_WAGES_GENERAL_SETTINGS_WORKING_HOURS_POLICY____CREATE',
  SiteWagesGeneralSettingsWorkingHoursPolicyUpdate = 'SITE_WAGES_GENERAL_SETTINGS_WORKING_HOURS_POLICY____UPDATE',
  SiteWagesGeneralSettingsWagesRatePolicyCreate = 'SITE_WAGES_GENERAL_SETTINGS_WAGES_RATE_POLICY____CREATE',
  SiteWagesGeneralSettingsWagesRatePolicyView = 'SITE_WAGES_GENERAL_SETTINGS_WAGES_RATE_POLICY____VIEW',
  SiteWagesGeneralSettingsWagesRatePolicyUpdate = 'SITE_WAGES_GENERAL_SETTINGS_WAGES_RATE_POLICY____UPDATE',
  SiteWagesContractSettingsIcon = 'SITE_WAGES_CONTRACT_SETTINGS_______ICON',
  SiteInventoryIcon = 'SITE_INVENTORY__________ICON',
  SiteInventoryView = 'SITE_INVENTORY__________VIEW',
  SiteInventoryGoodReceiveNoteCreate = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______CREATE',
  SiteInventoryGoodReceiveNoteView = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______VIEW',
  SiteInventoryGoodReceiveNoteUpdate = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______UPDATE',
  SiteInventoryGoodReceiveNoteApproveReject = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______APPROVE_REJECT',
  SiteInventoryGoodReceiveNoteCancel = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______CANCEL',
  SiteInventoryGoodReceiveNoteDelete = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______DELETE',
  SiteInventoryStockIssueCreate = 'SITE_INVENTORY_STOCK_ISSUE_______CREATE',
  SiteInventoryStockIssueView = 'SITE_INVENTORY_STOCK_ISSUE_______VIEW',
  SiteInventoryStockIssueUpdate = 'SITE_INVENTORY_STOCK_ISSUE_______UPDATE',
  SiteInventoryStockIssueDelete = 'SITE_INVENTORY_STOCK_ISSUE_______DELETE',
  SiteInventoryStockIssueDownload = 'SITE_INVENTORY_STOCK_ISSUE_______DOWNLOAD',
  SiteInventoryStockTransferInCreate = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______CREATE',
  SiteInventoryStockTransferInView = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______VIEW',
  SiteInventoryStockTransferInUpdate = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______UPDATE',
  SiteInventoryStockTransferInDelete = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______DELETE',
  SiteInventoryStockTransferInDownload = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______DOWNLOAD',
  SiteInventoryStockTransferOutCreate = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______CREATE',
  SiteInventoryStockTransferOutView = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______VIEW',
  SiteInventoryStockTransferOutUpdate = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______UPDATE',
  SiteInventoryStockTransferOutDelete = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______DELETE',
  SiteInventoryStockTransferOutDownload = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______DOWNLOAD',
  SiteInventoryStockAdjustmentCreate = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______CREATE',
  SiteInventoryStockAdjustmentView = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______VIEW',
  SiteInventoryStockAdjustmentUpdate = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______UPDATE',
  SiteInventoryStockAdjustmentDelete = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______DELETE',
  SiteInventoryStockAdjustmentDownload = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______DOWNLOAD',
  SiteInventoryStockMovementCreate = 'SITE_INVENTORY_STOCK_MOVEMENT_______CREATE',
  SiteInventoryStockMovementView = 'SITE_INVENTORY_STOCK_MOVEMENT_______VIEW',
  SiteInventoryStockMovementUpdate = 'SITE_INVENTORY_STOCK_MOVEMENT_______UPDATE',
  SiteInventoryStockMovementDelete = 'SITE_INVENTORY_STOCK_MOVEMENT_______DELETE',
  SiteInventoryStockUsageAnalysisCreate = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______CREATE',
  SiteInventoryStockUsageAnalysisView = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______VIEW',
  SiteInventoryStockUsageAnalysisUpdate = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______UPDATE',
  SiteInventoryStockUsageAnalysisDelete = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______DELETE',
  SiteInventoryStockUsageAnalysisExport = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______EXPORT',
  SiteInventoryGeneralSettingsIcon = 'SITE_INVENTORY_GENERAL_SETTINGS_______ICON',
  SiteInventoryGeneralSettingsSiteMaterialCreate = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____CREATE',
  SiteInventoryGeneralSettingsSiteMaterialView = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____VIEW',
  SiteInventoryGeneralSettingsSiteMaterialUpdate = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____UPDATE',
  SiteInventoryGeneralSettingsSiteMaterialDelete = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____DELETE',
  SiteInventoryGeneralSettingsSiteMaterialCancel = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____CANCEL',
  SiteInventoryGeneralSettingsSiteMaterialActive = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____ACTIVE',
  SiteInventoryGeneralSettingsSiteMaterialDownload = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____DOWNLOAD',
  SiteInventoryGeneralSettingsSiteMaterialUpload = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____UPLOAD',
  SiteInventoryContractSettingsIcon = 'SITE_INVENTORY_CONTRACT_SETTINGS_______ICON',
  SiteSurveillanceIcon = 'SITE_SURVEILLANCE__________ICON',
  SiteSurveillanceView = 'SITE_SURVEILLANCE__________VIEW',
  SiteSurveillanceSiteImagesCreate = 'SITE_SURVEILLANCE_SITE_IMAGES_______CREATE',
  SiteSurveillanceSiteImagesView = 'SITE_SURVEILLANCE_SITE_IMAGES_______VIEW',
  SiteSurveillanceSiteImagesUpdate = 'SITE_SURVEILLANCE_SITE_IMAGES_______UPDATE',
  SiteSurveillanceSiteImagesDelete = 'SITE_SURVEILLANCE_SITE_IMAGES_______DELETE',
  SiteSurveillanceLiveVideocamView = 'SITE_SURVEILLANCE_LIVE_VIDEOCAM_______VIEW',
  SiteSurveillanceGeneralSettingsIcon = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_______ICON',
  SiteSurveillanceGeneralSettingsVideoCamSetupCreate = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____CREATE',
  SiteSurveillanceGeneralSettingsVideoCamSetupView = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____VIEW',
  SiteSurveillanceGeneralSettingsVideoCamSetupUpdate = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____UPDATE',
  SiteSurveillanceGeneralSettingsVideoCamSetupDelete = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____DELETE',
  SiteSurveillanceContractSettingsIcon = 'SITE_SURVEILLANCE_CONTRACT_SETTINGS_______ICON',
  BusinessInsightIcon = 'BUSINESS_INSIGHT__________ICON',
  BusinessInsightView = 'BUSINESS_INSIGHT__________VIEW',
  BusinessInsightDefectStatusAnalysisView = 'BUSINESS_INSIGHT_DEFECT_STATUS_ANALYSIS__________VIEW',
  BusinessInsightDefectStatusAnalysisExport = 'BUSINESS_INSIGHT_DEFECT_STATUS_ANALYSIS__________EXPORT',
  BusinessInsightDefectTradeAnalysisView = 'BUSINESS_INSIGHT_DEFECT_TRADE_ANALYSIS__________VIEW',
  BusinessInsightDefectTradeAnalysisExport = 'BUSINESS_INSIGHT_DEFECT_TRADE_ANALYSIS__________EXPORT',
  BusinessInsightDefectSubconAnalysisView = 'BUSINESS_INSIGHT_DEFECT_SUBCON_ANALYSIS__________VIEW',
  BusinessInsightDefectSubconAnalysisExport = 'BUSINESS_INSIGHT_DEFECT_SUBCON_ANALYSIS__________EXPORT',
  BusinessInsightDefectsLodgedOverTimeView = 'BUSINESS_INSIGHT_DEFECTS_LODGED_OVER_TIME__________VIEW',
  BusinessInsightDefectsLodgedOverTimeExport = 'BUSINESS_INSIGHT_DEFECTS_LODGED_OVER_TIME__________EXPORT',
  BusinessInsightGeneralSettingsIcon = 'BUSINESS_INSIGHT_GENERAL_SETTINGS__________ICON',
  BusinessInsightContractSettingsIcon = 'BUSINESS_INSIGHT_CONTRACT_SETTINGS__________ICON',
  DigitalDocumentIcon = 'DIGITAL_DOCUMENT__________ICON',
  DigitalDocumentView = 'DIGITAL_DOCUMENT__________VIEW',
  DigitalDocumentCreate = 'DIGITAL_DOCUMENT__________CREATE',
  DigitalDocumentDelete = 'DIGITAL_DOCUMENT__________DELETE',
  DigitalDocumentGeneralSettingsIcon = 'DIGITAL_DOCUMENT_GENERAL_SETTINGS__________ICON',
  DigitalDocumentContractSettingsIcon = 'DIGITAL_DOCUMENT_CONTRACT_SETTINGS__________ICON',
  SystemAdminIcon = 'SYSTEM_ADMIN__________ICON',
  SystemAdminView = 'SYSTEM_ADMIN__________VIEW',
  SystemAdminAccessSecurityView = 'SYSTEM_ADMIN_ACCESS_SECURITY_______VIEW',
  SystemAdminAccessSecurityUserView = 'SYSTEM_ADMIN_ACCESS_SECURITY_USER____VIEW',
  SystemAdminAccessSecurityUserCreate = 'SYSTEM_ADMIN_ACCESS_SECURITY_USER____CREATE',
  SystemAdminAccessSecurityUserUpdate = 'SYSTEM_ADMIN_ACCESS_SECURITY_USER____UPDATE',
  SystemAdminAccessSecurityUserUnblock = 'SYSTEM_ADMIN_ACCESS_SECURITY_USER____UNBLOCK',
  SystemAdminAccessSecurityUserChangePassword = 'SYSTEM_ADMIN_ACCESS_SECURITY_USER____CHANGE_PASSWORD',
  SystemAdminAccessSecurityRolesView = 'SYSTEM_ADMIN_ACCESS_SECURITY_ROLES____VIEW',
  SystemAdminAccessSecurityRolesCreate = 'SYSTEM_ADMIN_ACCESS_SECURITY_ROLES____CREATE',
  SystemAdminAccessSecurityRolesUpdate = 'SYSTEM_ADMIN_ACCESS_SECURITY_ROLES____UPDATE',
  SystemAdminAccessSecurityRolesDelete = 'SYSTEM_ADMIN_ACCESS_SECURITY_ROLES____DELETE',
  SystemAdminAccessSecurityProjectRoleAuthView = 'SYSTEM_ADMIN_ACCESS_SECURITY_PROJECT_ROLE_AUTH____VIEW',
  SystemAdminAccessSecurityProjectRoleAuthCreate = 'SYSTEM_ADMIN_ACCESS_SECURITY_PROJECT_ROLE_AUTH____CREATE',
  SystemAdminAccessSecurityProjectRoleAuthUpdate = 'SYSTEM_ADMIN_ACCESS_SECURITY_PROJECT_ROLE_AUTH____UPDATE',
  SystemAdminProjectSiteView = 'SYSTEM_ADMIN_PROJECT_SITE_______VIEW',
  SystemAdminProjectSiteCreate = 'SYSTEM_ADMIN_PROJECT_SITE_______CREATE',
  SystemAdminProjectSiteUpdate = 'SYSTEM_ADMIN_PROJECT_SITE_______UPDATE',
  SystemAdminProjectSiteDelete = 'SYSTEM_ADMIN_PROJECT_SITE_______DELETE',
  SystemAdminSubconSetupView = 'SYSTEM_ADMIN_SUBCON_SETUP_______VIEW',
  SystemAdminSubconSetupCreate = 'SYSTEM_ADMIN_SUBCON_SETUP_______CREATE',
  SystemAdminSubconSetupUpdate = 'SYSTEM_ADMIN_SUBCON_SETUP_______UPDATE',
  SystemAdminSubconSetupDelete = 'SYSTEM_ADMIN_SUBCON_SETUP_______DELETE',
  SystemAdminUomSetupView = 'SYSTEM_ADMIN_UOM_SETUP_______VIEW',
  SystemAdminUomSetupCreate = 'SYSTEM_ADMIN_UOM_SETUP_______CREATE',
  SystemAdminUomSetupUpdate = 'SYSTEM_ADMIN_UOM_SETUP_______UPDATE',
  SystemAdminUomSetupDelete = 'SYSTEM_ADMIN_UOM_SETUP_______DELETE',
  SystemAdminUomSetupActive = 'SYSTEM_ADMIN_UOM_SETUP_______ACTIVE',
  SystemAdminUomSetupInactive = 'SYSTEM_ADMIN_UOM_SETUP_______INACTIVE',
  SystemAdminUomSetupDownload = 'SYSTEM_ADMIN_UOM_SETUP_______DOWNLOAD',
  SystemAdminUomSetupUpload = 'SYSTEM_ADMIN_UOM_SETUP_______UPLOAD',
  SystemAdminUomConversionView = 'SYSTEM_ADMIN_UOM_CONVERSION_______VIEW',
  SystemAdminUomConversionCreate = 'SYSTEM_ADMIN_UOM_CONVERSION_______CREATE',
  SystemAdminUomConversionUpdate = 'SYSTEM_ADMIN_UOM_CONVERSION_______UPDATE',
  SystemAdminUomConversionDelete = 'SYSTEM_ADMIN_UOM_CONVERSION_______DELETE',
  SystemAdminCompanySetupView = 'SYSTEM_ADMIN_COMPANY_SETUP_______VIEW',
  SystemAdminCompanySetupUpdate = 'SYSTEM_ADMIN_COMPANY_SETUP_______UPDATE',
  SystemAdminCompanySetupWatermarkLogoSetupView = 'SYSTEM_ADMIN_COMPANY_SETUP_WATERMARK_LOGO_SETUP____VIEW',
  SystemAdminGeneralSettingsIcon = 'SYSTEM_ADMIN_GENERAL_SETTINGS_______ICON',
  SystemAdminGeneralSettingsSubcontractTradeView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACT_TRADE____VIEW',
  SystemAdminGeneralSettingsSubcontractTradeCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACT_TRADE____CREATE',
  SystemAdminGeneralSettingsSubcontractTradeUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPDATE',
  SystemAdminGeneralSettingsSubcontractTradeInactive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACT_TRADE____INACTIVE',
  SystemAdminGeneralSettingsSubcontractTradeDelete = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DELETE',
  SystemAdminGeneralSettingsSubcontractTradeDownload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DOWNLOAD',
  SystemAdminGeneralSettingsSubcontractTradeUpload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPLOAD',
  SystemAdminGeneralSettingsPublicHolidayView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PUBLIC_HOLIDAY____VIEW',
  SystemAdminGeneralSettingsPublicHolidayCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PUBLIC_HOLIDAY____CREATE',
  SystemAdminGeneralSettingsPublicHolidayUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PUBLIC_HOLIDAY____UPDATE',
  SystemAdminGeneralSettingsPublicHolidayInactive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PUBLIC_HOLIDAY____INACTIVE',
  SystemAdminGeneralSettingsPublicHolidayDelete = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PUBLIC_HOLIDAY____DELETE',
  SystemAdminGeneralSettingsCalendarPolicyView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_CALENDAR_POLICY____VIEW',
  SystemAdminGeneralSettingsCalendarPolicyCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_CALENDAR_POLICY____CREATE',
  SystemAdminGeneralSettingsCalendarPolicyUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_CALENDAR_POLICY____UPDATE',
  SystemAdminGeneralSettingsWorkingHoursPolicyUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_WORKING_HOURS_POLICY____UPDATE',
  SystemAdminGeneralSettingsJobTypeView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_JOB_TYPE____VIEW',
  SystemAdminGeneralSettingsJobTypeCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_JOB_TYPE____CREATE',
  SystemAdminGeneralSettingsJobTypeUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_JOB_TYPE____UPDATE',
  SystemAdminGeneralSettingsJobTypeInactive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_JOB_TYPE____INACTIVE',
  SystemAdminGeneralSettingsJobTypeDelete = 'SYSTEM_ADMIN_GENERAL_SETTINGS_JOB_TYPE____DELETE',
  SystemAdminGeneralSettingsWagesRatePolicyUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_WAGES_RATE_POLICY____UPDATE',
  SystemAdminGeneralSettingsBuildingMaterialView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_BUILDING_MATERIAL____VIEW',
  SystemAdminGeneralSettingsBuildingMaterialCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_BUILDING_MATERIAL____CREATE',
  SystemAdminGeneralSettingsBuildingMaterialUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_BUILDING_MATERIAL____UPDATE',
  SystemAdminGeneralSettingsBuildingMaterialInactive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_BUILDING_MATERIAL____INACTIVE',
  SystemAdminGeneralSettingsBuildingMaterialDownload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_BUILDING_MATERIAL____DOWNLOAD',
  SystemAdminGeneralSettingsBuildingMaterialUpload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_BUILDING_MATERIAL____UPLOAD',
  SystemAdminGeneralSettingsSiteBuildingMaterialUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____UPDATE',
  SystemAdminGeneralSettingsSiteApprovalPolicyUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SITE_APPROVAL_POLICY____UPDATE',
  SystemAdminGeneralSettingsSiteApprovalPolicyCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SITE_APPROVAL_POLICY____CREATE',
  SystemAdminGeneralSettingsVideoCamSetupUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_VIDEO_CAM_SETUP____UPDATE',
  SystemAdminGeneralSettingsVideoCamSetupCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_VIDEO_CAM_SETUP____CREATE',
  SystemAdminGeneralSettingsVideoCamSetupDelete = 'SYSTEM_ADMIN_GENERAL_SETTINGS_VIDEO_CAM_SETUP____DELETE'
}

export enum ContPermission {
  ContractMgmtIcon = 'CONTRACT_MGMT__________ICON',
  ContractMgmtView = 'CONTRACT_MGMT__________VIEW',
  ContractMgmtCreate = 'CONTRACT_MGMT__________CREATE',
  ContractMgmtContractInfoView = 'CONTRACT_MGMT_CONTRACT_INFO_______VIEW',
  ContractMgmtContractInfoUpdate = 'CONTRACT_MGMT_CONTRACT_INFO_______UPDATE',
  ContractMgmtContractGuaranteeView = 'CONTRACT_MGMT_CONTRACT_GUARANTEE_______VIEW',
  ContractMgmtContractGuaranteeCreate = 'CONTRACT_MGMT_CONTRACT_GUARANTEE_______CREATE',
  ContractMgmtContractGuaranteeUpdate = 'CONTRACT_MGMT_CONTRACT_GUARANTEE_______UPDATE',
  ContractMgmtContractGuaranteeCancel = 'CONTRACT_MGMT_CONTRACT_GUARANTEE_______CANCEL',
  ContractMgmtContractInsuranceView = 'CONTRACT_MGMT_CONTRACT_INSURANCE_______VIEW',
  ContractMgmtContractInsuranceCreate = 'CONTRACT_MGMT_CONTRACT_INSURANCE_______CREATE',
  ContractMgmtContractInsuranceUpdate = 'CONTRACT_MGMT_CONTRACT_INSURANCE_______UPDATE',
  ContractMgmtContractInsuranceCancel = 'CONTRACT_MGMT_CONTRACT_INSURANCE_______CANCEL',
  ContractMgmtContractVoView = 'CONTRACT_MGMT_CONTRACT_VO_______VIEW',
  ContractMgmtContractVoCreate = 'CONTRACT_MGMT_CONTRACT_VO_______CREATE',
  ContractMgmtContractVoUpdate = 'CONTRACT_MGMT_CONTRACT_VO_______UPDATE',
  ContractMgmtContractVoApproveReject = 'CONTRACT_MGMT_CONTRACT_VO_______APPROVE_REJECT',
  ContractMgmtContractVoHealthIndexView = 'CONTRACT_MGMT_CONTRACT_VO_HEALTH_INDEX_______VIEW',
  ContractMgmtContractAccStatusView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_______VIEW',
  ContractMgmtContractAccStatusClientProfileView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_CLIENT_PROFILE____VIEW',
  ContractMgmtContractAccStatusAdvancesView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_ADVANCES____VIEW',
  ContractMgmtContractAccStatusProgressClaimView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_PROGRESS_CLAIM____VIEW',
  ContractMgmtContractAccStatusRetentionClaimsView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_RETENTION_CLAIMS____VIEW',
  ContractMgmtContractAccStatusMiscellaneousInvoiceView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_MISCELLANEOUS_INVOICE____VIEW',
  ContractMgmtContractAccStatusDebitNotesView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_DEBIT_NOTES____VIEW',
  ContractMgmtContractAccStatusCreditNotesView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_CREDIT_NOTES____VIEW',
  ContractMgmtContractAccStatusReceiptsView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_RECEIPTS____VIEW',
  ContractMgmtContractAccStatusRefundsView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_REFUNDS____VIEW',
  ContractMgmtContractAccStatusClientDebitNotesView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_CLIENT_DEBIT_NOTES____VIEW',
  ContractMgmtContractAccStatusClientCreditNotesView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_CLIENT_CREDIT_NOTES____VIEW',
  ContractMgmtContractAccStatusOsReceivableView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_OS_RECEIVABLE____VIEW',
  ContractMgmtMiscellaneousExpenseView = 'CONTRACT_MGMT_MISCELLANEOUS_EXPENSE_______VIEW',
  ContractMgmtMiscellaneousExpenseCreate = 'CONTRACT_MGMT_MISCELLANEOUS_EXPENSE_______CREATE',
  ContractMgmtMiscellaneousExpenseUpdate = 'CONTRACT_MGMT_MISCELLANEOUS_EXPENSE_______UPDATE',
  ContractMgmtMiscellaneousExpenseCancel = 'CONTRACT_MGMT_MISCELLANEOUS_EXPENSE_______CANCEL',
  ContractMgmtRetentionView = 'CONTRACT_MGMT_RETENTION_______VIEW',
  ContractMgmtRetentionCreate = 'CONTRACT_MGMT_RETENTION_______CREATE',
  ContractMgmtRetentionApproveReject = 'CONTRACT_MGMT_RETENTION_______APPROVE_REJECT',
  ContractMgmtRetentionDelete = 'CONTRACT_MGMT_RETENTION_______DELETE',
  ContractMgmtRetentionUpdate = 'CONTRACT_MGMT_RETENTION_______UPDATE',
  ContractMgmtExtensionOfTimeView = 'CONTRACT_MGMT_EXTENSION_OF_TIME_______VIEW',
  ContractMgmtExtensionOfTimeCreate = 'CONTRACT_MGMT_EXTENSION_OF_TIME_______CREATE',
  ContractMgmtContractClosureCreate = 'CONTRACT_MGMT_CONTRACT_CLOSURE_______CREATE',
  ContractMgmtContractTerminationCreate = 'CONTRACT_MGMT_CONTRACT_TERMINATION_______CREATE',
  ContractMgmtDigitalDocumentsView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_______VIEW',
  ContractMgmtDigitalDocumentsContractInfoView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INFO_______VIEW',
  ContractMgmtDigitalDocumentsContractInfoUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INFO_______UPLOAD',
  ContractMgmtDigitalDocumentsContractInfoDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INFO_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractInfoDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INFO_______DELETE',
  ContractMgmtDigitalDocumentsContractGuaranteeView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_GUARANTEE_______VIEW',
  ContractMgmtDigitalDocumentsContractGuaranteeUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_GUARANTEE_______UPLOAD',
  ContractMgmtDigitalDocumentsContractGuaranteeDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_GUARANTEE_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractGuaranteeDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_GUARANTEE_______DELETE',
  ContractMgmtDigitalDocumentsContractInsuranceView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INSURANCE_______VIEW',
  ContractMgmtDigitalDocumentsContractInsuranceUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INSURANCE_______UPLOAD',
  ContractMgmtDigitalDocumentsContractInsuranceDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INSURANCE_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractInsuranceDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INSURANCE_______DELETE',
  ContractMgmtDigitalDocumentsContractVoView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_VO_______VIEW',
  ContractMgmtDigitalDocumentsContractVoUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_VO_______UPLOAD',
  ContractMgmtDigitalDocumentsContractVoDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_VO_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractVoDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_VO_______DELETE',
  ContractMgmtDigitalDocumentsContractAdvanceView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_ADVANCE_______VIEW',
  ContractMgmtDigitalDocumentsContractAdvanceUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_ADVANCE_______UPLOAD',
  ContractMgmtDigitalDocumentsContractAdvanceDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_ADVANCE_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractAdvanceDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_ADVANCE_______DELETE',
  ContractMgmtDigitalDocumentsContractClaimView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLAIM_______VIEW',
  ContractMgmtDigitalDocumentsContractClaimUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLAIM_______UPLOAD',
  ContractMgmtDigitalDocumentsContractClaimDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLAIM_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractClaimDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLAIM_______DELETE',
  ContractMgmtDigitalDocumentsMiscellaneousInvoiceView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_MISCELLANEOUS_INVOICE_______VIEW',
  ContractMgmtDigitalDocumentsMiscellaneousInvoiceUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_MISCELLANEOUS_INVOICE_______UPLOAD',
  ContractMgmtDigitalDocumentsMiscellaneousInvoiceDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_MISCELLANEOUS_INVOICE_______DOWNLOAD',
  ContractMgmtDigitalDocumentsMiscellaneousInvoiceDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_MISCELLANEOUS_INVOICE_______DELETE',
  ContractMgmtDigitalDocumentsContractDnView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_DN_______VIEW',
  ContractMgmtDigitalDocumentsContractDnUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_DN_______UPLOAD',
  ContractMgmtDigitalDocumentsContractDnDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_DN_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractDnDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_DN_______DELETE',
  ContractMgmtDigitalDocumentsContractCnView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CN_______VIEW',
  ContractMgmtDigitalDocumentsContractCnUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CN_______UPLOAD',
  ContractMgmtDigitalDocumentsContractCnDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CN_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractCnDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CN_______DELETE',
  ContractMgmtDigitalDocumentsContractReceiptView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RECEIPT_______VIEW',
  ContractMgmtDigitalDocumentsContractReceiptUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RECEIPT_______UPLOAD',
  ContractMgmtDigitalDocumentsContractReceiptDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RECEIPT_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractReceiptDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RECEIPT_______DELETE',
  ContractMgmtDigitalDocumentsContractRefundView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_REFUND_______VIEW',
  ContractMgmtDigitalDocumentsContractRefundUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_REFUND_______UPLOAD',
  ContractMgmtDigitalDocumentsContractRefundDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_REFUND_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractRefundDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_REFUND_______DELETE',
  ContractMgmtDigitalDocumentsClientDnView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_DN_______VIEW',
  ContractMgmtDigitalDocumentsClientDnUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_DN_______UPLOAD',
  ContractMgmtDigitalDocumentsClientDnDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_DN_______DOWNLOAD',
  ContractMgmtDigitalDocumentsClientDnDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_DN_______DELETE',
  ContractMgmtDigitalDocumentsClientCnView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_CN_______VIEW',
  ContractMgmtDigitalDocumentsClientCnUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_CN_______UPLOAD',
  ContractMgmtDigitalDocumentsClientCnDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_CN_______DOWNLOAD',
  ContractMgmtDigitalDocumentsClientCnDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_CN_______DELETE',
  ContractMgmtDigitalDocumentsContractRetentionCpcView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CPC_______VIEW',
  ContractMgmtDigitalDocumentsContractRetentionCpcUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CPC_______UPLOAD',
  ContractMgmtDigitalDocumentsContractRetentionCpcDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CPC_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractRetentionCpcDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CPC_______DELETE',
  ContractMgmtDigitalDocumentsContractRetentionDlpView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_DLP_______VIEW',
  ContractMgmtDigitalDocumentsContractRetentionDlpUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_DLP_______UPLOAD',
  ContractMgmtDigitalDocumentsContractRetentionDlpDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_DLP_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractRetentionDlpDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_DLP_______DELETE',
  ContractMgmtDigitalDocumentsContractRetentionCmgView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CMG_______VIEW',
  ContractMgmtDigitalDocumentsContractRetentionCmgUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CMG_______UPLOAD',
  ContractMgmtDigitalDocumentsContractRetentionCmgDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CMG_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractRetentionCmgDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CMG_______DELETE',
  ContractMgmtDigitalDocumentsContractExtensionView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_EXTENSION_______VIEW',
  ContractMgmtDigitalDocumentsContractExtensionUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_EXTENSION_______UPLOAD',
  ContractMgmtDigitalDocumentsContractExtensionDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_EXTENSION_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractExtensionDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_EXTENSION_______DELETE',
  ContractMgmtDigitalDocumentsContractClosureView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLOSURE_______VIEW',
  ContractMgmtDigitalDocumentsContractClosureUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLOSURE_______UPLOAD',
  ContractMgmtDigitalDocumentsContractClosureDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLOSURE_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractClosureDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLOSURE_______DELETE',
  ContractMgmtDigitalDocumentsContractTerminationView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_TERMINATION_______VIEW',
  ContractMgmtDigitalDocumentsContractTerminationUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_TERMINATION_______UPLOAD',
  ContractMgmtDigitalDocumentsContractTerminationDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_TERMINATION_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractTerminationDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_TERMINATION_______DELETE',
  ContractMgmtGeneralSettingsIcon = 'CONTRACT_MGMT_GENERAL_SETTINGS_______ICON',
  ContractMgmtGeneralSettingsSubcontractTradeView = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____VIEW',
  ContractMgmtGeneralSettingsSubcontractTradeCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____CREATE',
  ContractMgmtGeneralSettingsSubcontractTradeUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPDATE',
  ContractMgmtGeneralSettingsSubcontractTradeDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DELETE',
  ContractMgmtGeneralSettingsSubcontractTradeInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____INACTIVE',
  ContractMgmtGeneralSettingsSubcontractTradeActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____ACTIVE',
  ContractMgmtGeneralSettingsSubcontractTradeDownload = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DOWNLOAD',
  ContractMgmtGeneralSettingsSubcontractTradeUpload = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPLOAD',
  ContractMgmtGeneralSettingsLabourCategoryView = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____VIEW',
  ContractMgmtGeneralSettingsLabourCategoryCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____CREATE',
  ContractMgmtGeneralSettingsLabourCategoryUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____UPDATE',
  ContractMgmtGeneralSettingsLabourCategoryDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____DELETE',
  ContractMgmtGeneralSettingsLabourCategoryInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____INACTIVE',
  ContractMgmtGeneralSettingsLabourCategoryActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____ACTIVE',
  ContractMgmtGeneralSettingsLabourCategoryDownload = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____DOWNLOAD',
  ContractMgmtGeneralSettingsLabourCategoryUpload = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____UPLOAD',
  ContractMgmtGeneralSettingsBuildingMaterialView = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____VIEW',
  ContractMgmtGeneralSettingsBuildingMaterialCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____CREATE',
  ContractMgmtGeneralSettingsBuildingMaterialUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____UPDATE',
  ContractMgmtGeneralSettingsBuildingMaterialDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____DELETE',
  ContractMgmtGeneralSettingsBuildingMaterialInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____INACTIVE',
  ContractMgmtGeneralSettingsBuildingMaterialActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____ACTIVE',
  ContractMgmtGeneralSettingsBuildingMaterialDownload = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____DOWNLOAD',
  ContractMgmtGeneralSettingsBuildingMaterialUpload = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____UPLOAD',
  ContractMgmtGeneralSettingsBuildingMaterialCostItemView = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_VIEW',
  ContractMgmtGeneralSettingsBuildingMaterialCostItemCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_CREATE',
  ContractMgmtGeneralSettingsBuildingMaterialCostItemUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_UPDATE',
  ContractMgmtGeneralSettingsBuildingMaterialCostItemDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_DELETE',
  ContractMgmtGeneralSettingsBuildingMaterialCostItemInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_INACTIVE',
  ContractMgmtGeneralSettingsBuildingMaterialCostItemActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_ACTIVE',
  ContractMgmtGeneralSettingsPmCategoryView = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____VIEW',
  ContractMgmtGeneralSettingsPmCategoryCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____CREATE',
  ContractMgmtGeneralSettingsPmCategoryUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____UPDATE',
  ContractMgmtGeneralSettingsPmCategoryDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____DELETE',
  ContractMgmtGeneralSettingsPmCategoryInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____INACTIVE',
  ContractMgmtGeneralSettingsPmCategoryActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____ACTIVE',
  ContractMgmtGeneralSettingsPmCategoryDownload = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____DOWNLOAD',
  ContractMgmtGeneralSettingsPmCategoryUpload = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____UPLOAD',
  ContractMgmtGeneralSettingsMiscellaneousExpenseView = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____VIEW',
  ContractMgmtGeneralSettingsMiscellaneousExpenseCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____CREATE',
  ContractMgmtGeneralSettingsMiscellaneousExpenseUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____UPDATE',
  ContractMgmtGeneralSettingsMiscellaneousExpenseDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____DELETE',
  ContractMgmtGeneralSettingsMiscellaneousExpenseInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____INACTIVE',
  ContractMgmtGeneralSettingsMiscellaneousExpenseActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____ACTIVE',
  ContractMgmtGeneralSettingsMiscellaneousExpenseDownload = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____DOWNLOAD',
  ContractMgmtGeneralSettingsMiscellaneousExpenseUpload = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____UPLOAD',
  ContractMgmtGeneralSettingsGuaranteeTypeView = 'CONTRACT_MGMT_GENERAL_SETTINGS_GUARANTEE_TYPE____VIEW',
  ContractMgmtGeneralSettingsGuaranteeTypeCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_GUARANTEE_TYPE____CREATE',
  ContractMgmtGeneralSettingsGuaranteeTypeUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_GUARANTEE_TYPE____UPDATE',
  ContractMgmtGeneralSettingsGuaranteeTypeActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_GUARANTEE_TYPE____ACTIVE',
  ContractMgmtGeneralSettingsGuaranteeTypeInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_GUARANTEE_TYPE____INACTIVE',
  ContractMgmtGeneralSettingsGuaranteeTypeDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_GUARANTEE_TYPE____DELETE',
  ContractMgmtGeneralSettingsInsuranceTypeView = 'CONTRACT_MGMT_GENERAL_SETTINGS_INSURANCE_TYPE____VIEW',
  ContractMgmtGeneralSettingsInsuranceTypeCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_INSURANCE_TYPE____CREATE',
  ContractMgmtGeneralSettingsInsuranceTypeUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_INSURANCE_TYPE____UPDATE',
  ContractMgmtGeneralSettingsInsuranceTypeDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_INSURANCE_TYPE____DELETE',
  ContractMgmtGeneralSettingsInsuranceTypeActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_INSURANCE_TYPE____ACTIVE',
  ContractMgmtGeneralSettingsInsuranceTypeInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_INSURANCE_TYPE____INACTIVE',
  ProfitRecognitionIcon = 'PROFIT_RECOGNITION__________ICON',
  ProfitRecognitionView = 'PROFIT_RECOGNITION__________VIEW',
  ProfitRecognitionProfitRecognitionAssignmentView = 'PROFIT_RECOGNITION_PROFIT_RECOGNITION_ASSIGNMENT__________VIEW',
  ProfitRecognitionProfitRecognitionAssignmentUpdate = 'PROFIT_RECOGNITION_PROFIT_RECOGNITION_ASSIGNMENT__________UPDATE',
  ProfitRecognitionNumberingStructureView = 'PROFIT_RECOGNITION_NUMBERING_STRUCTURE__________VIEW',
  ProfitRecognitionNumberingStructureUpdate = 'PROFIT_RECOGNITION_NUMBERING_STRUCTURE__________UPDATE',
  ProfitRecognitionProfitRecognitionGenerationView = 'PROFIT_RECOGNITION_PROFIT_RECOGNITION_GENERATION__________VIEW',
  ProfitRecognitionProfitRecognitionGenerationCreate = 'PROFIT_RECOGNITION_PROFIT_RECOGNITION_GENERATION__________CREATE',
  ProfitRecognitionProfitRecognitionGenerationUpdate = 'PROFIT_RECOGNITION_PROFIT_RECOGNITION_GENERATION__________UPDATE',
  ProfitRecognitionGeneralSettingsIcon = 'PROFIT_RECOGNITION_GENERAL_SETTINGS__________ICON',
  ClientAcctIcon = 'CLIENT_ACCT__________ICON',
  ClientAcctView = 'CLIENT_ACCT__________VIEW',
  ClientAcctClientProfileView = 'CLIENT_ACCT_CLIENT_PROFILE_______VIEW',
  ClientAcctClientProfileCreate = 'CLIENT_ACCT_CLIENT_PROFILE_______CREATE',
  ClientAcctClientProfileUpdate = 'CLIENT_ACCT_CLIENT_PROFILE_______UPDATE',
  ClientAcctAccTransactionsView = 'CLIENT_ACCT_ACC_TRANSACTIONS_______VIEW',
  ClientAcctAccTransactionsAdvancesView = 'CLIENT_ACCT_ACC_TRANSACTIONS_ADVANCES____VIEW',
  ClientAcctAccTransactionsAdvancesCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_ADVANCES____CREATE',
  ClientAcctAccTransactionsAdvancesUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_ADVANCES____UPDATE',
  ClientAcctAccTransactionsAdvancesApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_ADVANCES____APPROVE_REJECT',
  ClientAcctAccTransactionsAdvancesCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_ADVANCES____CANCEL',
  ClientAcctAccTransactionsAdvancesDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_ADVANCES____DELETE',
  ClientAcctAccTransactionsProgressClaimsView = 'CLIENT_ACCT_ACC_TRANSACTIONS_PROGRESS_CLAIMS____VIEW',
  ClientAcctAccTransactionsProgressClaimsCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_PROGRESS_CLAIMS____CREATE',
  ClientAcctAccTransactionsProgressClaimsUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_PROGRESS_CLAIMS____UPDATE',
  ClientAcctAccTransactionsProgressClaimsApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_PROGRESS_CLAIMS____APPROVE_REJECT',
  ClientAcctAccTransactionsProgressClaimsCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_PROGRESS_CLAIMS____CANCEL',
  ClientAcctAccTransactionsProgressClaimsDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_PROGRESS_CLAIMS____DELETE',
  ClientAcctAccTransactionsRetentionClaimsView = 'CLIENT_ACCT_ACC_TRANSACTIONS_RETENTION_CLAIMS____VIEW',
  ClientAcctAccTransactionsRetentionClaimsCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_RETENTION_CLAIMS____CANCEL',
  ClientAcctAccTransactionsRetentionClaimsUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_RETENTION_CLAIMS____UPDATE',
  ClientAcctAccTransactionsRetentionClaimsDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_RETENTION_CLAIMS____DELETE',
  ClientAcctAccTransactionsRetentionClaimsApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_RETENTION_CLAIMS____APPROVE_REJECT',
  ClientAcctAccTransactionsMiscellaneousInvoicesView = 'CLIENT_ACCT_ACC_TRANSACTIONS_MISCELLANEOUS_INVOICES____VIEW',
  ClientAcctAccTransactionsMiscellaneousInvoicesCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_MISCELLANEOUS_INVOICES____CREATE',
  ClientAcctAccTransactionsMiscellaneousInvoicesCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_MISCELLANEOUS_INVOICES____CANCEL',
  ClientAcctAccTransactionsMiscellaneousInvoicesApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_MISCELLANEOUS_INVOICES____APPROVE_REJECT',
  ClientAcctAccTransactionsMiscellaneousInvoicesUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_MISCELLANEOUS_INVOICES____UPDATE',
  ClientAcctAccTransactionsMiscellaneousInvoicesDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_MISCELLANEOUS_INVOICES____DELETE',
  ClientAcctAccTransactionsDebitNotesView = 'CLIENT_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____VIEW',
  ClientAcctAccTransactionsDebitNotesCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____CREATE',
  ClientAcctAccTransactionsDebitNotesApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____APPROVE_REJECT',
  ClientAcctAccTransactionsDebitNotesUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____UPDATE',
  ClientAcctAccTransactionsDebitNotesCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____CANCEL',
  ClientAcctAccTransactionsDebitNotesDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____DELETE',
  ClientAcctAccTransactionsCreditNotesView = 'CLIENT_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____VIEW',
  ClientAcctAccTransactionsCreditNotesCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____CREATE',
  ClientAcctAccTransactionsCreditNotesApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____APPROVE_REJECT',
  ClientAcctAccTransactionsCreditNotesAllocation = 'CLIENT_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____ALLOCATION',
  ClientAcctAccTransactionsCreditNotesCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____CANCEL',
  ClientAcctAccTransactionsCreditNotesDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____DELETE',
  ClientAcctAccTransactionsReceiptsView = 'CLIENT_ACCT_ACC_TRANSACTIONS_RECEIPTS____VIEW',
  ClientAcctAccTransactionsReceiptsCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_RECEIPTS____CREATE',
  ClientAcctAccTransactionsReceiptsApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_RECEIPTS____APPROVE_REJECT',
  ClientAcctAccTransactionsReceiptsAllocation = 'CLIENT_ACCT_ACC_TRANSACTIONS_RECEIPTS____ALLOCATION',
  ClientAcctAccTransactionsReceiptsUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_RECEIPTS____UPDATE',
  ClientAcctAccTransactionsReceiptsCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_RECEIPTS____CANCEL',
  ClientAcctAccTransactionsReceiptsDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_RECEIPTS____DELETE',
  ClientAcctAccTransactionsRefundsView = 'CLIENT_ACCT_ACC_TRANSACTIONS_REFUNDS____VIEW',
  ClientAcctAccTransactionsRefundsCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_REFUNDS____CREATE',
  ClientAcctAccTransactionsRefundsApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_REFUNDS____APPROVE_REJECT',
  ClientAcctAccTransactionsRefundsCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_REFUNDS____CANCEL',
  ClientAcctAccTransactionsRefundsUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_REFUNDS____UPDATE',
  ClientAcctAccTransactionsRefundsDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_REFUNDS____DELETE',
  ClientAcctAccTransactionsClientDebitNotesView = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_DEBIT_NOTES____VIEW',
  ClientAcctAccTransactionsClientDebitNotesCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_DEBIT_NOTES____CREATE',
  ClientAcctAccTransactionsClientDebitNotesApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_DEBIT_NOTES____APPROVE_REJECT',
  ClientAcctAccTransactionsClientDebitNotesUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_DEBIT_NOTES____UPDATE',
  ClientAcctAccTransactionsClientDebitNotesDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_DEBIT_NOTES____DELETE',
  ClientAcctAccTransactionsClientDebitNotesCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_DEBIT_NOTES____CANCEL',
  ClientAcctAccTransactionsClientDebitNotesAllocation = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_DEBIT_NOTES____ALLOCATION',
  ClientAcctAccTransactionsClientCreditNotesView = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_CREDIT_NOTES____VIEW',
  ClientAcctAccTransactionsClientCreditNotesCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_CREDIT_NOTES____CREATE',
  ClientAcctAccTransactionsClientCreditNotesApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_CREDIT_NOTES____APPROVE_REJECT',
  ClientAcctAccTransactionsClientCreditNotesCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_CREDIT_NOTES____CANCEL',
  ClientAcctAccTransactionsClientCreditNotesUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_CREDIT_NOTES____UPDATE',
  ClientAcctAccTransactionsClientCreditNotesDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_CREDIT_NOTES____DELETE',
  ClientAcctAccTransactionsOsReceivableView = 'CLIENT_ACCT_ACC_TRANSACTIONS_OS_RECEIVABLE____VIEW',
  ClientAcctCreditControlView = 'CLIENT_ACCT_CREDIT_CONTROL_______VIEW',
  ClientAcctStatementAccountsView = 'CLIENT_ACCT_STATEMENT_ACCOUNTS_______VIEW',
  ClientAcctPaymentReminderView = 'CLIENT_ACCT_PAYMENT_REMINDER_______VIEW',
  ClientAcctPaymentReminderPaymentReminderSettingView = 'CLIENT_ACCT_PAYMENT_REMINDER_PAYMENT_REMINDER_SETTING_______VIEW',
  ClientAcctPaymentReminderPaymentReminderSettingCreate = 'CLIENT_ACCT_PAYMENT_REMINDER_PAYMENT_REMINDER_SETTING_______CREATE',
  ClientAcctDigitalReportingView = 'CLIENT_ACCT_DIGITAL_REPORTING_______VIEW',
  ClientAcctDigitalReportingClientLedgerReportView = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_LEDGER_REPORT____VIEW',
  ClientAcctDigitalReportingClientAgingDetailByContractView = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_AGING_DETAIL_BY_CONTRACT____VIEW',
  ClientAcctDigitalReportingClientAgingSummaryView = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_AGING_SUMMARY____VIEW',
  ClientAcctDigitalReportingClientAgingDetailView = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_AGING_DETAIL____VIEW',
  ClientAcctDigitalReportingStatementOfAccountView = 'CLIENT_ACCT_DIGITAL_REPORTING_STATEMENT_OF_ACCOUNT____VIEW',
  ClientAcctGeneralSettingsIcon = 'CLIENT_ACCT_GENERAL_SETTINGS_____ICON',
  SubconTenderIcon = 'SUBCON_TENDER__________ICON',
  SubconTenderView = 'SUBCON_TENDER__________VIEW',
  SubconTenderCreate = 'SUBCON_TENDER__________CREATE',
  SubconTenderUpdate = 'SUBCON_TENDER__________UPDATE',
  SubconTenderDelete = 'SUBCON_TENDER__________DELETE',
  SubconTenderTenderInviteesView = 'SUBCON_TENDER_TENDER_INVITEES_______VIEW',
  SubconTenderTenderInviteesCreate = 'SUBCON_TENDER_TENDER_INVITEES_______CREATE',
  SubconTenderTenderInviteesUninvite = 'SUBCON_TENDER_TENDER_INVITEES_______UNINVITE',
  SubconTenderTenderInviteesTenderSubmissionView = 'SUBCON_TENDER_TENDER_INVITEES_TENDER_SUBMISSION____VIEW',
  SubconTenderTenderInviteesTenderSubmissionCreate = 'SUBCON_TENDER_TENDER_INVITEES_TENDER_SUBMISSION____CREATE',
  SubconTenderEvaluationSelectionCreate = 'SUBCON_TENDER_EVALUATION_SELECTION_______CREATE',
  SubconTenderGeneralSettingsIcon = 'SUBCON_TENDER_GENERAL_SETTINGS_____ICON',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeView = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_VIEW',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeCreate = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_CREATE',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeUpdate = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_UPDATE',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeDownload = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_DOWNLOAD',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeUpload = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_UPLOAD',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeDelete = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_DELETE',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeActive = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_ACTIVE',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeInactive = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_INACTIVE',
  SubcontractsIcon = 'SUBCONTRACTS__________ICON',
  SubcontractsView = 'SUBCONTRACTS__________VIEW',
  SubcontractsCreate = 'SUBCONTRACTS__________CREATE',
  SubcontractsSubcontractInfoView = 'SUBCONTRACTS_SUBCONTRACT_INFO_______VIEW',
  SubcontractsSubcontractInfoUpdate = 'SUBCONTRACTS_SUBCONTRACT_INFO_______UPDATE',
  SubcontractsSubcontractGuaranteeView = 'SUBCONTRACTS_SUBCONTRACT_GUARANTEE_______VIEW',
  SubcontractsSubcontractGuaranteeCreate = 'SUBCONTRACTS_SUBCONTRACT_GUARANTEE_______CREATE',
  SubcontractsSubcontractGuaranteeApproveReject = 'SUBCONTRACTS_SUBCONTRACT_GUARANTEE_______APPROVE_REJECT',
  SubcontractsSubcontractGuaranteeUpdate = 'SUBCONTRACTS_SUBCONTRACT_GUARANTEE_______UPDATE',
  SubcontractsSubcontractGuaranteeCancel = 'SUBCONTRACTS_SUBCONTRACT_GUARANTEE_______CANCEL',
  SubcontractsSubcontractInsuranceView = 'SUBCONTRACTS_SUBCONTRACT_INSURANCE_______VIEW',
  SubcontractsSubcontractInsuranceCreate = 'SUBCONTRACTS_SUBCONTRACT_INSURANCE_______CREATE',
  SubcontractsSubcontractInsuranceUpdate = 'SUBCONTRACTS_SUBCONTRACT_INSURANCE_______UPDATE',
  SubcontractsSubcontractInsuranceCancel = 'SUBCONTRACTS_SUBCONTRACT_INSURANCE_______CANCEL',
  SubcontractsSubcontractInsuranceApproveReject = 'SUBCONTRACTS_SUBCONTRACT_INSURANCE_______APPROVE_REJECT',
  SubcontractsSubcontractVoView = 'SUBCONTRACTS_SUBCONTRACT_VO_______VIEW',
  SubcontractsSubcontractVoCreate = 'SUBCONTRACTS_SUBCONTRACT_VO_______CREATE',
  SubcontractsSubcontractVoApproveReject = 'SUBCONTRACTS_SUBCONTRACT_VO_______APPROVE_REJECT',
  SubcontractsSubcontractVoUpdate = 'SUBCONTRACTS_SUBCONTRACT_VO_______UPDATE',
  SubcontractsSubcontractVoDelete = 'SUBCONTRACTS_SUBCONTRACT_VO_______DELETE',
  SubcontractsSubcontractAccStatusView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_______VIEW',
  SubcontractsSubcontractAccStatusSubcontractorProfileView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_SUBCONTRACTOR_PROFILE___VIEW',
  SubcontractsSubcontractAccStatusAdvancesView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_ADVANCES____VIEW',
  SubcontractsSubcontractAccStatusProgressClaimsView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_PROGRESS_CLAIMS____VIEW',
  SubcontractsSubcontractAccStatusRetentionClaimsView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_RETENTION_CLAIMS____VIEW',
  SubcontractsSubcontractAccStatusMiscellaneousInvoiceView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_MISCELLANEOUS_INVOICE____VIEW',
  SubcontractsSubcontractAccStatusDebitNotesView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_DEBIT_NOTES____VIEW',
  SubcontractsSubcontractAccStatusCreditNotesView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_CREDIT_NOTES____VIEW',
  SubcontractsSubcontractAccStatusPaymentsView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_PAYMENTS____VIEW',
  SubcontractsSubcontractAccStatusRefundsView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_REFUNDS____VIEW',
  SubcontractsSubcontractAccStatusDebitNotesSubconView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_DEBIT_NOTES_SUBCON____VIEW',
  SubcontractsSubcontractAccStatusCreditNotesSubconView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_CREDIT_NOTES_SUBCON____VIEW',
  SubcontractsSubcontractAccStatusOsReceivableView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_OS_RECEIVABLE____VIEW',
  SubcontractsRetentionView = 'SUBCONTRACTS_RETENTION_______VIEW',
  SubcontractsRetentionCreate = 'SUBCONTRACTS_RETENTION_______CREATE',
  SubcontractsRetentionUpdate = 'SUBCONTRACTS_RETENTION_______UPDATE',
  SubcontractsRetentionApproveReject = 'SUBCONTRACTS_RETENTION_______APPROVE_REJECT',
  SubcontractsRetentionDelete = 'SUBCONTRACTS_RETENTION_______DELETE',
  SubcontractsExtensionOfTimeView = 'SUBCONTRACTS_EXTENSION_OF_TIME_______VIEW',
  SubcontractsExtensionOfTimeCreate = 'SUBCONTRACTS_EXTENSION_OF_TIME_______CREATE',
  SubcontractsSubcontractClosureCreate = 'SUBCONTRACTS_SUBCONTRACT_CLOSURE_______CREATE',
  SubcontractsSubcontractTerminationCreate = 'SUBCONTRACTS_SUBCONTRACT_TERMINATION_______CREATE',
  SubcontractsDigitalDocumentView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_______VIEW',
  SubcontractsDigitalDocumentSubcontractInfoView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INFO_______VIEW',
  SubcontractsDigitalDocumentSubcontractInfoUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INFO_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractInfoDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INFO_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractInfoDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INFO_______DELETE',
  SubcontractsDigitalDocumentSubcontractGuaranteeView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_GUARANTEE_______VIEW',
  SubcontractsDigitalDocumentSubcontractGuaranteeUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_GUARANTEE_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractGuaranteeDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_GUARANTEE_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractGuaranteeDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_GUARANTEE_______DELETE',
  SubcontractsDigitalDocumentSubcontractInsuranceView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INSURANCE_______VIEW',
  SubcontractsDigitalDocumentSubcontractInsuranceUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INSURANCE_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractInsuranceDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INSURANCE_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractInsuranceDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INSURANCE_______DELETE',
  SubcontractsDigitalDocumentSubcontractVoView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_VO_______VIEW',
  SubcontractsDigitalDocumentSubcontractVoUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_VO_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractVoDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_VO_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractVoDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_VO_______DELETE',
  SubcontractsDigitalDocumentSubcontractAdvanceView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_ADVANCE_______VIEW',
  SubcontractsDigitalDocumentSubcontractAdvanceUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_ADVANCE_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractAdvanceDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_ADVANCE_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractAdvanceDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_ADVANCE_______DELETE',
  SubcontractsDigitalDocumentSubcontractClaimView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLAIM_______VIEW',
  SubcontractsDigitalDocumentSubcontractClaimUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLAIM_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractClaimDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLAIM_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractClaimDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLAIM_______DELETE',
  SubcontractsDigitalDocumentSubcontractMiscellaneousInvoicesView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_MISCELLANEOUS_INVOICES_______VIEW',
  SubcontractsDigitalDocumentSubcontractMiscellaneousInvoicesUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_MISCELLANEOUS_INVOICES_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractMiscellaneousInvoicesDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_MISCELLANEOUS_INVOICES_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractMiscellaneousInvoicesDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_MISCELLANEOUS_INVOICES_______DELETE',
  SubcontractsDigitalDocumentSubcontractDnView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_DN_______VIEW',
  SubcontractsDigitalDocumentSubcontractDnUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_DN_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractDnDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_DN_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractDnDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_DN_______DELETE',
  SubcontractsDigitalDocumentSubcontractCnView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CN_______VIEW',
  SubcontractsDigitalDocumentSubcontractCnUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CN_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractCnDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CN_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractCnDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CN_______DELETE',
  SubcontractsDigitalDocumentSubcontractPaymentView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_PAYMENT_______VIEW',
  SubcontractsDigitalDocumentSubcontractPaymentUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_PAYMENT_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractPaymentDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_PAYMENT_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractPaymentDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_PAYMENT_______DELETE',
  SubcontractsDigitalDocumentSubcontractRefundView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_REFUND_______VIEW',
  SubcontractsDigitalDocumentSubcontractRefundUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_REFUND_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractRefundDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_REFUND_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractRefundDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_REFUND_______DELETE',
  SubcontractsDigitalDocumentDnToSubconView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_DN_TO_SUBCON_______VIEW',
  SubcontractsDigitalDocumentDnToSubconUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_DN_TO_SUBCON_______UPLOAD',
  SubcontractsDigitalDocumentDnToSubconDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_DN_TO_SUBCON_______DOWNLOAD',
  SubcontractsDigitalDocumentDnToSubconDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_DN_TO_SUBCON_______DELETE',
  SubcontractsDigitalDocumentCnToSubconView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_CN_TO_SUBCON_______VIEW',
  SubcontractsDigitalDocumentCnToSubconUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_CN_TO_SUBCON_______UPLOAD',
  SubcontractsDigitalDocumentCnToSubconDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_CN_TO_SUBCON_______DOWNLOAD',
  SubcontractsDigitalDocumentCnToSubconDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_CN_TO_SUBCON_______DELETE',
  SubcontractsDigitalDocumentSubcontractRetentionCpcView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CPC_______VIEW',
  SubcontractsDigitalDocumentSubcontractRetentionCpcUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CPC_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractRetentionCpcDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CPC_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractRetentionCpcDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CPC_______DELETE',
  SubcontractsDigitalDocumentSubcontractRetentionDlpView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_DLP_______VIEW',
  SubcontractsDigitalDocumentSubcontractRetentionDlpUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_DLP_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractRetentionDlpDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_DLP_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractRetentionDlpDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_DLP_______DELETE',
  SubcontractsDigitalDocumentSubcontractRetentionCmgView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CMG_______VIEW',
  SubcontractsDigitalDocumentSubcontractRetentionCmgUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CMG_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractRetentionCmgDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CMG_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractRetentionCmgDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CMG_______DELETE',
  SubcontractsDigitalDocumentSubcontractExtensionView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_EXTENSION_______VIEW',
  SubcontractsDigitalDocumentSubcontractExtensionUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_EXTENSION_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractExtensionDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_EXTENSION_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractExtensionDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_EXTENSION_______DELETE',
  SubcontractsDigitalDocumentSubcontractClosureView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLOSURE_______VIEW',
  SubcontractsDigitalDocumentSubcontractClosureUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLOSURE_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractClosureDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLOSURE_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractClosureDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLOSURE_______DELETE',
  SubcontractsDigitalDocumentSubcontractTerminationView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_TERMINATION_______VIEW',
  SubcontractsDigitalDocumentSubcontractTerminationUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_TERMINATION_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractTerminationDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_TERMINATION_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractTerminationDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_TERMINATION_______DELETE',
  SubcontractsGeneralSettingsIcon = 'SUBCONTRACTS_GENERAL_SETTINGS_______ICON',
  SubcontractsGeneralSettingsSubcontractTradeView = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____VIEW',
  SubcontractsGeneralSettingsSubcontractTradeCreate = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____CREATE',
  SubcontractsGeneralSettingsSubcontractTradeUpdate = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPDATE',
  SubcontractsGeneralSettingsSubcontractTradeDelete = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DELETE',
  SubcontractsGeneralSettingsSubcontractTradeInactive = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____INACTIVE',
  SubcontractsGeneralSettingsSubcontractTradeActive = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____ACTIVE',
  SubcontractsGeneralSettingsSubcontractTradeDownload = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DOWNLOAD',
  SubcontractsGeneralSettingsSubcontractTradeUpload = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPLOAD',
  SubcontractsGeneralSettingsGuaranteeTypeView = 'SUBCONTRACTS_GENERAL_SETTINGS_GUARANTEE_TYPE____VIEW',
  SubcontractsGeneralSettingsGuaranteeTypeCreate = 'SUBCONTRACTS_GENERAL_SETTINGS_GUARANTEE_TYPE____CREATE',
  SubcontractsGeneralSettingsGuaranteeTypeUpdate = 'SUBCONTRACTS_GENERAL_SETTINGS_GUARANTEE_TYPE____UPDATE',
  SubcontractsGeneralSettingsGuaranteeTypeActive = 'SUBCONTRACTS_GENERAL_SETTINGS_GUARANTEE_TYPE____ACTIVE',
  SubcontractsGeneralSettingsGuaranteeTypeInactive = 'SUBCONTRACTS_GENERAL_SETTINGS_GUARANTEE_TYPE____INACTIVE',
  SubcontractsGeneralSettingsGuaranteeTypeDelete = 'SUBCONTRACTS_GENERAL_SETTINGS_GUARANTEE_TYPE____DELETE',
  SubcontractsGeneralSettingsInsuranceTypeView = 'SUBCONTRACTS_GENERAL_SETTINGS_INSURANCE_TYPE____VIEW',
  SubcontractsGeneralSettingsInsuranceTypeCreate = 'SUBCONTRACTS_GENERAL_SETTINGS_INSURANCE_TYPE____CREATE',
  SubcontractsGeneralSettingsInsuranceTypeUpdate = 'SUBCONTRACTS_GENERAL_SETTINGS_INSURANCE_TYPE____UPDATE',
  SubcontractsGeneralSettingsInsuranceTypeActive = 'SUBCONTRACTS_GENERAL_SETTINGS_INSURANCE_TYPE____ACTIVE',
  SubcontractsGeneralSettingsInsuranceTypeInactive = 'SUBCONTRACTS_GENERAL_SETTINGS_INSURANCE_TYPE____INACTIVE',
  SubcontractsGeneralSettingsInsuranceTypeDelete = 'SUBCONTRACTS_GENERAL_SETTINGS_INSURANCE_TYPE____DELETE',
  SubconAccountIcon = 'SUBCON_ACCOUNT__________ICON',
  SubconAccountView = 'SUBCON_ACCOUNT__________VIEW',
  SubconAccountCreate = 'SUBCON_ACCOUNT__________CREATE',
  SubconAccountSubcontractorProfileView = 'SUBCON_ACCOUNT_SUBCONTRACTOR_PROFILE_______VIEW',
  SubconAccountSubcontractorProfileCreate = 'SUBCON_ACCOUNT_SUBCONTRACTOR_PROFILE_______CREATE',
  SubconAccountSubcontractorProfileUpdate = 'SUBCON_ACCOUNT_SUBCONTRACTOR_PROFILE_______UPDATE',
  SubconAccountAccountTransactionView = 'SUBCON_ACCOUNT_ACCOUNT_TRANSACTION_______VIEW',
  SubconAccountAdvancesView = 'SUBCON_ACCOUNT_ADVANCES_______VIEW',
  SubconAccountAdvancesCreate = 'SUBCON_ACCOUNT_ADVANCES_______CREATE',
  SubconAccountAdvancesCancel = 'SUBCON_ACCOUNT_ADVANCES_______CANCEL',
  SubconAccountAdvancesApproveReject = 'SUBCON_ACCOUNT_ADVANCES_______APPROVE_REJECT',
  SubconAccountAdvancesUpdate = 'SUBCON_ACCOUNT_ADVANCES_______UPDATE',
  SubconAccountAdvancesDelete = 'SUBCON_ACCOUNT_ADVANCES_______DELETE',
  SubconAccountProgressClaimsView = 'SUBCON_ACCOUNT_PROGRESS_CLAIMS_______VIEW',
  SubconAccountProgressClaimsCreate = 'SUBCON_ACCOUNT_PROGRESS_CLAIMS_______CREATE',
  SubconAccountProgressClaimsUpdate = 'SUBCON_ACCOUNT_PROGRESS_CLAIMS_______UPDATE',
  SubconAccountProgressClaimsApproveReject = 'SUBCON_ACCOUNT_PROGRESS_CLAIMS_______APPROVE_REJECT',
  SubconAccountProgressClaimsCancel = 'SUBCON_ACCOUNT_PROGRESS_CLAIMS_______CANCEL',
  SubconAccountProgressClaimsDelete = 'SUBCON_ACCOUNT_PROGRESS_CLAIMS_______DELETE',
  SubconAccountRetentionClaimsView = 'SUBCON_ACCOUNT_RETENTION_CLAIMS_______VIEW',
  SubconAccountRetentionClaimsCreate = 'SUBCON_ACCOUNT_RETENTION_CLAIMS_______CREATE',
  SubconAccountRetentionClaimsUpdate = 'SUBCON_ACCOUNT_RETENTION_CLAIMS_______UPDATE',
  SubconAccountRetentionClaimsCancel = 'SUBCON_ACCOUNT_RETENTION_CLAIMS_______CANCEL',
  SubconAccountRetentionClaimsApproveReject = 'SUBCON_ACCOUNT_RETENTION_CLAIMS_______APPROVE_REJECT',
  SubconAccountRetentionClaimsDelete = 'SUBCON_ACCOUNT_RETENTION_CLAIMS_______DELETE',
  SubconAccountMiscellaneousInvoiceView = 'SUBCON_ACCOUNT_MISCELLANEOUS_INVOICE_______VIEW',
  SubconAccountMiscellaneousInvoiceCreate = 'SUBCON_ACCOUNT_MISCELLANEOUS_INVOICE_______CREATE',
  SubconAccountMiscellaneousInvoiceUpdate = 'SUBCON_ACCOUNT_MISCELLANEOUS_INVOICE_______UPDATE',
  SubconAccountMiscellaneousInvoiceCancel = 'SUBCON_ACCOUNT_MISCELLANEOUS_INVOICE_______CANCEL',
  SubconAccountMiscellaneousInvoiceApproveReject = 'SUBCON_ACCOUNT_MISCELLANEOUS_INVOICE_______APPROVE_REJECT',
  SubconAccountMiscellaneousInvoiceDelete = 'SUBCON_ACCOUNT_MISCELLANEOUS_INVOICE_______DELETE',
  SubconAccountDebitNotesView = 'SUBCON_ACCOUNT_DEBIT_NOTES_______VIEW',
  SubconAccountDebitNotesCreate = 'SUBCON_ACCOUNT_DEBIT_NOTES_______CREATE',
  SubconAccountDebitNotesUpdate = 'SUBCON_ACCOUNT_DEBIT_NOTES_______UPDATE',
  SubconAccountDebitNotesDelete = 'SUBCON_ACCOUNT_DEBIT_NOTES_______DELETE',
  SubconAccountDebitNotesCancel = 'SUBCON_ACCOUNT_DEBIT_NOTES_______CANCEL',
  SubconAccountDebitNotesApproveReject = 'SUBCON_ACCOUNT_DEBIT_NOTES_______APPROVE_REJECT',
  SubconAccountCreditNotesView = 'SUBCON_ACCOUNT_CREDIT_NOTES_______VIEW',
  SubconAccountCreditNotesCreate = 'SUBCON_ACCOUNT_CREDIT_NOTES_______CREATE',
  SubconAccountCreditNotesUpdate = 'SUBCON_ACCOUNT_CREDIT_NOTES_______UPDATE',
  SubconAccountCreditNotesApproveReject = 'SUBCON_ACCOUNT_CREDIT_NOTES_______APPROVE_REJECT',
  SubconAccountCreditNotesCancel = 'SUBCON_ACCOUNT_CREDIT_NOTES_______CANCEL',
  SubconAccountCreditNotesDelete = 'SUBCON_ACCOUNT_CREDIT_NOTES_______DELETE',
  SubconAccountPaymentView = 'SUBCON_ACCOUNT_PAYMENT_______VIEW',
  SubconAccountPaymentCreate = 'SUBCON_ACCOUNT_PAYMENT_______CREATE',
  SubconAccountPaymentCancel = 'SUBCON_ACCOUNT_PAYMENT_______CANCEL',
  SubconAccountPaymentUpdate = 'SUBCON_ACCOUNT_PAYMENT_______UPDATE',
  SubconAccountPaymentApproveReject = 'SUBCON_ACCOUNT_PAYMENT_______APPROVE_REJECT',
  SubconAccountPaymentDelete = 'SUBCON_ACCOUNT_PAYMENT_______DELETE',
  SubconAccountRefundsView = 'SUBCON_ACCOUNT_REFUNDS_______VIEW',
  SubconAccountRefundsCreate = 'SUBCON_ACCOUNT_REFUNDS_______CREATE',
  SubconAccountRefundsApproveReject = 'SUBCON_ACCOUNT_REFUNDS_______APPROVE_REJECT',
  SubconAccountRefundsCancel = 'SUBCON_ACCOUNT_REFUNDS_______CANCEL',
  SubconAccountRefundsUpdate = 'SUBCON_ACCOUNT_REFUNDS_______UPDATE',
  SubconAccountRefundsDelete = 'SUBCON_ACCOUNT_REFUNDS_______DELETE',
  SubconAccountRefundsAllocation = 'SUBCON_ACCOUNT_REFUNDS_______ALLOCATION',
  SubconAccountCnSubconView = 'SUBCON_ACCOUNT_CN_SUBCON_______VIEW',
  SubconAccountCnSubconCreate = 'SUBCON_ACCOUNT_CN_SUBCON_______CREATE',
  SubconAccountCnSubconCancel = 'SUBCON_ACCOUNT_CN_SUBCON_______CANCEL',
  SubconAccountCnSubconUpdate = 'SUBCON_ACCOUNT_CN_SUBCON______UPDATE',
  SubconAccountCnSubconApproveReject = 'SUBCON_ACCOUNT_CN_SUBCON______APPROVE_REJECT',
  SubconAccountCnSubconDelete = 'SUBCON_ACCOUNT_CN_SUBCON______DELETE',
  SubconAccountDnSubconView = 'SUBCON_ACCOUNT_DN_SUBCON_______VIEW',
  SubconAccountDnSubconCreate = 'SUBCON_ACCOUNT_DN_SUBCON_______CREATE',
  SubconAccountDnSubconCancel = 'SUBCON_ACCOUNT_DN_SUBCON_______CANCEL',
  SubconAccountDnSubconUpdate = 'SUBCON_ACCOUNT_DN_SUBCON______UPDATE',
  SubconAccountDnSubconApproveReject = 'SUBCON_ACCOUNT_DN_SUBCON______APPROVE_REJECT',
  SubconAccountDnSubconDelete = 'SUBCON_ACCOUNT_DN_SUBCON______DELETE',
  SubconAccountDnSubconAllocation = 'SUBCON_ACCOUNT_DN_SUBCON______ALLOCATION',
  SubconAccountDigitalReportingView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING______VIEW',
  SubconAccountDigitalReportingSubcontractorLedgerReportView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_LEDGER_REPORT______VIEW',
  SubconAccountDigitalReportingSubcontractorAgingDetailByProjectView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_AGING_DETAIL_BY_PROJECT______VIEW',
  SubconAccountDigitalReportingSubcontractorAgingDetailReportByTrxDateView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_AGING_DETAIL_REPORT_BY_TRX_DATE______VIEW',
  SubconAccountDigitalReportingSubcontractorAgingSummaryReportByTrxDateView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_AGING_SUMMARY_REPORT_BY_TRX_DATE______VIEW',
  SubconAccountDigitalReportingSubcontractorDetailBreakdownReportView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_DETAIL_BREAKDOWN_REPORT______VIEW',
  SubconAccountDigitalReportingSubcontractorLedgerReportByProjectView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_LEDGER_REPORT_BY_PROJECT______VIEW',
  SubconAccountDigitalReportingSubcontractorControlSummaryReportView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_CONTROL_SUMMARY_REPORT______VIEW',
  SubconAccountOsReceivableView = 'SUBCON_ACCOUNT_OS_RECEIVABLE_______VIEW',
  SubconAccountGeneralSettingsIcon = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_______ICON',
  SubconAccountGeneralSettingsSubcontractTradeView = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____VIEW',
  SubconAccountGeneralSettingsSubcontractTradeUpdate = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPDATE',
  SubconAccountGeneralSettingsSubcontractTradeCreate = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____CREATE',
  SubconAccountGeneralSettingsSubcontractTradeDownload = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DOWNLOAD',
  SubconAccountGeneralSettingsSubcontractTradeUpload = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPLOAD',
  SubconAccountGeneralSettingsSubcontractTradeDelete = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DELETE',
  SubconAccountGeneralSettingsSubcontractTradeInactive = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____INACTIVE',
  SubconAccountGeneralSettingsSubcontractTradeActive = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____ACTIVE',
  ProjectPurchaseIcon = 'PROJECT_PURCHASE__________ICON',
  ProjectPurchaseContractView = 'PROJECT_PURCHASE_CONTRACT_______VIEW',
  ProjectPurchaseNegotiatedSupplyView = 'PROJECT_PURCHASE_NEGOTIATED_SUPPLY_______VIEW',
  ProjectPurchaseNegotiatedSupplyCreate = 'PROJECT_PURCHASE_NEGOTIATED_SUPPLY_______CREATE',
  ProjectPurchaseNegotiatedSupplyUpdate = 'PROJECT_PURCHASE_NEGOTIATED_SUPPLY_______UPDATE',
  ProjectPurchaseNegotiatedSupplyCancel = 'PROJECT_PURCHASE_NEGOTIATED_SUPPLY_______CANCEL',
  ProjectPurchasePurchaseRequisitionView = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_______VIEW',
  ProjectPurchasePurchaseRequisitionCreate = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_______CREATE',
  ProjectPurchasePurchaseRequisitionUpdate = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_______UPDATE',
  ProjectPurchasePurchaseRequisitionCancel = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_______CANCEL',
  ProjectPurchasePurchaseRequisitionApproveReject = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_______APPROVE_REJECT',
  ProjectPurchasePurchaseRequisitionDraft = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_______DRAFT',
  ProjectPurchaseRequestForQuotationView = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_______VIEW',
  ProjectPurchaseRequestForQuotationCreate = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_______CREATE',
  ProjectPurchaseRequestForQuotationUpdate = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_______UPDATE',
  ProjectPurchaseRequestForQuotationApproveReject = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_______APPROVE_REJECT',
  ProjectPurchaseRequestForQuotationDelete = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_______DELETE',
  ProjectPurchaseRequestForQuotationRfqInviteesView = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES____VIEW',
  ProjectPurchaseRequestForQuotationRfqInviteesCreate = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES____CREATE',
  ProjectPurchaseRequestForQuotationRfqInviteesUninvite = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES____UNINVITE',
  ProjectPurchaseRequestForQuotationRfqInviteesQuotedRfqView = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES_QUOTED_RFQ_VIEW',
  ProjectPurchaseRequestForQuotationRfqInviteesQuotedRfqCreate = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES_QUOTED_RFQ_CREATE',
  ProjectPurchaseRequestForQuotationRfqInviteesQuotedRfqUpdate = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES_QUOTED_RFQ_UPDATE',
  ProjectPurchaseRequestForQuotationEvaluationAndSelectionView = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_EVALUATION_AND_SELECTION____VIEW',
  ProjectPurchaseRequestForQuotationEvaluationAndSelectionCreate = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_EVALUATION_AND_SELECTION____CREATE',
  ProjectPurchasePurchaseOrderView = 'PROJECT_PURCHASE_PURCHASE_ORDER_______VIEW',
  ProjectPurchasePurchaseOrderCreate = 'PROJECT_PURCHASE_PURCHASE_ORDER_______CREATE',
  ProjectPurchasePurchaseOrderUpdate = 'PROJECT_PURCHASE_PURCHASE_ORDER_______UPDATE',
  ProjectPurchasePurchaseOrderApproveReject = 'PROJECT_PURCHASE_PURCHASE_ORDER_______APPROVE_REJECT',
  ProjectPurchasePurchaseOrderClose = 'PROJECT_PURCHASE_PURCHASE_ORDER_______CLOSE',
  ProjectPurchasePurchaseRequisitionBehalfView = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_BEHALF_______VIEW',
  ProjectPurchasePurchaseRequisitionBehalfCreate = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_BEHALF_______CREATE',
  ProjectPurchasePurchaseRequisitionBehalfDraft = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_BEHALF_______DRAFT',
  ProjectPurchasePurchaseRequisitionBehalfUpdate = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_BEHALF_______UPDATE',
  ProjectPurchasePurchaseRequisitionBehalfCancel = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_BEHALF_______CANCEL',
  ProjectPurchasePurchaseRequisitionBehalfApproveReject = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_BEHALF_______APPROVE_REJECT',
  ProjectPurchasePurchaseOrderBehalfView = 'PROJECT_PURCHASE_PURCHASE_ORDER_BEHALF_______VIEW',
  ProjectPurchasePurchaseOrderBehalfUpdate = 'PROJECT_PURCHASE_PURCHASE_ORDER_BEHALF_______UPDATE',
  ProjectPurchasePurchaseOrderBehalfCreate = 'PROJECT_PURCHASE_PURCHASE_ORDER_BEHALF_______CREATE',
  ProjectPurchasePurchaseOrderBehalfApproveReject = 'PROJECT_PURCHASE_PURCHASE_ORDER_BEHALF_______APPROVE_REJECT',
  ProjectPurchasePurchaseOrderBehalfClose = 'PROJECT_PURCHASE_PURCHASE_ORDER_BEHALF_______CLOSE',
  ProjectPurchaseDeliveryOrderView = 'PROJECT_PURCHASE_DELIVERY_ORDER_______VIEW',
  ProjectPurchaseDeliveryOrderCreate = 'PROJECT_PURCHASE_DELIVERY_ORDER_______CREATE',
  ProjectPurchaseDeliveryOrderUpdate = 'PROJECT_PURCHASE_DELIVERY_ORDER_______UPDATE',
  ProjectPurchaseDeliveryOrderDelete = 'PROJECT_PURCHASE_DELIVERY_ORDER_______DELETE',
  ProjectPurchaseDeliveryOrderApproveReject = 'PROJECT_PURCHASE_DELIVERY_ORDER_______APPROVE_REJECT',
  ProjectPurchaseGoodReturnNoteView = 'PROJECT_PURCHASE_GOOD_RETURN_NOTE_______VIEW',
  ProjectPurchaseGoodReturnNoteCreate = 'PROJECT_PURCHASE_GOOD_RETURN_NOTE_______CREATE',
  ProjectPurchaseGoodReturnNoteUpdate = 'PROJECT_PURCHASE_GOOD_RETURN_NOTE_______UPDATE',
  ProjectPurchaseGoodReturnNoteDelete = 'PROJECT_PURCHASE_GOOD_RETURN_NOTE_______DELETE',
  ProjectPurchaseGoodReturnNoteApproveReject = 'PROJECT_PURCHASE_GOOD_RETURN_NOTE_______APPROVE_REJECT',
  ProjectPurchaseDigitalReportingView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_______VIEW',
  ProjectPurchaseDigitalReportingFiveWaysMatchReportingView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_FIVE_WAYS_MATCH_REPORTING_______VIEW',
  ProjectPurchaseDigitalReportingLateDeliveryReportView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_LATE_DELIVERY_REPORT_______VIEW',
  ProjectPurchaseDigitalReportingOutstandingDeliveryReportView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_OUTSTANDING_DELIVERY_REPORT_______VIEW',
  ProjectPurchaseDigitalReportingPricingHistoryReportView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PRICING_HISTORY_REPORT_______VIEW',
  ProjectPurchaseDigitalReportingProjectPurchaseAnalysisReportView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PROJECT_PURCHASE_ANALYSIS_REPORT_______VIEW',
  ProjectPurchaseDigitalReportingPurchaseAnalysisByItemView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ANALYSIS_BY_ITEM_______VIEW',
  ProjectPurchaseDigitalReportingPurchaseAnalysisBySupplierView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ANALYSIS_BY_SUPPLIER_______VIEW',
  ProjectPurchaseDigitalReportingPurchaseOrderStatusReportView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ORDER_STATUS_REPORT_______VIEW',
  ProjectPurchaseDigitalReportingPurchaseOrderStatusReportByProjectView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ORDER_STATUS_REPORT_BY_PROJECT_______VIEW',
  ProjectPurchaseGeneralSettingsIcon = 'PROJECT_PURCHASE_GENERAL_SETTINGS_______ICON',
  ProjectPurchaseGeneralSettingsBuildingMaterialView = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____VIEW',
  ProjectPurchaseGeneralSettingsBuildingMaterialCreate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____CREATE',
  ProjectPurchaseGeneralSettingsBuildingMaterialUpdate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____UPDATE',
  ProjectPurchaseGeneralSettingsBuildingMaterialDelete = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____DELETE',
  ProjectPurchaseGeneralSettingsBuildingMaterialDownload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____DOWNLOAD',
  ProjectPurchaseGeneralSettingsBuildingMaterialUpload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____UPLOAD',
  ProjectPurchaseGeneralSettingsBuildingMaterialInactive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____INACTIVE',
  ProjectPurchaseGeneralSettingsBuildingMaterialActive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____ACTIVE',
  ProjectPurchaseGeneralSettingsBuildingMaterialCostItemView = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_VIEW',
  ProjectPurchaseGeneralSettingsBuildingMaterialCostItemCreate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_CREATE',
  ProjectPurchaseGeneralSettingsBuildingMaterialCostItemUpdate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_UPDATE',
  ProjectPurchaseGeneralSettingsBuildingMaterialCostItemInactive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_INACTIVE',
  ProjectPurchaseGeneralSettingsBuildingMaterialCostItemActive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_ACTIVE',
  ProjectPurchaseGeneralSettingsBuildingMaterialCostItemDelete = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_DELETE',
  ProjectPurchaseGeneralSettingsLabourCategoryView = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____VIEW',
  ProjectPurchaseGeneralSettingsLabourCategoryCreate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____CREATE',
  ProjectPurchaseGeneralSettingsLabourCategoryUpdate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____UPDATE',
  ProjectPurchaseGeneralSettingsLabourCategoryDownload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____DOWNLOAD',
  ProjectPurchaseGeneralSettingsLabourCategoryUpload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____UPLOAD',
  ProjectPurchaseGeneralSettingsLabourCategoryDelete = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____DELETE',
  ProjectPurchaseGeneralSettingsLabourCategoryInactive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____INACTIVE',
  ProjectPurchaseGeneralSettingsLabourCategoryActive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____ACTIVE',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseView = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____VIEW',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseCreate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____CREATE',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseUpdate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____UPDATE',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseDelete = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____DELETE',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseDownload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____DOWNLOAD',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseUpload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____UPLOAD',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseInactive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____INACTIVE',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseActive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____ACTIVE',
  ProjectPurchaseGeneralSettingsPMCategoryView = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____VIEW',
  ProjectPurchaseGeneralSettingsPMCategoryCreate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____CREATE',
  ProjectPurchaseGeneralSettingsPMCategoryUpdate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____UPDATE',
  ProjectPurchaseGeneralSettingsPMCategoryDelete = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____DELETE',
  ProjectPurchaseGeneralSettingsPMCategoryDownload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____DOWNLOAD',
  ProjectPurchaseGeneralSettingsPMCategoryUpload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____UPLOAD',
  ProjectPurchaseGeneralSettingsPMCategoryActive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____ACTIVE',
  ProjectPurchaseGeneralSettingsPMCategoryInactive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____INACTIVE',
  SupplierAcctIcon = 'SUPPLIER_ACCT__________ICON',
  SupplierAcctView = 'SUPPLIER_ACCT__________VIEW',
  SupplierAcctCreate = 'SUPPLIER_ACCT__________CREATE',
  SupplierAcctSupplierProfileView = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_______VIEW',
  SupplierAcctSupplierProfileCreate = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_______CREATE',
  SupplierAcctSupplierProfileUpdate = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_______UPDATE',
  SupplierAcctSupplierProfileDelete = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_______DELETE',
  SupplierAcctSupplierProfilePreferredSupplierProductsCreate = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_PREFERRED_SUPPLIER_PRODUCTS_______CREATE',
  SupplierAcctSupplierProfilePreferredSupplierProductsView = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_PREFERRED_SUPPLIER_PRODUCTS_______VIEW',
  SupplierAcctSupplierProfilePreferredSupplierProductsUpdate = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_PREFERRED_SUPPLIER_PRODUCTS_______UPDATE',
  SupplierAcctSupplierProfileOsPayableView = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_OS_PAYABLE_______VIEW',
  SupplierAcctPreferredSupplierProductsCreate = 'SUPPLIER_ACCT_PREFERRED_SUPPLIER_PRODUCTS_______CREATE',
  SupplierAcctPreferredSupplierProductsUpdate = 'SUPPLIER_ACCT_PREFERRED_SUPPLIER_PRODUCTS_______UPDATE',
  SupplierAcctAccTransactionsView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_______VIEW',
  SupplierAcctAdvanceDepositsView = 'SUPPLIER_ACCT_ADVANCE_DEPOSITS____VIEW',
  SupplierAcctAdvanceDepositsCreate = 'SUPPLIER_ACCT_ADVANCE_DEPOSITS____CREATE',
  SupplierAcctAdvanceDepositsUpdate = 'SUPPLIER_ACCT_ADVANCE_DEPOSITS____UPDATE',
  SupplierAcctAdvanceDepositsApproveReject = 'SUPPLIER_ACCT_ADVANCE_DEPOSITS____APPROVE_REJECT',
  SupplierAcctAdvanceDepositsDelete = 'SUPPLIER_ACCT_ADVANCE_DEPOSITS____DELETE',
  SupplierAcctAccTransactionsDepositsView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEPOSITS____VIEW',
  SupplierAcctAccTransactionsDepositsCreate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEPOSITS____CREATE',
  SupplierAcctAccTransactionsDepositsUpdate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEPOSITS____UPDATE',
  SupplierAcctAccTransactionsDepositsApproveReject = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEPOSITS____APPROVE_REJECT',
  SupplierAcctAccTransactionsDepositsCancel = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEPOSITS____CANCEL',
  SupplierAcctAccTransactionsDepositsAllocation = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEPOSITS____ALLOCATION',
  SupplierAcctAccTransactionsDepositsDelete = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEPOSITS____DELETE',
  SupplierAcctInvoicesView = 'SUPPLIER_ACCT_INVOICES____VIEW',
  SupplierAcctInvoicesCreate = 'SUPPLIER_ACCT_INVOICES____CREATE',
  SupplierAcctInvoicesUpdate = 'SUPPLIER_ACCT_INVOICES____UPDATE',
  SupplierAcctInvoicesCancel = 'SUPPLIER_ACCT_INVOICES____CANCEL',
  SupplierAcctInvoicesDelete = 'SUPPLIER_ACCT_INVOICES____DELETE',
  SupplierAcctAccTransactionsInvoicesView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_INVOICES____VIEW',
  SupplierAcctAccTransactionsInvoicesCreate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_INVOICES____CREATE',
  SupplierAcctAccTransactionsInvoicesUpdate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_INVOICES____UPDATE',
  SupplierAcctAccTransactionsInvoicesApproveReject = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_INVOICES____APPROVE_REJECT',
  SupplierAcctAccTransactionsInvoicesCancel = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_INVOICES____CANCEL',
  SupplierAcctAccTransactionsInvoicesDelete = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_INVOICES____DELETE',
  SupplierAcctDebitNotesView = 'SUPPLIER_ACCT_DEBIT_NOTES____VIEW',
  SupplierAcctDebitNotesCreate = 'SUPPLIER_ACCT_DEBIT_NOTES____CREATE',
  SupplierAcctDebitNotesUpdate = 'SUPPLIER_ACCT_DEBIT_NOTES____UPDATE',
  SupplierAcctDebitNotesApproveReject = 'SUPPLIER_ACCT_DEBIT_NOTES____APPROVE_REJECT',
  SupplierAcctDebitNotesCancel = 'SUPPLIER_ACCT_DEBIT_NOTES____CANCEL',
  SupplierAcctDebitNotesDelete = 'SUPPLIER_ACCT_DEBIT_NOTES____DELETE',
  SupplierAcctAccTransactionsDebitNotesView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____VIEW',
  SupplierAcctAccTransactionsDebitNotesCreate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____CREATE',
  SupplierAcctAccTransactionsDebitNotesUpdate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____UPDATE',
  SupplierAcctAccTransactionsDebitNotesApproveReject = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____APPROVE_REJECT',
  SupplierAcctAccTransactionsDebitNotesCancel = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____CANCEL',
  SupplierAcctAccTransactionsDebitNotesDelete = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____DELETE',
  SupplierAcctCreditNotesView = 'SUPPLIER_ACCT_CREDIT_NOTES____VIEW',
  SupplierAcctCreditNotesCreate = 'SUPPLIER_ACCT_CREDIT_NOTES____CREATE',
  SupplierAcctCreditNotesUpdate = 'SUPPLIER_ACCT_CREDIT_NOTES____UPDATE',
  SupplierAcctCreditNotesApproveReject = 'SUPPLIER_ACCT_CREDIT_NOTES____APPROVE_REJECT',
  SupplierAcctCreditNotesCancel = 'SUPPLIER_ACCT_CREDIT_NOTES____CANCEL',
  SupplierAcctCreditNotesDelete = 'SUPPLIER_ACCT_CREDIT_NOTES____DELETE',
  SupplierAcctCreditNotesAllocation = 'SUPPLIER_ACCT_CREDIT_NOTES____ALLOCATION',
  SupplierAcctAccTransactionsCreditNotesView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____VIEW',
  SupplierAcctAccTransactionsCreditNotesCreate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____CREATE',
  SupplierAcctAccTransactionsCreditNotesUpdate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____UPDATE',
  SupplierAcctAccTransactionsCreditNotesApproveReject = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____APPROVE_REJECT',
  SupplierAcctAccTransactionsCreditNotesCancel = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____CANCEL',
  SupplierAcctAccTransactionsCreditNotesDelete = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____DELETE',
  SupplierAcctAccTransactionsCreditNotesAllocation = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____ALLOCATION',
  SupplierAcctPaymentsView = 'SUPPLIER_ACCT_PAYMENTS____VIEW',
  SupplierAcctPaymentsCreate = 'SUPPLIER_ACCT_PAYMENTS____CREATE',
  SupplierAcctPaymentsUpdate = 'SUPPLIER_ACCT_PAYMENTS____UPDATE',
  SupplierAcctPaymentsApproveReject = 'SUPPLIER_ACCT_PAYMENTS____APPROVE_REJECT',
  SupplierAcctPaymentsCancel = 'SUPPLIER_ACCT_PAYMENTS____CANCEL',
  SupplierAcctPaymentsDelete = 'SUPPLIER_ACCT_PAYMENTS____DELETE',
  SupplierAcctAccTransactionsPaymentsView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_PAYMENTS____VIEW',
  SupplierAcctAccTransactionsPaymentsCreate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_PAYMENTS____CREATE',
  SupplierAcctAccTransactionsPaymentsUpdate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_PAYMENTS____UPDATE',
  SupplierAcctAccTransactionsPaymentsApproveReject = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_PAYMENTS____APPROVE_REJECT',
  SupplierAcctAccTransactionsPaymentsCancel = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_PAYMENTS____CANCEL',
  SupplierAcctAccTransactionsPaymentsDelete = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_PAYMENTS____DELETE',
  SupplierAcctDnSupplierCreate = 'SUPPLIER_ACCT_DN_SUPPLIER____CREATE',
  SupplierAcctDnSupplierView = 'SUPPLIER_ACCT_DN_SUPPLIER____VIEW',
  SupplierAcctDnSupplierUpdate = 'SUPPLIER_ACCT_DN_SUPPLIER____UPDATE',
  SupplierAcctDnSupplierApproveReject = 'SUPPLIER_ACCT_DN_SUPPLIER____APPROVE_REJECT',
  SupplierAcctDnSupplierCancel = 'SUPPLIER_ACCT_DN_SUPPLIER____CANCEL',
  SupplierAcctDnSupplierDelete = 'SUPPLIER_ACCT_DN_SUPPLIER____DELETE',
  SupplierAcctDnSupplierAllocation = 'SUPPLIER_ACCT_DN_SUPPLIER____ALLOCATION',
  SupplierAcctAccTransactionsDnSupplierCreate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DN_SUPPLIER____CREATE',
  SupplierAcctAccTransactionsDnSupplierView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DN_SUPPLIER____VIEW',
  SupplierAcctAccTransactionsDnSupplierUpdate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DN_SUPPLIER____UPDATE',
  SupplierAcctAccTransactionsDnSupplierApproveReject = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DN_SUPPLIER____APPROVE_REJECT',
  SupplierAcctAccTransactionsDnSupplierCancel = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DN_SUPPLIER____CANCEL',
  SupplierAcctAccTransactionsDnSupplierDelete = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DN_SUPPLIER____DELETE',
  SupplierAcctAccTransactionsDnSupplierAllocation = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DN_SUPPLIER____ALLOCATION',
  SupplierAcctCnSupplierCreate = 'SUPPLIER_ACCT_CN_SUPPLIER____CREATE',
  SupplierAcctCnSupplierView = 'SUPPLIER_ACCT_CN_SUPPLIER____VIEW',
  SupplierAcctCnSupplierUpdate = 'SUPPLIER_ACCT_CN_SUPPLIER____UPDATE',
  SupplierAcctCnSupplierCancel = 'SUPPLIER_ACCT_CN_SUPPLIER____CANCEL',
  SupplierAcctCnSupplierApproveReject = 'SUPPLIER_ACCT_CN_SUPPLIER____APPROVE_REJECT',
  SupplierAcctCnSupplierDelete = 'SUPPLIER_ACCT_CN_SUPPLIER____DELETE',
  SupplierAcctAccTransactionsCnSupplierCreate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CN_SUPPLIER____CREATE',
  SupplierAcctAccTransactionsCnSupplierView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CN_SUPPLIER____VIEW',
  SupplierAcctAccTransactionsCnSupplierUpdate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CN_SUPPLIER____UPDATE',
  SupplierAcctAccTransactionsCnSupplierCancel = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CN_SUPPLIER____CANCEL',
  SupplierAcctAccTransactionsCnSupplierApproveReject = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CN_SUPPLIER____APPROVE_REJECT',
  SupplierAcctAccTransactionsCnSupplierDelete = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CN_SUPPLIER____DELETE',
  SupplierAcctAccTransactionsOsPayableView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_OS_PAYABLE____VIEW',
  SupplierAcctDigitalReportingView = 'SUPPLIER_ACCT_DIGITAL_REPORTING____VIEW',
  SupplierAcctDigitalReportingSupplierLedgerReportView = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_LEDGER_REPORT____VIEW',
  SupplierAcctDigitalReportingSupplierAgingDetailByProjectView = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_AGING_DETAIL_BY_PROJECT____VIEW',
  SupplierAcctDigitalReportingSupplierAgingDetailReportByTrxDateView = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_AGING_DETAIL_REPORT_BY_TRX_DATE____VIEW',
  SupplierAcctDigitalReportingSupplierAgingSummaryReportByTrxDateView = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_AGING_SUMMARY_REPORT_BY_TRX_DATE____VIEW',
  SupplierAcctDigitalReportingSupplierControlSummaryReportView = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_CONTROL_SUMMARY_REPORT____VIEW',
  SupplierAcctDigitalReportingSupplierDetailBreakdownReportView = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_DETAIL_BREAKDOWN_REPORT____VIEW',
  SupplierAcctDigitalReportingSupplierLedgerReportByProjectView = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_LEDGER_REPORT_BY_PROJECT____VIEW',
  SupplierAcctGeneralSettingsIcon = 'SUPPLIER_ACCT_GENERAL_SETTINGS_______ICON',
  SupplierAcctGeneralSettingsBuildingMaterialView = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____VIEW',
  SupplierAcctGeneralSettingsBuildingMaterialCreate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____CREATE',
  SupplierAcctGeneralSettingsBuildingMaterialUpdate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____UPDATE',
  SupplierAcctGeneralSettingsBuildingMaterialDelete = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____DELETE',
  SupplierAcctGeneralSettingsBuildingMaterialDownload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____DOWNLOAD',
  SupplierAcctGeneralSettingsBuildingMaterialUpload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____UPLOAD',
  SupplierAcctGeneralSettingsBuildingMaterialInactive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____INACTIVE',
  SupplierAcctGeneralSettingsBuildingMaterialActive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____ACTIVE',
  SupplierAcctGeneralSettingsBuildingMaterialCostItemView = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_VIEW',
  SupplierAcctGeneralSettingsBuildingMaterialCostItemCreate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_CREATE',
  SupplierAcctGeneralSettingsBuildingMaterialCostItemUpdate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_UPDATE',
  SupplierAcctGeneralSettingsBuildingMaterialCostItemDelete = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_DELETE',
  SupplierAcctGeneralSettingsBuildingMaterialCostItemInactive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_INACTIVE',
  SupplierAcctGeneralSettingsBuildingMaterialCostItemActive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_ACTIVE',
  SupplierAcctGeneralSettingsPAndMCategoryView = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____VIEW',
  SupplierAcctGeneralSettingsPAndMCategoryCreate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____CREATE',
  SupplierAcctGeneralSettingsPAndMCategoryUpdate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____UPDATE',
  SupplierAcctGeneralSettingsPAndMCategoryDelete = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____DELETE',
  SupplierAcctGeneralSettingsPAndMCategoryDownload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____DOWNLOAD',
  SupplierAcctGeneralSettingsPAndMCategoryUpload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____UPLOAD',
  SupplierAcctGeneralSettingsPAndMCategoryInactive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____INACTIVE',
  SupplierAcctGeneralSettingsPAndMCategoryActive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____ACTIVE',
  SupplierAcctGeneralSettingsLabourCategoryView = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____VIEW',
  SupplierAcctGeneralSettingsLabourCategoryCreate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____CREATE',
  SupplierAcctGeneralSettingsLabourCategoryUpdate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____UPDATE',
  SupplierAcctGeneralSettingsLabourCategoryDelete = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____DELETE',
  SupplierAcctGeneralSettingsLabourCategoryDownload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____DOWNLOAD',
  SupplierAcctGeneralSettingsLabourCategoryUpload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____UPLOAD',
  SupplierAcctGeneralSettingsLabourCategoryInactive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____INACTIVE',
  SupplierAcctGeneralSettingsLabourCategoryActive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____ACTIVE',
  SupplierAcctGeneralSettingsMiscellaneousExpenseView = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____VIEW',
  SupplierAcctGeneralSettingsMiscellaneousExpenseCreate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____CREATE',
  SupplierAcctGeneralSettingsMiscellaneousExpenseUpdate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____UPDATE',
  SupplierAcctGeneralSettingsMiscellaneousExpenseDelete = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____DELETE',
  SupplierAcctGeneralSettingsMiscellaneousExpenseDownload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____DOWNLOAD',
  SupplierAcctGeneralSettingsMiscellaneousExpenseUpload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____UPLOAD',
  SupplierAcctGeneralSettingsMiscellaneousExpenseInactive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____INACTIVE',
  SupplierAcctGeneralSettingsMiscellaneousExpenseActive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____ACTIVE',
  SiteMgmtIcon = 'SITE_MGMT__________ICON',
  ProjectCostingIcon = 'PROJECT_COSTING__________ICON',
  ProjectCostingContractView = 'PROJECT_COSTING_CONTRACT_______VIEW',
  ProjectCostingBudgetingView = 'PROJECT_COSTING_BUDGETING_______VIEW',
  ProjectCostingBudgetingCostCategoryView = 'PROJECT_COSTING_BUDGETING_COST_CATEGORY____VIEW',
  ProjectCostingBudgetingCostCategoryOriginalBudgetView = 'PROJECT_COSTING_BUDGETING_COST_CATEGORY_ORIGINAL_BUDGET_VIEW',
  ProjectCostingBudgetingCostCategoryOriginalBudgetCreate = 'PROJECT_COSTING_BUDGETING_COST_CATEGORY_ORIGINAL_BUDGET_CREATE',
  ProjectCostingBudgetingCostCategoryOriginalBudgetDelete = 'PROJECT_COSTING_BUDGETING_COST_CATEGORY_ORIGINAL_BUDGET_DELETE',
  ProjectCostingBudgetingCostCategoryOriginalBudgetUpdate = 'PROJECT_COSTING_BUDGETING_COST_CATEGORY_ORIGINAL_BUDGET_UPDATE',
  ProjectCostingBudgetingCostCategoryReviseBudgetView = 'PROJECT_COSTING_BUDGETING_COST_CATEGORY_REVISE_BUDGET_VIEW',
  ProjectCostingBudgetingCostCategoryReviseBudgetUpdate = 'PROJECT_COSTING_BUDGETING_COST_CATEGORY_REVISE_BUDGET_UPDATE',
  ProjectCostingBudgetingSubcontractTradeCostView = 'PROJECT_COSTING_BUDGETING_SUBCONTRACT_TRADE_COST____VIEW',
  ProjectCostingBudgetingSubcontractTradeCostOriginalBudgetCreate = 'PROJECT_COSTING_BUDGETING_SUBCONTRACT_TRADE_COST_ORIGINAL_BUDGET_CREATE',
  ProjectCostingBudgetingSubcontractTradeCostOriginalBudgetDelete = 'PROJECT_COSTING_BUDGETING_SUBCONTRACT_TRADE_COST_ORIGINAL_BUDGET_DELETE',
  ProjectCostingBudgetingSubcontractTradeCostOriginalBudgetUpdate = 'PROJECT_COSTING_BUDGETING_SUBCONTRACT_TRADE_COST_ORIGINAL_BUDGET_UPDATE',
  ProjectCostingBudgetingSubcontractTradeCostOriginalBudgetView = 'PROJECT_COSTING_BUDGETING_SUBCONTRACT_TRADE_COST_ORIGINAL_BUDGET_VIEW',
  ProjectCostingBudgetingSubcontractTradeCostReviseBudgetView = 'PROJECT_COSTING_BUDGETING_SUBCONTRACT_TRADE_COST_REVISE_BUDGET_VIEW',
  ProjectCostingBudgetingSubcontractTradeCostReviseBudgetUpdate = 'PROJECT_COSTING_BUDGETING_SUBCONTRACT_TRADE_COST_REVISE_BUDGET_UPDATE',
  ProjectCostingBudgetingMaterialCostView = 'PROJECT_COSTING_BUDGETING_MATERIAL_COST____VIEW',
  ProjectCostingBudgetingMaterialCostOriginalBudgetCreate = 'PROJECT_COSTING_BUDGETING_MATERIAL_COST_ORIGINAL_BUDGET_CREATE',
  ProjectCostingBudgetingMaterialCostOriginalBudgetUpdate = 'PROJECT_COSTING_BUDGETING_MATERIAL_COST_ORIGINAL_BUDGET_UPDATE',
  ProjectCostingBudgetingMaterialCostOriginalBudgetView = 'PROJECT_COSTING_BUDGETING_MATERIAL_COST_ORIGINAL_BUDGET_VIEW',
  ProjectCostingBudgetingMaterialCostOriginalBudgetDelete = 'PROJECT_COSTING_BUDGETING_MATERIAL_COST_ORIGINAL_BUDGET_DELETE',
  ProjectCostingBudgetingMaterialCostReviseBudgetUpdate = 'PROJECT_COSTING_BUDGETING_MATERIAL_COST_REVISE_BUDGET_UPDATE',
  ProjectCostingBudgetingMaterialCostReviseBudgetView = 'PROJECT_COSTING_BUDGETING_MATERIAL_COST_REVISE_BUDGET_VIEW',
  ProjectCostingBudgetingPAndMCostView = 'PROJECT_COSTING_BUDGETING_P_AND_M_COST____VIEW',
  ProjectCostingBudgetingPAndMCostOriginalBudgetCreate = 'PROJECT_COSTING_BUDGETING_P_AND_M_COST_ORIGINAL_BUDGET_CREATE',
  ProjectCostingBudgetingPAndMCostOriginalBudgetView = 'PROJECT_COSTING_BUDGETING_P_AND_M_COST_ORIGINAL_BUDGET_VIEW',
  ProjectCostingBudgetingPAndMCostOriginalBudgetUpdate = 'PROJECT_COSTING_BUDGETING_P_AND_M_COST_ORIGINAL_BUDGET_UPDATE',
  ProjectCostingBudgetingPAndMCostOriginalBudgetDelete = 'PROJECT_COSTING_BUDGETING_P_AND_M_COST_ORIGINAL_BUDGET_DELETE',
  ProjectCostingBudgetingPAndMCostReviseBudgetView = 'PROJECT_COSTING_BUDGETING_P_AND_M_COST_REVISE_BUDGET_VIEW',
  ProjectCostingBudgetingPAndMCostReviseBudgetUpdate = 'PROJECT_COSTING_BUDGETING_P_AND_M_COST_REVISE_BUDGET_UPDATE',
  ProjectCostingBudgetingLabourCostView = 'PROJECT_COSTING_BUDGETING_LABOUR_COST____VIEW',
  ProjectCostingBudgetingLabourCostOriginalBudgetCreate = 'PROJECT_COSTING_BUDGETING_LABOUR_COST_ORIGINAL_BUDGET_CREATE',
  ProjectCostingBudgetingLabourCostOriginalBudgetView = 'PROJECT_COSTING_BUDGETING_LABOUR_COST_ORIGINAL_BUDGET_VIEW',
  ProjectCostingBudgetingLabourCostOriginalBudgetUpdate = 'PROJECT_COSTING_BUDGETING_LABOUR_COST_ORIGINAL_BUDGET_UPDATE',
  ProjectCostingBudgetingLabourCostOriginalBudgetDelete = 'PROJECT_COSTING_BUDGETING_LABOUR_COST_ORIGINAL_BUDGET_DELETE',
  ProjectCostingBudgetingLabourCostReviseBudgetUpdate = 'PROJECT_COSTING_BUDGETING_LABOUR_COST_REVISE_BUDGET_UPDATE',
  ProjectCostingBudgetingLabourCostReviseBudgetView = 'PROJECT_COSTING_BUDGETING_LABOUR_COST_REVISE_BUDGET_VIEW',
  ProjectCostingBudgetingMiscExpensesView = 'PROJECT_COSTING_BUDGETING_MISC_EXPENSES____VIEW',
  ProjectCostingBudgetingMiscExpensesOriginalBudgetCreate = 'PROJECT_COSTING_BUDGETING_MISC_EXPENSES_ORIGINAL_BUDGET_CREATE',
  ProjectCostingBudgetingMiscExpensesOriginalBudgetView = 'PROJECT_COSTING_BUDGETING_MISC_EXPENSES_ORIGINAL_BUDGET_VIEW',
  ProjectCostingBudgetingMiscExpensesOriginalBudgetUpdate = 'PROJECT_COSTING_BUDGETING_MISC_EXPENSES_ORIGINAL_BUDGET_UPDATE',
  ProjectCostingBudgetingMiscExpensesOriginalBudgetDelete = 'PROJECT_COSTING_BUDGETING_MISC_EXPENSES_ORIGINAL_BUDGET_DELETE',
  ProjectCostingBudgetingMiscExpensesReviseBudgetView = 'PROJECT_COSTING_BUDGETING_MISC_EXPENSES_REVISE_BUDGET_VIEW',
  ProjectCostingBudgetingMiscExpensesReviseBudgetUpdate = 'PROJECT_COSTING_BUDGETING_MISC_EXPENSES_REVISE_BUDGET_UPDATE',
  ProjectCostingMiscellaneousExpenseView = 'PROJECT_COSTING_MISCELLANEOUS_EXPENSE____VIEW',
  ProjectCostingMiscellaneousExpenseCreate = 'PROJECT_COSTING_MISCELLANEOUS_EXPENSE____CREATE',
  ProjectCostingMiscellaneousExpenseUpdate = 'PROJECT_COSTING_MISCELLANEOUS_EXPENSE____UPDATE',
  ProjectCostingMiscellaneousExpenseCancel = 'PROJECT_COSTING_MISCELLANEOUS_EXPENSE____CANCEL',
  ProjectCostingProjectCostingView = 'PROJECT_COSTING_PROJECT_COSTING_______VIEW',
  ProjectCostingProjectCostingExport = 'PROJECT_COSTING_PROJECT_COSTING_______EXPORT',
  ProjectCostingProjectCostingShare = 'PROJECT_COSTING_PROJECT_COSTING_______SHARE',
  ProjectCostingProjectCostingSubcontractCostView = 'PROJECT_COSTING_PROJECT_COSTING_SUBCONTRACT_COST____VIEW',
  ProjectCostingProjectCostingSubcontractCostDownload = 'PROJECT_COSTING_PROJECT_COSTING_SUBCONTRACT_COST____DOWNLOAD',
  ProjectCostingProjectCostingMaterialCostView = 'PROJECT_COSTING_PROJECT_COSTING_MATERIAL_COST____VIEW',
  ProjectCostingProjectCostingMaterialCostDownload = 'PROJECT_COSTING_PROJECT_COSTING_MATERIAL_COST____DOWNLOAD',
  ProjectCostingProjectCostingPAndMCostView = 'PROJECT_COSTING_PROJECT_COSTING_P_AND_M_COST____VIEW',
  ProjectCostingProjectCostingPAndMCostDownload = 'PROJECT_COSTING_PROJECT_COSTING_P_AND_M_COST____DOWNLOAD',
  ProjectCostingProjectCostingLabourCostView = 'PROJECT_COSTING_PROJECT_COSTING_LABOUR_COST____VIEW',
  ProjectCostingProjectCostingLabourCostDownload = 'PROJECT_COSTING_PROJECT_COSTING_LABOUR_COST____DOWNLOAD',
  ProjectCostingProjectCostingMiscellaneousExpensesView = 'PROJECT_COSTING_PROJECT_COSTING_MISCELLANEOUS_EXPENSES____VIEW',
  ProjectCostingProjectCostingMiscellaneousExpensesDownload = 'PROJECT_COSTING_PROJECT_COSTING_MISCELLANEOUS_EXPENSES____DOWNLOAD',
  ProjectCostingProjectPlView = 'PROJECT_COSTING_PROJECT_PL_______VIEW',
  ProjectCostingProjectPlShare = 'PROJECT_COSTING_PROJECT_PL_______SHARE',
  ProjectCostingProjectPlDownload = 'PROJECT_COSTING_PROJECT_PL____DOWNLOAD',
  ProjectCostingProjectPlExport = 'PROJECT_COSTING_PROJECT_PL____EXPORT',
  ProjectCostingProjectPlRevisedContractSumsView = 'PROJECT_COSTING_PROJECT_PL_REVISED_CONTRACT_SUMS_____VIEW',
  ProjectCostingProjectPlProgressIncomeView = 'PROJECT_COSTING_PROJECT_PL_PROGRESS_INCOME_____VIEW',
  ProjectCostingProjectPlProgressIncomeDownload = 'PROJECT_COSTING_PROJECT_PL_PROGRESS_INCOME____DOWNLOAD',
  ProjectCostingProjectPlAdjustmentClientView = 'PROJECT_COSTING_PROJECT_PL_ADJUSTMENT_CLIENT_____VIEW',
  ProjectCostingProjectPlAdjustmentClientDownload = 'PROJECT_COSTING_PROJECT_PL_ADJUSTMENT_CLIENT____DOWNLOAD',
  ProjectCostingProjectPlMiscellaneousInvoicesArView = 'PROJECT_COSTING_PROJECT_PL_MISCELLANEOUS_INVOICES_AR_____VIEW',
  ProjectCostingProjectPlMiscellaneousInvoicesArDownload = 'PROJECT_COSTING_PROJECT_PL_MISCELLANEOUS_INVOICES_AR____DOWNLOAD',
  ProjectCostingProjectPlSubcontractCostView = 'PROJECT_COSTING_PROJECT_PL_SUBCONTRACT_COST_____VIEW',
  ProjectCostingProjectPlSubcontractCostDownload = 'PROJECT_COSTING_PROJECT_PL_SUBCONTRACT_COST____DOWNLOAD',
  ProjectCostingProjectPlMaterialCostView = 'PROJECT_COSTING_PROJECT_PL_MATERIAL_COST_____VIEW',
  ProjectCostingProjectPlMaterialCostDownload = 'PROJECT_COSTING_PROJECT_PL_MATERIAL_COST____DOWNLOAD',
  ProjectCostingProjectPlPAndMCostView = 'PROJECT_COSTING_PROJECT_PL_P_AND_M_COST_____VIEW',
  ProjectCostingProjectPlPAndMCostDownload = 'PROJECT_COSTING_PROJECT_PL_P_AND_M_COST____DOWNLOAD',
  ProjectCostingProjectPlLabourCostView = 'PROJECT_COSTING_PROJECT_PL_LABOUR_COST_____VIEW',
  ProjectCostingProjectPlLabourCostDownload = 'PROJECT_COSTING_PROJECT_PL_LABOUR_COST____DOWNLOAD',
  ProjectCostingProjectPlMiscellaneousInvoiceApView = 'PROJECT_COSTING_PROJECT_PL_MISCELLANEOUS_INVOICE_AP_____VIEW',
  ProjectCostingProjectPlMiscellaneousInvoiceApDownload = 'PROJECT_COSTING_PROJECT_PL_MISCELLANEOUS_INVOICE_AP____DOWNLOAD',
  ProjectCostingProjectPlAdjustmentSupplierSubconView = 'PROJECT_COSTING_PROJECT_PL_ADJUSTMENT_SUPPLIER_SUBCON_____VIEW',
  ProjectCostingProjectPlAdjustmentSupplierSubconDownload = 'PROJECT_COSTING_PROJECT_PL_ADJUSTMENT_SUPPLIER_SUBCON____DOWNLOAD',
  ProjectCostingProjectPlMiscellaneousExpensesView = 'PROJECT_COSTING_PROJECT_PL_MISCELLANEOUS_EXPENSES_____VIEW',
  ProjectCostingProjectPlMiscellaneousExpensesDownload = 'PROJECT_COSTING_PROJECT_PL_MISCELLANEOUS_EXPENSES____DOWNLOAD',
  ProjectCostingProjectCashFlowView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_______VIEW',
  ProjectCostingProjectCashFlowShare = 'PROJECT_COSTING_PROJECT_CASH_FLOW_______SHARE',
  ProjectCostingProjectCashFlowReceivedFromClientView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_RECEIVED_FROM_CLIENT____VIEW',
  ProjectCostingProjectCashFlowReceivedFromClientDownload = 'PROJECT_COSTING_PROJECT_CASH_FLOW_RECEIVED_FROM_CLIENT____DOWNLOAD',
  ProjectCostingProjectCashFlowPaidToSubcontractorView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_PAID_TO_SUBCONTRACTOR____VIEW',
  ProjectCostingProjectCashFlowPaidToSubcontractorDownload = 'PROJECT_COSTING_PROJECT_CASH_FLOW_PAID_TO_SUBCONTRACTOR____DOWNLOAD',
  ProjectCostingProjectCashFlowPaidToSupplierView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_PAID_TO_SUPPLIER____VIEW',
  ProjectCostingProjectCashFlowPaidToSupplierDownload = 'PROJECT_COSTING_PROJECT_CASH_FLOW_PAID_TO_SUPPLIER____DOWNLOAD',
  ProjectCostingProjectCashFlowOtherExpensesView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_OTHER_EXPENSES____VIEW',
  ProjectCostingProjectCashFlowOsReceivableFromClientView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_OS_RECEIVABLE_FROM_CLIENT____VIEW',
  ProjectCostingProjectCashFlowOsReceivableFromClientDownload = 'PROJECT_COSTING_PROJECT_CASH_FLOW_OS_RECEIVABLE_FROM_CLIENT____DOWNLOAD',
  ProjectCostingProjectCashFlowOsPayableToSubcontractorView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_OS_PAYABLE_TO_SUBCONTRACTOR____VIEW',
  ProjectCostingProjectCashFlowOsPayableToSubcontractorDownload = 'PROJECT_COSTING_PROJECT_CASH_FLOW_OS_PAYABLE_TO_SUBCONTRACTOR____DOWNLOAD',
  ProjectCostingProjectCashFlowOsPayableToSupplierView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_OS_PAYABLE_TO_SUPPLIER____VIEW',
  ProjectCostingProjectCashFlowOsPayableToSupplierDownload = 'PROJECT_COSTING_PROJECT_CASH_FLOW_OS_PAYABLE_TO_SUPPLIER____DOWNLOAD',
  ProjectCostingProjectCostPerformanceView = 'PROJECT_COSTING_PROJECT_COST_PERFORMANCE_______VIEW',
  ProjectCostingProjectCostPerformanceShare = 'PROJECT_COSTING_PROJECT_COST_PERFORMANCE_______SHARE',
  ProjectCostingProjectCostPerformancePercentageOfCompletionUpdate = 'PROJECT_COSTING_PROJECT_COST_PERFORMANCE_PERCENTAGE_OF_COMPLETION____UPDATE',
  ProjectCostingProjectFinancialStatusView = 'PROJECT_COSTING_PROJECT_FINANCIAL_STATUS_______VIEW',
  ProjectCostingProjectFinancialStatusShare = 'PROJECT_COSTING_PROJECT_FINANCIAL_STATUS_______SHARE',
  ProjectCostingProjectFinancialStatusPercentageOfCompletionUpdate = 'PROJECT_COSTING_PROJECT_FINANCIAL_STATUS_PERCENTAGE_OF_COMPLETION____UPDATE',
  ProjectCostingProjectFinancialStatusBillingToDateView = 'PROJECT_COSTING_PROJECT_FINANCIAL_STATUS_BILLING_TO_DATE____VIEW',
  ProjectCostingProjectFinancialStatusCollectionToDateView = 'PROJECT_COSTING_PROJECT_FINANCIAL_STATUS_COLLECTION_TO_DATE____VIEW',
  ProjectCostingProjectFinancialStatusPayableToDateView = 'PROJECT_COSTING_PROJECT_FINANCIAL_STATUS_PAYABLE_TO_DATE____VIEW',
  ProjectCostingProjectFinancialStatusPaymentToDateView = 'PROJECT_COSTING_PROJECT_FINANCIAL_STATUS_PAYMENT_TO_DATE____VIEW',
  ProjectCostingProjectBudgetAnalysisShare = 'PROJECT_COSTING_PROJECT_BUDGET_ANALYSIS_______SHARE',
  ProjectCostingProjectBudgetAnalysisView = 'PROJECT_COSTING_PROJECT_BUDGET_ANALYSIS_______VIEW',
  ProjectCostingProjectBudgetAnalysisOriginalBudgetView = 'PROJECT_COSTING_PROJECT_BUDGET_ANALYSIS_ORIGINAL_BUDGET_______VIEW',
  ProjectCostingProjectBudgetAnalysisRevisedBudgetView = 'PROJECT_COSTING_PROJECT_BUDGET_ANALYSIS_REVISED_BUDGET_______VIEW',
  ProjectCostingProjectBudgetVsActualAnalysisView = 'PROJECT_COSTING_PROJECT_BUDGET_VS_ACTUAL_ANALYSIS_______VIEW',
  ProjectCostingProjectBudgetVsActualAnalysisShare = 'PROJECT_COSTING_PROJECT_BUDGET_VS_ACTUAL_ANALYSIS_______SHARE',
  ProjectCostingDigitalReportView = 'PROJECT_COSTING_DIGITAL_REPORT_______VIEW',
  ProjectCostingDigitalReportExecutiveSummaryReportView = 'PROJECT_COSTING_DIGITAL_REPORT_EXECUTIVE_SUMMARY_REPORT_______VIEW',
  ProjectCostingDigitalReportProjectCostAnalysisReportView = 'PROJECT_COSTING_DIGITAL_REPORT_PROJECT_COST_ANALYSIS_REPORT_______VIEW',
  ProjectCostingDigitalReportProjectLedgerReportView = 'PROJECT_COSTING_DIGITAL_REPORT_PROJECT_LEDGER_REPORT_______VIEW',
  ProjectCostingGeneralSettingsIcon = 'PROJECT_COSTING_GENERAL_SETTINGS_______ICON',
  ProjectCostingGeneralSettingsSubcontractTradeCreate = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____CREATE',
  ProjectCostingGeneralSettingsSubcontractTradeView = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____VIEW',
  ProjectCostingGeneralSettingsSubcontractTradeUpdate = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPDATE',
  ProjectCostingGeneralSettingsSubcontractTradeDelete = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DELETE',
  ProjectCostingGeneralSettingsSubcontractTradeDownload = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DOWNLOAD',
  ProjectCostingGeneralSettingsSubcontractTradeUpload = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPLOAD',
  ProjectCostingGeneralSettingsSubcontractTradeInactive = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____INACTIVE',
  ProjectCostingGeneralSettingsSubcontractTradeActive = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____ACTIVE',
  ProjectCostingGeneralSettingsLabourCategoryView = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____VIEW',
  ProjectCostingGeneralSettingsLabourCategoryCreate = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____CREATE',
  ProjectCostingGeneralSettingsLabourCategoryUpdate = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____UPDATE',
  ProjectCostingGeneralSettingsLabourCategoryDelete = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____DELETE',
  ProjectCostingGeneralSettingsLabourCategoryDownload = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____DOWNLOAD',
  ProjectCostingGeneralSettingsLabourCategoryUpload = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____UPLOAD',
  ProjectCostingGeneralSettingsLabourCategoryInactive = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____INACTIVE',
  ProjectCostingGeneralSettingsLabourCategoryActive = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____ACTIVE',
  ProjectCostingGeneralSettingsBuildingMaterialView = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____VIEW',
  ProjectCostingGeneralSettingsBuildingMaterialCreate = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____CREATE',
  ProjectCostingGeneralSettingsBuildingMaterialUpdate = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____UPDATE',
  ProjectCostingGeneralSettingsBuildingMaterialDownload = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____DOWNLOAD',
  ProjectCostingGeneralSettingsBuildingMaterialUpload = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____UPLOAD',
  ProjectCostingGeneralSettingsBuildingMaterialDelete = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____DELETE',
  ProjectCostingGeneralSettingsBuildingMaterialInactive = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____INACTIVE',
  ProjectCostingGeneralSettingsBuildingMaterialActive = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____ACTIVE',
  ProjectCostingGeneralSettingsBuildingMaterialCostItemView = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_VIEW',
  ProjectCostingGeneralSettingsBuildingMaterialCostItemCreate = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_CREATE',
  ProjectCostingGeneralSettingsBuildingMaterialCostItemUpdate = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_UPDATE',
  ProjectCostingGeneralSettingsBuildingMaterialCostItemDelete = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_DELETE',
  ProjectCostingGeneralSettingsBuildingMaterialCostItemInactive = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_INACTIVE',
  ProjectCostingGeneralSettingsBuildingMaterialCostItemActive = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_ACTIVE',
  ProjectCostingGeneralSettingsPAndMCategoryView = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____VIEW',
  ProjectCostingGeneralSettingsPAndMCategoryCreate = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____CREATE',
  ProjectCostingGeneralSettingsPAndMCategoryUpdate = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____UPDATE',
  ProjectCostingGeneralSettingsPAndMCategoryDownload = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____DOWNLOAD',
  ProjectCostingGeneralSettingsPAndMCategoryUpload = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____UPLOAD',
  ProjectCostingGeneralSettingsPAndMCategoryDelete = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____DELETE',
  ProjectCostingGeneralSettingsPAndMCategoryInactive = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____INACTIVE',
  ProjectCostingGeneralSettingsPAndMCategoryActive = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____ACTIVE',
  ProjectCostingGeneralSettingsMiscellaneousExpensesView = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____VIEW',
  ProjectCostingGeneralSettingsMiscellaneousExpensesCreate = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____CREATE',
  ProjectCostingGeneralSettingsMiscellaneousExpensesUpdate = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____UPDATE',
  ProjectCostingGeneralSettingsMiscellaneousExpensesDownload = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____DOWNLOAD',
  ProjectCostingGeneralSettingsMiscellaneousExpensesUpload = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____UPLOAD',
  ProjectCostingGeneralSettingsMiscellaneousExpensesDelete = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____DELETE',
  ProjectCostingGeneralSettingsMiscellaneousExpensesInactive = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____INACTIVE',
  ProjectCostingGeneralSettingsMiscellaneousExpensesActive = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____ACTIVE',
  PlantMachineryIcon = 'PLANT_MACHINERY__________ICON',
  PlantMachineryView = 'PLANT_MACHINERY__________VIEW',
  PlantMachineryOwnedPMView = 'PLANT_MACHINERY_OWNED_P_M_______VIEW',
  PlantMachineryOwnedPMCreate = 'PLANT_MACHINERY_OWNED_P_M_______CREATE',
  PlantMachineryOwnedPMUpdate = 'PLANT_MACHINERY_OWNED_P_M_______UPDATE',
  PlantMachineryOwnedPMAssignmentHistoryView = 'PLANT_MACHINERY_OWNED_P_M_ASSIGNMENT_HISTORY____VIEW',
  PlantMachineryOwnedPMMaintenanceHistoryView = 'PLANT_MACHINERY_OWNED_P_M_MAINTENANCE_HISTORY____VIEW',
  PlantMachineryRentedPMView = 'PLANT_MACHINERY_RENTED_P_M_______VIEW',
  PlantMachineryRentedPMCreate = 'PLANT_MACHINERY_RENTED_P_M_______CREATE',
  PlantMachineryRentedPMUpdate = 'PLANT_MACHINERY_RENTED_P_M_______UPDATE',
  PlantMachineryRentedPMReturn = 'PLANT_MACHINERY_RENTED_P_M_______RETURN',
  PlantMachineryAssignmentRecordsView = 'PLANT_MACHINERY_ASSIGNMENT_RECORDS_______VIEW',
  PlantMachineryAssignmentRecordsCreate = 'PLANT_MACHINERY_ASSIGNMENT_RECORDS_______CREATE',
  PlantMachineryAssignmentRecordsUpdate = 'PLANT_MACHINERY_ASSIGNMENT_RECORDS_______UPDATE',
  PlantMachineryAssignmentRecordsReturn = 'PLANT_MACHINERY_ASSIGNMENT_RECORDS_______RETURN',
  PlantMachineryAssignmentRecordsCancel = 'PLANT_MACHINERY_ASSIGNMENT_RECORDS_______CANCEL',
  PlantMachineryAssignmentRecordsSendMaintenance = 'PLANT_MACHINERY_ASSIGNMENT_RECORDS_______SEND_MAINTENANCE',
  PlantMachineryMaintanenceRecordsView = 'PLANT_MACHINERY_MAINTANENCE_RECORDS_______VIEW',
  PlantMachineryMaintanenceRecordsCreate = 'PLANT_MACHINERY_MAINTANENCE_RECORDS_______CREATE',
  PlantMachineryMaintanenceRecordsReturn = 'PLANT_MACHINERY_MAINTANENCE_RECORDS_______RETURN',
  PlantMachineryMaintanenceRecordsCancel = 'PLANT_MACHINERY_MAINTANENCE_RECORDS_______CANCEL',
  PlantMachineryGeneralSettingsIcon = 'PLANT_MACHINERY_GENERAL_SETTINGS_______ICON',
  PlantMachineryGeneralSettingsPMCategoryView = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____VIEW',
  PlantMachineryGeneralSettingsPMCategoryCreate = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____CREATE',
  PlantMachineryGeneralSettingsPMCategoryUpdate = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____UPDATE',
  PlantMachineryGeneralSettingsPMCategoryDownload = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____DOWNLOAD',
  PlantMachineryGeneralSettingsPMCategoryUpload = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____UPLOAD',
  PlantMachineryGeneralSettingsPMCategoryInactive = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____INACTIVE',
  PlantMachineryGeneralSettingsPMCategoryActive = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____ACTIVE',
  PlantMachineryGeneralSettingsPMCategoryDelete = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____DELETE',
  PlantMachineryGeneralSettingsWorkshopMaintenanceView = 'PLANT_MACHINERY_GENERAL_SETTINGS_WORKSHOP_MAINTENANCE____VIEW',
  PlantMachineryGeneralSettingsWorkshopMaintenanceCreate = 'PLANT_MACHINERY_GENERAL_SETTINGS_WORKSHOP_MAINTENANCE____CREATE',
  PlantMachineryGeneralSettingsWorkshopMaintenanceUpdate = 'PLANT_MACHINERY_GENERAL_SETTINGS_WORKSHOP_MAINTENANCE____UPDATE',
  GlInterfaceIcon = 'GL_INTERFACE__________ICON',
  GlInterfaceView = 'GL_INTERFACE_______VIEW',
  GlInterfaceGeneralLedgerExportView = 'GL_INTERFACE_GENERAL_LEDGER_EXPORT____VIEW',
  GlInterfaceGeneralLedgerExportCreate = 'GL_INTERFACE_GENERAL_LEDGER_EXPORT____CREATE',
  GlInterfaceGeneralLedgerExportExport = 'GL_INTERFACE_GENERAL_LEDGER_EXPORT____EXPORT',
  GlInterfaceCashBookView = 'GL_INTERFACE_CASH_BOOK____VIEW',
  GlInterfaceCashBookCreate = 'GL_INTERFACE_CASH_BOOK____CREATE',
  GlInterfaceCashBookExport = 'GL_INTERFACE_CASH_BOOK____EXPORT',
  GlInterfaceGeneralSettingsIcon = 'GL_INTERFACE_GENERAL_SETTINGS_______ICON',
  SystemAdminIcon = 'SYSTEM_ADMIN__________ICON',
  SystemAdminAccessSecurityView = 'SYSTEM_ADMIN_ACCESS_SECURITY_______VIEW',
  SystemAdminAccessSecurityUserView = 'SYSTEM_ADMIN_ACCESS_SECURITY_USER____VIEW',
  SystemAdminAccessSecurityUserCreate = 'SYSTEM_ADMIN_ACCESS_SECURITY_USER____CREATE',
  SystemAdminAccessSecurityUserUpdate = 'SYSTEM_ADMIN_ACCESS_SECURITY_USER____UPDATE',
  SystemAdminAccessSecurityUserChangePassword = 'SYSTEM_ADMIN_ACCESS_SECURITY_USER____CHANGE_PASSWORD',
  SystemAdminAccessSecurityUserUnblock = 'SYSTEM_ADMIN_ACCESS_SECURITY_USER____UNBLOCK',
  SystemAdminAccessSecurityRolesView = 'SYSTEM_ADMIN_ACCESS_SECURITY_ROLES____VIEW',
  SystemAdminAccessSecurityRolesCreate = 'SYSTEM_ADMIN_ACCESS_SECURITY_ROLES____CREATE',
  SystemAdminAccessSecurityRolesUpdate = 'SYSTEM_ADMIN_ACCESS_SECURITY_ROLES____UPDATE',
  SystemAdminAccessSecurityRolesDelete = 'SYSTEM_ADMIN_ACCESS_SECURITY_ROLES____DELETE',
  SystemAdminAccessSecurityProjectRoleAuthView = 'SYSTEM_ADMIN_ACCESS_SECURITY_PROJECT_ROLE_AUTH____VIEW',
  SystemAdminAccessSecurityProjectRoleAuthCreate = 'SYSTEM_ADMIN_ACCESS_SECURITY_PROJECT_ROLE_AUTH____CREATE',
  SystemAdminAccessSecurityProjectRoleAuthUpdate = 'SYSTEM_ADMIN_ACCESS_SECURITY_PROJECT_ROLE_AUTH____UPDATE',
  SystemAdminDocumentNumberingSetupView = 'SYSTEM_ADMIN_DOCUMENT_NUMBERING_SETUP_______VIEW',
  SystemAdminDocumentNumberingSetupAccountReceivableView = 'SYSTEM_ADMIN_DOCUMENT_NUMBERING_SETUP_ACCOUNT_RECEIVABLE____VIEW',
  SystemAdminDocumentNumberingSetupAccountPayableView = 'SYSTEM_ADMIN_DOCUMENT_NUMBERING_SETUP_ACCOUNT_PAYABLE____VIEW',
  SystemAdminDocumentNumberingSetupProjectPurchaseView = 'SYSTEM_ADMIN_DOCUMENT_NUMBERING_SETUP_PROJECT_PURCHASE____VIEW',
  SystemAdminDocumentNumberingSetupSiteManagementView = 'SYSTEM_ADMIN_DOCUMENT_NUMBERING_SETUP_SITE_MANAGEMENT____VIEW',
  SystemAdminDocumentNumberingSetupDefectManagementView = 'SYSTEM_ADMIN_DOCUMENT_NUMBERING_SETUP_DEFECT_MANAGEMENT____VIEW',
  SystemAdminBankAccountView = 'SYSTEM_ADMIN_BANK_ACCOUNT_______VIEW',
  SystemAdminBankAccountCreate = 'SYSTEM_ADMIN_BANK_ACCOUNT_______CREATE',
  SystemAdminBankAccountUpdate = 'SYSTEM_ADMIN_BANK_ACCOUNT_______UPDATE',
  SystemAdminBankAccountDelete = 'SYSTEM_ADMIN_BANK_ACCOUNT_______DELETE',
  SystemAdminBankAccountActive = 'SYSTEM_ADMIN_BANK_ACCOUNT_______ACTIVE',
  SystemAdminBankAccountInactive = 'SYSTEM_ADMIN_BANK_ACCOUNT_______INACTIVE',
  SystemAdminTaxSetupView = 'SYSTEM_ADMIN_TAX_SETUP_______VIEW',
  SystemAdminTaxSetupCreate = 'SYSTEM_ADMIN_TAX_SETUP_______CREATE',
  SystemAdminTaxSetupUpdate = 'SYSTEM_ADMIN_TAX_SETUP_______UPDATE',
  SystemAdminTaxSetupDelete = 'SYSTEM_ADMIN_TAX_SETUP_______DELETE',
  SystemAdminTaxSetupInactive = 'SYSTEM_ADMIN_TAX_SETUP_______INACTIVE',
  SystemAdminTaxSetupActive = 'SYSTEM_ADMIN_TAX_SETUP_______ACTIVE',
  SystemAdminTaxSetupViewHistory = 'SYSTEM_ADMIN_TAX_SETUP_______VIEW_HISTORY',
  SystemAdminUomSetupView = 'SYSTEM_ADMIN_UOM_SETUP_______VIEW',
  SystemAdminUomSetupCreate = 'SYSTEM_ADMIN_UOM_SETUP_______CREATE',
  SystemAdminUomSetupUpdate = 'SYSTEM_ADMIN_UOM_SETUP_______UPDATE',
  SystemAdminUomSetupDelete = 'SYSTEM_ADMIN_UOM_SETUP_______DELETE',
  SystemAdminUomSetupDownload = 'SYSTEM_ADMIN_UOM_SETUP_______DOWNLOAD',
  SystemAdminUomSetupUpload = 'SYSTEM_ADMIN_UOM_SETUP_______UPLOAD',
  SystemAdminUomSetupInactive = 'SYSTEM_ADMIN_UOM_SETUP_______INACTIVE',
  SystemAdminUomSetupActive = 'SYSTEM_ADMIN_UOM_SETUP_______ACTIVE',
  SystemAdminUomConversionSetupView = 'SYSTEM_ADMIN_UOM_CONVERSION_SETUP_______VIEW',
  SystemAdminUomConversionSetupUpdate = 'SYSTEM_ADMIN_UOM_CONVERSION_SETUP_______UPDATE',
  SystemAdminUomConversionSetupCreate = 'SYSTEM_ADMIN_UOM_CONVERSION_SETUP_______CREATE',
  SystemAdminUomConversionSetupDelete = 'SYSTEM_ADMIN_UOM_CONVERSION_SETUP_______DELETE',
  SystemAdminCompanySetupView = 'SYSTEM_ADMIN_COMPANY_SETUP_______VIEW',
  SystemAdminCompanySetupUpdate = 'SYSTEM_ADMIN_COMPANY_SETUP_______UPDATE',
  SystemAdminCompanySetupWatermarkLogoSetupCreate = 'SYSTEM_ADMIN_COMPANY_SETUP_WATERMARK_LOGO_SETUP____CREATE',
  SystemAdminGeneralSettingsIcon = 'SYSTEM_ADMIN_GENERAL_SETTINGS_______ICON',
  SystemAdminGeneralSettingsSubcontractTradeView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACT_TRADE_______VIEW',
  SystemAdminGeneralSettingsSubcontractTradeCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACT_TRADE_______CREATE',
  SystemAdminGeneralSettingsSubcontractTradeUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACT_TRADE_______UPDATE',
  SystemAdminGeneralSettingsSubcontractTradeInactive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACT_TRADE_______INACTIVE',
  SystemAdminGeneralSettingsSubcontractTradeDelete = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACT_TRADE_______DELETE',
  SystemAdminGeneralSettingsSubcontractTradeUpload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACT_TRADE_______UPLOAD',
  SystemAdminGeneralSettingsSubcontractTradeDownload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACT_TRADE_______DOWNLOAD',
  SystemAdminGeneralSettingsLabourCategoryView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_LABOUR_CATEGORY_______VIEW',
  SystemAdminGeneralSettingsLabourCategoryCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_LABOUR_CATEGORY_______CREATE',
  SystemAdminGeneralSettingsLabourCategoryUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_LABOUR_CATEGORY_______UPDATE',
  SystemAdminGeneralSettingsLabourCategoryInactive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_LABOUR_CATEGORY_______INACTIVE',
  SystemAdminGeneralSettingsLabourCategoryDelete = 'SYSTEM_ADMIN_GENERAL_SETTINGS_LABOUR_CATEGORY_______DELETE',
  SystemAdminGeneralSettingsLabourCategoryUpload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_LABOUR_CATEGORY_______UPLOAD',
  SystemAdminGeneralSettingsLabourCategoryDownload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_LABOUR_CATEGORY_______DOWNLOAD',
  SystemAdminPolicyGuidelineCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_______CREATE',
  SystemAdminGeneralSettingsBuildingMaterialView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_BUILDING_MATERIAL_______VIEW',
  SystemAdminGeneralSettingsBuildingMaterialCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_BUILDING_MATERIAL_______CREATE',
  SystemAdminGeneralSettingsBuildingMaterialUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_BUILDING_MATERIAL_______UPDATE',
  SystemAdminGeneralSettingsBuildingMaterialInactive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_BUILDING_MATERIAL_______INACTIVE',
  SystemAdminGeneralSettingsBuildingMaterialDelete = 'SYSTEM_ADMIN_GENERAL_SETTINGS_BUILDING_MATERIAL_______DELETE',
  SystemAdminGeneralSettingsBuildingMaterialUpload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_BUILDING_MATERIAL_______UPLOAD',
  SystemAdminGeneralSettingsBuildingMaterialDownload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_BUILDING_MATERIAL_______DOWNLOAD',
  SystemAdminGeneralSettingsMiscellaneousExpenseView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______VIEW',
  SystemAdminGeneralSettingsMiscellaneousExpenseCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______CREATE',
  SystemAdminGeneralSettingsMiscellaneousExpenseUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______UPDATE',
  SystemAdminGeneralSettingsMiscellaneousExpenseInactive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______INACTIVE',
  SystemAdminGeneralSettingsMiscellaneousExpenseDelete = 'SYSTEM_ADMIN_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______DELETE',
  SystemAdminGeneralSettingsMiscellaneousExpenseUpload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______UPLOAD',
  SystemAdminGeneralSettingsMiscellaneousExpenseDownload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______DOWNLOAD',
  SystemAdminGeneralSettingsGuaranteeTypeView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_GUARANTEE_TYPE_______VIEW',
  SystemAdminGeneralSettingsGuaranteeTypeCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_GUARANTEE_TYPE_______CREATE',
  SystemAdminGeneralSettingsGuaranteeTypeUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_GUARANTEE_TYPE_______UPDATE',
  SystemAdminGeneralSettingsGuaranteeTypeInactive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_GUARANTEE_TYPE_______INACTIVE',
  SystemAdminGeneralSettingsGuaranteeTypeDelete = 'SYSTEM_ADMIN_GENERAL_SETTINGS_GUARANTEE_TYPE_______DELETE',
  SystemAdminGeneralSettingsInsuranceTypeView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_INSURANCE_TYPE_______VIEW',
  SystemAdminGeneralSettingsInsuranceTypeCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_INSURANCE_TYPE_______CREATE',
  SystemAdminGeneralSettingsInsuranceTypeUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_INSURANCE_TYPE_______UPDATE',
  SystemAdminGeneralSettingsInsuranceTypeInactive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_INSURANCE_TYPE_______INACTIVE',
  SystemAdminGeneralSettingsInsuranceTypeDelete = 'SYSTEM_ADMIN_GENERAL_SETTINGS_INSURANCE_TYPE_______DELETE',
  SystemAdminGeneralSettingsPmCategoryView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PM_CATEGORY_______VIEW',
  SystemAdminGeneralSettingsPmCategoryCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PM_CATEGORY_______CREATE',
  SystemAdminGeneralSettingsPmCategoryUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PM_CATEGORY_______UPDATE',
  SystemAdminGeneralSettingsPmCategoryInactive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PM_CATEGORY_______INACTIVE',
  SystemAdminGeneralSettingsPmCategoryDelete = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PM_CATEGORY_______DELETE',
  SystemAdminGeneralSettingsPmCategoryUpload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PM_CATEGORY_______UPLOAD',
  SystemAdminGeneralSettingsPmCategoryDownload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PM_CATEGORY_______DOWNLOAD',
  SystemAdminGeneralSettingsWorkshopMaintenanceView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_WORKSHOP_MAINTENANCE_______VIEW',
  SystemAdminGeneralSettingsWorkshopMaintenanceCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_WORKSHOP_MAINTENANCE_______CREATE',
  SystemAdminGeneralSettingsWorkshopMaintenanceUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_WORKSHOP_MAINTENANCE_______UPDATE',
  SystemAdminGeneralSettingsClientAccountSetupUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_CLIENT_ACCOUNT_SETUP_______UPDATE',
  SystemAdminGeneralSettingsSubcontractorAccountSetupUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACTOR_ACCOUNT_SETUP_______UPDATE',
  SystemAdminGeneralSettingsSupplierAccountSetupUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUPPLIER_ACCOUNT_SETUP_______UPDATE',
  SystemAdminGeneralSettingsPurchaseAccountSetupUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PURCHASE_ACCOUNT_SETUP_______UPDATE',
  SystemAdminGeneralSettingsPobAccountSetupUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_POB_ACCOUNT_SETUP_______UPDATE',
  SystemAdminGeneralSettingsBankAccountSetupUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_BANK_ACCOUNT_SETUP_______UPDATE',
  SystemAdminGeneralSettingsTaxAccountSetupUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_TAX_ACCOUNT_SETUP_______UPDATE',
  SystemAdminGeneralSettingsTradeAccountSetupUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_TRADE_ACCOUNT_SETUP_______UPDATE',
  SystemAdminGeneralSettingsMaterialCategorySetupUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_MATERIAL_CATEGORY_SETUP_______UPDATE',
  SystemAdminGeneralSettingsPmCategorySetupUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PM_CATEGORY_SETUP_______UPDATE',
  SystemAdminGeneralSettingsLabourCategorySetupUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_LABOUR_CATEGORY_SETUP_______UPDATE',
  SystemAdminGeneralSettingsMiscCategorySetupUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_MISC_CATEGORY_SETUP_______UPDATE',
  SystemAdminGeneralSettingsJournalTypeSetupUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_JOURNAL_TYPE_SETUP_______UPDATE',
  SystemAdminGeneralSettingsGlAccountCodeSetupCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_GL_ACCOUNT_CODE_SETUP_______CREATE',
  SystemAdminPolicyGuidelineUpdate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_______UPDATE',
  SystemAdminPolicyGuidelineDelete = 'SYSTEM_ADMIN_POLICY_GUIDELINE_______DELETE',
  SystemAdminContractSettingsView = 'SYSTEM_ADMIN_CONTRACT_SETTINGS_______VIEW',
  SystemAdminContractSettingsCreate = 'SYSTEM_ADMIN_CONTRACT_SETTINGS_______CREATE',
  SystemAdminContractSettingsUpdate = 'SYSTEM_ADMIN_CONTRACT_SETTINGS_______UPDATE',
  SystemAdminPolicyGuidelineView = 'SYSTEM_ADMIN_POLICY_GUIDELINE_______VIEW',
  SystemAdminPolicyGuidelineContractManagementCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_CONTRACT_MANAGEMENT_______CREATE',
  SystemAdminPolicyGuidelineClientAccountAdvanceReceiptApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_CLIENT_ACCOUNT_ADVANCE_RECEIPT_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineClientAccountClaimApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_CLIENT_ACCOUNT_CLAIM_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineClientAccountMiscInvoiceApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_CLIENT_ACCOUNT_MISC_INVOICE_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineClientAccountDebitNoteApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_CLIENT_ACCOUNT_DEBIT_NOTE_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineClientAccountCreditNoteApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_CLIENT_ACCOUNT_CREDIT_NOTE_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineClientAccountOfficialReceiptApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_CLIENT_ACCOUNT_OFFICIAL_RECEIPT_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineClientAccountRefundApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_CLIENT_ACCOUNT_REFUND_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineClientAccountClientDnApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_CLIENT_ACCOUNT_CLIENT_DN_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineClientAccountClientCnApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_CLIENT_ACCOUNT_CLIENT_CN_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSubcontractMgmtSubcontractVoApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUBCONTRACT_MGMT_SUBCONTRACT_VO_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSubcontractorAccountAdvancePaymentApprovalPolicyView = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUBCONTRACTOR_ACCOUNT_ADVANCE_PAYMENT_APPROVAL_POLICY_______VIEW',
  SystemAdminPolicyGuidelineSubcontractorAccountAdvancePaymentApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUBCONTRACTOR_ACCOUNT_ADVANCE_PAYMENT_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSubcontractorAccountAdvancePaymentApprovalPolicyUpdate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUBCONTRACTOR_ACCOUNT_ADVANCE_PAYMENT_APPROVAL_POLICY_______UPDATE',
  SystemAdminPolicyGuidelineSubcontractorAccountAdvancePaymentApprovalPolicyDelete = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUBCONTRACTOR_ACCOUNT_ADVANCE_PAYMENT_APPROVAL_POLICY_______DELETE',
  SystemAdminPolicyGuidelineSubcontractorAccountMiscInvoiceApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUBCONTRACTOR_ACCOUNT_MISC_INVOICE_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSubcontractorAccountDebitNoteApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUBCONTRACTOR_ACCOUNT_DEBIT_NOTE_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSubcontractorAccountCreditNoteApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUBCONTRACTOR_ACCOUNT_CREDIT_NOTE_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSubcontractorAccountPaymentApprovalPolicyView = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUBCONTRACTOR_ACCOUNT_PAYMENT_APPROVAL_POLICY_______VIEW',
  SystemAdminPolicyGuidelineSubcontractorAccountPaymentApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUBCONTRACTOR_ACCOUNT_PAYMENT_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSubcontractorAccountRefundReceiptApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUBCONTRACTOR_ACCOUNT_REFUND_RECEIPT_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSubcontractorAccountCnToSubconApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUBCONTRACTOR_ACCOUNT_CN_TO_SUBCON_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSubcontractorAccountDnToSubconApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUBCONTRACTOR_ACCOUNT_DN_TO_SUBCON_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSupplierAccountAdvanceDepositApprovalPolicyView = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUPPLIER_ACCOUNT_ADVANCE_DEPOSIT_APPROVAL_POLICY_______VIEW',
  SystemAdminPolicyGuidelineSupplierAccountAdvanceDepositApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUPPLIER_ACCOUNT_ADVANCE_DEPOSIT_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSupplierAccountAdvanceDepositApprovalPolicyUpdate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUPPLIER_ACCOUNT_ADVANCE_DEPOSIT_APPROVAL_POLICY_______UPDATE',
  SystemAdminPolicyGuidelineSupplierAccountAdvanceDepositApprovalPolicyDelete = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUPPLIER_ACCOUNT_ADVANCE_DEPOSIT_APPROVAL_POLICY_______DELETE',
  SystemAdminPolicyGuidelineSupplierAccountInvoiceApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUPPLIER_ACCOUNT_INVOICE_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSupplierAccountCreditNoteApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUPPLIER_ACCOUNT_CREDIT_NOTE_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSupplierAccountDebitNoteApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUPPLIER_ACCOUNT_DEBIT_NOTE_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSupplierAccountPaymentApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUPPLIER_ACCOUNT_PAYMENT_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSupplierAccountCnToSupplierApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUPPLIER_ACCOUNT_CN_TO_SUPPLIER_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSupplierAccountDnToSupplierApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUPPLIER_ACCOUNT_DN_TO_SUPPLIER_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineProjectPurchasePrApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_PROJECT_PURCHASE_PR_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineProjectPurchasePoApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_PROJECT_PURCHASE_PO_APPROVAL_POLICY_______CREATE',
  SystemAdminNotificationPolicyCreate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_______CREATE',
  SystemAdminNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicyDelete = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_______DELETE',
  SystemAdminNotificationPolicyContractManagementContractVoNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_CONTRACT_MANAGEMENT_CONTRACT_VO_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicyContractManagementContractVoNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_CONTRACT_MANAGEMENT_CONTRACT_VO_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicyClientAccountAdvanceReceiptNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_CLIENT_ACCOUNT_ADVANCE_RECEIPT_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicyClientAccountAdvanceReceiptNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_CLIENT_ACCOUNT_ADVANCE_RECEIPT_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicyClientAccountClaimNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_CLIENT_ACCOUNT_CLAIM_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicyClientAccountClaimNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_CLIENT_ACCOUNT_CLAIM_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicyClientAccountCreditNoteNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_CLIENT_ACCOUNT_CREDIT_NOTE_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicyClientAccountCreditNoteNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_CLIENT_ACCOUNT_CREDIT_NOTE_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicyClientAccountDebitNoteNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_CLIENT_ACCOUNT_DEBIT_NOTE_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicyClientAccountDebitNoteNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_CLIENT_ACCOUNT_DEBIT_NOTE_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicyClientAccountPaymentVoucherNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_CLIENT_ACCOUNT_PAYMENT_VOUCHER_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicyClientAccountPaymentVoucherNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_CLIENT_ACCOUNT_PAYMENT_VOUCHER_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicyClientAccountClientAdjustmentNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_CLIENT_ACCOUNT_CLIENT_ADJUSTMENT_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicyClientAccountClientAdjustmentNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_CLIENT_ACCOUNT_CLIENT_ADJUSTMENT_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicySubcontractMgmtSubcontractVoNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACT_MGMT_SUBCONTRACT_VO_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicySubcontractMgmtSubcontractVoNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACT_MGMT_SUBCONTRACT_VO_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicySubcontractorAccountAdvancePaymentNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACTOR_ACCOUNT_ADVANCE_PAYMENT_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicySubcontractorAccountAdvancePaymentNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACTOR_ACCOUNT_ADVANCE_PAYMENT_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicySubcontractorAccountClaimNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACTOR_ACCOUNT_CLAIM_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicySubcontractorAccountClaimNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACTOR_ACCOUNT_CLAIM_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicySubcontractorAccountCreditNoteNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACTOR_ACCOUNT_CREDIT_NOTE_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicySubcontractorAccountCreditNoteNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACTOR_ACCOUNT_CREDIT_NOTE_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicySubcontractorAccountDebitNoteNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACTOR_ACCOUNT_DEBIT_NOTE_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicySubcontractorAccountDebitNoteNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACTOR_ACCOUNT_DEBIT_NOTE_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicySubcontractorAccountPaymentVoucherNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACTOR_ACCOUNT_PAYMENT_VOUCHER_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicySubcontractorAccountPaymentVoucherNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACTOR_ACCOUNT_PAYMENT_VOUCHER_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicySubcontractorAccountOfficialReceiptNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACTOR_ACCOUNT_OFFICIAL_RECEIPT_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicySubcontractorAccountOfficialReceiptNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACTOR_ACCOUNT_OFFICIAL_RECEIPT_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicySubcontractorAccountSubconAdjustmentsNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACTOR_ACCOUNT_SUBCON_ADJUSTMENTS_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicySubcontractorAccountSubconAdjustmentsNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACTOR_ACCOUNT_SUBCON_ADJUSTMENTS_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicySupplierAccountSupplierInvoiceNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUPPLIER_ACCOUNT_SUPPLIER_INVOICE_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicySupplierAccountSupplierInvoiceNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUPPLIER_ACCOUNT_SUPPLIER_INVOICE_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicySupplierAccountCreditNoteNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUPPLIER_ACCOUNT_CREDIT_NOTE_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicySupplierAccountCreditNoteNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUPPLIER_ACCOUNT_CREDIT_NOTE_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicySupplierAccountDebitNoteNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUPPLIER_ACCOUNT_DEBIT_NOTE_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicySupplierAccountDebitNoteNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUPPLIER_ACCOUNT_DEBIT_NOTE_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicySupplierAccountPaymentVoucherNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUPPLIER_ACCOUNT_PAYMENT_VOUCHER_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicySupplierAccountPaymentVoucherNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUPPLIER_ACCOUNT_PAYMENT_VOUCHER_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicyProjectPurchasePrNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_PROJECT_PURCHASE_PR_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicyProjectPurchasePrNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_PROJECT_PURCHASE_PR_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicyProjectPurchasePoNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_PROJECT_PURCHASE_PO_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicyProjectPurchasePoNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_PROJECT_PURCHASE_PO_NOTIFICATION_POLICY_______UPDATE',
  DefectMgmtIcon = 'DEFECT_MGMT__________ICON',
  DefectMgmtView = 'DEFECT_MGMT__________VIEW',
  DefectMgmtCreate = 'DEFECT_MGMT__________CREATE',
  DefectMgmtUpdate = 'DEFECT_MGMT__________UPDATE',
  DefectMgmtActivityLogCreate = 'DEFECT_MGMT_ACTIVITY_LOG_______CREATE',
  DefectMgmtActivityLogView = 'DEFECT_MGMT_ACTIVITY_LOG_______VIEW',
  DefectMgmtActivityLogUpdate = 'DEFECT_MGMT_ACTIVITY_LOG_______UPDATE',
  DefectMgmtDefectAssignmentView = 'DEFECT_MGMT_DEFECT_ASSIGNMENT_______VIEW',
  DefectMgmtDefectAssignmentCreate = 'DEFECT_MGMT_DEFECT_ASSIGNMENT_______CREATE',
  DefectMgmtGeneralSettingsIcon = 'DEFECT_MGMT_GENERAL_SETTINGS_______ICON',
  DefectMgmtGeneralSettingsSubcontractPackageView = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____VIEW',
  DefectMgmtGeneralSettingsSubcontractPackageCreate = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____CREATE',
  DefectMgmtGeneralSettingsSubcontractPackageUpdate = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____UPDATE',
  DefectMgmtGeneralSettingsSubcontractPackageDelete = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____DELETE',
  DefectMgmtGeneralSettingsSubcontractPackageDownload = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____DOWNLOAD',
  DefectMgmtGeneralSettingsSubcontractPackageUpload = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____UPLOAD',
  DefectMgmtContractSettingsIcon = 'DEFECT_MGMT_CONTRACT_SETTINGS_______ICON',
  SiteAttendanceIcon = 'SITE_ATTENDANCE__________ICON',
  SiteAttendanceView = 'SITE_ATTENDANCE__________VIEW',
  SiteAttendanceWorkerProfileCreate = 'SITE_ATTENDANCE_WORKER_PROFILE_______CREATE',
  SiteAttendanceWorkerProfileView = 'SITE_ATTENDANCE_WORKER_PROFILE_______VIEW',
  SiteAttendanceWorkerProfileUpdate = 'SITE_ATTENDANCE_WORKER_PROFILE_______UPDATE',
  SiteAttendanceWorkerProfileDelete = 'SITE_ATTENDANCE_WORKER_PROFILE_______DELETE',
  SiteAttendanceWorkerProfileDownload = 'SITE_ATTENDANCE_WORKER_PROFILE_______DOWNLOAD',
  SiteAttendanceWorkerProfileUpload = 'SITE_ATTENDANCE_WORKER_PROFILE_______UPLOAD',
  SiteAttendanceWorkerAttendanceCreate = 'SITE_ATTENDANCE_WORKER_ATTENDANCE_______CREATE',
  SiteAttendanceWorkerAttendanceView = 'SITE_ATTENDANCE_WORKER_ATTENDANCE_______VIEW',
  SiteAttendanceWorkerAttendanceExport = 'SITE_ATTENDANCE_WORKER_ATTENDANCE_______EXPORT',
  SiteAttendanceGeneralSettingsIcon = 'SITE_ATTENDANCE_GENERAL_SETTINGS_______ICON',
  SiteAttendanceGeneralSettingsPublicHolidayCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____CREATE',
  SiteAttendanceGeneralSettingsPublicHolidayView = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____VIEW',
  SiteAttendanceGeneralSettingsPublicHolidayUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____UPDATE',
  SiteAttendanceGeneralSettingsPublicHolidayDelete = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____DELETE',
  SiteAttendanceGeneralSettingsPublicHolidayCancel = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____CANCEL',
  SiteAttendanceGeneralSettingsPublicHolidayActive = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____ACTIVE',
  SiteAttendanceGeneralSettingsCalendarPolicyCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_CALENDAR_POLICY____CREATE',
  SiteAttendanceGeneralSettingsCalendarPolicyView = 'SITE_ATTENDANCE_GENERAL_SETTINGS_CALENDAR_POLICY____VIEW',
  SiteAttendanceGeneralSettingsCalendarPolicyUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_CALENDAR_POLICY____UPDATE',
  SiteAttendanceGeneralSettingsWorkingHoursPolicyCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_WORKING_HOURS_POLICY____CREATE',
  SiteAttendanceGeneralSettingsWorkingHoursPolicyUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_WORKING_HOURS_POLICY____UPDATE',
  SiteAttendanceGeneralSettingsJobTypeCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____CREATE',
  SiteAttendanceGeneralSettingsJobTypeView = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____VIEW',
  SiteAttendanceGeneralSettingsJobTypeUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____UPDATE',
  SiteAttendanceGeneralSettingsJobTypeDelete = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____DELETE',
  SiteAttendanceGeneralSettingsJobTypeCancel = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____CANCEL',
  SiteAttendanceGeneralSettingsJobTypeActive = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____ACTIVE',
  SiteAttendanceContractSettingsIcon = 'SITE_ATTENDANCE_CONTRACT_SETTINGS_______ICON',
  SiteWagesIcon = 'SITE_WAGES__________ICON',
  SiteWagesView = 'SITE_WAGES__________VIEW',
  SiteWagesDailyRegisteredWorkHoursCreate = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______CREATE',
  SiteWagesDailyRegisteredWorkHoursView = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______VIEW',
  SiteWagesDailyRegisteredWorkHoursUpdate = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______UPDATE',
  SiteWagesDailyRegisteredWorkHoursDelete = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______DELETE',
  SiteWagesDailyWagesVerificationCreate = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______CREATE',
  SiteWagesDailyWagesVerificationView = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______VIEW',
  SiteWagesDailyWagesVerificationUpdate = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______UPDATE',
  SiteWagesDailyWagesVerificationDelete = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______DELETE',
  SiteWagesWagesBookView = 'SITE_WAGES_WAGES_BOOK_______VIEW',
  SiteWagesWagesBookCreate = 'SITE_WAGES_WAGES_BOOK_______CREATE',
  SiteWagesWagesBookUpdate = 'SITE_WAGES_WAGES_BOOK_______UPDATE',
  SiteWagesWagesBookDelete = 'SITE_WAGES_WAGES_BOOK_______DELETE',
  SiteWagesWageCycleClosureCreate = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______CREATE',
  SiteWagesWageCycleClosureView = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______VIEW',
  SiteWagesWageCycleClosureUpdate = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______UPDATE',
  SiteWagesWageCycleClosureDelete = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______DELETE',
  SiteWagesGeneralSettingsIcon = 'SITE_WAGES_GENERAL_SETTINGS_______ICON',
  SiteWagesGeneralSettingsPublicHolidayCreate = 'SITE_WAGES_GENERAL_SETTINGS_PUBLIC_HOLIDAY____CREATE',
  SiteWagesGeneralSettingsPublicHolidayView = 'SITE_WAGES_GENERAL_SETTINGS_PUBLIC_HOLIDAY____VIEW',
  SiteWagesGeneralSettingsPublicHolidayUpdate = 'SITE_WAGES_GENERAL_SETTINGS_PUBLIC_HOLIDAY____UPDATE',
  SiteWagesGeneralSettingsPublicHolidayDelete = 'SITE_WAGES_GENERAL_SETTINGS_PUBLIC_HOLIDAY____DELETE',
  SiteWagesGeneralSettingsPublicHolidayCancel = 'SITE_WAGES_GENERAL_SETTINGS_PUBLIC_HOLIDAY____CANCEL',
  SiteWagesGeneralSettingsPublicHolidayActive = 'SITE_WAGES_GENERAL_SETTINGS_PUBLIC_HOLIDAY____ACTIVE',
  SiteWagesGeneralSettingsCalendarPolicyCreate = 'SITE_WAGES_GENERAL_SETTINGS_CALENDAR_POLICY____CREATE',
  SiteWagesGeneralSettingsCalendarPolicyView = 'SITE_WAGES_GENERAL_SETTINGS_CALENDAR_POLICY____VIEW',
  SiteWagesGeneralSettingsCalendarPolicyUpdate = 'SITE_WAGES_GENERAL_SETTINGS_CALENDAR_POLICY____UPDATE',
  SiteWagesGeneralSettingsWorkingHoursPolicyCreate = 'SITE_WAGES_GENERAL_SETTINGS_WORKING_HOURS_POLICY____CREATE',
  SiteWagesGeneralSettingsWorkingHoursPolicyUpdate = 'SITE_WAGES_GENERAL_SETTINGS_WORKING_HOURS_POLICY____UPDATE',
  SiteWagesGeneralSettingsWagesRatePolicyCreate = 'SITE_WAGES_GENERAL_SETTINGS_WAGES_RATE_POLICY____CREATE',
  SiteWagesGeneralSettingsWagesRatePolicyView = 'SITE_WAGES_GENERAL_SETTINGS_WAGES_RATE_POLICY____VIEW',
  SiteWagesGeneralSettingsWagesRatePolicyUpdate = 'SITE_WAGES_GENERAL_SETTINGS_WAGES_RATE_POLICY____UPDATE',
  SiteWagesContractSettingsIcon = 'SITE_WAGES_CONTRACT_SETTINGS_______ICON',
  SiteInventoryIcon = 'SITE_INVENTORY__________ICON',
  SiteInventoryView = 'SITE_INVENTORY__________VIEW',
  SiteInventoryGoodReceiveNoteCreate = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______CREATE',
  SiteInventoryGoodReceiveNoteView = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______VIEW',
  SiteInventoryGoodReceiveNoteUpdate = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______UPDATE',
  SiteInventoryGoodReceiveNoteApproveReject = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______APPROVE_REJECT',
  SiteInventoryGoodReceiveNoteCancel = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______CANCEL',
  SiteInventoryGoodReceiveNoteDelete = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______DELETE',
  SiteInventoryStockIssueCreate = 'SITE_INVENTORY_STOCK_ISSUE_______CREATE',
  SiteInventoryStockIssueView = 'SITE_INVENTORY_STOCK_ISSUE_______VIEW',
  SiteInventoryStockIssueUpdate = 'SITE_INVENTORY_STOCK_ISSUE_______UPDATE',
  SiteInventoryStockIssueDelete = 'SITE_INVENTORY_STOCK_ISSUE_______DELETE',
  SiteInventoryStockIssueDownload = 'SITE_INVENTORY_STOCK_ISSUE_______DOWNLOAD',
  SiteInventoryStockTransferInCreate = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______CREATE',
  SiteInventoryStockTransferInView = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______VIEW',
  SiteInventoryStockTransferInUpdate = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______UPDATE',
  SiteInventoryStockTransferInDelete = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______DELETE',
  SiteInventoryStockTransferInDownload = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______DOWNLOAD',
  SiteInventoryStockTransferOutCreate = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______CREATE',
  SiteInventoryStockTransferOutView = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______VIEW',
  SiteInventoryStockTransferOutUpdate = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______UPDATE',
  SiteInventoryStockTransferOutDelete = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______DELETE',
  SiteInventoryStockTransferOutDownload = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______DOWNLOAD',
  SiteInventoryStockAdjustmentCreate = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______CREATE',
  SiteInventoryStockAdjustmentView = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______VIEW',
  SiteInventoryStockAdjustmentUpdate = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______UPDATE',
  SiteInventoryStockAdjustmentDelete = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______DELETE',
  SiteInventoryStockAdjustmentDownload = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______DOWNLOAD',
  SiteInventoryStockMovementCreate = 'SITE_INVENTORY_STOCK_MOVEMENT_______CREATE',
  SiteInventoryStockMovementView = 'SITE_INVENTORY_STOCK_MOVEMENT_______VIEW',
  SiteInventoryStockMovementUpdate = 'SITE_INVENTORY_STOCK_MOVEMENT_______UPDATE',
  SiteInventoryStockMovementDelete = 'SITE_INVENTORY_STOCK_MOVEMENT_______DELETE',
  SiteInventoryStockUsageAnalysisCreate = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______CREATE',
  SiteInventoryStockUsageAnalysisView = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______VIEW',
  SiteInventoryStockUsageAnalysisUpdate = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______UPDATE',
  SiteInventoryStockUsageAnalysisDelete = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______DELETE',
  SiteInventoryStockUsageAnalysisExport = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______EXPORT',
  SiteInventoryGeneralSettingsIcon = 'SITE_INVENTORY_GENERAL_SETTINGS_______ICON',
  SiteInventoryGeneralSettingsSiteMaterialCreate = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____CREATE',
  SiteInventoryGeneralSettingsSiteMaterialView = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____VIEW',
  SiteInventoryGeneralSettingsSiteMaterialUpdate = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____UPDATE',
  SiteInventoryGeneralSettingsSiteMaterialDelete = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____DELETE',
  SiteInventoryGeneralSettingsSiteMaterialCancel = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____CANCEL',
  SiteInventoryGeneralSettingsSiteMaterialActive = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____ACTIVE',
  SiteInventoryGeneralSettingsSiteMaterialDownload = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____DOWNLOAD',
  SiteInventoryGeneralSettingsSiteMaterialUpload = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____UPLOAD',
  SiteInventoryContractSettingsIcon = 'SITE_INVENTORY_CONTRACT_SETTINGS_______ICON',
  SiteSurveillanceIcon = 'SITE_SURVEILLANCE__________ICON',
  SiteSurveillanceView = 'SITE_SURVEILLANCE__________VIEW',
  SiteSurveillanceSiteImagesCreate = 'SITE_SURVEILLANCE_SITE_IMAGES_______CREATE',
  SiteSurveillanceSiteImagesView = 'SITE_SURVEILLANCE_SITE_IMAGES_______VIEW',
  SiteSurveillanceSiteImagesUpdate = 'SITE_SURVEILLANCE_SITE_IMAGES_______UPDATE',
  SiteSurveillanceSiteImagesDelete = 'SITE_SURVEILLANCE_SITE_IMAGES_______DELETE',
  SiteSurveillanceLiveVideocamView = 'SITE_SURVEILLANCE_LIVE_VIDEOCAM_______VIEW',
  SiteSurveillanceGeneralSettingsIcon = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_______ICON',
  SiteSurveillanceGeneralSettingsVideoCamSetupCreate = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____CREATE',
  SiteSurveillanceGeneralSettingsVideoCamSetupView = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____VIEW',
  SiteSurveillanceGeneralSettingsVideoCamSetupUpdate = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____UPDATE',
  SiteSurveillanceGeneralSettingsVideoCamSetupDelete = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____DELETE',
  SiteSurveillanceContractSettingsIcon = 'SITE_SURVEILLANCE_CONTRACT_SETTINGS_______ICON',
  BusinessInsightIcon = 'BUSINESS_INSIGHT__________ICON',
  BusinessInsightView = 'BUSINESS_INSIGHT__________VIEW',
  BusinessInsightDefectStatusAnalysisView = 'BUSINESS_INSIGHT_DEFECT_STATUS_ANALYSIS__________VIEW',
  BusinessInsightDefectStatusAnalysisExport = 'BUSINESS_INSIGHT_DEFECT_STATUS_ANALYSIS__________EXPORT',
  BusinessInsightDefectTradeAnalysisView = 'BUSINESS_INSIGHT_DEFECT_TRADE_ANALYSIS__________VIEW',
  BusinessInsightDefectTradeAnalysisExport = 'BUSINESS_INSIGHT_DEFECT_TRADE_ANALYSIS__________EXPORT',
  BusinessInsightDefectSubconAnalysisView = 'BUSINESS_INSIGHT_DEFECT_SUBCON_ANALYSIS__________VIEW',
  BusinessInsightDefectSubconAnalysisExport = 'BUSINESS_INSIGHT_DEFECT_SUBCON_ANALYSIS__________EXPORT',
  BusinessInsightDefectsLodgedOverTimeView = 'BUSINESS_INSIGHT_DEFECTS_LODGED_OVER_TIME__________VIEW',
  BusinessInsightDefectsLodgedOverTimeExport = 'BUSINESS_INSIGHT_DEFECTS_LODGED_OVER_TIME__________EXPORT',
  BusinessInsightGeneralSettingsIcon = 'BUSINESS_INSIGHT_GENERAL_SETTINGS__________ICON',
  BusinessInsightContractSettingsIcon = 'BUSINESS_INSIGHT_CONTRACT_SETTINGS__________ICON',
  DigitalDocumentIcon = 'DIGITAL_DOCUMENT__________ICON',
  DigitalDocumentView = 'DIGITAL_DOCUMENT__________VIEW',
  DigitalDocumentDelete = 'DIGITAL_DOCUMENT__________DELETE',
  DigitalDocumentGeneralSettingsIcon = 'DIGITAL_DOCUMENT_GENERAL_SETTINGS__________ICON',
  DigitalDocumentContractSettingsIcon = 'DIGITAL_DOCUMENT_CONTRACT_SETTINGS__________ICON'
}

export enum SampleEnum {
  Demo = 'DEMO',
  Testing = 'TESTING'
}

export enum UserSelection {
  All = 'ALL',
  Superuser = 'SUPERUSER',
  Nonsuper = 'NONSUPER'
}

export type Mutation = {
  __typename?: 'Mutation';
  CreateContractRole: Scalars['Boolean'];
  UpdateContractRole: Scalars['Boolean'];
  CreateSiteRole: Scalars['Boolean'];
  UpdateSiteRole: Scalars['Boolean'];
  DeleteRolePermission: Scalars['Boolean'];
  CreateEntityRoleUser: Scalars['Boolean'];
  CreateListEntityRoleUser: Scalars['Boolean'];
  CreateRolePermission: Scalars['Boolean'];
  UpdateRolePermission: Scalars['Boolean'];
  cancelApprovalPolicy: Scalars['Boolean'];
  cancelApprovalPolicyAssignment: Scalars['Boolean'];
  createApprovalPolicy: ApprovalPolicyEntity;
  updateApprovalPolicy: ApprovalPolicyEntity;
  createApprovalPolicyAssignment: ApprovalPolicyEntity;
  updateApprovalPolicyAssignment: ApprovalPolicyAssignmentEntity;
  deleteApprovalPolicy: Scalars['Boolean'];
  deleteApprovalPolicyAssignment: Scalars['Boolean'];
  deleteMultipleApprovalPolicy: Scalars['Boolean'];
  deleteMultipleApprovalPolicyAssignment: Scalars['Boolean'];
  deleteExistPDF: Scalars['Boolean'];
  uploadPDFPO: Scalars['Boolean'];
  uploadPDFDocument: Scalars['Boolean'];
  shareAttachment?: Maybe<ShareAttachmentEntity>;
  updateContact: ContactEntity;
  updateNumberFormat: ConNumberFormatEntity;
  createDocType: ConDocTypeEntity;
  updateDocType: ConDocTypeEntity;
  createRunningNumberSetup: ConRunningNumberSetupEntity;
  updateRunningNumberSetup: ConRunningNumberSetupEntity;
  editNumberFormat: Scalars['Boolean'];
  updateDocNumSetup: Scalars['Boolean'];
  patchNewDocNum: Scalars['Boolean'];
  createDocNumRunningNumber: Scalars['Boolean'];
  generateDocumentNumberSetup: Scalars['Boolean'];
  resetGeneralRunningNumber: Scalars['Boolean'];
  resetContractRunningNumber: Scalars['Boolean'];
  contractRunNumChecker: Scalars['Boolean'];
  createContractRunNum: Scalars['Boolean'];
  deleteDocRunningNumber: Scalars['Boolean'];
  createDocumentFolder: Scalars['Boolean'];
  implementBaseUOM: Scalars['Boolean'];
  extraDBSetup: Scalars['Boolean'];
  extraGLMasterSetup: Scalars['Boolean'];
  extraGLTransactionSetup: Scalars['Boolean'];
  extraGenSettingSetup: Scalars['Boolean'];
  extraSystemAdminSetup: Scalars['Boolean'];
  platformContactSetup: Scalars['Boolean'];
  initialDatabaseSetup: Scalars['Boolean'];
  initialMasterFileSetup: Scalars['Boolean'];
  deleteMasterFile: Scalars['Boolean'];
  initialSiteMasterFileSetup: Scalars['Boolean'];
  deleteSiteMasterFile: Scalars['Boolean'];
  initialGeneralSettingSetup: Scalars['Boolean'];
  deleteGenSetting: Scalars['Boolean'];
  initialGLInterfaceSetup: Scalars['Boolean'];
  generateGLMasterFileSetup: Scalars['Boolean'];
  deleteGLMasterFileSetup: Scalars['Boolean'];
  generateGLInitMasterSetup: Scalars['Boolean'];
  deleteGLInitMasterSetup: Scalars['Boolean'];
  updateSAccount: SAccountEntity;
  resetRolePermission: Scalars['Boolean'];
  removeSuperUserACL: Scalars['Boolean'];
  patchRetentionClaimData: Scalars['Boolean'];
  patchNullTrxDateData: Scalars['Boolean'];
  removeAllocData: Scalars['Boolean'];
  accountSubscriptionInfo: Scalars['Boolean'];
  patchNullPOItemData: Scalars['Boolean'];
  patchNullPOSiteData: Scalars['Boolean'];
  createAdjustmentType: AdjustmentTypeEntity;
  updateAdjustmentType: AdjustmentTypeEntity;
  deleteAdjustmentType: Scalars['Boolean'];
  createPlatformSuperUser: PlatformUserEntity;
  createPlatformUser: PlatformUserEntity;
  updatePlatformUser: Scalars['Boolean'];
  createPlatformPassword: Scalars['Boolean'];
  createPlatformPasswordTest: Scalars['Boolean'];
  activatePlatformUser: Scalars['Boolean'];
  platformLogin: PlatformLoginResponse;
  changePlatformPassword: Scalars['Boolean'];
  platformLogout: Scalars['Boolean'];
  resetPlatformPassword: Scalars['Boolean'];
  uploadProfilePicPlatform: DocumentsEntity;
  forgotPlatformPassword: Scalars['Boolean'];
  createPolicyGuideline: Scalars['Boolean'];
  updatePolicyGuideline: Scalars['Boolean'];
  createPolicyAmountGuideline: Scalars['Boolean'];
  updatePolicyAmountGuideline: Scalars['Boolean'];
  deletePolicyAmountGuideline: Scalars['Boolean'];
  createNotificationGuideline: Scalars['Boolean'];
  updateNotificationGuideline: Scalars['Boolean'];
  createProfitGeneration: ProfitRecogGenerationEntity;
  updateProfitGeneration: ProfitRecogGenerationEntity;
  createSuperUser: UserEntity;
  createUser: UserEntity;
  resendInvitation: UserEntity;
  updateUser: UserEntity;
  createPassword: Scalars['Boolean'];
  createPasswordTest: Scalars['Boolean'];
  activateUser: Scalars['Boolean'];
  unblockUser: Scalars['Boolean'];
  changePasswordSU: Scalars['Boolean'];
  login: LoginResponse;
  changePassword: Scalars['Boolean'];
  logout: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  totpSecret: Scalars['String'];
  totpValidate: Scalars['Boolean'];
  uploadProfilePic: DocumentsEntity;
  forgotPassword: Scalars['Boolean'];
  createContractNotification: ContractNotificationEntity;
  updateContractNotification: ContractNotificationEntity;
  deleteContractNotification: Scalars['Boolean'];
  createContractNews: ContractNewsEntity;
  updateContractNews: ContractNewsEntity;
  deleteContractNews: Scalars['Boolean'];
  createContractNewsRead: ContractNewsReadEntity;
  updateContractNewsRead: ContractNewsReadEntity;
  deleteContractNewsRead: Scalars['Boolean'];
  readNotification: Scalars['Boolean'];
  patchWorkDeskData: Scalars['Boolean'];
  createContractToDo: ContractToDoEntity;
  updateContractToDo: ContractToDoEntity;
  deleteContractToDo: Scalars['Boolean'];
  createNotification: Scalars['Boolean'];
  createToDo: Scalars['Boolean'];
  approveToDo: Scalars['Boolean'];
  rejectToDo: Scalars['Boolean'];
  approveRejectWorkDesk: Scalars['Boolean'];
  createNotificationWorkflow: Scalars['JSON'];
  completionPerc: Scalars['Boolean'];
  updateContract: Scalars['Boolean'];
  updateCompletionPerc: Scalars['Boolean'];
  createDocReminder: DocReminderEntity;
  updateDocReminder: DocReminderEntity;
  createBillInterest: BillInterestEntity;
  updateBillInterest: BillInterestEntity;
  createPaymentReminder: PaymentReminderEntity;
  updatePaymentReminder: PaymentReminderEntity;
  createCustomerReminder: Scalars['Boolean'];
  createContract: ContractEntity;
  addContractContact: Scalars['Boolean'];
  actionOnContractStatus: Scalars['Boolean'];
  createContractExtensionDate: Scalars['Boolean'];
  createContractCertificate: Scalars['Boolean'];
  addDigitalDocuments: Scalars['Boolean'];
  deleteDigitalDocuments: Scalars['Boolean'];
  createContractGuarantee: ContractGuaranteeEntity;
  updateContractGuarantee: ContractGuaranteeEntity;
  createContractInsurance: ContractInsuranceEntity;
  updateContractInsurance: ContractInsuranceEntity;
  cancelContractGuarantee: Scalars['Boolean'];
  cancelContractInsurance: Scalars['Boolean'];
  updateProjectBudget: ProjectBudgetEntity;
  deleteProjectBudget: Scalars['Boolean'];
  addOriginalBudget: Scalars['Boolean'];
  addRevisedBudget: Scalars['Boolean'];
  createContractVO: ContractVoEntity;
  updateContractVO: ContractVoEntity;
  deleteContractVO: Scalars['Boolean'];
  actionOnVOStatus: Scalars['Boolean'];
  createContractClaim: ContractClaimEntity;
  updateContractClaim: ContractClaimEntity;
  deleteContractClaim: Scalars['Boolean'];
  submitRetentionClaim: ContractClaimEntity;
  approveRetentionClaim: ContractClaimEntity;
  submitClaim: ContractClaimEntity;
  approveClaim: Scalars['JSON'];
  rejectClaim: Scalars['Boolean'];
  cancelClaim: Scalars['Boolean'];
  createContractAdvance: ContractAdvanceEntity;
  updateContractAdvance: ContractAdvanceEntity;
  deleteContractAdvance: Scalars['Boolean'];
  createContractReceipt: ContractReceiptEntity;
  updateContractReceipt: ContractReceiptEntity;
  deleteContractReceipt: Scalars['Boolean'];
  createContractRefund: ContractRefundEntity;
  updateContractRefund: ContractRefundEntity;
  deleteContractRefund: Scalars['Boolean'];
  makeContractRefund: ContractRefundEntity;
  cancelContractAdvance: Scalars['Boolean'];
  cancelContractReceipt: Scalars['Boolean'];
  cancelContractRefund: Scalars['Boolean'];
  createContractMiscInvoice: ContractMiscInvoiceEntity;
  updateContractMiscInvoice: ContractMiscInvoiceEntity;
  deleteContractMiscInvoice: Scalars['Boolean'];
  createContractDN: ContractDnEntity;
  updateContractDN: ContractDnEntity;
  deleteContractDN: Scalars['Boolean'];
  createContractCN: ContractCnEntity;
  updateContractCN: ContractCnEntity;
  deleteContractCN: Scalars['Boolean'];
  createClientDN: ClientDnEntity;
  updateClientDN: ClientDnEntity;
  deleteClientDN: Scalars['Boolean'];
  createClientCN: ClientCnEntity;
  updateClientCN: ClientCnEntity;
  deleteClientCN: Scalars['Boolean'];
  makeContractAllocation: Scalars['Boolean'];
  resetContractAllocation: Scalars['Boolean'];
  cancelContractMiscInvoice: Scalars['Boolean'];
  cancelContractDN: Scalars['Boolean'];
  cancelContractCN: Scalars['Boolean'];
  cancelClientDN: Scalars['Boolean'];
  cancelClientCN: Scalars['Boolean'];
  createCustomer: CustomerEntity;
  updateCustomer: CustomerEntity;
  createPlatformCustomer: CustomerEntity;
  updatePlatformCustomer: CustomerEntity;
  deleteCustomer: Scalars['Boolean'];
  addCustomerContact: Scalars['Boolean'];
  deletePlatformCustomerProfile: Scalars['Boolean'];
  createFollowUp: FollowUpEntity;
  updateFollowUp: FollowUpEntity;
  createBankAccount: BankAccountEntity;
  updateBankAccount: BankAccountEntity;
  deleteBankAccount: Scalars['Boolean'];
  activateBankAccount: Scalars['Boolean'];
  cancelBankAccount: Scalars['Boolean'];
  uploadCompanyLogo: Scalars['Boolean'];
  uploadISOLogo: Scalars['Boolean'];
  createGLInterfaceMaster: GlInterfaceMasterEntity;
  updateGLInterfaceMaster: GlInterfaceMasterEntity;
  deleteGLInterfaceMaster: Scalars['Boolean'];
  createGLInterfaceDetail: GlInterfaceDetailEntity;
  updateGLInterfaceDetail: GlInterfaceDetailEntity;
  deleteGLInterfaceDetail: Scalars['Boolean'];
  createGLTaxDynamic: GlTaxDynamicEntity;
  updateGLTaxDynamic: GlTaxDynamicEntity;
  deleteGLTaxDynamic: Scalars['Boolean'];
  createGLAdjustmentDynamic: GlAdjustmentDynamicEntity;
  updateGLAdjustmentDynamic: GlAdjustmentDynamicEntity;
  deleteGLAdjustmentDynamic: Scalars['Boolean'];
  cancelGLDynamicSetup: Scalars['Boolean'];
  saveGLAccountCode: Scalars['Boolean'];
  createGLInterfaceData: Scalars['Boolean'];
  deleteCBExport: Scalars['Boolean'];
  exportCBLedger: Scalars['Boolean'];
  deleteGLExport: Scalars['Boolean'];
  exportGLLedger: Scalars['Boolean'];
  createGuaranteeType: GuaranteeTypeEntity;
  updateGuaranteeType: GuaranteeTypeEntity;
  deleteGuaranteeType: Scalars['Boolean'];
  createInsuranceType: InsuranceTypeEntity;
  updateInsuranceType: InsuranceTypeEntity;
  cancelGuaranteeType: Scalars['Boolean'];
  activateGuaranteeType: Scalars['Boolean'];
  cancelInsuranceType: Scalars['Boolean'];
  activateInsuranceType: Scalars['Boolean'];
  deleteInsuranceType: Scalars['Boolean'];
  updateMiscExpense: Scalars['Boolean'];
  createMiscExpense: Scalars['Boolean'];
  cancelMiscExpense: Scalars['Boolean'];
  createCostCenter: CostCenterEntity;
  updateCostCenter: CostCenterEntity;
  deleteCostCenter: Scalars['Boolean'];
  cancelCostCenter: Scalars['Boolean'];
  createCurrencyExchange: CurrencyExchangeEntity;
  updateCurrencyExchange: Scalars['Boolean'];
  deleteCurrencyExchange: Scalars['Boolean'];
  createConTaxType: ConTaxTypeEntity;
  updateConTaxType: ConTaxTypeEntity;
  deleteConTaxType: Scalars['Boolean'];
  activateConTaxType: Scalars['Boolean'];
  createConTaxScheme: ConTaxSchemeEntity;
  updateConTaxScheme: ConTaxSchemeEntity;
  deleteConTaxScheme: Scalars['Boolean'];
  activateConTaxScheme: Scalars['Boolean'];
  createConTaxEffectiveDate: ConTaxEffectiveDateEntity;
  updateConTaxEffectiveDate: ConTaxEffectiveDateEntity;
  deleteConTaxEffectiveDate: Scalars['Boolean'];
  activateConTaxEffectiveDate: Scalars['Boolean'];
  insertConTax: ConTaxSchemeEntity;
  updateConTax: Scalars['Boolean'];
  deleteTaxScheme: Scalars['Boolean'];
  createDocNumHeader: DocNumHeaderEntity;
  updateDocNumHeader: DocNumHeaderEntity;
  createDocNumDetail: DocNumDetailEntity;
  updateDocNumDetail: DocNumDetailEntity;
  createDocumentNumberHeader: Scalars['Boolean'];
  updateDocumentNumberHeader: Scalars['Boolean'];
  createProfitRecogFormula: ProfitRecogFormulaEntity;
  updateProfitRecogFormula: ProfitRecogFormulaEntity;
  deleteProfitRecogFormula: Scalars['Boolean'];
  createProfitRecogParameter: ProfitRecogParameterEntity;
  updateProfitRecogParameter: ProfitRecogParameterEntity;
  createProfitRecogAssignment: ProfitRecogAssignmentEntity;
  updateProfitRecogAssignment: ProfitRecogAssignmentEntity;
  createProfitRecogGeneration: ProfitRecogGenerationEntity;
  updateProfitRecogGeneration: ProfitRecogGenerationEntity;
  createProfitRecogGenParamAssignment: ProfitRecogGenParamAssgmtEntity;
  updateProfitRecogGenParamAssignment: ProfitRecogGenParamAssgmtEntity;
  updateProfitRecogFormulaAssignment: Scalars['Boolean'];
  createProfitRecogGLAccountCode: ProfitRecogGlAccountCodeEntity;
  updateProfitRecogGLAccountCode: ProfitRecogGlAccountCodeEntity;
  createProfitRecogGLJournal: ProfitRecogGlJournalEntity;
  updateProfitRecogGLJournal: ProfitRecogGlJournalEntity;
  goodReceiveNoteRPA: Array<GoodReceiveNoteRpaResult>;
  invoiceRPA: Scalars['Boolean'];
  updateSubcontractorInvitation: SubcontractorInvitationEntity;
  deleteSubcontractorInvitation: Scalars['Boolean'];
  inviteSubcontractor: Scalars['Boolean'];
  unInviteSubcontractor: Scalars['Boolean'];
  unInviteSubcontractors: Scalars['Boolean'];
  createSubconRFQSubmission: SubconRfqSubmissionEntity;
  updateSubconRFQSubmission: SubconRfqSubmissionEntity;
  deleteSubconRFQSubmission: Scalars['Boolean'];
  createSubcontractTender: SubcontractTenderEntity;
  updateSubcontractTender: SubcontractTenderEntity;
  deleteSubcontractTender: Scalars['Boolean'];
  tenderToSubconGeneration: Scalars['Boolean'];
  createSubcontractGuarantee: SubcontractGuaranteeEntity;
  updateSubcontractGuarantee: SubcontractGuaranteeEntity;
  createSubcontractInsurance: SubcontractInsuranceEntity;
  updateSubcontractInsurance: SubcontractInsuranceEntity;
  cancelSubcontractGuarantee: Scalars['Boolean'];
  cancelSubcontractInsurance: Scalars['Boolean'];
  createSubcontract: SubcontractEntity;
  updateSubcontract: Scalars['Boolean'];
  addSubcontractContact: Scalars['Boolean'];
  actionOnSubcontractStatus: Scalars['Boolean'];
  createSubcontractExtensionDate: Scalars['Boolean'];
  createSubcontractCertificate: Scalars['Boolean'];
  createSubcontractVO: SubcontractVoEntity;
  updateSubcontractVO: SubcontractVoEntity;
  deleteSubcontractVO: Scalars['Boolean'];
  actionOnSubconVOStatus: Scalars['Boolean'];
  updateSubcontractLedgers: Scalars['Boolean'];
  createSubcontractClaim: SubcontractClaimEntity;
  updateSubcontractClaim: SubcontractClaimEntity;
  deleteSubcontractClaim: Scalars['Boolean'];
  submitSubconRetentionClaim: SubcontractClaimEntity;
  approveSubconRetentionClaim: SubcontractClaimEntity;
  submitSubconClaim: SubcontractClaimEntity;
  approveSubconClaim: Scalars['JSON'];
  rejectSubconClaim: Scalars['Boolean'];
  cancelSubconClaim: Scalars['Boolean'];
  createSubcontractAdvance: SubcontractAdvanceEntity;
  updateSubcontractAdvance: SubcontractAdvanceEntity;
  deleteSubcontractAdvance: Scalars['Boolean'];
  createSubcontractPayment: SubcontractPaymentEntity;
  updateSubcontractPayment: SubcontractPaymentEntity;
  deleteSubcontractPayment: Scalars['Boolean'];
  createSubcontractRefund: SubcontractRefundEntity;
  updateSubcontractRefund: SubcontractRefundEntity;
  deleteSubcontractRefund: Scalars['Boolean'];
  makeSubconPayment: SubcontractPaymentEntity;
  cancelSubconAdvance: Scalars['Boolean'];
  cancelSubconPayment: Scalars['Boolean'];
  cancelSubconRefund: Scalars['Boolean'];
  createSubcontractMiscInvoice: SubcontractMiscInvoiceEntity;
  updateSubcontractMiscInvoice: SubcontractMiscInvoiceEntity;
  deleteSubcontractMiscInvoice: Scalars['Boolean'];
  createSubcontractDN: SubcontractDnEntity;
  updateSubcontractDN: SubcontractDnEntity;
  deleteSubcontractDN: Scalars['Boolean'];
  createSubcontractCN: SubcontractCnEntity;
  updateSubcontractCN: SubcontractCnEntity;
  deleteSubcontractCN: Scalars['Boolean'];
  createDNtoSubcon: DNtoSubconEntity;
  updateDNtoSubcon: DNtoSubconEntity;
  deleteDNtoSubcon: Scalars['Boolean'];
  createCNtoSubcon: CNtoSubconEntity;
  updateCNtoSubcon: CNtoSubconEntity;
  deleteCNtoSubcon: Scalars['Boolean'];
  makeSubconAllocation: Scalars['Boolean'];
  resetSubcontractAllocation: Scalars['Boolean'];
  cancelSubcontractMiscInvoice: Scalars['Boolean'];
  cancelSubconDN: Scalars['Boolean'];
  cancelSubconCN: Scalars['Boolean'];
  cancelDNtoSubcon: Scalars['Boolean'];
  cancelCNtoSubcon: Scalars['Boolean'];
  createSubcontractor: SubcontractorEntity;
  createPlatformSubcontractor: SubcontractorEntity;
  updateSubcontractor: SubcontractorEntity;
  deleteSubcontractor: Scalars['Boolean'];
  updatePlatformSubcontractor: SubcontractorEntity;
  createSubcontractorCCategory: SubcontractorCCategoryEntity;
  updateSubcontractorCCategory: SubcontractorCCategoryEntity;
  addSubcontractorContact: Scalars['Boolean'];
  createSubcontractorAndPackage: SubcontractorEntity;
  createBulkSubcon: Scalars['Boolean'];
  addSubcontractorCCategory: Scalars['Boolean'];
  deleteCostCategory: Scalars['Boolean'];
  cancelCostCategory: Scalars['Boolean'];
  activateCostCategory: Scalars['Boolean'];
  /** ensure to pass the costClass in input */
  createBatchCostCategory: Scalars['Boolean'];
  createCostCategory: CostCategoryEntity;
  updateCostCategory: Scalars['Boolean'];
  deleteCostCategoryCostItem: Scalars['Boolean'];
  createCostItem: CostItemEntity;
  updateCostItem: CostItemEntity;
  deleteCostItem: Scalars['Boolean'];
  activateCostItem: Scalars['Boolean'];
  cancelCostItem: Scalars['Boolean'];
  createPlantMachinery: PlantMachineryEntity;
  updatePlantMachinery: PlantMachineryEntity;
  updateAssignment: AssignmentEntity;
  createWorkshop: WorkshopEntity;
  updateWorkshop: WorkshopEntity;
  createPNMRental: PnmRentalEntity;
  updatePNMRental: PnmRentalEntity;
  createAssignment: Scalars['Boolean'];
  createMaintenance: Scalars['Boolean'];
  updateMaintenance: Scalars['Boolean'];
  returnFromAssignment: Scalars['Boolean'];
  cancelMaintenanceCost: Scalars['Boolean'];
  cancelAssignment: Scalars['Boolean'];
  returnFromMaintenance: Scalars['Boolean'];
  submitDO: Scalars['Boolean'];
  createDO: Scalars['Boolean'];
  updateDO: Scalars['Boolean'];
  deleteDO: Scalars['Boolean'];
  rejectDO: Scalars['Boolean'];
  createUpdateGRTN: Scalars['Boolean'];
  approveGRTN: Scalars['Boolean'];
  rejectGRTN: Scalars['Boolean'];
  deleteGRTN: Scalars['Boolean'];
  createNegotiatedSupply: Scalars['Boolean'];
  updateNegotiatedSupply: Scalars['Boolean'];
  cancelNegotiatedSupply: Scalars['Boolean'];
  deleteNegoSupply: Scalars['Boolean'];
  createPO: Scalars['JSON'];
  actionOnPOStatus: Scalars['JSON'];
  cancelClosePO: Scalars['Boolean'];
  purchaseBudgetApproval: Scalars['Boolean'];
  createPurchaseReq: PurchaseReqEntity;
  updatePurchaseRequest: Scalars['JSON'];
  actionOnPRStatus: Scalars['JSON'];
  cancelClosePR: Scalars['Boolean'];
  submitEvaluationSelection: Scalars['Boolean'];
  createRFQ: Scalars['Boolean'];
  updateRFQ: Scalars['Boolean'];
  rejectRFQ: Scalars['Boolean'];
  cancelCloseRFQ: Scalars['Boolean'];
  updateRFQSupplierInvitation: RfqSupplierInvitationEntity;
  deleteRFQSupplierInvitation: Scalars['Boolean'];
  inviteSupplier: Scalars['Boolean'];
  uninviteSupplier: Scalars['Boolean'];
  submitRFQQuote: Scalars['Boolean'];
  createSupplier: SupplierEntity;
  createPlatformSupplier: SupplierEntity;
  updateSupplier: SupplierEntity;
  updatePlatformSupplier: SupplierEntity;
  createSupplierCCategory: SupplierCCategoryEntity;
  updateSupplierCCategory: SupplierCCategoryEntity;
  deleteSupplierCCategory: Scalars['Boolean'];
  deleteSupplier: Scalars['Boolean'];
  addSupplierContact: Scalars['Boolean'];
  createBulkSupplier: Scalars['Boolean'];
  addCCategoryInSupplier: Scalars['Boolean'];
  addSupplierInCCategory: Scalars['Boolean'];
  createAPInvoice: ApInvoiceEntity;
  updateAPInvoice: ApInvoiceEntity;
  deleteAPInvoice: Scalars['Boolean'];
  createSupplierInvoice: Scalars['Boolean'];
  createSupplierInvoiceV2: Scalars['Boolean'];
  updateSupplierInvoiceV2: Scalars['Boolean'];
  updateSupplierInvoice: Scalars['Boolean'];
  deleteSupplierInvoice: Scalars['Boolean'];
  cancelSupplierInvoice: Scalars['Boolean'];
  createAPAdvance: ApAdvanceEntity;
  updateAPAdvance: ApAdvanceEntity;
  deleteAPAdvance: Scalars['Boolean'];
  createAPPayment: ApPaymentEntity;
  updateAPPayment: ApPaymentEntity;
  deleteAPPayment: Scalars['Boolean'];
  createAPRefund: ApRefundEntity;
  updateAPRefund: ApRefundEntity;
  deleteAPRefund: Scalars['Boolean'];
  makeSupplierPayment: ApPaymentEntity;
  cancelSupplierDeposit: Scalars['Boolean'];
  cancelSupplierPayment: Scalars['Boolean'];
  cancelSupplierRefund: Scalars['Boolean'];
  createAPDebitNote: ApDebitNoteEntity;
  updateAPDebitNote: ApDebitNoteEntity;
  deleteAPDebitNote: Scalars['Boolean'];
  createAPCreditNote: ApCreditNoteEntity;
  updateAPCreditNote: ApCreditNoteEntity;
  deleteAPCreditNote: Scalars['Boolean'];
  createDNtoSupplier: DNtoSupplierEntity;
  updateDNtoSupplier: DNtoSupplierEntity;
  deleteDNtoSupplier: Scalars['Boolean'];
  createCNtoSupplier: CNtoSupplierEntity;
  updateCNtoSupplier: CNtoSupplierEntity;
  deleteCNtoSupplier: Scalars['Boolean'];
  createAPInvoiceWithoutDO: ApInvoiceEntity;
  updateAPInvoiceWithoutDO: ApInvoiceEntity;
  deleteAPInvoiceWithoutDO: Scalars['Boolean'];
  makeSupplierAllocation: Scalars['Boolean'];
  resetSupplierAllocation: Scalars['Boolean'];
  cancelSupplierDN: Scalars['Boolean'];
  cancelSupplierCN: Scalars['Boolean'];
  cancelDNtoSupplier: Scalars['Boolean'];
  cancelCNtoSupplier: Scalars['Boolean'];
  createUOM: UomEntity;
  updateUOM: UomEntity;
  deleteUOM: Scalars['Boolean'];
  cancelUOM: Scalars['Boolean'];
  activateUOM: Scalars['Boolean'];
  createBatchUOM: Scalars['Boolean'];
  createUOMExchange: Scalars['Boolean'];
  updateUOMExchange: Scalars['Boolean'];
  deleteUOMExchange: Scalars['Boolean'];
  createDefect: DefectEntity;
  updateDefect: DefectEntity;
  createDefectAssignment: Scalars['Boolean'];
  createActivityLogs: Scalars['Boolean'];
  createSubcontractorSetup: Scalars['Boolean'];
  updateSubcontractorSetup: Scalars['Boolean'];
  deleteSubcontractorSetup: Scalars['Boolean'];
  createDefectMgmt: DefectManagementEntity;
  updateDefectMgmt: DefectManagementEntity;
  assignDefect: Scalars['Boolean'];
  reOpenDefect: Scalars['Boolean'];
  createActivityLog: Scalars['Boolean'];
  deleteWorker: Scalars['Boolean'];
  cancelWorker: Scalars['Boolean'];
  createWorker: WorkerEntity;
  updateWorker: WorkerEntity;
  createBulkWorker: Scalars['Boolean'];
  updateWorkerAttendance: WorkerAttendanceEntity;
  deleteWorkerAttendance: Scalars['Boolean'];
  cancelWorkerAttendance: Scalars['Boolean'];
  createWorkerAttendance: WorkerAttendanceEntity;
  calculateWorkerWage: Scalars['Boolean'];
  cancelGRN: Scalars['Boolean'];
  createGRNItem: GrnItemEntity;
  updateGRNItem: GrnItemEntity;
  deleteGRNItem: Scalars['Boolean'];
  cancelGRNItem: Scalars['Boolean'];
  createGRN: Scalars['Boolean'];
  updateGRN: Scalars['Boolean'];
  deleteGRN: Scalars['Boolean'];
  createStockMovement: StockMovementEntity;
  updateStockMovement: StockMovementEntity;
  deleteStockMovement: Scalars['Boolean'];
  cancelStockMovement: Scalars['Boolean'];
  createSiteProgress: SiteProgressEntity;
  updateSiteProgress: SiteProgressEntity;
  deleteSiteProgress: Scalars['Boolean'];
  createSiteStockLedger: SiteStockLedgerEntity;
  updateSiteStockLedger: SiteStockLedgerEntity;
  deleteSiteStockLedger: Scalars['Boolean'];
  createSiteWorkerAttendance: WorkerAttendanceEntity;
  updateSiteWorkerAttendance: WorkerAttendanceEntity;
  deleteSiteWorkerAttendance: Scalars['Boolean'];
  createSiteWorkers: WorkerEntity;
  updateSiteWorkers: WorkerEntity;
  deleteSiteWorkers: Scalars['Boolean'];
  deleteCalendar: Scalars['Boolean'];
  cancelCalendar: Scalars['Boolean'];
  activateCalendar: Scalars['Boolean'];
  createCalendar: Scalars['Boolean'];
  updateCalendar: Scalars['Boolean'];
  createCalendarPHPolicy: CalendarPhPolicyEntity;
  updateCalendarPHPolicy: CalendarPhPolicyEntity;
  deleteCalendarPHPolicy: Scalars['Boolean'];
  cancelCalendarPHPolicy: Scalars['Boolean'];
  activateCalendarPHPolicy: Scalars['Boolean'];
  createCalendarWeekendPolicy: CalendarWeekendPolicyEntity;
  updateCalendarWeekendPolicy: CalendarWeekendPolicyEntity;
  deleteCalendarWeekendPolicy: Scalars['Boolean'];
  cancelCalendarWeekendPolicy: Scalars['Boolean'];
  activateCalendarWeekendPolicy: Scalars['Boolean'];
  createHolidayType: HolidayTypeEntity;
  updateHolidayType: HolidayTypeEntity;
  deleteHolidayType: Scalars['Boolean'];
  cancelHolidayType: Scalars['Boolean'];
  activateHolidayType: Scalars['Boolean'];
  createJobType: JobTypeEntity;
  updateJobType: JobTypeEntity;
  deleteJobType: Scalars['Boolean'];
  cancelJobType: Scalars['Boolean'];
  activateJobType: Scalars['Boolean'];
  createPublicHoliday: PublicHolidayEntity;
  updatePublicHoliday: PublicHolidayEntity;
  deletePublicHoliday: Scalars['Boolean'];
  cancelPublicHoliday: Scalars['Boolean'];
  activatePublicHoliday: Scalars['Boolean'];
  createSite: Scalars['Boolean'];
  updateSite: Scalars['Boolean'];
  deleteSite: Scalars['Boolean'];
  cancelSite: Scalars['Boolean'];
  activateSite: Scalars['Boolean'];
  insertUpdateSite: Scalars['Boolean'];
  deleteProjectSite: Scalars['Boolean'];
  createSiteMaterial: SiteMaterialEntity;
  updateSiteMaterial: SiteMaterialEntity;
  deleteSiteMaterial: Scalars['Boolean'];
  cancelSiteMaterial: Scalars['Boolean'];
  activateSiteMaterial: Scalars['Boolean'];
  createBulkSiteMaterial: Scalars['Boolean'];
  createVideoCam: VideoCamEntity;
  updateVideoCam: VideoCamEntity;
  deleteVideoCam: Scalars['Boolean'];
  cancelVideoCam: Scalars['Boolean'];
  activateVideoCam: Scalars['Boolean'];
  createWagesRatePolicy: WagesRatePolicyEntity;
  deleteWagesRatePolicy: Scalars['Boolean'];
  cancelWagesRatePolicy: Scalars['Boolean'];
  activateWagesRatePolicy: Scalars['Boolean'];
  updateWagesRatePolicy: Scalars['Boolean'];
  updateWorkingHourPolicy: WorkingHourPolicyEntity;
  deleteWorkingHourPolicy: Scalars['Boolean'];
  cancelWorkingHourPolicy: Scalars['Boolean'];
  activateWorkingHourPolicy: Scalars['Boolean'];
  createWorkingHourPolicy: WorkingHourPolicyEntity;
  createSiteImage: SiteImageEntity;
  updateSiteImage: SiteImageEntity;
  deleteSiteImage: Scalars['Boolean'];
  cancelSiteImage: Scalars['Boolean'];
  updateWageCycle: WageCycleEntity;
  cancelWageCycle: Scalars['Boolean'];
  deleteWageCycle: Scalars['Boolean'];
  createWageCycle: Scalars['Boolean'];
  updateWorkerVerifiedHour: Scalars['Boolean'];
  createWageCycleDetail: WageCycleDetailEntity;
  updateWageCycleDetail: WageCycleDetailEntity;
  deleteWageCycleDetail: Scalars['Boolean'];
  cancelWageCycleDetail: Scalars['Boolean'];
  createWorkerAdvance: WorkerAdvanceEntity;
  updateWorkerAdvance: WorkerAdvanceEntity;
  deleteWorkerAdvance: Scalars['Boolean'];
  cancelWorkerAdvance: Scalars['Boolean'];
  createWorkerWage: WorkerWageEntity;
  updateWorkerWage: WorkerWageEntity;
  deleteWorkerWage: Scalars['Boolean'];
  cancelWorkerWage: Scalars['Boolean'];
};


export type MutationCreateContractRoleArgs = {
  permissionArr: Array<ContPermission>;
  input: RoleInput;
};


export type MutationUpdateContractRoleArgs = {
  permissionArr: Array<ContPermission>;
  input: RoleInput;
};


export type MutationCreateSiteRoleArgs = {
  permissionArr: Array<SitePermission>;
  input: RoleInput;
};


export type MutationUpdateSiteRoleArgs = {
  permissionArr: Array<SitePermission>;
  input: RoleInput;
};


export type MutationDeleteRolePermissionArgs = {
  roleID: Scalars['String'];
};


export type MutationCreateEntityRoleUserArgs = {
  input: EntityRoleUserAsgInput;
};


export type MutationCreateListEntityRoleUserArgs = {
  roleUserIDs: Array<RoleUserInput>;
  contractID: Scalars['String'];
};


export type MutationCreateRolePermissionArgs = {
  permissionArr: Array<ContractPermission>;
  input: RoleInput;
};


export type MutationUpdateRolePermissionArgs = {
  permissionArr: Array<ContractPermission>;
  input: RoleInput;
};


export type MutationCancelApprovalPolicyArgs = {
  input: ApprovalPolicyDeleteInput;
};


export type MutationCancelApprovalPolicyAssignmentArgs = {
  input: ApprovalPolicyDeleteInput;
};


export type MutationCreateApprovalPolicyArgs = {
  input: ApprovalPolicyInput;
};


export type MutationUpdateApprovalPolicyArgs = {
  input: ApprovalPolicyInput;
};


export type MutationCreateApprovalPolicyAssignmentArgs = {
  input: ApprovalPolicyAssignmentInput;
};


export type MutationUpdateApprovalPolicyAssignmentArgs = {
  input: ApprovalPolicyAssignmentInput;
};


export type MutationDeleteApprovalPolicyArgs = {
  input: ApprovalPolicyDeleteInput;
};


export type MutationDeleteApprovalPolicyAssignmentArgs = {
  input: ApprovalPolicyAssignmentDeleteInput;
};


export type MutationDeleteMultipleApprovalPolicyArgs = {
  input: ApprovalPolicyDeleteInput;
};


export type MutationDeleteMultipleApprovalPolicyAssignmentArgs = {
  input: ApprovalPolicyAssignmentDeleteInput;
};


export type MutationDeleteExistPdfArgs = {
  refID?: Maybe<Scalars['String']>;
};


export type MutationUploadPdfpoArgs = {
  input: LedgerPdfInput;
};


export type MutationUploadPdfDocumentArgs = {
  input: LedgerPdfInput;
};


export type MutationShareAttachmentArgs = {
  file: Scalars['Upload'];
  contractID: Scalars['String'];
};


export type MutationUpdateContactArgs = {
  input: ContactInput;
};


export type MutationUpdateNumberFormatArgs = {
  input: NumberFormatInput;
};


export type MutationCreateDocTypeArgs = {
  input: DocTypeInput;
};


export type MutationUpdateDocTypeArgs = {
  input: DocTypeInput;
};


export type MutationCreateRunningNumberSetupArgs = {
  input: RunningNumberSetupInput;
};


export type MutationUpdateRunningNumberSetupArgs = {
  input: RunningNumberSetupInput;
};


export type MutationEditNumberFormatArgs = {
  input: NumberFormatInput;
};


export type MutationUpdateDocNumSetupArgs = {
  input: DocNumInput;
  ID: Scalars['String'];
};


export type MutationPatchNewDocNumArgs = {
  docCode: ContractDocNum;
};


export type MutationCreateDocNumRunningNumberArgs = {
  docCode: ContractDocNum;
  accountID: Scalars['String'];
};


export type MutationGenerateDocumentNumberSetupArgs = {
  accountID: Scalars['String'];
};


export type MutationResetGeneralRunningNumberArgs = {
  accountID: Scalars['String'];
};


export type MutationResetContractRunningNumberArgs = {
  accountID: Scalars['String'];
};


export type MutationContractRunNumCheckerArgs = {
  contractID: Scalars['String'];
};


export type MutationCreateContractRunNumArgs = {
  consubcon: Scalars['String'];
  contractID: Scalars['String'];
};


export type MutationDeleteDocRunningNumberArgs = {
  accountID: Scalars['String'];
};


export type MutationCreateDocumentFolderArgs = {
  input: DocumentFolderInput;
};


export type MutationExtraGenSettingSetupArgs = {
  accountID: Scalars['String'];
};


export type MutationExtraSystemAdminSetupArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationPlatformContactSetupArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationInitialMasterFileSetupArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteMasterFileArgs = {
  accountID: Scalars['String'];
};


export type MutationInitialSiteMasterFileSetupArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteSiteMasterFileArgs = {
  accountID: Scalars['String'];
};


export type MutationInitialGeneralSettingSetupArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteGenSettingArgs = {
  accountID: Scalars['String'];
};


export type MutationInitialGlInterfaceSetupArgs = {
  accountID: Scalars['String'];
};


export type MutationUpdateSAccountArgs = {
  input: SAccountInput;
};


export type MutationResetRolePermissionArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationRemoveSuperUserAclArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationPatchRetentionClaimDataArgs = {
  tableName: Scalars['String'];
};


export type MutationPatchNullTrxDateDataArgs = {
  tableName: Scalars['String'];
};


export type MutationRemoveAllocDataArgs = {
  confirmDelete: Scalars['Boolean'];
  tableName: Scalars['String'];
};


export type MutationCreateAdjustmentTypeArgs = {
  input: AdjustmentTypeInput;
};


export type MutationUpdateAdjustmentTypeArgs = {
  input: AdjustmentTypeInput;
};


export type MutationDeleteAdjustmentTypeArgs = {
  input: AdjustmentTypeInput;
};


export type MutationCreatePlatformSuperUserArgs = {
  softwareName: Scalars['String'];
  input: UserInput;
};


export type MutationCreatePlatformUserArgs = {
  softwareCode: SoftwareCode;
  input: UserInput;
};


export type MutationUpdatePlatformUserArgs = {
  input: UserInput;
};


export type MutationCreatePlatformPasswordArgs = {
  input: PasswordInfo;
};


export type MutationCreatePlatformPasswordTestArgs = {
  input: PasswordInfo;
};


export type MutationActivatePlatformUserArgs = {
  token: Scalars['String'];
};


export type MutationPlatformLoginArgs = {
  softwareCode: SoftwareCode;
  password: Scalars['String'];
  loginId: Scalars['String'];
};


export type MutationChangePlatformPasswordArgs = {
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};


export type MutationResetPlatformPasswordArgs = {
  accountID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationUploadProfilePicPlatformArgs = {
  file?: Maybe<Scalars['Upload']>;
  userID: Scalars['String'];
};


export type MutationForgotPlatformPasswordArgs = {
  email: Scalars['String'];
};


export type MutationCreatePolicyGuidelineArgs = {
  roleApprovalIDs: Array<PolicyAssignmentInput>;
  input: PolicyGuidelineInput;
};


export type MutationUpdatePolicyGuidelineArgs = {
  input: Array<PolicyAssignmentInput>;
  docRefTable: Scalars['String'];
};


export type MutationCreatePolicyAmountGuidelineArgs = {
  notiRoleUserIDs: Array<NotificationGuidelineInput>;
  roleApprovalIDs: Array<PolicyAssignmentInput>;
  input: PolicyGuidelineInput;
};


export type MutationUpdatePolicyAmountGuidelineArgs = {
  notiRoleUserIDs: Array<NotificationGuidelineInput>;
  roleApprovalIDs: Array<PolicyAssignmentInput>;
  input: PolicyGuidelineInput;
  ID: Scalars['String'];
};


export type MutationDeletePolicyAmountGuidelineArgs = {
  ID: Scalars['String'];
};


export type MutationCreateNotificationGuidelineArgs = {
  input: Array<NotificationGuidelineInput>;
  docRefTable: Scalars['String'];
};


export type MutationUpdateNotificationGuidelineArgs = {
  input: Array<NotificationGuidelineInput>;
  ID: Scalars['String'];
};


export type MutationCreateProfitGenerationArgs = {
  input: ProfitRecogGenerationInput;
};


export type MutationUpdateProfitGenerationArgs = {
  profitRecogStatus: Scalars['String'];
  profitRecogGenerationID: Scalars['String'];
  input: ProfitRecogGenerationInput;
};


export type MutationCreateSuperUserArgs = {
  accountID: Scalars['String'];
  input: UserInput;
};


export type MutationCreateUserArgs = {
  input: UserInput;
};


export type MutationResendInvitationArgs = {
  ID: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  input: UserInput;
};


export type MutationCreatePasswordArgs = {
  input: PasswordInfo;
};


export type MutationCreatePasswordTestArgs = {
  input: PasswordInfo;
};


export type MutationActivateUserArgs = {
  token: Scalars['String'];
};


export type MutationUnblockUserArgs = {
  input: PasswordInfo;
};


export type MutationChangePasswordSuArgs = {
  input: PasswordInfo;
};


export type MutationLoginArgs = {
  password: Scalars['String'];
  loginId: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};


export type MutationLogoutArgs = {
  entityID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type MutationResetPasswordArgs = {
  accountID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationTotpValidateArgs = {
  token: Scalars['String'];
};


export type MutationUploadProfilePicArgs = {
  file?: Maybe<Scalars['Upload']>;
  userID: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationCreateContractNotificationArgs = {
  input: ContractNotificationInput;
};


export type MutationUpdateContractNotificationArgs = {
  input: ContractNotificationInput;
};


export type MutationDeleteContractNotificationArgs = {
  input: ContractNotificationInput;
};


export type MutationCreateContractNewsArgs = {
  input: ContractNewsInput;
};


export type MutationUpdateContractNewsArgs = {
  input: ContractNewsInput;
};


export type MutationDeleteContractNewsArgs = {
  input: ContractNewsInput;
};


export type MutationCreateContractNewsReadArgs = {
  input: ContractNewsReadInput;
};


export type MutationUpdateContractNewsReadArgs = {
  input: ContractNewsReadInput;
};


export type MutationDeleteContractNewsReadArgs = {
  input: ContractNewsReadInput;
};


export type MutationReadNotificationArgs = {
  ID?: Maybe<Scalars['String']>;
};


export type MutationPatchWorkDeskDataArgs = {
  tableName: Scalars['String'];
};


export type MutationCreateContractToDoArgs = {
  input: ContractToDoInput;
};


export type MutationUpdateContractToDoArgs = {
  input: ContractToDoInput;
};


export type MutationDeleteContractToDoArgs = {
  input: ContractToDoInput;
};


export type MutationCreateNotificationArgs = {
  body: Scalars['String'];
  docRefTable: Scalars['String'];
  docRefID: Scalars['String'];
};


export type MutationCreateToDoArgs = {
  docNo: Scalars['String'];
  input: LedgerInput;
  docRefTable: Scalars['String'];
  docRefID: Scalars['String'];
};


export type MutationApproveToDoArgs = {
  IDs: Array<Scalars['String']>;
};


export type MutationRejectToDoArgs = {
  remark: Scalars['String'];
  IDs: Array<Scalars['String']>;
};


export type MutationApproveRejectWorkDeskArgs = {
  workDeskInput: Array<WorkDeskInput>;
};


export type MutationCreateNotificationWorkflowArgs = {
  body?: Maybe<Scalars['String']>;
  workflowID?: Maybe<Scalars['String']>;
  docRefID: Scalars['String'];
  docRefTable: Scalars['String'];
};


export type MutationCompletionPercArgs = {
  input: CompletionInput;
  contractID: Scalars['String'];
};


export type MutationUpdateContractArgs = {
  input: ContractInput;
};


export type MutationUpdateCompletionPercArgs = {
  completionPerc: Scalars['String'];
  contractID: Scalars['String'];
};


export type MutationCreateDocReminderArgs = {
  input: DocReminderInput;
};


export type MutationUpdateDocReminderArgs = {
  input: DocReminderInput;
};


export type MutationCreateBillInterestArgs = {
  input: BillInterestInput;
};


export type MutationUpdateBillInterestArgs = {
  input: BillInterestInput;
};


export type MutationCreatePaymentReminderArgs = {
  input: PaymentReminderInput;
};


export type MutationUpdatePaymentReminderArgs = {
  input: PaymentReminderInput;
};


export type MutationCreateCustomerReminderArgs = {
  inputReminder: CustomerReminderInput;
};


export type MutationCreateContractArgs = {
  siteInput: Array<SiteInput>;
  input: ContractInput;
};


export type MutationAddContractContactArgs = {
  contactInput: Array<ContactPersonInput>;
  ID: Scalars['String'];
};


export type MutationActionOnContractStatusArgs = {
  input: ContractActionInput;
  ID: Scalars['String'];
};


export type MutationCreateContractExtensionDateArgs = {
  input: ContractExtensionInput;
  ID: Scalars['String'];
};


export type MutationCreateContractCertificateArgs = {
  input: ContractCertificateInput;
  ID: Scalars['String'];
};


export type MutationAddDigitalDocumentsArgs = {
  input: DigitalDocumentInput;
};


export type MutationDeleteDigitalDocumentsArgs = {
  ID: Scalars['String'];
};


export type MutationCreateContractGuaranteeArgs = {
  input: ContractGuaranteeInput;
};


export type MutationUpdateContractGuaranteeArgs = {
  input: ContractGuaranteeInput;
};


export type MutationCreateContractInsuranceArgs = {
  input: ContractInsuranceInput;
};


export type MutationUpdateContractInsuranceArgs = {
  input: ContractInsuranceInput;
};


export type MutationCancelContractGuaranteeArgs = {
  input: ContractGuaranteeCancellationInput;
};


export type MutationCancelContractInsuranceArgs = {
  input: ContractInsuranceCancellationInput;
};


export type MutationUpdateProjectBudgetArgs = {
  input: ProjectBudgetInput;
};


export type MutationDeleteProjectBudgetArgs = {
  input: ProjectBudgetDeleteInput;
};


export type MutationAddOriginalBudgetArgs = {
  budgetInput: Array<ProjectBudgetInput>;
  contractID: Scalars['String'];
};


export type MutationAddRevisedBudgetArgs = {
  file?: Maybe<Array<Scalars['Upload']>>;
  budgetAmtInput: BudgetAmtInput;
  contractID: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationCreateContractVoArgs = {
  input: ContractVoInput;
};


export type MutationUpdateContractVoArgs = {
  input: ContractVoInput;
};


export type MutationDeleteContractVoArgs = {
  input: ContractVoDeleteInput;
};


export type MutationActionOnVoStatusArgs = {
  input: ContractVoActionInput;
  ID: Scalars['String'];
};


export type MutationCreateContractClaimArgs = {
  input: ContractClaimInput;
};


export type MutationUpdateContractClaimArgs = {
  input: ContractClaimInput;
};


export type MutationDeleteContractClaimArgs = {
  ID: Scalars['String'];
};


export type MutationSubmitRetentionClaimArgs = {
  input: ClaimRetentionInput;
};


export type MutationApproveRetentionClaimArgs = {
  input: ClaimRetentionInput;
};


export type MutationSubmitClaimArgs = {
  deductionLedger?: Maybe<Array<LedgerInput>>;
  input: ContractClaimInput;
};


export type MutationApproveClaimArgs = {
  input: ContractClaimInput;
};


export type MutationRejectClaimArgs = {
  retentionClaim?: Maybe<Scalars['Boolean']>;
  input: RejectInput;
  ID: Scalars['String'];
};


export type MutationCancelClaimArgs = {
  retentionClaim?: Maybe<Scalars['Boolean']>;
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateContractAdvanceArgs = {
  input: ContractReceiptInput;
};


export type MutationUpdateContractAdvanceArgs = {
  input: ContractReceiptInput;
};


export type MutationDeleteContractAdvanceArgs = {
  ID: Scalars['String'];
};


export type MutationCreateContractReceiptArgs = {
  input: ContractReceiptInput;
};


export type MutationUpdateContractReceiptArgs = {
  input: ContractReceiptInput;
};


export type MutationDeleteContractReceiptArgs = {
  ID: Scalars['String'];
};


export type MutationCreateContractRefundArgs = {
  input: ContractReceiptInput;
};


export type MutationUpdateContractRefundArgs = {
  input: ContractReceiptInput;
};


export type MutationDeleteContractRefundArgs = {
  ID: Scalars['String'];
};


export type MutationMakeContractRefundArgs = {
  ledger: Array<LedgerInput>;
  input: ContractReceiptInput;
};


export type MutationCancelContractAdvanceArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelContractReceiptArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelContractRefundArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateContractMiscInvoiceArgs = {
  input: ContractTransactionInput;
};


export type MutationUpdateContractMiscInvoiceArgs = {
  input: ContractTransactionInput;
};


export type MutationDeleteContractMiscInvoiceArgs = {
  ID: Scalars['String'];
};


export type MutationCreateContractDnArgs = {
  input: ContractTransactionInput;
};


export type MutationUpdateContractDnArgs = {
  input: ContractTransactionInput;
};


export type MutationDeleteContractDnArgs = {
  ID: Scalars['String'];
};


export type MutationCreateContractCnArgs = {
  input: ContractTransactionInput;
};


export type MutationUpdateContractCnArgs = {
  input: ContractTransactionInput;
};


export type MutationDeleteContractCnArgs = {
  ID: Scalars['String'];
};


export type MutationCreateClientDnArgs = {
  input: ContractTransactionInput;
};


export type MutationUpdateClientDnArgs = {
  input: ContractTransactionInput;
};


export type MutationDeleteClientDnArgs = {
  ID: Scalars['String'];
};


export type MutationCreateClientCnArgs = {
  input: ContractTransactionInput;
};


export type MutationUpdateClientCnArgs = {
  input: ContractTransactionInput;
};


export type MutationDeleteClientCnArgs = {
  ID: Scalars['String'];
};


export type MutationMakeContractAllocationArgs = {
  ledger: Array<LedgerInput>;
};


export type MutationResetContractAllocationArgs = {
  receiptPayment?: Maybe<Scalars['Boolean']>;
  creditID?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
};


export type MutationCancelContractMiscInvoiceArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelContractDnArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelContractCnArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelClientDnArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelClientCnArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateCustomerArgs = {
  input: CustomerInput;
};


export type MutationUpdateCustomerArgs = {
  input: CustomerInput;
};


export type MutationCreatePlatformCustomerArgs = {
  input: CustomerInput;
};


export type MutationUpdatePlatformCustomerArgs = {
  input: CustomerInput;
};


export type MutationDeleteCustomerArgs = {
  input: CustomerDeleteInput;
};


export type MutationAddCustomerContactArgs = {
  contactInput: Array<ContactPersonInput>;
  platformAccess: Scalars['Boolean'];
  ID: Scalars['String'];
};


export type MutationDeletePlatformCustomerProfileArgs = {
  ID: Scalars['String'];
};


export type MutationCreateFollowUpArgs = {
  input: FollowUpInput;
};


export type MutationUpdateFollowUpArgs = {
  input: FollowUpInput;
};


export type MutationCreateBankAccountArgs = {
  input: BankAccountInput;
};


export type MutationUpdateBankAccountArgs = {
  input: BankAccountInput;
};


export type MutationDeleteBankAccountArgs = {
  input: BankAccountDeleteInput;
};


export type MutationActivateBankAccountArgs = {
  input: BankAccountDeleteInput;
};


export type MutationCancelBankAccountArgs = {
  input?: Maybe<BankAccountCancellationInput>;
  ID: Scalars['String'];
};


export type MutationUploadCompanyLogoArgs = {
  file?: Maybe<Scalars['Upload']>;
};


export type MutationUploadIsoLogoArgs = {
  file?: Maybe<Scalars['Upload']>;
};


export type MutationCreateGlInterfaceMasterArgs = {
  input: GlInterfaceMasterInput;
};


export type MutationUpdateGlInterfaceMasterArgs = {
  input: GlInterfaceMasterInput;
};


export type MutationDeleteGlInterfaceMasterArgs = {
  input: GlInterfaceSetupDeleteInput;
};


export type MutationCreateGlInterfaceDetailArgs = {
  input: GlInterfaceDetailInput;
};


export type MutationUpdateGlInterfaceDetailArgs = {
  input: GlInterfaceDetailInput;
};


export type MutationDeleteGlInterfaceDetailArgs = {
  input: GlInterfaceSetupDeleteInput;
};


export type MutationCreateGlTaxDynamicArgs = {
  input: GlTaxDynamicInput;
};


export type MutationUpdateGlTaxDynamicArgs = {
  input: GlTaxDynamicInput;
};


export type MutationDeleteGlTaxDynamicArgs = {
  input: GlInterfaceSetupDeleteInput;
};


export type MutationCreateGlAdjustmentDynamicArgs = {
  input: GlAdjustmentDynamicInput;
};


export type MutationUpdateGlAdjustmentDynamicArgs = {
  input: GlAdjustmentDynamicInput;
};


export type MutationDeleteGlAdjustmentDynamicArgs = {
  input: GlInterfaceSetupDeleteInput;
};


export type MutationCancelGlDynamicSetupArgs = {
  input?: Maybe<GlInterfaceDynamicInput>;
  ID: Scalars['String'];
};


export type MutationSaveGlAccountCodeArgs = {
  glInterfaceInput: Array<GlInterfaceDynamicInput>;
  accountType: Scalars['String'];
};


export type MutationCreateGlInterfaceDataArgs = {
  input: Array<GlInterfaceDetailInput>;
};


export type MutationDeleteCbExportArgs = {
  input: CbExportDeleteInput;
};


export type MutationExportCbLedgerArgs = {
  ledgers: Array<CbLedgerInput>;
  input: CbExportInput;
};


export type MutationDeleteGlExportArgs = {
  input: GlExportDeleteInput;
};


export type MutationExportGlLedgerArgs = {
  ledgers: Array<GlLedgerInput>;
  input: GlExportInput;
};


export type MutationCreateGuaranteeTypeArgs = {
  input: GuarInsTypeInput;
};


export type MutationUpdateGuaranteeTypeArgs = {
  input: GuarInsTypeInput;
};


export type MutationDeleteGuaranteeTypeArgs = {
  input: GuarInsTypeDeleteInput;
};


export type MutationCreateInsuranceTypeArgs = {
  input: GuarInsTypeInput;
};


export type MutationUpdateInsuranceTypeArgs = {
  input: GuarInsTypeInput;
};


export type MutationCancelGuaranteeTypeArgs = {
  input: GuarInsTypeInput;
};


export type MutationActivateGuaranteeTypeArgs = {
  input: GuarInsTypeInput;
};


export type MutationCancelInsuranceTypeArgs = {
  input: GuarInsTypeInput;
};


export type MutationActivateInsuranceTypeArgs = {
  input: GuarInsTypeInput;
};


export type MutationDeleteInsuranceTypeArgs = {
  input: GuarInsTypeDeleteInput;
};


export type MutationUpdateMiscExpenseArgs = {
  input: MiscExpenseInput;
};


export type MutationCreateMiscExpenseArgs = {
  input: MiscExpenseInput;
};


export type MutationCancelMiscExpenseArgs = {
  input: MiscExpenseCancellationInput;
  ID: Scalars['String'];
};


export type MutationCreateCostCenterArgs = {
  input: CostCenterInput;
};


export type MutationUpdateCostCenterArgs = {
  input: CostCenterInput;
};


export type MutationDeleteCostCenterArgs = {
  input: CostCenterDeleteInput;
};


export type MutationCancelCostCenterArgs = {
  ID: Scalars['String'];
};


export type MutationCreateCurrencyExchangeArgs = {
  input: CurrencyExchangeInput;
};


export type MutationUpdateCurrencyExchangeArgs = {
  input: CurrencyExchangeInput;
};


export type MutationDeleteCurrencyExchangeArgs = {
  input: CurrencyExchangeInput;
};


export type MutationCreateConTaxTypeArgs = {
  input: ConTaxTypeInput;
};


export type MutationUpdateConTaxTypeArgs = {
  input: ConTaxTypeInput;
};


export type MutationDeleteConTaxTypeArgs = {
  input: ConTaxTypeInput;
};


export type MutationActivateConTaxTypeArgs = {
  input: ConTaxTypeInput;
};


export type MutationCreateConTaxSchemeArgs = {
  input: ConTaxSchemeInput;
};


export type MutationUpdateConTaxSchemeArgs = {
  input: ConTaxSchemeInput;
};


export type MutationDeleteConTaxSchemeArgs = {
  input: ConTaxSchemeInput;
};


export type MutationActivateConTaxSchemeArgs = {
  input: ConTaxSchemeInput;
};


export type MutationCreateConTaxEffectiveDateArgs = {
  input: ConTaxEffectiveDateInput;
};


export type MutationUpdateConTaxEffectiveDateArgs = {
  input: ConTaxEffectiveDateInput;
};


export type MutationDeleteConTaxEffectiveDateArgs = {
  input: ConTaxEffectiveDateInput;
};


export type MutationActivateConTaxEffectiveDateArgs = {
  input: ConTaxEffectiveDateInput;
};


export type MutationInsertConTaxArgs = {
  taxEffectiveInput: Array<TaxEffectiveInput>;
  conTaxSchemeInput: ConTaxSchemeInput;
};


export type MutationUpdateConTaxArgs = {
  taxEffectiveInput: Array<TaxEffectiveInput>;
  conTaxSchemeInput: ConTaxSchemeInput;
};


export type MutationDeleteTaxSchemeArgs = {
  ID: Scalars['String'];
};


export type MutationCreateDocNumHeaderArgs = {
  input: DocNumHeaderInput;
};


export type MutationUpdateDocNumHeaderArgs = {
  input: DocNumHeaderInput;
};


export type MutationCreateDocNumDetailArgs = {
  input: DocNumDetailInput;
};


export type MutationUpdateDocNumDetailArgs = {
  input: DocNumDetailInput;
};


export type MutationCreateDocumentNumberHeaderArgs = {
  DocNumDetailInput: Array<DocNumDetailInput>;
  DocNumHeaderInput: DocNumHeaderInput;
};


export type MutationUpdateDocumentNumberHeaderArgs = {
  DocNumDetailInput: Array<DocNumDetailInput>;
  DocNumHeaderInput: DocNumHeaderInput;
};


export type MutationCreateProfitRecogFormulaArgs = {
  input: ProfitRecogFormulaInput;
};


export type MutationUpdateProfitRecogFormulaArgs = {
  input: ProfitRecogFormulaInput;
};


export type MutationDeleteProfitRecogFormulaArgs = {
  input: ProfitRecogFormulaDeleteInput;
};


export type MutationCreateProfitRecogParameterArgs = {
  input: ProfitRecogParameterInput;
};


export type MutationUpdateProfitRecogParameterArgs = {
  input: ProfitRecogParameterInput;
};


export type MutationCreateProfitRecogAssignmentArgs = {
  input: ProfitRecogAssignmentInput;
};


export type MutationUpdateProfitRecogAssignmentArgs = {
  input: ProfitRecogAssignmentInput;
};


export type MutationCreateProfitRecogGenerationArgs = {
  input: ProfitRecogGenerationInput;
};


export type MutationUpdateProfitRecogGenerationArgs = {
  input: ProfitRecogGenerationInput;
};


export type MutationCreateProfitRecogGenParamAssignmentArgs = {
  input: ProfitRecogGenParamAssgmtInput;
};


export type MutationUpdateProfitRecogGenParamAssignmentArgs = {
  input: ProfitRecogGenParamAssgmtInput;
};


export type MutationUpdateProfitRecogFormulaAssignmentArgs = {
  input: UpdateProfitRecogFormulaInput;
};


export type MutationCreateProfitRecogGlAccountCodeArgs = {
  input: ProfitRecogGlAccountCodeInput;
};


export type MutationUpdateProfitRecogGlAccountCodeArgs = {
  input: ProfitRecogGlAccountCodeInput;
};


export type MutationCreateProfitRecogGlJournalArgs = {
  input: ProfitRecogGlJournalInput;
};


export type MutationUpdateProfitRecogGlJournalArgs = {
  input: ProfitRecogGlJournalInput;
};


export type MutationGoodReceiveNoteRpaArgs = {
  files: Array<Scalars['Upload']>;
  siteID?: Maybe<Scalars['String']>;
};


export type MutationInvoiceRpaArgs = {
  files: Array<Scalars['Upload']>;
};


export type MutationUpdateSubcontractorInvitationArgs = {
  input: SubcontractorInvitationInput;
};


export type MutationDeleteSubcontractorInvitationArgs = {
  input: SubcontractorInvitationInput;
};


export type MutationInviteSubcontractorArgs = {
  subcontractorIDs: Array<Scalars['String']>;
  subcontractTenderID: Scalars['String'];
};


export type MutationUnInviteSubcontractorArgs = {
  subcontractTenderID: Scalars['String'];
  subcontractorID: Scalars['String'];
};


export type MutationUnInviteSubcontractorsArgs = {
  subcontractorIDs: Array<Scalars['String']>;
  subcontractTenderID: Scalars['String'];
};


export type MutationCreateSubconRfqSubmissionArgs = {
  input: SubconRfqSubmissionInput;
};


export type MutationUpdateSubconRfqSubmissionArgs = {
  input: SubconRfqSubmissionInput;
};


export type MutationDeleteSubconRfqSubmissionArgs = {
  input: SubconRfqSubmissionInput;
};


export type MutationCreateSubcontractTenderArgs = {
  input: SubcontractTenderInput;
};


export type MutationUpdateSubcontractTenderArgs = {
  input: SubcontractTenderInput;
};


export type MutationDeleteSubcontractTenderArgs = {
  input: SubcontractTenderInput;
};


export type MutationTenderToSubconGenerationArgs = {
  input: SubconTenderGenerationInput;
  ID: Scalars['String'];
};


export type MutationCreateSubcontractGuaranteeArgs = {
  input: SubcontractGuaranteeInput;
};


export type MutationUpdateSubcontractGuaranteeArgs = {
  input: SubcontractGuaranteeInput;
};


export type MutationCreateSubcontractInsuranceArgs = {
  input: SubcontractInsuranceInput;
};


export type MutationUpdateSubcontractInsuranceArgs = {
  input: SubcontractInsuranceInput;
};


export type MutationCancelSubcontractGuaranteeArgs = {
  input: SubcontractGuaranteeCancellationInput;
};


export type MutationCancelSubcontractInsuranceArgs = {
  input: SubcontractInsuranceCancellationInput;
};


export type MutationCreateSubcontractArgs = {
  input: SubcontractInput;
};


export type MutationUpdateSubcontractArgs = {
  input: SubcontractInput;
};


export type MutationAddSubcontractContactArgs = {
  contactInput: Array<ContactPersonInput>;
  ID: Scalars['String'];
};


export type MutationActionOnSubcontractStatusArgs = {
  input: SubcontractActionInput;
  ID: Scalars['String'];
};


export type MutationCreateSubcontractExtensionDateArgs = {
  input: SubcontractExtensionInput;
  ID: Scalars['String'];
};


export type MutationCreateSubcontractCertificateArgs = {
  input: SubcontractCertificateInput;
  ID: Scalars['String'];
};


export type MutationCreateSubcontractVoArgs = {
  input: SubcontractVoInput;
};


export type MutationUpdateSubcontractVoArgs = {
  input: SubcontractVoInput;
};


export type MutationDeleteSubcontractVoArgs = {
  input: SubcontractVoDeleteInput;
};


export type MutationActionOnSubconVoStatusArgs = {
  input: SubcontractVoActionInput;
  ID: Scalars['String'];
};


export type MutationUpdateSubcontractLedgersArgs = {
  tableName: Scalars['String'];
};


export type MutationCreateSubcontractClaimArgs = {
  input: SubcontractClaimInput;
};


export type MutationUpdateSubcontractClaimArgs = {
  input: SubcontractClaimInput;
};


export type MutationDeleteSubcontractClaimArgs = {
  ID: Scalars['String'];
};


export type MutationSubmitSubconRetentionClaimArgs = {
  input: SubconClaimRetentionInput;
};


export type MutationApproveSubconRetentionClaimArgs = {
  input: SubconClaimRetentionInput;
};


export type MutationSubmitSubconClaimArgs = {
  deductionLedger?: Maybe<Array<LedgerInput>>;
  input: SubcontractClaimInput;
};


export type MutationApproveSubconClaimArgs = {
  input: SubcontractClaimInput;
};


export type MutationRejectSubconClaimArgs = {
  retentionClaim?: Maybe<Scalars['Boolean']>;
  input: RejectInput;
  ID: Scalars['String'];
};


export type MutationCancelSubconClaimArgs = {
  retentionClaim?: Maybe<Scalars['Boolean']>;
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateSubcontractAdvanceArgs = {
  input: SubcontractPaymentInput;
};


export type MutationUpdateSubcontractAdvanceArgs = {
  input: SubcontractPaymentInput;
};


export type MutationDeleteSubcontractAdvanceArgs = {
  ID: Scalars['String'];
};


export type MutationCreateSubcontractPaymentArgs = {
  input: SubcontractPaymentInput;
};


export type MutationUpdateSubcontractPaymentArgs = {
  input: SubcontractPaymentInput;
};


export type MutationDeleteSubcontractPaymentArgs = {
  ID: Scalars['String'];
};


export type MutationCreateSubcontractRefundArgs = {
  input: SubcontractPaymentInput;
};


export type MutationUpdateSubcontractRefundArgs = {
  input: SubcontractPaymentInput;
};


export type MutationDeleteSubcontractRefundArgs = {
  ID: Scalars['String'];
};


export type MutationMakeSubconPaymentArgs = {
  ledger: Array<LedgerInput>;
  input: SubcontractPaymentInput;
};


export type MutationCancelSubconAdvanceArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelSubconPaymentArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelSubconRefundArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateSubcontractMiscInvoiceArgs = {
  input: SubcontractTransactionInput;
};


export type MutationUpdateSubcontractMiscInvoiceArgs = {
  input: SubcontractTransactionInput;
};


export type MutationDeleteSubcontractMiscInvoiceArgs = {
  ID: Scalars['String'];
};


export type MutationCreateSubcontractDnArgs = {
  input: SubcontractTransactionInput;
};


export type MutationUpdateSubcontractDnArgs = {
  input: SubcontractTransactionInput;
};


export type MutationDeleteSubcontractDnArgs = {
  ID: Scalars['String'];
};


export type MutationCreateSubcontractCnArgs = {
  input: SubcontractTransactionInput;
};


export type MutationUpdateSubcontractCnArgs = {
  input: SubcontractTransactionInput;
};


export type MutationDeleteSubcontractCnArgs = {
  ID: Scalars['String'];
};


export type MutationCreateDNtoSubconArgs = {
  input: SubcontractTransactionInput;
};


export type MutationUpdateDNtoSubconArgs = {
  input: SubcontractTransactionInput;
};


export type MutationDeleteDNtoSubconArgs = {
  ID: Scalars['String'];
};


export type MutationCreateCNtoSubconArgs = {
  input: SubcontractTransactionInput;
};


export type MutationUpdateCNtoSubconArgs = {
  input: SubcontractTransactionInput;
};


export type MutationDeleteCNtoSubconArgs = {
  ID: Scalars['String'];
};


export type MutationMakeSubconAllocationArgs = {
  ledger: Array<LedgerInput>;
};


export type MutationResetSubcontractAllocationArgs = {
  receiptPayment?: Maybe<Scalars['Boolean']>;
  creditID?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
};


export type MutationCancelSubcontractMiscInvoiceArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelSubconDnArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelSubconCnArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelDNtoSubconArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelCNtoSubconArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateSubcontractorArgs = {
  input: SubcontractorInput;
};


export type MutationCreatePlatformSubcontractorArgs = {
  input: SubcontractorInput;
};


export type MutationUpdateSubcontractorArgs = {
  input: SubcontractorInput;
};


export type MutationDeleteSubcontractorArgs = {
  input: SubcontractorDeleteId;
};


export type MutationUpdatePlatformSubcontractorArgs = {
  input: SubcontractorInput;
};


export type MutationCreateSubcontractorCCategoryArgs = {
  input: SubcontractorCCategoryInput;
};


export type MutationUpdateSubcontractorCCategoryArgs = {
  input: SubcontractorCCategoryInput;
};


export type MutationAddSubcontractorContactArgs = {
  contactInput: Array<ContactPersonInput>;
  platformAccess: Scalars['Boolean'];
  ID: Scalars['String'];
};


export type MutationCreateSubcontractorAndPackageArgs = {
  costCategoryID: Array<Scalars['String']>;
  subcontractorInput: SubcontractorInput;
};


export type MutationCreateBulkSubconArgs = {
  subcontractorInput: Array<BulkSubcontractorInput>;
};


export type MutationAddSubcontractorCCategoryArgs = {
  costCategoryID: Array<Scalars['String']>;
  subcontractorID: Scalars['String'];
};


export type MutationDeleteCostCategoryArgs = {
  input: CostCategoryUpdateInput;
};


export type MutationCancelCostCategoryArgs = {
  ID: Scalars['String'];
};


export type MutationActivateCostCategoryArgs = {
  input: CostCategoryUpdateInput;
};


export type MutationCreateBatchCostCategoryArgs = {
  input: Array<CostCategoryInput>;
};


export type MutationCreateCostCategoryArgs = {
  input: CostCategoryInput;
};


export type MutationUpdateCostCategoryArgs = {
  input: CostCategoryInput;
};


export type MutationDeleteCostCategoryCostItemArgs = {
  ID: Scalars['String'];
};


export type MutationCreateCostItemArgs = {
  input: CostItemInput;
};


export type MutationUpdateCostItemArgs = {
  input: CostItemInput;
};


export type MutationDeleteCostItemArgs = {
  input: CostItemDeleteInput;
};


export type MutationActivateCostItemArgs = {
  input: CostItemDeleteInput;
};


export type MutationCancelCostItemArgs = {
  ID: Scalars['String'];
};


export type MutationCreatePlantMachineryArgs = {
  input: PlantMachineryInput;
};


export type MutationUpdatePlantMachineryArgs = {
  input: PlantMachineryInput;
};


export type MutationUpdateAssignmentArgs = {
  input: AssignmentInput;
};


export type MutationCreateWorkshopArgs = {
  input: WorkshopInput;
};


export type MutationUpdateWorkshopArgs = {
  input: WorkshopInput;
};


export type MutationCreatePnmRentalArgs = {
  input: PnmRentalInput;
};


export type MutationUpdatePnmRentalArgs = {
  input: PnmRentalInput;
};


export type MutationCreateAssignmentArgs = {
  input: AssignmentInput;
};


export type MutationCreateMaintenanceArgs = {
  assignmentID?: Maybe<Scalars['String']>;
  input: MaintenanceInput;
};


export type MutationUpdateMaintenanceArgs = {
  input: MaintenanceInput;
  assignmentID?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};


export type MutationReturnFromAssignmentArgs = {
  input: ReturnInput;
  plantMachineryID: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationCancelMaintenanceCostArgs = {
  input: CancelInput;
  plantMachineryID: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationCancelAssignmentArgs = {
  input: CancelInput;
  plantMachineryID: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationReturnFromMaintenanceArgs = {
  input: ReturnmaintenanceCostInput;
  plantMachineryID: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationSubmitDoArgs = {
  doItemInput: Array<DoItemInput>;
  doInput: DoInput;
  ID?: Maybe<Scalars['String']>;
};


export type MutationCreateDoArgs = {
  doItemInput: Array<DoItemInput>;
  doInput: DoInput;
  ID?: Maybe<Scalars['String']>;
};


export type MutationUpdateDoArgs = {
  doItemInput: Array<DoItemInput>;
  doInput: UpdateDoInput;
  ID: Scalars['String'];
};


export type MutationDeleteDoArgs = {
  doID: Scalars['String'];
};


export type MutationRejectDoArgs = {
  rejectInput?: Maybe<DoRejectInput>;
  ID: Scalars['String'];
};


export type MutationCreateUpdateGrtnArgs = {
  grtnItemInput: Array<GrtnItemInput>;
  grtnInput: GoodReturnNoteInput;
  ID?: Maybe<Scalars['String']>;
};


export type MutationApproveGrtnArgs = {
  grtnItemInput: Array<GrtnItemInput>;
  ID: Scalars['String'];
};


export type MutationRejectGrtnArgs = {
  rejectInput?: Maybe<GrtnRejectInput>;
  ID: Scalars['String'];
};


export type MutationDeleteGrtnArgs = {
  ID: Scalars['String'];
};


export type MutationCreateNegotiatedSupplyArgs = {
  negoItemInput: Array<NegotiatedSupplyItemInput>;
  negoSupplyInput: NegotiatedSupplyInput;
};


export type MutationUpdateNegotiatedSupplyArgs = {
  negoItemInput: Array<NegotiatedSupplyItemInput>;
  negoSupplyInput: NegotiatedSupplyInput;
  ID: Scalars['String'];
};


export type MutationCancelNegotiatedSupplyArgs = {
  input: NegoSupplyCancellationInput;
  ID: Scalars['String'];
};


export type MutationDeleteNegoSupplyArgs = {
  ID: Scalars['String'];
};


export type MutationCreatePoArgs = {
  poInput: Array<PurchaseOrderInput>;
};


export type MutationActionOnPoStatusArgs = {
  input: PurchaseActionInput;
  ID: Scalars['String'];
};


export type MutationCancelClosePoArgs = {
  input: PurchaseActionInput;
  ID: Scalars['String'];
};


export type MutationPurchaseBudgetApprovalArgs = {
  input: PurchaseBudgetInput;
  IDs: Array<Scalars['String']>;
};


export type MutationCreatePurchaseReqArgs = {
  prItemListInput: Array<PrItemInput>;
  purchaseReqInput: PurchaseReqInput;
};


export type MutationUpdatePurchaseRequestArgs = {
  prItemListInput: Array<PrItemInput>;
  purchaseReqInput: PurchaseReqInput;
  ID: Scalars['String'];
};


export type MutationActionOnPrStatusArgs = {
  input: PurchaseActionInput;
  ID: Scalars['String'];
};


export type MutationCancelClosePrArgs = {
  input: PurchaseActionInput;
  ID: Scalars['String'];
};


export type MutationSubmitEvaluationSelectionArgs = {
  evalSelectInput: Array<EvalSelectInput>;
  rfqInput: RfqEvalInput;
};


export type MutationCreateRfqArgs = {
  rfqItemInput: Array<RfqItemInput>;
  rfqInput: RfqInput;
};


export type MutationUpdateRfqArgs = {
  rfqItemInput: Array<RfqItemInput>;
  rfqInput: RfqInput;
  ID: Scalars['String'];
};


export type MutationRejectRfqArgs = {
  rfqRejectInput: RfqRejectInput;
  ID: Scalars['String'];
};


export type MutationCancelCloseRfqArgs = {
  input: PurchaseActionInput;
  ID: Scalars['String'];
};


export type MutationUpdateRfqSupplierInvitationArgs = {
  input: RfqSupplierInvitationInput;
};


export type MutationDeleteRfqSupplierInvitationArgs = {
  input: RfqSupplierInvitationInput;
};


export type MutationInviteSupplierArgs = {
  supplierIDs: Array<Scalars['String']>;
  rfqID: Scalars['String'];
};


export type MutationUninviteSupplierArgs = {
  supplierID: Scalars['String'];
  rfqID: Scalars['String'];
};


export type MutationSubmitRfqQuoteArgs = {
  input: RfqSubmissionInput;
};


export type MutationCreateSupplierArgs = {
  input: SupplierInput;
};


export type MutationCreatePlatformSupplierArgs = {
  input: SupplierInput;
};


export type MutationUpdateSupplierArgs = {
  input: SupplierInput;
};


export type MutationUpdatePlatformSupplierArgs = {
  input: SupplierInput;
};


export type MutationCreateSupplierCCategoryArgs = {
  input: SupplierCCategoryInput;
};


export type MutationUpdateSupplierCCategoryArgs = {
  input: SupplierCCategoryInput;
};


export type MutationDeleteSupplierCCategoryArgs = {
  input: SupplierCCategoryInput;
};


export type MutationDeleteSupplierArgs = {
  input: SupplierDeleteInput;
};


export type MutationAddSupplierContactArgs = {
  contactInput: Array<ContactPersonInput>;
  platformAccess: Scalars['Boolean'];
  ID: Scalars['String'];
};


export type MutationCreateBulkSupplierArgs = {
  supplierInput: Array<BulkSupplierInput>;
};


export type MutationAddCCategoryInSupplierArgs = {
  costCategoryID: Array<Scalars['String']>;
  supplierID: Scalars['String'];
};


export type MutationAddSupplierInCCategoryArgs = {
  supplierID: Array<Scalars['String']>;
  costCategoryID: Scalars['String'];
};


export type MutationCreateApInvoiceArgs = {
  input: ApTransactionInput;
};


export type MutationUpdateApInvoiceArgs = {
  input: ApTransactionInput;
};


export type MutationDeleteApInvoiceArgs = {
  ID: Scalars['String'];
};


export type MutationCreateSupplierInvoiceArgs = {
  invDoItemMatchInput: Array<ApInvoiceItemInput>;
  ledger: Array<LedgerInput>;
  invoiceInput: ApInvoiceInput;
};


export type MutationCreateSupplierInvoiceV2Args = {
  isWithDO: Scalars['Boolean'];
  APInvoiceItemInput: Array<ApInvoiceItemInput>;
  ledger: Array<LedgerInput>;
  invoiceInput: ApInvoiceInput;
};


export type MutationUpdateSupplierInvoiceV2Args = {
  isWithDO: Scalars['Boolean'];
  APInvoiceItemInput: Array<ApInvoiceItemInput>;
  ledger: Array<LedgerInput>;
  invoiceInput: ApInvoiceInput;
};


export type MutationUpdateSupplierInvoiceArgs = {
  invDoItemMatchInput: Array<ApInvoiceItemInput>;
  ledger: Array<LedgerInput>;
  invoiceInput: ApInvoiceInput;
};


export type MutationDeleteSupplierInvoiceArgs = {
  ID: Scalars['String'];
};


export type MutationCancelSupplierInvoiceArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateApAdvanceArgs = {
  input: ApPaymentInput;
};


export type MutationUpdateApAdvanceArgs = {
  input: ApPaymentInput;
};


export type MutationDeleteApAdvanceArgs = {
  ID: Scalars['String'];
};


export type MutationCreateApPaymentArgs = {
  input: ApPaymentInput;
};


export type MutationUpdateApPaymentArgs = {
  input: ApPaymentInput;
};


export type MutationDeleteApPaymentArgs = {
  ID: Scalars['String'];
};


export type MutationCreateApRefundArgs = {
  input: ApPaymentInput;
};


export type MutationUpdateApRefundArgs = {
  input: ApPaymentInput;
};


export type MutationDeleteApRefundArgs = {
  ID: Scalars['String'];
};


export type MutationMakeSupplierPaymentArgs = {
  ledger: Array<LedgerInput>;
  input: ApPaymentInput;
};


export type MutationCancelSupplierDepositArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelSupplierPaymentArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelSupplierRefundArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateApDebitNoteArgs = {
  input: ApTransactionInput;
};


export type MutationUpdateApDebitNoteArgs = {
  input: ApTransactionInput;
};


export type MutationDeleteApDebitNoteArgs = {
  ID: Scalars['String'];
};


export type MutationCreateApCreditNoteArgs = {
  input: ApTransactionInput;
};


export type MutationUpdateApCreditNoteArgs = {
  input: ApTransactionInput;
};


export type MutationDeleteApCreditNoteArgs = {
  ID: Scalars['String'];
};


export type MutationCreateDNtoSupplierArgs = {
  input: ApTransactionInput;
};


export type MutationUpdateDNtoSupplierArgs = {
  input: ApTransactionInput;
};


export type MutationDeleteDNtoSupplierArgs = {
  ID: Scalars['String'];
};


export type MutationCreateCNtoSupplierArgs = {
  input: ApTransactionInput;
};


export type MutationUpdateCNtoSupplierArgs = {
  input: ApTransactionInput;
};


export type MutationDeleteCNtoSupplierArgs = {
  ID: Scalars['String'];
};


export type MutationCreateApInvoiceWithoutDoArgs = {
  input: ApTransactionInput;
};


export type MutationUpdateApInvoiceWithoutDoArgs = {
  input: ApTransactionInput;
};


export type MutationDeleteApInvoiceWithoutDoArgs = {
  ID: Scalars['String'];
};


export type MutationMakeSupplierAllocationArgs = {
  ledger: Array<LedgerInput>;
};


export type MutationResetSupplierAllocationArgs = {
  creditID?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
};


export type MutationCancelSupplierDnArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelSupplierCnArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelDNtoSupplierArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelCNtoSupplierArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateUomArgs = {
  input: UomInput;
};


export type MutationUpdateUomArgs = {
  input: UomInput;
};


export type MutationDeleteUomArgs = {
  input: UomDeleteInput;
};


export type MutationCancelUomArgs = {
  input: UomDeleteInput;
};


export type MutationActivateUomArgs = {
  input: UomDeleteInput;
};


export type MutationCreateBatchUomArgs = {
  input: Array<UomInput>;
};


export type MutationCreateUomExchangeArgs = {
  input: UomExchangeInput;
};


export type MutationUpdateUomExchangeArgs = {
  input: UomExchangeInput;
};


export type MutationDeleteUomExchangeArgs = {
  ID: Scalars['String'];
};


export type MutationCreateDefectArgs = {
  input: DefectInput;
};


export type MutationUpdateDefectArgs = {
  input: DefectInput;
};


export type MutationCreateDefectAssignmentArgs = {
  input: DefectAssignmentInput;
  ID: Scalars['String'];
};


export type MutationCreateActivityLogsArgs = {
  file?: Maybe<Array<Scalars['Upload']>>;
  input: ActivityLogInput;
  defectID: Scalars['String'];
};


export type MutationCreateSubcontractorSetupArgs = {
  contactInput: Array<ContactPersonInput>;
  costCategoryID: Array<Scalars['String']>;
  input: SubcontractorHeaderInput;
};


export type MutationUpdateSubcontractorSetupArgs = {
  contactInput: Array<ContactPersonInput>;
  costCategoryID: Array<Scalars['String']>;
  input: SubcontractorHeaderInput;
};


export type MutationDeleteSubcontractorSetupArgs = {
  ID: Scalars['String'];
};


export type MutationCreateDefectMgmtArgs = {
  input: DefectManagementInput;
};


export type MutationUpdateDefectMgmtArgs = {
  input: DefectManagementInput;
};


export type MutationAssignDefectArgs = {
  input: DefectAssignmentInput;
  ID: Scalars['String'];
};


export type MutationReOpenDefectArgs = {
  input: ReOpenDefectInput;
  ID: Scalars['String'];
};


export type MutationCreateActivityLogArgs = {
  file?: Maybe<Array<Scalars['Upload']>>;
  input: DefectLogInput;
  defectID: Scalars['String'];
};


export type MutationDeleteWorkerArgs = {
  input: WorkerDeleteInput;
};


export type MutationCancelWorkerArgs = {
  input: WorkerDeleteInput;
};


export type MutationCreateWorkerArgs = {
  input: WorkerInput;
};


export type MutationUpdateWorkerArgs = {
  input: WorkerInput;
};


export type MutationCreateBulkWorkerArgs = {
  input: Array<WorkerInput>;
};


export type MutationUpdateWorkerAttendanceArgs = {
  input: WorkerAttendanceInput;
};


export type MutationDeleteWorkerAttendanceArgs = {
  input: WorkerAttendanceDeleteInput;
};


export type MutationCancelWorkerAttendanceArgs = {
  input: WorkerAttendanceDeleteInput;
};


export type MutationCreateWorkerAttendanceArgs = {
  input: WorkerAttendanceInput;
};


export type MutationCancelGrnArgs = {
  input: GrnDeleteInput;
};


export type MutationCreateGrnItemArgs = {
  input: GrnItemInput;
};


export type MutationUpdateGrnItemArgs = {
  input: GrnItemInput;
};


export type MutationDeleteGrnItemArgs = {
  input: GrnItemDeleteInput;
};


export type MutationCancelGrnItemArgs = {
  input: GrnItemDeleteInput;
};


export type MutationCreateGrnArgs = {
  grnItemInput: Array<GrnItemInput>;
  grnInput: GrnInput;
};


export type MutationUpdateGrnArgs = {
  grnItemInput: Array<GrnItemInput>;
  grnInput: GrnInput;
};


export type MutationDeleteGrnArgs = {
  ID: Scalars['String'];
};


export type MutationCreateStockMovementArgs = {
  input: StockMovementInput;
};


export type MutationUpdateStockMovementArgs = {
  input: StockMovementInput;
};


export type MutationDeleteStockMovementArgs = {
  input: StockMovementDeleteInput;
};


export type MutationCancelStockMovementArgs = {
  input: StockMovementDeleteInput;
};


export type MutationCreateSiteProgressArgs = {
  input: SiteProgressInput;
};


export type MutationUpdateSiteProgressArgs = {
  input: SiteProgressInput;
};


export type MutationDeleteSiteProgressArgs = {
  input: SiteProgressInput;
};


export type MutationCreateSiteStockLedgerArgs = {
  input: SiteStockLedgerInput;
};


export type MutationUpdateSiteStockLedgerArgs = {
  input: SiteStockLedgerInput;
};


export type MutationDeleteSiteStockLedgerArgs = {
  input: SiteStockLedgerInput;
};


export type MutationCreateSiteWorkerAttendanceArgs = {
  input: SiteWorkerAttendanceInput;
};


export type MutationUpdateSiteWorkerAttendanceArgs = {
  input: SiteWorkerAttendanceInput;
};


export type MutationDeleteSiteWorkerAttendanceArgs = {
  input: SiteWorkerAttendanceInput;
};


export type MutationCreateSiteWorkersArgs = {
  input: SiteWorkersInput;
};


export type MutationUpdateSiteWorkersArgs = {
  input: SiteWorkersInput;
};


export type MutationDeleteSiteWorkersArgs = {
  input: SiteWorkersInput;
};


export type MutationDeleteCalendarArgs = {
  input: CalendarDeleteInput;
};


export type MutationCancelCalendarArgs = {
  input: CalendarDeleteInput;
};


export type MutationActivateCalendarArgs = {
  input: CalendarDeleteInput;
};


export type MutationCreateCalendarArgs = {
  publicHolidayID: Array<Scalars['String']>;
  input: CalendarInput;
};


export type MutationUpdateCalendarArgs = {
  publicHolidayID: Array<Scalars['String']>;
  input: CalendarInput;
};


export type MutationCreateCalendarPhPolicyArgs = {
  input: CalendarPhPolicyInput;
};


export type MutationUpdateCalendarPhPolicyArgs = {
  input: CalendarPhPolicyInput;
};


export type MutationDeleteCalendarPhPolicyArgs = {
  input: CalendarPhPolicyDeleteInput;
};


export type MutationCancelCalendarPhPolicyArgs = {
  input: CalendarPhPolicyDeleteInput;
};


export type MutationActivateCalendarPhPolicyArgs = {
  input: CalendarPhPolicyDeleteInput;
};


export type MutationCreateCalendarWeekendPolicyArgs = {
  input: CalendarWeekendPolicyInput;
};


export type MutationUpdateCalendarWeekendPolicyArgs = {
  input: CalendarWeekendPolicyInput;
};


export type MutationDeleteCalendarWeekendPolicyArgs = {
  input: CalendarWeekendPolicyDeleteInput;
};


export type MutationCancelCalendarWeekendPolicyArgs = {
  input: CalendarWeekendPolicyDeleteInput;
};


export type MutationActivateCalendarWeekendPolicyArgs = {
  input: CalendarWeekendPolicyDeleteInput;
};


export type MutationCreateHolidayTypeArgs = {
  input: HolidayTypeInput;
};


export type MutationUpdateHolidayTypeArgs = {
  input: HolidayTypeInput;
};


export type MutationDeleteHolidayTypeArgs = {
  input: HolidayTypeDeleteInput;
};


export type MutationCancelHolidayTypeArgs = {
  input: HolidayTypeDeleteInput;
};


export type MutationActivateHolidayTypeArgs = {
  input: HolidayTypeDeleteInput;
};


export type MutationCreateJobTypeArgs = {
  input: JobTypeInput;
};


export type MutationUpdateJobTypeArgs = {
  input: JobTypeInput;
};


export type MutationDeleteJobTypeArgs = {
  input: JobTypeDeleteInput;
};


export type MutationCancelJobTypeArgs = {
  input: JobTypeDeleteInput;
};


export type MutationActivateJobTypeArgs = {
  input: JobTypeDeleteInput;
};


export type MutationCreatePublicHolidayArgs = {
  input: PublicHolidayInput;
};


export type MutationUpdatePublicHolidayArgs = {
  input: PublicHolidayInput;
};


export type MutationDeletePublicHolidayArgs = {
  input: PublicHolidayDeleteInput;
};


export type MutationCancelPublicHolidayArgs = {
  input: PublicHolidayDeleteInput;
};


export type MutationActivatePublicHolidayArgs = {
  input: PublicHolidayDeleteInput;
};


export type MutationCreateSiteArgs = {
  siteInput: Array<SiteInput>;
  input: ProjectHeaderInput;
};


export type MutationUpdateSiteArgs = {
  siteInput: Array<SiteInput>;
  input: ProjectHeaderInput;
};


export type MutationDeleteSiteArgs = {
  input: SiteDeleteInput;
};


export type MutationCancelSiteArgs = {
  input: SiteDeleteInput;
};


export type MutationActivateSiteArgs = {
  input: SiteDeleteInput;
};


export type MutationInsertUpdateSiteArgs = {
  siteInput: Array<SiteInput>;
  contractID: Scalars['String'];
};


export type MutationDeleteProjectSiteArgs = {
  ID: Scalars['String'];
};


export type MutationCreateSiteMaterialArgs = {
  input: SiteMaterialInput;
};


export type MutationUpdateSiteMaterialArgs = {
  input: SiteMaterialInput;
};


export type MutationDeleteSiteMaterialArgs = {
  input: SiteMaterialDeleteInput;
};


export type MutationCancelSiteMaterialArgs = {
  input: SiteMaterialDeleteInput;
};


export type MutationActivateSiteMaterialArgs = {
  input: SiteMaterialDeleteInput;
};


export type MutationCreateBulkSiteMaterialArgs = {
  input: Array<SiteMaterialInput>;
};


export type MutationCreateVideoCamArgs = {
  input: VideoCamInput;
};


export type MutationUpdateVideoCamArgs = {
  input: VideoCamInput;
};


export type MutationDeleteVideoCamArgs = {
  input: VideoCamDeleteInput;
};


export type MutationCancelVideoCamArgs = {
  input: VideoCamDeleteInput;
};


export type MutationActivateVideoCamArgs = {
  input: VideoCamDeleteInput;
};


export type MutationCreateWagesRatePolicyArgs = {
  input: WagesRatePolicyInput;
};


export type MutationDeleteWagesRatePolicyArgs = {
  input: WagesRatePolicyDeleteInput;
};


export type MutationCancelWagesRatePolicyArgs = {
  input: WagesRatePolicyDeleteInput;
};


export type MutationActivateWagesRatePolicyArgs = {
  input: WagesRatePolicyDeleteInput;
};


export type MutationUpdateWagesRatePolicyArgs = {
  holTypeRateIDs: Array<HolidayTypeRateInput>;
};


export type MutationUpdateWorkingHourPolicyArgs = {
  input: WorkingHourPolicyInput;
};


export type MutationDeleteWorkingHourPolicyArgs = {
  input: WorkingHourPolicyDeleteInput;
};


export type MutationCancelWorkingHourPolicyArgs = {
  input: WorkingHourPolicyDeleteInput;
};


export type MutationActivateWorkingHourPolicyArgs = {
  input: WorkingHourPolicyDeleteInput;
};


export type MutationCreateWorkingHourPolicyArgs = {
  input: WorkingHourPolicyInput;
};


export type MutationCreateSiteImageArgs = {
  input: SiteImageInput;
};


export type MutationUpdateSiteImageArgs = {
  input: SiteImageInput;
};


export type MutationDeleteSiteImageArgs = {
  input: SiteImageDeleteInput;
};


export type MutationCancelSiteImageArgs = {
  input: SiteImageDeleteInput;
};


export type MutationUpdateWageCycleArgs = {
  input: WageCycleInput;
};


export type MutationCancelWageCycleArgs = {
  input: WageCycleDeleteInput;
};


export type MutationDeleteWageCycleArgs = {
  wageCycleID: Scalars['String'];
};


export type MutationCreateWageCycleArgs = {
  workerWageCycleIDs: Array<WorkerWageCycleInput>;
  input: WageCycleInput;
};


export type MutationUpdateWorkerVerifiedHourArgs = {
  workerRateIDs: Array<WorkerHourInput>;
};


export type MutationCreateWageCycleDetailArgs = {
  input: WageCycleDetailInput;
};


export type MutationUpdateWageCycleDetailArgs = {
  input: WageCycleDetailInput;
};


export type MutationDeleteWageCycleDetailArgs = {
  input: WageCycleDetailDeleteInput;
};


export type MutationCancelWageCycleDetailArgs = {
  input: WageCycleDetailDeleteInput;
};


export type MutationCreateWorkerAdvanceArgs = {
  input: WorkerAdvanceInput;
};


export type MutationUpdateWorkerAdvanceArgs = {
  input: WorkerAdvanceInput;
};


export type MutationDeleteWorkerAdvanceArgs = {
  input: WorkerAdvanceDeleteInput;
};


export type MutationCancelWorkerAdvanceArgs = {
  input: WorkerAdvanceDeleteInput;
};


export type MutationCreateWorkerWageArgs = {
  input: WorkerWageInput;
};


export type MutationUpdateWorkerWageArgs = {
  input: WorkerWageInput;
};


export type MutationDeleteWorkerWageArgs = {
  input: WorkerWageDeleteInput;
};


export type MutationCancelWorkerWageArgs = {
  input: WorkerWageDeleteInput;
};

export type LoggedInUserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type LoggedInUserProfileQuery = (
  { __typename?: 'Query' }
  & { loggedInUserProfile: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'accountID' | 'name' | 'email' | 'contactNo' | 'department' | 'superUser' | 'jobTitle'>
  ) }
);

export type LoginMutationVariables = Exact<{
  loginId: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'accessToken'>
    & { user: (
      { __typename?: 'UserResponse' }
      & Pick<UserResponse, 'name'>
    ) }
  ) }
);

export type ChangePasswordMutationVariables = Exact<{
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changePassword'>
);

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'forgotPassword'>
);

export type ResetPasswordMutationVariables = Exact<{
  password: Scalars['String'];
  token: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPassword'>
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type ActivateUserMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ActivateUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activateUser'>
);

export type PlatformLoginMutationVariables = Exact<{
  softwareCode: SoftwareCode;
  password: Scalars['String'];
  loginId: Scalars['String'];
}>;


export type PlatformLoginMutation = (
  { __typename?: 'Mutation' }
  & { platformLogin: (
    { __typename?: 'PlatformLoginResponse' }
    & Pick<PlatformLoginResponse, 'accessToken'>
    & { user: (
      { __typename?: 'PlatformUserResponse' }
      & Pick<PlatformUserResponse, 'name'>
    ) }
  ) }
);

export type LoggedInPlatformUserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type LoggedInPlatformUserProfileQuery = (
  { __typename?: 'Query' }
  & { loggedInPlatformUserProfile: (
    { __typename?: 'PlatformUserEntity' }
    & Pick<PlatformUserEntity, 'ID' | 'accountID' | 'userName' | 'associateID' | 'superUser' | 'softwareCode'>
    & { contactDetail: (
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'email' | 'contactNo' | 'name' | 'designation'>
    ) }
  ) }
);

export type AssociateMenuQueryVariables = Exact<{
  associate: Scalars['String'];
}>;


export type AssociateMenuQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'associateMenu'>
);

export type GetSubcontractorQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractorQuery = (
  { __typename?: 'Query' }
  & { getSubcontractor: Array<(
    { __typename?: 'SubcontractorEntity' }
    & Pick<SubcontractorEntity, 'ID' | 'name' | 'email' | 'accountID' | 'registrationNo' | 'associatedStatus' | 'contactNo' | 'creditTerm' | 'currencyID' | 'ratingType' | 'cidbDetails' | 'pkkDetails' | 'statusRemarks' | 'bankDetails' | 'taxDetails' | 'address' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'description' | 'taxTypeID'>
    & { contactPerson: Array<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID'>
    )>, subconClaim: Array<(
      { __typename?: 'SubcontractClaimEntity' }
      & Pick<SubcontractClaimEntity, 'ID' | 'outstandingAmt'>
    )>, subconAdvance?: Maybe<Array<(
      { __typename?: 'SubcontractAdvanceEntity' }
      & Pick<SubcontractAdvanceEntity, 'ID' | 'outstandingAmt' | 'baseAmt'>
    )>>, subcontract?: Maybe<Array<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'ID' | 'title' | 'subcontractSum'>
    )>> }
  )> }
);

export type GetSubcontractorListQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractorListQuery = (
  { __typename?: 'Query' }
  & { getSubcontractor: Array<(
    { __typename?: 'SubcontractorEntity' }
    & Pick<SubcontractorEntity, 'ID' | 'name'>
  )> }
);

export type GetSubcontractorCCategoryQueryVariables = Exact<{
  subcontractorID?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractorCCategoryQuery = (
  { __typename?: 'Query' }
  & { getSubcontractorCCategory: Array<(
    { __typename?: 'SubcontractorCCategoryEntity' }
    & Pick<SubcontractorCCategoryEntity, 'subcontractorID' | 'costCategoryID'>
  )> }
);

export type CreateSubcontractorMutationVariables = Exact<{
  input: SubcontractorInput;
}>;


export type CreateSubcontractorMutation = (
  { __typename?: 'Mutation' }
  & { createSubcontractor: (
    { __typename?: 'SubcontractorEntity' }
    & Pick<SubcontractorEntity, 'ID' | 'name'>
  ) }
);

export type UpdateSubcontractorMutationVariables = Exact<{
  input: SubcontractorInput;
}>;


export type UpdateSubcontractorMutation = (
  { __typename?: 'Mutation' }
  & { updateSubcontractor: (
    { __typename?: 'SubcontractorEntity' }
    & Pick<SubcontractorEntity, 'ID' | 'name'>
  ) }
);

export type AddSubcontractorContactMutationVariables = Exact<{
  ID: Scalars['String'];
  platformAccess: Scalars['Boolean'];
  contactInput: Array<ContactPersonInput> | ContactPersonInput;
}>;


export type AddSubcontractorContactMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addSubcontractorContact'>
);

export type AddSupplierContactMutationVariables = Exact<{
  ID: Scalars['String'];
  platformAccess: Scalars['Boolean'];
  contactInput: Array<ContactPersonInput> | ContactPersonInput;
}>;


export type AddSupplierContactMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addSupplierContact'>
);

export type AddSubcontractorCCategoryMutationVariables = Exact<{
  subcontractorID: Scalars['String'];
  costCategoryID: Array<Scalars['String']> | Scalars['String'];
}>;


export type AddSubcontractorCCategoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addSubcontractorCCategory'>
);

export type CreateSubcontractorAndPackageMutationVariables = Exact<{
  subcontractorInput: SubcontractorInput;
  costCategoryID: Array<Scalars['String']> | Scalars['String'];
}>;


export type CreateSubcontractorAndPackageMutation = (
  { __typename?: 'Mutation' }
  & { createSubcontractorAndPackage: (
    { __typename?: 'SubcontractorEntity' }
    & Pick<SubcontractorEntity, 'ID' | 'name'>
  ) }
);

export type GetCustomerTitleQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetCustomerTitleQuery = (
  { __typename?: 'Query' }
  & { getCustomer: Array<(
    { __typename?: 'CustomerEntity' }
    & Pick<CustomerEntity, 'ID' | 'name'>
  )> }
);

export type GetSubcontractorTitleQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractorTitleQuery = (
  { __typename?: 'Query' }
  & { getSubcontractor: Array<(
    { __typename?: 'SubcontractorEntity' }
    & Pick<SubcontractorEntity, 'ID' | 'name'>
  )> }
);

export type GetSubcontractorExecutiveSummaryQueryVariables = Exact<{
  subcontractorID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractorExecutiveSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'subconProfileDate' | 'subconAdvancesInfo' | 'subconClaimsInfo' | 'subconProgressClaimsInfo' | 'subconRetentionClaimsInfo' | 'subconDNInfo' | 'subconCNInfo' | 'submittedPaymentNotification' | 'subconPaymentInfo' | 'subconRefundsInfo' | 'DNtoSubconInfo' | 'outstandingSubcon' | 'unallocatedSubcon'>
);

export type MaxRetentionAmtQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
}>;


export type MaxRetentionAmtQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'maxRetention'>
);

export type ClaimLatestApprovedIdPerContractQueryVariables = Exact<{
  identifier: Scalars['String'];
}>;


export type ClaimLatestApprovedIdPerContractQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'cancellableClaims'>
);

export type GetCustomerQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetCustomerQuery = (
  { __typename?: 'Query' }
  & { getCustomer: Array<(
    { __typename?: 'CustomerEntity' }
    & Pick<CustomerEntity, 'ID' | 'name' | 'accountID' | 'registrationNo' | 'email' | 'contactNo' | 'address' | 'associatedStatus' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'creditTerm' | 'taxDetails' | 'currencyID' | 'taxTypeID' | 'clientAgingInfo'>
    & { contactPerson: Array<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'name' | 'designation' | 'email' | 'contactNo'>
    )>, contract?: Maybe<Array<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title' | 'contractSum'>
    )>>, contractClaim?: Maybe<Array<(
      { __typename?: 'ContractClaimEntity' }
      & Pick<ContractClaimEntity, 'ID' | 'outstandingAmt'>
    )>> }
  )> }
);

export type GetCustomerListQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetCustomerListQuery = (
  { __typename?: 'Query' }
  & { getCustomer: Array<(
    { __typename?: 'CustomerEntity' }
    & Pick<CustomerEntity, 'ID' | 'name'>
  )> }
);

export type UpdateCustomerMutationVariables = Exact<{
  input: CustomerInput;
}>;


export type UpdateCustomerMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomer: (
    { __typename?: 'CustomerEntity' }
    & Pick<CustomerEntity, 'ID' | 'name'>
  ) }
);

export type CreateCustomerMutationVariables = Exact<{
  input: CustomerInput;
}>;


export type CreateCustomerMutation = (
  { __typename?: 'Mutation' }
  & { createCustomer: (
    { __typename?: 'CustomerEntity' }
    & Pick<CustomerEntity, 'ID' | 'name'>
  ) }
);

export type AddCustomerContactMutationVariables = Exact<{
  ID: Scalars['String'];
  contactInput: Array<ContactPersonInput> | ContactPersonInput;
  platformAccess: Scalars['Boolean'];
}>;


export type AddCustomerContactMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addCustomerContact'>
);

export type GetClientSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetClientSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'totalClients' | 'clientNotifications' | 'totalDebitClient' | 'totalCreditClient' | 'totalOutstandingClients' | 'totalOutstandingAmtClients'>
);

export type CreditControlCustomerQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type CreditControlCustomerQuery = (
  { __typename?: 'Query' }
  & { getCustomer: Array<(
    { __typename?: 'CustomerEntity' }
    & Pick<CustomerEntity, 'ID' | 'name' | 'activeContract' | 'overdueInvInfo' | 'clientReceiptInfo' | 'clientAgingInfo' | 'currencyID'>
    & { followUp?: Maybe<Array<(
      { __typename?: 'FollowUpEntity' }
      & Pick<FollowUpEntity, 'createdTs'>
    )>> }
  )> }
);

export type GetCustomerFollowUpLogQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetCustomerFollowUpLogQuery = (
  { __typename?: 'Query' }
  & { getCustomer: Array<(
    { __typename?: 'CustomerEntity' }
    & Pick<CustomerEntity, 'ID' | 'contactNo'>
    & { contactPerson: Array<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'name' | 'designation' | 'email' | 'contactNo'>
    )>, followUp?: Maybe<Array<(
      { __typename?: 'FollowUpEntity' }
      & Pick<FollowUpEntity, 'ID' | 'remarks' | 'contactPerson' | 'followUpStatus' | 'createdTs'>
    )>> }
  )> }
);

export type CreateFollowUpMutationVariables = Exact<{
  input: FollowUpInput;
}>;


export type CreateFollowUpMutation = (
  { __typename?: 'Mutation' }
  & { createFollowUp: (
    { __typename?: 'FollowUpEntity' }
    & Pick<FollowUpEntity, 'ID'>
  ) }
);

export type GetClientExecutiveSummaryQueryVariables = Exact<{
  customerID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetClientExecutiveSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'clientProfileDate' | 'advancesInfo' | 'claimsInfo' | 'progressClaimsInfo' | 'retentionClaimsInfo' | 'submittedClaimNotification' | 'DNInfo' | 'CNInfo' | 'receiptsInfo' | 'refundsInfo' | 'clientDNInfo' | 'outstandingClient' | 'unallocatedClient'>
);

export type GetContractClaimQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
}>;


export type GetContractClaimQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'claimsInfo'>
  & { getContractClaim: Array<(
    { __typename?: 'ContractClaimEntity' }
    & Pick<ContractClaimEntity, 'ID' | 'docNo' | 'claimDocNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'docDueDate' | 'submittedDate' | 'previousCertifiedAmt' | 'submittedCertifiedAmt' | 'currentCertifiedAmt' | 'previousVOClaimAmt' | 'submittedVOClaimAmt' | 'currentVOClaimAmt' | 'previousMosAmt' | 'submittedMosAmt' | 'currentMosAmt' | 'retentionAmt' | 'submittedDayWorkAmt' | 'currentDayWorkAmt' | 'balanceAdvanceAmt' | 'currentAdvRecoupmentAmt' | 'currentDeductionAmt' | 'submittedDeductionAmt' | 'balanceDeductionAmt' | 'transactionStatus' | 'rejectedDate' | 'rejectionRemarks' | 'cancellationDate' | 'cancellationRemarks' | 'contractID' | 'customerID' | 'outstandingAmt' | 'modBy' | 'createdBy'>
    & { contractAllocation: Array<(
      { __typename?: 'ContractAllocationEntity' }
      & Pick<ContractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt'>
    )> }
  )> }
);

export type GetConProgressClaimQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
}>;


export type GetConProgressClaimQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'progressClaimsInfo'>
  & { getProgressClaim: Array<(
    { __typename?: 'ContractClaimEntity' }
    & Pick<ContractClaimEntity, 'ID' | 'isGlExport' | 'accountID' | 'balanceAdvanceAmt' | 'balanceDeductionAmt' | 'balanceRetentionAmt' | 'baseAmt' | 'cancellationRemarks' | 'cancellationDate' | 'claimDocNo' | 'contractID' | 'createdBy' | 'createdTs' | 'currencyID' | 'currentAdvRecoupmentAmt' | 'currentCertifiedAmt' | 'currentDayWorkAmt' | 'currentDeductionAmt' | 'currentMosAmt' | 'customerID' | 'currentVOClaimAmt' | 'description' | 'docAmt' | 'docDate' | 'docDueDate' | 'docNo' | 'docRef' | 'docRefID' | 'docRefTable' | 'exchangeRate' | 'localBaseAmt' | 'localDocAmt' | 'localTaxAmt' | 'modBy' | 'modTs' | 'previousCertifiedAmt' | 'previousMosAmt' | 'previousVOClaimAmt' | 'rejectedDate' | 'rejectionDate' | 'rejectionRemarks' | 'retentionAmt' | 'revisedNo' | 'sequenceNo' | 'submittedCertifiedAmt' | 'submittedDate' | 'submittedDayWorkAmt' | 'submittedDeductionAmt' | 'submittedMosAmt' | 'submittedRetentionAmt' | 'submittedVOClaimAmt' | 'taxAmt' | 'taxRate' | 'taxSchemeID' | 'transactionDate' | 'transactionStatus' | 'voDisplayAmt'>
    & { toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'approvalLevel' | 'body' | 'createdBy' | 'createdTs' | 'docRefAmt' | 'docRefID' | 'docRefTable' | 'modBy' | 'modTs' | 'notifiedDate' | 'subject' | 'submitterID' | 'toDoStatus'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'approverID' | 'createdBy' | 'createdTs' | 'logDate' | 'modBy' | 'modTs' | 'notifiedDate' | 'remarks' | 'toDoID' | 'toDoStatus'>
      )>> }
    )> }
  )> }
);

export type GetConRetentionClaimQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
}>;


export type GetConRetentionClaimQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'retentionClaimsInfo'>
  & { getRetentionClaim: Array<(
    { __typename?: 'ContractClaimEntity' }
    & Pick<ContractClaimEntity, 'ID' | 'isGlExport' | 'accountID' | 'balanceAdvanceAmt' | 'balanceDeductionAmt' | 'balanceRetentionAmt' | 'baseAmt' | 'cancellationDate' | 'cancellationRemarks' | 'claimDocNo' | 'contractID' | 'createdBy' | 'createdTs' | 'currencyID' | 'currentAdvRecoupmentAmt' | 'currentCertifiedAmt' | 'currentDayWorkAmt' | 'currentDeductionAmt' | 'currentMosAmt' | 'currentVOClaimAmt' | 'customerID' | 'description' | 'docAmt' | 'docDate' | 'docDueDate' | 'docNo' | 'docRef' | 'docRefID' | 'docRefTable' | 'exchangeRate' | 'localBaseAmt' | 'localDocAmt' | 'localTaxAmt' | 'modBy' | 'modTs' | 'previousCertifiedAmt' | 'previousMosAmt' | 'previousVOClaimAmt' | 'rejectedDate' | 'rejectionDate' | 'rejectionRemarks' | 'retentionAmt' | 'revisedNo' | 'sequenceNo' | 'submittedCertifiedAmt' | 'submittedDate' | 'submittedDayWorkAmt' | 'submittedDeductionAmt' | 'submittedMosAmt' | 'submittedRetentionAmt' | 'submittedVOClaimAmt' | 'taxAmt' | 'taxRate' | 'taxSchemeID' | 'transactionDate' | 'transactionStatus' | 'voDisplayAmt'>
    & { toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'approvalLevel' | 'body' | 'createdBy' | 'createdTs' | 'docRefAmt' | 'docRefID' | 'docRefTable' | 'modBy' | 'modTs' | 'notifiedDate' | 'subject' | 'submitterID' | 'toDoStatus'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'approverID' | 'createdBy' | 'createdTs' | 'logDate' | 'modBy' | 'modTs' | 'notifiedDate' | 'remarks' | 'toDoID' | 'toDoStatus'>
      )>> }
    )> }
  )> }
);

export type CreateContractClaimMutationVariables = Exact<{
  input: ContractClaimInput;
}>;


export type CreateContractClaimMutation = (
  { __typename?: 'Mutation' }
  & { createContractClaim: (
    { __typename?: 'ContractClaimEntity' }
    & Pick<ContractClaimEntity, 'ID' | 'accountID'>
  ) }
);

export type SubmitRetentionClaimMutationVariables = Exact<{
  input: ClaimRetentionInput;
}>;


export type SubmitRetentionClaimMutation = (
  { __typename?: 'Mutation' }
  & { submitRetentionClaim: (
    { __typename?: 'ContractClaimEntity' }
    & Pick<ContractClaimEntity, 'ID'>
  ) }
);

export type SubmitClaimMutationVariables = Exact<{
  deductionLedger?: Maybe<Array<LedgerInput> | LedgerInput>;
  input: ContractClaimInput;
}>;


export type SubmitClaimMutation = (
  { __typename?: 'Mutation' }
  & { submitClaim: (
    { __typename?: 'ContractClaimEntity' }
    & Pick<ContractClaimEntity, 'ID'>
  ) }
);

export type ApproveClaimMutationVariables = Exact<{
  input: ContractClaimInput;
}>;


export type ApproveClaimMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'approveClaim'>
);

export type UpdateContractClaimMutationVariables = Exact<{
  input: ContractClaimInput;
}>;


export type UpdateContractClaimMutation = (
  { __typename?: 'Mutation' }
  & { updateContractClaim: (
    { __typename?: 'ContractClaimEntity' }
    & Pick<ContractClaimEntity, 'accountID'>
  ) }
);

export type CancelClaimMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelClaimMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelClaim'>
);

export type GetContractDnQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
}>;


export type GetContractDnQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'DNInfo'>
  & { getContractDN: Array<(
    { __typename?: 'ContractDNEntity' }
    & Pick<ContractDnEntity, 'ID' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'transactionStatus' | 'customerID' | 'contractID' | 'outstandingAmt'>
    & { contractAllocation: Array<(
      { __typename?: 'ContractAllocationEntity' }
      & Pick<ContractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt'>
    )> }
  )> }
);

export type CreateContractDnMutationVariables = Exact<{
  input: ContractTransactionInput;
}>;


export type CreateContractDnMutation = (
  { __typename?: 'Mutation' }
  & { createContractDN: (
    { __typename?: 'ContractDNEntity' }
    & Pick<ContractDnEntity, 'accountID'>
  ) }
);

export type UpdateContractDnMutationVariables = Exact<{
  input: ContractTransactionInput;
}>;


export type UpdateContractDnMutation = (
  { __typename?: 'Mutation' }
  & { updateContractDN: (
    { __typename?: 'ContractDNEntity' }
    & Pick<ContractDnEntity, 'accountID'>
  ) }
);

export type GetContractCnQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
}>;


export type GetContractCnQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CNInfo'>
  & { getContractCN: Array<(
    { __typename?: 'ContractCNEntity' }
    & Pick<ContractCnEntity, 'ID' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'transactionStatus' | 'customerID' | 'contractID' | 'outstandingAmt'>
    & { contractAllocation: Array<(
      { __typename?: 'ContractAllocationEntity' }
      & Pick<ContractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt'>
    )> }
  )> }
);

export type CreateContractCnMutationVariables = Exact<{
  input: ContractTransactionInput;
}>;


export type CreateContractCnMutation = (
  { __typename?: 'Mutation' }
  & { createContractCN: (
    { __typename?: 'ContractCNEntity' }
    & Pick<ContractCnEntity, 'accountID'>
  ) }
);

export type UpdateContractCnMutationVariables = Exact<{
  input: ContractTransactionInput;
}>;


export type UpdateContractCnMutation = (
  { __typename?: 'Mutation' }
  & { updateContractCN: (
    { __typename?: 'ContractCNEntity' }
    & Pick<ContractCnEntity, 'accountID'>
  ) }
);

export type GetClientDnQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
}>;


export type GetClientDnQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'clientDNInfo'>
  & { getClientDN: Array<(
    { __typename?: 'ClientDNEntity' }
    & Pick<ClientDnEntity, 'ID' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'outstandingAmt' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'transactionStatus' | 'customerID' | 'contractID'>
    & { deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code' | 'description'>
    )> }
  )> }
);

export type CreateClientDnMutationVariables = Exact<{
  input: ContractTransactionInput;
}>;


export type CreateClientDnMutation = (
  { __typename?: 'Mutation' }
  & { createClientDN: (
    { __typename?: 'ClientDNEntity' }
    & Pick<ClientDnEntity, 'accountID'>
  ) }
);

export type UpdateClientDnMutationVariables = Exact<{
  input: ContractTransactionInput;
}>;


export type UpdateClientDnMutation = (
  { __typename?: 'Mutation' }
  & { updateClientDN: (
    { __typename?: 'ClientDNEntity' }
    & Pick<ClientDnEntity, 'accountID'>
  ) }
);

export type GetContractAdvanceQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
}>;


export type GetContractAdvanceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'advancesInfo'>
  & { getContractAdvance: Array<(
    { __typename?: 'ContractAdvanceEntity' }
    & Pick<ContractAdvanceEntity, 'ID' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'bankAccountID' | 'bankDocAmt' | 'bankDetails' | 'receiptStatus' | 'customerID' | 'contractID' | 'outstandingAmt'>
    & { contractAllocation: Array<(
      { __typename?: 'ContractAllocationEntity' }
      & Pick<ContractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt'>
    )> }
  )> }
);

export type CreateContractAdvanceMutationVariables = Exact<{
  input: ContractReceiptInput;
}>;


export type CreateContractAdvanceMutation = (
  { __typename?: 'Mutation' }
  & { createContractAdvance: (
    { __typename?: 'ContractAdvanceEntity' }
    & Pick<ContractAdvanceEntity, 'ID'>
  ) }
);

export type UpdateContractAdvanceMutationVariables = Exact<{
  input: ContractReceiptInput;
}>;


export type UpdateContractAdvanceMutation = (
  { __typename?: 'Mutation' }
  & { updateContractAdvance: (
    { __typename?: 'ContractAdvanceEntity' }
    & Pick<ContractAdvanceEntity, 'ID'>
  ) }
);

export type GetContractReceiptQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
}>;


export type GetContractReceiptQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'receiptsInfo'>
  & { getContractReceipt: Array<(
    { __typename?: 'ContractReceiptEntity' }
    & Pick<ContractReceiptEntity, 'ID' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'bankAccountID' | 'bankDocAmt' | 'bankDetails' | 'receiptStatus' | 'customerID' | 'contractID' | 'outstandingAmt'>
    & { contractAllocation: Array<(
      { __typename?: 'ContractAllocationEntity' }
      & Pick<ContractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt'>
    )> }
  )> }
);

export type CreateContractReceiptMutationVariables = Exact<{
  input: ContractReceiptInput;
}>;


export type CreateContractReceiptMutation = (
  { __typename?: 'Mutation' }
  & { createContractReceipt: (
    { __typename?: 'ContractReceiptEntity' }
    & Pick<ContractReceiptEntity, 'ID'>
  ) }
);

export type UpdateContractReceiptMutationVariables = Exact<{
  input: ContractReceiptInput;
}>;


export type UpdateContractReceiptMutation = (
  { __typename?: 'Mutation' }
  & { updateContractReceipt: (
    { __typename?: 'ContractReceiptEntity' }
    & Pick<ContractReceiptEntity, 'ID'>
  ) }
);

export type GetContractRefundQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
}>;


export type GetContractRefundQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'refundsInfo'>
  & { getContractRefund: Array<(
    { __typename?: 'ContractRefundEntity' }
    & Pick<ContractRefundEntity, 'ID' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'bankAccountID' | 'bankDocAmt' | 'bankDetails' | 'receiptStatus' | 'customerID' | 'contractID' | 'outstandingAmt'>
    & { contractAllocation: Array<(
      { __typename?: 'ContractAllocationEntity' }
      & Pick<ContractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt'>
    )> }
  )> }
);

export type CreateContractRefundMutationVariables = Exact<{
  input: ContractReceiptInput;
}>;


export type CreateContractRefundMutation = (
  { __typename?: 'Mutation' }
  & { createContractRefund: (
    { __typename?: 'ContractRefundEntity' }
    & Pick<ContractRefundEntity, 'ID'>
  ) }
);

export type UpdateContractRefundMutationVariables = Exact<{
  input: ContractReceiptInput;
}>;


export type UpdateContractRefundMutation = (
  { __typename?: 'Mutation' }
  & { updateContractRefund: (
    { __typename?: 'ContractRefundEntity' }
    & Pick<ContractRefundEntity, 'ID'>
  ) }
);

export type SubmittedClaimCheckingQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
}>;


export type SubmittedClaimCheckingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'submittedClaimChecking'>
);

export type ContractVoCalculationQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type ContractVoCalculationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'contractVoCalculation'>
);

export type ApprovedClaimCheckingQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
}>;


export type ApprovedClaimCheckingQuery = (
  { __typename?: 'Query' }
  & { approvedClaimChecking: (
    { __typename?: 'ClaimSummary' }
    & Pick<ClaimSummary, 'previousClaim' | 'previousMOS' | 'previousVOClaim' | 'previousDeduction' | 'previousDayWork' | 'retentionPerc' | 'previousRetentionSum' | 'retentionBalance' | 'advanceSum' | 'advanceRecouped' | 'advanceBalance' | 'deductionSum' | 'deductionRecouped' | 'deductionBalance'>
  ) }
);

export type MakeContractRefundMutationVariables = Exact<{
  input: ContractReceiptInput;
  ledger: Array<LedgerInput> | LedgerInput;
}>;


export type MakeContractRefundMutation = (
  { __typename?: 'Mutation' }
  & { makeContractRefund: (
    { __typename?: 'ContractRefundEntity' }
    & Pick<ContractRefundEntity, 'ID'>
  ) }
);

export type MakeContractAllocationMutationVariables = Exact<{
  ledger: Array<LedgerInput> | LedgerInput;
}>;


export type MakeContractAllocationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'makeContractAllocation'>
);

export type GetContractCnAllocationQueryVariables = Exact<{
  creditID: Scalars['String'];
}>;


export type GetContractCnAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getContractCNAllocation'>
);

export type GetContractReceiptAllocationQueryVariables = Exact<{
  creditID: Scalars['String'];
}>;


export type GetContractReceiptAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getContractReceiptAllocation'>
);

export type GetContractRefundAllocationQueryVariables = Exact<{
  debitID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetContractRefundAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getContractRefundAllocation'>
);

export type GetContractAdvanceAllocationQueryVariables = Exact<{
  creditID: Scalars['String'];
}>;


export type GetContractAdvanceAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getContractAdvanceAllocation'>
);

export type CancelContractAdvanceMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelContractAdvanceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelContractAdvance'>
);

export type CancelContractDnMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelContractDnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelContractDN'>
);

export type CancelContractCnMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelContractCnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelContractCN'>
);

export type CancelContractReceiptMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelContractReceiptMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelContractReceipt'>
);

export type CancelContractRefundMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelContractRefundMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelContractRefund'>
);

export type CancelClientDnMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelClientDnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelClientDN'>
);

export type RejectClaimMutationVariables = Exact<{
  ID: Scalars['String'];
  input: RejectInput;
}>;


export type RejectClaimMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectClaim'>
);

export type DeleteContractAdvanceMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteContractAdvanceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteContractAdvance'>
);

export type DeleteContractCnMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteContractCnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteContractCN'>
);

export type DeleteContractDnMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteContractDnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteContractDN'>
);

export type DeleteContractClaimMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteContractClaimMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteContractClaim'>
);

export type DeleteContractReceiptMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteContractReceiptMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteContractReceipt'>
);

export type DeleteContractRefundMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteContractRefundMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteContractRefund'>
);

export type CreateNegotiatedSupplyItemMutationVariables = Exact<{
  negoItemInput: Array<NegotiatedSupplyItemInput> | NegotiatedSupplyItemInput;
  negoSupplyInput: NegotiatedSupplyInput;
}>;


export type CreateNegotiatedSupplyItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createNegotiatedSupply'>
);

export type UpdateNegotiatedSupplyItemMutationVariables = Exact<{
  negoItemInput: Array<NegotiatedSupplyItemInput> | NegotiatedSupplyItemInput;
  negoSupplyInput: NegotiatedSupplyInput;
  ID: Scalars['String'];
}>;


export type UpdateNegotiatedSupplyItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateNegotiatedSupply'>
);

export type CancelNegotiatedSupplyMutationVariables = Exact<{
  input: NegoSupplyCancellationInput;
  ID: Scalars['String'];
}>;


export type CancelNegotiatedSupplyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelNegotiatedSupply'>
);

export type GetNegotiatedSupplyItemQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
}>;


export type GetNegotiatedSupplyItemQuery = (
  { __typename?: 'Query' }
  & { getNegotiatedSupplyItem: Array<(
    { __typename?: 'NegotiatedSupplyItemEntity' }
    & Pick<NegotiatedSupplyItemEntity, 'ID' | 'negotiatedSupplyID' | 'costItemID' | 'uomID' | 'remarks' | 'quantity' | 'balanceQty' | 'unitPrice'>
    & { costItem?: Maybe<(
      { __typename?: 'CostItemEntity' }
      & Pick<CostItemEntity, 'ID' | 'name' | 'uomID'>
      & { costCategory?: Maybe<(
        { __typename?: 'CostCategoryEntity' }
        & Pick<CostCategoryEntity, 'ID' | 'name'>
        & { supplier: Array<(
          { __typename?: 'SupplierEntity' }
          & Pick<SupplierEntity, 'ID' | 'name' | 'associatedStatus'>
        )> }
      )> }
    )>, poItem?: Maybe<Array<(
      { __typename?: 'POItemEntity' }
      & Pick<PoItemEntity, 'ID' | 'orderedQty' | 'outstandingQty'>
    )>>, UOM?: Maybe<(
      { __typename?: 'UOMEntity' }
      & Pick<UomEntity, 'ID' | 'code' | 'name'>
    )>, negoSupply?: Maybe<(
      { __typename?: 'NegotiatedSupplyEntity' }
      & Pick<NegotiatedSupplyEntity, 'ID' | 'supplierID' | 'contractID' | 'docNo' | 'negoSupplyDate' | 'startDate' | 'endDate' | 'cancellationDate' | 'cancellationRemarks' | 'status'>
      & { supplier?: Maybe<(
        { __typename?: 'SupplierEntity' }
        & Pick<SupplierEntity, 'ID' | 'name'>
      )> }
    )> }
  )> }
);

export type CreateSupplierInvoiceMutationVariables = Exact<{
  invoiceInput: ApInvoiceInput;
  ledger: Array<LedgerInput> | LedgerInput;
  invDoItemMatchInput: Array<ApInvoiceItemInput> | ApInvoiceItemInput;
}>;


export type CreateSupplierInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createSupplierInvoice'>
);

export type UpdateSupplierInvoiceMutationVariables = Exact<{
  invoiceInput: ApInvoiceInput;
  ledger: Array<LedgerInput> | LedgerInput;
  invDoItemMatchInput: Array<ApInvoiceItemInput> | ApInvoiceItemInput;
}>;


export type UpdateSupplierInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateSupplierInvoice'>
);

export type GetApInvoiceQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
}>;


export type GetApInvoiceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'supplierInvInfo'>
  & { getAPInvoice: Array<(
    { __typename?: 'APInvoiceEntity' }
    & Pick<ApInvoiceEntity, 'ID' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'invoiceAmt' | 'discountAmt' | 'transactionStatus' | 'supplierID' | 'contractID' | 'subcontractID' | 'grnMatch' | 'outstanding'>
    & { supplierAllocation: Array<(
      { __typename?: 'APAllocationEntity' }
      & Pick<ApAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt'>
    )> }
  )> }
);

export type GetSupplierInvoiceQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
}>;


export type GetSupplierInvoiceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'supplierInvInfo'>
  & { getAPInvoice: Array<(
    { __typename?: 'APInvoiceEntity' }
    & Pick<ApInvoiceEntity, 'ID' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'discountAmt' | 'transactionStatus' | 'supplierID' | 'contractID' | 'subcontractID' | 'grnMatch' | 'outstanding'>
    & { supplierAllocation: Array<(
      { __typename?: 'APAllocationEntity' }
      & Pick<ApAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt'>
    )> }
  )>, getDO: Array<(
    { __typename?: 'DOEntity' }
    & Pick<DoEntity, 'ID' | 'doNo'>
  )> }
);

export type CreateApDebitNoteMutationVariables = Exact<{
  input: ApTransactionInput;
}>;


export type CreateApDebitNoteMutation = (
  { __typename?: 'Mutation' }
  & { createAPDebitNote: (
    { __typename?: 'APDebitNoteEntity' }
    & Pick<ApDebitNoteEntity, 'supplierID'>
  ) }
);

export type UpdateApDebitNoteMutationVariables = Exact<{
  input: ApTransactionInput;
}>;


export type UpdateApDebitNoteMutation = (
  { __typename?: 'Mutation' }
  & { updateAPDebitNote: (
    { __typename?: 'APDebitNoteEntity' }
    & Pick<ApDebitNoteEntity, 'supplierID'>
  ) }
);

export type GetApDebitNoteQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
}>;


export type GetApDebitNoteQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'supplierDNInfo'>
  & { getAPDebitNote: Array<(
    { __typename?: 'APDebitNoteEntity' }
    & Pick<ApDebitNoteEntity, 'ID' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'transactionStatus' | 'supplierID' | 'contractID' | 'subcontractID' | 'outstandingAmt'>
    & { supplierAllocation: Array<(
      { __typename?: 'APAllocationEntity' }
      & Pick<ApAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt'>
    )> }
  )> }
);

export type CreateApCreditNoteMutationVariables = Exact<{
  input: ApTransactionInput;
}>;


export type CreateApCreditNoteMutation = (
  { __typename?: 'Mutation' }
  & { createAPCreditNote: (
    { __typename?: 'APCreditNoteEntity' }
    & Pick<ApCreditNoteEntity, 'supplierID'>
  ) }
);

export type UpdateApCreditNoteMutationVariables = Exact<{
  input: ApTransactionInput;
}>;


export type UpdateApCreditNoteMutation = (
  { __typename?: 'Mutation' }
  & { updateAPCreditNote: (
    { __typename?: 'APCreditNoteEntity' }
    & Pick<ApCreditNoteEntity, 'supplierID'>
  ) }
);

export type GetApCreditNoteQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
}>;


export type GetApCreditNoteQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'supplierCNInfo'>
  & { getAPCreditNote: Array<(
    { __typename?: 'APCreditNoteEntity' }
    & Pick<ApCreditNoteEntity, 'ID' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'outstandingAmt' | 'transactionStatus' | 'supplierID' | 'contractID' | 'subcontractID'>
    & { supplierAllocation: Array<(
      { __typename?: 'APAllocationEntity' }
      & Pick<ApAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt'>
    )> }
  )> }
);

export type CreateDNtoSupplierMutationVariables = Exact<{
  input: ApTransactionInput;
}>;


export type CreateDNtoSupplierMutation = (
  { __typename?: 'Mutation' }
  & { createDNtoSupplier: (
    { __typename?: 'DNtoSupplierEntity' }
    & Pick<DNtoSupplierEntity, 'supplierID'>
  ) }
);

export type UpdateDNtoSupplierMutationVariables = Exact<{
  input: ApTransactionInput;
}>;


export type UpdateDNtoSupplierMutation = (
  { __typename?: 'Mutation' }
  & { updateDNtoSupplier: (
    { __typename?: 'DNtoSupplierEntity' }
    & Pick<DNtoSupplierEntity, 'supplierID'>
  ) }
);

export type GetDNtoSupplierQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
}>;


export type GetDNtoSupplierQuery = (
  { __typename?: 'Query' }
  & { getDNtoSupplier: Array<(
    { __typename?: 'DNtoSupplierEntity' }
    & Pick<DNtoSupplierEntity, 'ID' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'transactionStatus' | 'supplierID' | 'contractID' | 'subcontractID'>
  )> }
);

export type CreateApPaymentMutationVariables = Exact<{
  input: ApPaymentInput;
}>;


export type CreateApPaymentMutation = (
  { __typename?: 'Mutation' }
  & { createAPPayment: (
    { __typename?: 'APPaymentEntity' }
    & Pick<ApPaymentEntity, 'supplierID'>
  ) }
);

export type UpdateApPaymentMutationVariables = Exact<{
  input: ApPaymentInput;
}>;


export type UpdateApPaymentMutation = (
  { __typename?: 'Mutation' }
  & { updateAPPayment: (
    { __typename?: 'APPaymentEntity' }
    & Pick<ApPaymentEntity, 'supplierID'>
  ) }
);

export type GetApPaymentQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
}>;


export type GetApPaymentQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'supplierPaymentsInfo'>
  & { getAPPayment: Array<(
    { __typename?: 'APPaymentEntity' }
    & Pick<ApPaymentEntity, 'ID' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'bankAccountID' | 'bankDocAmt' | 'bankDetails' | 'receiptStatus' | 'supplierID' | 'contractID' | 'subcontractID'>
    & { supplierAllocation: Array<(
      { __typename?: 'APAllocationEntity' }
      & Pick<ApAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt'>
    )> }
  )> }
);

export type CreateApAdvanceMutationVariables = Exact<{
  input: ApPaymentInput;
}>;


export type CreateApAdvanceMutation = (
  { __typename?: 'Mutation' }
  & { createAPAdvance: (
    { __typename?: 'APAdvanceEntity' }
    & Pick<ApAdvanceEntity, 'supplierID'>
  ) }
);

export type UpdateApAdvanceMutationVariables = Exact<{
  input: ApPaymentInput;
}>;


export type UpdateApAdvanceMutation = (
  { __typename?: 'Mutation' }
  & { updateAPAdvance: (
    { __typename?: 'APAdvanceEntity' }
    & Pick<ApAdvanceEntity, 'supplierID'>
  ) }
);

export type GetApAdvanceQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
}>;


export type GetApAdvanceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'supplierDepositsInfo'>
  & { getAPAdvance: Array<(
    { __typename?: 'APAdvanceEntity' }
    & Pick<ApAdvanceEntity, 'ID' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'bankAccountID' | 'bankDocAmt' | 'bankDetails' | 'receiptStatus' | 'supplierID' | 'contractID' | 'subcontractID'>
    & { supplierAllocation: Array<(
      { __typename?: 'APAllocationEntity' }
      & Pick<ApAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt'>
    )> }
  )> }
);

export type GetSupplierPaymentTableQueryVariables = Exact<{
  supplierID?: Maybe<Scalars['String']>;
}>;


export type GetSupplierPaymentTableQuery = (
  { __typename?: 'Query' }
  & { getAPInvoice: Array<(
    { __typename?: 'APInvoiceEntity' }
    & Pick<ApInvoiceEntity, 'ID' | 'docNo' | 'docRef' | 'docDate' | 'docAmt' | 'baseAmt' | 'description' | 'outstanding'>
  )>, getAPDebitNote: Array<(
    { __typename?: 'APDebitNoteEntity' }
    & Pick<ApDebitNoteEntity, 'ID' | 'docNo' | 'docRef' | 'docDate' | 'docAmt' | 'baseAmt' | 'description' | 'outstandingAmt'>
  )> }
);

export type MakeSupplierPaymentMutationVariables = Exact<{
  input: ApPaymentInput;
  ledger: Array<LedgerInput> | LedgerInput;
}>;


export type MakeSupplierPaymentMutation = (
  { __typename?: 'Mutation' }
  & { makeSupplierPayment: (
    { __typename?: 'APPaymentEntity' }
    & Pick<ApPaymentEntity, 'ID'>
  ) }
);

export type MakeSupplierAllocationMutationVariables = Exact<{
  ledger: Array<LedgerInput> | LedgerInput;
}>;


export type MakeSupplierAllocationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'makeSupplierAllocation'>
);

export type GetSupplierDepositAllocationQueryVariables = Exact<{
  debitID: Scalars['String'];
}>;


export type GetSupplierDepositAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSupplierDepositAllocation'>
);

export type GetSupplierCnAllocationQueryVariables = Exact<{
  debitID: Scalars['String'];
}>;


export type GetSupplierCnAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSupplierCNAllocation'>
);

export type GetSupplierPaymentAllocationQueryVariables = Exact<{
  debitID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
}>;


export type GetSupplierPaymentAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSupplierPaymentAllocation'>
);

export type GetSupplierAccountExecutiveSummaryQueryVariables = Exact<{
  supplierID: Scalars['String'];
}>;


export type GetSupplierAccountExecutiveSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'suppProfileDate' | 'supplierDepositsInfo' | 'supplierInvInfo' | 'supplierDNInfo' | 'supplierCNInfo' | 'submittedSupplierPaymentNotification' | 'supplierPaymentsInfo' | 'outstandingSupplier' | 'unallocatedSupplier'>
);

export type CancelSupplierInvoiceMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelSupplierInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelSupplierInvoice'>
);

export type CancelSupplierDepositMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelSupplierDepositMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelSupplierDeposit'>
);

export type CancelSupplierDnMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelSupplierDnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelSupplierDN'>
);

export type CancelSupplierCnMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelSupplierCnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelSupplierCN'>
);

export type CancelSupplierPaymentMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelSupplierPaymentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelSupplierPayment'>
);

export type DeleteApAdvanceMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteApAdvanceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAPAdvance'>
);

export type DeleteApInvoiceMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteApInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAPInvoice'>
);

export type DeleteApCreditNoteMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteApCreditNoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAPCreditNote'>
);

export type DeleteApDebitNoteMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteApDebitNoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAPDebitNote'>
);

export type DeleteApPaymentMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteApPaymentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAPPayment'>
);

export type UpdateConTaxTypeMutationVariables = Exact<{
  input: ConTaxTypeInput;
}>;


export type UpdateConTaxTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateConTaxType: (
    { __typename?: 'ConTaxTypeEntity' }
    & Pick<ConTaxTypeEntity, 'ID' | 'code' | 'description'>
  ) }
);

export type CreateConTaxTypeMutationVariables = Exact<{
  input: ConTaxTypeInput;
}>;


export type CreateConTaxTypeMutation = (
  { __typename?: 'Mutation' }
  & { createConTaxType: (
    { __typename?: 'ConTaxTypeEntity' }
    & Pick<ConTaxTypeEntity, 'ID'>
  ) }
);

export type GetConTaxTypeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetConTaxTypeQuery = (
  { __typename?: 'Query' }
  & { getConTaxType: Array<(
    { __typename?: 'ConTaxTypeEntity' }
    & Pick<ConTaxTypeEntity, 'ID' | 'code' | 'commonStatus' | 'description' | 'createdTs'>
  )> }
);

export type DeleteConTaxTypeMutationVariables = Exact<{
  input: ConTaxTypeInput;
}>;


export type DeleteConTaxTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteConTaxType'>
);

export type CreateConTaxSchemeMutationVariables = Exact<{
  input: ConTaxSchemeInput;
}>;


export type CreateConTaxSchemeMutation = (
  { __typename?: 'Mutation' }
  & { createConTaxScheme: (
    { __typename?: 'ConTaxSchemeEntity' }
    & Pick<ConTaxSchemeEntity, 'ID'>
  ) }
);

export type UpdateConTaxSchemeMutationVariables = Exact<{
  input: ConTaxSchemeInput;
}>;


export type UpdateConTaxSchemeMutation = (
  { __typename?: 'Mutation' }
  & { updateConTaxScheme: (
    { __typename?: 'ConTaxSchemeEntity' }
    & Pick<ConTaxSchemeEntity, 'ID'>
  ) }
);

export type DeleteConTaxSchemeMutationVariables = Exact<{
  input: ConTaxSchemeInput;
}>;


export type DeleteConTaxSchemeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteConTaxScheme'>
);

export type CreateConTaxEffectiveDateMutationVariables = Exact<{
  input: ConTaxEffectiveDateInput;
}>;


export type CreateConTaxEffectiveDateMutation = (
  { __typename?: 'Mutation' }
  & { createConTaxEffectiveDate: (
    { __typename?: 'ConTaxEffectiveDateEntity' }
    & Pick<ConTaxEffectiveDateEntity, 'ID'>
  ) }
);

export type GetConTaxSchemeQueryVariables = Exact<{
  taxTypeID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
}>;


export type GetConTaxSchemeQuery = (
  { __typename?: 'Query' }
  & { getConTaxScheme: Array<(
    { __typename?: 'ConTaxSchemeEntity' }
    & Pick<ConTaxSchemeEntity, 'ID' | 'code' | 'description' | 'taxCategory' | 'taxTypeID' | 'taxClass' | 'createdTs' | 'commonStatus'>
    & { latestTax?: Maybe<(
      { __typename?: 'Tax' }
      & Pick<Tax, 'taxRate' | 'taxDate'>
    )>, taxEffective?: Maybe<Array<(
      { __typename?: 'ConTaxEffectiveDateEntity' }
      & Pick<ConTaxEffectiveDateEntity, 'date' | 'taxRate' | 'createdTs'>
    )>> }
  )> }
);

export type GetConTaxEffectiveDateQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
}>;


export type GetConTaxEffectiveDateQuery = (
  { __typename?: 'Query' }
  & { getConTaxEffectiveDate: Array<(
    { __typename?: 'ConTaxEffectiveDateEntity' }
    & Pick<ConTaxEffectiveDateEntity, 'ID' | 'taxSchemeID' | 'date' | 'taxRate'>
  )> }
);

export type InsertConTaxMutationVariables = Exact<{
  TaxEffectiveInput: Array<TaxEffectiveInput> | TaxEffectiveInput;
  ConTaxSchemeInput: ConTaxSchemeInput;
}>;


export type InsertConTaxMutation = (
  { __typename?: 'Mutation' }
  & { insertConTax: (
    { __typename?: 'ConTaxSchemeEntity' }
    & Pick<ConTaxSchemeEntity, 'ID'>
  ) }
);

export type UpdateConTaxSchemeWithEffectiveDateMutationVariables = Exact<{
  TaxEffectiveInput: Array<TaxEffectiveInput> | TaxEffectiveInput;
  ConTaxSchemeInput: ConTaxSchemeInput;
}>;


export type UpdateConTaxSchemeWithEffectiveDateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateConTax'>
);

export type CreateSupplierMutationVariables = Exact<{
  input: SupplierInput;
}>;


export type CreateSupplierMutation = (
  { __typename?: 'Mutation' }
  & { createSupplier: (
    { __typename?: 'SupplierEntity' }
    & Pick<SupplierEntity, 'name'>
  ) }
);

export type UpdateSupplierMutationVariables = Exact<{
  input: SupplierInput;
}>;


export type UpdateSupplierMutation = (
  { __typename?: 'Mutation' }
  & { updateSupplier: (
    { __typename?: 'SupplierEntity' }
    & Pick<SupplierEntity, 'name'>
  ) }
);

export type GetSupplierQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetSupplierQuery = (
  { __typename?: 'Query' }
  & { getSupplier: Array<(
    { __typename?: 'SupplierEntity' }
    & Pick<SupplierEntity, 'ID' | 'name' | 'registrationNo' | 'email' | 'address' | 'currencyID' | 'creditTerm' | 'contactNo' | 'description' | 'ratingType' | 'taxDetails' | 'taxTypeID' | 'associatedStatus' | 'bankDetails' | 'accountID'>
    & { contactPerson: Array<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'name' | 'designation' | 'email' | 'contactNo' | 'associateID'>
    )>, costCategory: Array<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'ID' | 'name'>
    )>, supplierInv?: Maybe<Array<(
      { __typename?: 'APInvoiceEntity' }
      & Pick<ApInvoiceEntity, 'ID' | 'transactionStatus' | 'outstanding'>
    )>> }
  )> }
);

export type GetSupplierListQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetSupplierListQuery = (
  { __typename?: 'Query' }
  & { getSupplier: Array<(
    { __typename?: 'SupplierEntity' }
    & Pick<SupplierEntity, 'ID' | 'name'>
  )> }
);

export type CreateSupplierCCategoryMutationVariables = Exact<{
  input: SupplierCCategoryInput;
}>;


export type CreateSupplierCCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createSupplierCCategory: (
    { __typename?: 'SupplierCCategoryEntity' }
    & Pick<SupplierCCategoryEntity, 'costCategoryID'>
  ) }
);

export type UpdateSupplierCCategoryMutationVariables = Exact<{
  input: SupplierCCategoryInput;
}>;


export type UpdateSupplierCCategoryMutation = (
  { __typename?: 'Mutation' }
  & { updateSupplierCCategory: (
    { __typename?: 'SupplierCCategoryEntity' }
    & Pick<SupplierCCategoryEntity, 'costCategoryID'>
  ) }
);

export type GetSupplierCCategoryQueryVariables = Exact<{
  supplierID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
}>;


export type GetSupplierCCategoryQuery = (
  { __typename?: 'Query' }
  & { getSupplierCCategory: Array<(
    { __typename?: 'SupplierCCategoryEntity' }
    & Pick<SupplierCCategoryEntity, 'supplierID' | 'costCategoryID'>
  )> }
);

export type AddCCategoryInSupplierMutationVariables = Exact<{
  supplierID: Scalars['String'];
  costCategoryID: Array<Scalars['String']> | Scalars['String'];
}>;


export type AddCCategoryInSupplierMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addCCategoryInSupplier'>
);

export type GetSupplierExecutiveSummaryQueryVariables = Exact<{
  supplierID: Scalars['String'];
}>;


export type GetSupplierExecutiveSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'supplierProfileDate' | 'PrefSupplierProductDate' | 'SupplierAccDate'>
);

export type GetSupplierTitleQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetSupplierTitleQuery = (
  { __typename?: 'Query' }
  & { getSupplier: Array<(
    { __typename?: 'SupplierEntity' }
    & Pick<SupplierEntity, 'ID' | 'name'>
  )> }
);

export type CashFlowSummaryQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type CashFlowSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'receivedFromClient' | 'paidToSubCon' | 'paidToSupplier' | 'miscExpense' | 'clientLastReceipt' | 'subconLastPayment' | 'supplierOSPayableAmount'>
  & { outstandingClaims: Array<(
    { __typename?: 'ContractClaimEntity' }
    & Pick<ContractClaimEntity, 'outstandingAmt'>
  )>, getSubcontractClaim: Array<(
    { __typename?: 'SubcontractClaimEntity' }
    & Pick<SubcontractClaimEntity, 'ID' | 'outstandingAmt'>
  )> }
);

export type GetReceiptsListQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type GetReceiptsListQuery = (
  { __typename?: 'Query' }
  & { financialStatus: (
    { __typename?: 'FinancialStatusDetail' }
    & Pick<FinancialStatusDetail, 'collectionListing' | 'collectionSum'>
  ) }
);

export type GetSubconPaymentsListQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type GetSubconPaymentsListQuery = (
  { __typename?: 'Query' }
  & { financialStatus: (
    { __typename?: 'FinancialStatusDetail' }
    & Pick<FinancialStatusDetail, 'subconPayment' | 'subconPaymentSum'>
  ) }
);

export type GetSupplierPaymentsListQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type GetSupplierPaymentsListQuery = (
  { __typename?: 'Query' }
  & { financialStatus: (
    { __typename?: 'FinancialStatusDetail' }
    & Pick<FinancialStatusDetail, 'supplierPayment' | 'supplierPaymentSum'>
  ) }
);

export type GetMiscExpensesListQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type GetMiscExpensesListQuery = (
  { __typename?: 'Query' }
  & { financialStatus: (
    { __typename?: 'FinancialStatusDetail' }
    & Pick<FinancialStatusDetail, 'miscExpense' | 'miscExpSum'>
  ) }
);

export type GetClientOsListQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type GetClientOsListQuery = (
  { __typename?: 'Query' }
  & { outstandingClaims: Array<(
    { __typename?: 'ContractClaimEntity' }
    & Pick<ContractClaimEntity, 'docAmt' | 'baseAmt' | 'outstandingAmt' | 'docDate' | 'description' | 'docNo' | 'ID'>
  )> }
);

export type GetSubconOsListQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type GetSubconOsListQuery = (
  { __typename?: 'Query' }
  & { getSubcontractClaim: Array<(
    { __typename?: 'SubcontractClaimEntity' }
    & Pick<SubcontractClaimEntity, 'docAmt' | 'baseAmt' | 'outstandingAmt' | 'docDate' | 'description' | 'docNo' | 'ID'>
  )> }
);

export type ProjectCostingQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type ProjectCostingQuery = (
  { __typename?: 'Query' }
  & { associateCalculation: (
    { __typename?: 'AssociatedDetail' }
    & Pick<AssociatedDetail, 'totalCost' | 'totalBalance' | 'revisedBudgetSubcontractSum' | 'subcontCostTotal' | 'materialCostTotal' | 'plantCostTotal' | 'miscCostTotal' | 'labourCostTotal' | 'subcontractBalance' | 'materialBalance' | 'plantBalance' | 'labourBalance' | 'miscBalance' | 'revisedBudgetMaterialSum' | 'revisedBudgetPlantSum' | 'revisedBudgetLabourSum' | 'revisedBudgetMiscSum' | 'revisedBudgetTotalSum' | 'revMiscItemBudget' | 'revPlantItemBudget' | 'revSubconItemBudget' | 'revLabourItemBudget' | 'revMaterialItemBudget' | 'originalBudgetMiscSum' | 'originalBudgetPlantSum' | 'originalBudgetLabourSum' | 'originalBudgetMaterialSum' | 'originalBudgetSubcontractSum' | 'originalBudgetTotalSum'>
  ) }
);

export type ProjectBudgetAnalysisQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type ProjectBudgetAnalysisQuery = (
  { __typename?: 'Query' }
  & { associateCalculation: (
    { __typename?: 'AssociatedDetail' }
    & Pick<AssociatedDetail, 'totalCost' | 'totalBalance' | 'latestDate' | 'originalBudgetTotalSum' | 'budgetVarianceAmt' | 'revisedBudgetTotalSum' | 'revisedBudgetMiscSum' | 'revisedBudgetPlantSum' | 'revisedBudgetLabourSum' | 'revisedBudgetMaterialSum' | 'revisedBudgetSubcontractSum' | 'originalBudgetMiscSum' | 'originalBudgetPlantSum' | 'originalBudgetLabourSum' | 'originalBudgetMaterialSum' | 'originalBudgetSubcontractSum' | 'origSubconItemBudget' | 'origMiscItemBudget' | 'origPlantItemBudget' | 'origLabourItemBudget' | 'origMaterialItemBudget' | 'revMiscItemBudget' | 'revPlantItemBudget' | 'revSubconItemBudget' | 'revLabourItemBudget' | 'revMaterialItemBudget' | 'materialCostTotal' | 'plantCostTotal' | 'labourCostTotal' | 'miscCostTotal' | 'subcontCostTotal'>
  ) }
);

export type BudgetVsActualQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type BudgetVsActualQuery = (
  { __typename?: 'Query' }
  & { associateCalculation: (
    { __typename?: 'AssociatedDetail' }
    & Pick<AssociatedDetail, 'revisedBudgetTotalSum' | 'originalBudgetTotalSum' | 'totalCost' | 'totalBalance' | 'revisedBudgetMiscSum' | 'revisedBudgetPlantSum' | 'revisedBudgetLabourSum' | 'revisedBudgetMaterialSum' | 'revisedBudgetSubcontractSum' | 'subconCostToDate' | 'materialCostToDate' | 'plantCostToDate' | 'labourCostToDate' | 'miscCostToDate' | 'originalBudgetMiscSum' | 'originalBudgetPlantSum' | 'originalBudgetLabourSum' | 'originalBudgetMaterialSum' | 'originalBudgetSubcontractSum' | 'miscCommCost' | 'plantCommCost' | 'subconCommCost' | 'labourCommCost' | 'materialCommCost' | 'miscCostTotal' | 'plantCostTotal' | 'labourCostTotal' | 'subcontCostTotal' | 'materialCostTotal' | 'subconBalToSpend' | 'materialBalToSpend' | 'plantBalToSpend' | 'labourBalToSpend' | 'miscBalToSpend'>
  ) }
);

export type ProjectCostPerformanceQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type ProjectCostPerformanceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'contractVoCalculation'>
  & { associateCalculation: (
    { __typename?: 'AssociatedDetail' }
    & Pick<AssociatedDetail, 'cpi' | 'completionEst' | 'completionPerc' | 'costVariance' | 'earnedValue' | 'totalCost' | 'revisedBudgetTotalSum' | 'revisedBudgetSubcontractSum'>
  ) }
);

export type GetProjectPlSummaryQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type GetProjectPlSummaryQuery = (
  { __typename?: 'Query' }
  & { projectPL: (
    { __typename?: 'PLDetail' }
    & Pick<PlDetail, 'miscCostTotal' | 'labourCostTotal' | 'plantCostTotal' | 'materialCostTotal' | 'subconCostTotal' | 'progressIncome' | 'totalPL' | 'totalCost' | 'origContractSum' | 'revisedContractSum' | 'voSum' | 'materialInvListing' | 'subconClaimListing'>
  ), getSubcontract: Array<(
    { __typename?: 'SubcontractEntity' }
    & Pick<SubcontractEntity, 'description' | 'subcontractSum' | 'subcontractNo' | 'createdTs' | 'ID'>
    & { subcontractorDetail?: Maybe<(
      { __typename?: 'SubcontractorEntity' }
      & Pick<SubcontractorEntity, 'name'>
    )> }
  )>, financialStatus: (
    { __typename?: 'FinancialStatusDetail' }
    & Pick<FinancialStatusDetail, 'paymentToDateSum' | 'collectionSum'>
  ), associateCalculation: (
    { __typename?: 'AssociatedDetail' }
    & Pick<AssociatedDetail, 'totalCost' | 'subcontCostTotal' | 'subcontractBalance'>
  ), outstandingClaims: Array<(
    { __typename?: 'ContractClaimEntity' }
    & Pick<ContractClaimEntity, 'outstandingAmt' | 'docDate' | 'description' | 'docNo'>
  )> }
);

export type GetProjectFinancialStatusQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type GetProjectFinancialStatusQuery = (
  { __typename?: 'Query' }
  & { financialStatus: (
    { __typename?: 'FinancialStatusDetail' }
    & Pick<FinancialStatusDetail, 'paymentToDateSum' | 'billToDateSum' | 'latestBillingDate' | 'latestMiscExpDate' | 'collectionSum' | 'latestCollectionDate' | 'latestSupplierInvDate' | 'latestSubconPaymentDate' | 'costToDateSum' | 'subconBillingSum' | 'supplierInvSum' | 'miscExpSum' | 'subconPaymentSum' | 'supplierPaymentSum' | 'latestSupplierPaymentDate'>
  ), associateCalculation: (
    { __typename?: 'AssociatedDetail' }
    & Pick<AssociatedDetail, 'revisedBudgetTotalSum'>
  ), projectPL: (
    { __typename?: 'PLDetail' }
    & Pick<PlDetail, 'revisedContractSum'>
  ) }
);

export type GetProjectFinancialStatusListQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type GetProjectFinancialStatusListQuery = (
  { __typename?: 'Query' }
  & { financialStatus: (
    { __typename?: 'FinancialStatusDetail' }
    & Pick<FinancialStatusDetail, 'conBilling' | 'collectionListing' | 'subconBilling' | 'supplierInvoice' | 'miscExpense' | 'subconPayment' | 'supplierPayment'>
  ) }
);

export type GetClientVoQueryVariables = Exact<{
  contractID: Scalars['String'];
  voStatus: VoStatus;
}>;


export type GetClientVoQuery = (
  { __typename?: 'Query' }
  & { getContractVO: Array<(
    { __typename?: 'ContractVOEntity' }
    & Pick<ContractVoEntity, 'approvedAmt' | 'submissionAmt' | 'VOType' | 'docNo' | 'docRef' | 'docDate' | 'description'>
  )> }
);

export type GetSubconVoQueryVariables = Exact<{
  subcontractID: Scalars['String'];
  voStatus: VoStatus;
}>;


export type GetSubconVoQuery = (
  { __typename?: 'Query' }
  & { getSubcontractVO: Array<(
    { __typename?: 'SubcontractVOEntity' }
    & Pick<SubcontractVoEntity, 'approvedAmt' | 'submissionAmt' | 'VOType' | 'docNo' | 'docRef' | 'docDate' | 'description'>
  )> }
);

export type GetProjectCostingBudgetQueryVariables = Exact<{
  ID: Scalars['String'];
  costClass: CostClass;
  costCategoryID?: Maybe<Scalars['String']>;
}>;


export type GetProjectCostingBudgetQuery = (
  { __typename?: 'Query' }
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'title'>
    & { costCateg: Array<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'ID' | 'costClass' | 'name'>
      & { budget: Array<(
        { __typename?: 'ProjectBudgetEntity' }
        & Pick<ProjectBudgetEntity, 'ID' | 'createdTs' | 'budgetAmt'>
      )> }
    )> }
  )>, associateCalculation: (
    { __typename?: 'AssociatedDetail' }
    & Pick<AssociatedDetail, 'revisedBudgetSubcontractSum' | 'subcontractBalance' | 'subcontCostTotal' | 'revisedBudgetMaterialSum' | 'materialBalance' | 'materialCostTotal' | 'revisedBudgetLabourSum' | 'labourBalance' | 'labourCostTotal' | 'revisedBudgetMiscSum' | 'miscBalance' | 'miscCostTotal' | 'revisedBudgetPlantSum' | 'plantBalance' | 'plantCostTotal' | 'originalBudgetMiscSum' | 'originalBudgetPlantSum' | 'originalBudgetLabourSum' | 'originalBudgetMaterialSum' | 'originalBudgetSubcontractSum'>
  ) }
);

export type GetBizMenuSummaryQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type GetBizMenuSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'budgetCalculation' | 'receivedFromClient' | 'paidToSubCon' | 'paidToSupplier' | 'miscExpense'>
  & { associateCalculation: (
    { __typename?: 'AssociatedDetail' }
    & Pick<AssociatedDetail, 'revisedBudgetTotalSum' | 'originalBudgetTotalSum' | 'totalBalance' | 'totalCost' | 'earnedValue' | 'costVariance'>
  ), financialStatus: (
    { __typename?: 'FinancialStatusDetail' }
    & Pick<FinancialStatusDetail, 'paymentToDateSum' | 'billToDateSum' | 'costToDateSum'>
  ), projectPL: (
    { __typename?: 'PLDetail' }
    & Pick<PlDetail, 'totalPL'>
  ) }
);

export type SetCompletionPercMutationVariables = Exact<{
  contractID: Scalars['String'];
  input: CompletionInput;
}>;


export type SetCompletionPercMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'completionPerc'>
);

export type DeleteBudgetCheckingQueryVariables = Exact<{
  contractID: Scalars['String'];
  costCategoryIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type DeleteBudgetCheckingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'deleteBudgetChecking'>
);

export type DeleteProjectBudgetMutationVariables = Exact<{
  input: ProjectBudgetDeleteInput;
}>;


export type DeleteProjectBudgetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteProjectBudget'>
);

export type GetContractQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<ContractStatus>;
}>;


export type GetContractQuery = (
  { __typename?: 'Query' }
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'title' | 'accountID' | 'contractNo' | 'customerID' | 'description' | 'contractDate' | 'contractSum' | 'voSum' | 'revisedContractSum' | 'taxSchemeID' | 'startDate' | 'endDate' | 'progressRetentionPerc' | 'maxRetentionPerc' | 'targetProfitPerc' | 'cmgdDate' | 'cpcDate' | 'dlpDate' | 'ladAmt' | 'ladRate' | 'creditTerm' | 'latestRunNum' | 'docNumChecking' | 'contractPOCounts' | 'contractStatus' | 'subcontractSummary' | 'contactPerson' | 'closureRemarks' | 'closureDate' | 'modBy' | 'extensionDate'>
    & { site?: Maybe<Array<(
      { __typename?: 'SiteEntity' }
      & Pick<SiteEntity, 'name' | 'contractID' | 'address' | 'ID'>
    )>>, customerDetail?: Maybe<(
      { __typename?: 'CustomerEntity' }
      & Pick<CustomerEntity, 'name' | 'address' | 'contactNo'>
      & { contactPerson: Array<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ID' | 'name' | 'designation' | 'email' | 'contactNo'>
      )> }
    )>, subcontractTenderDetail?: Maybe<Array<(
      { __typename?: 'SubcontractTenderEntity' }
      & Pick<SubcontractTenderEntity, 'ID' | 'title'>
    )>>, subcontractDetail?: Maybe<Array<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'ID' | 'title' | 'accountID' | 'contractID' | 'subcontractorID' | 'costCategoryID' | 'subcontractNo' | 'description' | 'subcontractDate' | 'taxSchemeID' | 'subcontractSum' | 'revisedSubcontractSum' | 'startDate' | 'endDate' | 'progressRetentionPerc' | 'maxRetentionPerc' | 'targetProfitPerc' | 'creditTerm' | 'subcontractStatus'>
      & { subcontractorDetail?: Maybe<(
        { __typename?: 'SubcontractorEntity' }
        & Pick<SubcontractorEntity, 'ID' | 'name' | 'associatedStatus'>
      )> }
    )>>, taxDetail?: Maybe<(
      { __typename?: 'ConTaxSchemeEntity' }
      & Pick<ConTaxSchemeEntity, 'ID' | 'code' | 'description' | 'taxCategory' | 'taxTypeID' | 'taxClass' | 'createdTs' | 'commonStatus'>
      & { latestTax?: Maybe<(
        { __typename?: 'Tax' }
        & Pick<Tax, 'taxRate' | 'taxDate'>
      )>, taxEffective?: Maybe<Array<(
        { __typename?: 'ConTaxEffectiveDateEntity' }
        & Pick<ConTaxEffectiveDateEntity, 'date' | 'taxRate' | 'createdTs'>
      )>> }
    )> }
  )> }
);

export type GetContractListingQueryVariables = Exact<{
  customerID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<ContractStatus>;
}>;


export type GetContractListingQuery = (
  { __typename?: 'Query' }
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'accountID' | 'customerID' | 'title' | 'contractNo' | 'description' | 'contractDate' | 'contractSum' | 'voSum' | 'revisedContractSum' | 'startDate' | 'endDate' | 'contractStatus'>
    & { customerDetail?: Maybe<(
      { __typename?: 'CustomerEntity' }
      & Pick<CustomerEntity, 'name' | 'address' | 'contactNo'>
      & { contactPerson: Array<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ID' | 'name' | 'designation' | 'email' | 'contactNo'>
      )> }
    )> }
  )> }
);

export type GetContractSubmenuQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetContractSubmenuQuery = (
  { __typename?: 'Query' }
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'title' | 'accountID' | 'contractNo' | 'customerID' | 'contractStatus' | 'cmgdDate' | 'cpcDate' | 'dlpDate' | 'contractSum' | 'revisedContractSum'>
  )>, getAllContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'title' | 'accountID' | 'contractNo' | 'customerID'>
  )> }
);

export type CreateContractMutationVariables = Exact<{
  input: ContractInput;
  siteInput: Array<SiteInput> | SiteInput;
}>;


export type CreateContractMutation = (
  { __typename?: 'Mutation' }
  & { createContract: (
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'contractNo' | 'title' | 'description' | 'contractSum' | 'taxSchemeID' | 'startDate' | 'endDate' | 'progressRetentionPerc' | 'maxRetentionPerc' | 'cmgdDate' | 'cpcDate' | 'creditTerm' | 'customerID' | 'accountID' | 'contractDate'>
  ) }
);

export type UpdateContractMutationVariables = Exact<{
  input: ContractInput;
}>;


export type UpdateContractMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateContract'>
);

export type GetContractGuaranteeQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetContractGuaranteeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'contractGuarantee'>
  & { getContractGuarantee: Array<(
    { __typename?: 'ContractGuaranteeEntity' }
    & Pick<ContractGuaranteeEntity, 'ID' | 'modBy' | 'accountID' | 'contractID' | 'docRef' | 'issuer' | 'guaranteeTypeID' | 'description' | 'docAmt' | 'startDate' | 'expiryDate' | 'collateralAmt' | 'remarks' | 'cancellationDate' | 'guaranteeStatus'>
    & { guaranteeType?: Maybe<(
      { __typename?: 'GuaranteeTypeEntity' }
      & Pick<GuaranteeTypeEntity, 'name'>
    )> }
  )> }
);

export type CreateContractGuaranteeMutationVariables = Exact<{
  input: ContractGuaranteeInput;
}>;


export type CreateContractGuaranteeMutation = (
  { __typename?: 'Mutation' }
  & { createContractGuarantee: (
    { __typename?: 'ContractGuaranteeEntity' }
    & Pick<ContractGuaranteeEntity, 'ID'>
  ) }
);

export type UpdateContractGuaranteeMutationVariables = Exact<{
  input: ContractGuaranteeInput;
}>;


export type UpdateContractGuaranteeMutation = (
  { __typename?: 'Mutation' }
  & { updateContractGuarantee: (
    { __typename?: 'ContractGuaranteeEntity' }
    & Pick<ContractGuaranteeEntity, 'ID'>
  ) }
);

export type GetContractInsuranceQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
}>;


export type GetContractInsuranceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'contractInsurance'>
  & { getContractInsurance: Array<(
    { __typename?: 'ContractInsuranceEntity' }
    & Pick<ContractInsuranceEntity, 'ID' | 'modBy' | 'accountID' | 'contractID' | 'docRef' | 'issuer' | 'insuranceTypeID' | 'description' | 'docAmt' | 'startDate' | 'expiryDate' | 'remarks' | 'cancellationDate' | 'insuranceStatus'>
    & { insuranceType?: Maybe<(
      { __typename?: 'InsuranceTypeEntity' }
      & Pick<InsuranceTypeEntity, 'name'>
    )> }
  )> }
);

export type CreateContractInsuranceMutationVariables = Exact<{
  input: ContractInsuranceInput;
}>;


export type CreateContractInsuranceMutation = (
  { __typename?: 'Mutation' }
  & { createContractInsurance: (
    { __typename?: 'ContractInsuranceEntity' }
    & Pick<ContractInsuranceEntity, 'ID'>
  ) }
);

export type UpdateContractInsuranceMutationVariables = Exact<{
  input: ContractInsuranceInput;
}>;


export type UpdateContractInsuranceMutation = (
  { __typename?: 'Mutation' }
  & { updateContractInsurance: (
    { __typename?: 'ContractInsuranceEntity' }
    & Pick<ContractInsuranceEntity, 'ID'>
  ) }
);

export type ActionOnContractStatusMutationVariables = Exact<{
  input: ContractActionInput;
  ID: Scalars['String'];
}>;


export type ActionOnContractStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'actionOnContractStatus'>
);

export type CreateContractExtensionDateMutationVariables = Exact<{
  input: ContractExtensionInput;
  ID: Scalars['String'];
}>;


export type CreateContractExtensionDateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createContractExtensionDate'>
);

export type CancelContractGuaranteeMutationVariables = Exact<{
  input: ContractGuaranteeCancellationInput;
}>;


export type CancelContractGuaranteeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelContractGuarantee'>
);

export type CancelContractInsuranceMutationVariables = Exact<{
  input: ContractInsuranceCancellationInput;
}>;


export type CancelContractInsuranceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelContractInsurance'>
);

export type GetContractSummaryQueryVariables = Exact<{
  contractID: Scalars['String'];
  type?: Maybe<Scalars['String']>;
}>;


export type GetContractSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'contractGuarantee' | 'contractInsurance' | 'submittedVONotification' | 'lastExtensionDate' | 'contractClosureDate' | 'contractVoCalculation' | 'totalDigitalDocuments'>
  & { contractInfo: (
    { __typename?: 'ContractInfo' }
    & Pick<ContractInfo, 'contractSum' | 'contractDate'>
  ), contractVO: (
    { __typename?: 'ContractVO' }
    & Pick<ContractVo, 'voSum' | 'voOmission' | 'voSumCount' | 'voOmissionCount' | 'voClientCount' | 'voSubconCount' | 'totalVoAmt'>
  ), outstandingAdvances: Array<(
    { __typename?: 'ContractAdvanceEntity' }
    & Pick<ContractAdvanceEntity, 'docNo' | 'docDate' | 'docAmt' | 'baseAmt' | 'outstandingAmt'>
  )>, outstandingClaims: Array<(
    { __typename?: 'ContractClaimEntity' }
    & Pick<ContractClaimEntity, 'docNo' | 'docDate' | 'docAmt' | 'baseAmt' | 'outstandingAmt'>
  )>, retention: (
    { __typename?: 'RetentionInfo' }
    & Pick<RetentionInfo, 'retentionSum' | 'retentionBalance' | 'releasedSum'>
  ) }
);

export type CreateContractCertificateMutationVariables = Exact<{
  input: ContractCertificateInput;
  ID: Scalars['String'];
}>;


export type CreateContractCertificateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createContractCertificate'>
);

export type GetContractExtensionQueryVariables = Exact<{
  ID: Scalars['String'];
  customerID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetContractExtensionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'lastExtensionDate'>
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'title' | 'accountID' | 'contractNo' | 'customerID' | 'description' | 'contractDate' | 'contractSum' | 'taxSchemeID' | 'startDate' | 'endDate' | 'creditTerm' | 'contractStatus' | 'extensionDate'>
    & { customerDetail?: Maybe<(
      { __typename?: 'CustomerEntity' }
      & Pick<CustomerEntity, 'name' | 'contactNo'>
      & { contactPerson: Array<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ID' | 'name' | 'designation' | 'email' | 'contactNo'>
      )> }
    )> }
  )> }
);

export type GetContractTitleQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
}>;


export type GetContractTitleQuery = (
  { __typename?: 'Query' }
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'title' | 'contractNo' | 'contractStatus' | 'cmgdDate' | 'cpcDate' | 'dlpDate'>
  )> }
);

export type InsertUpdateSiteMutationVariables = Exact<{
  siteInput: Array<SiteInput> | SiteInput;
  contractID: Scalars['String'];
}>;


export type InsertUpdateSiteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'insertUpdateSite'>
);

export type AssociateCheckingQueryVariables = Exact<{
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type AssociateCheckingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'associateChecking' | 'voChecking'>
);

export type GetCustomerForContractQueryVariables = Exact<{
  associatedStatus?: Maybe<Scalars['String']>;
}>;


export type GetCustomerForContractQuery = (
  { __typename?: 'Query' }
  & { getCustomer: Array<(
    { __typename?: 'CustomerEntity' }
    & Pick<CustomerEntity, 'name' | 'ID' | 'associatedStatus'>
  )> }
);

export type CreateDefectMgmtMutationVariables = Exact<{
  input: DefectManagementInput;
}>;


export type CreateDefectMgmtMutation = (
  { __typename?: 'Mutation' }
  & { createDefectMgmt: (
    { __typename?: 'DefectManagementEntity' }
    & Pick<DefectManagementEntity, 'ID'>
  ) }
);

export type UpdateDefectMgmtMutationVariables = Exact<{
  input: DefectManagementInput;
}>;


export type UpdateDefectMgmtMutation = (
  { __typename?: 'Mutation' }
  & { updateDefectMgmt: (
    { __typename?: 'DefectManagementEntity' }
    & Pick<DefectManagementEntity, 'ID'>
  ) }
);

export type CreateActivityLogMutationVariables = Exact<{
  input: DefectLogInput;
  defectID: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
}>;


export type CreateActivityLogMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createActivityLog'>
);

export type AssignDefectMutationVariables = Exact<{
  input: DefectAssignmentInput;
  defectID: Scalars['String'];
}>;


export type AssignDefectMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'assignDefect'>
);

export type ReOpenDefectMutationVariables = Exact<{
  input: ReOpenDefectInput;
  defectID: Scalars['String'];
}>;


export type ReOpenDefectMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'reOpenDefect'>
);

export type GetDefectMgmtQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  defectStatus?: Maybe<DefectStatus>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetDefectMgmtQuery = (
  { __typename?: 'Query' }
  & { getDefectMgmt: Array<(
    { __typename?: 'DefectManagementEntity' }
    & Pick<DefectManagementEntity, 'ID' | 'remarks' | 'title' | 'defectStatus' | 'contractID' | 'subcontractorID' | 'createdTs' | 'createdBy' | 'docNo' | 'defectLog'>
    & { subcontractor?: Maybe<(
      { __typename?: 'SubcontractorEntity' }
      & Pick<SubcontractorEntity, 'name'>
    )> }
  )> }
);

export type GetDigitalDocumentQueryVariables = Exact<{
  associateID: Scalars['String'];
  type: Scalars['String'];
}>;


export type GetDigitalDocumentQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getDigitalDocument'>
);

export type GetTableDocumentsQueryVariables = Exact<{
  refID: Array<Scalars['String']> | Scalars['String'];
  associateID: Scalars['String'];
  refTable: Scalars['String'];
  description?: Maybe<Scalars['String']>;
}>;


export type GetTableDocumentsQuery = (
  { __typename?: 'Query' }
  & { getTableDocuments: Array<(
    { __typename?: 'DigitalDocuments' }
    & Pick<DigitalDocuments, 'ID' | 'refID' | 'fileName' | 'fileURL' | 'mediaType' | 'bucketFileName' | 'description' | 'size' | 'createdTs'>
  )> }
);

export type AddDigitalDocumentsMutationVariables = Exact<{
  input: DigitalDocumentInput;
}>;


export type AddDigitalDocumentsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addDigitalDocuments'>
);

export type DeleteDigitalDocumentsMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteDigitalDocumentsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDigitalDocuments'>
);

export type GetContractSiteQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetContractSiteQuery = (
  { __typename?: 'Query' }
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'title' | 'accountID' | 'contractNo' | 'customerID' | 'description' | 'contractDate' | 'contractSum' | 'startDate' | 'endDate' | 'contractStatus'>
    & { site?: Maybe<Array<(
      { __typename?: 'SiteEntity' }
      & Pick<SiteEntity, 'name' | 'contractID' | 'address' | 'ID'>
    )>> }
  )> }
);

export type GetSiteContractQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetSiteContractQuery = (
  { __typename?: 'Query' }
  & { getSite: Array<(
    { __typename?: 'SiteEntity' }
    & Pick<SiteEntity, 'ID' | 'contractID' | 'name' | 'address'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title' | 'description' | 'contractSum' | 'contractStatus'>
    )> }
  )> }
);

export type SiteManagementExecSummaryQueryVariables = Exact<{
  siteID: Scalars['String'];
}>;


export type SiteManagementExecSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'siteWorkerAttendanceInfo' | 'DOCount' | 'MOSCount' | 'lastSiteProgressDate'>
);

export type GetSiteWorkersQueryVariables = Exact<{
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
}>;


export type GetSiteWorkersQuery = (
  { __typename?: 'Query' }
  & { getSiteWorkers: Array<(
    { __typename?: 'WorkerEntity' }
    & Pick<WorkerEntity, 'ID' | 'contactID' | 'accountID' | 'siteID' | 'nationality' | 'commonStatus' | 'modTs'>
    & { site?: Maybe<(
      { __typename?: 'SiteEntity' }
      & Pick<SiteEntity, 'name'>
      & { contract?: Maybe<(
        { __typename?: 'ContractEntity' }
        & Pick<ContractEntity, 'title'>
      )> }
    )> }
  )> }
);

export type GetSiteProgressQueryVariables = Exact<{
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
}>;


export type GetSiteProgressQuery = (
  { __typename?: 'Query' }
  & { getSiteProgress: Array<(
    { __typename?: 'SiteProgressEntity' }
    & Pick<SiteProgressEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'ID' | 'accountID' | 'contractID' | 'title' | 'docDate' | 'imagesInfo'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'title'>
    )> }
  )> }
);

export type GetThumbnailDocumentListingQueryVariables = Exact<{
  refID?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
}>;


export type GetThumbnailDocumentListingQuery = (
  { __typename?: 'Query' }
  & { DocumentListing?: Maybe<Array<(
    { __typename?: 'DocumentsEntity' }
    & Pick<DocumentsEntity, 'ID' | 'mediaType' | 'refTable' | 'refID' | 'description' | 'fileName' | 'fileURL' | 'accountID' | 'bucketFileName' | 'opacity'>
  )>> }
);

export type CreateSiteProgressMutationVariables = Exact<{
  input: SiteProgressInput;
}>;


export type CreateSiteProgressMutation = (
  { __typename?: 'Mutation' }
  & { createSiteProgress: (
    { __typename?: 'SiteProgressEntity' }
    & Pick<SiteProgressEntity, 'ID' | 'title'>
  ) }
);

export type UpdateSiteProgressMutationVariables = Exact<{
  input: SiteProgressInput;
}>;


export type UpdateSiteProgressMutation = (
  { __typename?: 'Mutation' }
  & { updateSiteProgress: (
    { __typename?: 'SiteProgressEntity' }
    & Pick<SiteProgressEntity, 'ID' | 'title'>
  ) }
);

export type DeleteSiteProgressMutationVariables = Exact<{
  input: SiteProgressInput;
}>;


export type DeleteSiteProgressMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSiteProgress'>
);

export type GetSiteStockItemsQueryVariables = Exact<{
  siteID: Scalars['String'];
}>;


export type GetSiteStockItemsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSiteStockItems'>
);

export type GetStockItemDetailQueryVariables = Exact<{
  siteID: Scalars['String'];
  costItemID: Scalars['String'];
  uomID: Scalars['String'];
}>;


export type GetStockItemDetailQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getStockItemDetail'>
);

export type GetSiteStockLedgerQueryVariables = Exact<{
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
}>;


export type GetSiteStockLedgerQuery = (
  { __typename?: 'Query' }
  & { getSiteStockLedger: Array<(
    { __typename?: 'SiteStockLedgerEntity' }
    & Pick<SiteStockLedgerEntity, 'ID' | 'trackingDate' | 'remarks' | 'docRef' | 'quantity' | 'trxType' | 'siteID' | 'costItemID' | 'createdBy' | 'createdTs'>
    & { site?: Maybe<(
      { __typename?: 'SiteEntity' }
      & Pick<SiteEntity, 'ID' | 'name' | 'address'>
    )>, costItem?: Maybe<(
      { __typename?: 'CostItemEntity' }
      & Pick<CostItemEntity, 'ID' | 'name'>
    )>, uom?: Maybe<(
      { __typename?: 'UOMEntity' }
      & Pick<UomEntity, 'ID' | 'name' | 'code'>
    )> }
  )> }
);

export type CreateSiteStockLedgerMutationVariables = Exact<{
  input: SiteStockLedgerInput;
}>;


export type CreateSiteStockLedgerMutation = (
  { __typename?: 'Mutation' }
  & { createSiteStockLedger: (
    { __typename?: 'SiteStockLedgerEntity' }
    & Pick<SiteStockLedgerEntity, 'ID'>
  ) }
);

export type UpdateSiteStockLedgerMutationVariables = Exact<{
  input: SiteStockLedgerInput;
}>;


export type UpdateSiteStockLedgerMutation = (
  { __typename?: 'Mutation' }
  & { updateSiteStockLedger: (
    { __typename?: 'SiteStockLedgerEntity' }
    & Pick<SiteStockLedgerEntity, 'ID'>
  ) }
);

export type DeleteSiteStockLedgerMutationVariables = Exact<{
  input: SiteStockLedgerInput;
}>;


export type DeleteSiteStockLedgerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSiteStockLedger'>
);

export type GetSiteWorkerAttendanceQueryVariables = Exact<{
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  siteWorkerID?: Maybe<Scalars['String']>;
}>;


export type GetSiteWorkerAttendanceQuery = (
  { __typename?: 'Query' }
  & { getSiteWorkerAttendance: Array<(
    { __typename?: 'WorkerAttendanceEntity' }
    & Pick<WorkerAttendanceEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'accountID' | 'workerID' | 'siteID' | 'timeIn' | 'timeOut'>
    & { worker?: Maybe<(
      { __typename?: 'WorkerEntity' }
      & Pick<WorkerEntity, 'ID' | 'siteID' | 'nationality' | 'commonStatus'>
    )> }
  )> }
);

export type GetSiteQueryVariables = Exact<{
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetSiteQuery = (
  { __typename?: 'Query' }
  & { getSite: Array<(
    { __typename?: 'SiteEntity' }
    & Pick<SiteEntity, 'ID' | 'accountID' | 'contractID' | 'name' | 'address'>
  )> }
);

export type CreateSiteWorkersMutationVariables = Exact<{
  input: SiteWorkersInput;
}>;


export type CreateSiteWorkersMutation = (
  { __typename?: 'Mutation' }
  & { createSiteWorkers: (
    { __typename?: 'WorkerEntity' }
    & Pick<WorkerEntity, 'ID'>
  ) }
);

export type UpdateSiteWorkersMutationVariables = Exact<{
  input: SiteWorkersInput;
}>;


export type UpdateSiteWorkersMutation = (
  { __typename?: 'Mutation' }
  & { updateSiteWorkers: (
    { __typename?: 'WorkerEntity' }
    & Pick<WorkerEntity, 'ID'>
  ) }
);

export type GetSubcontractClaimQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractClaimQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'subconClaimsInfo'>
  & { getSubcontractClaim: Array<(
    { __typename?: 'SubcontractClaimEntity' }
    & Pick<SubcontractClaimEntity, 'ID' | 'docNo' | 'claimDocNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'docRefID' | 'docRefTable' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'submittedDate' | 'previousCertifiedAmt' | 'submittedCertifiedAmt' | 'currentCertifiedAmt' | 'previousVOClaimAmt' | 'submittedVOClaimAmt' | 'currentVOClaimAmt' | 'previousMosAmt' | 'submittedMosAmt' | 'currentMosAmt' | 'retentionAmt' | 'submittedRetentionAmt' | 'balanceRetentionAmt' | 'submittedDayWorkAmt' | 'currentDayWorkAmt' | 'balanceAdvanceAmt' | 'currentAdvRecoupmentAmt' | 'currentDeductionAmt' | 'submittedDeductionAmt' | 'balanceDeductionAmt' | 'transactionStatus' | 'contractID' | 'subcontractorID' | 'subcontractID' | 'rejectedDate' | 'rejectionRemarks' | 'cancellationDate' | 'cancellationRemarks' | 'voDisplayAmt' | 'outstandingAmt' | 'modBy' | 'createdBy' | 'adjustmentInfo'>
    & { subconAllocation: Array<(
      { __typename?: 'SubcontractAllocationEntity' }
      & Pick<SubcontractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type GetSubconProgressClaimQueryVariables = Exact<{
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
}>;


export type GetSubconProgressClaimQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'subconProgressClaimsInfo'>
  & { getSubconProgressClaim: Array<(
    { __typename?: 'SubcontractClaimEntity' }
    & Pick<SubcontractClaimEntity, 'ID' | 'isGlExport' | 'accountID' | 'balanceAdvanceAmt' | 'balanceDeductionAmt' | 'balanceRetentionAmt' | 'baseAmt' | 'cancellationDate' | 'cancellationRemarks' | 'claimDocNo' | 'contractID' | 'createdBy' | 'createdTs' | 'currencyID' | 'currentAdvRecoupmentAmt' | 'currentCertifiedAmt' | 'currentDayWorkAmt' | 'currentDeductionAmt' | 'currentMosAmt' | 'currentVOClaimAmt' | 'description' | 'docAmt' | 'docDate' | 'docDueDate' | 'docNo' | 'docRef' | 'docRefID' | 'docRefTable' | 'exchangeRate' | 'localBaseAmt' | 'localDocAmt' | 'localTaxAmt' | 'modBy' | 'modTs' | 'previousCertifiedAmt' | 'previousMosAmt' | 'previousVOClaimAmt' | 'rejectedDate' | 'rejectionDate' | 'rejectionRemarks' | 'retentionAmt' | 'revisedNo' | 'sequenceNo' | 'subcontractID' | 'subcontractorID' | 'submittedCertifiedAmt' | 'submittedDate' | 'submittedDayWorkAmt' | 'submittedDeductionAmt' | 'submittedMosAmt' | 'submittedRetentionAmt' | 'submittedVOClaimAmt' | 'taxAmt' | 'taxRate' | 'taxSchemeID' | 'transactionDate' | 'transactionStatus' | 'voDisplayAmt'>
    & { toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'approvalLevel' | 'body' | 'createdBy' | 'createdTs' | 'docRefAmt' | 'docRefID' | 'docRefTable' | 'modBy' | 'modTs' | 'notifiedDate' | 'subject' | 'submitterID' | 'toDoStatus'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'approverID' | 'createdBy' | 'createdTs' | 'logDate' | 'modBy' | 'modTs' | 'notifiedDate' | 'remarks' | 'toDoID' | 'toDoStatus'>
      )>> }
    )> }
  )> }
);

export type GetSubconRetentionClaimQueryVariables = Exact<{
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
}>;


export type GetSubconRetentionClaimQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'subconRetentionClaimsInfo'>
  & { getSubconRetentionClaim: Array<(
    { __typename?: 'SubcontractClaimEntity' }
    & Pick<SubcontractClaimEntity, 'ID' | 'isGlExport' | 'accountID' | 'balanceAdvanceAmt' | 'balanceDeductionAmt' | 'balanceRetentionAmt' | 'baseAmt' | 'cancellationDate' | 'cancellationRemarks' | 'claimDocNo' | 'contractID' | 'createdBy' | 'createdTs' | 'currencyID' | 'currentAdvRecoupmentAmt' | 'currentCertifiedAmt' | 'currentDayWorkAmt' | 'currentDeductionAmt' | 'currentMosAmt' | 'currentVOClaimAmt' | 'description' | 'docAmt' | 'docDate' | 'docDueDate' | 'docNo' | 'docRef' | 'docRefID' | 'docRefTable' | 'exchangeRate' | 'localBaseAmt' | 'localDocAmt' | 'localTaxAmt' | 'modBy' | 'modTs' | 'previousCertifiedAmt' | 'previousMosAmt' | 'previousVOClaimAmt' | 'rejectedDate' | 'rejectionDate' | 'rejectionRemarks' | 'retentionAmt' | 'revisedNo' | 'sequenceNo' | 'subcontractID' | 'subcontractorID' | 'submittedCertifiedAmt' | 'submittedDate' | 'submittedDayWorkAmt' | 'submittedDeductionAmt' | 'submittedMosAmt' | 'submittedRetentionAmt' | 'submittedVOClaimAmt' | 'taxAmt' | 'taxRate' | 'taxSchemeID' | 'transactionDate' | 'transactionStatus' | 'voDisplayAmt'>
    & { toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'approvalLevel' | 'body' | 'createdBy' | 'createdTs' | 'docRefAmt' | 'docRefID' | 'docRefTable' | 'modBy' | 'modTs' | 'notifiedDate' | 'subject' | 'submitterID' | 'toDoStatus'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'approverID' | 'createdBy' | 'createdTs' | 'logDate' | 'modBy' | 'modTs' | 'notifiedDate' | 'remarks' | 'toDoID' | 'toDoStatus'>
      )>> }
    )> }
  )> }
);

export type SubmitSubconRetentionClaimMutationVariables = Exact<{
  input: SubconClaimRetentionInput;
}>;


export type SubmitSubconRetentionClaimMutation = (
  { __typename?: 'Mutation' }
  & { submitSubconRetentionClaim: (
    { __typename?: 'SubcontractClaimEntity' }
    & Pick<SubcontractClaimEntity, 'ID' | 'claimDocNo'>
  ) }
);

export type ApproveSubconRetentionClaimMutationVariables = Exact<{
  input: SubconClaimRetentionInput;
}>;


export type ApproveSubconRetentionClaimMutation = (
  { __typename?: 'Mutation' }
  & { approveSubconRetentionClaim: (
    { __typename?: 'SubcontractClaimEntity' }
    & Pick<SubcontractClaimEntity, 'ID' | 'docNo'>
  ) }
);

export type CreateSubcontractClaimMutationVariables = Exact<{
  input: SubcontractClaimInput;
}>;


export type CreateSubcontractClaimMutation = (
  { __typename?: 'Mutation' }
  & { createSubcontractClaim: (
    { __typename?: 'SubcontractClaimEntity' }
    & Pick<SubcontractClaimEntity, 'accountID'>
  ) }
);

export type UpdateSubcontractClaimMutationVariables = Exact<{
  input: SubcontractClaimInput;
}>;


export type UpdateSubcontractClaimMutation = (
  { __typename?: 'Mutation' }
  & { updateSubcontractClaim: (
    { __typename?: 'SubcontractClaimEntity' }
    & Pick<SubcontractClaimEntity, 'accountID' | 'ID'>
  ) }
);

export type SubmitSubconClaimMutationVariables = Exact<{
  input: SubcontractClaimInput;
  deductionLedger?: Maybe<Array<LedgerInput> | LedgerInput>;
}>;


export type SubmitSubconClaimMutation = (
  { __typename?: 'Mutation' }
  & { submitSubconClaim: (
    { __typename?: 'SubcontractClaimEntity' }
    & Pick<SubcontractClaimEntity, 'ID' | 'docNo'>
  ) }
);

export type ApproveSubconClaimMutationVariables = Exact<{
  input: SubcontractClaimInput;
}>;


export type ApproveSubconClaimMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'approveSubconClaim'>
);

export type CancelSubconClaimMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
  retentionClaim?: Maybe<Scalars['Boolean']>;
}>;


export type CancelSubconClaimMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelSubconClaim'>
);

export type RejectSubconClaimMutationVariables = Exact<{
  ID: Scalars['String'];
  input: RejectInput;
  retentionClaim?: Maybe<Scalars['Boolean']>;
}>;


export type RejectSubconClaimMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectSubconClaim'>
);

export type DeleteSubcontractClaimMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteSubcontractClaimMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSubcontractClaim'>
);

export type GetSubconRetClaimAllocationQueryVariables = Exact<{
  creditID: Scalars['String'];
}>;


export type GetSubconRetClaimAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSubconRetClaimAllocation'>
);

export type GetSubcontractAdvanceQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
}>;


export type GetSubcontractAdvanceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'subconAdvancesInfo'>
  & { getSubcontractAdvance: Array<(
    { __typename?: 'SubcontractAdvanceEntity' }
    & Pick<SubcontractAdvanceEntity, 'ID' | 'isGlExport' | 'isRecoupment' | 'modBy' | 'cancellationRemarks' | 'cancellationDate' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'bankAccountID' | 'bankDocAmt' | 'bankDetails' | 'receiptStatus' | 'subcontractorID' | 'contractID' | 'subcontractID' | 'outstandingAmt'>
    & { subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'ID' | 'title'>
    )>, subconAllocation: Array<(
      { __typename?: 'SubcontractAllocationEntity' }
      & Pick<SubcontractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type CreateSubcontractAdvanceMutationVariables = Exact<{
  input: SubcontractPaymentInput;
}>;


export type CreateSubcontractAdvanceMutation = (
  { __typename?: 'Mutation' }
  & { createSubcontractAdvance: (
    { __typename?: 'SubcontractAdvanceEntity' }
    & Pick<SubcontractAdvanceEntity, 'ID' | 'docNo'>
  ) }
);

export type UpdateSubcontractAdvanceMutationVariables = Exact<{
  input: SubcontractPaymentInput;
}>;


export type UpdateSubcontractAdvanceMutation = (
  { __typename?: 'Mutation' }
  & { updateSubcontractAdvance: (
    { __typename?: 'SubcontractAdvanceEntity' }
    & Pick<SubcontractAdvanceEntity, 'ID' | 'docNo'>
  ) }
);

export type GetSubcontractPaymentQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractPaymentQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'subconPaymentInfo'>
  & { getSubcontractPayment: Array<(
    { __typename?: 'SubcontractPaymentEntity' }
    & Pick<SubcontractPaymentEntity, 'ID' | 'modBy' | 'isGlExport' | 'cancellationRemarks' | 'cancellationDate' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'bankAccountID' | 'bankDocAmt' | 'bankDetails' | 'receiptStatus' | 'subcontractorID' | 'contractID' | 'subcontractID' | 'outstandingAmt'>
    & { subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'title'>
    )>, subconAllocation: Array<(
      { __typename?: 'SubcontractAllocationEntity' }
      & Pick<SubcontractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type CreateSubcontractPaymentMutationVariables = Exact<{
  input: SubcontractPaymentInput;
}>;


export type CreateSubcontractPaymentMutation = (
  { __typename?: 'Mutation' }
  & { createSubcontractPayment: (
    { __typename?: 'SubcontractPaymentEntity' }
    & Pick<SubcontractPaymentEntity, 'ID'>
  ) }
);

export type UpdateSubcontractPaymentMutationVariables = Exact<{
  input: SubcontractPaymentInput;
}>;


export type UpdateSubcontractPaymentMutation = (
  { __typename?: 'Mutation' }
  & { updateSubcontractPayment: (
    { __typename?: 'SubcontractPaymentEntity' }
    & Pick<SubcontractPaymentEntity, 'ID'>
  ) }
);

export type GetSubcontractRefundQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractRefundQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'subconRefundsInfo'>
  & { getSubcontractRefund: Array<(
    { __typename?: 'SubcontractRefundEntity' }
    & Pick<SubcontractRefundEntity, 'ID' | 'modBy' | 'isGlExport' | 'cancellationRemarks' | 'cancellationDate' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'bankAccountID' | 'bankDocAmt' | 'bankDetails' | 'receiptStatus' | 'subcontractorID' | 'contractID' | 'subcontractID' | 'outstandingAmt'>
    & { subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'title'>
    )>, subconAllocation: Array<(
      { __typename?: 'SubcontractAllocationEntity' }
      & Pick<SubcontractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type CreateSubcontractRefundMutationVariables = Exact<{
  input: SubcontractPaymentInput;
}>;


export type CreateSubcontractRefundMutation = (
  { __typename?: 'Mutation' }
  & { createSubcontractRefund: (
    { __typename?: 'SubcontractRefundEntity' }
    & Pick<SubcontractRefundEntity, 'ID' | 'docNo'>
  ) }
);

export type UpdateSubcontractRefundMutationVariables = Exact<{
  input: SubcontractPaymentInput;
}>;


export type UpdateSubcontractRefundMutation = (
  { __typename?: 'Mutation' }
  & { updateSubcontractRefund: (
    { __typename?: 'SubcontractRefundEntity' }
    & Pick<SubcontractRefundEntity, 'ID' | 'docNo'>
  ) }
);

export type GetSubcontractPaymentTableQueryVariables = Exact<{
  subcontractorID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractPaymentTableQuery = (
  { __typename?: 'Query' }
  & { getSubcontractClaim: Array<(
    { __typename?: 'SubcontractClaimEntity' }
    & Pick<SubcontractClaimEntity, 'ID' | 'subcontractID' | 'docNo' | 'docRef' | 'docDate' | 'docAmt' | 'baseAmt' | 'description' | 'outstandingAmt'>
  )>, getSubcontractDN: Array<(
    { __typename?: 'SubcontractDNEntity' }
    & Pick<SubcontractDnEntity, 'ID' | 'subcontractID' | 'docNo' | 'docRef' | 'docDate' | 'docAmt' | 'baseAmt' | 'description' | 'outstandingAmt'>
  )> }
);

export type MakeSubconPaymentMutationVariables = Exact<{
  input: SubcontractPaymentInput;
  ledger: Array<LedgerInput> | LedgerInput;
}>;


export type MakeSubconPaymentMutation = (
  { __typename?: 'Mutation' }
  & { makeSubconPayment: (
    { __typename?: 'SubcontractPaymentEntity' }
    & Pick<SubcontractPaymentEntity, 'ID' | 'docNo'>
  ) }
);

export type MakeSubconAllocationMutationVariables = Exact<{
  ledger: Array<LedgerInput> | LedgerInput;
}>;


export type MakeSubconAllocationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'makeSubconAllocation'>
);

export type ResetSubcontractAllocationMutationVariables = Exact<{
  creditID?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
}>;


export type ResetSubcontractAllocationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetSubcontractAllocation'>
);

export type GetSubconAdvanceAllocationQueryVariables = Exact<{
  debitID: Scalars['String'];
}>;


export type GetSubconAdvanceAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSubconAdvanceAllocation'>
);

export type GetSubconPaymentAllocationQueryVariables = Exact<{
  debitID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
}>;


export type GetSubconPaymentAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSubconPaymentAllocation'>
);

export type GetSubconRefundAllocationQueryVariables = Exact<{
  creditID: Scalars['String'];
}>;


export type GetSubconRefundAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSubconRefundAllocation'>
);

export type CancelSubconAdvanceMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelSubconAdvanceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelSubconAdvance'>
);

export type CancelSubconPaymentMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelSubconPaymentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelSubconPayment'>
);

export type CancelSubconRefundMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelSubconRefundMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelSubconRefund'>
);

export type DeleteSubcontractAdvanceMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteSubcontractAdvanceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSubcontractAdvance'>
);

export type DeleteSubcontractRefundMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteSubcontractRefundMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSubcontractRefund'>
);

export type DeleteSubcontractPaymentMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteSubcontractPaymentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSubcontractPayment'>
);

export type GetSubcontractMiscInvoiceQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractMiscInvoiceQuery = (
  { __typename?: 'Query' }
  & { getSubcontractMiscInvoice: Array<(
    { __typename?: 'SubcontractMiscInvoiceEntity' }
    & Pick<SubcontractMiscInvoiceEntity, 'ID' | 'modBy' | 'docNo' | 'isGlExport' | 'docRef' | 'cancellationDate' | 'cancellationRemarks' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'transactionStatus' | 'subcontractorID' | 'contractID' | 'subcontractID' | 'outstandingAmt'>
    & { subconAllocation: Array<(
      { __typename?: 'SubcontractAllocationEntity' }
      & Pick<SubcontractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'title'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type CreateSubcontractMiscInvoiceMutationVariables = Exact<{
  input: SubcontractTransactionInput;
}>;


export type CreateSubcontractMiscInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { createSubcontractMiscInvoice: (
    { __typename?: 'SubcontractMiscInvoiceEntity' }
    & Pick<SubcontractMiscInvoiceEntity, 'accountID' | 'ID' | 'docNo'>
  ) }
);

export type UpdateSubcontractMiscInvoiceMutationVariables = Exact<{
  input: SubcontractTransactionInput;
}>;


export type UpdateSubcontractMiscInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { updateSubcontractMiscInvoice: (
    { __typename?: 'SubcontractMiscInvoiceEntity' }
    & Pick<SubcontractMiscInvoiceEntity, 'accountID' | 'ID' | 'docNo'>
  ) }
);

export type GetSubcontractDnQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractDnQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'subconDNInfo'>
  & { getSubcontractDN: Array<(
    { __typename?: 'SubcontractDNEntity' }
    & Pick<SubcontractDnEntity, 'ID' | 'isGlExport' | 'modBy' | 'docNo' | 'docRef' | 'cancellationDate' | 'cancellationRemarks' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'transactionStatus' | 'subcontractorID' | 'contractID' | 'subcontractID' | 'outstandingAmt' | 'deductionTypeID'>
    & { deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code' | 'description'>
    )>, subconAllocation: Array<(
      { __typename?: 'SubcontractAllocationEntity' }
      & Pick<SubcontractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'title'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type CreateSubcontractDnMutationVariables = Exact<{
  input: SubcontractTransactionInput;
}>;


export type CreateSubcontractDnMutation = (
  { __typename?: 'Mutation' }
  & { createSubcontractDN: (
    { __typename?: 'SubcontractDNEntity' }
    & Pick<SubcontractDnEntity, 'accountID' | 'ID'>
  ) }
);

export type UpdateSubcontractDnMutationVariables = Exact<{
  input: SubcontractTransactionInput;
}>;


export type UpdateSubcontractDnMutation = (
  { __typename?: 'Mutation' }
  & { updateSubcontractDN: (
    { __typename?: 'SubcontractDNEntity' }
    & Pick<SubcontractDnEntity, 'accountID' | 'ID'>
  ) }
);

export type GetSubcontractCnQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractCnQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'subconCNInfo'>
  & { getSubcontractCN: Array<(
    { __typename?: 'SubcontractCNEntity' }
    & Pick<SubcontractCnEntity, 'ID' | 'isGlExport' | 'modBy' | 'cancellationDate' | 'cancellationRemarks' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'transactionStatus' | 'subcontractorID' | 'contractID' | 'subcontractID' | 'outstandingAmt' | 'deductionTypeID'>
    & { deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code' | 'description'>
    )>, subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'title'>
    )>, subconAllocation: Array<(
      { __typename?: 'SubcontractAllocationEntity' }
      & Pick<SubcontractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type CreateSubcontractCnMutationVariables = Exact<{
  input: SubcontractTransactionInput;
}>;


export type CreateSubcontractCnMutation = (
  { __typename?: 'Mutation' }
  & { createSubcontractCN: (
    { __typename?: 'SubcontractCNEntity' }
    & Pick<SubcontractCnEntity, 'accountID' | 'ID'>
  ) }
);

export type UpdateSubcontractCnMutationVariables = Exact<{
  input: SubcontractTransactionInput;
}>;


export type UpdateSubcontractCnMutation = (
  { __typename?: 'Mutation' }
  & { updateSubcontractCN: (
    { __typename?: 'SubcontractCNEntity' }
    & Pick<SubcontractCnEntity, 'accountID' | 'ID'>
  ) }
);

export type GetDNtoSubconQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
}>;


export type GetDNtoSubconQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'DNtoSubconInfo'>
  & { getDNtoSubcon: Array<(
    { __typename?: 'DNtoSubconEntity' }
    & Pick<DNtoSubconEntity, 'ID' | 'modBy' | 'isGlExport' | 'cancellationDate' | 'cancellationRemarks' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'transactionStatus' | 'contractID' | 'subcontractID' | 'outstandingAmt' | 'deductionTypeID'>
    & { deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code' | 'description'>
    )>, subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'title' | 'siteID'>
    )>, subconAllocation: Array<(
      { __typename?: 'SubcontractAllocationEntity' }
      & Pick<SubcontractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type CreateDNtoSubconMutationVariables = Exact<{
  input: SubcontractTransactionInput;
}>;


export type CreateDNtoSubconMutation = (
  { __typename?: 'Mutation' }
  & { createDNtoSubcon: (
    { __typename?: 'DNtoSubconEntity' }
    & Pick<DNtoSubconEntity, 'accountID' | 'ID' | 'docNo'>
  ) }
);

export type UpdateDNtoSubconMutationVariables = Exact<{
  input: SubcontractTransactionInput;
}>;


export type UpdateDNtoSubconMutation = (
  { __typename?: 'Mutation' }
  & { updateDNtoSubcon: (
    { __typename?: 'DNtoSubconEntity' }
    & Pick<DNtoSubconEntity, 'accountID' | 'ID' | 'docNo'>
  ) }
);

export type GetCNtoSubconQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
}>;


export type GetCNtoSubconQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CNtoSubconInfo'>
  & { getCNtoSubcon: Array<(
    { __typename?: 'CNtoSubconEntity' }
    & Pick<CNtoSubconEntity, 'ID' | 'modBy' | 'isGlExport' | 'cancellationDate' | 'cancellationRemarks' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'transactionStatus' | 'contractID' | 'subcontractID' | 'outstandingAmt' | 'deductionTypeID'>
    & { deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code' | 'description'>
    )>, subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'title'>
    )>, subconAllocation: Array<(
      { __typename?: 'SubcontractAllocationEntity' }
      & Pick<SubcontractAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type CreateCNtoSubconMutationVariables = Exact<{
  input: SubcontractTransactionInput;
}>;


export type CreateCNtoSubconMutation = (
  { __typename?: 'Mutation' }
  & { createCNtoSubcon: (
    { __typename?: 'CNtoSubconEntity' }
    & Pick<CNtoSubconEntity, 'accountID' | 'ID' | 'docNo'>
  ) }
);

export type UpdateCNtoSubconMutationVariables = Exact<{
  input: SubcontractTransactionInput;
}>;


export type UpdateCNtoSubconMutation = (
  { __typename?: 'Mutation' }
  & { updateCNtoSubcon: (
    { __typename?: 'CNtoSubconEntity' }
    & Pick<CNtoSubconEntity, 'accountID' | 'ID' | 'docNo'>
  ) }
);

export type GetSubconCnAllocationQueryVariables = Exact<{
  debitID: Scalars['String'];
}>;


export type GetSubconCnAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSubconCNAllocation'>
);

export type GetSubconDnAllocationQueryVariables = Exact<{
  creditID: Scalars['String'];
}>;


export type GetSubconDnAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSubconDNAllocation'>
);

export type GetDNtoSubconAllocationQueryVariables = Exact<{
  debitID: Scalars['String'];
}>;


export type GetDNtoSubconAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getDNtoSubconAllocation'>
);

export type GetCNtoSubconAllocationQueryVariables = Exact<{
  creditID: Scalars['String'];
}>;


export type GetCNtoSubconAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCNtoSubconAllocation'>
);

export type CancelSubcontractMiscInvoiceMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelSubcontractMiscInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelSubcontractMiscInvoice'>
);

export type CancelSubconDnMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelSubconDnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelSubconDN'>
);

export type CancelSubconCnMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelSubconCnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelSubconCN'>
);

export type CancelDNtoSubconMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelDNtoSubconMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelDNtoSubcon'>
);

export type CancelCNtoSubconMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelCNtoSubconMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelCNtoSubcon'>
);

export type DeleteSubcontractMiscInvoiceMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteSubcontractMiscInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSubcontractMiscInvoice'>
);

export type DeleteSubcontractCnMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteSubcontractCnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSubcontractCN'>
);

export type DeleteSubcontractDnMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteSubcontractDnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSubcontractDN'>
);

export type DeleteDNtoSubconMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteDNtoSubconMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDNtoSubcon'>
);

export type DeleteCNtoSubconMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteCNtoSubconMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCNtoSubcon'>
);

export type GetSubconAdjustmentQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
}>;


export type GetSubconAdjustmentQuery = (
  { __typename?: 'Query' }
  & { getCNtoSubcon: Array<(
    { __typename?: 'CNtoSubconEntity' }
    & Pick<CNtoSubconEntity, 'ID' | 'docAmt' | 'baseAmt' | 'docDate' | 'docNo' | 'docRef' | 'taxAmt' | 'description' | 'deductionTypeID'>
    & { deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code'>
    )> }
  )>, getDNtoSubcon: Array<(
    { __typename?: 'DNtoSubconEntity' }
    & Pick<DNtoSubconEntity, 'ID' | 'docAmt' | 'baseAmt' | 'docDate' | 'docNo' | 'docRef' | 'taxAmt' | 'description' | 'deductionTypeID'>
    & { deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code'>
    )> }
  )>, getSubcontractDN: Array<(
    { __typename?: 'SubcontractDNEntity' }
    & Pick<SubcontractDnEntity, 'ID' | 'docAmt' | 'baseAmt' | 'docDate' | 'docNo' | 'docRef' | 'taxAmt' | 'description' | 'deductionTypeID'>
    & { deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code'>
    )> }
  )>, getSubcontractCN: Array<(
    { __typename?: 'SubcontractCNEntity' }
    & Pick<SubcontractCnEntity, 'ID' | 'docAmt' | 'baseAmt' | 'docDate' | 'docNo' | 'docRef' | 'taxAmt' | 'description' | 'deductionTypeID'>
    & { deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code'>
    )> }
  )> }
);

export type GetSubcontractQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractStatus?: Maybe<ContractStatus>;
}>;


export type GetSubcontractQuery = (
  { __typename?: 'Query' }
  & { getSubcontract: Array<(
    { __typename?: 'SubcontractEntity' }
    & Pick<SubcontractEntity, 'ID' | 'title' | 'accountID' | 'contractID' | 'subcontractorID' | 'costCategoryID' | 'subcontractNo' | 'description' | 'subcontractDate' | 'taxSchemeID' | 'subcontractSum' | 'revisedSubcontractSum' | 'startDate' | 'endDate' | 'progressRetentionPerc' | 'maxRetentionPerc' | 'maxRetentionSum' | 'targetProfitPerc' | 'cmgdDate' | 'cpcDate' | 'dlpDate' | 'latestRunNum' | 'docNumChecking' | 'ladAmt' | 'ladRate' | 'creditTerm' | 'subcontractStatus' | 'contactPerson' | 'siteID' | 'extensionDate' | 'closureRemarks' | 'closureDate' | 'modBy' | 'nominatedSC'>
    & { site?: Maybe<(
      { __typename?: 'SiteEntity' }
      & Pick<SiteEntity, 'ID' | 'name' | 'contractID' | 'address'>
    )>, subcontractorDetail?: Maybe<(
      { __typename?: 'SubcontractorEntity' }
      & Pick<SubcontractorEntity, 'associatedStatus' | 'name' | 'ID' | 'address' | 'contactNo'>
    )>, mainContractDetail?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'title' | 'ID'>
    )>, VO?: Maybe<Array<(
      { __typename?: 'SubcontractVOEntity' }
      & Pick<SubcontractVoEntity, 'submissionAmt' | 'approvedAmt'>
    )>>, taxDetail?: Maybe<(
      { __typename?: 'ConTaxSchemeEntity' }
      & Pick<ConTaxSchemeEntity, 'ID' | 'code' | 'description' | 'taxCategory' | 'taxTypeID' | 'taxClass' | 'createdTs' | 'commonStatus'>
      & { latestTax?: Maybe<(
        { __typename?: 'Tax' }
        & Pick<Tax, 'taxRate' | 'taxDate'>
      )>, taxEffective?: Maybe<Array<(
        { __typename?: 'ConTaxEffectiveDateEntity' }
        & Pick<ConTaxEffectiveDateEntity, 'date' | 'taxRate' | 'createdTs'>
      )>> }
    )>, tradeDetail: (
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'ID' | 'name'>
    ) }
  )> }
);

export type GetSubcontractSubmenuQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractSubmenuQuery = (
  { __typename?: 'Query' }
  & { getSubcontract: Array<(
    { __typename?: 'SubcontractEntity' }
    & Pick<SubcontractEntity, 'ID' | 'title' | 'accountID' | 'subcontractNo' | 'subcontractorID' | 'subcontractStatus' | 'cmgdDate' | 'cpcDate' | 'dlpDate' | 'subcontractSum' | 'revisedSubcontractSum'>
  )>, getSubcontractRelated: Array<(
    { __typename?: 'SubcontractEntity' }
    & Pick<SubcontractEntity, 'ID' | 'title' | 'accountID' | 'subcontractNo' | 'subcontractorID'>
  )> }
);

export type GetContractSubcontractListingQueryVariables = Exact<{
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<ContractStatus>;
}>;


export type GetContractSubcontractListingQuery = (
  { __typename?: 'Query' }
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'title' | 'accountID' | 'customerID' | 'contractNo' | 'description' | 'contractDate' | 'contractSum' | 'voSum' | 'revisedContractSum' | 'subcontractSummary'>
    & { customerDetail?: Maybe<(
      { __typename?: 'CustomerEntity' }
      & Pick<CustomerEntity, 'name' | 'address' | 'contactNo'>
      & { contactPerson: Array<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ID' | 'name' | 'designation' | 'email' | 'contactNo'>
      )> }
    )>, subcontractDetail?: Maybe<Array<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'ID' | 'title' | 'accountID' | 'contractID' | 'subcontractorID' | 'costCategoryID' | 'subcontractNo' | 'description' | 'subcontractDate' | 'taxSchemeID' | 'subcontractSum' | 'revisedSubcontractSum' | 'startDate' | 'endDate' | 'progressRetentionPerc' | 'maxRetentionPerc' | 'targetProfitPerc' | 'creditTerm' | 'subcontractStatus'>
      & { tradeDetail: (
        { __typename?: 'CostCategoryEntity' }
        & Pick<CostCategoryEntity, 'ID' | 'name'>
      ), subcontractorDetail?: Maybe<(
        { __typename?: 'SubcontractorEntity' }
        & Pick<SubcontractorEntity, 'ID' | 'name' | 'associatedStatus'>
      )> }
    )>> }
  )> }
);

export type CreateSubcontractMutationVariables = Exact<{
  input: SubcontractInput;
}>;


export type CreateSubcontractMutation = (
  { __typename?: 'Mutation' }
  & { createSubcontract: (
    { __typename?: 'SubcontractEntity' }
    & Pick<SubcontractEntity, 'ID' | 'title'>
  ) }
);

export type UpdateSubcontractMutationVariables = Exact<{
  input: SubcontractInput;
}>;


export type UpdateSubcontractMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateSubcontract'>
);

export type GetSubcontractGuaranteeQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
}>;


export type GetSubcontractGuaranteeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'subconGuarantee'>
  & { getSubcontractGuarantee: Array<(
    { __typename?: 'SubcontractGuaranteeEntity' }
    & Pick<SubcontractGuaranteeEntity, 'ID' | 'modBy' | 'accountID' | 'subcontractID' | 'docRef' | 'guaranteeTypeID' | 'description' | 'docAmt' | 'startDate' | 'expiryDate' | 'issuer' | 'collateralAmt' | 'remarks' | 'cancellationDate' | 'guaranteeStatus'>
    & { guaranteeType?: Maybe<(
      { __typename?: 'GuaranteeTypeEntity' }
      & Pick<GuaranteeTypeEntity, 'name'>
    )> }
  )> }
);

export type CreateSubcontractGuaranteeMutationVariables = Exact<{
  input: SubcontractGuaranteeInput;
}>;


export type CreateSubcontractGuaranteeMutation = (
  { __typename?: 'Mutation' }
  & { createSubcontractGuarantee: (
    { __typename?: 'SubcontractGuaranteeEntity' }
    & Pick<SubcontractGuaranteeEntity, 'ID'>
  ) }
);

export type UpdateSubcontractGuaranteeMutationVariables = Exact<{
  input: SubcontractGuaranteeInput;
}>;


export type UpdateSubcontractGuaranteeMutation = (
  { __typename?: 'Mutation' }
  & { updateSubcontractGuarantee: (
    { __typename?: 'SubcontractGuaranteeEntity' }
    & Pick<SubcontractGuaranteeEntity, 'ID'>
  ) }
);

export type GetSubcontractInsuranceQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
}>;


export type GetSubcontractInsuranceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'subconInsurance'>
  & { getSubcontractInsurance: Array<(
    { __typename?: 'SubcontractInsuranceEntity' }
    & Pick<SubcontractInsuranceEntity, 'ID' | 'modBy' | 'accountID' | 'subcontractID' | 'docRef' | 'insuranceTypeID' | 'description' | 'docAmt' | 'startDate' | 'expiryDate' | 'issuer' | 'remarks' | 'cancellationDate' | 'insuranceStatus'>
    & { insuranceType?: Maybe<(
      { __typename?: 'InsuranceTypeEntity' }
      & Pick<InsuranceTypeEntity, 'name'>
    )> }
  )> }
);

export type CreateSubcontractInsuranceMutationVariables = Exact<{
  input: SubcontractInsuranceInput;
}>;


export type CreateSubcontractInsuranceMutation = (
  { __typename?: 'Mutation' }
  & { createSubcontractInsurance: (
    { __typename?: 'SubcontractInsuranceEntity' }
    & Pick<SubcontractInsuranceEntity, 'ID'>
  ) }
);

export type UpdateSubcontractInsuranceMutationVariables = Exact<{
  input: SubcontractInsuranceInput;
}>;


export type UpdateSubcontractInsuranceMutation = (
  { __typename?: 'Mutation' }
  & { updateSubcontractInsurance: (
    { __typename?: 'SubcontractInsuranceEntity' }
    & Pick<SubcontractInsuranceEntity, 'ID'>
  ) }
);

export type CancelSubcontractGuaranteeMutationVariables = Exact<{
  input: SubcontractGuaranteeCancellationInput;
}>;


export type CancelSubcontractGuaranteeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelSubcontractGuarantee'>
);

export type CancelSubcontractInsuranceMutationVariables = Exact<{
  input: SubcontractInsuranceCancellationInput;
}>;


export type CancelSubcontractInsuranceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelSubcontractInsurance'>
);

export type GetSubcontractSummaryQueryVariables = Exact<{
  subcontractID: Scalars['String'];
  type?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'subconGuarantee' | 'subconInsurance' | 'submittedVOSubconNotification' | 'subconLastExtensionDate' | 'subconClosureDate' | 'totalDigitalDocuments'>
  & { subcontractInfo: (
    { __typename?: 'SubontractInfo' }
    & Pick<SubontractInfo, 'subcontractSum' | 'subcontractDate'>
  ), subcontractVO: (
    { __typename?: 'SubcontractVO' }
    & Pick<SubcontractVo, 'voSum' | 'voOmission' | 'voSumCount' | 'voOmissionCount' | 'totalVoAmt'>
  ), subconOutstandingAdvances: Array<(
    { __typename?: 'SubcontractAdvanceEntity' }
    & Pick<SubcontractAdvanceEntity, 'docNo' | 'docDate' | 'docAmt' | 'baseAmt' | 'outstandingAmt'>
  )>, subconOutstandingClaims: Array<(
    { __typename?: 'SubcontractClaimEntity' }
    & Pick<SubcontractClaimEntity, 'docNo' | 'docDate' | 'docAmt' | 'baseAmt' | 'outstandingAmt'>
  )>, retention: (
    { __typename?: 'RetentionInfo' }
    & Pick<RetentionInfo, 'retentionSum' | 'retentionBalance' | 'releasedSum'>
  ) }
);

export type GetIpcSummaryQueryVariables = Exact<{
  subcontractID: Scalars['String'];
}>;


export type GetIpcSummaryQuery = (
  { __typename?: 'Query' }
  & { retention: (
    { __typename?: 'RetentionInfo' }
    & Pick<RetentionInfo, 'retentionSum' | 'retentionBalance' | 'releasedSum'>
  ) }
);

export type ActionOnSubcontractStatusMutationVariables = Exact<{
  input: SubcontractActionInput;
  ID: Scalars['String'];
}>;


export type ActionOnSubcontractStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'actionOnSubcontractStatus'>
);

export type CreateSubcontractExtensionDateMutationVariables = Exact<{
  input: SubcontractExtensionInput;
  ID: Scalars['String'];
}>;


export type CreateSubcontractExtensionDateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createSubcontractExtensionDate'>
);

export type CreateSubcontractCertificateMutationVariables = Exact<{
  input: SubcontractCertificateInput;
  ID: Scalars['String'];
}>;


export type CreateSubcontractCertificateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createSubcontractCertificate'>
);

export type GetSubcontractExtensionQueryVariables = Exact<{
  ID: Scalars['String'];
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractExtensionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'subconLastExtensionDate'>
  & { getSubcontract: Array<(
    { __typename?: 'SubcontractEntity' }
    & Pick<SubcontractEntity, 'ID' | 'title' | 'subcontractNo' | 'description' | 'subcontractDate' | 'subcontractSum' | 'taxSchemeID' | 'startDate' | 'endDate' | 'subcontractStatus' | 'extensionDate'>
    & { mainContractDetail?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'title' | 'ID'>
    )> }
  )> }
);

export type GetSubcontractTitleQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractTitleQuery = (
  { __typename?: 'Query' }
  & { getSubcontract: Array<(
    { __typename?: 'SubcontractEntity' }
    & Pick<SubcontractEntity, 'ID' | 'title' | 'subcontractStatus' | 'cmgdDate' | 'cpcDate' | 'dlpDate'>
  )> }
);

export type GetSubcontractRelatedQueryVariables = Exact<{
  subcontractID?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractRelatedQuery = (
  { __typename?: 'Query' }
  & { getSubcontractRelated: Array<(
    { __typename?: 'SubcontractEntity' }
    & Pick<SubcontractEntity, 'ID' | 'title'>
  )> }
);

export type SubconBudgetCheckingQueryVariables = Exact<{
  amount: Scalars['Float'];
  contractID: Scalars['String'];
  costCategoryID: Scalars['String'];
}>;


export type SubconBudgetCheckingQuery = (
  { __typename?: 'Query' }
  & { subconBudgetChecking: (
    { __typename?: 'BudgetInfo' }
    & Pick<BudgetInfo, 'isExceeded' | 'isAllocated'>
  ) }
);

export type GetSubcontractorForSubcontractQueryVariables = Exact<{
  associatedStatus?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractorForSubcontractQuery = (
  { __typename?: 'Query' }
  & { getSubcontractor: Array<(
    { __typename?: 'SubcontractorEntity' }
    & Pick<SubcontractorEntity, 'name' | 'ID' | 'associatedStatus' | 'nominatedSC'>
  )> }
);

export type GetContractVoQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetContractVoQuery = (
  { __typename?: 'Query' }
  & { getContractVO: Array<(
    { __typename?: 'ContractVOEntity' }
    & Pick<ContractVoEntity, 'ID' | 'accountID' | 'contractID' | 'docNo' | 'docRef' | 'startDate' | 'endDate' | 'description' | 'architectNo' | 'engineeringNo' | 'VOType' | 'VOStatus' | 'submissionAmt' | 'submittedDate' | 'rejectedDate' | 'approvedAmt' | 'docDate' | 'remarks'>
    & { toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type CreateContractVoMutationVariables = Exact<{
  input: ContractVoInput;
}>;


export type CreateContractVoMutation = (
  { __typename?: 'Mutation' }
  & { createContractVO: (
    { __typename?: 'ContractVOEntity' }
    & Pick<ContractVoEntity, 'ID'>
  ) }
);

export type UpdateContractVoMutationVariables = Exact<{
  input: ContractVoInput;
}>;


export type UpdateContractVoMutation = (
  { __typename?: 'Mutation' }
  & { updateContractVO: (
    { __typename?: 'ContractVOEntity' }
    & Pick<ContractVoEntity, 'ID'>
  ) }
);

export type ActionOnVoStatusMutationVariables = Exact<{
  input: ContractVoActionInput;
  ID: Scalars['String'];
}>;


export type ActionOnVoStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'actionOnVOStatus'>
);

export type GetContractVoSummaryQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type GetContractVoSummaryQuery = (
  { __typename?: 'Query' }
  & { contractVO: (
    { __typename?: 'ContractVO' }
    & Pick<ContractVo, 'voSum' | 'voOmission' | 'voSumCount' | 'voOmissionCount' | 'totalVoAmt'>
  ) }
);

export type GetSubcontractVoQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetSubcontractVoQuery = (
  { __typename?: 'Query' }
  & { getSubcontractVO: Array<(
    { __typename?: 'SubcontractVOEntity' }
    & Pick<SubcontractVoEntity, 'ID' | 'accountID' | 'subcontractID' | 'docNo' | 'docRef' | 'startDate' | 'endDate' | 'description' | 'architectNo' | 'engineeringNo' | 'VOType' | 'VOStatus' | 'submissionAmt' | 'approvedAmt' | 'remarks' | 'docDate'>
    & { toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
    )> }
  )> }
);

export type CreateSubcontractVoMutationVariables = Exact<{
  input: SubcontractVoInput;
}>;


export type CreateSubcontractVoMutation = (
  { __typename?: 'Mutation' }
  & { createSubcontractVO: (
    { __typename?: 'SubcontractVOEntity' }
    & Pick<SubcontractVoEntity, 'ID'>
  ) }
);

export type UpdateSubcontractVoMutationVariables = Exact<{
  input: SubcontractVoInput;
}>;


export type UpdateSubcontractVoMutation = (
  { __typename?: 'Mutation' }
  & { updateSubcontractVO: (
    { __typename?: 'SubcontractVOEntity' }
    & Pick<SubcontractVoEntity, 'ID'>
  ) }
);

export type ActionOnSubconVoStatusMutationVariables = Exact<{
  input: SubcontractVoActionInput;
  ID: Scalars['String'];
}>;


export type ActionOnSubconVoStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'actionOnSubconVOStatus'>
);

export type GetSubcontractVoSummaryQueryVariables = Exact<{
  subcontractID: Scalars['String'];
}>;


export type GetSubcontractVoSummaryQuery = (
  { __typename?: 'Query' }
  & { subcontractVO: (
    { __typename?: 'SubcontractVO' }
    & Pick<SubcontractVo, 'voSum' | 'voOmission' | 'voSumCount' | 'voOmissionCount' | 'totalVoAmt'>
  ) }
);

export type DocumentListingQueryVariables = Exact<{
  refID?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  notDescription?: Maybe<Scalars['String']>;
  hasDescription?: Maybe<Scalars['Boolean']>;
}>;


export type DocumentListingQuery = (
  { __typename?: 'Query' }
  & { DocumentListing?: Maybe<Array<(
    { __typename?: 'DocumentsEntity' }
    & Pick<DocumentsEntity, 'ID' | 'fileName' | 'fileURL' | 'mediaType' | 'createdTs' | 'description' | 'refID'>
  )>> }
);

export type UploadPdfDocumentMutationVariables = Exact<{
  input: LedgerPdfInput;
}>;


export type UploadPdfDocumentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadPDFDocument'>
);

export type TestSampleEnumQueryVariables = Exact<{
  SampleEnum: SampleEnum;
}>;


export type TestSampleEnumQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'testSampleEnum'>
);

export type UploadCompanyLogoMutationVariables = Exact<{
  file?: Maybe<Scalars['Upload']>;
}>;


export type UploadCompanyLogoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadCompanyLogo'>
);

export type LogoViewQueryVariables = Exact<{
  refID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
}>;


export type LogoViewQuery = (
  { __typename?: 'Query' }
  & { logoView: (
    { __typename?: 'DocumentsEntity' }
    & Pick<DocumentsEntity, 'fileName' | 'fileURL'>
  ) }
);

export type GetBankAccountQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetBankAccountQuery = (
  { __typename?: 'Query' }
  & { getBankAccount: Array<(
    { __typename?: 'BankAccountEntity' }
    & Pick<BankAccountEntity, 'ID' | 'status' | 'accountID' | 'name' | 'accountHolder' | 'accountNo' | 'contactNo' | 'branch' | 'address' | 'swiftCode' | 'contactPerson'>
  )> }
);

export type CreateBankAccountMutationVariables = Exact<{
  input: BankAccountInput;
}>;


export type CreateBankAccountMutation = (
  { __typename?: 'Mutation' }
  & { createBankAccount: (
    { __typename?: 'BankAccountEntity' }
    & Pick<BankAccountEntity, 'ID'>
  ) }
);

export type UpdateBankAccountMutationVariables = Exact<{
  input: BankAccountInput;
}>;


export type UpdateBankAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateBankAccount: (
    { __typename?: 'BankAccountEntity' }
    & Pick<BankAccountEntity, 'ID'>
  ) }
);

export type CancelBankAccountMutationVariables = Exact<{
  input: BankAccountCancellationInput;
  ID: Scalars['String'];
}>;


export type CancelBankAccountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelBankAccount'>
);

export type GetCostCategoryQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  costClass?: Maybe<CostClass>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
}>;


export type GetCostCategoryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'costCategoryDates'>
  & { getCostCategory: Array<(
    { __typename?: 'CostCategoryEntity' }
    & Pick<CostCategoryEntity, 'ID' | 'name' | 'modTs' | 'accountID' | 'commonStatus' | 'pnmCostCat' | 'costClass'>
    & { supplier: Array<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'name' | 'ID'>
    )>, subcontractor: Array<(
      { __typename?: 'SubcontractorEntity' }
      & Pick<SubcontractorEntity, 'name' | 'ID'>
    )>, costItem?: Maybe<Array<(
      { __typename?: 'CostItemEntity' }
      & Pick<CostItemEntity, 'ID' | 'name' | 'unitPrice'>
      & { UOM?: Maybe<(
        { __typename?: 'UOMEntity' }
        & Pick<UomEntity, 'ID' | 'name' | 'code'>
      )> }
    )>>, budget: Array<(
      { __typename?: 'ProjectBudgetEntity' }
      & Pick<ProjectBudgetEntity, 'ID' | 'createdTs' | 'budgetAmt'>
    )> }
  )> }
);

export type CreateCostCategoryMutationVariables = Exact<{
  input: CostCategoryInput;
}>;


export type CreateCostCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createCostCategory: (
    { __typename?: 'CostCategoryEntity' }
    & Pick<CostCategoryEntity, 'ID'>
  ) }
);

export type UpdateCostCategoryMutationVariables = Exact<{
  input: CostCategoryInput;
}>;


export type UpdateCostCategoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCostCategory'>
);

export type DeleteCostCategoryMutationVariables = Exact<{
  input: CostCategoryUpdateInput;
}>;


export type DeleteCostCategoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCostCategory'>
);

export type AddSupplierInCCategoryMutationVariables = Exact<{
  supplierID: Array<Scalars['String']> | Scalars['String'];
  costCategoryID: Scalars['String'];
}>;


export type AddSupplierInCCategoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addSupplierInCCategory'>
);

export type GetCostItemQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetCostItemQuery = (
  { __typename?: 'Query' }
  & { getCostItem: Array<(
    { __typename?: 'CostItemEntity' }
    & Pick<CostItemEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'ID' | 'accountID' | 'commonStatus' | 'costCategoryID' | 'name' | 'unitPrice' | 'onBehalf' | 'markupPerc'>
    & { UOM?: Maybe<(
      { __typename?: 'UOMEntity' }
      & Pick<UomEntity, 'ID' | 'name' | 'code'>
    )>, costCategory?: Maybe<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'ID' | 'name'>
    )> }
  )>, getCostCategory: Array<(
    { __typename?: 'CostCategoryEntity' }
    & Pick<CostCategoryEntity, 'name'>
    & { supplier: Array<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'name' | 'ID'>
    )> }
  )> }
);

export type CreateCostItemMutationVariables = Exact<{
  input: CostItemInput;
}>;


export type CreateCostItemMutation = (
  { __typename?: 'Mutation' }
  & { createCostItem: (
    { __typename?: 'CostItemEntity' }
    & Pick<CostItemEntity, 'ID' | 'name'>
  ) }
);

export type CancelCostItemMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type CancelCostItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelCostItem'>
);

export type UpdateCostItemMutationVariables = Exact<{
  input: CostItemInput;
}>;


export type UpdateCostItemMutation = (
  { __typename?: 'Mutation' }
  & { updateCostItem: (
    { __typename?: 'CostItemEntity' }
    & Pick<CostItemEntity, 'ID' | 'name'>
  ) }
);

export type UpdateNumberFormatMutationVariables = Exact<{
  input: NumberFormatInput;
}>;


export type UpdateNumberFormatMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'editNumberFormat'>
);

export type GetGeneralSettingExecutiveSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGeneralSettingExecutiveSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'userDate' | 'costCategoryDates' | 'guaranteeTypeDate' | 'insuranceTypeDate' | 'bankAccountDate' | 'docNumDate' | 'uomDate' | 'taxSchemeDate' | 'roleDate' | 'conAuthDate'>
);

export type UpdateGuaranteeTypeMutationVariables = Exact<{
  input: GuarInsTypeInput;
}>;


export type UpdateGuaranteeTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateGuaranteeType: (
    { __typename?: 'GuaranteeTypeEntity' }
    & Pick<GuaranteeTypeEntity, 'ID' | 'name'>
  ) }
);

export type CreateGuaranteeTypeMutationVariables = Exact<{
  input: GuarInsTypeInput;
}>;


export type CreateGuaranteeTypeMutation = (
  { __typename?: 'Mutation' }
  & { createGuaranteeType: (
    { __typename?: 'GuaranteeTypeEntity' }
    & Pick<GuaranteeTypeEntity, 'name'>
  ) }
);

export type GetGuaranteeTypeQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetGuaranteeTypeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'guaranteeTypeDate'>
  & { getGuaranteeType: Array<(
    { __typename?: 'GuaranteeTypeEntity' }
    & Pick<GuaranteeTypeEntity, 'ID' | 'name' | 'commonStatus' | 'modTs'>
  )> }
);

export type DeleteGuaranteeTypeMutationVariables = Exact<{
  ID: GuarInsTypeDeleteInput;
}>;


export type DeleteGuaranteeTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteGuaranteeType'>
);

export type UpdateInsuranceTypeMutationVariables = Exact<{
  input: GuarInsTypeInput;
}>;


export type UpdateInsuranceTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateInsuranceType: (
    { __typename?: 'InsuranceTypeEntity' }
    & Pick<InsuranceTypeEntity, 'ID' | 'name'>
  ) }
);

export type CreateInsuranceTypeMutationVariables = Exact<{
  input: GuarInsTypeInput;
}>;


export type CreateInsuranceTypeMutation = (
  { __typename?: 'Mutation' }
  & { createInsuranceType: (
    { __typename?: 'InsuranceTypeEntity' }
    & Pick<InsuranceTypeEntity, 'name'>
  ) }
);

export type GetInsuranceTypeQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetInsuranceTypeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'insuranceTypeDate'>
  & { getInsuranceType: Array<(
    { __typename?: 'InsuranceTypeEntity' }
    & Pick<InsuranceTypeEntity, 'ID' | 'name' | 'commonStatus' | 'modTs'>
  )> }
);

export type DeleteInsuranceTypeMutationVariables = Exact<{
  ID: GuarInsTypeDeleteInput;
}>;


export type DeleteInsuranceTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteInsuranceType'>
);

export type UserRoleIDsQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type UserRoleIDsQuery = (
  { __typename?: 'Query' }
  & { userRoleIDs: Array<(
    { __typename?: 'userRoleID' }
    & Pick<UserRoleId, 'roleID' | 'userID'>
  )> }
);

export type CreateEntityRoleUserMutationVariables = Exact<{
  input: EntityRoleUserAsgInput;
}>;


export type CreateEntityRoleUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateEntityRoleUser'>
);

export type CreateListEntityRoleUserMutationVariables = Exact<{
  contractID: Scalars['String'];
  roleUserIDs: Array<RoleUserInput> | RoleUserInput;
}>;


export type CreateListEntityRoleUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateListEntityRoleUser'>
);

export type TestPermissionQueryVariables = Exact<{
  conPerm: ContractPermission;
}>;


export type TestPermissionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'testPermission'>
);

export type CreateRolePermissionMutationVariables = Exact<{
  input: RoleInput;
  permissionArr: Array<ContractPermission> | ContractPermission;
}>;


export type CreateRolePermissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateRolePermission'>
);

export type UpdateRolePermissionMutationVariables = Exact<{
  input: RoleInput;
  permissionArr: Array<ContractPermission> | ContractPermission;
}>;


export type UpdateRolePermissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateRolePermission'>
);

export type GetRoleQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetRoleQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'roleDate'>
  & { getRole: Array<(
    { __typename?: 'RoleEntity' }
    & Pick<RoleEntity, 'ID' | 'name'>
    & { rolePerm: Array<(
      { __typename?: 'RolePermissionAssignmentEntity' }
      & Pick<RolePermissionAssignmentEntity, 'permissionID' | 'roleID' | 'permName'>
    )> }
  )> }
);

export type GetContractListForProjectAndRoleAuthQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetContractListForProjectAndRoleAuthQuery = (
  { __typename?: 'Query' }
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'title' | 'contractDate'>
  )> }
);

export type GetEntityRoleUserAssignmentQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  entityID?: Maybe<Scalars['String']>;
}>;


export type GetEntityRoleUserAssignmentQuery = (
  { __typename?: 'Query' }
  & { getEntityRoleUserAssignment: Array<(
    { __typename?: 'RoleUserEntAssignmentEntity' }
    & Pick<RoleUserEntAssignmentEntity, 'userID' | 'roleID'>
  )> }
);

export type DeleteRolePermissionMutationVariables = Exact<{
  roleID: Scalars['String'];
}>;


export type DeleteRolePermissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteRolePermission'>
);

export type CreateUomMutationVariables = Exact<{
  input: UomInput;
}>;


export type CreateUomMutation = (
  { __typename?: 'Mutation' }
  & { createUOM: (
    { __typename?: 'UOMEntity' }
    & Pick<UomEntity, 'name' | 'code'>
  ) }
);

export type UpdateUomMutationVariables = Exact<{
  input: UomInput;
}>;


export type UpdateUomMutation = (
  { __typename?: 'Mutation' }
  & { updateUOM: (
    { __typename?: 'UOMEntity' }
    & Pick<UomEntity, 'ID' | 'name' | 'code'>
  ) }
);

export type DeleteUomMutationVariables = Exact<{
  ID: UomDeleteInput;
}>;


export type DeleteUomMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUOM'>
);

export type GetUserQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'name' | 'department' | 'email' | 'jobTitle' | 'contactNo' | 'superUser' | 'commonStatus' | 'userName'>
  ) }
);

export type GetUsersByAccountAndSoftwareQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersByAccountAndSoftwareQuery = (
  { __typename?: 'Query' }
  & { getUsersByAccountAndSoftware: Array<(
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'name' | 'department' | 'email' | 'contactNo' | 'superUser' | 'commonStatus' | 'jobTitle' | 'userName'>
  )> }
);

export type CreateUserMutationVariables = Exact<{
  input: UserInput;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'email'>
  ) }
);

export type UpdateUserMutationVariables = Exact<{
  input: UserInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID'>
  ) }
);

export type GetHomeSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHomeSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'activeContractCount' | 'activeClientCount' | 'activeSubcontractCount' | 'activeSubcontractorCount' | 'activeSupplierCount' | 'lastMiscExpDate' | 'lastPurchaseDate'>
  & { loggedInUserProfile: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'accountID' | 'name' | 'email' | 'contactNo' | 'department' | 'superUser' | 'jobTitle'>
  ) }
);

export type GetRolePermissionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRolePermissionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getRolePermission'>
);

export type GetContractPermissionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetContractPermissionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getContractPermission'>
);


export const LoggedInUserProfileDocument = gql`
    query loggedInUserProfile {
  loggedInUserProfile {
    ID
    accountID
    name
    email
    contactNo
    department
    superUser
    jobTitle
  }
}
    `;

/**
 * __useLoggedInUserProfileQuery__
 *
 * To run a query within a React component, call `useLoggedInUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedInUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedInUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoggedInUserProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>(LoggedInUserProfileDocument, baseOptions);
      }
export function useLoggedInUserProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>(LoggedInUserProfileDocument, baseOptions);
        }
export type LoggedInUserProfileQueryHookResult = ReturnType<typeof useLoggedInUserProfileQuery>;
export type LoggedInUserProfileLazyQueryHookResult = ReturnType<typeof useLoggedInUserProfileLazyQuery>;
export type LoggedInUserProfileQueryResult = ApolloReactCommon.QueryResult<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>;
export const LoginDocument = gql`
    mutation login($loginId: String!, $password: String!) {
  login(loginId: $loginId, password: $password) {
    accessToken
    user {
      name
    }
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      loginId: // value for 'loginId'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($newPassword: String!, $currentPassword: String!) {
  changePassword(newPassword: $newPassword, currentPassword: $currentPassword)
}
    `;
export type ChangePasswordMutationFn = ApolloReactCommon.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      currentPassword: // value for 'currentPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, baseOptions);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = ApolloReactCommon.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  forgotPassword(email: $email)
}
    `;
export type ForgotPasswordMutationFn = ApolloReactCommon.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, baseOptions);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = ApolloReactCommon.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($password: String!, $token: String!) {
  resetPassword(password: $password, token: $token)
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const ActivateUserDocument = gql`
    mutation activateUser($token: String!) {
  activateUser(token: $token)
}
    `;
export type ActivateUserMutationFn = ApolloReactCommon.MutationFunction<ActivateUserMutation, ActivateUserMutationVariables>;

/**
 * __useActivateUserMutation__
 *
 * To run a mutation, you first call `useActivateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateUserMutation, { data, loading, error }] = useActivateUserMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useActivateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateUserMutation, ActivateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateUserMutation, ActivateUserMutationVariables>(ActivateUserDocument, baseOptions);
      }
export type ActivateUserMutationHookResult = ReturnType<typeof useActivateUserMutation>;
export type ActivateUserMutationResult = ApolloReactCommon.MutationResult<ActivateUserMutation>;
export type ActivateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateUserMutation, ActivateUserMutationVariables>;
export const PlatformLoginDocument = gql`
    mutation platformLogin($softwareCode: SoftwareCode!, $password: String!, $loginId: String!) {
  platformLogin(softwareCode: $softwareCode, password: $password, loginId: $loginId) {
    accessToken
    user {
      name
    }
  }
}
    `;
export type PlatformLoginMutationFn = ApolloReactCommon.MutationFunction<PlatformLoginMutation, PlatformLoginMutationVariables>;

/**
 * __usePlatformLoginMutation__
 *
 * To run a mutation, you first call `usePlatformLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlatformLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [platformLoginMutation, { data, loading, error }] = usePlatformLoginMutation({
 *   variables: {
 *      softwareCode: // value for 'softwareCode'
 *      password: // value for 'password'
 *      loginId: // value for 'loginId'
 *   },
 * });
 */
export function usePlatformLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PlatformLoginMutation, PlatformLoginMutationVariables>) {
        return ApolloReactHooks.useMutation<PlatformLoginMutation, PlatformLoginMutationVariables>(PlatformLoginDocument, baseOptions);
      }
export type PlatformLoginMutationHookResult = ReturnType<typeof usePlatformLoginMutation>;
export type PlatformLoginMutationResult = ApolloReactCommon.MutationResult<PlatformLoginMutation>;
export type PlatformLoginMutationOptions = ApolloReactCommon.BaseMutationOptions<PlatformLoginMutation, PlatformLoginMutationVariables>;
export const LoggedInPlatformUserProfileDocument = gql`
    query loggedInPlatformUserProfile {
  loggedInPlatformUserProfile {
    ID
    accountID
    userName
    contactDetail {
      email
      contactNo
      name
      designation
    }
    associateID
    superUser
    softwareCode
  }
}
    `;

/**
 * __useLoggedInPlatformUserProfileQuery__
 *
 * To run a query within a React component, call `useLoggedInPlatformUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedInPlatformUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedInPlatformUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoggedInPlatformUserProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoggedInPlatformUserProfileQuery, LoggedInPlatformUserProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<LoggedInPlatformUserProfileQuery, LoggedInPlatformUserProfileQueryVariables>(LoggedInPlatformUserProfileDocument, baseOptions);
      }
export function useLoggedInPlatformUserProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoggedInPlatformUserProfileQuery, LoggedInPlatformUserProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoggedInPlatformUserProfileQuery, LoggedInPlatformUserProfileQueryVariables>(LoggedInPlatformUserProfileDocument, baseOptions);
        }
export type LoggedInPlatformUserProfileQueryHookResult = ReturnType<typeof useLoggedInPlatformUserProfileQuery>;
export type LoggedInPlatformUserProfileLazyQueryHookResult = ReturnType<typeof useLoggedInPlatformUserProfileLazyQuery>;
export type LoggedInPlatformUserProfileQueryResult = ApolloReactCommon.QueryResult<LoggedInPlatformUserProfileQuery, LoggedInPlatformUserProfileQueryVariables>;
export const AssociateMenuDocument = gql`
    query associateMenu($associate: String!) {
  associateMenu(associate: $associate)
}
    `;

/**
 * __useAssociateMenuQuery__
 *
 * To run a query within a React component, call `useAssociateMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssociateMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssociateMenuQuery({
 *   variables: {
 *      associate: // value for 'associate'
 *   },
 * });
 */
export function useAssociateMenuQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AssociateMenuQuery, AssociateMenuQueryVariables>) {
        return ApolloReactHooks.useQuery<AssociateMenuQuery, AssociateMenuQueryVariables>(AssociateMenuDocument, baseOptions);
      }
export function useAssociateMenuLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AssociateMenuQuery, AssociateMenuQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AssociateMenuQuery, AssociateMenuQueryVariables>(AssociateMenuDocument, baseOptions);
        }
export type AssociateMenuQueryHookResult = ReturnType<typeof useAssociateMenuQuery>;
export type AssociateMenuLazyQueryHookResult = ReturnType<typeof useAssociateMenuLazyQuery>;
export type AssociateMenuQueryResult = ApolloReactCommon.QueryResult<AssociateMenuQuery, AssociateMenuQueryVariables>;
export const GetSubcontractorDocument = gql`
    query getSubcontractor($ID: String, $orderByAsc: String) {
  getSubcontractor(ID: $ID, orderByAsc: $orderByAsc) {
    ID
    name
    email
    accountID
    registrationNo
    associatedStatus
    contactNo
    creditTerm
    currencyID
    ratingType
    cidbDetails
    pkkDetails
    statusRemarks
    bankDetails
    taxDetails
    address
    contactPerson {
      ID
    }
    createdTs
    createdBy
    modTs
    modBy
    description
    currencyID
    taxTypeID
    subconClaim {
      ID
      outstandingAmt
    }
    subconAdvance {
      ID
      outstandingAmt
      baseAmt
    }
    subcontract {
      ID
      title
      subcontractSum
    }
  }
}
    `;

/**
 * __useGetSubcontractorQuery__
 *
 * To run a query within a React component, call `useGetSubcontractorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractorQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetSubcontractorQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractorQuery, GetSubcontractorQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractorQuery, GetSubcontractorQueryVariables>(GetSubcontractorDocument, baseOptions);
      }
export function useGetSubcontractorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractorQuery, GetSubcontractorQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractorQuery, GetSubcontractorQueryVariables>(GetSubcontractorDocument, baseOptions);
        }
export type GetSubcontractorQueryHookResult = ReturnType<typeof useGetSubcontractorQuery>;
export type GetSubcontractorLazyQueryHookResult = ReturnType<typeof useGetSubcontractorLazyQuery>;
export type GetSubcontractorQueryResult = ApolloReactCommon.QueryResult<GetSubcontractorQuery, GetSubcontractorQueryVariables>;
export const GetSubcontractorListDocument = gql`
    query getSubcontractorList($ID: String, $orderByAsc: String) {
  getSubcontractor(ID: $ID, orderByAsc: $orderByAsc) {
    ID
    name
  }
}
    `;

/**
 * __useGetSubcontractorListQuery__
 *
 * To run a query within a React component, call `useGetSubcontractorListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractorListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractorListQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetSubcontractorListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractorListQuery, GetSubcontractorListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractorListQuery, GetSubcontractorListQueryVariables>(GetSubcontractorListDocument, baseOptions);
      }
export function useGetSubcontractorListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractorListQuery, GetSubcontractorListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractorListQuery, GetSubcontractorListQueryVariables>(GetSubcontractorListDocument, baseOptions);
        }
export type GetSubcontractorListQueryHookResult = ReturnType<typeof useGetSubcontractorListQuery>;
export type GetSubcontractorListLazyQueryHookResult = ReturnType<typeof useGetSubcontractorListLazyQuery>;
export type GetSubcontractorListQueryResult = ApolloReactCommon.QueryResult<GetSubcontractorListQuery, GetSubcontractorListQueryVariables>;
export const GetSubcontractorCCategoryDocument = gql`
    query getSubcontractorCCategory($subcontractorID: String) {
  getSubcontractorCCategory(subcontractorID: $subcontractorID) {
    subcontractorID
    costCategoryID
  }
}
    `;

/**
 * __useGetSubcontractorCCategoryQuery__
 *
 * To run a query within a React component, call `useGetSubcontractorCCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractorCCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractorCCategoryQuery({
 *   variables: {
 *      subcontractorID: // value for 'subcontractorID'
 *   },
 * });
 */
export function useGetSubcontractorCCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractorCCategoryQuery, GetSubcontractorCCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractorCCategoryQuery, GetSubcontractorCCategoryQueryVariables>(GetSubcontractorCCategoryDocument, baseOptions);
      }
export function useGetSubcontractorCCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractorCCategoryQuery, GetSubcontractorCCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractorCCategoryQuery, GetSubcontractorCCategoryQueryVariables>(GetSubcontractorCCategoryDocument, baseOptions);
        }
export type GetSubcontractorCCategoryQueryHookResult = ReturnType<typeof useGetSubcontractorCCategoryQuery>;
export type GetSubcontractorCCategoryLazyQueryHookResult = ReturnType<typeof useGetSubcontractorCCategoryLazyQuery>;
export type GetSubcontractorCCategoryQueryResult = ApolloReactCommon.QueryResult<GetSubcontractorCCategoryQuery, GetSubcontractorCCategoryQueryVariables>;
export const CreateSubcontractorDocument = gql`
    mutation createSubcontractor($input: SubcontractorInput!) {
  createSubcontractor(input: $input) {
    ID
    name
  }
}
    `;
export type CreateSubcontractorMutationFn = ApolloReactCommon.MutationFunction<CreateSubcontractorMutation, CreateSubcontractorMutationVariables>;

/**
 * __useCreateSubcontractorMutation__
 *
 * To run a mutation, you first call `useCreateSubcontractorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubcontractorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubcontractorMutation, { data, loading, error }] = useCreateSubcontractorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubcontractorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubcontractorMutation, CreateSubcontractorMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubcontractorMutation, CreateSubcontractorMutationVariables>(CreateSubcontractorDocument, baseOptions);
      }
export type CreateSubcontractorMutationHookResult = ReturnType<typeof useCreateSubcontractorMutation>;
export type CreateSubcontractorMutationResult = ApolloReactCommon.MutationResult<CreateSubcontractorMutation>;
export type CreateSubcontractorMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubcontractorMutation, CreateSubcontractorMutationVariables>;
export const UpdateSubcontractorDocument = gql`
    mutation updateSubcontractor($input: SubcontractorInput!) {
  updateSubcontractor(input: $input) {
    ID
    name
  }
}
    `;
export type UpdateSubcontractorMutationFn = ApolloReactCommon.MutationFunction<UpdateSubcontractorMutation, UpdateSubcontractorMutationVariables>;

/**
 * __useUpdateSubcontractorMutation__
 *
 * To run a mutation, you first call `useUpdateSubcontractorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubcontractorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubcontractorMutation, { data, loading, error }] = useUpdateSubcontractorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubcontractorMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSubcontractorMutation, UpdateSubcontractorMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSubcontractorMutation, UpdateSubcontractorMutationVariables>(UpdateSubcontractorDocument, baseOptions);
      }
export type UpdateSubcontractorMutationHookResult = ReturnType<typeof useUpdateSubcontractorMutation>;
export type UpdateSubcontractorMutationResult = ApolloReactCommon.MutationResult<UpdateSubcontractorMutation>;
export type UpdateSubcontractorMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSubcontractorMutation, UpdateSubcontractorMutationVariables>;
export const AddSubcontractorContactDocument = gql`
    mutation addSubcontractorContact($ID: String!, $platformAccess: Boolean!, $contactInput: [ContactPersonInput!]!) {
  addSubcontractorContact(ID: $ID, platformAccess: $platformAccess, contactInput: $contactInput)
}
    `;
export type AddSubcontractorContactMutationFn = ApolloReactCommon.MutationFunction<AddSubcontractorContactMutation, AddSubcontractorContactMutationVariables>;

/**
 * __useAddSubcontractorContactMutation__
 *
 * To run a mutation, you first call `useAddSubcontractorContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSubcontractorContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSubcontractorContactMutation, { data, loading, error }] = useAddSubcontractorContactMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      platformAccess: // value for 'platformAccess'
 *      contactInput: // value for 'contactInput'
 *   },
 * });
 */
export function useAddSubcontractorContactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddSubcontractorContactMutation, AddSubcontractorContactMutationVariables>) {
        return ApolloReactHooks.useMutation<AddSubcontractorContactMutation, AddSubcontractorContactMutationVariables>(AddSubcontractorContactDocument, baseOptions);
      }
export type AddSubcontractorContactMutationHookResult = ReturnType<typeof useAddSubcontractorContactMutation>;
export type AddSubcontractorContactMutationResult = ApolloReactCommon.MutationResult<AddSubcontractorContactMutation>;
export type AddSubcontractorContactMutationOptions = ApolloReactCommon.BaseMutationOptions<AddSubcontractorContactMutation, AddSubcontractorContactMutationVariables>;
export const AddSupplierContactDocument = gql`
    mutation addSupplierContact($ID: String!, $platformAccess: Boolean!, $contactInput: [ContactPersonInput!]!) {
  addSupplierContact(ID: $ID, platformAccess: $platformAccess, contactInput: $contactInput)
}
    `;
export type AddSupplierContactMutationFn = ApolloReactCommon.MutationFunction<AddSupplierContactMutation, AddSupplierContactMutationVariables>;

/**
 * __useAddSupplierContactMutation__
 *
 * To run a mutation, you first call `useAddSupplierContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSupplierContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSupplierContactMutation, { data, loading, error }] = useAddSupplierContactMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      platformAccess: // value for 'platformAccess'
 *      contactInput: // value for 'contactInput'
 *   },
 * });
 */
export function useAddSupplierContactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddSupplierContactMutation, AddSupplierContactMutationVariables>) {
        return ApolloReactHooks.useMutation<AddSupplierContactMutation, AddSupplierContactMutationVariables>(AddSupplierContactDocument, baseOptions);
      }
export type AddSupplierContactMutationHookResult = ReturnType<typeof useAddSupplierContactMutation>;
export type AddSupplierContactMutationResult = ApolloReactCommon.MutationResult<AddSupplierContactMutation>;
export type AddSupplierContactMutationOptions = ApolloReactCommon.BaseMutationOptions<AddSupplierContactMutation, AddSupplierContactMutationVariables>;
export const AddSubcontractorCCategoryDocument = gql`
    mutation addSubcontractorCCategory($subcontractorID: String!, $costCategoryID: [String!]!) {
  addSubcontractorCCategory(subcontractorID: $subcontractorID, costCategoryID: $costCategoryID)
}
    `;
export type AddSubcontractorCCategoryMutationFn = ApolloReactCommon.MutationFunction<AddSubcontractorCCategoryMutation, AddSubcontractorCCategoryMutationVariables>;

/**
 * __useAddSubcontractorCCategoryMutation__
 *
 * To run a mutation, you first call `useAddSubcontractorCCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSubcontractorCCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSubcontractorCCategoryMutation, { data, loading, error }] = useAddSubcontractorCCategoryMutation({
 *   variables: {
 *      subcontractorID: // value for 'subcontractorID'
 *      costCategoryID: // value for 'costCategoryID'
 *   },
 * });
 */
export function useAddSubcontractorCCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddSubcontractorCCategoryMutation, AddSubcontractorCCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<AddSubcontractorCCategoryMutation, AddSubcontractorCCategoryMutationVariables>(AddSubcontractorCCategoryDocument, baseOptions);
      }
export type AddSubcontractorCCategoryMutationHookResult = ReturnType<typeof useAddSubcontractorCCategoryMutation>;
export type AddSubcontractorCCategoryMutationResult = ApolloReactCommon.MutationResult<AddSubcontractorCCategoryMutation>;
export type AddSubcontractorCCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<AddSubcontractorCCategoryMutation, AddSubcontractorCCategoryMutationVariables>;
export const CreateSubcontractorAndPackageDocument = gql`
    mutation createSubcontractorAndPackage($subcontractorInput: SubcontractorInput!, $costCategoryID: [String!]!) {
  createSubcontractorAndPackage(subcontractorInput: $subcontractorInput, costCategoryID: $costCategoryID) {
    ID
    name
  }
}
    `;
export type CreateSubcontractorAndPackageMutationFn = ApolloReactCommon.MutationFunction<CreateSubcontractorAndPackageMutation, CreateSubcontractorAndPackageMutationVariables>;

/**
 * __useCreateSubcontractorAndPackageMutation__
 *
 * To run a mutation, you first call `useCreateSubcontractorAndPackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubcontractorAndPackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubcontractorAndPackageMutation, { data, loading, error }] = useCreateSubcontractorAndPackageMutation({
 *   variables: {
 *      subcontractorInput: // value for 'subcontractorInput'
 *      costCategoryID: // value for 'costCategoryID'
 *   },
 * });
 */
export function useCreateSubcontractorAndPackageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubcontractorAndPackageMutation, CreateSubcontractorAndPackageMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubcontractorAndPackageMutation, CreateSubcontractorAndPackageMutationVariables>(CreateSubcontractorAndPackageDocument, baseOptions);
      }
export type CreateSubcontractorAndPackageMutationHookResult = ReturnType<typeof useCreateSubcontractorAndPackageMutation>;
export type CreateSubcontractorAndPackageMutationResult = ApolloReactCommon.MutationResult<CreateSubcontractorAndPackageMutation>;
export type CreateSubcontractorAndPackageMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubcontractorAndPackageMutation, CreateSubcontractorAndPackageMutationVariables>;
export const GetCustomerTitleDocument = gql`
    query getCustomerTitle($ID: String) {
  getCustomer(ID: $ID) {
    ID
    name
  }
}
    `;

/**
 * __useGetCustomerTitleQuery__
 *
 * To run a query within a React component, call `useGetCustomerTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerTitleQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetCustomerTitleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCustomerTitleQuery, GetCustomerTitleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCustomerTitleQuery, GetCustomerTitleQueryVariables>(GetCustomerTitleDocument, baseOptions);
      }
export function useGetCustomerTitleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomerTitleQuery, GetCustomerTitleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCustomerTitleQuery, GetCustomerTitleQueryVariables>(GetCustomerTitleDocument, baseOptions);
        }
export type GetCustomerTitleQueryHookResult = ReturnType<typeof useGetCustomerTitleQuery>;
export type GetCustomerTitleLazyQueryHookResult = ReturnType<typeof useGetCustomerTitleLazyQuery>;
export type GetCustomerTitleQueryResult = ApolloReactCommon.QueryResult<GetCustomerTitleQuery, GetCustomerTitleQueryVariables>;
export const GetSubcontractorTitleDocument = gql`
    query getSubcontractorTitle($ID: String, $orderByAsc: String) {
  getSubcontractor(ID: $ID, orderByAsc: $orderByAsc) {
    ID
    name
  }
}
    `;

/**
 * __useGetSubcontractorTitleQuery__
 *
 * To run a query within a React component, call `useGetSubcontractorTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractorTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractorTitleQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetSubcontractorTitleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractorTitleQuery, GetSubcontractorTitleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractorTitleQuery, GetSubcontractorTitleQueryVariables>(GetSubcontractorTitleDocument, baseOptions);
      }
export function useGetSubcontractorTitleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractorTitleQuery, GetSubcontractorTitleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractorTitleQuery, GetSubcontractorTitleQueryVariables>(GetSubcontractorTitleDocument, baseOptions);
        }
export type GetSubcontractorTitleQueryHookResult = ReturnType<typeof useGetSubcontractorTitleQuery>;
export type GetSubcontractorTitleLazyQueryHookResult = ReturnType<typeof useGetSubcontractorTitleLazyQuery>;
export type GetSubcontractorTitleQueryResult = ApolloReactCommon.QueryResult<GetSubcontractorTitleQuery, GetSubcontractorTitleQueryVariables>;
export const GetSubcontractorExecutiveSummaryDocument = gql`
    query getSubcontractorExecutiveSummary($subcontractorID: String, $subcontractID: String, $contractID: String) {
  subconProfileDate(subcontractorID: $subcontractorID, subcontractID: $subcontractID)
  subconAdvancesInfo(subcontractorID: $subcontractorID, subcontractID: $subcontractID)
  subconClaimsInfo(subcontractorID: $subcontractorID, subcontractID: $subcontractID)
  subconProgressClaimsInfo(subcontractorID: $subcontractorID, subcontractID: $subcontractID)
  subconRetentionClaimsInfo(subcontractorID: $subcontractorID, subcontractID: $subcontractID)
  subconDNInfo(subcontractorID: $subcontractorID, subcontractID: $subcontractID)
  subconCNInfo(subcontractorID: $subcontractorID, subcontractID: $subcontractID)
  submittedPaymentNotification(subcontractorID: $subcontractorID, subcontractID: $subcontractID)
  subconPaymentInfo(subcontractorID: $subcontractorID, subcontractID: $subcontractID)
  subconRefundsInfo(subcontractorID: $subcontractorID, subcontractID: $subcontractID)
  DNtoSubconInfo(subcontractorID: $subcontractorID, subcontractID: $subcontractID)
  outstandingSubcon(subcontractorID: $subcontractorID, subcontractID: $subcontractID)
  unallocatedSubcon(subcontractorID: $subcontractorID, subcontractID: $subcontractID)
}
    `;

/**
 * __useGetSubcontractorExecutiveSummaryQuery__
 *
 * To run a query within a React component, call `useGetSubcontractorExecutiveSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractorExecutiveSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractorExecutiveSummaryQuery({
 *   variables: {
 *      subcontractorID: // value for 'subcontractorID'
 *      subcontractID: // value for 'subcontractID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetSubcontractorExecutiveSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractorExecutiveSummaryQuery, GetSubcontractorExecutiveSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractorExecutiveSummaryQuery, GetSubcontractorExecutiveSummaryQueryVariables>(GetSubcontractorExecutiveSummaryDocument, baseOptions);
      }
export function useGetSubcontractorExecutiveSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractorExecutiveSummaryQuery, GetSubcontractorExecutiveSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractorExecutiveSummaryQuery, GetSubcontractorExecutiveSummaryQueryVariables>(GetSubcontractorExecutiveSummaryDocument, baseOptions);
        }
export type GetSubcontractorExecutiveSummaryQueryHookResult = ReturnType<typeof useGetSubcontractorExecutiveSummaryQuery>;
export type GetSubcontractorExecutiveSummaryLazyQueryHookResult = ReturnType<typeof useGetSubcontractorExecutiveSummaryLazyQuery>;
export type GetSubcontractorExecutiveSummaryQueryResult = ApolloReactCommon.QueryResult<GetSubcontractorExecutiveSummaryQuery, GetSubcontractorExecutiveSummaryQueryVariables>;
export const MaxRetentionAmtDocument = gql`
    query maxRetentionAmt($contractID: String, $subcontractID: String) {
  maxRetention(contractID: $contractID, subcontractID: $subcontractID)
}
    `;

/**
 * __useMaxRetentionAmtQuery__
 *
 * To run a query within a React component, call `useMaxRetentionAmtQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaxRetentionAmtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaxRetentionAmtQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      subcontractID: // value for 'subcontractID'
 *   },
 * });
 */
export function useMaxRetentionAmtQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MaxRetentionAmtQuery, MaxRetentionAmtQueryVariables>) {
        return ApolloReactHooks.useQuery<MaxRetentionAmtQuery, MaxRetentionAmtQueryVariables>(MaxRetentionAmtDocument, baseOptions);
      }
export function useMaxRetentionAmtLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MaxRetentionAmtQuery, MaxRetentionAmtQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MaxRetentionAmtQuery, MaxRetentionAmtQueryVariables>(MaxRetentionAmtDocument, baseOptions);
        }
export type MaxRetentionAmtQueryHookResult = ReturnType<typeof useMaxRetentionAmtQuery>;
export type MaxRetentionAmtLazyQueryHookResult = ReturnType<typeof useMaxRetentionAmtLazyQuery>;
export type MaxRetentionAmtQueryResult = ApolloReactCommon.QueryResult<MaxRetentionAmtQuery, MaxRetentionAmtQueryVariables>;
export const ClaimLatestApprovedIdPerContractDocument = gql`
    query claimLatestApprovedIDPerContract($identifier: String!) {
  cancellableClaims(identifier: $identifier)
}
    `;

/**
 * __useClaimLatestApprovedIdPerContractQuery__
 *
 * To run a query within a React component, call `useClaimLatestApprovedIdPerContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useClaimLatestApprovedIdPerContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClaimLatestApprovedIdPerContractQuery({
 *   variables: {
 *      identifier: // value for 'identifier'
 *   },
 * });
 */
export function useClaimLatestApprovedIdPerContractQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClaimLatestApprovedIdPerContractQuery, ClaimLatestApprovedIdPerContractQueryVariables>) {
        return ApolloReactHooks.useQuery<ClaimLatestApprovedIdPerContractQuery, ClaimLatestApprovedIdPerContractQueryVariables>(ClaimLatestApprovedIdPerContractDocument, baseOptions);
      }
export function useClaimLatestApprovedIdPerContractLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClaimLatestApprovedIdPerContractQuery, ClaimLatestApprovedIdPerContractQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClaimLatestApprovedIdPerContractQuery, ClaimLatestApprovedIdPerContractQueryVariables>(ClaimLatestApprovedIdPerContractDocument, baseOptions);
        }
export type ClaimLatestApprovedIdPerContractQueryHookResult = ReturnType<typeof useClaimLatestApprovedIdPerContractQuery>;
export type ClaimLatestApprovedIdPerContractLazyQueryHookResult = ReturnType<typeof useClaimLatestApprovedIdPerContractLazyQuery>;
export type ClaimLatestApprovedIdPerContractQueryResult = ApolloReactCommon.QueryResult<ClaimLatestApprovedIdPerContractQuery, ClaimLatestApprovedIdPerContractQueryVariables>;
export const GetCustomerDocument = gql`
    query getCustomer($ID: String, $orderByAsc: String) {
  getCustomer(ID: $ID, orderByAsc: $orderByAsc) {
    ID
    name
    accountID
    registrationNo
    email
    contactNo
    address
    contactPerson {
      ID
      name
      designation
      email
      contactNo
    }
    associatedStatus
    createdTs
    createdBy
    modTs
    modBy
    creditTerm
    taxDetails
    currencyID
    taxTypeID
    contract {
      ID
      title
      contractSum
    }
    contractClaim {
      ID
      outstandingAmt
    }
    clientAgingInfo
  }
}
    `;

/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetCustomerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, baseOptions);
      }
export function useGetCustomerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, baseOptions);
        }
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>;
export type GetCustomerLazyQueryHookResult = ReturnType<typeof useGetCustomerLazyQuery>;
export type GetCustomerQueryResult = ApolloReactCommon.QueryResult<GetCustomerQuery, GetCustomerQueryVariables>;
export const GetCustomerListDocument = gql`
    query getCustomerList($ID: String, $orderByAsc: String) {
  getCustomer(ID: $ID, orderByAsc: $orderByAsc) {
    ID
    name
  }
}
    `;

/**
 * __useGetCustomerListQuery__
 *
 * To run a query within a React component, call `useGetCustomerListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerListQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetCustomerListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCustomerListQuery, GetCustomerListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCustomerListQuery, GetCustomerListQueryVariables>(GetCustomerListDocument, baseOptions);
      }
export function useGetCustomerListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomerListQuery, GetCustomerListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCustomerListQuery, GetCustomerListQueryVariables>(GetCustomerListDocument, baseOptions);
        }
export type GetCustomerListQueryHookResult = ReturnType<typeof useGetCustomerListQuery>;
export type GetCustomerListLazyQueryHookResult = ReturnType<typeof useGetCustomerListLazyQuery>;
export type GetCustomerListQueryResult = ApolloReactCommon.QueryResult<GetCustomerListQuery, GetCustomerListQueryVariables>;
export const UpdateCustomerDocument = gql`
    mutation updateCustomer($input: CustomerInput!) {
  updateCustomer(input: $input) {
    ID
    name
  }
}
    `;
export type UpdateCustomerMutationFn = ApolloReactCommon.MutationFunction<UpdateCustomerMutation, UpdateCustomerMutationVariables>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(UpdateCustomerDocument, baseOptions);
      }
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = ApolloReactCommon.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>;
export const CreateCustomerDocument = gql`
    mutation createCustomer($input: CustomerInput!) {
  createCustomer(input: $input) {
    ID
    name
  }
}
    `;
export type CreateCustomerMutationFn = ApolloReactCommon.MutationFunction<CreateCustomerMutation, CreateCustomerMutationVariables>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCustomerMutation, CreateCustomerMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(CreateCustomerDocument, baseOptions);
      }
export type CreateCustomerMutationHookResult = ReturnType<typeof useCreateCustomerMutation>;
export type CreateCustomerMutationResult = ApolloReactCommon.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCustomerMutation, CreateCustomerMutationVariables>;
export const AddCustomerContactDocument = gql`
    mutation addCustomerContact($ID: String!, $contactInput: [ContactPersonInput!]!, $platformAccess: Boolean!) {
  addCustomerContact(ID: $ID, contactInput: $contactInput, platformAccess: $platformAccess)
}
    `;
export type AddCustomerContactMutationFn = ApolloReactCommon.MutationFunction<AddCustomerContactMutation, AddCustomerContactMutationVariables>;

/**
 * __useAddCustomerContactMutation__
 *
 * To run a mutation, you first call `useAddCustomerContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCustomerContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCustomerContactMutation, { data, loading, error }] = useAddCustomerContactMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      contactInput: // value for 'contactInput'
 *      platformAccess: // value for 'platformAccess'
 *   },
 * });
 */
export function useAddCustomerContactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddCustomerContactMutation, AddCustomerContactMutationVariables>) {
        return ApolloReactHooks.useMutation<AddCustomerContactMutation, AddCustomerContactMutationVariables>(AddCustomerContactDocument, baseOptions);
      }
export type AddCustomerContactMutationHookResult = ReturnType<typeof useAddCustomerContactMutation>;
export type AddCustomerContactMutationResult = ApolloReactCommon.MutationResult<AddCustomerContactMutation>;
export type AddCustomerContactMutationOptions = ApolloReactCommon.BaseMutationOptions<AddCustomerContactMutation, AddCustomerContactMutationVariables>;
export const GetClientSummaryDocument = gql`
    query getClientSummary {
  totalClients
  clientNotifications
  totalDebitClient
  totalCreditClient
  totalOutstandingClients
  totalOutstandingAmtClients
}
    `;

/**
 * __useGetClientSummaryQuery__
 *
 * To run a query within a React component, call `useGetClientSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClientSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClientSummaryQuery, GetClientSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClientSummaryQuery, GetClientSummaryQueryVariables>(GetClientSummaryDocument, baseOptions);
      }
export function useGetClientSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClientSummaryQuery, GetClientSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClientSummaryQuery, GetClientSummaryQueryVariables>(GetClientSummaryDocument, baseOptions);
        }
export type GetClientSummaryQueryHookResult = ReturnType<typeof useGetClientSummaryQuery>;
export type GetClientSummaryLazyQueryHookResult = ReturnType<typeof useGetClientSummaryLazyQuery>;
export type GetClientSummaryQueryResult = ApolloReactCommon.QueryResult<GetClientSummaryQuery, GetClientSummaryQueryVariables>;
export const CreditControlCustomerDocument = gql`
    query creditControlCustomer($ID: String) {
  getCustomer(ID: $ID) {
    ID
    name
    activeContract
    overdueInvInfo
    clientReceiptInfo
    clientAgingInfo
    currencyID
    followUp {
      createdTs
    }
  }
}
    `;

/**
 * __useCreditControlCustomerQuery__
 *
 * To run a query within a React component, call `useCreditControlCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditControlCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditControlCustomerQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useCreditControlCustomerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CreditControlCustomerQuery, CreditControlCustomerQueryVariables>) {
        return ApolloReactHooks.useQuery<CreditControlCustomerQuery, CreditControlCustomerQueryVariables>(CreditControlCustomerDocument, baseOptions);
      }
export function useCreditControlCustomerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CreditControlCustomerQuery, CreditControlCustomerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CreditControlCustomerQuery, CreditControlCustomerQueryVariables>(CreditControlCustomerDocument, baseOptions);
        }
export type CreditControlCustomerQueryHookResult = ReturnType<typeof useCreditControlCustomerQuery>;
export type CreditControlCustomerLazyQueryHookResult = ReturnType<typeof useCreditControlCustomerLazyQuery>;
export type CreditControlCustomerQueryResult = ApolloReactCommon.QueryResult<CreditControlCustomerQuery, CreditControlCustomerQueryVariables>;
export const GetCustomerFollowUpLogDocument = gql`
    query getCustomerFollowUpLog($ID: String) {
  getCustomer(ID: $ID) {
    ID
    contactNo
    contactPerson {
      ID
      name
      designation
      email
      contactNo
    }
    followUp {
      ID
      remarks
      contactPerson
      followUpStatus
      createdTs
    }
  }
}
    `;

/**
 * __useGetCustomerFollowUpLogQuery__
 *
 * To run a query within a React component, call `useGetCustomerFollowUpLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerFollowUpLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerFollowUpLogQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetCustomerFollowUpLogQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCustomerFollowUpLogQuery, GetCustomerFollowUpLogQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCustomerFollowUpLogQuery, GetCustomerFollowUpLogQueryVariables>(GetCustomerFollowUpLogDocument, baseOptions);
      }
export function useGetCustomerFollowUpLogLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomerFollowUpLogQuery, GetCustomerFollowUpLogQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCustomerFollowUpLogQuery, GetCustomerFollowUpLogQueryVariables>(GetCustomerFollowUpLogDocument, baseOptions);
        }
export type GetCustomerFollowUpLogQueryHookResult = ReturnType<typeof useGetCustomerFollowUpLogQuery>;
export type GetCustomerFollowUpLogLazyQueryHookResult = ReturnType<typeof useGetCustomerFollowUpLogLazyQuery>;
export type GetCustomerFollowUpLogQueryResult = ApolloReactCommon.QueryResult<GetCustomerFollowUpLogQuery, GetCustomerFollowUpLogQueryVariables>;
export const CreateFollowUpDocument = gql`
    mutation createFollowUp($input: FollowUpInput!) {
  createFollowUp(input: $input) {
    ID
  }
}
    `;
export type CreateFollowUpMutationFn = ApolloReactCommon.MutationFunction<CreateFollowUpMutation, CreateFollowUpMutationVariables>;

/**
 * __useCreateFollowUpMutation__
 *
 * To run a mutation, you first call `useCreateFollowUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFollowUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFollowUpMutation, { data, loading, error }] = useCreateFollowUpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFollowUpMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFollowUpMutation, CreateFollowUpMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateFollowUpMutation, CreateFollowUpMutationVariables>(CreateFollowUpDocument, baseOptions);
      }
export type CreateFollowUpMutationHookResult = ReturnType<typeof useCreateFollowUpMutation>;
export type CreateFollowUpMutationResult = ApolloReactCommon.MutationResult<CreateFollowUpMutation>;
export type CreateFollowUpMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateFollowUpMutation, CreateFollowUpMutationVariables>;
export const GetClientExecutiveSummaryDocument = gql`
    query getClientExecutiveSummary($customerID: String, $contractID: String) {
  clientProfileDate(customerID: $customerID, contractID: $contractID)
  advancesInfo(customerID: $customerID, contractID: $contractID)
  claimsInfo(customerID: $customerID, contractID: $contractID)
  progressClaimsInfo(customerID: $customerID, contractID: $contractID)
  retentionClaimsInfo(customerID: $customerID, contractID: $contractID)
  submittedClaimNotification(customerID: $customerID)
  DNInfo(customerID: $customerID, contractID: $contractID)
  CNInfo(customerID: $customerID, contractID: $contractID)
  receiptsInfo(customerID: $customerID, contractID: $contractID)
  refundsInfo(customerID: $customerID, contractID: $contractID)
  clientDNInfo(customerID: $customerID, contractID: $contractID)
  outstandingClient(customerID: $customerID, contractID: $contractID)
  unallocatedClient(customerID: $customerID, contractID: $contractID)
}
    `;

/**
 * __useGetClientExecutiveSummaryQuery__
 *
 * To run a query within a React component, call `useGetClientExecutiveSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientExecutiveSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientExecutiveSummaryQuery({
 *   variables: {
 *      customerID: // value for 'customerID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetClientExecutiveSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClientExecutiveSummaryQuery, GetClientExecutiveSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClientExecutiveSummaryQuery, GetClientExecutiveSummaryQueryVariables>(GetClientExecutiveSummaryDocument, baseOptions);
      }
export function useGetClientExecutiveSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClientExecutiveSummaryQuery, GetClientExecutiveSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClientExecutiveSummaryQuery, GetClientExecutiveSummaryQueryVariables>(GetClientExecutiveSummaryDocument, baseOptions);
        }
export type GetClientExecutiveSummaryQueryHookResult = ReturnType<typeof useGetClientExecutiveSummaryQuery>;
export type GetClientExecutiveSummaryLazyQueryHookResult = ReturnType<typeof useGetClientExecutiveSummaryLazyQuery>;
export type GetClientExecutiveSummaryQueryResult = ApolloReactCommon.QueryResult<GetClientExecutiveSummaryQuery, GetClientExecutiveSummaryQueryVariables>;
export const GetContractClaimDocument = gql`
    query getContractClaim($ID: String, $contractID: String, $customerID: String) {
  getContractClaim(ID: $ID, contractID: $contractID, customerID: $customerID) {
    ID
    docNo
    claimDocNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    docDueDate
    submittedDate
    previousCertifiedAmt
    submittedCertifiedAmt
    currentCertifiedAmt
    previousVOClaimAmt
    submittedVOClaimAmt
    currentVOClaimAmt
    previousMosAmt
    submittedMosAmt
    currentMosAmt
    retentionAmt
    submittedDayWorkAmt
    currentDayWorkAmt
    balanceAdvanceAmt
    currentAdvRecoupmentAmt
    currentDeductionAmt
    submittedDeductionAmt
    balanceDeductionAmt
    transactionStatus
    rejectedDate
    rejectionRemarks
    cancellationDate
    cancellationRemarks
    contractID
    customerID
    outstandingAmt
    modBy
    createdBy
    contractAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
    }
  }
  claimsInfo(contractID: $contractID, customerID: $customerID)
}
    `;

/**
 * __useGetContractClaimQuery__
 *
 * To run a query within a React component, call `useGetContractClaimQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractClaimQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractClaimQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *   },
 * });
 */
export function useGetContractClaimQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractClaimQuery, GetContractClaimQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractClaimQuery, GetContractClaimQueryVariables>(GetContractClaimDocument, baseOptions);
      }
export function useGetContractClaimLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractClaimQuery, GetContractClaimQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractClaimQuery, GetContractClaimQueryVariables>(GetContractClaimDocument, baseOptions);
        }
export type GetContractClaimQueryHookResult = ReturnType<typeof useGetContractClaimQuery>;
export type GetContractClaimLazyQueryHookResult = ReturnType<typeof useGetContractClaimLazyQuery>;
export type GetContractClaimQueryResult = ApolloReactCommon.QueryResult<GetContractClaimQuery, GetContractClaimQueryVariables>;
export const GetConProgressClaimDocument = gql`
    query getConProgressClaim($contractID: String, $customerID: String) {
  getProgressClaim(contractID: $contractID, customerID: $customerID) {
    ID
    isGlExport
    accountID
    balanceAdvanceAmt
    balanceDeductionAmt
    balanceRetentionAmt
    baseAmt
    cancellationRemarks
    cancellationDate
    claimDocNo
    contractID
    createdBy
    createdTs
    currencyID
    currentAdvRecoupmentAmt
    currentCertifiedAmt
    currentDayWorkAmt
    currentDeductionAmt
    currentMosAmt
    customerID
    currentVOClaimAmt
    description
    docAmt
    docDate
    docDueDate
    docNo
    docRef
    docRefID
    docRefTable
    exchangeRate
    localBaseAmt
    localDocAmt
    localTaxAmt
    modBy
    modTs
    previousCertifiedAmt
    previousMosAmt
    previousVOClaimAmt
    rejectedDate
    rejectionDate
    rejectionRemarks
    retentionAmt
    revisedNo
    sequenceNo
    submittedCertifiedAmt
    submittedDate
    submittedDayWorkAmt
    submittedDeductionAmt
    submittedMosAmt
    submittedRetentionAmt
    submittedVOClaimAmt
    taxAmt
    taxRate
    taxSchemeID
    toDo {
      ID
      accountID
      approvalLevel
      approvalLog {
        ID
        accountID
        approverID
        createdBy
        createdTs
        logDate
        modBy
        modTs
        notifiedDate
        remarks
        toDoID
        toDoStatus
      }
      body
      createdBy
      createdTs
      docRefAmt
      docRefID
      docRefTable
      modBy
      modTs
      notifiedDate
      subject
      submitterID
      toDoStatus
    }
    transactionDate
    transactionStatus
    voDisplayAmt
  }
  progressClaimsInfo(contractID: $contractID, customerID: $customerID)
}
    `;

/**
 * __useGetConProgressClaimQuery__
 *
 * To run a query within a React component, call `useGetConProgressClaimQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConProgressClaimQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConProgressClaimQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *   },
 * });
 */
export function useGetConProgressClaimQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetConProgressClaimQuery, GetConProgressClaimQueryVariables>) {
        return ApolloReactHooks.useQuery<GetConProgressClaimQuery, GetConProgressClaimQueryVariables>(GetConProgressClaimDocument, baseOptions);
      }
export function useGetConProgressClaimLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetConProgressClaimQuery, GetConProgressClaimQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetConProgressClaimQuery, GetConProgressClaimQueryVariables>(GetConProgressClaimDocument, baseOptions);
        }
export type GetConProgressClaimQueryHookResult = ReturnType<typeof useGetConProgressClaimQuery>;
export type GetConProgressClaimLazyQueryHookResult = ReturnType<typeof useGetConProgressClaimLazyQuery>;
export type GetConProgressClaimQueryResult = ApolloReactCommon.QueryResult<GetConProgressClaimQuery, GetConProgressClaimQueryVariables>;
export const GetConRetentionClaimDocument = gql`
    query getConRetentionClaim($contractID: String, $customerID: String) {
  getRetentionClaim(contractID: $contractID, customerID: $customerID) {
    ID
    isGlExport
    accountID
    balanceAdvanceAmt
    balanceDeductionAmt
    balanceRetentionAmt
    baseAmt
    cancellationDate
    cancellationRemarks
    claimDocNo
    contractID
    createdBy
    createdTs
    currencyID
    currentAdvRecoupmentAmt
    currentCertifiedAmt
    currentDayWorkAmt
    currentDeductionAmt
    currentMosAmt
    currentVOClaimAmt
    customerID
    description
    docAmt
    docDate
    docDueDate
    docNo
    docRef
    docRefID
    docRefTable
    exchangeRate
    localBaseAmt
    localDocAmt
    localTaxAmt
    modBy
    modTs
    previousCertifiedAmt
    previousMosAmt
    previousVOClaimAmt
    rejectedDate
    rejectionDate
    rejectionRemarks
    retentionAmt
    revisedNo
    sequenceNo
    submittedCertifiedAmt
    submittedDate
    submittedDayWorkAmt
    submittedDeductionAmt
    submittedMosAmt
    submittedRetentionAmt
    submittedVOClaimAmt
    taxAmt
    taxRate
    taxSchemeID
    toDo {
      ID
      accountID
      approvalLevel
      approvalLog {
        ID
        accountID
        approverID
        createdBy
        createdTs
        logDate
        modBy
        modTs
        notifiedDate
        remarks
        toDoID
        toDoStatus
      }
      body
      createdBy
      createdTs
      docRefAmt
      docRefID
      docRefTable
      modBy
      modTs
      notifiedDate
      subject
      submitterID
      toDoStatus
    }
    transactionDate
    transactionStatus
    voDisplayAmt
  }
  retentionClaimsInfo(contractID: $contractID, customerID: $customerID)
}
    `;

/**
 * __useGetConRetentionClaimQuery__
 *
 * To run a query within a React component, call `useGetConRetentionClaimQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConRetentionClaimQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConRetentionClaimQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *   },
 * });
 */
export function useGetConRetentionClaimQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetConRetentionClaimQuery, GetConRetentionClaimQueryVariables>) {
        return ApolloReactHooks.useQuery<GetConRetentionClaimQuery, GetConRetentionClaimQueryVariables>(GetConRetentionClaimDocument, baseOptions);
      }
export function useGetConRetentionClaimLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetConRetentionClaimQuery, GetConRetentionClaimQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetConRetentionClaimQuery, GetConRetentionClaimQueryVariables>(GetConRetentionClaimDocument, baseOptions);
        }
export type GetConRetentionClaimQueryHookResult = ReturnType<typeof useGetConRetentionClaimQuery>;
export type GetConRetentionClaimLazyQueryHookResult = ReturnType<typeof useGetConRetentionClaimLazyQuery>;
export type GetConRetentionClaimQueryResult = ApolloReactCommon.QueryResult<GetConRetentionClaimQuery, GetConRetentionClaimQueryVariables>;
export const CreateContractClaimDocument = gql`
    mutation createContractClaim($input: ContractClaimInput!) {
  createContractClaim(input: $input) {
    ID
    accountID
  }
}
    `;
export type CreateContractClaimMutationFn = ApolloReactCommon.MutationFunction<CreateContractClaimMutation, CreateContractClaimMutationVariables>;

/**
 * __useCreateContractClaimMutation__
 *
 * To run a mutation, you first call `useCreateContractClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractClaimMutation, { data, loading, error }] = useCreateContractClaimMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContractClaimMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContractClaimMutation, CreateContractClaimMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContractClaimMutation, CreateContractClaimMutationVariables>(CreateContractClaimDocument, baseOptions);
      }
export type CreateContractClaimMutationHookResult = ReturnType<typeof useCreateContractClaimMutation>;
export type CreateContractClaimMutationResult = ApolloReactCommon.MutationResult<CreateContractClaimMutation>;
export type CreateContractClaimMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateContractClaimMutation, CreateContractClaimMutationVariables>;
export const SubmitRetentionClaimDocument = gql`
    mutation submitRetentionClaim($input: ClaimRetentionInput!) {
  submitRetentionClaim(input: $input) {
    ID
  }
}
    `;
export type SubmitRetentionClaimMutationFn = ApolloReactCommon.MutationFunction<SubmitRetentionClaimMutation, SubmitRetentionClaimMutationVariables>;

/**
 * __useSubmitRetentionClaimMutation__
 *
 * To run a mutation, you first call `useSubmitRetentionClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitRetentionClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitRetentionClaimMutation, { data, loading, error }] = useSubmitRetentionClaimMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitRetentionClaimMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitRetentionClaimMutation, SubmitRetentionClaimMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitRetentionClaimMutation, SubmitRetentionClaimMutationVariables>(SubmitRetentionClaimDocument, baseOptions);
      }
export type SubmitRetentionClaimMutationHookResult = ReturnType<typeof useSubmitRetentionClaimMutation>;
export type SubmitRetentionClaimMutationResult = ApolloReactCommon.MutationResult<SubmitRetentionClaimMutation>;
export type SubmitRetentionClaimMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitRetentionClaimMutation, SubmitRetentionClaimMutationVariables>;
export const SubmitClaimDocument = gql`
    mutation submitClaim($deductionLedger: [LedgerInput!], $input: ContractClaimInput!) {
  submitClaim(input: $input, deductionLedger: $deductionLedger) {
    ID
  }
}
    `;
export type SubmitClaimMutationFn = ApolloReactCommon.MutationFunction<SubmitClaimMutation, SubmitClaimMutationVariables>;

/**
 * __useSubmitClaimMutation__
 *
 * To run a mutation, you first call `useSubmitClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitClaimMutation, { data, loading, error }] = useSubmitClaimMutation({
 *   variables: {
 *      deductionLedger: // value for 'deductionLedger'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitClaimMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitClaimMutation, SubmitClaimMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitClaimMutation, SubmitClaimMutationVariables>(SubmitClaimDocument, baseOptions);
      }
export type SubmitClaimMutationHookResult = ReturnType<typeof useSubmitClaimMutation>;
export type SubmitClaimMutationResult = ApolloReactCommon.MutationResult<SubmitClaimMutation>;
export type SubmitClaimMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitClaimMutation, SubmitClaimMutationVariables>;
export const ApproveClaimDocument = gql`
    mutation approveClaim($input: ContractClaimInput!) {
  approveClaim(input: $input)
}
    `;
export type ApproveClaimMutationFn = ApolloReactCommon.MutationFunction<ApproveClaimMutation, ApproveClaimMutationVariables>;

/**
 * __useApproveClaimMutation__
 *
 * To run a mutation, you first call `useApproveClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveClaimMutation, { data, loading, error }] = useApproveClaimMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveClaimMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApproveClaimMutation, ApproveClaimMutationVariables>) {
        return ApolloReactHooks.useMutation<ApproveClaimMutation, ApproveClaimMutationVariables>(ApproveClaimDocument, baseOptions);
      }
export type ApproveClaimMutationHookResult = ReturnType<typeof useApproveClaimMutation>;
export type ApproveClaimMutationResult = ApolloReactCommon.MutationResult<ApproveClaimMutation>;
export type ApproveClaimMutationOptions = ApolloReactCommon.BaseMutationOptions<ApproveClaimMutation, ApproveClaimMutationVariables>;
export const UpdateContractClaimDocument = gql`
    mutation updateContractClaim($input: ContractClaimInput!) {
  updateContractClaim(input: $input) {
    accountID
  }
}
    `;
export type UpdateContractClaimMutationFn = ApolloReactCommon.MutationFunction<UpdateContractClaimMutation, UpdateContractClaimMutationVariables>;

/**
 * __useUpdateContractClaimMutation__
 *
 * To run a mutation, you first call `useUpdateContractClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractClaimMutation, { data, loading, error }] = useUpdateContractClaimMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractClaimMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContractClaimMutation, UpdateContractClaimMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateContractClaimMutation, UpdateContractClaimMutationVariables>(UpdateContractClaimDocument, baseOptions);
      }
export type UpdateContractClaimMutationHookResult = ReturnType<typeof useUpdateContractClaimMutation>;
export type UpdateContractClaimMutationResult = ApolloReactCommon.MutationResult<UpdateContractClaimMutation>;
export type UpdateContractClaimMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateContractClaimMutation, UpdateContractClaimMutationVariables>;
export const CancelClaimDocument = gql`
    mutation cancelClaim($ID: String!, $input: CancelInput!) {
  cancelClaim(ID: $ID, input: $input)
}
    `;
export type CancelClaimMutationFn = ApolloReactCommon.MutationFunction<CancelClaimMutation, CancelClaimMutationVariables>;

/**
 * __useCancelClaimMutation__
 *
 * To run a mutation, you first call `useCancelClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelClaimMutation, { data, loading, error }] = useCancelClaimMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelClaimMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelClaimMutation, CancelClaimMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelClaimMutation, CancelClaimMutationVariables>(CancelClaimDocument, baseOptions);
      }
export type CancelClaimMutationHookResult = ReturnType<typeof useCancelClaimMutation>;
export type CancelClaimMutationResult = ApolloReactCommon.MutationResult<CancelClaimMutation>;
export type CancelClaimMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelClaimMutation, CancelClaimMutationVariables>;
export const GetContractDnDocument = gql`
    query getContractDN($ID: String, $contractID: String, $customerID: String) {
  getContractDN(ID: $ID, contractID: $contractID, customerID: $customerID) {
    ID
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    transactionStatus
    customerID
    contractID
    outstandingAmt
    contractAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
    }
  }
  DNInfo(contractID: $contractID, customerID: $customerID)
}
    `;

/**
 * __useGetContractDnQuery__
 *
 * To run a query within a React component, call `useGetContractDnQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractDnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractDnQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *   },
 * });
 */
export function useGetContractDnQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractDnQuery, GetContractDnQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractDnQuery, GetContractDnQueryVariables>(GetContractDnDocument, baseOptions);
      }
export function useGetContractDnLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractDnQuery, GetContractDnQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractDnQuery, GetContractDnQueryVariables>(GetContractDnDocument, baseOptions);
        }
export type GetContractDnQueryHookResult = ReturnType<typeof useGetContractDnQuery>;
export type GetContractDnLazyQueryHookResult = ReturnType<typeof useGetContractDnLazyQuery>;
export type GetContractDnQueryResult = ApolloReactCommon.QueryResult<GetContractDnQuery, GetContractDnQueryVariables>;
export const CreateContractDnDocument = gql`
    mutation createContractDN($input: ContractTransactionInput!) {
  createContractDN(input: $input) {
    accountID
  }
}
    `;
export type CreateContractDnMutationFn = ApolloReactCommon.MutationFunction<CreateContractDnMutation, CreateContractDnMutationVariables>;

/**
 * __useCreateContractDnMutation__
 *
 * To run a mutation, you first call `useCreateContractDnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractDnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractDnMutation, { data, loading, error }] = useCreateContractDnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContractDnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContractDnMutation, CreateContractDnMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContractDnMutation, CreateContractDnMutationVariables>(CreateContractDnDocument, baseOptions);
      }
export type CreateContractDnMutationHookResult = ReturnType<typeof useCreateContractDnMutation>;
export type CreateContractDnMutationResult = ApolloReactCommon.MutationResult<CreateContractDnMutation>;
export type CreateContractDnMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateContractDnMutation, CreateContractDnMutationVariables>;
export const UpdateContractDnDocument = gql`
    mutation updateContractDN($input: ContractTransactionInput!) {
  updateContractDN(input: $input) {
    accountID
  }
}
    `;
export type UpdateContractDnMutationFn = ApolloReactCommon.MutationFunction<UpdateContractDnMutation, UpdateContractDnMutationVariables>;

/**
 * __useUpdateContractDnMutation__
 *
 * To run a mutation, you first call `useUpdateContractDnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractDnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractDnMutation, { data, loading, error }] = useUpdateContractDnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractDnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContractDnMutation, UpdateContractDnMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateContractDnMutation, UpdateContractDnMutationVariables>(UpdateContractDnDocument, baseOptions);
      }
export type UpdateContractDnMutationHookResult = ReturnType<typeof useUpdateContractDnMutation>;
export type UpdateContractDnMutationResult = ApolloReactCommon.MutationResult<UpdateContractDnMutation>;
export type UpdateContractDnMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateContractDnMutation, UpdateContractDnMutationVariables>;
export const GetContractCnDocument = gql`
    query getContractCN($ID: String, $contractID: String, $customerID: String) {
  getContractCN(ID: $ID, contractID: $contractID, customerID: $customerID) {
    ID
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    transactionStatus
    customerID
    contractID
    outstandingAmt
    contractAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
    }
  }
  CNInfo(contractID: $contractID, customerID: $customerID)
}
    `;

/**
 * __useGetContractCnQuery__
 *
 * To run a query within a React component, call `useGetContractCnQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractCnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractCnQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *   },
 * });
 */
export function useGetContractCnQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractCnQuery, GetContractCnQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractCnQuery, GetContractCnQueryVariables>(GetContractCnDocument, baseOptions);
      }
export function useGetContractCnLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractCnQuery, GetContractCnQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractCnQuery, GetContractCnQueryVariables>(GetContractCnDocument, baseOptions);
        }
export type GetContractCnQueryHookResult = ReturnType<typeof useGetContractCnQuery>;
export type GetContractCnLazyQueryHookResult = ReturnType<typeof useGetContractCnLazyQuery>;
export type GetContractCnQueryResult = ApolloReactCommon.QueryResult<GetContractCnQuery, GetContractCnQueryVariables>;
export const CreateContractCnDocument = gql`
    mutation createContractCN($input: ContractTransactionInput!) {
  createContractCN(input: $input) {
    accountID
  }
}
    `;
export type CreateContractCnMutationFn = ApolloReactCommon.MutationFunction<CreateContractCnMutation, CreateContractCnMutationVariables>;

/**
 * __useCreateContractCnMutation__
 *
 * To run a mutation, you first call `useCreateContractCnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractCnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractCnMutation, { data, loading, error }] = useCreateContractCnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContractCnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContractCnMutation, CreateContractCnMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContractCnMutation, CreateContractCnMutationVariables>(CreateContractCnDocument, baseOptions);
      }
export type CreateContractCnMutationHookResult = ReturnType<typeof useCreateContractCnMutation>;
export type CreateContractCnMutationResult = ApolloReactCommon.MutationResult<CreateContractCnMutation>;
export type CreateContractCnMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateContractCnMutation, CreateContractCnMutationVariables>;
export const UpdateContractCnDocument = gql`
    mutation updateContractCN($input: ContractTransactionInput!) {
  updateContractCN(input: $input) {
    accountID
  }
}
    `;
export type UpdateContractCnMutationFn = ApolloReactCommon.MutationFunction<UpdateContractCnMutation, UpdateContractCnMutationVariables>;

/**
 * __useUpdateContractCnMutation__
 *
 * To run a mutation, you first call `useUpdateContractCnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractCnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractCnMutation, { data, loading, error }] = useUpdateContractCnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractCnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContractCnMutation, UpdateContractCnMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateContractCnMutation, UpdateContractCnMutationVariables>(UpdateContractCnDocument, baseOptions);
      }
export type UpdateContractCnMutationHookResult = ReturnType<typeof useUpdateContractCnMutation>;
export type UpdateContractCnMutationResult = ApolloReactCommon.MutationResult<UpdateContractCnMutation>;
export type UpdateContractCnMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateContractCnMutation, UpdateContractCnMutationVariables>;
export const GetClientDnDocument = gql`
    query getClientDN($ID: String, $contractID: String, $customerID: String) {
  getClientDN(ID: $ID, contractID: $contractID, customerID: $customerID) {
    ID
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    outstandingAmt
    localBaseAmt
    localTaxAmt
    accountID
    transactionStatus
    customerID
    contractID
    deductionType {
      ID
      code
      description
    }
  }
  clientDNInfo(customerID: $customerID)
}
    `;

/**
 * __useGetClientDnQuery__
 *
 * To run a query within a React component, call `useGetClientDnQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientDnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientDnQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *   },
 * });
 */
export function useGetClientDnQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClientDnQuery, GetClientDnQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClientDnQuery, GetClientDnQueryVariables>(GetClientDnDocument, baseOptions);
      }
export function useGetClientDnLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClientDnQuery, GetClientDnQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClientDnQuery, GetClientDnQueryVariables>(GetClientDnDocument, baseOptions);
        }
export type GetClientDnQueryHookResult = ReturnType<typeof useGetClientDnQuery>;
export type GetClientDnLazyQueryHookResult = ReturnType<typeof useGetClientDnLazyQuery>;
export type GetClientDnQueryResult = ApolloReactCommon.QueryResult<GetClientDnQuery, GetClientDnQueryVariables>;
export const CreateClientDnDocument = gql`
    mutation createClientDN($input: ContractTransactionInput!) {
  createClientDN(input: $input) {
    accountID
  }
}
    `;
export type CreateClientDnMutationFn = ApolloReactCommon.MutationFunction<CreateClientDnMutation, CreateClientDnMutationVariables>;

/**
 * __useCreateClientDnMutation__
 *
 * To run a mutation, you first call `useCreateClientDnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientDnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientDnMutation, { data, loading, error }] = useCreateClientDnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientDnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateClientDnMutation, CreateClientDnMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateClientDnMutation, CreateClientDnMutationVariables>(CreateClientDnDocument, baseOptions);
      }
export type CreateClientDnMutationHookResult = ReturnType<typeof useCreateClientDnMutation>;
export type CreateClientDnMutationResult = ApolloReactCommon.MutationResult<CreateClientDnMutation>;
export type CreateClientDnMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateClientDnMutation, CreateClientDnMutationVariables>;
export const UpdateClientDnDocument = gql`
    mutation updateClientDN($input: ContractTransactionInput!) {
  updateClientDN(input: $input) {
    accountID
  }
}
    `;
export type UpdateClientDnMutationFn = ApolloReactCommon.MutationFunction<UpdateClientDnMutation, UpdateClientDnMutationVariables>;

/**
 * __useUpdateClientDnMutation__
 *
 * To run a mutation, you first call `useUpdateClientDnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientDnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientDnMutation, { data, loading, error }] = useUpdateClientDnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientDnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateClientDnMutation, UpdateClientDnMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateClientDnMutation, UpdateClientDnMutationVariables>(UpdateClientDnDocument, baseOptions);
      }
export type UpdateClientDnMutationHookResult = ReturnType<typeof useUpdateClientDnMutation>;
export type UpdateClientDnMutationResult = ApolloReactCommon.MutationResult<UpdateClientDnMutation>;
export type UpdateClientDnMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateClientDnMutation, UpdateClientDnMutationVariables>;
export const GetContractAdvanceDocument = gql`
    query getContractAdvance($ID: String, $contractID: String, $customerID: String) {
  getContractAdvance(ID: $ID, contractID: $contractID, customerID: $customerID) {
    ID
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    bankAccountID
    bankDocAmt
    bankDetails
    receiptStatus
    customerID
    contractID
    outstandingAmt
    contractAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
    }
  }
  advancesInfo(contractID: $contractID, customerID: $customerID)
}
    `;

/**
 * __useGetContractAdvanceQuery__
 *
 * To run a query within a React component, call `useGetContractAdvanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractAdvanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractAdvanceQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *   },
 * });
 */
export function useGetContractAdvanceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractAdvanceQuery, GetContractAdvanceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractAdvanceQuery, GetContractAdvanceQueryVariables>(GetContractAdvanceDocument, baseOptions);
      }
export function useGetContractAdvanceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractAdvanceQuery, GetContractAdvanceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractAdvanceQuery, GetContractAdvanceQueryVariables>(GetContractAdvanceDocument, baseOptions);
        }
export type GetContractAdvanceQueryHookResult = ReturnType<typeof useGetContractAdvanceQuery>;
export type GetContractAdvanceLazyQueryHookResult = ReturnType<typeof useGetContractAdvanceLazyQuery>;
export type GetContractAdvanceQueryResult = ApolloReactCommon.QueryResult<GetContractAdvanceQuery, GetContractAdvanceQueryVariables>;
export const CreateContractAdvanceDocument = gql`
    mutation createContractAdvance($input: ContractReceiptInput!) {
  createContractAdvance(input: $input) {
    ID
  }
}
    `;
export type CreateContractAdvanceMutationFn = ApolloReactCommon.MutationFunction<CreateContractAdvanceMutation, CreateContractAdvanceMutationVariables>;

/**
 * __useCreateContractAdvanceMutation__
 *
 * To run a mutation, you first call `useCreateContractAdvanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractAdvanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractAdvanceMutation, { data, loading, error }] = useCreateContractAdvanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContractAdvanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContractAdvanceMutation, CreateContractAdvanceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContractAdvanceMutation, CreateContractAdvanceMutationVariables>(CreateContractAdvanceDocument, baseOptions);
      }
export type CreateContractAdvanceMutationHookResult = ReturnType<typeof useCreateContractAdvanceMutation>;
export type CreateContractAdvanceMutationResult = ApolloReactCommon.MutationResult<CreateContractAdvanceMutation>;
export type CreateContractAdvanceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateContractAdvanceMutation, CreateContractAdvanceMutationVariables>;
export const UpdateContractAdvanceDocument = gql`
    mutation updateContractAdvance($input: ContractReceiptInput!) {
  updateContractAdvance(input: $input) {
    ID
  }
}
    `;
export type UpdateContractAdvanceMutationFn = ApolloReactCommon.MutationFunction<UpdateContractAdvanceMutation, UpdateContractAdvanceMutationVariables>;

/**
 * __useUpdateContractAdvanceMutation__
 *
 * To run a mutation, you first call `useUpdateContractAdvanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractAdvanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractAdvanceMutation, { data, loading, error }] = useUpdateContractAdvanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractAdvanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContractAdvanceMutation, UpdateContractAdvanceMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateContractAdvanceMutation, UpdateContractAdvanceMutationVariables>(UpdateContractAdvanceDocument, baseOptions);
      }
export type UpdateContractAdvanceMutationHookResult = ReturnType<typeof useUpdateContractAdvanceMutation>;
export type UpdateContractAdvanceMutationResult = ApolloReactCommon.MutationResult<UpdateContractAdvanceMutation>;
export type UpdateContractAdvanceMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateContractAdvanceMutation, UpdateContractAdvanceMutationVariables>;
export const GetContractReceiptDocument = gql`
    query getContractReceipt($ID: String, $contractID: String, $customerID: String) {
  getContractReceipt(ID: $ID, contractID: $contractID, customerID: $customerID) {
    ID
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    bankAccountID
    bankDocAmt
    bankDetails
    receiptStatus
    customerID
    contractID
    outstandingAmt
    contractAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
    }
  }
  receiptsInfo(contractID: $contractID, customerID: $customerID)
}
    `;

/**
 * __useGetContractReceiptQuery__
 *
 * To run a query within a React component, call `useGetContractReceiptQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractReceiptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractReceiptQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *   },
 * });
 */
export function useGetContractReceiptQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractReceiptQuery, GetContractReceiptQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractReceiptQuery, GetContractReceiptQueryVariables>(GetContractReceiptDocument, baseOptions);
      }
export function useGetContractReceiptLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractReceiptQuery, GetContractReceiptQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractReceiptQuery, GetContractReceiptQueryVariables>(GetContractReceiptDocument, baseOptions);
        }
export type GetContractReceiptQueryHookResult = ReturnType<typeof useGetContractReceiptQuery>;
export type GetContractReceiptLazyQueryHookResult = ReturnType<typeof useGetContractReceiptLazyQuery>;
export type GetContractReceiptQueryResult = ApolloReactCommon.QueryResult<GetContractReceiptQuery, GetContractReceiptQueryVariables>;
export const CreateContractReceiptDocument = gql`
    mutation createContractReceipt($input: ContractReceiptInput!) {
  createContractReceipt(input: $input) {
    ID
  }
}
    `;
export type CreateContractReceiptMutationFn = ApolloReactCommon.MutationFunction<CreateContractReceiptMutation, CreateContractReceiptMutationVariables>;

/**
 * __useCreateContractReceiptMutation__
 *
 * To run a mutation, you first call `useCreateContractReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractReceiptMutation, { data, loading, error }] = useCreateContractReceiptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContractReceiptMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContractReceiptMutation, CreateContractReceiptMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContractReceiptMutation, CreateContractReceiptMutationVariables>(CreateContractReceiptDocument, baseOptions);
      }
export type CreateContractReceiptMutationHookResult = ReturnType<typeof useCreateContractReceiptMutation>;
export type CreateContractReceiptMutationResult = ApolloReactCommon.MutationResult<CreateContractReceiptMutation>;
export type CreateContractReceiptMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateContractReceiptMutation, CreateContractReceiptMutationVariables>;
export const UpdateContractReceiptDocument = gql`
    mutation updateContractReceipt($input: ContractReceiptInput!) {
  updateContractReceipt(input: $input) {
    ID
  }
}
    `;
export type UpdateContractReceiptMutationFn = ApolloReactCommon.MutationFunction<UpdateContractReceiptMutation, UpdateContractReceiptMutationVariables>;

/**
 * __useUpdateContractReceiptMutation__
 *
 * To run a mutation, you first call `useUpdateContractReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractReceiptMutation, { data, loading, error }] = useUpdateContractReceiptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractReceiptMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContractReceiptMutation, UpdateContractReceiptMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateContractReceiptMutation, UpdateContractReceiptMutationVariables>(UpdateContractReceiptDocument, baseOptions);
      }
export type UpdateContractReceiptMutationHookResult = ReturnType<typeof useUpdateContractReceiptMutation>;
export type UpdateContractReceiptMutationResult = ApolloReactCommon.MutationResult<UpdateContractReceiptMutation>;
export type UpdateContractReceiptMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateContractReceiptMutation, UpdateContractReceiptMutationVariables>;
export const GetContractRefundDocument = gql`
    query getContractRefund($ID: String, $contractID: String, $customerID: String) {
  getContractRefund(ID: $ID, contractID: $contractID, customerID: $customerID) {
    ID
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    bankAccountID
    bankDocAmt
    bankDetails
    receiptStatus
    customerID
    contractID
    outstandingAmt
    contractAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
    }
  }
  refundsInfo(contractID: $contractID, customerID: $customerID)
}
    `;

/**
 * __useGetContractRefundQuery__
 *
 * To run a query within a React component, call `useGetContractRefundQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractRefundQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractRefundQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *   },
 * });
 */
export function useGetContractRefundQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractRefundQuery, GetContractRefundQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractRefundQuery, GetContractRefundQueryVariables>(GetContractRefundDocument, baseOptions);
      }
export function useGetContractRefundLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractRefundQuery, GetContractRefundQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractRefundQuery, GetContractRefundQueryVariables>(GetContractRefundDocument, baseOptions);
        }
export type GetContractRefundQueryHookResult = ReturnType<typeof useGetContractRefundQuery>;
export type GetContractRefundLazyQueryHookResult = ReturnType<typeof useGetContractRefundLazyQuery>;
export type GetContractRefundQueryResult = ApolloReactCommon.QueryResult<GetContractRefundQuery, GetContractRefundQueryVariables>;
export const CreateContractRefundDocument = gql`
    mutation createContractRefund($input: ContractReceiptInput!) {
  createContractRefund(input: $input) {
    ID
  }
}
    `;
export type CreateContractRefundMutationFn = ApolloReactCommon.MutationFunction<CreateContractRefundMutation, CreateContractRefundMutationVariables>;

/**
 * __useCreateContractRefundMutation__
 *
 * To run a mutation, you first call `useCreateContractRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractRefundMutation, { data, loading, error }] = useCreateContractRefundMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContractRefundMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContractRefundMutation, CreateContractRefundMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContractRefundMutation, CreateContractRefundMutationVariables>(CreateContractRefundDocument, baseOptions);
      }
export type CreateContractRefundMutationHookResult = ReturnType<typeof useCreateContractRefundMutation>;
export type CreateContractRefundMutationResult = ApolloReactCommon.MutationResult<CreateContractRefundMutation>;
export type CreateContractRefundMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateContractRefundMutation, CreateContractRefundMutationVariables>;
export const UpdateContractRefundDocument = gql`
    mutation updateContractRefund($input: ContractReceiptInput!) {
  updateContractRefund(input: $input) {
    ID
  }
}
    `;
export type UpdateContractRefundMutationFn = ApolloReactCommon.MutationFunction<UpdateContractRefundMutation, UpdateContractRefundMutationVariables>;

/**
 * __useUpdateContractRefundMutation__
 *
 * To run a mutation, you first call `useUpdateContractRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractRefundMutation, { data, loading, error }] = useUpdateContractRefundMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractRefundMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContractRefundMutation, UpdateContractRefundMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateContractRefundMutation, UpdateContractRefundMutationVariables>(UpdateContractRefundDocument, baseOptions);
      }
export type UpdateContractRefundMutationHookResult = ReturnType<typeof useUpdateContractRefundMutation>;
export type UpdateContractRefundMutationResult = ApolloReactCommon.MutationResult<UpdateContractRefundMutation>;
export type UpdateContractRefundMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateContractRefundMutation, UpdateContractRefundMutationVariables>;
export const SubmittedClaimCheckingDocument = gql`
    query submittedClaimChecking($contractID: String, $subcontractID: String) {
  submittedClaimChecking(contractID: $contractID, subcontractID: $subcontractID)
}
    `;

/**
 * __useSubmittedClaimCheckingQuery__
 *
 * To run a query within a React component, call `useSubmittedClaimCheckingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubmittedClaimCheckingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubmittedClaimCheckingQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      subcontractID: // value for 'subcontractID'
 *   },
 * });
 */
export function useSubmittedClaimCheckingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SubmittedClaimCheckingQuery, SubmittedClaimCheckingQueryVariables>) {
        return ApolloReactHooks.useQuery<SubmittedClaimCheckingQuery, SubmittedClaimCheckingQueryVariables>(SubmittedClaimCheckingDocument, baseOptions);
      }
export function useSubmittedClaimCheckingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SubmittedClaimCheckingQuery, SubmittedClaimCheckingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SubmittedClaimCheckingQuery, SubmittedClaimCheckingQueryVariables>(SubmittedClaimCheckingDocument, baseOptions);
        }
export type SubmittedClaimCheckingQueryHookResult = ReturnType<typeof useSubmittedClaimCheckingQuery>;
export type SubmittedClaimCheckingLazyQueryHookResult = ReturnType<typeof useSubmittedClaimCheckingLazyQuery>;
export type SubmittedClaimCheckingQueryResult = ApolloReactCommon.QueryResult<SubmittedClaimCheckingQuery, SubmittedClaimCheckingQueryVariables>;
export const ContractVoCalculationDocument = gql`
    query contractVOCalculation($contractID: String!) {
  contractVoCalculation(contractID: $contractID)
}
    `;

/**
 * __useContractVoCalculationQuery__
 *
 * To run a query within a React component, call `useContractVoCalculationQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractVoCalculationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractVoCalculationQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useContractVoCalculationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContractVoCalculationQuery, ContractVoCalculationQueryVariables>) {
        return ApolloReactHooks.useQuery<ContractVoCalculationQuery, ContractVoCalculationQueryVariables>(ContractVoCalculationDocument, baseOptions);
      }
export function useContractVoCalculationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContractVoCalculationQuery, ContractVoCalculationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ContractVoCalculationQuery, ContractVoCalculationQueryVariables>(ContractVoCalculationDocument, baseOptions);
        }
export type ContractVoCalculationQueryHookResult = ReturnType<typeof useContractVoCalculationQuery>;
export type ContractVoCalculationLazyQueryHookResult = ReturnType<typeof useContractVoCalculationLazyQuery>;
export type ContractVoCalculationQueryResult = ApolloReactCommon.QueryResult<ContractVoCalculationQuery, ContractVoCalculationQueryVariables>;
export const ApprovedClaimCheckingDocument = gql`
    query approvedClaimChecking($contractID: String, $subcontractID: String) {
  approvedClaimChecking(contractID: $contractID, subcontractID: $subcontractID) {
    previousClaim
    previousMOS
    previousVOClaim
    previousDeduction
    previousDayWork
    retentionPerc
    previousRetentionSum
    retentionBalance
    advanceSum
    advanceRecouped
    advanceBalance
    deductionSum
    deductionRecouped
    deductionBalance
  }
}
    `;

/**
 * __useApprovedClaimCheckingQuery__
 *
 * To run a query within a React component, call `useApprovedClaimCheckingQuery` and pass it any options that fit your needs.
 * When your component renders, `useApprovedClaimCheckingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApprovedClaimCheckingQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      subcontractID: // value for 'subcontractID'
 *   },
 * });
 */
export function useApprovedClaimCheckingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ApprovedClaimCheckingQuery, ApprovedClaimCheckingQueryVariables>) {
        return ApolloReactHooks.useQuery<ApprovedClaimCheckingQuery, ApprovedClaimCheckingQueryVariables>(ApprovedClaimCheckingDocument, baseOptions);
      }
export function useApprovedClaimCheckingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ApprovedClaimCheckingQuery, ApprovedClaimCheckingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ApprovedClaimCheckingQuery, ApprovedClaimCheckingQueryVariables>(ApprovedClaimCheckingDocument, baseOptions);
        }
export type ApprovedClaimCheckingQueryHookResult = ReturnType<typeof useApprovedClaimCheckingQuery>;
export type ApprovedClaimCheckingLazyQueryHookResult = ReturnType<typeof useApprovedClaimCheckingLazyQuery>;
export type ApprovedClaimCheckingQueryResult = ApolloReactCommon.QueryResult<ApprovedClaimCheckingQuery, ApprovedClaimCheckingQueryVariables>;
export const MakeContractRefundDocument = gql`
    mutation makeContractRefund($input: ContractReceiptInput!, $ledger: [LedgerInput!]!) {
  makeContractRefund(input: $input, ledger: $ledger) {
    ID
  }
}
    `;
export type MakeContractRefundMutationFn = ApolloReactCommon.MutationFunction<MakeContractRefundMutation, MakeContractRefundMutationVariables>;

/**
 * __useMakeContractRefundMutation__
 *
 * To run a mutation, you first call `useMakeContractRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeContractRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeContractRefundMutation, { data, loading, error }] = useMakeContractRefundMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ledger: // value for 'ledger'
 *   },
 * });
 */
export function useMakeContractRefundMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MakeContractRefundMutation, MakeContractRefundMutationVariables>) {
        return ApolloReactHooks.useMutation<MakeContractRefundMutation, MakeContractRefundMutationVariables>(MakeContractRefundDocument, baseOptions);
      }
export type MakeContractRefundMutationHookResult = ReturnType<typeof useMakeContractRefundMutation>;
export type MakeContractRefundMutationResult = ApolloReactCommon.MutationResult<MakeContractRefundMutation>;
export type MakeContractRefundMutationOptions = ApolloReactCommon.BaseMutationOptions<MakeContractRefundMutation, MakeContractRefundMutationVariables>;
export const MakeContractAllocationDocument = gql`
    mutation makeContractAllocation($ledger: [LedgerInput!]!) {
  makeContractAllocation(ledger: $ledger)
}
    `;
export type MakeContractAllocationMutationFn = ApolloReactCommon.MutationFunction<MakeContractAllocationMutation, MakeContractAllocationMutationVariables>;

/**
 * __useMakeContractAllocationMutation__
 *
 * To run a mutation, you first call `useMakeContractAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeContractAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeContractAllocationMutation, { data, loading, error }] = useMakeContractAllocationMutation({
 *   variables: {
 *      ledger: // value for 'ledger'
 *   },
 * });
 */
export function useMakeContractAllocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MakeContractAllocationMutation, MakeContractAllocationMutationVariables>) {
        return ApolloReactHooks.useMutation<MakeContractAllocationMutation, MakeContractAllocationMutationVariables>(MakeContractAllocationDocument, baseOptions);
      }
export type MakeContractAllocationMutationHookResult = ReturnType<typeof useMakeContractAllocationMutation>;
export type MakeContractAllocationMutationResult = ApolloReactCommon.MutationResult<MakeContractAllocationMutation>;
export type MakeContractAllocationMutationOptions = ApolloReactCommon.BaseMutationOptions<MakeContractAllocationMutation, MakeContractAllocationMutationVariables>;
export const GetContractCnAllocationDocument = gql`
    query getContractCNAllocation($creditID: String!) {
  getContractCNAllocation(creditID: $creditID)
}
    `;

/**
 * __useGetContractCnAllocationQuery__
 *
 * To run a query within a React component, call `useGetContractCnAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractCnAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractCnAllocationQuery({
 *   variables: {
 *      creditID: // value for 'creditID'
 *   },
 * });
 */
export function useGetContractCnAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractCnAllocationQuery, GetContractCnAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractCnAllocationQuery, GetContractCnAllocationQueryVariables>(GetContractCnAllocationDocument, baseOptions);
      }
export function useGetContractCnAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractCnAllocationQuery, GetContractCnAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractCnAllocationQuery, GetContractCnAllocationQueryVariables>(GetContractCnAllocationDocument, baseOptions);
        }
export type GetContractCnAllocationQueryHookResult = ReturnType<typeof useGetContractCnAllocationQuery>;
export type GetContractCnAllocationLazyQueryHookResult = ReturnType<typeof useGetContractCnAllocationLazyQuery>;
export type GetContractCnAllocationQueryResult = ApolloReactCommon.QueryResult<GetContractCnAllocationQuery, GetContractCnAllocationQueryVariables>;
export const GetContractReceiptAllocationDocument = gql`
    query getContractReceiptAllocation($creditID: String!) {
  getContractReceiptAllocation(creditID: $creditID)
}
    `;

/**
 * __useGetContractReceiptAllocationQuery__
 *
 * To run a query within a React component, call `useGetContractReceiptAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractReceiptAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractReceiptAllocationQuery({
 *   variables: {
 *      creditID: // value for 'creditID'
 *   },
 * });
 */
export function useGetContractReceiptAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractReceiptAllocationQuery, GetContractReceiptAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractReceiptAllocationQuery, GetContractReceiptAllocationQueryVariables>(GetContractReceiptAllocationDocument, baseOptions);
      }
export function useGetContractReceiptAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractReceiptAllocationQuery, GetContractReceiptAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractReceiptAllocationQuery, GetContractReceiptAllocationQueryVariables>(GetContractReceiptAllocationDocument, baseOptions);
        }
export type GetContractReceiptAllocationQueryHookResult = ReturnType<typeof useGetContractReceiptAllocationQuery>;
export type GetContractReceiptAllocationLazyQueryHookResult = ReturnType<typeof useGetContractReceiptAllocationLazyQuery>;
export type GetContractReceiptAllocationQueryResult = ApolloReactCommon.QueryResult<GetContractReceiptAllocationQuery, GetContractReceiptAllocationQueryVariables>;
export const GetContractRefundAllocationDocument = gql`
    query getContractRefundAllocation($debitID: String, $contractID: String) {
  getContractRefundAllocation(debitID: $debitID, contractID: $contractID)
}
    `;

/**
 * __useGetContractRefundAllocationQuery__
 *
 * To run a query within a React component, call `useGetContractRefundAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractRefundAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractRefundAllocationQuery({
 *   variables: {
 *      debitID: // value for 'debitID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetContractRefundAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractRefundAllocationQuery, GetContractRefundAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractRefundAllocationQuery, GetContractRefundAllocationQueryVariables>(GetContractRefundAllocationDocument, baseOptions);
      }
export function useGetContractRefundAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractRefundAllocationQuery, GetContractRefundAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractRefundAllocationQuery, GetContractRefundAllocationQueryVariables>(GetContractRefundAllocationDocument, baseOptions);
        }
export type GetContractRefundAllocationQueryHookResult = ReturnType<typeof useGetContractRefundAllocationQuery>;
export type GetContractRefundAllocationLazyQueryHookResult = ReturnType<typeof useGetContractRefundAllocationLazyQuery>;
export type GetContractRefundAllocationQueryResult = ApolloReactCommon.QueryResult<GetContractRefundAllocationQuery, GetContractRefundAllocationQueryVariables>;
export const GetContractAdvanceAllocationDocument = gql`
    query getContractAdvanceAllocation($creditID: String!) {
  getContractAdvanceAllocation(creditID: $creditID)
}
    `;

/**
 * __useGetContractAdvanceAllocationQuery__
 *
 * To run a query within a React component, call `useGetContractAdvanceAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractAdvanceAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractAdvanceAllocationQuery({
 *   variables: {
 *      creditID: // value for 'creditID'
 *   },
 * });
 */
export function useGetContractAdvanceAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractAdvanceAllocationQuery, GetContractAdvanceAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractAdvanceAllocationQuery, GetContractAdvanceAllocationQueryVariables>(GetContractAdvanceAllocationDocument, baseOptions);
      }
export function useGetContractAdvanceAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractAdvanceAllocationQuery, GetContractAdvanceAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractAdvanceAllocationQuery, GetContractAdvanceAllocationQueryVariables>(GetContractAdvanceAllocationDocument, baseOptions);
        }
export type GetContractAdvanceAllocationQueryHookResult = ReturnType<typeof useGetContractAdvanceAllocationQuery>;
export type GetContractAdvanceAllocationLazyQueryHookResult = ReturnType<typeof useGetContractAdvanceAllocationLazyQuery>;
export type GetContractAdvanceAllocationQueryResult = ApolloReactCommon.QueryResult<GetContractAdvanceAllocationQuery, GetContractAdvanceAllocationQueryVariables>;
export const CancelContractAdvanceDocument = gql`
    mutation cancelContractAdvance($ID: String!, $input: CancelInput!) {
  cancelContractAdvance(ID: $ID, input: $input)
}
    `;
export type CancelContractAdvanceMutationFn = ApolloReactCommon.MutationFunction<CancelContractAdvanceMutation, CancelContractAdvanceMutationVariables>;

/**
 * __useCancelContractAdvanceMutation__
 *
 * To run a mutation, you first call `useCancelContractAdvanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelContractAdvanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelContractAdvanceMutation, { data, loading, error }] = useCancelContractAdvanceMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelContractAdvanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelContractAdvanceMutation, CancelContractAdvanceMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelContractAdvanceMutation, CancelContractAdvanceMutationVariables>(CancelContractAdvanceDocument, baseOptions);
      }
export type CancelContractAdvanceMutationHookResult = ReturnType<typeof useCancelContractAdvanceMutation>;
export type CancelContractAdvanceMutationResult = ApolloReactCommon.MutationResult<CancelContractAdvanceMutation>;
export type CancelContractAdvanceMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelContractAdvanceMutation, CancelContractAdvanceMutationVariables>;
export const CancelContractDnDocument = gql`
    mutation cancelContractDN($ID: String!, $input: CancelInput!) {
  cancelContractDN(ID: $ID, input: $input)
}
    `;
export type CancelContractDnMutationFn = ApolloReactCommon.MutationFunction<CancelContractDnMutation, CancelContractDnMutationVariables>;

/**
 * __useCancelContractDnMutation__
 *
 * To run a mutation, you first call `useCancelContractDnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelContractDnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelContractDnMutation, { data, loading, error }] = useCancelContractDnMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelContractDnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelContractDnMutation, CancelContractDnMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelContractDnMutation, CancelContractDnMutationVariables>(CancelContractDnDocument, baseOptions);
      }
export type CancelContractDnMutationHookResult = ReturnType<typeof useCancelContractDnMutation>;
export type CancelContractDnMutationResult = ApolloReactCommon.MutationResult<CancelContractDnMutation>;
export type CancelContractDnMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelContractDnMutation, CancelContractDnMutationVariables>;
export const CancelContractCnDocument = gql`
    mutation cancelContractCN($ID: String!, $input: CancelInput!) {
  cancelContractCN(ID: $ID, input: $input)
}
    `;
export type CancelContractCnMutationFn = ApolloReactCommon.MutationFunction<CancelContractCnMutation, CancelContractCnMutationVariables>;

/**
 * __useCancelContractCnMutation__
 *
 * To run a mutation, you first call `useCancelContractCnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelContractCnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelContractCnMutation, { data, loading, error }] = useCancelContractCnMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelContractCnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelContractCnMutation, CancelContractCnMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelContractCnMutation, CancelContractCnMutationVariables>(CancelContractCnDocument, baseOptions);
      }
export type CancelContractCnMutationHookResult = ReturnType<typeof useCancelContractCnMutation>;
export type CancelContractCnMutationResult = ApolloReactCommon.MutationResult<CancelContractCnMutation>;
export type CancelContractCnMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelContractCnMutation, CancelContractCnMutationVariables>;
export const CancelContractReceiptDocument = gql`
    mutation cancelContractReceipt($ID: String!, $input: CancelInput!) {
  cancelContractReceipt(ID: $ID, input: $input)
}
    `;
export type CancelContractReceiptMutationFn = ApolloReactCommon.MutationFunction<CancelContractReceiptMutation, CancelContractReceiptMutationVariables>;

/**
 * __useCancelContractReceiptMutation__
 *
 * To run a mutation, you first call `useCancelContractReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelContractReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelContractReceiptMutation, { data, loading, error }] = useCancelContractReceiptMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelContractReceiptMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelContractReceiptMutation, CancelContractReceiptMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelContractReceiptMutation, CancelContractReceiptMutationVariables>(CancelContractReceiptDocument, baseOptions);
      }
export type CancelContractReceiptMutationHookResult = ReturnType<typeof useCancelContractReceiptMutation>;
export type CancelContractReceiptMutationResult = ApolloReactCommon.MutationResult<CancelContractReceiptMutation>;
export type CancelContractReceiptMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelContractReceiptMutation, CancelContractReceiptMutationVariables>;
export const CancelContractRefundDocument = gql`
    mutation cancelContractRefund($ID: String!, $input: CancelInput!) {
  cancelContractRefund(ID: $ID, input: $input)
}
    `;
export type CancelContractRefundMutationFn = ApolloReactCommon.MutationFunction<CancelContractRefundMutation, CancelContractRefundMutationVariables>;

/**
 * __useCancelContractRefundMutation__
 *
 * To run a mutation, you first call `useCancelContractRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelContractRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelContractRefundMutation, { data, loading, error }] = useCancelContractRefundMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelContractRefundMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelContractRefundMutation, CancelContractRefundMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelContractRefundMutation, CancelContractRefundMutationVariables>(CancelContractRefundDocument, baseOptions);
      }
export type CancelContractRefundMutationHookResult = ReturnType<typeof useCancelContractRefundMutation>;
export type CancelContractRefundMutationResult = ApolloReactCommon.MutationResult<CancelContractRefundMutation>;
export type CancelContractRefundMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelContractRefundMutation, CancelContractRefundMutationVariables>;
export const CancelClientDnDocument = gql`
    mutation cancelClientDN($ID: String!, $input: CancelInput!) {
  cancelClientDN(ID: $ID, input: $input)
}
    `;
export type CancelClientDnMutationFn = ApolloReactCommon.MutationFunction<CancelClientDnMutation, CancelClientDnMutationVariables>;

/**
 * __useCancelClientDnMutation__
 *
 * To run a mutation, you first call `useCancelClientDnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelClientDnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelClientDnMutation, { data, loading, error }] = useCancelClientDnMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelClientDnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelClientDnMutation, CancelClientDnMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelClientDnMutation, CancelClientDnMutationVariables>(CancelClientDnDocument, baseOptions);
      }
export type CancelClientDnMutationHookResult = ReturnType<typeof useCancelClientDnMutation>;
export type CancelClientDnMutationResult = ApolloReactCommon.MutationResult<CancelClientDnMutation>;
export type CancelClientDnMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelClientDnMutation, CancelClientDnMutationVariables>;
export const RejectClaimDocument = gql`
    mutation rejectClaim($ID: String!, $input: RejectInput!) {
  rejectClaim(ID: $ID, input: $input)
}
    `;
export type RejectClaimMutationFn = ApolloReactCommon.MutationFunction<RejectClaimMutation, RejectClaimMutationVariables>;

/**
 * __useRejectClaimMutation__
 *
 * To run a mutation, you first call `useRejectClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectClaimMutation, { data, loading, error }] = useRejectClaimMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectClaimMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectClaimMutation, RejectClaimMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectClaimMutation, RejectClaimMutationVariables>(RejectClaimDocument, baseOptions);
      }
export type RejectClaimMutationHookResult = ReturnType<typeof useRejectClaimMutation>;
export type RejectClaimMutationResult = ApolloReactCommon.MutationResult<RejectClaimMutation>;
export type RejectClaimMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectClaimMutation, RejectClaimMutationVariables>;
export const DeleteContractAdvanceDocument = gql`
    mutation deleteContractAdvance($ID: String!) {
  deleteContractAdvance(ID: $ID)
}
    `;
export type DeleteContractAdvanceMutationFn = ApolloReactCommon.MutationFunction<DeleteContractAdvanceMutation, DeleteContractAdvanceMutationVariables>;

/**
 * __useDeleteContractAdvanceMutation__
 *
 * To run a mutation, you first call `useDeleteContractAdvanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContractAdvanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContractAdvanceMutation, { data, loading, error }] = useDeleteContractAdvanceMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteContractAdvanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteContractAdvanceMutation, DeleteContractAdvanceMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteContractAdvanceMutation, DeleteContractAdvanceMutationVariables>(DeleteContractAdvanceDocument, baseOptions);
      }
export type DeleteContractAdvanceMutationHookResult = ReturnType<typeof useDeleteContractAdvanceMutation>;
export type DeleteContractAdvanceMutationResult = ApolloReactCommon.MutationResult<DeleteContractAdvanceMutation>;
export type DeleteContractAdvanceMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteContractAdvanceMutation, DeleteContractAdvanceMutationVariables>;
export const DeleteContractCnDocument = gql`
    mutation deleteContractCN($ID: String!) {
  deleteContractCN(ID: $ID)
}
    `;
export type DeleteContractCnMutationFn = ApolloReactCommon.MutationFunction<DeleteContractCnMutation, DeleteContractCnMutationVariables>;

/**
 * __useDeleteContractCnMutation__
 *
 * To run a mutation, you first call `useDeleteContractCnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContractCnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContractCnMutation, { data, loading, error }] = useDeleteContractCnMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteContractCnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteContractCnMutation, DeleteContractCnMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteContractCnMutation, DeleteContractCnMutationVariables>(DeleteContractCnDocument, baseOptions);
      }
export type DeleteContractCnMutationHookResult = ReturnType<typeof useDeleteContractCnMutation>;
export type DeleteContractCnMutationResult = ApolloReactCommon.MutationResult<DeleteContractCnMutation>;
export type DeleteContractCnMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteContractCnMutation, DeleteContractCnMutationVariables>;
export const DeleteContractDnDocument = gql`
    mutation deleteContractDN($ID: String!) {
  deleteContractDN(ID: $ID)
}
    `;
export type DeleteContractDnMutationFn = ApolloReactCommon.MutationFunction<DeleteContractDnMutation, DeleteContractDnMutationVariables>;

/**
 * __useDeleteContractDnMutation__
 *
 * To run a mutation, you first call `useDeleteContractDnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContractDnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContractDnMutation, { data, loading, error }] = useDeleteContractDnMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteContractDnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteContractDnMutation, DeleteContractDnMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteContractDnMutation, DeleteContractDnMutationVariables>(DeleteContractDnDocument, baseOptions);
      }
export type DeleteContractDnMutationHookResult = ReturnType<typeof useDeleteContractDnMutation>;
export type DeleteContractDnMutationResult = ApolloReactCommon.MutationResult<DeleteContractDnMutation>;
export type DeleteContractDnMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteContractDnMutation, DeleteContractDnMutationVariables>;
export const DeleteContractClaimDocument = gql`
    mutation deleteContractClaim($ID: String!) {
  deleteContractClaim(ID: $ID)
}
    `;
export type DeleteContractClaimMutationFn = ApolloReactCommon.MutationFunction<DeleteContractClaimMutation, DeleteContractClaimMutationVariables>;

/**
 * __useDeleteContractClaimMutation__
 *
 * To run a mutation, you first call `useDeleteContractClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContractClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContractClaimMutation, { data, loading, error }] = useDeleteContractClaimMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteContractClaimMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteContractClaimMutation, DeleteContractClaimMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteContractClaimMutation, DeleteContractClaimMutationVariables>(DeleteContractClaimDocument, baseOptions);
      }
export type DeleteContractClaimMutationHookResult = ReturnType<typeof useDeleteContractClaimMutation>;
export type DeleteContractClaimMutationResult = ApolloReactCommon.MutationResult<DeleteContractClaimMutation>;
export type DeleteContractClaimMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteContractClaimMutation, DeleteContractClaimMutationVariables>;
export const DeleteContractReceiptDocument = gql`
    mutation deleteContractReceipt($ID: String!) {
  deleteContractReceipt(ID: $ID)
}
    `;
export type DeleteContractReceiptMutationFn = ApolloReactCommon.MutationFunction<DeleteContractReceiptMutation, DeleteContractReceiptMutationVariables>;

/**
 * __useDeleteContractReceiptMutation__
 *
 * To run a mutation, you first call `useDeleteContractReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContractReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContractReceiptMutation, { data, loading, error }] = useDeleteContractReceiptMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteContractReceiptMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteContractReceiptMutation, DeleteContractReceiptMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteContractReceiptMutation, DeleteContractReceiptMutationVariables>(DeleteContractReceiptDocument, baseOptions);
      }
export type DeleteContractReceiptMutationHookResult = ReturnType<typeof useDeleteContractReceiptMutation>;
export type DeleteContractReceiptMutationResult = ApolloReactCommon.MutationResult<DeleteContractReceiptMutation>;
export type DeleteContractReceiptMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteContractReceiptMutation, DeleteContractReceiptMutationVariables>;
export const DeleteContractRefundDocument = gql`
    mutation deleteContractRefund($ID: String!) {
  deleteContractRefund(ID: $ID)
}
    `;
export type DeleteContractRefundMutationFn = ApolloReactCommon.MutationFunction<DeleteContractRefundMutation, DeleteContractRefundMutationVariables>;

/**
 * __useDeleteContractRefundMutation__
 *
 * To run a mutation, you first call `useDeleteContractRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContractRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContractRefundMutation, { data, loading, error }] = useDeleteContractRefundMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteContractRefundMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteContractRefundMutation, DeleteContractRefundMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteContractRefundMutation, DeleteContractRefundMutationVariables>(DeleteContractRefundDocument, baseOptions);
      }
export type DeleteContractRefundMutationHookResult = ReturnType<typeof useDeleteContractRefundMutation>;
export type DeleteContractRefundMutationResult = ApolloReactCommon.MutationResult<DeleteContractRefundMutation>;
export type DeleteContractRefundMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteContractRefundMutation, DeleteContractRefundMutationVariables>;
export const CreateNegotiatedSupplyItemDocument = gql`
    mutation createNegotiatedSupplyItem($negoItemInput: [NegotiatedSupplyItemInput!]!, $negoSupplyInput: NegotiatedSupplyInput!) {
  createNegotiatedSupply(negoItemInput: $negoItemInput, negoSupplyInput: $negoSupplyInput)
}
    `;
export type CreateNegotiatedSupplyItemMutationFn = ApolloReactCommon.MutationFunction<CreateNegotiatedSupplyItemMutation, CreateNegotiatedSupplyItemMutationVariables>;

/**
 * __useCreateNegotiatedSupplyItemMutation__
 *
 * To run a mutation, you first call `useCreateNegotiatedSupplyItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNegotiatedSupplyItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNegotiatedSupplyItemMutation, { data, loading, error }] = useCreateNegotiatedSupplyItemMutation({
 *   variables: {
 *      negoItemInput: // value for 'negoItemInput'
 *      negoSupplyInput: // value for 'negoSupplyInput'
 *   },
 * });
 */
export function useCreateNegotiatedSupplyItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateNegotiatedSupplyItemMutation, CreateNegotiatedSupplyItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateNegotiatedSupplyItemMutation, CreateNegotiatedSupplyItemMutationVariables>(CreateNegotiatedSupplyItemDocument, baseOptions);
      }
export type CreateNegotiatedSupplyItemMutationHookResult = ReturnType<typeof useCreateNegotiatedSupplyItemMutation>;
export type CreateNegotiatedSupplyItemMutationResult = ApolloReactCommon.MutationResult<CreateNegotiatedSupplyItemMutation>;
export type CreateNegotiatedSupplyItemMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateNegotiatedSupplyItemMutation, CreateNegotiatedSupplyItemMutationVariables>;
export const UpdateNegotiatedSupplyItemDocument = gql`
    mutation updateNegotiatedSupplyItem($negoItemInput: [NegotiatedSupplyItemInput!]!, $negoSupplyInput: NegotiatedSupplyInput!, $ID: String!) {
  updateNegotiatedSupply(negoItemInput: $negoItemInput, negoSupplyInput: $negoSupplyInput, ID: $ID)
}
    `;
export type UpdateNegotiatedSupplyItemMutationFn = ApolloReactCommon.MutationFunction<UpdateNegotiatedSupplyItemMutation, UpdateNegotiatedSupplyItemMutationVariables>;

/**
 * __useUpdateNegotiatedSupplyItemMutation__
 *
 * To run a mutation, you first call `useUpdateNegotiatedSupplyItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNegotiatedSupplyItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNegotiatedSupplyItemMutation, { data, loading, error }] = useUpdateNegotiatedSupplyItemMutation({
 *   variables: {
 *      negoItemInput: // value for 'negoItemInput'
 *      negoSupplyInput: // value for 'negoSupplyInput'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useUpdateNegotiatedSupplyItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateNegotiatedSupplyItemMutation, UpdateNegotiatedSupplyItemMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateNegotiatedSupplyItemMutation, UpdateNegotiatedSupplyItemMutationVariables>(UpdateNegotiatedSupplyItemDocument, baseOptions);
      }
export type UpdateNegotiatedSupplyItemMutationHookResult = ReturnType<typeof useUpdateNegotiatedSupplyItemMutation>;
export type UpdateNegotiatedSupplyItemMutationResult = ApolloReactCommon.MutationResult<UpdateNegotiatedSupplyItemMutation>;
export type UpdateNegotiatedSupplyItemMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateNegotiatedSupplyItemMutation, UpdateNegotiatedSupplyItemMutationVariables>;
export const CancelNegotiatedSupplyDocument = gql`
    mutation cancelNegotiatedSupply($input: NegoSupplyCancellationInput!, $ID: String!) {
  cancelNegotiatedSupply(input: $input, ID: $ID)
}
    `;
export type CancelNegotiatedSupplyMutationFn = ApolloReactCommon.MutationFunction<CancelNegotiatedSupplyMutation, CancelNegotiatedSupplyMutationVariables>;

/**
 * __useCancelNegotiatedSupplyMutation__
 *
 * To run a mutation, you first call `useCancelNegotiatedSupplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelNegotiatedSupplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelNegotiatedSupplyMutation, { data, loading, error }] = useCancelNegotiatedSupplyMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useCancelNegotiatedSupplyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelNegotiatedSupplyMutation, CancelNegotiatedSupplyMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelNegotiatedSupplyMutation, CancelNegotiatedSupplyMutationVariables>(CancelNegotiatedSupplyDocument, baseOptions);
      }
export type CancelNegotiatedSupplyMutationHookResult = ReturnType<typeof useCancelNegotiatedSupplyMutation>;
export type CancelNegotiatedSupplyMutationResult = ApolloReactCommon.MutationResult<CancelNegotiatedSupplyMutation>;
export type CancelNegotiatedSupplyMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelNegotiatedSupplyMutation, CancelNegotiatedSupplyMutationVariables>;
export const GetNegotiatedSupplyItemDocument = gql`
    query getNegotiatedSupplyItem($ID: String, $orderByAsc: String, $uomID: String, $costItemID: String) {
  getNegotiatedSupplyItem(ID: $ID, orderByAsc: $orderByAsc, uomID: $uomID, costItemID: $costItemID) {
    ID
    negotiatedSupplyID
    costItemID
    uomID
    remarks
    quantity
    balanceQty
    unitPrice
    costItem {
      costCategory {
        ID
        name
        supplier {
          ID
          name
          associatedStatus
        }
      }
      ID
      name
      uomID
    }
    poItem {
      ID
      orderedQty
      outstandingQty
    }
    UOM {
      ID
      code
      name
    }
    negoSupply {
      ID
      supplierID
      contractID
      docNo
      negoSupplyDate
      startDate
      endDate
      cancellationDate
      cancellationRemarks
      status
      supplier {
        ID
        name
      }
    }
  }
}
    `;

/**
 * __useGetNegotiatedSupplyItemQuery__
 *
 * To run a query within a React component, call `useGetNegotiatedSupplyItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNegotiatedSupplyItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNegotiatedSupplyItemQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *      uomID: // value for 'uomID'
 *      costItemID: // value for 'costItemID'
 *   },
 * });
 */
export function useGetNegotiatedSupplyItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNegotiatedSupplyItemQuery, GetNegotiatedSupplyItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNegotiatedSupplyItemQuery, GetNegotiatedSupplyItemQueryVariables>(GetNegotiatedSupplyItemDocument, baseOptions);
      }
export function useGetNegotiatedSupplyItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNegotiatedSupplyItemQuery, GetNegotiatedSupplyItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNegotiatedSupplyItemQuery, GetNegotiatedSupplyItemQueryVariables>(GetNegotiatedSupplyItemDocument, baseOptions);
        }
export type GetNegotiatedSupplyItemQueryHookResult = ReturnType<typeof useGetNegotiatedSupplyItemQuery>;
export type GetNegotiatedSupplyItemLazyQueryHookResult = ReturnType<typeof useGetNegotiatedSupplyItemLazyQuery>;
export type GetNegotiatedSupplyItemQueryResult = ApolloReactCommon.QueryResult<GetNegotiatedSupplyItemQuery, GetNegotiatedSupplyItemQueryVariables>;
export const CreateSupplierInvoiceDocument = gql`
    mutation createSupplierInvoice($invoiceInput: APInvoiceInput!, $ledger: [LedgerInput!]!, $invDoItemMatchInput: [APInvoiceItemInput!]!) {
  createSupplierInvoice(invoiceInput: $invoiceInput, ledger: $ledger, invDoItemMatchInput: $invDoItemMatchInput)
}
    `;
export type CreateSupplierInvoiceMutationFn = ApolloReactCommon.MutationFunction<CreateSupplierInvoiceMutation, CreateSupplierInvoiceMutationVariables>;

/**
 * __useCreateSupplierInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateSupplierInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupplierInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupplierInvoiceMutation, { data, loading, error }] = useCreateSupplierInvoiceMutation({
 *   variables: {
 *      invoiceInput: // value for 'invoiceInput'
 *      ledger: // value for 'ledger'
 *      invDoItemMatchInput: // value for 'invDoItemMatchInput'
 *   },
 * });
 */
export function useCreateSupplierInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSupplierInvoiceMutation, CreateSupplierInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSupplierInvoiceMutation, CreateSupplierInvoiceMutationVariables>(CreateSupplierInvoiceDocument, baseOptions);
      }
export type CreateSupplierInvoiceMutationHookResult = ReturnType<typeof useCreateSupplierInvoiceMutation>;
export type CreateSupplierInvoiceMutationResult = ApolloReactCommon.MutationResult<CreateSupplierInvoiceMutation>;
export type CreateSupplierInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSupplierInvoiceMutation, CreateSupplierInvoiceMutationVariables>;
export const UpdateSupplierInvoiceDocument = gql`
    mutation updateSupplierInvoice($invoiceInput: APInvoiceInput!, $ledger: [LedgerInput!]!, $invDoItemMatchInput: [APInvoiceItemInput!]!) {
  updateSupplierInvoice(invoiceInput: $invoiceInput, ledger: $ledger, invDoItemMatchInput: $invDoItemMatchInput)
}
    `;
export type UpdateSupplierInvoiceMutationFn = ApolloReactCommon.MutationFunction<UpdateSupplierInvoiceMutation, UpdateSupplierInvoiceMutationVariables>;

/**
 * __useUpdateSupplierInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateSupplierInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupplierInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSupplierInvoiceMutation, { data, loading, error }] = useUpdateSupplierInvoiceMutation({
 *   variables: {
 *      invoiceInput: // value for 'invoiceInput'
 *      ledger: // value for 'ledger'
 *      invDoItemMatchInput: // value for 'invDoItemMatchInput'
 *   },
 * });
 */
export function useUpdateSupplierInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSupplierInvoiceMutation, UpdateSupplierInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSupplierInvoiceMutation, UpdateSupplierInvoiceMutationVariables>(UpdateSupplierInvoiceDocument, baseOptions);
      }
export type UpdateSupplierInvoiceMutationHookResult = ReturnType<typeof useUpdateSupplierInvoiceMutation>;
export type UpdateSupplierInvoiceMutationResult = ApolloReactCommon.MutationResult<UpdateSupplierInvoiceMutation>;
export type UpdateSupplierInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSupplierInvoiceMutation, UpdateSupplierInvoiceMutationVariables>;
export const GetApInvoiceDocument = gql`
    query getAPInvoice($ID: String, $supplierID: String) {
  getAPInvoice(ID: $ID, supplierID: $supplierID) {
    ID
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    invoiceAmt
    discountAmt
    transactionStatus
    supplierID
    contractID
    subcontractID
    grnMatch
    outstanding
    supplierAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
    }
  }
  supplierInvInfo(supplierID: $supplierID)
}
    `;

/**
 * __useGetApInvoiceQuery__
 *
 * To run a query within a React component, call `useGetApInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApInvoiceQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *   },
 * });
 */
export function useGetApInvoiceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApInvoiceQuery, GetApInvoiceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApInvoiceQuery, GetApInvoiceQueryVariables>(GetApInvoiceDocument, baseOptions);
      }
export function useGetApInvoiceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApInvoiceQuery, GetApInvoiceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApInvoiceQuery, GetApInvoiceQueryVariables>(GetApInvoiceDocument, baseOptions);
        }
export type GetApInvoiceQueryHookResult = ReturnType<typeof useGetApInvoiceQuery>;
export type GetApInvoiceLazyQueryHookResult = ReturnType<typeof useGetApInvoiceLazyQuery>;
export type GetApInvoiceQueryResult = ApolloReactCommon.QueryResult<GetApInvoiceQuery, GetApInvoiceQueryVariables>;
export const GetSupplierInvoiceDocument = gql`
    query getSupplierInvoice($ID: String, $supplierID: String) {
  getAPInvoice(ID: $ID, supplierID: $supplierID) {
    ID
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    discountAmt
    transactionStatus
    supplierID
    contractID
    subcontractID
    grnMatch
    outstanding
    supplierAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
    }
  }
  supplierInvInfo(supplierID: $supplierID)
  getDO(supplierID: $supplierID, doStatus: ACTIVE) {
    ID
    doNo
  }
}
    `;

/**
 * __useGetSupplierInvoiceQuery__
 *
 * To run a query within a React component, call `useGetSupplierInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierInvoiceQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *   },
 * });
 */
export function useGetSupplierInvoiceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierInvoiceQuery, GetSupplierInvoiceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierInvoiceQuery, GetSupplierInvoiceQueryVariables>(GetSupplierInvoiceDocument, baseOptions);
      }
export function useGetSupplierInvoiceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierInvoiceQuery, GetSupplierInvoiceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierInvoiceQuery, GetSupplierInvoiceQueryVariables>(GetSupplierInvoiceDocument, baseOptions);
        }
export type GetSupplierInvoiceQueryHookResult = ReturnType<typeof useGetSupplierInvoiceQuery>;
export type GetSupplierInvoiceLazyQueryHookResult = ReturnType<typeof useGetSupplierInvoiceLazyQuery>;
export type GetSupplierInvoiceQueryResult = ApolloReactCommon.QueryResult<GetSupplierInvoiceQuery, GetSupplierInvoiceQueryVariables>;
export const CreateApDebitNoteDocument = gql`
    mutation createAPDebitNote($input: APTransactionInput!) {
  createAPDebitNote(input: $input) {
    supplierID
  }
}
    `;
export type CreateApDebitNoteMutationFn = ApolloReactCommon.MutationFunction<CreateApDebitNoteMutation, CreateApDebitNoteMutationVariables>;

/**
 * __useCreateApDebitNoteMutation__
 *
 * To run a mutation, you first call `useCreateApDebitNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApDebitNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApDebitNoteMutation, { data, loading, error }] = useCreateApDebitNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApDebitNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApDebitNoteMutation, CreateApDebitNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateApDebitNoteMutation, CreateApDebitNoteMutationVariables>(CreateApDebitNoteDocument, baseOptions);
      }
export type CreateApDebitNoteMutationHookResult = ReturnType<typeof useCreateApDebitNoteMutation>;
export type CreateApDebitNoteMutationResult = ApolloReactCommon.MutationResult<CreateApDebitNoteMutation>;
export type CreateApDebitNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateApDebitNoteMutation, CreateApDebitNoteMutationVariables>;
export const UpdateApDebitNoteDocument = gql`
    mutation updateAPDebitNote($input: APTransactionInput!) {
  updateAPDebitNote(input: $input) {
    supplierID
  }
}
    `;
export type UpdateApDebitNoteMutationFn = ApolloReactCommon.MutationFunction<UpdateApDebitNoteMutation, UpdateApDebitNoteMutationVariables>;

/**
 * __useUpdateApDebitNoteMutation__
 *
 * To run a mutation, you first call `useUpdateApDebitNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApDebitNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApDebitNoteMutation, { data, loading, error }] = useUpdateApDebitNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApDebitNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateApDebitNoteMutation, UpdateApDebitNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateApDebitNoteMutation, UpdateApDebitNoteMutationVariables>(UpdateApDebitNoteDocument, baseOptions);
      }
export type UpdateApDebitNoteMutationHookResult = ReturnType<typeof useUpdateApDebitNoteMutation>;
export type UpdateApDebitNoteMutationResult = ApolloReactCommon.MutationResult<UpdateApDebitNoteMutation>;
export type UpdateApDebitNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateApDebitNoteMutation, UpdateApDebitNoteMutationVariables>;
export const GetApDebitNoteDocument = gql`
    query getAPDebitNote($ID: String, $supplierID: String) {
  getAPDebitNote(ID: $ID, supplierID: $supplierID) {
    ID
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    transactionStatus
    supplierID
    contractID
    subcontractID
    outstandingAmt
    supplierAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
    }
  }
  supplierDNInfo(supplierID: $supplierID)
}
    `;

/**
 * __useGetApDebitNoteQuery__
 *
 * To run a query within a React component, call `useGetApDebitNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApDebitNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApDebitNoteQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *   },
 * });
 */
export function useGetApDebitNoteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApDebitNoteQuery, GetApDebitNoteQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApDebitNoteQuery, GetApDebitNoteQueryVariables>(GetApDebitNoteDocument, baseOptions);
      }
export function useGetApDebitNoteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApDebitNoteQuery, GetApDebitNoteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApDebitNoteQuery, GetApDebitNoteQueryVariables>(GetApDebitNoteDocument, baseOptions);
        }
export type GetApDebitNoteQueryHookResult = ReturnType<typeof useGetApDebitNoteQuery>;
export type GetApDebitNoteLazyQueryHookResult = ReturnType<typeof useGetApDebitNoteLazyQuery>;
export type GetApDebitNoteQueryResult = ApolloReactCommon.QueryResult<GetApDebitNoteQuery, GetApDebitNoteQueryVariables>;
export const CreateApCreditNoteDocument = gql`
    mutation createAPCreditNote($input: APTransactionInput!) {
  createAPCreditNote(input: $input) {
    supplierID
  }
}
    `;
export type CreateApCreditNoteMutationFn = ApolloReactCommon.MutationFunction<CreateApCreditNoteMutation, CreateApCreditNoteMutationVariables>;

/**
 * __useCreateApCreditNoteMutation__
 *
 * To run a mutation, you first call `useCreateApCreditNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApCreditNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApCreditNoteMutation, { data, loading, error }] = useCreateApCreditNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApCreditNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApCreditNoteMutation, CreateApCreditNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateApCreditNoteMutation, CreateApCreditNoteMutationVariables>(CreateApCreditNoteDocument, baseOptions);
      }
export type CreateApCreditNoteMutationHookResult = ReturnType<typeof useCreateApCreditNoteMutation>;
export type CreateApCreditNoteMutationResult = ApolloReactCommon.MutationResult<CreateApCreditNoteMutation>;
export type CreateApCreditNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateApCreditNoteMutation, CreateApCreditNoteMutationVariables>;
export const UpdateApCreditNoteDocument = gql`
    mutation updateAPCreditNote($input: APTransactionInput!) {
  updateAPCreditNote(input: $input) {
    supplierID
  }
}
    `;
export type UpdateApCreditNoteMutationFn = ApolloReactCommon.MutationFunction<UpdateApCreditNoteMutation, UpdateApCreditNoteMutationVariables>;

/**
 * __useUpdateApCreditNoteMutation__
 *
 * To run a mutation, you first call `useUpdateApCreditNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApCreditNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApCreditNoteMutation, { data, loading, error }] = useUpdateApCreditNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApCreditNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateApCreditNoteMutation, UpdateApCreditNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateApCreditNoteMutation, UpdateApCreditNoteMutationVariables>(UpdateApCreditNoteDocument, baseOptions);
      }
export type UpdateApCreditNoteMutationHookResult = ReturnType<typeof useUpdateApCreditNoteMutation>;
export type UpdateApCreditNoteMutationResult = ApolloReactCommon.MutationResult<UpdateApCreditNoteMutation>;
export type UpdateApCreditNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateApCreditNoteMutation, UpdateApCreditNoteMutationVariables>;
export const GetApCreditNoteDocument = gql`
    query getAPCreditNote($ID: String, $supplierID: String) {
  getAPCreditNote(ID: $ID, supplierID: $supplierID) {
    ID
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    outstandingAmt
    transactionStatus
    supplierID
    contractID
    subcontractID
    outstandingAmt
    supplierAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
    }
  }
  supplierCNInfo(supplierID: $supplierID)
}
    `;

/**
 * __useGetApCreditNoteQuery__
 *
 * To run a query within a React component, call `useGetApCreditNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApCreditNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApCreditNoteQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *   },
 * });
 */
export function useGetApCreditNoteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApCreditNoteQuery, GetApCreditNoteQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApCreditNoteQuery, GetApCreditNoteQueryVariables>(GetApCreditNoteDocument, baseOptions);
      }
export function useGetApCreditNoteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApCreditNoteQuery, GetApCreditNoteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApCreditNoteQuery, GetApCreditNoteQueryVariables>(GetApCreditNoteDocument, baseOptions);
        }
export type GetApCreditNoteQueryHookResult = ReturnType<typeof useGetApCreditNoteQuery>;
export type GetApCreditNoteLazyQueryHookResult = ReturnType<typeof useGetApCreditNoteLazyQuery>;
export type GetApCreditNoteQueryResult = ApolloReactCommon.QueryResult<GetApCreditNoteQuery, GetApCreditNoteQueryVariables>;
export const CreateDNtoSupplierDocument = gql`
    mutation createDNtoSupplier($input: APTransactionInput!) {
  createDNtoSupplier(input: $input) {
    supplierID
  }
}
    `;
export type CreateDNtoSupplierMutationFn = ApolloReactCommon.MutationFunction<CreateDNtoSupplierMutation, CreateDNtoSupplierMutationVariables>;

/**
 * __useCreateDNtoSupplierMutation__
 *
 * To run a mutation, you first call `useCreateDNtoSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDNtoSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDNtoSupplierMutation, { data, loading, error }] = useCreateDNtoSupplierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDNtoSupplierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDNtoSupplierMutation, CreateDNtoSupplierMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDNtoSupplierMutation, CreateDNtoSupplierMutationVariables>(CreateDNtoSupplierDocument, baseOptions);
      }
export type CreateDNtoSupplierMutationHookResult = ReturnType<typeof useCreateDNtoSupplierMutation>;
export type CreateDNtoSupplierMutationResult = ApolloReactCommon.MutationResult<CreateDNtoSupplierMutation>;
export type CreateDNtoSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDNtoSupplierMutation, CreateDNtoSupplierMutationVariables>;
export const UpdateDNtoSupplierDocument = gql`
    mutation updateDNtoSupplier($input: APTransactionInput!) {
  updateDNtoSupplier(input: $input) {
    supplierID
  }
}
    `;
export type UpdateDNtoSupplierMutationFn = ApolloReactCommon.MutationFunction<UpdateDNtoSupplierMutation, UpdateDNtoSupplierMutationVariables>;

/**
 * __useUpdateDNtoSupplierMutation__
 *
 * To run a mutation, you first call `useUpdateDNtoSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDNtoSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDNtoSupplierMutation, { data, loading, error }] = useUpdateDNtoSupplierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDNtoSupplierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDNtoSupplierMutation, UpdateDNtoSupplierMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDNtoSupplierMutation, UpdateDNtoSupplierMutationVariables>(UpdateDNtoSupplierDocument, baseOptions);
      }
export type UpdateDNtoSupplierMutationHookResult = ReturnType<typeof useUpdateDNtoSupplierMutation>;
export type UpdateDNtoSupplierMutationResult = ApolloReactCommon.MutationResult<UpdateDNtoSupplierMutation>;
export type UpdateDNtoSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDNtoSupplierMutation, UpdateDNtoSupplierMutationVariables>;
export const GetDNtoSupplierDocument = gql`
    query getDNtoSupplier($ID: String, $supplierID: String) {
  getDNtoSupplier(ID: $ID, supplierID: $supplierID) {
    ID
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    transactionStatus
    supplierID
    contractID
    subcontractID
  }
}
    `;

/**
 * __useGetDNtoSupplierQuery__
 *
 * To run a query within a React component, call `useGetDNtoSupplierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDNtoSupplierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDNtoSupplierQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *   },
 * });
 */
export function useGetDNtoSupplierQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDNtoSupplierQuery, GetDNtoSupplierQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDNtoSupplierQuery, GetDNtoSupplierQueryVariables>(GetDNtoSupplierDocument, baseOptions);
      }
export function useGetDNtoSupplierLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDNtoSupplierQuery, GetDNtoSupplierQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDNtoSupplierQuery, GetDNtoSupplierQueryVariables>(GetDNtoSupplierDocument, baseOptions);
        }
export type GetDNtoSupplierQueryHookResult = ReturnType<typeof useGetDNtoSupplierQuery>;
export type GetDNtoSupplierLazyQueryHookResult = ReturnType<typeof useGetDNtoSupplierLazyQuery>;
export type GetDNtoSupplierQueryResult = ApolloReactCommon.QueryResult<GetDNtoSupplierQuery, GetDNtoSupplierQueryVariables>;
export const CreateApPaymentDocument = gql`
    mutation createAPPayment($input: APPaymentInput!) {
  createAPPayment(input: $input) {
    supplierID
  }
}
    `;
export type CreateApPaymentMutationFn = ApolloReactCommon.MutationFunction<CreateApPaymentMutation, CreateApPaymentMutationVariables>;

/**
 * __useCreateApPaymentMutation__
 *
 * To run a mutation, you first call `useCreateApPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApPaymentMutation, { data, loading, error }] = useCreateApPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApPaymentMutation, CreateApPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateApPaymentMutation, CreateApPaymentMutationVariables>(CreateApPaymentDocument, baseOptions);
      }
export type CreateApPaymentMutationHookResult = ReturnType<typeof useCreateApPaymentMutation>;
export type CreateApPaymentMutationResult = ApolloReactCommon.MutationResult<CreateApPaymentMutation>;
export type CreateApPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateApPaymentMutation, CreateApPaymentMutationVariables>;
export const UpdateApPaymentDocument = gql`
    mutation updateAPPayment($input: APPaymentInput!) {
  updateAPPayment(input: $input) {
    supplierID
  }
}
    `;
export type UpdateApPaymentMutationFn = ApolloReactCommon.MutationFunction<UpdateApPaymentMutation, UpdateApPaymentMutationVariables>;

/**
 * __useUpdateApPaymentMutation__
 *
 * To run a mutation, you first call `useUpdateApPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApPaymentMutation, { data, loading, error }] = useUpdateApPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateApPaymentMutation, UpdateApPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateApPaymentMutation, UpdateApPaymentMutationVariables>(UpdateApPaymentDocument, baseOptions);
      }
export type UpdateApPaymentMutationHookResult = ReturnType<typeof useUpdateApPaymentMutation>;
export type UpdateApPaymentMutationResult = ApolloReactCommon.MutationResult<UpdateApPaymentMutation>;
export type UpdateApPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateApPaymentMutation, UpdateApPaymentMutationVariables>;
export const GetApPaymentDocument = gql`
    query getAPPayment($ID: String, $supplierID: String) {
  getAPPayment(ID: $ID, supplierID: $supplierID) {
    ID
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    bankAccountID
    bankDocAmt
    bankDetails
    receiptStatus
    supplierID
    contractID
    subcontractID
    bankAccountID
    bankDetails
    supplierAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
    }
  }
  supplierPaymentsInfo(supplierID: $supplierID)
}
    `;

/**
 * __useGetApPaymentQuery__
 *
 * To run a query within a React component, call `useGetApPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApPaymentQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *   },
 * });
 */
export function useGetApPaymentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApPaymentQuery, GetApPaymentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApPaymentQuery, GetApPaymentQueryVariables>(GetApPaymentDocument, baseOptions);
      }
export function useGetApPaymentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApPaymentQuery, GetApPaymentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApPaymentQuery, GetApPaymentQueryVariables>(GetApPaymentDocument, baseOptions);
        }
export type GetApPaymentQueryHookResult = ReturnType<typeof useGetApPaymentQuery>;
export type GetApPaymentLazyQueryHookResult = ReturnType<typeof useGetApPaymentLazyQuery>;
export type GetApPaymentQueryResult = ApolloReactCommon.QueryResult<GetApPaymentQuery, GetApPaymentQueryVariables>;
export const CreateApAdvanceDocument = gql`
    mutation createAPAdvance($input: APPaymentInput!) {
  createAPAdvance(input: $input) {
    supplierID
  }
}
    `;
export type CreateApAdvanceMutationFn = ApolloReactCommon.MutationFunction<CreateApAdvanceMutation, CreateApAdvanceMutationVariables>;

/**
 * __useCreateApAdvanceMutation__
 *
 * To run a mutation, you first call `useCreateApAdvanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApAdvanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApAdvanceMutation, { data, loading, error }] = useCreateApAdvanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApAdvanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApAdvanceMutation, CreateApAdvanceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateApAdvanceMutation, CreateApAdvanceMutationVariables>(CreateApAdvanceDocument, baseOptions);
      }
export type CreateApAdvanceMutationHookResult = ReturnType<typeof useCreateApAdvanceMutation>;
export type CreateApAdvanceMutationResult = ApolloReactCommon.MutationResult<CreateApAdvanceMutation>;
export type CreateApAdvanceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateApAdvanceMutation, CreateApAdvanceMutationVariables>;
export const UpdateApAdvanceDocument = gql`
    mutation updateAPAdvance($input: APPaymentInput!) {
  updateAPAdvance(input: $input) {
    supplierID
  }
}
    `;
export type UpdateApAdvanceMutationFn = ApolloReactCommon.MutationFunction<UpdateApAdvanceMutation, UpdateApAdvanceMutationVariables>;

/**
 * __useUpdateApAdvanceMutation__
 *
 * To run a mutation, you first call `useUpdateApAdvanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApAdvanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApAdvanceMutation, { data, loading, error }] = useUpdateApAdvanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApAdvanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateApAdvanceMutation, UpdateApAdvanceMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateApAdvanceMutation, UpdateApAdvanceMutationVariables>(UpdateApAdvanceDocument, baseOptions);
      }
export type UpdateApAdvanceMutationHookResult = ReturnType<typeof useUpdateApAdvanceMutation>;
export type UpdateApAdvanceMutationResult = ApolloReactCommon.MutationResult<UpdateApAdvanceMutation>;
export type UpdateApAdvanceMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateApAdvanceMutation, UpdateApAdvanceMutationVariables>;
export const GetApAdvanceDocument = gql`
    query getAPAdvance($ID: String, $supplierID: String) {
  getAPAdvance(ID: $ID, supplierID: $supplierID) {
    ID
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    bankAccountID
    bankDocAmt
    bankDetails
    receiptStatus
    supplierID
    contractID
    subcontractID
    supplierAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
    }
  }
  supplierDepositsInfo(supplierID: $supplierID)
}
    `;

/**
 * __useGetApAdvanceQuery__
 *
 * To run a query within a React component, call `useGetApAdvanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApAdvanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApAdvanceQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *   },
 * });
 */
export function useGetApAdvanceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApAdvanceQuery, GetApAdvanceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApAdvanceQuery, GetApAdvanceQueryVariables>(GetApAdvanceDocument, baseOptions);
      }
export function useGetApAdvanceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApAdvanceQuery, GetApAdvanceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApAdvanceQuery, GetApAdvanceQueryVariables>(GetApAdvanceDocument, baseOptions);
        }
export type GetApAdvanceQueryHookResult = ReturnType<typeof useGetApAdvanceQuery>;
export type GetApAdvanceLazyQueryHookResult = ReturnType<typeof useGetApAdvanceLazyQuery>;
export type GetApAdvanceQueryResult = ApolloReactCommon.QueryResult<GetApAdvanceQuery, GetApAdvanceQueryVariables>;
export const GetSupplierPaymentTableDocument = gql`
    query getSupplierPaymentTable($supplierID: String) {
  getAPInvoice(supplierID: $supplierID) {
    ID
    docNo
    docRef
    docDate
    docAmt
    baseAmt
    description
    outstanding
  }
  getAPDebitNote(supplierID: $supplierID) {
    ID
    docNo
    docRef
    docDate
    docAmt
    baseAmt
    description
    outstandingAmt
  }
}
    `;

/**
 * __useGetSupplierPaymentTableQuery__
 *
 * To run a query within a React component, call `useGetSupplierPaymentTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierPaymentTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierPaymentTableQuery({
 *   variables: {
 *      supplierID: // value for 'supplierID'
 *   },
 * });
 */
export function useGetSupplierPaymentTableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierPaymentTableQuery, GetSupplierPaymentTableQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierPaymentTableQuery, GetSupplierPaymentTableQueryVariables>(GetSupplierPaymentTableDocument, baseOptions);
      }
export function useGetSupplierPaymentTableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierPaymentTableQuery, GetSupplierPaymentTableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierPaymentTableQuery, GetSupplierPaymentTableQueryVariables>(GetSupplierPaymentTableDocument, baseOptions);
        }
export type GetSupplierPaymentTableQueryHookResult = ReturnType<typeof useGetSupplierPaymentTableQuery>;
export type GetSupplierPaymentTableLazyQueryHookResult = ReturnType<typeof useGetSupplierPaymentTableLazyQuery>;
export type GetSupplierPaymentTableQueryResult = ApolloReactCommon.QueryResult<GetSupplierPaymentTableQuery, GetSupplierPaymentTableQueryVariables>;
export const MakeSupplierPaymentDocument = gql`
    mutation makeSupplierPayment($input: APPaymentInput!, $ledger: [LedgerInput!]!) {
  makeSupplierPayment(input: $input, ledger: $ledger) {
    ID
  }
}
    `;
export type MakeSupplierPaymentMutationFn = ApolloReactCommon.MutationFunction<MakeSupplierPaymentMutation, MakeSupplierPaymentMutationVariables>;

/**
 * __useMakeSupplierPaymentMutation__
 *
 * To run a mutation, you first call `useMakeSupplierPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeSupplierPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeSupplierPaymentMutation, { data, loading, error }] = useMakeSupplierPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ledger: // value for 'ledger'
 *   },
 * });
 */
export function useMakeSupplierPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MakeSupplierPaymentMutation, MakeSupplierPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<MakeSupplierPaymentMutation, MakeSupplierPaymentMutationVariables>(MakeSupplierPaymentDocument, baseOptions);
      }
export type MakeSupplierPaymentMutationHookResult = ReturnType<typeof useMakeSupplierPaymentMutation>;
export type MakeSupplierPaymentMutationResult = ApolloReactCommon.MutationResult<MakeSupplierPaymentMutation>;
export type MakeSupplierPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<MakeSupplierPaymentMutation, MakeSupplierPaymentMutationVariables>;
export const MakeSupplierAllocationDocument = gql`
    mutation makeSupplierAllocation($ledger: [LedgerInput!]!) {
  makeSupplierAllocation(ledger: $ledger)
}
    `;
export type MakeSupplierAllocationMutationFn = ApolloReactCommon.MutationFunction<MakeSupplierAllocationMutation, MakeSupplierAllocationMutationVariables>;

/**
 * __useMakeSupplierAllocationMutation__
 *
 * To run a mutation, you first call `useMakeSupplierAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeSupplierAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeSupplierAllocationMutation, { data, loading, error }] = useMakeSupplierAllocationMutation({
 *   variables: {
 *      ledger: // value for 'ledger'
 *   },
 * });
 */
export function useMakeSupplierAllocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MakeSupplierAllocationMutation, MakeSupplierAllocationMutationVariables>) {
        return ApolloReactHooks.useMutation<MakeSupplierAllocationMutation, MakeSupplierAllocationMutationVariables>(MakeSupplierAllocationDocument, baseOptions);
      }
export type MakeSupplierAllocationMutationHookResult = ReturnType<typeof useMakeSupplierAllocationMutation>;
export type MakeSupplierAllocationMutationResult = ApolloReactCommon.MutationResult<MakeSupplierAllocationMutation>;
export type MakeSupplierAllocationMutationOptions = ApolloReactCommon.BaseMutationOptions<MakeSupplierAllocationMutation, MakeSupplierAllocationMutationVariables>;
export const GetSupplierDepositAllocationDocument = gql`
    query getSupplierDepositAllocation($debitID: String!) {
  getSupplierDepositAllocation(debitID: $debitID)
}
    `;

/**
 * __useGetSupplierDepositAllocationQuery__
 *
 * To run a query within a React component, call `useGetSupplierDepositAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierDepositAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierDepositAllocationQuery({
 *   variables: {
 *      debitID: // value for 'debitID'
 *   },
 * });
 */
export function useGetSupplierDepositAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierDepositAllocationQuery, GetSupplierDepositAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierDepositAllocationQuery, GetSupplierDepositAllocationQueryVariables>(GetSupplierDepositAllocationDocument, baseOptions);
      }
export function useGetSupplierDepositAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierDepositAllocationQuery, GetSupplierDepositAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierDepositAllocationQuery, GetSupplierDepositAllocationQueryVariables>(GetSupplierDepositAllocationDocument, baseOptions);
        }
export type GetSupplierDepositAllocationQueryHookResult = ReturnType<typeof useGetSupplierDepositAllocationQuery>;
export type GetSupplierDepositAllocationLazyQueryHookResult = ReturnType<typeof useGetSupplierDepositAllocationLazyQuery>;
export type GetSupplierDepositAllocationQueryResult = ApolloReactCommon.QueryResult<GetSupplierDepositAllocationQuery, GetSupplierDepositAllocationQueryVariables>;
export const GetSupplierCnAllocationDocument = gql`
    query getSupplierCNAllocation($debitID: String!) {
  getSupplierCNAllocation(debitID: $debitID)
}
    `;

/**
 * __useGetSupplierCnAllocationQuery__
 *
 * To run a query within a React component, call `useGetSupplierCnAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierCnAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierCnAllocationQuery({
 *   variables: {
 *      debitID: // value for 'debitID'
 *   },
 * });
 */
export function useGetSupplierCnAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierCnAllocationQuery, GetSupplierCnAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierCnAllocationQuery, GetSupplierCnAllocationQueryVariables>(GetSupplierCnAllocationDocument, baseOptions);
      }
export function useGetSupplierCnAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierCnAllocationQuery, GetSupplierCnAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierCnAllocationQuery, GetSupplierCnAllocationQueryVariables>(GetSupplierCnAllocationDocument, baseOptions);
        }
export type GetSupplierCnAllocationQueryHookResult = ReturnType<typeof useGetSupplierCnAllocationQuery>;
export type GetSupplierCnAllocationLazyQueryHookResult = ReturnType<typeof useGetSupplierCnAllocationLazyQuery>;
export type GetSupplierCnAllocationQueryResult = ApolloReactCommon.QueryResult<GetSupplierCnAllocationQuery, GetSupplierCnAllocationQueryVariables>;
export const GetSupplierPaymentAllocationDocument = gql`
    query getSupplierPaymentAllocation($debitID: String, $supplierID: String) {
  getSupplierPaymentAllocation(debitID: $debitID, supplierID: $supplierID)
}
    `;

/**
 * __useGetSupplierPaymentAllocationQuery__
 *
 * To run a query within a React component, call `useGetSupplierPaymentAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierPaymentAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierPaymentAllocationQuery({
 *   variables: {
 *      debitID: // value for 'debitID'
 *      supplierID: // value for 'supplierID'
 *   },
 * });
 */
export function useGetSupplierPaymentAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierPaymentAllocationQuery, GetSupplierPaymentAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierPaymentAllocationQuery, GetSupplierPaymentAllocationQueryVariables>(GetSupplierPaymentAllocationDocument, baseOptions);
      }
export function useGetSupplierPaymentAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierPaymentAllocationQuery, GetSupplierPaymentAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierPaymentAllocationQuery, GetSupplierPaymentAllocationQueryVariables>(GetSupplierPaymentAllocationDocument, baseOptions);
        }
export type GetSupplierPaymentAllocationQueryHookResult = ReturnType<typeof useGetSupplierPaymentAllocationQuery>;
export type GetSupplierPaymentAllocationLazyQueryHookResult = ReturnType<typeof useGetSupplierPaymentAllocationLazyQuery>;
export type GetSupplierPaymentAllocationQueryResult = ApolloReactCommon.QueryResult<GetSupplierPaymentAllocationQuery, GetSupplierPaymentAllocationQueryVariables>;
export const GetSupplierAccountExecutiveSummaryDocument = gql`
    query getSupplierAccountExecutiveSummary($supplierID: String!) {
  suppProfileDate(supplierID: $supplierID)
  supplierDepositsInfo(supplierID: $supplierID)
  supplierInvInfo(supplierID: $supplierID)
  supplierDNInfo(supplierID: $supplierID)
  supplierCNInfo(supplierID: $supplierID)
  submittedSupplierPaymentNotification(supplierID: $supplierID)
  supplierPaymentsInfo(supplierID: $supplierID)
  outstandingSupplier(supplierID: $supplierID)
  unallocatedSupplier(supplierID: $supplierID)
}
    `;

/**
 * __useGetSupplierAccountExecutiveSummaryQuery__
 *
 * To run a query within a React component, call `useGetSupplierAccountExecutiveSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierAccountExecutiveSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierAccountExecutiveSummaryQuery({
 *   variables: {
 *      supplierID: // value for 'supplierID'
 *   },
 * });
 */
export function useGetSupplierAccountExecutiveSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierAccountExecutiveSummaryQuery, GetSupplierAccountExecutiveSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierAccountExecutiveSummaryQuery, GetSupplierAccountExecutiveSummaryQueryVariables>(GetSupplierAccountExecutiveSummaryDocument, baseOptions);
      }
export function useGetSupplierAccountExecutiveSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierAccountExecutiveSummaryQuery, GetSupplierAccountExecutiveSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierAccountExecutiveSummaryQuery, GetSupplierAccountExecutiveSummaryQueryVariables>(GetSupplierAccountExecutiveSummaryDocument, baseOptions);
        }
export type GetSupplierAccountExecutiveSummaryQueryHookResult = ReturnType<typeof useGetSupplierAccountExecutiveSummaryQuery>;
export type GetSupplierAccountExecutiveSummaryLazyQueryHookResult = ReturnType<typeof useGetSupplierAccountExecutiveSummaryLazyQuery>;
export type GetSupplierAccountExecutiveSummaryQueryResult = ApolloReactCommon.QueryResult<GetSupplierAccountExecutiveSummaryQuery, GetSupplierAccountExecutiveSummaryQueryVariables>;
export const CancelSupplierInvoiceDocument = gql`
    mutation cancelSupplierInvoice($ID: String!, $input: CancelInput!) {
  cancelSupplierInvoice(ID: $ID, input: $input)
}
    `;
export type CancelSupplierInvoiceMutationFn = ApolloReactCommon.MutationFunction<CancelSupplierInvoiceMutation, CancelSupplierInvoiceMutationVariables>;

/**
 * __useCancelSupplierInvoiceMutation__
 *
 * To run a mutation, you first call `useCancelSupplierInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSupplierInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSupplierInvoiceMutation, { data, loading, error }] = useCancelSupplierInvoiceMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSupplierInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelSupplierInvoiceMutation, CancelSupplierInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelSupplierInvoiceMutation, CancelSupplierInvoiceMutationVariables>(CancelSupplierInvoiceDocument, baseOptions);
      }
export type CancelSupplierInvoiceMutationHookResult = ReturnType<typeof useCancelSupplierInvoiceMutation>;
export type CancelSupplierInvoiceMutationResult = ApolloReactCommon.MutationResult<CancelSupplierInvoiceMutation>;
export type CancelSupplierInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelSupplierInvoiceMutation, CancelSupplierInvoiceMutationVariables>;
export const CancelSupplierDepositDocument = gql`
    mutation cancelSupplierDeposit($ID: String!, $input: CancelInput!) {
  cancelSupplierDeposit(ID: $ID, input: $input)
}
    `;
export type CancelSupplierDepositMutationFn = ApolloReactCommon.MutationFunction<CancelSupplierDepositMutation, CancelSupplierDepositMutationVariables>;

/**
 * __useCancelSupplierDepositMutation__
 *
 * To run a mutation, you first call `useCancelSupplierDepositMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSupplierDepositMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSupplierDepositMutation, { data, loading, error }] = useCancelSupplierDepositMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSupplierDepositMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelSupplierDepositMutation, CancelSupplierDepositMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelSupplierDepositMutation, CancelSupplierDepositMutationVariables>(CancelSupplierDepositDocument, baseOptions);
      }
export type CancelSupplierDepositMutationHookResult = ReturnType<typeof useCancelSupplierDepositMutation>;
export type CancelSupplierDepositMutationResult = ApolloReactCommon.MutationResult<CancelSupplierDepositMutation>;
export type CancelSupplierDepositMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelSupplierDepositMutation, CancelSupplierDepositMutationVariables>;
export const CancelSupplierDnDocument = gql`
    mutation cancelSupplierDN($ID: String!, $input: CancelInput!) {
  cancelSupplierDN(ID: $ID, input: $input)
}
    `;
export type CancelSupplierDnMutationFn = ApolloReactCommon.MutationFunction<CancelSupplierDnMutation, CancelSupplierDnMutationVariables>;

/**
 * __useCancelSupplierDnMutation__
 *
 * To run a mutation, you first call `useCancelSupplierDnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSupplierDnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSupplierDnMutation, { data, loading, error }] = useCancelSupplierDnMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSupplierDnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelSupplierDnMutation, CancelSupplierDnMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelSupplierDnMutation, CancelSupplierDnMutationVariables>(CancelSupplierDnDocument, baseOptions);
      }
export type CancelSupplierDnMutationHookResult = ReturnType<typeof useCancelSupplierDnMutation>;
export type CancelSupplierDnMutationResult = ApolloReactCommon.MutationResult<CancelSupplierDnMutation>;
export type CancelSupplierDnMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelSupplierDnMutation, CancelSupplierDnMutationVariables>;
export const CancelSupplierCnDocument = gql`
    mutation cancelSupplierCN($ID: String!, $input: CancelInput!) {
  cancelSupplierCN(ID: $ID, input: $input)
}
    `;
export type CancelSupplierCnMutationFn = ApolloReactCommon.MutationFunction<CancelSupplierCnMutation, CancelSupplierCnMutationVariables>;

/**
 * __useCancelSupplierCnMutation__
 *
 * To run a mutation, you first call `useCancelSupplierCnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSupplierCnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSupplierCnMutation, { data, loading, error }] = useCancelSupplierCnMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSupplierCnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelSupplierCnMutation, CancelSupplierCnMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelSupplierCnMutation, CancelSupplierCnMutationVariables>(CancelSupplierCnDocument, baseOptions);
      }
export type CancelSupplierCnMutationHookResult = ReturnType<typeof useCancelSupplierCnMutation>;
export type CancelSupplierCnMutationResult = ApolloReactCommon.MutationResult<CancelSupplierCnMutation>;
export type CancelSupplierCnMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelSupplierCnMutation, CancelSupplierCnMutationVariables>;
export const CancelSupplierPaymentDocument = gql`
    mutation cancelSupplierPayment($ID: String!, $input: CancelInput!) {
  cancelSupplierPayment(ID: $ID, input: $input)
}
    `;
export type CancelSupplierPaymentMutationFn = ApolloReactCommon.MutationFunction<CancelSupplierPaymentMutation, CancelSupplierPaymentMutationVariables>;

/**
 * __useCancelSupplierPaymentMutation__
 *
 * To run a mutation, you first call `useCancelSupplierPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSupplierPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSupplierPaymentMutation, { data, loading, error }] = useCancelSupplierPaymentMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSupplierPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelSupplierPaymentMutation, CancelSupplierPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelSupplierPaymentMutation, CancelSupplierPaymentMutationVariables>(CancelSupplierPaymentDocument, baseOptions);
      }
export type CancelSupplierPaymentMutationHookResult = ReturnType<typeof useCancelSupplierPaymentMutation>;
export type CancelSupplierPaymentMutationResult = ApolloReactCommon.MutationResult<CancelSupplierPaymentMutation>;
export type CancelSupplierPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelSupplierPaymentMutation, CancelSupplierPaymentMutationVariables>;
export const DeleteApAdvanceDocument = gql`
    mutation deleteAPAdvance($ID: String!) {
  deleteAPAdvance(ID: $ID)
}
    `;
export type DeleteApAdvanceMutationFn = ApolloReactCommon.MutationFunction<DeleteApAdvanceMutation, DeleteApAdvanceMutationVariables>;

/**
 * __useDeleteApAdvanceMutation__
 *
 * To run a mutation, you first call `useDeleteApAdvanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApAdvanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApAdvanceMutation, { data, loading, error }] = useDeleteApAdvanceMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteApAdvanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteApAdvanceMutation, DeleteApAdvanceMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteApAdvanceMutation, DeleteApAdvanceMutationVariables>(DeleteApAdvanceDocument, baseOptions);
      }
export type DeleteApAdvanceMutationHookResult = ReturnType<typeof useDeleteApAdvanceMutation>;
export type DeleteApAdvanceMutationResult = ApolloReactCommon.MutationResult<DeleteApAdvanceMutation>;
export type DeleteApAdvanceMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteApAdvanceMutation, DeleteApAdvanceMutationVariables>;
export const DeleteApInvoiceDocument = gql`
    mutation deleteAPInvoice($ID: String!) {
  deleteAPInvoice(ID: $ID)
}
    `;
export type DeleteApInvoiceMutationFn = ApolloReactCommon.MutationFunction<DeleteApInvoiceMutation, DeleteApInvoiceMutationVariables>;

/**
 * __useDeleteApInvoiceMutation__
 *
 * To run a mutation, you first call `useDeleteApInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApInvoiceMutation, { data, loading, error }] = useDeleteApInvoiceMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteApInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteApInvoiceMutation, DeleteApInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteApInvoiceMutation, DeleteApInvoiceMutationVariables>(DeleteApInvoiceDocument, baseOptions);
      }
export type DeleteApInvoiceMutationHookResult = ReturnType<typeof useDeleteApInvoiceMutation>;
export type DeleteApInvoiceMutationResult = ApolloReactCommon.MutationResult<DeleteApInvoiceMutation>;
export type DeleteApInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteApInvoiceMutation, DeleteApInvoiceMutationVariables>;
export const DeleteApCreditNoteDocument = gql`
    mutation deleteAPCreditNote($ID: String!) {
  deleteAPCreditNote(ID: $ID)
}
    `;
export type DeleteApCreditNoteMutationFn = ApolloReactCommon.MutationFunction<DeleteApCreditNoteMutation, DeleteApCreditNoteMutationVariables>;

/**
 * __useDeleteApCreditNoteMutation__
 *
 * To run a mutation, you first call `useDeleteApCreditNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApCreditNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApCreditNoteMutation, { data, loading, error }] = useDeleteApCreditNoteMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteApCreditNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteApCreditNoteMutation, DeleteApCreditNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteApCreditNoteMutation, DeleteApCreditNoteMutationVariables>(DeleteApCreditNoteDocument, baseOptions);
      }
export type DeleteApCreditNoteMutationHookResult = ReturnType<typeof useDeleteApCreditNoteMutation>;
export type DeleteApCreditNoteMutationResult = ApolloReactCommon.MutationResult<DeleteApCreditNoteMutation>;
export type DeleteApCreditNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteApCreditNoteMutation, DeleteApCreditNoteMutationVariables>;
export const DeleteApDebitNoteDocument = gql`
    mutation deleteAPDebitNote($ID: String!) {
  deleteAPDebitNote(ID: $ID)
}
    `;
export type DeleteApDebitNoteMutationFn = ApolloReactCommon.MutationFunction<DeleteApDebitNoteMutation, DeleteApDebitNoteMutationVariables>;

/**
 * __useDeleteApDebitNoteMutation__
 *
 * To run a mutation, you first call `useDeleteApDebitNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApDebitNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApDebitNoteMutation, { data, loading, error }] = useDeleteApDebitNoteMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteApDebitNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteApDebitNoteMutation, DeleteApDebitNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteApDebitNoteMutation, DeleteApDebitNoteMutationVariables>(DeleteApDebitNoteDocument, baseOptions);
      }
export type DeleteApDebitNoteMutationHookResult = ReturnType<typeof useDeleteApDebitNoteMutation>;
export type DeleteApDebitNoteMutationResult = ApolloReactCommon.MutationResult<DeleteApDebitNoteMutation>;
export type DeleteApDebitNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteApDebitNoteMutation, DeleteApDebitNoteMutationVariables>;
export const DeleteApPaymentDocument = gql`
    mutation deleteAPPayment($ID: String!) {
  deleteAPPayment(ID: $ID)
}
    `;
export type DeleteApPaymentMutationFn = ApolloReactCommon.MutationFunction<DeleteApPaymentMutation, DeleteApPaymentMutationVariables>;

/**
 * __useDeleteApPaymentMutation__
 *
 * To run a mutation, you first call `useDeleteApPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApPaymentMutation, { data, loading, error }] = useDeleteApPaymentMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteApPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteApPaymentMutation, DeleteApPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteApPaymentMutation, DeleteApPaymentMutationVariables>(DeleteApPaymentDocument, baseOptions);
      }
export type DeleteApPaymentMutationHookResult = ReturnType<typeof useDeleteApPaymentMutation>;
export type DeleteApPaymentMutationResult = ApolloReactCommon.MutationResult<DeleteApPaymentMutation>;
export type DeleteApPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteApPaymentMutation, DeleteApPaymentMutationVariables>;
export const UpdateConTaxTypeDocument = gql`
    mutation updateConTaxType($input: ConTaxTypeInput!) {
  updateConTaxType(input: $input) {
    ID
    code
    description
  }
}
    `;
export type UpdateConTaxTypeMutationFn = ApolloReactCommon.MutationFunction<UpdateConTaxTypeMutation, UpdateConTaxTypeMutationVariables>;

/**
 * __useUpdateConTaxTypeMutation__
 *
 * To run a mutation, you first call `useUpdateConTaxTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConTaxTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConTaxTypeMutation, { data, loading, error }] = useUpdateConTaxTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateConTaxTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateConTaxTypeMutation, UpdateConTaxTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateConTaxTypeMutation, UpdateConTaxTypeMutationVariables>(UpdateConTaxTypeDocument, baseOptions);
      }
export type UpdateConTaxTypeMutationHookResult = ReturnType<typeof useUpdateConTaxTypeMutation>;
export type UpdateConTaxTypeMutationResult = ApolloReactCommon.MutationResult<UpdateConTaxTypeMutation>;
export type UpdateConTaxTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateConTaxTypeMutation, UpdateConTaxTypeMutationVariables>;
export const CreateConTaxTypeDocument = gql`
    mutation createConTaxType($input: ConTaxTypeInput!) {
  createConTaxType(input: $input) {
    ID
  }
}
    `;
export type CreateConTaxTypeMutationFn = ApolloReactCommon.MutationFunction<CreateConTaxTypeMutation, CreateConTaxTypeMutationVariables>;

/**
 * __useCreateConTaxTypeMutation__
 *
 * To run a mutation, you first call `useCreateConTaxTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConTaxTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConTaxTypeMutation, { data, loading, error }] = useCreateConTaxTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateConTaxTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateConTaxTypeMutation, CreateConTaxTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateConTaxTypeMutation, CreateConTaxTypeMutationVariables>(CreateConTaxTypeDocument, baseOptions);
      }
export type CreateConTaxTypeMutationHookResult = ReturnType<typeof useCreateConTaxTypeMutation>;
export type CreateConTaxTypeMutationResult = ApolloReactCommon.MutationResult<CreateConTaxTypeMutation>;
export type CreateConTaxTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateConTaxTypeMutation, CreateConTaxTypeMutationVariables>;
export const GetConTaxTypeDocument = gql`
    query getConTaxType {
  getConTaxType {
    ID
    code
    commonStatus
    description
    createdTs
  }
}
    `;

/**
 * __useGetConTaxTypeQuery__
 *
 * To run a query within a React component, call `useGetConTaxTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConTaxTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConTaxTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetConTaxTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetConTaxTypeQuery, GetConTaxTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetConTaxTypeQuery, GetConTaxTypeQueryVariables>(GetConTaxTypeDocument, baseOptions);
      }
export function useGetConTaxTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetConTaxTypeQuery, GetConTaxTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetConTaxTypeQuery, GetConTaxTypeQueryVariables>(GetConTaxTypeDocument, baseOptions);
        }
export type GetConTaxTypeQueryHookResult = ReturnType<typeof useGetConTaxTypeQuery>;
export type GetConTaxTypeLazyQueryHookResult = ReturnType<typeof useGetConTaxTypeLazyQuery>;
export type GetConTaxTypeQueryResult = ApolloReactCommon.QueryResult<GetConTaxTypeQuery, GetConTaxTypeQueryVariables>;
export const DeleteConTaxTypeDocument = gql`
    mutation deleteConTaxType($input: ConTaxTypeInput!) {
  deleteConTaxType(input: $input)
}
    `;
export type DeleteConTaxTypeMutationFn = ApolloReactCommon.MutationFunction<DeleteConTaxTypeMutation, DeleteConTaxTypeMutationVariables>;

/**
 * __useDeleteConTaxTypeMutation__
 *
 * To run a mutation, you first call `useDeleteConTaxTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConTaxTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConTaxTypeMutation, { data, loading, error }] = useDeleteConTaxTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteConTaxTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteConTaxTypeMutation, DeleteConTaxTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteConTaxTypeMutation, DeleteConTaxTypeMutationVariables>(DeleteConTaxTypeDocument, baseOptions);
      }
export type DeleteConTaxTypeMutationHookResult = ReturnType<typeof useDeleteConTaxTypeMutation>;
export type DeleteConTaxTypeMutationResult = ApolloReactCommon.MutationResult<DeleteConTaxTypeMutation>;
export type DeleteConTaxTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteConTaxTypeMutation, DeleteConTaxTypeMutationVariables>;
export const CreateConTaxSchemeDocument = gql`
    mutation createConTaxScheme($input: ConTaxSchemeInput!) {
  createConTaxScheme(input: $input) {
    ID
  }
}
    `;
export type CreateConTaxSchemeMutationFn = ApolloReactCommon.MutationFunction<CreateConTaxSchemeMutation, CreateConTaxSchemeMutationVariables>;

/**
 * __useCreateConTaxSchemeMutation__
 *
 * To run a mutation, you first call `useCreateConTaxSchemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConTaxSchemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConTaxSchemeMutation, { data, loading, error }] = useCreateConTaxSchemeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateConTaxSchemeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateConTaxSchemeMutation, CreateConTaxSchemeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateConTaxSchemeMutation, CreateConTaxSchemeMutationVariables>(CreateConTaxSchemeDocument, baseOptions);
      }
export type CreateConTaxSchemeMutationHookResult = ReturnType<typeof useCreateConTaxSchemeMutation>;
export type CreateConTaxSchemeMutationResult = ApolloReactCommon.MutationResult<CreateConTaxSchemeMutation>;
export type CreateConTaxSchemeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateConTaxSchemeMutation, CreateConTaxSchemeMutationVariables>;
export const UpdateConTaxSchemeDocument = gql`
    mutation updateConTaxScheme($input: ConTaxSchemeInput!) {
  updateConTaxScheme(input: $input) {
    ID
  }
}
    `;
export type UpdateConTaxSchemeMutationFn = ApolloReactCommon.MutationFunction<UpdateConTaxSchemeMutation, UpdateConTaxSchemeMutationVariables>;

/**
 * __useUpdateConTaxSchemeMutation__
 *
 * To run a mutation, you first call `useUpdateConTaxSchemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConTaxSchemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConTaxSchemeMutation, { data, loading, error }] = useUpdateConTaxSchemeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateConTaxSchemeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateConTaxSchemeMutation, UpdateConTaxSchemeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateConTaxSchemeMutation, UpdateConTaxSchemeMutationVariables>(UpdateConTaxSchemeDocument, baseOptions);
      }
export type UpdateConTaxSchemeMutationHookResult = ReturnType<typeof useUpdateConTaxSchemeMutation>;
export type UpdateConTaxSchemeMutationResult = ApolloReactCommon.MutationResult<UpdateConTaxSchemeMutation>;
export type UpdateConTaxSchemeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateConTaxSchemeMutation, UpdateConTaxSchemeMutationVariables>;
export const DeleteConTaxSchemeDocument = gql`
    mutation deleteConTaxScheme($input: ConTaxSchemeInput!) {
  deleteConTaxScheme(input: $input)
}
    `;
export type DeleteConTaxSchemeMutationFn = ApolloReactCommon.MutationFunction<DeleteConTaxSchemeMutation, DeleteConTaxSchemeMutationVariables>;

/**
 * __useDeleteConTaxSchemeMutation__
 *
 * To run a mutation, you first call `useDeleteConTaxSchemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConTaxSchemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConTaxSchemeMutation, { data, loading, error }] = useDeleteConTaxSchemeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteConTaxSchemeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteConTaxSchemeMutation, DeleteConTaxSchemeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteConTaxSchemeMutation, DeleteConTaxSchemeMutationVariables>(DeleteConTaxSchemeDocument, baseOptions);
      }
export type DeleteConTaxSchemeMutationHookResult = ReturnType<typeof useDeleteConTaxSchemeMutation>;
export type DeleteConTaxSchemeMutationResult = ApolloReactCommon.MutationResult<DeleteConTaxSchemeMutation>;
export type DeleteConTaxSchemeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteConTaxSchemeMutation, DeleteConTaxSchemeMutationVariables>;
export const CreateConTaxEffectiveDateDocument = gql`
    mutation createConTaxEffectiveDate($input: ConTaxEffectiveDateInput!) {
  createConTaxEffectiveDate(input: $input) {
    ID
  }
}
    `;
export type CreateConTaxEffectiveDateMutationFn = ApolloReactCommon.MutationFunction<CreateConTaxEffectiveDateMutation, CreateConTaxEffectiveDateMutationVariables>;

/**
 * __useCreateConTaxEffectiveDateMutation__
 *
 * To run a mutation, you first call `useCreateConTaxEffectiveDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConTaxEffectiveDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConTaxEffectiveDateMutation, { data, loading, error }] = useCreateConTaxEffectiveDateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateConTaxEffectiveDateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateConTaxEffectiveDateMutation, CreateConTaxEffectiveDateMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateConTaxEffectiveDateMutation, CreateConTaxEffectiveDateMutationVariables>(CreateConTaxEffectiveDateDocument, baseOptions);
      }
export type CreateConTaxEffectiveDateMutationHookResult = ReturnType<typeof useCreateConTaxEffectiveDateMutation>;
export type CreateConTaxEffectiveDateMutationResult = ApolloReactCommon.MutationResult<CreateConTaxEffectiveDateMutation>;
export type CreateConTaxEffectiveDateMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateConTaxEffectiveDateMutation, CreateConTaxEffectiveDateMutationVariables>;
export const GetConTaxSchemeDocument = gql`
    query getConTaxScheme($taxTypeID: String, $ID: String) {
  getConTaxScheme(taxTypeID: $taxTypeID, ID: $ID) {
    ID
    code
    description
    taxCategory
    taxTypeID
    taxClass
    createdTs
    commonStatus
    latestTax {
      taxRate
      taxDate
    }
    taxEffective {
      date
      taxRate
      createdTs
    }
  }
}
    `;

/**
 * __useGetConTaxSchemeQuery__
 *
 * To run a query within a React component, call `useGetConTaxSchemeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConTaxSchemeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConTaxSchemeQuery({
 *   variables: {
 *      taxTypeID: // value for 'taxTypeID'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetConTaxSchemeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetConTaxSchemeQuery, GetConTaxSchemeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetConTaxSchemeQuery, GetConTaxSchemeQueryVariables>(GetConTaxSchemeDocument, baseOptions);
      }
export function useGetConTaxSchemeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetConTaxSchemeQuery, GetConTaxSchemeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetConTaxSchemeQuery, GetConTaxSchemeQueryVariables>(GetConTaxSchemeDocument, baseOptions);
        }
export type GetConTaxSchemeQueryHookResult = ReturnType<typeof useGetConTaxSchemeQuery>;
export type GetConTaxSchemeLazyQueryHookResult = ReturnType<typeof useGetConTaxSchemeLazyQuery>;
export type GetConTaxSchemeQueryResult = ApolloReactCommon.QueryResult<GetConTaxSchemeQuery, GetConTaxSchemeQueryVariables>;
export const GetConTaxEffectiveDateDocument = gql`
    query getConTaxEffectiveDate($ID: String, $taxSchemeID: String) {
  getConTaxEffectiveDate(ID: $ID, taxSchemeID: $taxSchemeID) {
    ID
    taxSchemeID
    date
    taxRate
  }
}
    `;

/**
 * __useGetConTaxEffectiveDateQuery__
 *
 * To run a query within a React component, call `useGetConTaxEffectiveDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConTaxEffectiveDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConTaxEffectiveDateQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      taxSchemeID: // value for 'taxSchemeID'
 *   },
 * });
 */
export function useGetConTaxEffectiveDateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetConTaxEffectiveDateQuery, GetConTaxEffectiveDateQueryVariables>) {
        return ApolloReactHooks.useQuery<GetConTaxEffectiveDateQuery, GetConTaxEffectiveDateQueryVariables>(GetConTaxEffectiveDateDocument, baseOptions);
      }
export function useGetConTaxEffectiveDateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetConTaxEffectiveDateQuery, GetConTaxEffectiveDateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetConTaxEffectiveDateQuery, GetConTaxEffectiveDateQueryVariables>(GetConTaxEffectiveDateDocument, baseOptions);
        }
export type GetConTaxEffectiveDateQueryHookResult = ReturnType<typeof useGetConTaxEffectiveDateQuery>;
export type GetConTaxEffectiveDateLazyQueryHookResult = ReturnType<typeof useGetConTaxEffectiveDateLazyQuery>;
export type GetConTaxEffectiveDateQueryResult = ApolloReactCommon.QueryResult<GetConTaxEffectiveDateQuery, GetConTaxEffectiveDateQueryVariables>;
export const InsertConTaxDocument = gql`
    mutation insertConTax($TaxEffectiveInput: [TaxEffectiveInput!]!, $ConTaxSchemeInput: ConTaxSchemeInput!) {
  insertConTax(taxEffectiveInput: $TaxEffectiveInput, conTaxSchemeInput: $ConTaxSchemeInput) {
    ID
  }
}
    `;
export type InsertConTaxMutationFn = ApolloReactCommon.MutationFunction<InsertConTaxMutation, InsertConTaxMutationVariables>;

/**
 * __useInsertConTaxMutation__
 *
 * To run a mutation, you first call `useInsertConTaxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertConTaxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertConTaxMutation, { data, loading, error }] = useInsertConTaxMutation({
 *   variables: {
 *      TaxEffectiveInput: // value for 'TaxEffectiveInput'
 *      ConTaxSchemeInput: // value for 'ConTaxSchemeInput'
 *   },
 * });
 */
export function useInsertConTaxMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertConTaxMutation, InsertConTaxMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertConTaxMutation, InsertConTaxMutationVariables>(InsertConTaxDocument, baseOptions);
      }
export type InsertConTaxMutationHookResult = ReturnType<typeof useInsertConTaxMutation>;
export type InsertConTaxMutationResult = ApolloReactCommon.MutationResult<InsertConTaxMutation>;
export type InsertConTaxMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertConTaxMutation, InsertConTaxMutationVariables>;
export const UpdateConTaxSchemeWithEffectiveDateDocument = gql`
    mutation updateConTaxSchemeWithEffectiveDate($TaxEffectiveInput: [TaxEffectiveInput!]!, $ConTaxSchemeInput: ConTaxSchemeInput!) {
  updateConTax(taxEffectiveInput: $TaxEffectiveInput, conTaxSchemeInput: $ConTaxSchemeInput)
}
    `;
export type UpdateConTaxSchemeWithEffectiveDateMutationFn = ApolloReactCommon.MutationFunction<UpdateConTaxSchemeWithEffectiveDateMutation, UpdateConTaxSchemeWithEffectiveDateMutationVariables>;

/**
 * __useUpdateConTaxSchemeWithEffectiveDateMutation__
 *
 * To run a mutation, you first call `useUpdateConTaxSchemeWithEffectiveDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConTaxSchemeWithEffectiveDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConTaxSchemeWithEffectiveDateMutation, { data, loading, error }] = useUpdateConTaxSchemeWithEffectiveDateMutation({
 *   variables: {
 *      TaxEffectiveInput: // value for 'TaxEffectiveInput'
 *      ConTaxSchemeInput: // value for 'ConTaxSchemeInput'
 *   },
 * });
 */
export function useUpdateConTaxSchemeWithEffectiveDateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateConTaxSchemeWithEffectiveDateMutation, UpdateConTaxSchemeWithEffectiveDateMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateConTaxSchemeWithEffectiveDateMutation, UpdateConTaxSchemeWithEffectiveDateMutationVariables>(UpdateConTaxSchemeWithEffectiveDateDocument, baseOptions);
      }
export type UpdateConTaxSchemeWithEffectiveDateMutationHookResult = ReturnType<typeof useUpdateConTaxSchemeWithEffectiveDateMutation>;
export type UpdateConTaxSchemeWithEffectiveDateMutationResult = ApolloReactCommon.MutationResult<UpdateConTaxSchemeWithEffectiveDateMutation>;
export type UpdateConTaxSchemeWithEffectiveDateMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateConTaxSchemeWithEffectiveDateMutation, UpdateConTaxSchemeWithEffectiveDateMutationVariables>;
export const CreateSupplierDocument = gql`
    mutation createSupplier($input: SupplierInput!) {
  createSupplier(input: $input) {
    name
  }
}
    `;
export type CreateSupplierMutationFn = ApolloReactCommon.MutationFunction<CreateSupplierMutation, CreateSupplierMutationVariables>;

/**
 * __useCreateSupplierMutation__
 *
 * To run a mutation, you first call `useCreateSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupplierMutation, { data, loading, error }] = useCreateSupplierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSupplierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSupplierMutation, CreateSupplierMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSupplierMutation, CreateSupplierMutationVariables>(CreateSupplierDocument, baseOptions);
      }
export type CreateSupplierMutationHookResult = ReturnType<typeof useCreateSupplierMutation>;
export type CreateSupplierMutationResult = ApolloReactCommon.MutationResult<CreateSupplierMutation>;
export type CreateSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSupplierMutation, CreateSupplierMutationVariables>;
export const UpdateSupplierDocument = gql`
    mutation updateSupplier($input: SupplierInput!) {
  updateSupplier(input: $input) {
    name
  }
}
    `;
export type UpdateSupplierMutationFn = ApolloReactCommon.MutationFunction<UpdateSupplierMutation, UpdateSupplierMutationVariables>;

/**
 * __useUpdateSupplierMutation__
 *
 * To run a mutation, you first call `useUpdateSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSupplierMutation, { data, loading, error }] = useUpdateSupplierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSupplierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSupplierMutation, UpdateSupplierMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSupplierMutation, UpdateSupplierMutationVariables>(UpdateSupplierDocument, baseOptions);
      }
export type UpdateSupplierMutationHookResult = ReturnType<typeof useUpdateSupplierMutation>;
export type UpdateSupplierMutationResult = ApolloReactCommon.MutationResult<UpdateSupplierMutation>;
export type UpdateSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSupplierMutation, UpdateSupplierMutationVariables>;
export const GetSupplierDocument = gql`
    query getSupplier($ID: String, $orderByAsc: String) {
  getSupplier(ID: $ID, orderByAsc: $orderByAsc) {
    ID
    name
    registrationNo
    email
    address
    currencyID
    creditTerm
    contactNo
    contactPerson {
      ID
      name
      designation
      email
      contactNo
      associateID
    }
    description
    ratingType
    taxDetails
    taxTypeID
    associatedStatus
    bankDetails
    accountID
    costCategory {
      ID
      name
    }
    supplierInv {
      ID
      transactionStatus
      outstanding
    }
  }
}
    `;

/**
 * __useGetSupplierQuery__
 *
 * To run a query within a React component, call `useGetSupplierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetSupplierQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierQuery, GetSupplierQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierQuery, GetSupplierQueryVariables>(GetSupplierDocument, baseOptions);
      }
export function useGetSupplierLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierQuery, GetSupplierQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierQuery, GetSupplierQueryVariables>(GetSupplierDocument, baseOptions);
        }
export type GetSupplierQueryHookResult = ReturnType<typeof useGetSupplierQuery>;
export type GetSupplierLazyQueryHookResult = ReturnType<typeof useGetSupplierLazyQuery>;
export type GetSupplierQueryResult = ApolloReactCommon.QueryResult<GetSupplierQuery, GetSupplierQueryVariables>;
export const GetSupplierListDocument = gql`
    query getSupplierList($ID: String, $orderByAsc: String) {
  getSupplier(ID: $ID, orderByAsc: $orderByAsc) {
    ID
    name
  }
}
    `;

/**
 * __useGetSupplierListQuery__
 *
 * To run a query within a React component, call `useGetSupplierListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierListQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetSupplierListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierListQuery, GetSupplierListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierListQuery, GetSupplierListQueryVariables>(GetSupplierListDocument, baseOptions);
      }
export function useGetSupplierListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierListQuery, GetSupplierListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierListQuery, GetSupplierListQueryVariables>(GetSupplierListDocument, baseOptions);
        }
export type GetSupplierListQueryHookResult = ReturnType<typeof useGetSupplierListQuery>;
export type GetSupplierListLazyQueryHookResult = ReturnType<typeof useGetSupplierListLazyQuery>;
export type GetSupplierListQueryResult = ApolloReactCommon.QueryResult<GetSupplierListQuery, GetSupplierListQueryVariables>;
export const CreateSupplierCCategoryDocument = gql`
    mutation createSupplierCCategory($input: SupplierCCategoryInput!) {
  createSupplierCCategory(input: $input) {
    costCategoryID
  }
}
    `;
export type CreateSupplierCCategoryMutationFn = ApolloReactCommon.MutationFunction<CreateSupplierCCategoryMutation, CreateSupplierCCategoryMutationVariables>;

/**
 * __useCreateSupplierCCategoryMutation__
 *
 * To run a mutation, you first call `useCreateSupplierCCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupplierCCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupplierCCategoryMutation, { data, loading, error }] = useCreateSupplierCCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSupplierCCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSupplierCCategoryMutation, CreateSupplierCCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSupplierCCategoryMutation, CreateSupplierCCategoryMutationVariables>(CreateSupplierCCategoryDocument, baseOptions);
      }
export type CreateSupplierCCategoryMutationHookResult = ReturnType<typeof useCreateSupplierCCategoryMutation>;
export type CreateSupplierCCategoryMutationResult = ApolloReactCommon.MutationResult<CreateSupplierCCategoryMutation>;
export type CreateSupplierCCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSupplierCCategoryMutation, CreateSupplierCCategoryMutationVariables>;
export const UpdateSupplierCCategoryDocument = gql`
    mutation updateSupplierCCategory($input: SupplierCCategoryInput!) {
  updateSupplierCCategory(input: $input) {
    costCategoryID
  }
}
    `;
export type UpdateSupplierCCategoryMutationFn = ApolloReactCommon.MutationFunction<UpdateSupplierCCategoryMutation, UpdateSupplierCCategoryMutationVariables>;

/**
 * __useUpdateSupplierCCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateSupplierCCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupplierCCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSupplierCCategoryMutation, { data, loading, error }] = useUpdateSupplierCCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSupplierCCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSupplierCCategoryMutation, UpdateSupplierCCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSupplierCCategoryMutation, UpdateSupplierCCategoryMutationVariables>(UpdateSupplierCCategoryDocument, baseOptions);
      }
export type UpdateSupplierCCategoryMutationHookResult = ReturnType<typeof useUpdateSupplierCCategoryMutation>;
export type UpdateSupplierCCategoryMutationResult = ApolloReactCommon.MutationResult<UpdateSupplierCCategoryMutation>;
export type UpdateSupplierCCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSupplierCCategoryMutation, UpdateSupplierCCategoryMutationVariables>;
export const GetSupplierCCategoryDocument = gql`
    query getSupplierCCategory($supplierID: String, $costCategoryID: String) {
  getSupplierCCategory(supplierID: $supplierID, costCategoryID: $costCategoryID) {
    supplierID
    costCategoryID
  }
}
    `;

/**
 * __useGetSupplierCCategoryQuery__
 *
 * To run a query within a React component, call `useGetSupplierCCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierCCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierCCategoryQuery({
 *   variables: {
 *      supplierID: // value for 'supplierID'
 *      costCategoryID: // value for 'costCategoryID'
 *   },
 * });
 */
export function useGetSupplierCCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierCCategoryQuery, GetSupplierCCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierCCategoryQuery, GetSupplierCCategoryQueryVariables>(GetSupplierCCategoryDocument, baseOptions);
      }
export function useGetSupplierCCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierCCategoryQuery, GetSupplierCCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierCCategoryQuery, GetSupplierCCategoryQueryVariables>(GetSupplierCCategoryDocument, baseOptions);
        }
export type GetSupplierCCategoryQueryHookResult = ReturnType<typeof useGetSupplierCCategoryQuery>;
export type GetSupplierCCategoryLazyQueryHookResult = ReturnType<typeof useGetSupplierCCategoryLazyQuery>;
export type GetSupplierCCategoryQueryResult = ApolloReactCommon.QueryResult<GetSupplierCCategoryQuery, GetSupplierCCategoryQueryVariables>;
export const AddCCategoryInSupplierDocument = gql`
    mutation addCCategoryInSupplier($supplierID: String!, $costCategoryID: [String!]!) {
  addCCategoryInSupplier(supplierID: $supplierID, costCategoryID: $costCategoryID)
}
    `;
export type AddCCategoryInSupplierMutationFn = ApolloReactCommon.MutationFunction<AddCCategoryInSupplierMutation, AddCCategoryInSupplierMutationVariables>;

/**
 * __useAddCCategoryInSupplierMutation__
 *
 * To run a mutation, you first call `useAddCCategoryInSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCCategoryInSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCCategoryInSupplierMutation, { data, loading, error }] = useAddCCategoryInSupplierMutation({
 *   variables: {
 *      supplierID: // value for 'supplierID'
 *      costCategoryID: // value for 'costCategoryID'
 *   },
 * });
 */
export function useAddCCategoryInSupplierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddCCategoryInSupplierMutation, AddCCategoryInSupplierMutationVariables>) {
        return ApolloReactHooks.useMutation<AddCCategoryInSupplierMutation, AddCCategoryInSupplierMutationVariables>(AddCCategoryInSupplierDocument, baseOptions);
      }
export type AddCCategoryInSupplierMutationHookResult = ReturnType<typeof useAddCCategoryInSupplierMutation>;
export type AddCCategoryInSupplierMutationResult = ApolloReactCommon.MutationResult<AddCCategoryInSupplierMutation>;
export type AddCCategoryInSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<AddCCategoryInSupplierMutation, AddCCategoryInSupplierMutationVariables>;
export const GetSupplierExecutiveSummaryDocument = gql`
    query getSupplierExecutiveSummary($supplierID: String!) {
  supplierProfileDate(ID: $supplierID)
  PrefSupplierProductDate(supplierID: $supplierID)
  SupplierAccDate(supplierID: $supplierID)
}
    `;

/**
 * __useGetSupplierExecutiveSummaryQuery__
 *
 * To run a query within a React component, call `useGetSupplierExecutiveSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierExecutiveSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierExecutiveSummaryQuery({
 *   variables: {
 *      supplierID: // value for 'supplierID'
 *   },
 * });
 */
export function useGetSupplierExecutiveSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierExecutiveSummaryQuery, GetSupplierExecutiveSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierExecutiveSummaryQuery, GetSupplierExecutiveSummaryQueryVariables>(GetSupplierExecutiveSummaryDocument, baseOptions);
      }
export function useGetSupplierExecutiveSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierExecutiveSummaryQuery, GetSupplierExecutiveSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierExecutiveSummaryQuery, GetSupplierExecutiveSummaryQueryVariables>(GetSupplierExecutiveSummaryDocument, baseOptions);
        }
export type GetSupplierExecutiveSummaryQueryHookResult = ReturnType<typeof useGetSupplierExecutiveSummaryQuery>;
export type GetSupplierExecutiveSummaryLazyQueryHookResult = ReturnType<typeof useGetSupplierExecutiveSummaryLazyQuery>;
export type GetSupplierExecutiveSummaryQueryResult = ApolloReactCommon.QueryResult<GetSupplierExecutiveSummaryQuery, GetSupplierExecutiveSummaryQueryVariables>;
export const GetSupplierTitleDocument = gql`
    query getSupplierTitle($ID: String, $orderByAsc: String) {
  getSupplier(ID: $ID, orderByAsc: $orderByAsc) {
    ID
    name
  }
}
    `;

/**
 * __useGetSupplierTitleQuery__
 *
 * To run a query within a React component, call `useGetSupplierTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierTitleQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetSupplierTitleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierTitleQuery, GetSupplierTitleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierTitleQuery, GetSupplierTitleQueryVariables>(GetSupplierTitleDocument, baseOptions);
      }
export function useGetSupplierTitleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierTitleQuery, GetSupplierTitleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierTitleQuery, GetSupplierTitleQueryVariables>(GetSupplierTitleDocument, baseOptions);
        }
export type GetSupplierTitleQueryHookResult = ReturnType<typeof useGetSupplierTitleQuery>;
export type GetSupplierTitleLazyQueryHookResult = ReturnType<typeof useGetSupplierTitleLazyQuery>;
export type GetSupplierTitleQueryResult = ApolloReactCommon.QueryResult<GetSupplierTitleQuery, GetSupplierTitleQueryVariables>;
export const CashFlowSummaryDocument = gql`
    query cashFlowSummary($contractID: String!) {
  receivedFromClient(contractID: $contractID)
  paidToSubCon(contractID: $contractID)
  paidToSupplier(contractID: $contractID)
  miscExpense(contractID: $contractID)
  outstandingClaims(contractID: $contractID) {
    outstandingAmt
  }
  clientLastReceipt(contractID: $contractID)
  getSubcontractClaim(contractID: $contractID) {
    ID
    outstandingAmt
  }
  subconLastPayment(contractID: $contractID)
  supplierOSPayableAmount(contractID: $contractID)
}
    `;

/**
 * __useCashFlowSummaryQuery__
 *
 * To run a query within a React component, call `useCashFlowSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCashFlowSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCashFlowSummaryQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useCashFlowSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CashFlowSummaryQuery, CashFlowSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<CashFlowSummaryQuery, CashFlowSummaryQueryVariables>(CashFlowSummaryDocument, baseOptions);
      }
export function useCashFlowSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CashFlowSummaryQuery, CashFlowSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CashFlowSummaryQuery, CashFlowSummaryQueryVariables>(CashFlowSummaryDocument, baseOptions);
        }
export type CashFlowSummaryQueryHookResult = ReturnType<typeof useCashFlowSummaryQuery>;
export type CashFlowSummaryLazyQueryHookResult = ReturnType<typeof useCashFlowSummaryLazyQuery>;
export type CashFlowSummaryQueryResult = ApolloReactCommon.QueryResult<CashFlowSummaryQuery, CashFlowSummaryQueryVariables>;
export const GetReceiptsListDocument = gql`
    query getReceiptsList($contractID: String!) {
  financialStatus(contractID: $contractID) {
    collectionListing
    collectionSum
  }
}
    `;

/**
 * __useGetReceiptsListQuery__
 *
 * To run a query within a React component, call `useGetReceiptsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReceiptsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReceiptsListQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetReceiptsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetReceiptsListQuery, GetReceiptsListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetReceiptsListQuery, GetReceiptsListQueryVariables>(GetReceiptsListDocument, baseOptions);
      }
export function useGetReceiptsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetReceiptsListQuery, GetReceiptsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetReceiptsListQuery, GetReceiptsListQueryVariables>(GetReceiptsListDocument, baseOptions);
        }
export type GetReceiptsListQueryHookResult = ReturnType<typeof useGetReceiptsListQuery>;
export type GetReceiptsListLazyQueryHookResult = ReturnType<typeof useGetReceiptsListLazyQuery>;
export type GetReceiptsListQueryResult = ApolloReactCommon.QueryResult<GetReceiptsListQuery, GetReceiptsListQueryVariables>;
export const GetSubconPaymentsListDocument = gql`
    query getSubconPaymentsList($contractID: String!) {
  financialStatus(contractID: $contractID) {
    subconPayment
    subconPaymentSum
  }
}
    `;

/**
 * __useGetSubconPaymentsListQuery__
 *
 * To run a query within a React component, call `useGetSubconPaymentsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubconPaymentsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubconPaymentsListQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetSubconPaymentsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubconPaymentsListQuery, GetSubconPaymentsListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubconPaymentsListQuery, GetSubconPaymentsListQueryVariables>(GetSubconPaymentsListDocument, baseOptions);
      }
export function useGetSubconPaymentsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubconPaymentsListQuery, GetSubconPaymentsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubconPaymentsListQuery, GetSubconPaymentsListQueryVariables>(GetSubconPaymentsListDocument, baseOptions);
        }
export type GetSubconPaymentsListQueryHookResult = ReturnType<typeof useGetSubconPaymentsListQuery>;
export type GetSubconPaymentsListLazyQueryHookResult = ReturnType<typeof useGetSubconPaymentsListLazyQuery>;
export type GetSubconPaymentsListQueryResult = ApolloReactCommon.QueryResult<GetSubconPaymentsListQuery, GetSubconPaymentsListQueryVariables>;
export const GetSupplierPaymentsListDocument = gql`
    query getSupplierPaymentsList($contractID: String!) {
  financialStatus(contractID: $contractID) {
    supplierPayment
    supplierPaymentSum
  }
}
    `;

/**
 * __useGetSupplierPaymentsListQuery__
 *
 * To run a query within a React component, call `useGetSupplierPaymentsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierPaymentsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierPaymentsListQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetSupplierPaymentsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierPaymentsListQuery, GetSupplierPaymentsListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierPaymentsListQuery, GetSupplierPaymentsListQueryVariables>(GetSupplierPaymentsListDocument, baseOptions);
      }
export function useGetSupplierPaymentsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierPaymentsListQuery, GetSupplierPaymentsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierPaymentsListQuery, GetSupplierPaymentsListQueryVariables>(GetSupplierPaymentsListDocument, baseOptions);
        }
export type GetSupplierPaymentsListQueryHookResult = ReturnType<typeof useGetSupplierPaymentsListQuery>;
export type GetSupplierPaymentsListLazyQueryHookResult = ReturnType<typeof useGetSupplierPaymentsListLazyQuery>;
export type GetSupplierPaymentsListQueryResult = ApolloReactCommon.QueryResult<GetSupplierPaymentsListQuery, GetSupplierPaymentsListQueryVariables>;
export const GetMiscExpensesListDocument = gql`
    query getMiscExpensesList($contractID: String!) {
  financialStatus(contractID: $contractID) {
    miscExpense
    miscExpSum
  }
}
    `;

/**
 * __useGetMiscExpensesListQuery__
 *
 * To run a query within a React component, call `useGetMiscExpensesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMiscExpensesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMiscExpensesListQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetMiscExpensesListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMiscExpensesListQuery, GetMiscExpensesListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMiscExpensesListQuery, GetMiscExpensesListQueryVariables>(GetMiscExpensesListDocument, baseOptions);
      }
export function useGetMiscExpensesListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMiscExpensesListQuery, GetMiscExpensesListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMiscExpensesListQuery, GetMiscExpensesListQueryVariables>(GetMiscExpensesListDocument, baseOptions);
        }
export type GetMiscExpensesListQueryHookResult = ReturnType<typeof useGetMiscExpensesListQuery>;
export type GetMiscExpensesListLazyQueryHookResult = ReturnType<typeof useGetMiscExpensesListLazyQuery>;
export type GetMiscExpensesListQueryResult = ApolloReactCommon.QueryResult<GetMiscExpensesListQuery, GetMiscExpensesListQueryVariables>;
export const GetClientOsListDocument = gql`
    query getClientOSList($contractID: String!) {
  outstandingClaims(contractID: $contractID) {
    docAmt
    baseAmt
    outstandingAmt
    docDate
    description
    docNo
    ID
  }
}
    `;

/**
 * __useGetClientOsListQuery__
 *
 * To run a query within a React component, call `useGetClientOsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientOsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientOsListQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetClientOsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClientOsListQuery, GetClientOsListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClientOsListQuery, GetClientOsListQueryVariables>(GetClientOsListDocument, baseOptions);
      }
export function useGetClientOsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClientOsListQuery, GetClientOsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClientOsListQuery, GetClientOsListQueryVariables>(GetClientOsListDocument, baseOptions);
        }
export type GetClientOsListQueryHookResult = ReturnType<typeof useGetClientOsListQuery>;
export type GetClientOsListLazyQueryHookResult = ReturnType<typeof useGetClientOsListLazyQuery>;
export type GetClientOsListQueryResult = ApolloReactCommon.QueryResult<GetClientOsListQuery, GetClientOsListQueryVariables>;
export const GetSubconOsListDocument = gql`
    query getSubconOSList($contractID: String!) {
  getSubcontractClaim(contractID: $contractID) {
    docAmt
    baseAmt
    outstandingAmt
    docDate
    description
    docNo
    ID
  }
}
    `;

/**
 * __useGetSubconOsListQuery__
 *
 * To run a query within a React component, call `useGetSubconOsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubconOsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubconOsListQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetSubconOsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubconOsListQuery, GetSubconOsListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubconOsListQuery, GetSubconOsListQueryVariables>(GetSubconOsListDocument, baseOptions);
      }
export function useGetSubconOsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubconOsListQuery, GetSubconOsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubconOsListQuery, GetSubconOsListQueryVariables>(GetSubconOsListDocument, baseOptions);
        }
export type GetSubconOsListQueryHookResult = ReturnType<typeof useGetSubconOsListQuery>;
export type GetSubconOsListLazyQueryHookResult = ReturnType<typeof useGetSubconOsListLazyQuery>;
export type GetSubconOsListQueryResult = ApolloReactCommon.QueryResult<GetSubconOsListQuery, GetSubconOsListQueryVariables>;
export const ProjectCostingDocument = gql`
    query projectCosting($contractID: String!) {
  associateCalculation(contractID: $contractID) {
    totalCost
    totalBalance
    revisedBudgetSubcontractSum
    subcontCostTotal
    materialCostTotal
    plantCostTotal
    miscCostTotal
    labourCostTotal
    subcontractBalance
    materialBalance
    plantBalance
    labourBalance
    miscBalance
    revisedBudgetMaterialSum
    revisedBudgetPlantSum
    revisedBudgetLabourSum
    revisedBudgetLabourSum
    revisedBudgetMiscSum
    revisedBudgetTotalSum
    revMiscItemBudget
    revPlantItemBudget
    revSubconItemBudget
    revLabourItemBudget
    revMaterialItemBudget
    originalBudgetMiscSum
    originalBudgetPlantSum
    originalBudgetLabourSum
    originalBudgetMaterialSum
    originalBudgetSubcontractSum
    originalBudgetTotalSum
  }
}
    `;

/**
 * __useProjectCostingQuery__
 *
 * To run a query within a React component, call `useProjectCostingQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectCostingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectCostingQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useProjectCostingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectCostingQuery, ProjectCostingQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectCostingQuery, ProjectCostingQueryVariables>(ProjectCostingDocument, baseOptions);
      }
export function useProjectCostingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectCostingQuery, ProjectCostingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectCostingQuery, ProjectCostingQueryVariables>(ProjectCostingDocument, baseOptions);
        }
export type ProjectCostingQueryHookResult = ReturnType<typeof useProjectCostingQuery>;
export type ProjectCostingLazyQueryHookResult = ReturnType<typeof useProjectCostingLazyQuery>;
export type ProjectCostingQueryResult = ApolloReactCommon.QueryResult<ProjectCostingQuery, ProjectCostingQueryVariables>;
export const ProjectBudgetAnalysisDocument = gql`
    query projectBudgetAnalysis($contractID: String!) {
  associateCalculation(contractID: $contractID) {
    totalCost
    totalBalance
    latestDate
    originalBudgetTotalSum
    budgetVarianceAmt
    revisedBudgetTotalSum
    revisedBudgetMiscSum
    revisedBudgetPlantSum
    revisedBudgetLabourSum
    revisedBudgetMaterialSum
    revisedBudgetSubcontractSum
    originalBudgetMiscSum
    originalBudgetPlantSum
    originalBudgetLabourSum
    originalBudgetMaterialSum
    originalBudgetSubcontractSum
    origSubconItemBudget
    origMiscItemBudget
    origPlantItemBudget
    origLabourItemBudget
    origMaterialItemBudget
    revMiscItemBudget
    revPlantItemBudget
    revSubconItemBudget
    revLabourItemBudget
    revMaterialItemBudget
    materialCostTotal
    plantCostTotal
    labourCostTotal
    miscCostTotal
    subcontCostTotal
  }
}
    `;

/**
 * __useProjectBudgetAnalysisQuery__
 *
 * To run a query within a React component, call `useProjectBudgetAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectBudgetAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectBudgetAnalysisQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useProjectBudgetAnalysisQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectBudgetAnalysisQuery, ProjectBudgetAnalysisQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectBudgetAnalysisQuery, ProjectBudgetAnalysisQueryVariables>(ProjectBudgetAnalysisDocument, baseOptions);
      }
export function useProjectBudgetAnalysisLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectBudgetAnalysisQuery, ProjectBudgetAnalysisQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectBudgetAnalysisQuery, ProjectBudgetAnalysisQueryVariables>(ProjectBudgetAnalysisDocument, baseOptions);
        }
export type ProjectBudgetAnalysisQueryHookResult = ReturnType<typeof useProjectBudgetAnalysisQuery>;
export type ProjectBudgetAnalysisLazyQueryHookResult = ReturnType<typeof useProjectBudgetAnalysisLazyQuery>;
export type ProjectBudgetAnalysisQueryResult = ApolloReactCommon.QueryResult<ProjectBudgetAnalysisQuery, ProjectBudgetAnalysisQueryVariables>;
export const BudgetVsActualDocument = gql`
    query budgetVSActual($contractID: String!) {
  associateCalculation(contractID: $contractID) {
    revisedBudgetTotalSum
    originalBudgetTotalSum
    totalCost
    totalBalance
    revisedBudgetMiscSum
    revisedBudgetPlantSum
    revisedBudgetLabourSum
    revisedBudgetMaterialSum
    revisedBudgetSubcontractSum
    subconCostToDate
    materialCostToDate
    plantCostToDate
    labourCostToDate
    miscCostToDate
    originalBudgetMiscSum
    originalBudgetPlantSum
    originalBudgetLabourSum
    originalBudgetMaterialSum
    originalBudgetSubcontractSum
    miscCommCost
    plantCommCost
    subconCommCost
    labourCommCost
    materialCommCost
    miscCostTotal
    plantCostTotal
    labourCostTotal
    subcontCostTotal
    materialCostTotal
    subconBalToSpend
    materialBalToSpend
    plantBalToSpend
    labourBalToSpend
    miscBalToSpend
    totalBalance
  }
}
    `;

/**
 * __useBudgetVsActualQuery__
 *
 * To run a query within a React component, call `useBudgetVsActualQuery` and pass it any options that fit your needs.
 * When your component renders, `useBudgetVsActualQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBudgetVsActualQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useBudgetVsActualQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BudgetVsActualQuery, BudgetVsActualQueryVariables>) {
        return ApolloReactHooks.useQuery<BudgetVsActualQuery, BudgetVsActualQueryVariables>(BudgetVsActualDocument, baseOptions);
      }
export function useBudgetVsActualLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BudgetVsActualQuery, BudgetVsActualQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BudgetVsActualQuery, BudgetVsActualQueryVariables>(BudgetVsActualDocument, baseOptions);
        }
export type BudgetVsActualQueryHookResult = ReturnType<typeof useBudgetVsActualQuery>;
export type BudgetVsActualLazyQueryHookResult = ReturnType<typeof useBudgetVsActualLazyQuery>;
export type BudgetVsActualQueryResult = ApolloReactCommon.QueryResult<BudgetVsActualQuery, BudgetVsActualQueryVariables>;
export const ProjectCostPerformanceDocument = gql`
    query projectCostPerformance($contractID: String!) {
  associateCalculation(contractID: $contractID) {
    cpi
    completionEst
    completionPerc
    costVariance
    earnedValue
    totalCost
    revisedBudgetTotalSum
    revisedBudgetSubcontractSum
  }
  contractVoCalculation(contractID: $contractID)
}
    `;

/**
 * __useProjectCostPerformanceQuery__
 *
 * To run a query within a React component, call `useProjectCostPerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectCostPerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectCostPerformanceQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useProjectCostPerformanceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectCostPerformanceQuery, ProjectCostPerformanceQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectCostPerformanceQuery, ProjectCostPerformanceQueryVariables>(ProjectCostPerformanceDocument, baseOptions);
      }
export function useProjectCostPerformanceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectCostPerformanceQuery, ProjectCostPerformanceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectCostPerformanceQuery, ProjectCostPerformanceQueryVariables>(ProjectCostPerformanceDocument, baseOptions);
        }
export type ProjectCostPerformanceQueryHookResult = ReturnType<typeof useProjectCostPerformanceQuery>;
export type ProjectCostPerformanceLazyQueryHookResult = ReturnType<typeof useProjectCostPerformanceLazyQuery>;
export type ProjectCostPerformanceQueryResult = ApolloReactCommon.QueryResult<ProjectCostPerformanceQuery, ProjectCostPerformanceQueryVariables>;
export const GetProjectPlSummaryDocument = gql`
    query getProjectPLSummary($contractID: String!) {
  projectPL(contractID: $contractID) {
    miscCostTotal
    labourCostTotal
    plantCostTotal
    materialCostTotal
    subconCostTotal
    progressIncome
    totalPL
    totalCost
    origContractSum
    revisedContractSum
    voSum
    materialInvListing
    subconClaimListing
  }
  getSubcontract(contractID: $contractID) {
    description
    subcontractSum
    subcontractNo
    createdTs
    ID
    subcontractorDetail {
      name
    }
  }
  financialStatus(contractID: $contractID) {
    paymentToDateSum
    collectionSum
  }
  associateCalculation(contractID: $contractID) {
    totalCost
    subcontCostTotal
    subcontractBalance
  }
  outstandingClaims(contractID: $contractID) {
    outstandingAmt
    docDate
    description
    docNo
  }
}
    `;

/**
 * __useGetProjectPlSummaryQuery__
 *
 * To run a query within a React component, call `useGetProjectPlSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectPlSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectPlSummaryQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetProjectPlSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectPlSummaryQuery, GetProjectPlSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectPlSummaryQuery, GetProjectPlSummaryQueryVariables>(GetProjectPlSummaryDocument, baseOptions);
      }
export function useGetProjectPlSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectPlSummaryQuery, GetProjectPlSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectPlSummaryQuery, GetProjectPlSummaryQueryVariables>(GetProjectPlSummaryDocument, baseOptions);
        }
export type GetProjectPlSummaryQueryHookResult = ReturnType<typeof useGetProjectPlSummaryQuery>;
export type GetProjectPlSummaryLazyQueryHookResult = ReturnType<typeof useGetProjectPlSummaryLazyQuery>;
export type GetProjectPlSummaryQueryResult = ApolloReactCommon.QueryResult<GetProjectPlSummaryQuery, GetProjectPlSummaryQueryVariables>;
export const GetProjectFinancialStatusDocument = gql`
    query getProjectFinancialStatus($contractID: String!) {
  financialStatus(contractID: $contractID) {
    paymentToDateSum
    billToDateSum
    latestBillingDate
    latestMiscExpDate
    collectionSum
    latestCollectionDate
    latestSupplierInvDate
    latestSubconPaymentDate
    costToDateSum
    subconBillingSum
    supplierInvSum
    miscExpSum
    subconPaymentSum
    supplierPaymentSum
    latestSubconPaymentDate
    latestSupplierPaymentDate
  }
  associateCalculation(contractID: $contractID) {
    revisedBudgetTotalSum
  }
  projectPL(contractID: $contractID) {
    revisedContractSum
  }
}
    `;

/**
 * __useGetProjectFinancialStatusQuery__
 *
 * To run a query within a React component, call `useGetProjectFinancialStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectFinancialStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectFinancialStatusQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetProjectFinancialStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectFinancialStatusQuery, GetProjectFinancialStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectFinancialStatusQuery, GetProjectFinancialStatusQueryVariables>(GetProjectFinancialStatusDocument, baseOptions);
      }
export function useGetProjectFinancialStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectFinancialStatusQuery, GetProjectFinancialStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectFinancialStatusQuery, GetProjectFinancialStatusQueryVariables>(GetProjectFinancialStatusDocument, baseOptions);
        }
export type GetProjectFinancialStatusQueryHookResult = ReturnType<typeof useGetProjectFinancialStatusQuery>;
export type GetProjectFinancialStatusLazyQueryHookResult = ReturnType<typeof useGetProjectFinancialStatusLazyQuery>;
export type GetProjectFinancialStatusQueryResult = ApolloReactCommon.QueryResult<GetProjectFinancialStatusQuery, GetProjectFinancialStatusQueryVariables>;
export const GetProjectFinancialStatusListDocument = gql`
    query getProjectFinancialStatusList($contractID: String!) {
  financialStatus(contractID: $contractID) {
    conBilling
    collectionListing
    subconBilling
    supplierInvoice
    miscExpense
    subconPayment
    supplierPayment
  }
}
    `;

/**
 * __useGetProjectFinancialStatusListQuery__
 *
 * To run a query within a React component, call `useGetProjectFinancialStatusListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectFinancialStatusListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectFinancialStatusListQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetProjectFinancialStatusListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectFinancialStatusListQuery, GetProjectFinancialStatusListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectFinancialStatusListQuery, GetProjectFinancialStatusListQueryVariables>(GetProjectFinancialStatusListDocument, baseOptions);
      }
export function useGetProjectFinancialStatusListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectFinancialStatusListQuery, GetProjectFinancialStatusListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectFinancialStatusListQuery, GetProjectFinancialStatusListQueryVariables>(GetProjectFinancialStatusListDocument, baseOptions);
        }
export type GetProjectFinancialStatusListQueryHookResult = ReturnType<typeof useGetProjectFinancialStatusListQuery>;
export type GetProjectFinancialStatusListLazyQueryHookResult = ReturnType<typeof useGetProjectFinancialStatusListLazyQuery>;
export type GetProjectFinancialStatusListQueryResult = ApolloReactCommon.QueryResult<GetProjectFinancialStatusListQuery, GetProjectFinancialStatusListQueryVariables>;
export const GetClientVoDocument = gql`
    query getClientVo($contractID: String!, $voStatus: VOStatus!) {
  getContractVO(contractID: $contractID, VOStatus: $voStatus) {
    approvedAmt
    submissionAmt
    VOType
    docNo
    docRef
    docDate
    description
  }
}
    `;

/**
 * __useGetClientVoQuery__
 *
 * To run a query within a React component, call `useGetClientVoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientVoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientVoQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      voStatus: // value for 'voStatus'
 *   },
 * });
 */
export function useGetClientVoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClientVoQuery, GetClientVoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClientVoQuery, GetClientVoQueryVariables>(GetClientVoDocument, baseOptions);
      }
export function useGetClientVoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClientVoQuery, GetClientVoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClientVoQuery, GetClientVoQueryVariables>(GetClientVoDocument, baseOptions);
        }
export type GetClientVoQueryHookResult = ReturnType<typeof useGetClientVoQuery>;
export type GetClientVoLazyQueryHookResult = ReturnType<typeof useGetClientVoLazyQuery>;
export type GetClientVoQueryResult = ApolloReactCommon.QueryResult<GetClientVoQuery, GetClientVoQueryVariables>;
export const GetSubconVoDocument = gql`
    query getSubconVo($subcontractID: String!, $voStatus: VOStatus!) {
  getSubcontractVO(subcontractID: $subcontractID, VOStatus: $voStatus) {
    approvedAmt
    submissionAmt
    VOType
    docNo
    docRef
    docDate
    description
  }
}
    `;

/**
 * __useGetSubconVoQuery__
 *
 * To run a query within a React component, call `useGetSubconVoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubconVoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubconVoQuery({
 *   variables: {
 *      subcontractID: // value for 'subcontractID'
 *      voStatus: // value for 'voStatus'
 *   },
 * });
 */
export function useGetSubconVoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubconVoQuery, GetSubconVoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubconVoQuery, GetSubconVoQueryVariables>(GetSubconVoDocument, baseOptions);
      }
export function useGetSubconVoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubconVoQuery, GetSubconVoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubconVoQuery, GetSubconVoQueryVariables>(GetSubconVoDocument, baseOptions);
        }
export type GetSubconVoQueryHookResult = ReturnType<typeof useGetSubconVoQuery>;
export type GetSubconVoLazyQueryHookResult = ReturnType<typeof useGetSubconVoLazyQuery>;
export type GetSubconVoQueryResult = ApolloReactCommon.QueryResult<GetSubconVoQuery, GetSubconVoQueryVariables>;
export const GetProjectCostingBudgetDocument = gql`
    query getProjectCostingBudget($ID: String!, $costClass: CostClass!, $costCategoryID: String) {
  getContract(ID: $ID) {
    title
    costCateg(costClass: $costClass, costCategoryID: $costCategoryID) {
      ID
      costClass
      name
      budget(contractID: $ID) {
        ID
        createdTs
        budgetAmt
      }
    }
  }
  associateCalculation(contractID: $ID) {
    revisedBudgetSubcontractSum
    subcontractBalance
    subcontCostTotal
    revisedBudgetMaterialSum
    materialBalance
    materialCostTotal
    revisedBudgetLabourSum
    labourBalance
    labourCostTotal
    revisedBudgetMiscSum
    miscBalance
    miscCostTotal
    revisedBudgetPlantSum
    plantBalance
    plantCostTotal
    originalBudgetMiscSum
    originalBudgetPlantSum
    originalBudgetLabourSum
    originalBudgetMaterialSum
    originalBudgetSubcontractSum
  }
}
    `;

/**
 * __useGetProjectCostingBudgetQuery__
 *
 * To run a query within a React component, call `useGetProjectCostingBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectCostingBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectCostingBudgetQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      costClass: // value for 'costClass'
 *      costCategoryID: // value for 'costCategoryID'
 *   },
 * });
 */
export function useGetProjectCostingBudgetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectCostingBudgetQuery, GetProjectCostingBudgetQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectCostingBudgetQuery, GetProjectCostingBudgetQueryVariables>(GetProjectCostingBudgetDocument, baseOptions);
      }
export function useGetProjectCostingBudgetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectCostingBudgetQuery, GetProjectCostingBudgetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectCostingBudgetQuery, GetProjectCostingBudgetQueryVariables>(GetProjectCostingBudgetDocument, baseOptions);
        }
export type GetProjectCostingBudgetQueryHookResult = ReturnType<typeof useGetProjectCostingBudgetQuery>;
export type GetProjectCostingBudgetLazyQueryHookResult = ReturnType<typeof useGetProjectCostingBudgetLazyQuery>;
export type GetProjectCostingBudgetQueryResult = ApolloReactCommon.QueryResult<GetProjectCostingBudgetQuery, GetProjectCostingBudgetQueryVariables>;
export const GetBizMenuSummaryDocument = gql`
    query getBizMenuSummary($contractID: String!) {
  budgetCalculation(contractID: $contractID)
  associateCalculation(contractID: $contractID) {
    revisedBudgetTotalSum
    originalBudgetTotalSum
    totalBalance
    totalCost
    earnedValue
    costVariance
  }
  financialStatus(contractID: $contractID) {
    paymentToDateSum
    billToDateSum
    costToDateSum
  }
  projectPL(contractID: $contractID) {
    totalPL
  }
  receivedFromClient(contractID: $contractID)
  paidToSubCon(contractID: $contractID)
  paidToSupplier(contractID: $contractID)
  miscExpense(contractID: $contractID)
}
    `;

/**
 * __useGetBizMenuSummaryQuery__
 *
 * To run a query within a React component, call `useGetBizMenuSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBizMenuSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBizMenuSummaryQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetBizMenuSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBizMenuSummaryQuery, GetBizMenuSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBizMenuSummaryQuery, GetBizMenuSummaryQueryVariables>(GetBizMenuSummaryDocument, baseOptions);
      }
export function useGetBizMenuSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBizMenuSummaryQuery, GetBizMenuSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBizMenuSummaryQuery, GetBizMenuSummaryQueryVariables>(GetBizMenuSummaryDocument, baseOptions);
        }
export type GetBizMenuSummaryQueryHookResult = ReturnType<typeof useGetBizMenuSummaryQuery>;
export type GetBizMenuSummaryLazyQueryHookResult = ReturnType<typeof useGetBizMenuSummaryLazyQuery>;
export type GetBizMenuSummaryQueryResult = ApolloReactCommon.QueryResult<GetBizMenuSummaryQuery, GetBizMenuSummaryQueryVariables>;
export const SetCompletionPercDocument = gql`
    mutation setCompletionPerc($contractID: String!, $input: completionInput!) {
  completionPerc(contractID: $contractID, input: $input)
}
    `;
export type SetCompletionPercMutationFn = ApolloReactCommon.MutationFunction<SetCompletionPercMutation, SetCompletionPercMutationVariables>;

/**
 * __useSetCompletionPercMutation__
 *
 * To run a mutation, you first call `useSetCompletionPercMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCompletionPercMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCompletionPercMutation, { data, loading, error }] = useSetCompletionPercMutation({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetCompletionPercMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetCompletionPercMutation, SetCompletionPercMutationVariables>) {
        return ApolloReactHooks.useMutation<SetCompletionPercMutation, SetCompletionPercMutationVariables>(SetCompletionPercDocument, baseOptions);
      }
export type SetCompletionPercMutationHookResult = ReturnType<typeof useSetCompletionPercMutation>;
export type SetCompletionPercMutationResult = ApolloReactCommon.MutationResult<SetCompletionPercMutation>;
export type SetCompletionPercMutationOptions = ApolloReactCommon.BaseMutationOptions<SetCompletionPercMutation, SetCompletionPercMutationVariables>;
export const DeleteBudgetCheckingDocument = gql`
    query deleteBudgetChecking($contractID: String!, $costCategoryIDs: [String!]!) {
  deleteBudgetChecking(contractID: $contractID, costCategoryIDs: $costCategoryIDs)
}
    `;

/**
 * __useDeleteBudgetCheckingQuery__
 *
 * To run a query within a React component, call `useDeleteBudgetCheckingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeleteBudgetCheckingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeleteBudgetCheckingQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      costCategoryIDs: // value for 'costCategoryIDs'
 *   },
 * });
 */
export function useDeleteBudgetCheckingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DeleteBudgetCheckingQuery, DeleteBudgetCheckingQueryVariables>) {
        return ApolloReactHooks.useQuery<DeleteBudgetCheckingQuery, DeleteBudgetCheckingQueryVariables>(DeleteBudgetCheckingDocument, baseOptions);
      }
export function useDeleteBudgetCheckingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DeleteBudgetCheckingQuery, DeleteBudgetCheckingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DeleteBudgetCheckingQuery, DeleteBudgetCheckingQueryVariables>(DeleteBudgetCheckingDocument, baseOptions);
        }
export type DeleteBudgetCheckingQueryHookResult = ReturnType<typeof useDeleteBudgetCheckingQuery>;
export type DeleteBudgetCheckingLazyQueryHookResult = ReturnType<typeof useDeleteBudgetCheckingLazyQuery>;
export type DeleteBudgetCheckingQueryResult = ApolloReactCommon.QueryResult<DeleteBudgetCheckingQuery, DeleteBudgetCheckingQueryVariables>;
export const DeleteProjectBudgetDocument = gql`
    mutation deleteProjectBudget($input: ProjectBudgetDeleteInput!) {
  deleteProjectBudget(input: $input)
}
    `;
export type DeleteProjectBudgetMutationFn = ApolloReactCommon.MutationFunction<DeleteProjectBudgetMutation, DeleteProjectBudgetMutationVariables>;

/**
 * __useDeleteProjectBudgetMutation__
 *
 * To run a mutation, you first call `useDeleteProjectBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectBudgetMutation, { data, loading, error }] = useDeleteProjectBudgetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProjectBudgetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteProjectBudgetMutation, DeleteProjectBudgetMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteProjectBudgetMutation, DeleteProjectBudgetMutationVariables>(DeleteProjectBudgetDocument, baseOptions);
      }
export type DeleteProjectBudgetMutationHookResult = ReturnType<typeof useDeleteProjectBudgetMutation>;
export type DeleteProjectBudgetMutationResult = ApolloReactCommon.MutationResult<DeleteProjectBudgetMutation>;
export type DeleteProjectBudgetMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteProjectBudgetMutation, DeleteProjectBudgetMutationVariables>;
export const GetContractDocument = gql`
    query getContract($ID: String, $customerID: String, $orderByAsc: String, $orderByDesc: String, $contractStatus: ContractStatus) {
  getContract(ID: $ID, customerID: $customerID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, contractStatus: $contractStatus) {
    ID
    title
    accountID
    contractNo
    customerID
    description
    site {
      name
      contractID
      address
      ID
    }
    contractDate
    contractSum
    voSum
    revisedContractSum
    taxSchemeID
    startDate
    endDate
    progressRetentionPerc
    maxRetentionPerc
    targetProfitPerc
    cmgdDate
    cpcDate
    dlpDate
    ladAmt
    ladRate
    creditTerm
    latestRunNum
    docNumChecking
    contractPOCounts
    contractStatus
    customerDetail {
      name
      contactPerson {
        ID
        name
        designation
        email
        contactNo
      }
      address
      contactNo
    }
    subcontractTenderDetail {
      ID
      title
    }
    subcontractSummary
    subcontractDetail {
      ID
      title
      accountID
      contractID
      subcontractorID
      costCategoryID
      subcontractNo
      description
      subcontractDate
      taxSchemeID
      subcontractSum
      revisedSubcontractSum
      startDate
      endDate
      progressRetentionPerc
      maxRetentionPerc
      targetProfitPerc
      creditTerm
      subcontractorDetail {
        ID
        name
        associatedStatus
      }
      subcontractStatus
    }
    subcontractSummary
    contactPerson
    closureRemarks
    closureDate
    modBy
    extensionDate
    taxDetail {
      ID
      code
      description
      taxCategory
      taxTypeID
      taxClass
      createdTs
      commonStatus
      latestTax {
        taxRate
        taxDate
      }
      taxEffective {
        date
        taxRate
        createdTs
      }
    }
  }
}
    `;

/**
 * __useGetContractQuery__
 *
 * To run a query within a React component, call `useGetContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      customerID: // value for 'customerID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      contractStatus: // value for 'contractStatus'
 *   },
 * });
 */
export function useGetContractQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractQuery, GetContractQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractQuery, GetContractQueryVariables>(GetContractDocument, baseOptions);
      }
export function useGetContractLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractQuery, GetContractQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractQuery, GetContractQueryVariables>(GetContractDocument, baseOptions);
        }
export type GetContractQueryHookResult = ReturnType<typeof useGetContractQuery>;
export type GetContractLazyQueryHookResult = ReturnType<typeof useGetContractLazyQuery>;
export type GetContractQueryResult = ApolloReactCommon.QueryResult<GetContractQuery, GetContractQueryVariables>;
export const GetContractListingDocument = gql`
    query getContractListing($customerID: String, $orderByAsc: String, $orderByDesc: String, $contractStatus: ContractStatus) {
  getContract(customerID: $customerID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, contractStatus: $contractStatus) {
    ID
    accountID
    customerID
    title
    contractNo
    description
    contractDate
    contractSum
    voSum
    revisedContractSum
    startDate
    endDate
    contractStatus
    customerDetail {
      name
      contactPerson {
        ID
        name
        designation
        email
        contactNo
      }
      address
      contactNo
    }
  }
}
    `;

/**
 * __useGetContractListingQuery__
 *
 * To run a query within a React component, call `useGetContractListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractListingQuery({
 *   variables: {
 *      customerID: // value for 'customerID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      contractStatus: // value for 'contractStatus'
 *   },
 * });
 */
export function useGetContractListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractListingQuery, GetContractListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractListingQuery, GetContractListingQueryVariables>(GetContractListingDocument, baseOptions);
      }
export function useGetContractListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractListingQuery, GetContractListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractListingQuery, GetContractListingQueryVariables>(GetContractListingDocument, baseOptions);
        }
export type GetContractListingQueryHookResult = ReturnType<typeof useGetContractListingQuery>;
export type GetContractListingLazyQueryHookResult = ReturnType<typeof useGetContractListingLazyQuery>;
export type GetContractListingQueryResult = ApolloReactCommon.QueryResult<GetContractListingQuery, GetContractListingQueryVariables>;
export const GetContractSubmenuDocument = gql`
    query getContractSubmenu($ID: String) {
  getContract(ID: $ID) {
    ID
    title
    accountID
    contractNo
    customerID
    contractStatus
    cmgdDate
    cpcDate
    dlpDate
    contractSum
    revisedContractSum
  }
  getAllContract: getContract {
    ID
    title
    accountID
    contractNo
    customerID
  }
}
    `;

/**
 * __useGetContractSubmenuQuery__
 *
 * To run a query within a React component, call `useGetContractSubmenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractSubmenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractSubmenuQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetContractSubmenuQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractSubmenuQuery, GetContractSubmenuQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractSubmenuQuery, GetContractSubmenuQueryVariables>(GetContractSubmenuDocument, baseOptions);
      }
export function useGetContractSubmenuLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractSubmenuQuery, GetContractSubmenuQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractSubmenuQuery, GetContractSubmenuQueryVariables>(GetContractSubmenuDocument, baseOptions);
        }
export type GetContractSubmenuQueryHookResult = ReturnType<typeof useGetContractSubmenuQuery>;
export type GetContractSubmenuLazyQueryHookResult = ReturnType<typeof useGetContractSubmenuLazyQuery>;
export type GetContractSubmenuQueryResult = ApolloReactCommon.QueryResult<GetContractSubmenuQuery, GetContractSubmenuQueryVariables>;
export const CreateContractDocument = gql`
    mutation createContract($input: ContractInput!, $siteInput: [SiteInput!]!) {
  createContract(input: $input, siteInput: $siteInput) {
    contractNo
    title
    description
    contractSum
    taxSchemeID
    startDate
    endDate
    progressRetentionPerc
    maxRetentionPerc
    cmgdDate
    cpcDate
    creditTerm
    customerID
    accountID
    contractDate
  }
}
    `;
export type CreateContractMutationFn = ApolloReactCommon.MutationFunction<CreateContractMutation, CreateContractMutationVariables>;

/**
 * __useCreateContractMutation__
 *
 * To run a mutation, you first call `useCreateContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractMutation, { data, loading, error }] = useCreateContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *      siteInput: // value for 'siteInput'
 *   },
 * });
 */
export function useCreateContractMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContractMutation, CreateContractMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContractMutation, CreateContractMutationVariables>(CreateContractDocument, baseOptions);
      }
export type CreateContractMutationHookResult = ReturnType<typeof useCreateContractMutation>;
export type CreateContractMutationResult = ApolloReactCommon.MutationResult<CreateContractMutation>;
export type CreateContractMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateContractMutation, CreateContractMutationVariables>;
export const UpdateContractDocument = gql`
    mutation updateContract($input: ContractInput!) {
  updateContract(input: $input)
}
    `;
export type UpdateContractMutationFn = ApolloReactCommon.MutationFunction<UpdateContractMutation, UpdateContractMutationVariables>;

/**
 * __useUpdateContractMutation__
 *
 * To run a mutation, you first call `useUpdateContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractMutation, { data, loading, error }] = useUpdateContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContractMutation, UpdateContractMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateContractMutation, UpdateContractMutationVariables>(UpdateContractDocument, baseOptions);
      }
export type UpdateContractMutationHookResult = ReturnType<typeof useUpdateContractMutation>;
export type UpdateContractMutationResult = ApolloReactCommon.MutationResult<UpdateContractMutation>;
export type UpdateContractMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateContractMutation, UpdateContractMutationVariables>;
export const GetContractGuaranteeDocument = gql`
    query getContractGuarantee($ID: String, $contractID: String!, $orderByAsc: String) {
  getContractGuarantee(ID: $ID, contractID: $contractID, orderByAsc: $orderByAsc) {
    ID
    modBy
    accountID
    contractID
    docRef
    issuer
    guaranteeTypeID
    description
    docAmt
    startDate
    expiryDate
    collateralAmt
    remarks
    cancellationDate
    guaranteeStatus
    guaranteeType {
      name
    }
  }
  contractGuarantee(contractID: $contractID)
}
    `;

/**
 * __useGetContractGuaranteeQuery__
 *
 * To run a query within a React component, call `useGetContractGuaranteeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractGuaranteeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractGuaranteeQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetContractGuaranteeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractGuaranteeQuery, GetContractGuaranteeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractGuaranteeQuery, GetContractGuaranteeQueryVariables>(GetContractGuaranteeDocument, baseOptions);
      }
export function useGetContractGuaranteeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractGuaranteeQuery, GetContractGuaranteeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractGuaranteeQuery, GetContractGuaranteeQueryVariables>(GetContractGuaranteeDocument, baseOptions);
        }
export type GetContractGuaranteeQueryHookResult = ReturnType<typeof useGetContractGuaranteeQuery>;
export type GetContractGuaranteeLazyQueryHookResult = ReturnType<typeof useGetContractGuaranteeLazyQuery>;
export type GetContractGuaranteeQueryResult = ApolloReactCommon.QueryResult<GetContractGuaranteeQuery, GetContractGuaranteeQueryVariables>;
export const CreateContractGuaranteeDocument = gql`
    mutation createContractGuarantee($input: ContractGuaranteeInput!) {
  createContractGuarantee(input: $input) {
    ID
  }
}
    `;
export type CreateContractGuaranteeMutationFn = ApolloReactCommon.MutationFunction<CreateContractGuaranteeMutation, CreateContractGuaranteeMutationVariables>;

/**
 * __useCreateContractGuaranteeMutation__
 *
 * To run a mutation, you first call `useCreateContractGuaranteeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractGuaranteeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractGuaranteeMutation, { data, loading, error }] = useCreateContractGuaranteeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContractGuaranteeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContractGuaranteeMutation, CreateContractGuaranteeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContractGuaranteeMutation, CreateContractGuaranteeMutationVariables>(CreateContractGuaranteeDocument, baseOptions);
      }
export type CreateContractGuaranteeMutationHookResult = ReturnType<typeof useCreateContractGuaranteeMutation>;
export type CreateContractGuaranteeMutationResult = ApolloReactCommon.MutationResult<CreateContractGuaranteeMutation>;
export type CreateContractGuaranteeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateContractGuaranteeMutation, CreateContractGuaranteeMutationVariables>;
export const UpdateContractGuaranteeDocument = gql`
    mutation updateContractGuarantee($input: ContractGuaranteeInput!) {
  updateContractGuarantee(input: $input) {
    ID
  }
}
    `;
export type UpdateContractGuaranteeMutationFn = ApolloReactCommon.MutationFunction<UpdateContractGuaranteeMutation, UpdateContractGuaranteeMutationVariables>;

/**
 * __useUpdateContractGuaranteeMutation__
 *
 * To run a mutation, you first call `useUpdateContractGuaranteeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractGuaranteeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractGuaranteeMutation, { data, loading, error }] = useUpdateContractGuaranteeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractGuaranteeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContractGuaranteeMutation, UpdateContractGuaranteeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateContractGuaranteeMutation, UpdateContractGuaranteeMutationVariables>(UpdateContractGuaranteeDocument, baseOptions);
      }
export type UpdateContractGuaranteeMutationHookResult = ReturnType<typeof useUpdateContractGuaranteeMutation>;
export type UpdateContractGuaranteeMutationResult = ApolloReactCommon.MutationResult<UpdateContractGuaranteeMutation>;
export type UpdateContractGuaranteeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateContractGuaranteeMutation, UpdateContractGuaranteeMutationVariables>;
export const GetContractInsuranceDocument = gql`
    query getContractInsurance($ID: String, $contractID: String!) {
  getContractInsurance(ID: $ID, contractID: $contractID) {
    ID
    modBy
    accountID
    contractID
    docRef
    issuer
    insuranceTypeID
    description
    docAmt
    startDate
    expiryDate
    remarks
    cancellationDate
    insuranceStatus
    insuranceType {
      name
    }
  }
  contractInsurance(contractID: $contractID)
}
    `;

/**
 * __useGetContractInsuranceQuery__
 *
 * To run a query within a React component, call `useGetContractInsuranceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractInsuranceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractInsuranceQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetContractInsuranceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractInsuranceQuery, GetContractInsuranceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractInsuranceQuery, GetContractInsuranceQueryVariables>(GetContractInsuranceDocument, baseOptions);
      }
export function useGetContractInsuranceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractInsuranceQuery, GetContractInsuranceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractInsuranceQuery, GetContractInsuranceQueryVariables>(GetContractInsuranceDocument, baseOptions);
        }
export type GetContractInsuranceQueryHookResult = ReturnType<typeof useGetContractInsuranceQuery>;
export type GetContractInsuranceLazyQueryHookResult = ReturnType<typeof useGetContractInsuranceLazyQuery>;
export type GetContractInsuranceQueryResult = ApolloReactCommon.QueryResult<GetContractInsuranceQuery, GetContractInsuranceQueryVariables>;
export const CreateContractInsuranceDocument = gql`
    mutation createContractInsurance($input: ContractInsuranceInput!) {
  createContractInsurance(input: $input) {
    ID
  }
}
    `;
export type CreateContractInsuranceMutationFn = ApolloReactCommon.MutationFunction<CreateContractInsuranceMutation, CreateContractInsuranceMutationVariables>;

/**
 * __useCreateContractInsuranceMutation__
 *
 * To run a mutation, you first call `useCreateContractInsuranceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractInsuranceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractInsuranceMutation, { data, loading, error }] = useCreateContractInsuranceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContractInsuranceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContractInsuranceMutation, CreateContractInsuranceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContractInsuranceMutation, CreateContractInsuranceMutationVariables>(CreateContractInsuranceDocument, baseOptions);
      }
export type CreateContractInsuranceMutationHookResult = ReturnType<typeof useCreateContractInsuranceMutation>;
export type CreateContractInsuranceMutationResult = ApolloReactCommon.MutationResult<CreateContractInsuranceMutation>;
export type CreateContractInsuranceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateContractInsuranceMutation, CreateContractInsuranceMutationVariables>;
export const UpdateContractInsuranceDocument = gql`
    mutation updateContractInsurance($input: ContractInsuranceInput!) {
  updateContractInsurance(input: $input) {
    ID
  }
}
    `;
export type UpdateContractInsuranceMutationFn = ApolloReactCommon.MutationFunction<UpdateContractInsuranceMutation, UpdateContractInsuranceMutationVariables>;

/**
 * __useUpdateContractInsuranceMutation__
 *
 * To run a mutation, you first call `useUpdateContractInsuranceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractInsuranceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractInsuranceMutation, { data, loading, error }] = useUpdateContractInsuranceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractInsuranceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContractInsuranceMutation, UpdateContractInsuranceMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateContractInsuranceMutation, UpdateContractInsuranceMutationVariables>(UpdateContractInsuranceDocument, baseOptions);
      }
export type UpdateContractInsuranceMutationHookResult = ReturnType<typeof useUpdateContractInsuranceMutation>;
export type UpdateContractInsuranceMutationResult = ApolloReactCommon.MutationResult<UpdateContractInsuranceMutation>;
export type UpdateContractInsuranceMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateContractInsuranceMutation, UpdateContractInsuranceMutationVariables>;
export const ActionOnContractStatusDocument = gql`
    mutation actionOnContractStatus($input: ContractActionInput!, $ID: String!) {
  actionOnContractStatus(input: $input, ID: $ID)
}
    `;
export type ActionOnContractStatusMutationFn = ApolloReactCommon.MutationFunction<ActionOnContractStatusMutation, ActionOnContractStatusMutationVariables>;

/**
 * __useActionOnContractStatusMutation__
 *
 * To run a mutation, you first call `useActionOnContractStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionOnContractStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionOnContractStatusMutation, { data, loading, error }] = useActionOnContractStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useActionOnContractStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActionOnContractStatusMutation, ActionOnContractStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<ActionOnContractStatusMutation, ActionOnContractStatusMutationVariables>(ActionOnContractStatusDocument, baseOptions);
      }
export type ActionOnContractStatusMutationHookResult = ReturnType<typeof useActionOnContractStatusMutation>;
export type ActionOnContractStatusMutationResult = ApolloReactCommon.MutationResult<ActionOnContractStatusMutation>;
export type ActionOnContractStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<ActionOnContractStatusMutation, ActionOnContractStatusMutationVariables>;
export const CreateContractExtensionDateDocument = gql`
    mutation createContractExtensionDate($input: ContractExtensionInput!, $ID: String!) {
  createContractExtensionDate(input: $input, ID: $ID)
}
    `;
export type CreateContractExtensionDateMutationFn = ApolloReactCommon.MutationFunction<CreateContractExtensionDateMutation, CreateContractExtensionDateMutationVariables>;

/**
 * __useCreateContractExtensionDateMutation__
 *
 * To run a mutation, you first call `useCreateContractExtensionDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractExtensionDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractExtensionDateMutation, { data, loading, error }] = useCreateContractExtensionDateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useCreateContractExtensionDateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContractExtensionDateMutation, CreateContractExtensionDateMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContractExtensionDateMutation, CreateContractExtensionDateMutationVariables>(CreateContractExtensionDateDocument, baseOptions);
      }
export type CreateContractExtensionDateMutationHookResult = ReturnType<typeof useCreateContractExtensionDateMutation>;
export type CreateContractExtensionDateMutationResult = ApolloReactCommon.MutationResult<CreateContractExtensionDateMutation>;
export type CreateContractExtensionDateMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateContractExtensionDateMutation, CreateContractExtensionDateMutationVariables>;
export const CancelContractGuaranteeDocument = gql`
    mutation cancelContractGuarantee($input: ContractGuaranteeCancellationInput!) {
  cancelContractGuarantee(input: $input)
}
    `;
export type CancelContractGuaranteeMutationFn = ApolloReactCommon.MutationFunction<CancelContractGuaranteeMutation, CancelContractGuaranteeMutationVariables>;

/**
 * __useCancelContractGuaranteeMutation__
 *
 * To run a mutation, you first call `useCancelContractGuaranteeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelContractGuaranteeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelContractGuaranteeMutation, { data, loading, error }] = useCancelContractGuaranteeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelContractGuaranteeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelContractGuaranteeMutation, CancelContractGuaranteeMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelContractGuaranteeMutation, CancelContractGuaranteeMutationVariables>(CancelContractGuaranteeDocument, baseOptions);
      }
export type CancelContractGuaranteeMutationHookResult = ReturnType<typeof useCancelContractGuaranteeMutation>;
export type CancelContractGuaranteeMutationResult = ApolloReactCommon.MutationResult<CancelContractGuaranteeMutation>;
export type CancelContractGuaranteeMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelContractGuaranteeMutation, CancelContractGuaranteeMutationVariables>;
export const CancelContractInsuranceDocument = gql`
    mutation cancelContractInsurance($input: ContractInsuranceCancellationInput!) {
  cancelContractInsurance(input: $input)
}
    `;
export type CancelContractInsuranceMutationFn = ApolloReactCommon.MutationFunction<CancelContractInsuranceMutation, CancelContractInsuranceMutationVariables>;

/**
 * __useCancelContractInsuranceMutation__
 *
 * To run a mutation, you first call `useCancelContractInsuranceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelContractInsuranceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelContractInsuranceMutation, { data, loading, error }] = useCancelContractInsuranceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelContractInsuranceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelContractInsuranceMutation, CancelContractInsuranceMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelContractInsuranceMutation, CancelContractInsuranceMutationVariables>(CancelContractInsuranceDocument, baseOptions);
      }
export type CancelContractInsuranceMutationHookResult = ReturnType<typeof useCancelContractInsuranceMutation>;
export type CancelContractInsuranceMutationResult = ApolloReactCommon.MutationResult<CancelContractInsuranceMutation>;
export type CancelContractInsuranceMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelContractInsuranceMutation, CancelContractInsuranceMutationVariables>;
export const GetContractSummaryDocument = gql`
    query getContractSummary($contractID: String!, $type: String) {
  contractInfo(contractID: $contractID) {
    contractSum
    contractDate
  }
  contractGuarantee(contractID: $contractID)
  contractInsurance(contractID: $contractID)
  contractVO(contractID: $contractID) {
    voSum
    voOmission
    voSumCount
    voOmissionCount
    voClientCount
    voSubconCount
    totalVoAmt
  }
  outstandingAdvances(contractID: $contractID) {
    docNo
    docDate
    docAmt
    baseAmt
    outstandingAmt
  }
  outstandingClaims(contractID: $contractID) {
    docNo
    docDate
    docAmt
    baseAmt
    outstandingAmt
  }
  submittedVONotification(contractID: $contractID)
  lastExtensionDate(contractID: $contractID)
  contractClosureDate(contractID: $contractID)
  retention(contractID: $contractID) {
    retentionSum
    retentionBalance
    releasedSum
  }
  contractVoCalculation(contractID: $contractID)
  totalDigitalDocuments(associateID: $contractID, type: $type)
}
    `;

/**
 * __useGetContractSummaryQuery__
 *
 * To run a query within a React component, call `useGetContractSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractSummaryQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetContractSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractSummaryQuery, GetContractSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractSummaryQuery, GetContractSummaryQueryVariables>(GetContractSummaryDocument, baseOptions);
      }
export function useGetContractSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractSummaryQuery, GetContractSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractSummaryQuery, GetContractSummaryQueryVariables>(GetContractSummaryDocument, baseOptions);
        }
export type GetContractSummaryQueryHookResult = ReturnType<typeof useGetContractSummaryQuery>;
export type GetContractSummaryLazyQueryHookResult = ReturnType<typeof useGetContractSummaryLazyQuery>;
export type GetContractSummaryQueryResult = ApolloReactCommon.QueryResult<GetContractSummaryQuery, GetContractSummaryQueryVariables>;
export const CreateContractCertificateDocument = gql`
    mutation createContractCertificate($input: ContractCertificateInput!, $ID: String!) {
  createContractCertificate(input: $input, ID: $ID)
}
    `;
export type CreateContractCertificateMutationFn = ApolloReactCommon.MutationFunction<CreateContractCertificateMutation, CreateContractCertificateMutationVariables>;

/**
 * __useCreateContractCertificateMutation__
 *
 * To run a mutation, you first call `useCreateContractCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractCertificateMutation, { data, loading, error }] = useCreateContractCertificateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useCreateContractCertificateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContractCertificateMutation, CreateContractCertificateMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContractCertificateMutation, CreateContractCertificateMutationVariables>(CreateContractCertificateDocument, baseOptions);
      }
export type CreateContractCertificateMutationHookResult = ReturnType<typeof useCreateContractCertificateMutation>;
export type CreateContractCertificateMutationResult = ApolloReactCommon.MutationResult<CreateContractCertificateMutation>;
export type CreateContractCertificateMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateContractCertificateMutation, CreateContractCertificateMutationVariables>;
export const GetContractExtensionDocument = gql`
    query getContractExtension($ID: String!, $customerID: String, $orderByAsc: String, $orderByDesc: String) {
  getContract(ID: $ID, customerID: $customerID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    ID
    title
    accountID
    contractNo
    customerID
    description
    contractDate
    contractSum
    taxSchemeID
    startDate
    endDate
    creditTerm
    contractStatus
    customerDetail {
      name
      contactPerson {
        ID
        name
        designation
        email
        contactNo
      }
      contactNo
    }
    extensionDate
  }
  lastExtensionDate(contractID: $ID)
}
    `;

/**
 * __useGetContractExtensionQuery__
 *
 * To run a query within a React component, call `useGetContractExtensionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractExtensionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractExtensionQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      customerID: // value for 'customerID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetContractExtensionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractExtensionQuery, GetContractExtensionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractExtensionQuery, GetContractExtensionQueryVariables>(GetContractExtensionDocument, baseOptions);
      }
export function useGetContractExtensionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractExtensionQuery, GetContractExtensionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractExtensionQuery, GetContractExtensionQueryVariables>(GetContractExtensionDocument, baseOptions);
        }
export type GetContractExtensionQueryHookResult = ReturnType<typeof useGetContractExtensionQuery>;
export type GetContractExtensionLazyQueryHookResult = ReturnType<typeof useGetContractExtensionLazyQuery>;
export type GetContractExtensionQueryResult = ApolloReactCommon.QueryResult<GetContractExtensionQuery, GetContractExtensionQueryVariables>;
export const GetContractTitleDocument = gql`
    query getContractTitle($ID: String, $customerID: String, $orderByAsc: String, $orderByDesc: String, $take: Float) {
  getContract(ID: $ID, customerID: $customerID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, take: $take) {
    ID
    title
    contractNo
    contractStatus
    cmgdDate
    cpcDate
    dlpDate
  }
}
    `;

/**
 * __useGetContractTitleQuery__
 *
 * To run a query within a React component, call `useGetContractTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractTitleQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      customerID: // value for 'customerID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetContractTitleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractTitleQuery, GetContractTitleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractTitleQuery, GetContractTitleQueryVariables>(GetContractTitleDocument, baseOptions);
      }
export function useGetContractTitleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractTitleQuery, GetContractTitleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractTitleQuery, GetContractTitleQueryVariables>(GetContractTitleDocument, baseOptions);
        }
export type GetContractTitleQueryHookResult = ReturnType<typeof useGetContractTitleQuery>;
export type GetContractTitleLazyQueryHookResult = ReturnType<typeof useGetContractTitleLazyQuery>;
export type GetContractTitleQueryResult = ApolloReactCommon.QueryResult<GetContractTitleQuery, GetContractTitleQueryVariables>;
export const InsertUpdateSiteDocument = gql`
    mutation insertUpdateSite($siteInput: [SiteInput!]!, $contractID: String!) {
  insertUpdateSite(siteInput: $siteInput, contractID: $contractID)
}
    `;
export type InsertUpdateSiteMutationFn = ApolloReactCommon.MutationFunction<InsertUpdateSiteMutation, InsertUpdateSiteMutationVariables>;

/**
 * __useInsertUpdateSiteMutation__
 *
 * To run a mutation, you first call `useInsertUpdateSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertUpdateSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertUpdateSiteMutation, { data, loading, error }] = useInsertUpdateSiteMutation({
 *   variables: {
 *      siteInput: // value for 'siteInput'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useInsertUpdateSiteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertUpdateSiteMutation, InsertUpdateSiteMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertUpdateSiteMutation, InsertUpdateSiteMutationVariables>(InsertUpdateSiteDocument, baseOptions);
      }
export type InsertUpdateSiteMutationHookResult = ReturnType<typeof useInsertUpdateSiteMutation>;
export type InsertUpdateSiteMutationResult = ApolloReactCommon.MutationResult<InsertUpdateSiteMutation>;
export type InsertUpdateSiteMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertUpdateSiteMutation, InsertUpdateSiteMutationVariables>;
export const AssociateCheckingDocument = gql`
    query associateChecking($subcontractID: String, $contractID: String) {
  associateChecking(subcontractID: $subcontractID, contractID: $contractID)
  voChecking(subcontractID: $subcontractID, contractID: $contractID)
}
    `;

/**
 * __useAssociateCheckingQuery__
 *
 * To run a query within a React component, call `useAssociateCheckingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssociateCheckingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssociateCheckingQuery({
 *   variables: {
 *      subcontractID: // value for 'subcontractID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useAssociateCheckingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AssociateCheckingQuery, AssociateCheckingQueryVariables>) {
        return ApolloReactHooks.useQuery<AssociateCheckingQuery, AssociateCheckingQueryVariables>(AssociateCheckingDocument, baseOptions);
      }
export function useAssociateCheckingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AssociateCheckingQuery, AssociateCheckingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AssociateCheckingQuery, AssociateCheckingQueryVariables>(AssociateCheckingDocument, baseOptions);
        }
export type AssociateCheckingQueryHookResult = ReturnType<typeof useAssociateCheckingQuery>;
export type AssociateCheckingLazyQueryHookResult = ReturnType<typeof useAssociateCheckingLazyQuery>;
export type AssociateCheckingQueryResult = ApolloReactCommon.QueryResult<AssociateCheckingQuery, AssociateCheckingQueryVariables>;
export const GetCustomerForContractDocument = gql`
    query getCustomerForContract($associatedStatus: String) {
  getCustomer(associatedStatus: $associatedStatus) {
    name
    ID
    associatedStatus
  }
}
    `;

/**
 * __useGetCustomerForContractQuery__
 *
 * To run a query within a React component, call `useGetCustomerForContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerForContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerForContractQuery({
 *   variables: {
 *      associatedStatus: // value for 'associatedStatus'
 *   },
 * });
 */
export function useGetCustomerForContractQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCustomerForContractQuery, GetCustomerForContractQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCustomerForContractQuery, GetCustomerForContractQueryVariables>(GetCustomerForContractDocument, baseOptions);
      }
export function useGetCustomerForContractLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomerForContractQuery, GetCustomerForContractQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCustomerForContractQuery, GetCustomerForContractQueryVariables>(GetCustomerForContractDocument, baseOptions);
        }
export type GetCustomerForContractQueryHookResult = ReturnType<typeof useGetCustomerForContractQuery>;
export type GetCustomerForContractLazyQueryHookResult = ReturnType<typeof useGetCustomerForContractLazyQuery>;
export type GetCustomerForContractQueryResult = ApolloReactCommon.QueryResult<GetCustomerForContractQuery, GetCustomerForContractQueryVariables>;
export const CreateDefectMgmtDocument = gql`
    mutation createDefectMgmt($input: DefectManagementInput!) {
  createDefectMgmt(input: $input) {
    ID
  }
}
    `;
export type CreateDefectMgmtMutationFn = ApolloReactCommon.MutationFunction<CreateDefectMgmtMutation, CreateDefectMgmtMutationVariables>;

/**
 * __useCreateDefectMgmtMutation__
 *
 * To run a mutation, you first call `useCreateDefectMgmtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDefectMgmtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDefectMgmtMutation, { data, loading, error }] = useCreateDefectMgmtMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDefectMgmtMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDefectMgmtMutation, CreateDefectMgmtMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDefectMgmtMutation, CreateDefectMgmtMutationVariables>(CreateDefectMgmtDocument, baseOptions);
      }
export type CreateDefectMgmtMutationHookResult = ReturnType<typeof useCreateDefectMgmtMutation>;
export type CreateDefectMgmtMutationResult = ApolloReactCommon.MutationResult<CreateDefectMgmtMutation>;
export type CreateDefectMgmtMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDefectMgmtMutation, CreateDefectMgmtMutationVariables>;
export const UpdateDefectMgmtDocument = gql`
    mutation updateDefectMgmt($input: DefectManagementInput!) {
  updateDefectMgmt(input: $input) {
    ID
  }
}
    `;
export type UpdateDefectMgmtMutationFn = ApolloReactCommon.MutationFunction<UpdateDefectMgmtMutation, UpdateDefectMgmtMutationVariables>;

/**
 * __useUpdateDefectMgmtMutation__
 *
 * To run a mutation, you first call `useUpdateDefectMgmtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDefectMgmtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDefectMgmtMutation, { data, loading, error }] = useUpdateDefectMgmtMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDefectMgmtMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDefectMgmtMutation, UpdateDefectMgmtMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDefectMgmtMutation, UpdateDefectMgmtMutationVariables>(UpdateDefectMgmtDocument, baseOptions);
      }
export type UpdateDefectMgmtMutationHookResult = ReturnType<typeof useUpdateDefectMgmtMutation>;
export type UpdateDefectMgmtMutationResult = ApolloReactCommon.MutationResult<UpdateDefectMgmtMutation>;
export type UpdateDefectMgmtMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDefectMgmtMutation, UpdateDefectMgmtMutationVariables>;
export const CreateActivityLogDocument = gql`
    mutation createActivityLog($input: defectLogInput!, $defectID: String!, $file: [Upload!]) {
  createActivityLog(input: $input, defectID: $defectID, file: $file)
}
    `;
export type CreateActivityLogMutationFn = ApolloReactCommon.MutationFunction<CreateActivityLogMutation, CreateActivityLogMutationVariables>;

/**
 * __useCreateActivityLogMutation__
 *
 * To run a mutation, you first call `useCreateActivityLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateActivityLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createActivityLogMutation, { data, loading, error }] = useCreateActivityLogMutation({
 *   variables: {
 *      input: // value for 'input'
 *      defectID: // value for 'defectID'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useCreateActivityLogMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateActivityLogMutation, CreateActivityLogMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateActivityLogMutation, CreateActivityLogMutationVariables>(CreateActivityLogDocument, baseOptions);
      }
export type CreateActivityLogMutationHookResult = ReturnType<typeof useCreateActivityLogMutation>;
export type CreateActivityLogMutationResult = ApolloReactCommon.MutationResult<CreateActivityLogMutation>;
export type CreateActivityLogMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateActivityLogMutation, CreateActivityLogMutationVariables>;
export const AssignDefectDocument = gql`
    mutation assignDefect($input: DefectAssignmentInput!, $defectID: String!) {
  assignDefect(input: $input, ID: $defectID)
}
    `;
export type AssignDefectMutationFn = ApolloReactCommon.MutationFunction<AssignDefectMutation, AssignDefectMutationVariables>;

/**
 * __useAssignDefectMutation__
 *
 * To run a mutation, you first call `useAssignDefectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignDefectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignDefectMutation, { data, loading, error }] = useAssignDefectMutation({
 *   variables: {
 *      input: // value for 'input'
 *      defectID: // value for 'defectID'
 *   },
 * });
 */
export function useAssignDefectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignDefectMutation, AssignDefectMutationVariables>) {
        return ApolloReactHooks.useMutation<AssignDefectMutation, AssignDefectMutationVariables>(AssignDefectDocument, baseOptions);
      }
export type AssignDefectMutationHookResult = ReturnType<typeof useAssignDefectMutation>;
export type AssignDefectMutationResult = ApolloReactCommon.MutationResult<AssignDefectMutation>;
export type AssignDefectMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignDefectMutation, AssignDefectMutationVariables>;
export const ReOpenDefectDocument = gql`
    mutation reOpenDefect($input: ReOpenDefectInput!, $defectID: String!) {
  reOpenDefect(input: $input, ID: $defectID)
}
    `;
export type ReOpenDefectMutationFn = ApolloReactCommon.MutationFunction<ReOpenDefectMutation, ReOpenDefectMutationVariables>;

/**
 * __useReOpenDefectMutation__
 *
 * To run a mutation, you first call `useReOpenDefectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReOpenDefectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reOpenDefectMutation, { data, loading, error }] = useReOpenDefectMutation({
 *   variables: {
 *      input: // value for 'input'
 *      defectID: // value for 'defectID'
 *   },
 * });
 */
export function useReOpenDefectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReOpenDefectMutation, ReOpenDefectMutationVariables>) {
        return ApolloReactHooks.useMutation<ReOpenDefectMutation, ReOpenDefectMutationVariables>(ReOpenDefectDocument, baseOptions);
      }
export type ReOpenDefectMutationHookResult = ReturnType<typeof useReOpenDefectMutation>;
export type ReOpenDefectMutationResult = ApolloReactCommon.MutationResult<ReOpenDefectMutation>;
export type ReOpenDefectMutationOptions = ApolloReactCommon.BaseMutationOptions<ReOpenDefectMutation, ReOpenDefectMutationVariables>;
export const GetDefectMgmtDocument = gql`
    query getDefectMgmt($ID: String, $contractID: String, $defectStatus: DefectStatus, $orderByDesc: String, $orderByAsc: String) {
  getDefectMgmt(ID: $ID, contractID: $contractID, defectStatus: $defectStatus, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc) {
    ID
    remarks
    title
    defectStatus
    contractID
    subcontractorID
    createdTs
    createdBy
    docNo
    defectLog
    subcontractor {
      name
    }
  }
}
    `;

/**
 * __useGetDefectMgmtQuery__
 *
 * To run a query within a React component, call `useGetDefectMgmtQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefectMgmtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefectMgmtQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      defectStatus: // value for 'defectStatus'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetDefectMgmtQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDefectMgmtQuery, GetDefectMgmtQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDefectMgmtQuery, GetDefectMgmtQueryVariables>(GetDefectMgmtDocument, baseOptions);
      }
export function useGetDefectMgmtLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDefectMgmtQuery, GetDefectMgmtQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDefectMgmtQuery, GetDefectMgmtQueryVariables>(GetDefectMgmtDocument, baseOptions);
        }
export type GetDefectMgmtQueryHookResult = ReturnType<typeof useGetDefectMgmtQuery>;
export type GetDefectMgmtLazyQueryHookResult = ReturnType<typeof useGetDefectMgmtLazyQuery>;
export type GetDefectMgmtQueryResult = ApolloReactCommon.QueryResult<GetDefectMgmtQuery, GetDefectMgmtQueryVariables>;
export const GetDigitalDocumentDocument = gql`
    query getDigitalDocument($associateID: String!, $type: String!) {
  getDigitalDocument(associateID: $associateID, type: $type)
}
    `;

/**
 * __useGetDigitalDocumentQuery__
 *
 * To run a query within a React component, call `useGetDigitalDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDigitalDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDigitalDocumentQuery({
 *   variables: {
 *      associateID: // value for 'associateID'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetDigitalDocumentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDigitalDocumentQuery, GetDigitalDocumentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDigitalDocumentQuery, GetDigitalDocumentQueryVariables>(GetDigitalDocumentDocument, baseOptions);
      }
export function useGetDigitalDocumentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDigitalDocumentQuery, GetDigitalDocumentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDigitalDocumentQuery, GetDigitalDocumentQueryVariables>(GetDigitalDocumentDocument, baseOptions);
        }
export type GetDigitalDocumentQueryHookResult = ReturnType<typeof useGetDigitalDocumentQuery>;
export type GetDigitalDocumentLazyQueryHookResult = ReturnType<typeof useGetDigitalDocumentLazyQuery>;
export type GetDigitalDocumentQueryResult = ApolloReactCommon.QueryResult<GetDigitalDocumentQuery, GetDigitalDocumentQueryVariables>;
export const GetTableDocumentsDocument = gql`
    query getTableDocuments($refID: [String!]!, $associateID: String!, $refTable: String!, $description: String) {
  getTableDocuments(refID: $refID, associateID: $associateID, refTable: $refTable, description: $description) {
    ID
    refID
    fileName
    fileURL
    mediaType
    bucketFileName
    description
    size
    createdTs
  }
}
    `;

/**
 * __useGetTableDocumentsQuery__
 *
 * To run a query within a React component, call `useGetTableDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTableDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTableDocumentsQuery({
 *   variables: {
 *      refID: // value for 'refID'
 *      associateID: // value for 'associateID'
 *      refTable: // value for 'refTable'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useGetTableDocumentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTableDocumentsQuery, GetTableDocumentsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTableDocumentsQuery, GetTableDocumentsQueryVariables>(GetTableDocumentsDocument, baseOptions);
      }
export function useGetTableDocumentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTableDocumentsQuery, GetTableDocumentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTableDocumentsQuery, GetTableDocumentsQueryVariables>(GetTableDocumentsDocument, baseOptions);
        }
export type GetTableDocumentsQueryHookResult = ReturnType<typeof useGetTableDocumentsQuery>;
export type GetTableDocumentsLazyQueryHookResult = ReturnType<typeof useGetTableDocumentsLazyQuery>;
export type GetTableDocumentsQueryResult = ApolloReactCommon.QueryResult<GetTableDocumentsQuery, GetTableDocumentsQueryVariables>;
export const AddDigitalDocumentsDocument = gql`
    mutation addDigitalDocuments($input: DigitalDocumentInput!) {
  addDigitalDocuments(input: $input)
}
    `;
export type AddDigitalDocumentsMutationFn = ApolloReactCommon.MutationFunction<AddDigitalDocumentsMutation, AddDigitalDocumentsMutationVariables>;

/**
 * __useAddDigitalDocumentsMutation__
 *
 * To run a mutation, you first call `useAddDigitalDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDigitalDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDigitalDocumentsMutation, { data, loading, error }] = useAddDigitalDocumentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddDigitalDocumentsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddDigitalDocumentsMutation, AddDigitalDocumentsMutationVariables>) {
        return ApolloReactHooks.useMutation<AddDigitalDocumentsMutation, AddDigitalDocumentsMutationVariables>(AddDigitalDocumentsDocument, baseOptions);
      }
export type AddDigitalDocumentsMutationHookResult = ReturnType<typeof useAddDigitalDocumentsMutation>;
export type AddDigitalDocumentsMutationResult = ApolloReactCommon.MutationResult<AddDigitalDocumentsMutation>;
export type AddDigitalDocumentsMutationOptions = ApolloReactCommon.BaseMutationOptions<AddDigitalDocumentsMutation, AddDigitalDocumentsMutationVariables>;
export const DeleteDigitalDocumentsDocument = gql`
    mutation deleteDigitalDocuments($ID: String!) {
  deleteDigitalDocuments(ID: $ID)
}
    `;
export type DeleteDigitalDocumentsMutationFn = ApolloReactCommon.MutationFunction<DeleteDigitalDocumentsMutation, DeleteDigitalDocumentsMutationVariables>;

/**
 * __useDeleteDigitalDocumentsMutation__
 *
 * To run a mutation, you first call `useDeleteDigitalDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDigitalDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDigitalDocumentsMutation, { data, loading, error }] = useDeleteDigitalDocumentsMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteDigitalDocumentsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDigitalDocumentsMutation, DeleteDigitalDocumentsMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteDigitalDocumentsMutation, DeleteDigitalDocumentsMutationVariables>(DeleteDigitalDocumentsDocument, baseOptions);
      }
export type DeleteDigitalDocumentsMutationHookResult = ReturnType<typeof useDeleteDigitalDocumentsMutation>;
export type DeleteDigitalDocumentsMutationResult = ApolloReactCommon.MutationResult<DeleteDigitalDocumentsMutation>;
export type DeleteDigitalDocumentsMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDigitalDocumentsMutation, DeleteDigitalDocumentsMutationVariables>;
export const GetContractSiteDocument = gql`
    query getContractSite($ID: String, $customerID: String, $orderByAsc: String, $orderByDesc: String) {
  getContract(ID: $ID, customerID: $customerID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    ID
    title
    accountID
    contractNo
    customerID
    description
    site {
      name
      contractID
      address
      ID
    }
    contractDate
    contractSum
    startDate
    endDate
    contractStatus
  }
}
    `;

/**
 * __useGetContractSiteQuery__
 *
 * To run a query within a React component, call `useGetContractSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractSiteQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      customerID: // value for 'customerID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetContractSiteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractSiteQuery, GetContractSiteQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractSiteQuery, GetContractSiteQueryVariables>(GetContractSiteDocument, baseOptions);
      }
export function useGetContractSiteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractSiteQuery, GetContractSiteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractSiteQuery, GetContractSiteQueryVariables>(GetContractSiteDocument, baseOptions);
        }
export type GetContractSiteQueryHookResult = ReturnType<typeof useGetContractSiteQuery>;
export type GetContractSiteLazyQueryHookResult = ReturnType<typeof useGetContractSiteLazyQuery>;
export type GetContractSiteQueryResult = ApolloReactCommon.QueryResult<GetContractSiteQuery, GetContractSiteQueryVariables>;
export const GetSiteContractDocument = gql`
    query getSiteContract($ID: String, $contractID: String) {
  getSite(ID: $ID, contractID: $contractID) {
    ID
    contractID
    name
    address
    contract {
      ID
      title
      description
      contractSum
      contractStatus
    }
  }
}
    `;

/**
 * __useGetSiteContractQuery__
 *
 * To run a query within a React component, call `useGetSiteContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteContractQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetSiteContractQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteContractQuery, GetSiteContractQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteContractQuery, GetSiteContractQueryVariables>(GetSiteContractDocument, baseOptions);
      }
export function useGetSiteContractLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteContractQuery, GetSiteContractQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteContractQuery, GetSiteContractQueryVariables>(GetSiteContractDocument, baseOptions);
        }
export type GetSiteContractQueryHookResult = ReturnType<typeof useGetSiteContractQuery>;
export type GetSiteContractLazyQueryHookResult = ReturnType<typeof useGetSiteContractLazyQuery>;
export type GetSiteContractQueryResult = ApolloReactCommon.QueryResult<GetSiteContractQuery, GetSiteContractQueryVariables>;
export const SiteManagementExecSummaryDocument = gql`
    query siteManagementExecSummary($siteID: String!) {
  siteWorkerAttendanceInfo(siteID: $siteID)
  DOCount(siteID: $siteID)
  MOSCount(siteID: $siteID)
  lastSiteProgressDate(siteID: $siteID)
}
    `;

/**
 * __useSiteManagementExecSummaryQuery__
 *
 * To run a query within a React component, call `useSiteManagementExecSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteManagementExecSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteManagementExecSummaryQuery({
 *   variables: {
 *      siteID: // value for 'siteID'
 *   },
 * });
 */
export function useSiteManagementExecSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SiteManagementExecSummaryQuery, SiteManagementExecSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<SiteManagementExecSummaryQuery, SiteManagementExecSummaryQueryVariables>(SiteManagementExecSummaryDocument, baseOptions);
      }
export function useSiteManagementExecSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SiteManagementExecSummaryQuery, SiteManagementExecSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SiteManagementExecSummaryQuery, SiteManagementExecSummaryQueryVariables>(SiteManagementExecSummaryDocument, baseOptions);
        }
export type SiteManagementExecSummaryQueryHookResult = ReturnType<typeof useSiteManagementExecSummaryQuery>;
export type SiteManagementExecSummaryLazyQueryHookResult = ReturnType<typeof useSiteManagementExecSummaryLazyQuery>;
export type SiteManagementExecSummaryQueryResult = ApolloReactCommon.QueryResult<SiteManagementExecSummaryQuery, SiteManagementExecSummaryQueryVariables>;
export const GetSiteWorkersDocument = gql`
    query getSiteWorkers($orderByDesc: String, $orderByAsc: String, $skip: Float, $take: Float, $ID: String, $contractID: String, $siteID: String, $commonStatus: CommonStatus) {
  getSiteWorkers(orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, skip: $skip, take: $take, ID: $ID, contractID: $contractID, siteID: $siteID, commonStatus: $commonStatus) {
    ID
    contactID
    accountID
    siteID
    nationality
    commonStatus
    modTs
    site {
      name
      contract {
        title
      }
    }
  }
}
    `;

/**
 * __useGetSiteWorkersQuery__
 *
 * To run a query within a React component, call `useGetSiteWorkersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteWorkersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteWorkersQuery({
 *   variables: {
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      siteID: // value for 'siteID'
 *      commonStatus: // value for 'commonStatus'
 *   },
 * });
 */
export function useGetSiteWorkersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteWorkersQuery, GetSiteWorkersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteWorkersQuery, GetSiteWorkersQueryVariables>(GetSiteWorkersDocument, baseOptions);
      }
export function useGetSiteWorkersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteWorkersQuery, GetSiteWorkersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteWorkersQuery, GetSiteWorkersQueryVariables>(GetSiteWorkersDocument, baseOptions);
        }
export type GetSiteWorkersQueryHookResult = ReturnType<typeof useGetSiteWorkersQuery>;
export type GetSiteWorkersLazyQueryHookResult = ReturnType<typeof useGetSiteWorkersLazyQuery>;
export type GetSiteWorkersQueryResult = ApolloReactCommon.QueryResult<GetSiteWorkersQuery, GetSiteWorkersQueryVariables>;
export const GetSiteProgressDocument = gql`
    query getSiteProgress($orderByDesc: String, $orderByAsc: String, $skip: Float, $take: Float, $ID: String, $contractID: String, $siteID: String) {
  getSiteProgress(orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, skip: $skip, take: $take, ID: $ID, contractID: $contractID, siteID: $siteID) {
    createdTs
    createdBy
    modTs
    modBy
    ID
    accountID
    contractID
    title
    docDate
    contract {
      title
    }
    imagesInfo
  }
}
    `;

/**
 * __useGetSiteProgressQuery__
 *
 * To run a query within a React component, call `useGetSiteProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteProgressQuery({
 *   variables: {
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      siteID: // value for 'siteID'
 *   },
 * });
 */
export function useGetSiteProgressQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteProgressQuery, GetSiteProgressQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteProgressQuery, GetSiteProgressQueryVariables>(GetSiteProgressDocument, baseOptions);
      }
export function useGetSiteProgressLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteProgressQuery, GetSiteProgressQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteProgressQuery, GetSiteProgressQueryVariables>(GetSiteProgressDocument, baseOptions);
        }
export type GetSiteProgressQueryHookResult = ReturnType<typeof useGetSiteProgressQuery>;
export type GetSiteProgressLazyQueryHookResult = ReturnType<typeof useGetSiteProgressLazyQuery>;
export type GetSiteProgressQueryResult = ApolloReactCommon.QueryResult<GetSiteProgressQuery, GetSiteProgressQueryVariables>;
export const GetThumbnailDocumentListingDocument = gql`
    query getThumbnailDocumentListing($refID: String, $refTable: String) {
  DocumentListing(refID: $refID, refTable: $refTable) {
    ID
    mediaType
    refTable
    refID
    description
    fileName
    fileURL
    accountID
    bucketFileName
    opacity
  }
}
    `;

/**
 * __useGetThumbnailDocumentListingQuery__
 *
 * To run a query within a React component, call `useGetThumbnailDocumentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThumbnailDocumentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThumbnailDocumentListingQuery({
 *   variables: {
 *      refID: // value for 'refID'
 *      refTable: // value for 'refTable'
 *   },
 * });
 */
export function useGetThumbnailDocumentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetThumbnailDocumentListingQuery, GetThumbnailDocumentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetThumbnailDocumentListingQuery, GetThumbnailDocumentListingQueryVariables>(GetThumbnailDocumentListingDocument, baseOptions);
      }
export function useGetThumbnailDocumentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetThumbnailDocumentListingQuery, GetThumbnailDocumentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetThumbnailDocumentListingQuery, GetThumbnailDocumentListingQueryVariables>(GetThumbnailDocumentListingDocument, baseOptions);
        }
export type GetThumbnailDocumentListingQueryHookResult = ReturnType<typeof useGetThumbnailDocumentListingQuery>;
export type GetThumbnailDocumentListingLazyQueryHookResult = ReturnType<typeof useGetThumbnailDocumentListingLazyQuery>;
export type GetThumbnailDocumentListingQueryResult = ApolloReactCommon.QueryResult<GetThumbnailDocumentListingQuery, GetThumbnailDocumentListingQueryVariables>;
export const CreateSiteProgressDocument = gql`
    mutation createSiteProgress($input: SiteProgressInput!) {
  createSiteProgress(input: $input) {
    ID
    title
  }
}
    `;
export type CreateSiteProgressMutationFn = ApolloReactCommon.MutationFunction<CreateSiteProgressMutation, CreateSiteProgressMutationVariables>;

/**
 * __useCreateSiteProgressMutation__
 *
 * To run a mutation, you first call `useCreateSiteProgressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSiteProgressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSiteProgressMutation, { data, loading, error }] = useCreateSiteProgressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSiteProgressMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSiteProgressMutation, CreateSiteProgressMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSiteProgressMutation, CreateSiteProgressMutationVariables>(CreateSiteProgressDocument, baseOptions);
      }
export type CreateSiteProgressMutationHookResult = ReturnType<typeof useCreateSiteProgressMutation>;
export type CreateSiteProgressMutationResult = ApolloReactCommon.MutationResult<CreateSiteProgressMutation>;
export type CreateSiteProgressMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSiteProgressMutation, CreateSiteProgressMutationVariables>;
export const UpdateSiteProgressDocument = gql`
    mutation updateSiteProgress($input: SiteProgressInput!) {
  updateSiteProgress(input: $input) {
    ID
    title
  }
}
    `;
export type UpdateSiteProgressMutationFn = ApolloReactCommon.MutationFunction<UpdateSiteProgressMutation, UpdateSiteProgressMutationVariables>;

/**
 * __useUpdateSiteProgressMutation__
 *
 * To run a mutation, you first call `useUpdateSiteProgressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSiteProgressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSiteProgressMutation, { data, loading, error }] = useUpdateSiteProgressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSiteProgressMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSiteProgressMutation, UpdateSiteProgressMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSiteProgressMutation, UpdateSiteProgressMutationVariables>(UpdateSiteProgressDocument, baseOptions);
      }
export type UpdateSiteProgressMutationHookResult = ReturnType<typeof useUpdateSiteProgressMutation>;
export type UpdateSiteProgressMutationResult = ApolloReactCommon.MutationResult<UpdateSiteProgressMutation>;
export type UpdateSiteProgressMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSiteProgressMutation, UpdateSiteProgressMutationVariables>;
export const DeleteSiteProgressDocument = gql`
    mutation deleteSiteProgress($input: SiteProgressInput!) {
  deleteSiteProgress(input: $input)
}
    `;
export type DeleteSiteProgressMutationFn = ApolloReactCommon.MutationFunction<DeleteSiteProgressMutation, DeleteSiteProgressMutationVariables>;

/**
 * __useDeleteSiteProgressMutation__
 *
 * To run a mutation, you first call `useDeleteSiteProgressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSiteProgressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSiteProgressMutation, { data, loading, error }] = useDeleteSiteProgressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSiteProgressMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSiteProgressMutation, DeleteSiteProgressMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSiteProgressMutation, DeleteSiteProgressMutationVariables>(DeleteSiteProgressDocument, baseOptions);
      }
export type DeleteSiteProgressMutationHookResult = ReturnType<typeof useDeleteSiteProgressMutation>;
export type DeleteSiteProgressMutationResult = ApolloReactCommon.MutationResult<DeleteSiteProgressMutation>;
export type DeleteSiteProgressMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSiteProgressMutation, DeleteSiteProgressMutationVariables>;
export const GetSiteStockItemsDocument = gql`
    query getSiteStockItems($siteID: String!) {
  getSiteStockItems(siteID: $siteID)
}
    `;

/**
 * __useGetSiteStockItemsQuery__
 *
 * To run a query within a React component, call `useGetSiteStockItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteStockItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteStockItemsQuery({
 *   variables: {
 *      siteID: // value for 'siteID'
 *   },
 * });
 */
export function useGetSiteStockItemsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteStockItemsQuery, GetSiteStockItemsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteStockItemsQuery, GetSiteStockItemsQueryVariables>(GetSiteStockItemsDocument, baseOptions);
      }
export function useGetSiteStockItemsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteStockItemsQuery, GetSiteStockItemsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteStockItemsQuery, GetSiteStockItemsQueryVariables>(GetSiteStockItemsDocument, baseOptions);
        }
export type GetSiteStockItemsQueryHookResult = ReturnType<typeof useGetSiteStockItemsQuery>;
export type GetSiteStockItemsLazyQueryHookResult = ReturnType<typeof useGetSiteStockItemsLazyQuery>;
export type GetSiteStockItemsQueryResult = ApolloReactCommon.QueryResult<GetSiteStockItemsQuery, GetSiteStockItemsQueryVariables>;
export const GetStockItemDetailDocument = gql`
    query getStockItemDetail($siteID: String!, $costItemID: String!, $uomID: String!) {
  getStockItemDetail(siteID: $siteID, costItemID: $costItemID, uomID: $uomID)
}
    `;

/**
 * __useGetStockItemDetailQuery__
 *
 * To run a query within a React component, call `useGetStockItemDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockItemDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockItemDetailQuery({
 *   variables: {
 *      siteID: // value for 'siteID'
 *      costItemID: // value for 'costItemID'
 *      uomID: // value for 'uomID'
 *   },
 * });
 */
export function useGetStockItemDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockItemDetailQuery, GetStockItemDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockItemDetailQuery, GetStockItemDetailQueryVariables>(GetStockItemDetailDocument, baseOptions);
      }
export function useGetStockItemDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockItemDetailQuery, GetStockItemDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockItemDetailQuery, GetStockItemDetailQueryVariables>(GetStockItemDetailDocument, baseOptions);
        }
export type GetStockItemDetailQueryHookResult = ReturnType<typeof useGetStockItemDetailQuery>;
export type GetStockItemDetailLazyQueryHookResult = ReturnType<typeof useGetStockItemDetailLazyQuery>;
export type GetStockItemDetailQueryResult = ApolloReactCommon.QueryResult<GetStockItemDetailQuery, GetStockItemDetailQueryVariables>;
export const GetSiteStockLedgerDocument = gql`
    query getSiteStockLedger($orderByDesc: String, $orderByAsc: String, $skip: Float, $take: Float, $ID: String, $uomID: String, $costItemID: String) {
  getSiteStockLedger(orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, skip: $skip, take: $take, ID: $ID, uomID: $uomID, costItemID: $costItemID) {
    ID
    trackingDate
    remarks
    docRef
    quantity
    trxType
    siteID
    costItemID
    site {
      ID
      name
      address
    }
    costItem {
      ID
      name
    }
    uom {
      ID
      name
      code
    }
    createdBy
    createdTs
  }
}
    `;

/**
 * __useGetSiteStockLedgerQuery__
 *
 * To run a query within a React component, call `useGetSiteStockLedgerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteStockLedgerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteStockLedgerQuery({
 *   variables: {
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      ID: // value for 'ID'
 *      uomID: // value for 'uomID'
 *      costItemID: // value for 'costItemID'
 *   },
 * });
 */
export function useGetSiteStockLedgerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteStockLedgerQuery, GetSiteStockLedgerQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteStockLedgerQuery, GetSiteStockLedgerQueryVariables>(GetSiteStockLedgerDocument, baseOptions);
      }
export function useGetSiteStockLedgerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteStockLedgerQuery, GetSiteStockLedgerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteStockLedgerQuery, GetSiteStockLedgerQueryVariables>(GetSiteStockLedgerDocument, baseOptions);
        }
export type GetSiteStockLedgerQueryHookResult = ReturnType<typeof useGetSiteStockLedgerQuery>;
export type GetSiteStockLedgerLazyQueryHookResult = ReturnType<typeof useGetSiteStockLedgerLazyQuery>;
export type GetSiteStockLedgerQueryResult = ApolloReactCommon.QueryResult<GetSiteStockLedgerQuery, GetSiteStockLedgerQueryVariables>;
export const CreateSiteStockLedgerDocument = gql`
    mutation createSiteStockLedger($input: SiteStockLedgerInput!) {
  createSiteStockLedger(input: $input) {
    ID
  }
}
    `;
export type CreateSiteStockLedgerMutationFn = ApolloReactCommon.MutationFunction<CreateSiteStockLedgerMutation, CreateSiteStockLedgerMutationVariables>;

/**
 * __useCreateSiteStockLedgerMutation__
 *
 * To run a mutation, you first call `useCreateSiteStockLedgerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSiteStockLedgerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSiteStockLedgerMutation, { data, loading, error }] = useCreateSiteStockLedgerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSiteStockLedgerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSiteStockLedgerMutation, CreateSiteStockLedgerMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSiteStockLedgerMutation, CreateSiteStockLedgerMutationVariables>(CreateSiteStockLedgerDocument, baseOptions);
      }
export type CreateSiteStockLedgerMutationHookResult = ReturnType<typeof useCreateSiteStockLedgerMutation>;
export type CreateSiteStockLedgerMutationResult = ApolloReactCommon.MutationResult<CreateSiteStockLedgerMutation>;
export type CreateSiteStockLedgerMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSiteStockLedgerMutation, CreateSiteStockLedgerMutationVariables>;
export const UpdateSiteStockLedgerDocument = gql`
    mutation updateSiteStockLedger($input: SiteStockLedgerInput!) {
  updateSiteStockLedger(input: $input) {
    ID
  }
}
    `;
export type UpdateSiteStockLedgerMutationFn = ApolloReactCommon.MutationFunction<UpdateSiteStockLedgerMutation, UpdateSiteStockLedgerMutationVariables>;

/**
 * __useUpdateSiteStockLedgerMutation__
 *
 * To run a mutation, you first call `useUpdateSiteStockLedgerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSiteStockLedgerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSiteStockLedgerMutation, { data, loading, error }] = useUpdateSiteStockLedgerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSiteStockLedgerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSiteStockLedgerMutation, UpdateSiteStockLedgerMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSiteStockLedgerMutation, UpdateSiteStockLedgerMutationVariables>(UpdateSiteStockLedgerDocument, baseOptions);
      }
export type UpdateSiteStockLedgerMutationHookResult = ReturnType<typeof useUpdateSiteStockLedgerMutation>;
export type UpdateSiteStockLedgerMutationResult = ApolloReactCommon.MutationResult<UpdateSiteStockLedgerMutation>;
export type UpdateSiteStockLedgerMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSiteStockLedgerMutation, UpdateSiteStockLedgerMutationVariables>;
export const DeleteSiteStockLedgerDocument = gql`
    mutation deleteSiteStockLedger($input: SiteStockLedgerInput!) {
  deleteSiteStockLedger(input: $input)
}
    `;
export type DeleteSiteStockLedgerMutationFn = ApolloReactCommon.MutationFunction<DeleteSiteStockLedgerMutation, DeleteSiteStockLedgerMutationVariables>;

/**
 * __useDeleteSiteStockLedgerMutation__
 *
 * To run a mutation, you first call `useDeleteSiteStockLedgerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSiteStockLedgerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSiteStockLedgerMutation, { data, loading, error }] = useDeleteSiteStockLedgerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSiteStockLedgerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSiteStockLedgerMutation, DeleteSiteStockLedgerMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSiteStockLedgerMutation, DeleteSiteStockLedgerMutationVariables>(DeleteSiteStockLedgerDocument, baseOptions);
      }
export type DeleteSiteStockLedgerMutationHookResult = ReturnType<typeof useDeleteSiteStockLedgerMutation>;
export type DeleteSiteStockLedgerMutationResult = ApolloReactCommon.MutationResult<DeleteSiteStockLedgerMutation>;
export type DeleteSiteStockLedgerMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSiteStockLedgerMutation, DeleteSiteStockLedgerMutationVariables>;
export const GetSiteWorkerAttendanceDocument = gql`
    query getSiteWorkerAttendance($orderByDesc: String, $orderByAsc: String, $skip: Float, $take: Float, $ID: String, $siteID: String, $siteWorkerID: String) {
  getSiteWorkerAttendance(orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, skip: $skip, take: $take, ID: $ID, siteID: $siteID, siteWorkerID: $siteWorkerID) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    accountID
    workerID
    siteID
    timeIn
    timeOut
    worker {
      ID
      siteID
      nationality
      commonStatus
    }
    siteID
  }
}
    `;

/**
 * __useGetSiteWorkerAttendanceQuery__
 *
 * To run a query within a React component, call `useGetSiteWorkerAttendanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteWorkerAttendanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteWorkerAttendanceQuery({
 *   variables: {
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      ID: // value for 'ID'
 *      siteID: // value for 'siteID'
 *      siteWorkerID: // value for 'siteWorkerID'
 *   },
 * });
 */
export function useGetSiteWorkerAttendanceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteWorkerAttendanceQuery, GetSiteWorkerAttendanceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteWorkerAttendanceQuery, GetSiteWorkerAttendanceQueryVariables>(GetSiteWorkerAttendanceDocument, baseOptions);
      }
export function useGetSiteWorkerAttendanceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteWorkerAttendanceQuery, GetSiteWorkerAttendanceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteWorkerAttendanceQuery, GetSiteWorkerAttendanceQueryVariables>(GetSiteWorkerAttendanceDocument, baseOptions);
        }
export type GetSiteWorkerAttendanceQueryHookResult = ReturnType<typeof useGetSiteWorkerAttendanceQuery>;
export type GetSiteWorkerAttendanceLazyQueryHookResult = ReturnType<typeof useGetSiteWorkerAttendanceLazyQuery>;
export type GetSiteWorkerAttendanceQueryResult = ApolloReactCommon.QueryResult<GetSiteWorkerAttendanceQuery, GetSiteWorkerAttendanceQueryVariables>;
export const GetSiteDocument = gql`
    query getSite($orderByDesc: String, $orderByAsc: String, $skip: Float, $take: Float, $ID: String, $contractID: String) {
  getSite(orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, skip: $skip, take: $take, ID: $ID, contractID: $contractID) {
    ID
    accountID
    contractID
    name
    address
  }
}
    `;

/**
 * __useGetSiteQuery__
 *
 * To run a query within a React component, call `useGetSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteQuery({
 *   variables: {
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetSiteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteQuery, GetSiteQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteQuery, GetSiteQueryVariables>(GetSiteDocument, baseOptions);
      }
export function useGetSiteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteQuery, GetSiteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteQuery, GetSiteQueryVariables>(GetSiteDocument, baseOptions);
        }
export type GetSiteQueryHookResult = ReturnType<typeof useGetSiteQuery>;
export type GetSiteLazyQueryHookResult = ReturnType<typeof useGetSiteLazyQuery>;
export type GetSiteQueryResult = ApolloReactCommon.QueryResult<GetSiteQuery, GetSiteQueryVariables>;
export const CreateSiteWorkersDocument = gql`
    mutation createSiteWorkers($input: SiteWorkersInput!) {
  createSiteWorkers(input: $input) {
    ID
  }
}
    `;
export type CreateSiteWorkersMutationFn = ApolloReactCommon.MutationFunction<CreateSiteWorkersMutation, CreateSiteWorkersMutationVariables>;

/**
 * __useCreateSiteWorkersMutation__
 *
 * To run a mutation, you first call `useCreateSiteWorkersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSiteWorkersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSiteWorkersMutation, { data, loading, error }] = useCreateSiteWorkersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSiteWorkersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSiteWorkersMutation, CreateSiteWorkersMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSiteWorkersMutation, CreateSiteWorkersMutationVariables>(CreateSiteWorkersDocument, baseOptions);
      }
export type CreateSiteWorkersMutationHookResult = ReturnType<typeof useCreateSiteWorkersMutation>;
export type CreateSiteWorkersMutationResult = ApolloReactCommon.MutationResult<CreateSiteWorkersMutation>;
export type CreateSiteWorkersMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSiteWorkersMutation, CreateSiteWorkersMutationVariables>;
export const UpdateSiteWorkersDocument = gql`
    mutation updateSiteWorkers($input: SiteWorkersInput!) {
  updateSiteWorkers(input: $input) {
    ID
  }
}
    `;
export type UpdateSiteWorkersMutationFn = ApolloReactCommon.MutationFunction<UpdateSiteWorkersMutation, UpdateSiteWorkersMutationVariables>;

/**
 * __useUpdateSiteWorkersMutation__
 *
 * To run a mutation, you first call `useUpdateSiteWorkersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSiteWorkersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSiteWorkersMutation, { data, loading, error }] = useUpdateSiteWorkersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSiteWorkersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSiteWorkersMutation, UpdateSiteWorkersMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSiteWorkersMutation, UpdateSiteWorkersMutationVariables>(UpdateSiteWorkersDocument, baseOptions);
      }
export type UpdateSiteWorkersMutationHookResult = ReturnType<typeof useUpdateSiteWorkersMutation>;
export type UpdateSiteWorkersMutationResult = ApolloReactCommon.MutationResult<UpdateSiteWorkersMutation>;
export type UpdateSiteWorkersMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSiteWorkersMutation, UpdateSiteWorkersMutationVariables>;
export const GetSubcontractClaimDocument = gql`
    query getSubcontractClaim($ID: String, $subcontractID: String, $subcontractorID: String) {
  getSubcontractClaim(ID: $ID, subcontractID: $subcontractID, subcontractorID: $subcontractorID) {
    ID
    docNo
    claimDocNo
    docRef
    description
    docDate
    docAmt
    docRefID
    docRefTable
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    submittedDate
    previousCertifiedAmt
    submittedCertifiedAmt
    currentCertifiedAmt
    previousVOClaimAmt
    submittedVOClaimAmt
    currentVOClaimAmt
    previousMosAmt
    submittedMosAmt
    currentMosAmt
    retentionAmt
    submittedRetentionAmt
    balanceRetentionAmt
    submittedDayWorkAmt
    currentDayWorkAmt
    balanceAdvanceAmt
    currentAdvRecoupmentAmt
    currentDeductionAmt
    submittedDeductionAmt
    balanceDeductionAmt
    transactionStatus
    contractID
    subcontractorID
    subcontractID
    rejectedDate
    rejectionRemarks
    cancellationDate
    cancellationRemarks
    voDisplayAmt
    outstandingAmt
    modBy
    createdBy
    adjustmentInfo
    subconAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
  }
  subconClaimsInfo(subcontractID: $subcontractID, subcontractorID: $subcontractorID)
}
    `;

/**
 * __useGetSubcontractClaimQuery__
 *
 * To run a query within a React component, call `useGetSubcontractClaimQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractClaimQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractClaimQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *   },
 * });
 */
export function useGetSubcontractClaimQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractClaimQuery, GetSubcontractClaimQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractClaimQuery, GetSubcontractClaimQueryVariables>(GetSubcontractClaimDocument, baseOptions);
      }
export function useGetSubcontractClaimLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractClaimQuery, GetSubcontractClaimQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractClaimQuery, GetSubcontractClaimQueryVariables>(GetSubcontractClaimDocument, baseOptions);
        }
export type GetSubcontractClaimQueryHookResult = ReturnType<typeof useGetSubcontractClaimQuery>;
export type GetSubcontractClaimLazyQueryHookResult = ReturnType<typeof useGetSubcontractClaimLazyQuery>;
export type GetSubcontractClaimQueryResult = ApolloReactCommon.QueryResult<GetSubcontractClaimQuery, GetSubcontractClaimQueryVariables>;
export const GetSubconProgressClaimDocument = gql`
    query getSubconProgressClaim($subcontractID: String, $subcontractorID: String) {
  getSubconProgressClaim(subcontractID: $subcontractID, subcontractorID: $subcontractorID) {
    ID
    isGlExport
    accountID
    balanceAdvanceAmt
    balanceDeductionAmt
    balanceRetentionAmt
    baseAmt
    cancellationDate
    cancellationRemarks
    claimDocNo
    contractID
    createdBy
    createdTs
    currencyID
    currentAdvRecoupmentAmt
    currentCertifiedAmt
    currentDayWorkAmt
    currentDeductionAmt
    currentMosAmt
    currentVOClaimAmt
    description
    docAmt
    docDate
    docDueDate
    docNo
    docRef
    docRefID
    docRefTable
    exchangeRate
    localBaseAmt
    localDocAmt
    localTaxAmt
    modBy
    modTs
    previousCertifiedAmt
    previousMosAmt
    previousVOClaimAmt
    rejectedDate
    rejectionDate
    rejectionRemarks
    retentionAmt
    revisedNo
    sequenceNo
    subcontractID
    subcontractorID
    submittedCertifiedAmt
    submittedDate
    submittedDayWorkAmt
    submittedDeductionAmt
    submittedMosAmt
    submittedRetentionAmt
    submittedVOClaimAmt
    taxAmt
    taxRate
    taxSchemeID
    toDo {
      ID
      accountID
      approvalLevel
      approvalLog {
        ID
        accountID
        approverID
        createdBy
        createdTs
        logDate
        modBy
        modTs
        notifiedDate
        remarks
        toDoID
        toDoStatus
      }
      body
      createdBy
      createdTs
      docRefAmt
      docRefID
      docRefTable
      modBy
      modTs
      notifiedDate
      subject
      submitterID
      toDoStatus
    }
    transactionDate
    transactionStatus
    voDisplayAmt
  }
  subconProgressClaimsInfo(subcontractID: $subcontractID, subcontractorID: $subcontractorID)
}
    `;

/**
 * __useGetSubconProgressClaimQuery__
 *
 * To run a query within a React component, call `useGetSubconProgressClaimQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubconProgressClaimQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubconProgressClaimQuery({
 *   variables: {
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *   },
 * });
 */
export function useGetSubconProgressClaimQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubconProgressClaimQuery, GetSubconProgressClaimQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubconProgressClaimQuery, GetSubconProgressClaimQueryVariables>(GetSubconProgressClaimDocument, baseOptions);
      }
export function useGetSubconProgressClaimLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubconProgressClaimQuery, GetSubconProgressClaimQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubconProgressClaimQuery, GetSubconProgressClaimQueryVariables>(GetSubconProgressClaimDocument, baseOptions);
        }
export type GetSubconProgressClaimQueryHookResult = ReturnType<typeof useGetSubconProgressClaimQuery>;
export type GetSubconProgressClaimLazyQueryHookResult = ReturnType<typeof useGetSubconProgressClaimLazyQuery>;
export type GetSubconProgressClaimQueryResult = ApolloReactCommon.QueryResult<GetSubconProgressClaimQuery, GetSubconProgressClaimQueryVariables>;
export const GetSubconRetentionClaimDocument = gql`
    query getSubconRetentionClaim($subcontractID: String, $subcontractorID: String) {
  getSubconRetentionClaim(subcontractID: $subcontractID, subcontractorID: $subcontractorID) {
    ID
    isGlExport
    accountID
    balanceAdvanceAmt
    balanceDeductionAmt
    balanceRetentionAmt
    baseAmt
    cancellationDate
    cancellationRemarks
    claimDocNo
    contractID
    createdBy
    createdTs
    currencyID
    currentAdvRecoupmentAmt
    currentCertifiedAmt
    currentDayWorkAmt
    currentDeductionAmt
    currentMosAmt
    currentVOClaimAmt
    description
    docAmt
    docDate
    docDueDate
    docNo
    docRef
    docRefID
    docRefTable
    exchangeRate
    localBaseAmt
    localDocAmt
    localTaxAmt
    modBy
    modTs
    previousCertifiedAmt
    previousMosAmt
    previousVOClaimAmt
    rejectedDate
    rejectionDate
    rejectionRemarks
    retentionAmt
    revisedNo
    sequenceNo
    subcontractID
    subcontractorID
    submittedCertifiedAmt
    submittedDate
    submittedDayWorkAmt
    submittedDeductionAmt
    submittedMosAmt
    submittedRetentionAmt
    submittedVOClaimAmt
    taxAmt
    taxRate
    taxSchemeID
    toDo {
      ID
      accountID
      approvalLevel
      approvalLog {
        ID
        accountID
        approverID
        createdBy
        createdTs
        logDate
        modBy
        modTs
        notifiedDate
        remarks
        toDoID
        toDoStatus
      }
      body
      createdBy
      createdTs
      docRefAmt
      docRefID
      docRefTable
      modBy
      modTs
      notifiedDate
      subject
      submitterID
      toDoStatus
    }
    transactionDate
    transactionStatus
    voDisplayAmt
  }
  subconRetentionClaimsInfo(subcontractID: $subcontractID, subcontractorID: $subcontractorID)
}
    `;

/**
 * __useGetSubconRetentionClaimQuery__
 *
 * To run a query within a React component, call `useGetSubconRetentionClaimQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubconRetentionClaimQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubconRetentionClaimQuery({
 *   variables: {
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *   },
 * });
 */
export function useGetSubconRetentionClaimQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubconRetentionClaimQuery, GetSubconRetentionClaimQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubconRetentionClaimQuery, GetSubconRetentionClaimQueryVariables>(GetSubconRetentionClaimDocument, baseOptions);
      }
export function useGetSubconRetentionClaimLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubconRetentionClaimQuery, GetSubconRetentionClaimQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubconRetentionClaimQuery, GetSubconRetentionClaimQueryVariables>(GetSubconRetentionClaimDocument, baseOptions);
        }
export type GetSubconRetentionClaimQueryHookResult = ReturnType<typeof useGetSubconRetentionClaimQuery>;
export type GetSubconRetentionClaimLazyQueryHookResult = ReturnType<typeof useGetSubconRetentionClaimLazyQuery>;
export type GetSubconRetentionClaimQueryResult = ApolloReactCommon.QueryResult<GetSubconRetentionClaimQuery, GetSubconRetentionClaimQueryVariables>;
export const SubmitSubconRetentionClaimDocument = gql`
    mutation submitSubconRetentionClaim($input: SubconClaimRetentionInput!) {
  submitSubconRetentionClaim(input: $input) {
    ID
    claimDocNo
  }
}
    `;
export type SubmitSubconRetentionClaimMutationFn = ApolloReactCommon.MutationFunction<SubmitSubconRetentionClaimMutation, SubmitSubconRetentionClaimMutationVariables>;

/**
 * __useSubmitSubconRetentionClaimMutation__
 *
 * To run a mutation, you first call `useSubmitSubconRetentionClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitSubconRetentionClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitSubconRetentionClaimMutation, { data, loading, error }] = useSubmitSubconRetentionClaimMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitSubconRetentionClaimMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitSubconRetentionClaimMutation, SubmitSubconRetentionClaimMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitSubconRetentionClaimMutation, SubmitSubconRetentionClaimMutationVariables>(SubmitSubconRetentionClaimDocument, baseOptions);
      }
export type SubmitSubconRetentionClaimMutationHookResult = ReturnType<typeof useSubmitSubconRetentionClaimMutation>;
export type SubmitSubconRetentionClaimMutationResult = ApolloReactCommon.MutationResult<SubmitSubconRetentionClaimMutation>;
export type SubmitSubconRetentionClaimMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitSubconRetentionClaimMutation, SubmitSubconRetentionClaimMutationVariables>;
export const ApproveSubconRetentionClaimDocument = gql`
    mutation approveSubconRetentionClaim($input: SubconClaimRetentionInput!) {
  approveSubconRetentionClaim(input: $input) {
    ID
    docNo
  }
}
    `;
export type ApproveSubconRetentionClaimMutationFn = ApolloReactCommon.MutationFunction<ApproveSubconRetentionClaimMutation, ApproveSubconRetentionClaimMutationVariables>;

/**
 * __useApproveSubconRetentionClaimMutation__
 *
 * To run a mutation, you first call `useApproveSubconRetentionClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveSubconRetentionClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveSubconRetentionClaimMutation, { data, loading, error }] = useApproveSubconRetentionClaimMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveSubconRetentionClaimMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApproveSubconRetentionClaimMutation, ApproveSubconRetentionClaimMutationVariables>) {
        return ApolloReactHooks.useMutation<ApproveSubconRetentionClaimMutation, ApproveSubconRetentionClaimMutationVariables>(ApproveSubconRetentionClaimDocument, baseOptions);
      }
export type ApproveSubconRetentionClaimMutationHookResult = ReturnType<typeof useApproveSubconRetentionClaimMutation>;
export type ApproveSubconRetentionClaimMutationResult = ApolloReactCommon.MutationResult<ApproveSubconRetentionClaimMutation>;
export type ApproveSubconRetentionClaimMutationOptions = ApolloReactCommon.BaseMutationOptions<ApproveSubconRetentionClaimMutation, ApproveSubconRetentionClaimMutationVariables>;
export const CreateSubcontractClaimDocument = gql`
    mutation createSubcontractClaim($input: SubcontractClaimInput!) {
  createSubcontractClaim(input: $input) {
    accountID
  }
}
    `;
export type CreateSubcontractClaimMutationFn = ApolloReactCommon.MutationFunction<CreateSubcontractClaimMutation, CreateSubcontractClaimMutationVariables>;

/**
 * __useCreateSubcontractClaimMutation__
 *
 * To run a mutation, you first call `useCreateSubcontractClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubcontractClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubcontractClaimMutation, { data, loading, error }] = useCreateSubcontractClaimMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubcontractClaimMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubcontractClaimMutation, CreateSubcontractClaimMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubcontractClaimMutation, CreateSubcontractClaimMutationVariables>(CreateSubcontractClaimDocument, baseOptions);
      }
export type CreateSubcontractClaimMutationHookResult = ReturnType<typeof useCreateSubcontractClaimMutation>;
export type CreateSubcontractClaimMutationResult = ApolloReactCommon.MutationResult<CreateSubcontractClaimMutation>;
export type CreateSubcontractClaimMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubcontractClaimMutation, CreateSubcontractClaimMutationVariables>;
export const UpdateSubcontractClaimDocument = gql`
    mutation updateSubcontractClaim($input: SubcontractClaimInput!) {
  updateSubcontractClaim(input: $input) {
    accountID
    ID
  }
}
    `;
export type UpdateSubcontractClaimMutationFn = ApolloReactCommon.MutationFunction<UpdateSubcontractClaimMutation, UpdateSubcontractClaimMutationVariables>;

/**
 * __useUpdateSubcontractClaimMutation__
 *
 * To run a mutation, you first call `useUpdateSubcontractClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubcontractClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubcontractClaimMutation, { data, loading, error }] = useUpdateSubcontractClaimMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubcontractClaimMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSubcontractClaimMutation, UpdateSubcontractClaimMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSubcontractClaimMutation, UpdateSubcontractClaimMutationVariables>(UpdateSubcontractClaimDocument, baseOptions);
      }
export type UpdateSubcontractClaimMutationHookResult = ReturnType<typeof useUpdateSubcontractClaimMutation>;
export type UpdateSubcontractClaimMutationResult = ApolloReactCommon.MutationResult<UpdateSubcontractClaimMutation>;
export type UpdateSubcontractClaimMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSubcontractClaimMutation, UpdateSubcontractClaimMutationVariables>;
export const SubmitSubconClaimDocument = gql`
    mutation submitSubconClaim($input: SubcontractClaimInput!, $deductionLedger: [LedgerInput!]) {
  submitSubconClaim(input: $input, deductionLedger: $deductionLedger) {
    ID
    docNo
  }
}
    `;
export type SubmitSubconClaimMutationFn = ApolloReactCommon.MutationFunction<SubmitSubconClaimMutation, SubmitSubconClaimMutationVariables>;

/**
 * __useSubmitSubconClaimMutation__
 *
 * To run a mutation, you first call `useSubmitSubconClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitSubconClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitSubconClaimMutation, { data, loading, error }] = useSubmitSubconClaimMutation({
 *   variables: {
 *      input: // value for 'input'
 *      deductionLedger: // value for 'deductionLedger'
 *   },
 * });
 */
export function useSubmitSubconClaimMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitSubconClaimMutation, SubmitSubconClaimMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitSubconClaimMutation, SubmitSubconClaimMutationVariables>(SubmitSubconClaimDocument, baseOptions);
      }
export type SubmitSubconClaimMutationHookResult = ReturnType<typeof useSubmitSubconClaimMutation>;
export type SubmitSubconClaimMutationResult = ApolloReactCommon.MutationResult<SubmitSubconClaimMutation>;
export type SubmitSubconClaimMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitSubconClaimMutation, SubmitSubconClaimMutationVariables>;
export const ApproveSubconClaimDocument = gql`
    mutation approveSubconClaim($input: SubcontractClaimInput!) {
  approveSubconClaim(input: $input)
}
    `;
export type ApproveSubconClaimMutationFn = ApolloReactCommon.MutationFunction<ApproveSubconClaimMutation, ApproveSubconClaimMutationVariables>;

/**
 * __useApproveSubconClaimMutation__
 *
 * To run a mutation, you first call `useApproveSubconClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveSubconClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveSubconClaimMutation, { data, loading, error }] = useApproveSubconClaimMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveSubconClaimMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApproveSubconClaimMutation, ApproveSubconClaimMutationVariables>) {
        return ApolloReactHooks.useMutation<ApproveSubconClaimMutation, ApproveSubconClaimMutationVariables>(ApproveSubconClaimDocument, baseOptions);
      }
export type ApproveSubconClaimMutationHookResult = ReturnType<typeof useApproveSubconClaimMutation>;
export type ApproveSubconClaimMutationResult = ApolloReactCommon.MutationResult<ApproveSubconClaimMutation>;
export type ApproveSubconClaimMutationOptions = ApolloReactCommon.BaseMutationOptions<ApproveSubconClaimMutation, ApproveSubconClaimMutationVariables>;
export const CancelSubconClaimDocument = gql`
    mutation cancelSubconClaim($ID: String!, $input: CancelInput!, $retentionClaim: Boolean) {
  cancelSubconClaim(ID: $ID, input: $input, retentionClaim: $retentionClaim)
}
    `;
export type CancelSubconClaimMutationFn = ApolloReactCommon.MutationFunction<CancelSubconClaimMutation, CancelSubconClaimMutationVariables>;

/**
 * __useCancelSubconClaimMutation__
 *
 * To run a mutation, you first call `useCancelSubconClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubconClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubconClaimMutation, { data, loading, error }] = useCancelSubconClaimMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *      retentionClaim: // value for 'retentionClaim'
 *   },
 * });
 */
export function useCancelSubconClaimMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelSubconClaimMutation, CancelSubconClaimMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelSubconClaimMutation, CancelSubconClaimMutationVariables>(CancelSubconClaimDocument, baseOptions);
      }
export type CancelSubconClaimMutationHookResult = ReturnType<typeof useCancelSubconClaimMutation>;
export type CancelSubconClaimMutationResult = ApolloReactCommon.MutationResult<CancelSubconClaimMutation>;
export type CancelSubconClaimMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelSubconClaimMutation, CancelSubconClaimMutationVariables>;
export const RejectSubconClaimDocument = gql`
    mutation rejectSubconClaim($ID: String!, $input: RejectInput!, $retentionClaim: Boolean) {
  rejectSubconClaim(ID: $ID, input: $input, retentionClaim: $retentionClaim)
}
    `;
export type RejectSubconClaimMutationFn = ApolloReactCommon.MutationFunction<RejectSubconClaimMutation, RejectSubconClaimMutationVariables>;

/**
 * __useRejectSubconClaimMutation__
 *
 * To run a mutation, you first call `useRejectSubconClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectSubconClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectSubconClaimMutation, { data, loading, error }] = useRejectSubconClaimMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *      retentionClaim: // value for 'retentionClaim'
 *   },
 * });
 */
export function useRejectSubconClaimMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectSubconClaimMutation, RejectSubconClaimMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectSubconClaimMutation, RejectSubconClaimMutationVariables>(RejectSubconClaimDocument, baseOptions);
      }
export type RejectSubconClaimMutationHookResult = ReturnType<typeof useRejectSubconClaimMutation>;
export type RejectSubconClaimMutationResult = ApolloReactCommon.MutationResult<RejectSubconClaimMutation>;
export type RejectSubconClaimMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectSubconClaimMutation, RejectSubconClaimMutationVariables>;
export const DeleteSubcontractClaimDocument = gql`
    mutation deleteSubcontractClaim($ID: String!) {
  deleteSubcontractClaim(ID: $ID)
}
    `;
export type DeleteSubcontractClaimMutationFn = ApolloReactCommon.MutationFunction<DeleteSubcontractClaimMutation, DeleteSubcontractClaimMutationVariables>;

/**
 * __useDeleteSubcontractClaimMutation__
 *
 * To run a mutation, you first call `useDeleteSubcontractClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubcontractClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubcontractClaimMutation, { data, loading, error }] = useDeleteSubcontractClaimMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteSubcontractClaimMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSubcontractClaimMutation, DeleteSubcontractClaimMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSubcontractClaimMutation, DeleteSubcontractClaimMutationVariables>(DeleteSubcontractClaimDocument, baseOptions);
      }
export type DeleteSubcontractClaimMutationHookResult = ReturnType<typeof useDeleteSubcontractClaimMutation>;
export type DeleteSubcontractClaimMutationResult = ApolloReactCommon.MutationResult<DeleteSubcontractClaimMutation>;
export type DeleteSubcontractClaimMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSubcontractClaimMutation, DeleteSubcontractClaimMutationVariables>;
export const GetSubconRetClaimAllocationDocument = gql`
    query getSubconRetClaimAllocation($creditID: String!) {
  getSubconRetClaimAllocation(creditID: $creditID)
}
    `;

/**
 * __useGetSubconRetClaimAllocationQuery__
 *
 * To run a query within a React component, call `useGetSubconRetClaimAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubconRetClaimAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubconRetClaimAllocationQuery({
 *   variables: {
 *      creditID: // value for 'creditID'
 *   },
 * });
 */
export function useGetSubconRetClaimAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubconRetClaimAllocationQuery, GetSubconRetClaimAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubconRetClaimAllocationQuery, GetSubconRetClaimAllocationQueryVariables>(GetSubconRetClaimAllocationDocument, baseOptions);
      }
export function useGetSubconRetClaimAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubconRetClaimAllocationQuery, GetSubconRetClaimAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubconRetClaimAllocationQuery, GetSubconRetClaimAllocationQueryVariables>(GetSubconRetClaimAllocationDocument, baseOptions);
        }
export type GetSubconRetClaimAllocationQueryHookResult = ReturnType<typeof useGetSubconRetClaimAllocationQuery>;
export type GetSubconRetClaimAllocationLazyQueryHookResult = ReturnType<typeof useGetSubconRetClaimAllocationLazyQuery>;
export type GetSubconRetClaimAllocationQueryResult = ApolloReactCommon.QueryResult<GetSubconRetClaimAllocationQuery, GetSubconRetClaimAllocationQueryVariables>;
export const GetSubcontractAdvanceDocument = gql`
    query getSubcontractAdvance($ID: String, $subcontractID: String, $subcontractorID: String, $receiptStatus: ReceiptStatus) {
  getSubcontractAdvance(ID: $ID, subcontractID: $subcontractID, subcontractorID: $subcontractorID, receiptStatus: $receiptStatus) {
    ID
    isGlExport
    isRecoupment
    modBy
    cancellationRemarks
    cancellationDate
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    bankAccountID
    bankDocAmt
    bankDetails
    receiptStatus
    subcontractorID
    contractID
    subcontractID
    subcontract {
      ID
      title
    }
    subconAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
    outstandingAmt
  }
  subconAdvancesInfo(subcontractID: $subcontractID, subcontractorID: $subcontractorID)
}
    `;

/**
 * __useGetSubcontractAdvanceQuery__
 *
 * To run a query within a React component, call `useGetSubcontractAdvanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractAdvanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractAdvanceQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *      receiptStatus: // value for 'receiptStatus'
 *   },
 * });
 */
export function useGetSubcontractAdvanceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractAdvanceQuery, GetSubcontractAdvanceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractAdvanceQuery, GetSubcontractAdvanceQueryVariables>(GetSubcontractAdvanceDocument, baseOptions);
      }
export function useGetSubcontractAdvanceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractAdvanceQuery, GetSubcontractAdvanceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractAdvanceQuery, GetSubcontractAdvanceQueryVariables>(GetSubcontractAdvanceDocument, baseOptions);
        }
export type GetSubcontractAdvanceQueryHookResult = ReturnType<typeof useGetSubcontractAdvanceQuery>;
export type GetSubcontractAdvanceLazyQueryHookResult = ReturnType<typeof useGetSubcontractAdvanceLazyQuery>;
export type GetSubcontractAdvanceQueryResult = ApolloReactCommon.QueryResult<GetSubcontractAdvanceQuery, GetSubcontractAdvanceQueryVariables>;
export const CreateSubcontractAdvanceDocument = gql`
    mutation createSubcontractAdvance($input: SubcontractPaymentInput!) {
  createSubcontractAdvance(input: $input) {
    ID
    docNo
  }
}
    `;
export type CreateSubcontractAdvanceMutationFn = ApolloReactCommon.MutationFunction<CreateSubcontractAdvanceMutation, CreateSubcontractAdvanceMutationVariables>;

/**
 * __useCreateSubcontractAdvanceMutation__
 *
 * To run a mutation, you first call `useCreateSubcontractAdvanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubcontractAdvanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubcontractAdvanceMutation, { data, loading, error }] = useCreateSubcontractAdvanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubcontractAdvanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubcontractAdvanceMutation, CreateSubcontractAdvanceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubcontractAdvanceMutation, CreateSubcontractAdvanceMutationVariables>(CreateSubcontractAdvanceDocument, baseOptions);
      }
export type CreateSubcontractAdvanceMutationHookResult = ReturnType<typeof useCreateSubcontractAdvanceMutation>;
export type CreateSubcontractAdvanceMutationResult = ApolloReactCommon.MutationResult<CreateSubcontractAdvanceMutation>;
export type CreateSubcontractAdvanceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubcontractAdvanceMutation, CreateSubcontractAdvanceMutationVariables>;
export const UpdateSubcontractAdvanceDocument = gql`
    mutation updateSubcontractAdvance($input: SubcontractPaymentInput!) {
  updateSubcontractAdvance(input: $input) {
    ID
    docNo
  }
}
    `;
export type UpdateSubcontractAdvanceMutationFn = ApolloReactCommon.MutationFunction<UpdateSubcontractAdvanceMutation, UpdateSubcontractAdvanceMutationVariables>;

/**
 * __useUpdateSubcontractAdvanceMutation__
 *
 * To run a mutation, you first call `useUpdateSubcontractAdvanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubcontractAdvanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubcontractAdvanceMutation, { data, loading, error }] = useUpdateSubcontractAdvanceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubcontractAdvanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSubcontractAdvanceMutation, UpdateSubcontractAdvanceMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSubcontractAdvanceMutation, UpdateSubcontractAdvanceMutationVariables>(UpdateSubcontractAdvanceDocument, baseOptions);
      }
export type UpdateSubcontractAdvanceMutationHookResult = ReturnType<typeof useUpdateSubcontractAdvanceMutation>;
export type UpdateSubcontractAdvanceMutationResult = ApolloReactCommon.MutationResult<UpdateSubcontractAdvanceMutation>;
export type UpdateSubcontractAdvanceMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSubcontractAdvanceMutation, UpdateSubcontractAdvanceMutationVariables>;
export const GetSubcontractPaymentDocument = gql`
    query getSubcontractPayment($ID: String, $subcontractID: String, $subcontractorID: String) {
  getSubcontractPayment(ID: $ID, subcontractID: $subcontractID, subcontractorID: $subcontractorID) {
    ID
    modBy
    isGlExport
    cancellationRemarks
    cancellationDate
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    bankAccountID
    bankDocAmt
    bankDetails
    receiptStatus
    subcontractorID
    contractID
    subcontractID
    outstandingAmt
    bankAccountID
    bankDetails
    subcontract {
      title
    }
    subconAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
  }
  subconPaymentInfo(subcontractID: $subcontractID, subcontractorID: $subcontractorID)
}
    `;

/**
 * __useGetSubcontractPaymentQuery__
 *
 * To run a query within a React component, call `useGetSubcontractPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractPaymentQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *   },
 * });
 */
export function useGetSubcontractPaymentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractPaymentQuery, GetSubcontractPaymentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractPaymentQuery, GetSubcontractPaymentQueryVariables>(GetSubcontractPaymentDocument, baseOptions);
      }
export function useGetSubcontractPaymentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractPaymentQuery, GetSubcontractPaymentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractPaymentQuery, GetSubcontractPaymentQueryVariables>(GetSubcontractPaymentDocument, baseOptions);
        }
export type GetSubcontractPaymentQueryHookResult = ReturnType<typeof useGetSubcontractPaymentQuery>;
export type GetSubcontractPaymentLazyQueryHookResult = ReturnType<typeof useGetSubcontractPaymentLazyQuery>;
export type GetSubcontractPaymentQueryResult = ApolloReactCommon.QueryResult<GetSubcontractPaymentQuery, GetSubcontractPaymentQueryVariables>;
export const CreateSubcontractPaymentDocument = gql`
    mutation createSubcontractPayment($input: SubcontractPaymentInput!) {
  createSubcontractPayment(input: $input) {
    ID
  }
}
    `;
export type CreateSubcontractPaymentMutationFn = ApolloReactCommon.MutationFunction<CreateSubcontractPaymentMutation, CreateSubcontractPaymentMutationVariables>;

/**
 * __useCreateSubcontractPaymentMutation__
 *
 * To run a mutation, you first call `useCreateSubcontractPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubcontractPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubcontractPaymentMutation, { data, loading, error }] = useCreateSubcontractPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubcontractPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubcontractPaymentMutation, CreateSubcontractPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubcontractPaymentMutation, CreateSubcontractPaymentMutationVariables>(CreateSubcontractPaymentDocument, baseOptions);
      }
export type CreateSubcontractPaymentMutationHookResult = ReturnType<typeof useCreateSubcontractPaymentMutation>;
export type CreateSubcontractPaymentMutationResult = ApolloReactCommon.MutationResult<CreateSubcontractPaymentMutation>;
export type CreateSubcontractPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubcontractPaymentMutation, CreateSubcontractPaymentMutationVariables>;
export const UpdateSubcontractPaymentDocument = gql`
    mutation updateSubcontractPayment($input: SubcontractPaymentInput!) {
  updateSubcontractPayment(input: $input) {
    ID
  }
}
    `;
export type UpdateSubcontractPaymentMutationFn = ApolloReactCommon.MutationFunction<UpdateSubcontractPaymentMutation, UpdateSubcontractPaymentMutationVariables>;

/**
 * __useUpdateSubcontractPaymentMutation__
 *
 * To run a mutation, you first call `useUpdateSubcontractPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubcontractPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubcontractPaymentMutation, { data, loading, error }] = useUpdateSubcontractPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubcontractPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSubcontractPaymentMutation, UpdateSubcontractPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSubcontractPaymentMutation, UpdateSubcontractPaymentMutationVariables>(UpdateSubcontractPaymentDocument, baseOptions);
      }
export type UpdateSubcontractPaymentMutationHookResult = ReturnType<typeof useUpdateSubcontractPaymentMutation>;
export type UpdateSubcontractPaymentMutationResult = ApolloReactCommon.MutationResult<UpdateSubcontractPaymentMutation>;
export type UpdateSubcontractPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSubcontractPaymentMutation, UpdateSubcontractPaymentMutationVariables>;
export const GetSubcontractRefundDocument = gql`
    query getSubcontractRefund($ID: String, $subcontractID: String, $subcontractorID: String) {
  getSubcontractRefund(ID: $ID, subcontractID: $subcontractID, subcontractorID: $subcontractorID) {
    ID
    modBy
    isGlExport
    cancellationRemarks
    cancellationDate
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    bankAccountID
    bankDocAmt
    bankDetails
    receiptStatus
    subcontractorID
    contractID
    subcontractID
    outstandingAmt
    subcontract {
      title
    }
    subconAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
  }
  subconRefundsInfo(subcontractID: $subcontractID, subcontractorID: $subcontractorID)
}
    `;

/**
 * __useGetSubcontractRefundQuery__
 *
 * To run a query within a React component, call `useGetSubcontractRefundQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractRefundQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractRefundQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *   },
 * });
 */
export function useGetSubcontractRefundQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractRefundQuery, GetSubcontractRefundQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractRefundQuery, GetSubcontractRefundQueryVariables>(GetSubcontractRefundDocument, baseOptions);
      }
export function useGetSubcontractRefundLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractRefundQuery, GetSubcontractRefundQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractRefundQuery, GetSubcontractRefundQueryVariables>(GetSubcontractRefundDocument, baseOptions);
        }
export type GetSubcontractRefundQueryHookResult = ReturnType<typeof useGetSubcontractRefundQuery>;
export type GetSubcontractRefundLazyQueryHookResult = ReturnType<typeof useGetSubcontractRefundLazyQuery>;
export type GetSubcontractRefundQueryResult = ApolloReactCommon.QueryResult<GetSubcontractRefundQuery, GetSubcontractRefundQueryVariables>;
export const CreateSubcontractRefundDocument = gql`
    mutation createSubcontractRefund($input: SubcontractPaymentInput!) {
  createSubcontractRefund(input: $input) {
    ID
    docNo
  }
}
    `;
export type CreateSubcontractRefundMutationFn = ApolloReactCommon.MutationFunction<CreateSubcontractRefundMutation, CreateSubcontractRefundMutationVariables>;

/**
 * __useCreateSubcontractRefundMutation__
 *
 * To run a mutation, you first call `useCreateSubcontractRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubcontractRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubcontractRefundMutation, { data, loading, error }] = useCreateSubcontractRefundMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubcontractRefundMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubcontractRefundMutation, CreateSubcontractRefundMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubcontractRefundMutation, CreateSubcontractRefundMutationVariables>(CreateSubcontractRefundDocument, baseOptions);
      }
export type CreateSubcontractRefundMutationHookResult = ReturnType<typeof useCreateSubcontractRefundMutation>;
export type CreateSubcontractRefundMutationResult = ApolloReactCommon.MutationResult<CreateSubcontractRefundMutation>;
export type CreateSubcontractRefundMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubcontractRefundMutation, CreateSubcontractRefundMutationVariables>;
export const UpdateSubcontractRefundDocument = gql`
    mutation updateSubcontractRefund($input: SubcontractPaymentInput!) {
  updateSubcontractRefund(input: $input) {
    ID
    docNo
  }
}
    `;
export type UpdateSubcontractRefundMutationFn = ApolloReactCommon.MutationFunction<UpdateSubcontractRefundMutation, UpdateSubcontractRefundMutationVariables>;

/**
 * __useUpdateSubcontractRefundMutation__
 *
 * To run a mutation, you first call `useUpdateSubcontractRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubcontractRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubcontractRefundMutation, { data, loading, error }] = useUpdateSubcontractRefundMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubcontractRefundMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSubcontractRefundMutation, UpdateSubcontractRefundMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSubcontractRefundMutation, UpdateSubcontractRefundMutationVariables>(UpdateSubcontractRefundDocument, baseOptions);
      }
export type UpdateSubcontractRefundMutationHookResult = ReturnType<typeof useUpdateSubcontractRefundMutation>;
export type UpdateSubcontractRefundMutationResult = ApolloReactCommon.MutationResult<UpdateSubcontractRefundMutation>;
export type UpdateSubcontractRefundMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSubcontractRefundMutation, UpdateSubcontractRefundMutationVariables>;
export const GetSubcontractPaymentTableDocument = gql`
    query getSubcontractPaymentTable($subcontractorID: String, $subcontractID: String) {
  getSubcontractClaim(subcontractorID: $subcontractorID, subcontractID: $subcontractID) {
    ID
    subcontractID
    docNo
    docRef
    docDate
    docAmt
    baseAmt
    description
    outstandingAmt
  }
  getSubcontractDN(subcontractorID: $subcontractorID, subcontractID: $subcontractID) {
    ID
    subcontractID
    docNo
    docRef
    docDate
    docAmt
    baseAmt
    description
    outstandingAmt
  }
}
    `;

/**
 * __useGetSubcontractPaymentTableQuery__
 *
 * To run a query within a React component, call `useGetSubcontractPaymentTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractPaymentTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractPaymentTableQuery({
 *   variables: {
 *      subcontractorID: // value for 'subcontractorID'
 *      subcontractID: // value for 'subcontractID'
 *   },
 * });
 */
export function useGetSubcontractPaymentTableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractPaymentTableQuery, GetSubcontractPaymentTableQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractPaymentTableQuery, GetSubcontractPaymentTableQueryVariables>(GetSubcontractPaymentTableDocument, baseOptions);
      }
export function useGetSubcontractPaymentTableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractPaymentTableQuery, GetSubcontractPaymentTableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractPaymentTableQuery, GetSubcontractPaymentTableQueryVariables>(GetSubcontractPaymentTableDocument, baseOptions);
        }
export type GetSubcontractPaymentTableQueryHookResult = ReturnType<typeof useGetSubcontractPaymentTableQuery>;
export type GetSubcontractPaymentTableLazyQueryHookResult = ReturnType<typeof useGetSubcontractPaymentTableLazyQuery>;
export type GetSubcontractPaymentTableQueryResult = ApolloReactCommon.QueryResult<GetSubcontractPaymentTableQuery, GetSubcontractPaymentTableQueryVariables>;
export const MakeSubconPaymentDocument = gql`
    mutation makeSubconPayment($input: SubcontractPaymentInput!, $ledger: [LedgerInput!]!) {
  makeSubconPayment(input: $input, ledger: $ledger) {
    ID
    docNo
  }
}
    `;
export type MakeSubconPaymentMutationFn = ApolloReactCommon.MutationFunction<MakeSubconPaymentMutation, MakeSubconPaymentMutationVariables>;

/**
 * __useMakeSubconPaymentMutation__
 *
 * To run a mutation, you first call `useMakeSubconPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeSubconPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeSubconPaymentMutation, { data, loading, error }] = useMakeSubconPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ledger: // value for 'ledger'
 *   },
 * });
 */
export function useMakeSubconPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MakeSubconPaymentMutation, MakeSubconPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<MakeSubconPaymentMutation, MakeSubconPaymentMutationVariables>(MakeSubconPaymentDocument, baseOptions);
      }
export type MakeSubconPaymentMutationHookResult = ReturnType<typeof useMakeSubconPaymentMutation>;
export type MakeSubconPaymentMutationResult = ApolloReactCommon.MutationResult<MakeSubconPaymentMutation>;
export type MakeSubconPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<MakeSubconPaymentMutation, MakeSubconPaymentMutationVariables>;
export const MakeSubconAllocationDocument = gql`
    mutation makeSubconAllocation($ledger: [LedgerInput!]!) {
  makeSubconAllocation(ledger: $ledger)
}
    `;
export type MakeSubconAllocationMutationFn = ApolloReactCommon.MutationFunction<MakeSubconAllocationMutation, MakeSubconAllocationMutationVariables>;

/**
 * __useMakeSubconAllocationMutation__
 *
 * To run a mutation, you first call `useMakeSubconAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeSubconAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeSubconAllocationMutation, { data, loading, error }] = useMakeSubconAllocationMutation({
 *   variables: {
 *      ledger: // value for 'ledger'
 *   },
 * });
 */
export function useMakeSubconAllocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MakeSubconAllocationMutation, MakeSubconAllocationMutationVariables>) {
        return ApolloReactHooks.useMutation<MakeSubconAllocationMutation, MakeSubconAllocationMutationVariables>(MakeSubconAllocationDocument, baseOptions);
      }
export type MakeSubconAllocationMutationHookResult = ReturnType<typeof useMakeSubconAllocationMutation>;
export type MakeSubconAllocationMutationResult = ApolloReactCommon.MutationResult<MakeSubconAllocationMutation>;
export type MakeSubconAllocationMutationOptions = ApolloReactCommon.BaseMutationOptions<MakeSubconAllocationMutation, MakeSubconAllocationMutationVariables>;
export const ResetSubcontractAllocationDocument = gql`
    mutation resetSubcontractAllocation($creditID: String, $debitID: String) {
  resetSubcontractAllocation(creditID: $creditID, debitID: $debitID)
}
    `;
export type ResetSubcontractAllocationMutationFn = ApolloReactCommon.MutationFunction<ResetSubcontractAllocationMutation, ResetSubcontractAllocationMutationVariables>;

/**
 * __useResetSubcontractAllocationMutation__
 *
 * To run a mutation, you first call `useResetSubcontractAllocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetSubcontractAllocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetSubcontractAllocationMutation, { data, loading, error }] = useResetSubcontractAllocationMutation({
 *   variables: {
 *      creditID: // value for 'creditID'
 *      debitID: // value for 'debitID'
 *   },
 * });
 */
export function useResetSubcontractAllocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetSubcontractAllocationMutation, ResetSubcontractAllocationMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetSubcontractAllocationMutation, ResetSubcontractAllocationMutationVariables>(ResetSubcontractAllocationDocument, baseOptions);
      }
export type ResetSubcontractAllocationMutationHookResult = ReturnType<typeof useResetSubcontractAllocationMutation>;
export type ResetSubcontractAllocationMutationResult = ApolloReactCommon.MutationResult<ResetSubcontractAllocationMutation>;
export type ResetSubcontractAllocationMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetSubcontractAllocationMutation, ResetSubcontractAllocationMutationVariables>;
export const GetSubconAdvanceAllocationDocument = gql`
    query getSubconAdvanceAllocation($debitID: String!) {
  getSubconAdvanceAllocation(debitID: $debitID)
}
    `;

/**
 * __useGetSubconAdvanceAllocationQuery__
 *
 * To run a query within a React component, call `useGetSubconAdvanceAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubconAdvanceAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubconAdvanceAllocationQuery({
 *   variables: {
 *      debitID: // value for 'debitID'
 *   },
 * });
 */
export function useGetSubconAdvanceAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubconAdvanceAllocationQuery, GetSubconAdvanceAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubconAdvanceAllocationQuery, GetSubconAdvanceAllocationQueryVariables>(GetSubconAdvanceAllocationDocument, baseOptions);
      }
export function useGetSubconAdvanceAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubconAdvanceAllocationQuery, GetSubconAdvanceAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubconAdvanceAllocationQuery, GetSubconAdvanceAllocationQueryVariables>(GetSubconAdvanceAllocationDocument, baseOptions);
        }
export type GetSubconAdvanceAllocationQueryHookResult = ReturnType<typeof useGetSubconAdvanceAllocationQuery>;
export type GetSubconAdvanceAllocationLazyQueryHookResult = ReturnType<typeof useGetSubconAdvanceAllocationLazyQuery>;
export type GetSubconAdvanceAllocationQueryResult = ApolloReactCommon.QueryResult<GetSubconAdvanceAllocationQuery, GetSubconAdvanceAllocationQueryVariables>;
export const GetSubconPaymentAllocationDocument = gql`
    query getSubconPaymentAllocation($debitID: String, $subcontractID: String) {
  getSubconPaymentAllocation(debitID: $debitID, subcontractID: $subcontractID)
}
    `;

/**
 * __useGetSubconPaymentAllocationQuery__
 *
 * To run a query within a React component, call `useGetSubconPaymentAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubconPaymentAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubconPaymentAllocationQuery({
 *   variables: {
 *      debitID: // value for 'debitID'
 *      subcontractID: // value for 'subcontractID'
 *   },
 * });
 */
export function useGetSubconPaymentAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubconPaymentAllocationQuery, GetSubconPaymentAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubconPaymentAllocationQuery, GetSubconPaymentAllocationQueryVariables>(GetSubconPaymentAllocationDocument, baseOptions);
      }
export function useGetSubconPaymentAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubconPaymentAllocationQuery, GetSubconPaymentAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubconPaymentAllocationQuery, GetSubconPaymentAllocationQueryVariables>(GetSubconPaymentAllocationDocument, baseOptions);
        }
export type GetSubconPaymentAllocationQueryHookResult = ReturnType<typeof useGetSubconPaymentAllocationQuery>;
export type GetSubconPaymentAllocationLazyQueryHookResult = ReturnType<typeof useGetSubconPaymentAllocationLazyQuery>;
export type GetSubconPaymentAllocationQueryResult = ApolloReactCommon.QueryResult<GetSubconPaymentAllocationQuery, GetSubconPaymentAllocationQueryVariables>;
export const GetSubconRefundAllocationDocument = gql`
    query getSubconRefundAllocation($creditID: String!) {
  getSubconRefundAllocation(creditID: $creditID)
}
    `;

/**
 * __useGetSubconRefundAllocationQuery__
 *
 * To run a query within a React component, call `useGetSubconRefundAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubconRefundAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubconRefundAllocationQuery({
 *   variables: {
 *      creditID: // value for 'creditID'
 *   },
 * });
 */
export function useGetSubconRefundAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubconRefundAllocationQuery, GetSubconRefundAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubconRefundAllocationQuery, GetSubconRefundAllocationQueryVariables>(GetSubconRefundAllocationDocument, baseOptions);
      }
export function useGetSubconRefundAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubconRefundAllocationQuery, GetSubconRefundAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubconRefundAllocationQuery, GetSubconRefundAllocationQueryVariables>(GetSubconRefundAllocationDocument, baseOptions);
        }
export type GetSubconRefundAllocationQueryHookResult = ReturnType<typeof useGetSubconRefundAllocationQuery>;
export type GetSubconRefundAllocationLazyQueryHookResult = ReturnType<typeof useGetSubconRefundAllocationLazyQuery>;
export type GetSubconRefundAllocationQueryResult = ApolloReactCommon.QueryResult<GetSubconRefundAllocationQuery, GetSubconRefundAllocationQueryVariables>;
export const CancelSubconAdvanceDocument = gql`
    mutation cancelSubconAdvance($ID: String!, $input: CancelInput!) {
  cancelSubconAdvance(ID: $ID, input: $input)
}
    `;
export type CancelSubconAdvanceMutationFn = ApolloReactCommon.MutationFunction<CancelSubconAdvanceMutation, CancelSubconAdvanceMutationVariables>;

/**
 * __useCancelSubconAdvanceMutation__
 *
 * To run a mutation, you first call `useCancelSubconAdvanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubconAdvanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubconAdvanceMutation, { data, loading, error }] = useCancelSubconAdvanceMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSubconAdvanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelSubconAdvanceMutation, CancelSubconAdvanceMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelSubconAdvanceMutation, CancelSubconAdvanceMutationVariables>(CancelSubconAdvanceDocument, baseOptions);
      }
export type CancelSubconAdvanceMutationHookResult = ReturnType<typeof useCancelSubconAdvanceMutation>;
export type CancelSubconAdvanceMutationResult = ApolloReactCommon.MutationResult<CancelSubconAdvanceMutation>;
export type CancelSubconAdvanceMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelSubconAdvanceMutation, CancelSubconAdvanceMutationVariables>;
export const CancelSubconPaymentDocument = gql`
    mutation cancelSubconPayment($ID: String!, $input: CancelInput!) {
  cancelSubconPayment(ID: $ID, input: $input)
}
    `;
export type CancelSubconPaymentMutationFn = ApolloReactCommon.MutationFunction<CancelSubconPaymentMutation, CancelSubconPaymentMutationVariables>;

/**
 * __useCancelSubconPaymentMutation__
 *
 * To run a mutation, you first call `useCancelSubconPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubconPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubconPaymentMutation, { data, loading, error }] = useCancelSubconPaymentMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSubconPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelSubconPaymentMutation, CancelSubconPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelSubconPaymentMutation, CancelSubconPaymentMutationVariables>(CancelSubconPaymentDocument, baseOptions);
      }
export type CancelSubconPaymentMutationHookResult = ReturnType<typeof useCancelSubconPaymentMutation>;
export type CancelSubconPaymentMutationResult = ApolloReactCommon.MutationResult<CancelSubconPaymentMutation>;
export type CancelSubconPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelSubconPaymentMutation, CancelSubconPaymentMutationVariables>;
export const CancelSubconRefundDocument = gql`
    mutation cancelSubconRefund($ID: String!, $input: CancelInput!) {
  cancelSubconRefund(ID: $ID, input: $input)
}
    `;
export type CancelSubconRefundMutationFn = ApolloReactCommon.MutationFunction<CancelSubconRefundMutation, CancelSubconRefundMutationVariables>;

/**
 * __useCancelSubconRefundMutation__
 *
 * To run a mutation, you first call `useCancelSubconRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubconRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubconRefundMutation, { data, loading, error }] = useCancelSubconRefundMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSubconRefundMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelSubconRefundMutation, CancelSubconRefundMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelSubconRefundMutation, CancelSubconRefundMutationVariables>(CancelSubconRefundDocument, baseOptions);
      }
export type CancelSubconRefundMutationHookResult = ReturnType<typeof useCancelSubconRefundMutation>;
export type CancelSubconRefundMutationResult = ApolloReactCommon.MutationResult<CancelSubconRefundMutation>;
export type CancelSubconRefundMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelSubconRefundMutation, CancelSubconRefundMutationVariables>;
export const DeleteSubcontractAdvanceDocument = gql`
    mutation deleteSubcontractAdvance($ID: String!) {
  deleteSubcontractAdvance(ID: $ID)
}
    `;
export type DeleteSubcontractAdvanceMutationFn = ApolloReactCommon.MutationFunction<DeleteSubcontractAdvanceMutation, DeleteSubcontractAdvanceMutationVariables>;

/**
 * __useDeleteSubcontractAdvanceMutation__
 *
 * To run a mutation, you first call `useDeleteSubcontractAdvanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubcontractAdvanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubcontractAdvanceMutation, { data, loading, error }] = useDeleteSubcontractAdvanceMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteSubcontractAdvanceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSubcontractAdvanceMutation, DeleteSubcontractAdvanceMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSubcontractAdvanceMutation, DeleteSubcontractAdvanceMutationVariables>(DeleteSubcontractAdvanceDocument, baseOptions);
      }
export type DeleteSubcontractAdvanceMutationHookResult = ReturnType<typeof useDeleteSubcontractAdvanceMutation>;
export type DeleteSubcontractAdvanceMutationResult = ApolloReactCommon.MutationResult<DeleteSubcontractAdvanceMutation>;
export type DeleteSubcontractAdvanceMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSubcontractAdvanceMutation, DeleteSubcontractAdvanceMutationVariables>;
export const DeleteSubcontractRefundDocument = gql`
    mutation deleteSubcontractRefund($ID: String!) {
  deleteSubcontractRefund(ID: $ID)
}
    `;
export type DeleteSubcontractRefundMutationFn = ApolloReactCommon.MutationFunction<DeleteSubcontractRefundMutation, DeleteSubcontractRefundMutationVariables>;

/**
 * __useDeleteSubcontractRefundMutation__
 *
 * To run a mutation, you first call `useDeleteSubcontractRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubcontractRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubcontractRefundMutation, { data, loading, error }] = useDeleteSubcontractRefundMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteSubcontractRefundMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSubcontractRefundMutation, DeleteSubcontractRefundMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSubcontractRefundMutation, DeleteSubcontractRefundMutationVariables>(DeleteSubcontractRefundDocument, baseOptions);
      }
export type DeleteSubcontractRefundMutationHookResult = ReturnType<typeof useDeleteSubcontractRefundMutation>;
export type DeleteSubcontractRefundMutationResult = ApolloReactCommon.MutationResult<DeleteSubcontractRefundMutation>;
export type DeleteSubcontractRefundMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSubcontractRefundMutation, DeleteSubcontractRefundMutationVariables>;
export const DeleteSubcontractPaymentDocument = gql`
    mutation deleteSubcontractPayment($ID: String!) {
  deleteSubcontractPayment(ID: $ID)
}
    `;
export type DeleteSubcontractPaymentMutationFn = ApolloReactCommon.MutationFunction<DeleteSubcontractPaymentMutation, DeleteSubcontractPaymentMutationVariables>;

/**
 * __useDeleteSubcontractPaymentMutation__
 *
 * To run a mutation, you first call `useDeleteSubcontractPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubcontractPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubcontractPaymentMutation, { data, loading, error }] = useDeleteSubcontractPaymentMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteSubcontractPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSubcontractPaymentMutation, DeleteSubcontractPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSubcontractPaymentMutation, DeleteSubcontractPaymentMutationVariables>(DeleteSubcontractPaymentDocument, baseOptions);
      }
export type DeleteSubcontractPaymentMutationHookResult = ReturnType<typeof useDeleteSubcontractPaymentMutation>;
export type DeleteSubcontractPaymentMutationResult = ApolloReactCommon.MutationResult<DeleteSubcontractPaymentMutation>;
export type DeleteSubcontractPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSubcontractPaymentMutation, DeleteSubcontractPaymentMutationVariables>;
export const GetSubcontractMiscInvoiceDocument = gql`
    query getSubcontractMiscInvoice($ID: String, $subcontractID: String, $subcontractorID: String) {
  getSubcontractMiscInvoice(ID: $ID, subcontractID: $subcontractID, subcontractorID: $subcontractorID) {
    ID
    modBy
    docNo
    isGlExport
    docRef
    cancellationDate
    cancellationRemarks
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    transactionStatus
    subcontractorID
    contractID
    subcontractID
    outstandingAmt
    subconAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    subcontract {
      title
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
  }
}
    `;

/**
 * __useGetSubcontractMiscInvoiceQuery__
 *
 * To run a query within a React component, call `useGetSubcontractMiscInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractMiscInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractMiscInvoiceQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *   },
 * });
 */
export function useGetSubcontractMiscInvoiceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractMiscInvoiceQuery, GetSubcontractMiscInvoiceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractMiscInvoiceQuery, GetSubcontractMiscInvoiceQueryVariables>(GetSubcontractMiscInvoiceDocument, baseOptions);
      }
export function useGetSubcontractMiscInvoiceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractMiscInvoiceQuery, GetSubcontractMiscInvoiceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractMiscInvoiceQuery, GetSubcontractMiscInvoiceQueryVariables>(GetSubcontractMiscInvoiceDocument, baseOptions);
        }
export type GetSubcontractMiscInvoiceQueryHookResult = ReturnType<typeof useGetSubcontractMiscInvoiceQuery>;
export type GetSubcontractMiscInvoiceLazyQueryHookResult = ReturnType<typeof useGetSubcontractMiscInvoiceLazyQuery>;
export type GetSubcontractMiscInvoiceQueryResult = ApolloReactCommon.QueryResult<GetSubcontractMiscInvoiceQuery, GetSubcontractMiscInvoiceQueryVariables>;
export const CreateSubcontractMiscInvoiceDocument = gql`
    mutation createSubcontractMiscInvoice($input: SubcontractTransactionInput!) {
  createSubcontractMiscInvoice(input: $input) {
    accountID
    ID
    docNo
  }
}
    `;
export type CreateSubcontractMiscInvoiceMutationFn = ApolloReactCommon.MutationFunction<CreateSubcontractMiscInvoiceMutation, CreateSubcontractMiscInvoiceMutationVariables>;

/**
 * __useCreateSubcontractMiscInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateSubcontractMiscInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubcontractMiscInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubcontractMiscInvoiceMutation, { data, loading, error }] = useCreateSubcontractMiscInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubcontractMiscInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubcontractMiscInvoiceMutation, CreateSubcontractMiscInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubcontractMiscInvoiceMutation, CreateSubcontractMiscInvoiceMutationVariables>(CreateSubcontractMiscInvoiceDocument, baseOptions);
      }
export type CreateSubcontractMiscInvoiceMutationHookResult = ReturnType<typeof useCreateSubcontractMiscInvoiceMutation>;
export type CreateSubcontractMiscInvoiceMutationResult = ApolloReactCommon.MutationResult<CreateSubcontractMiscInvoiceMutation>;
export type CreateSubcontractMiscInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubcontractMiscInvoiceMutation, CreateSubcontractMiscInvoiceMutationVariables>;
export const UpdateSubcontractMiscInvoiceDocument = gql`
    mutation updateSubcontractMiscInvoice($input: SubcontractTransactionInput!) {
  updateSubcontractMiscInvoice(input: $input) {
    accountID
    ID
    docNo
  }
}
    `;
export type UpdateSubcontractMiscInvoiceMutationFn = ApolloReactCommon.MutationFunction<UpdateSubcontractMiscInvoiceMutation, UpdateSubcontractMiscInvoiceMutationVariables>;

/**
 * __useUpdateSubcontractMiscInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateSubcontractMiscInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubcontractMiscInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubcontractMiscInvoiceMutation, { data, loading, error }] = useUpdateSubcontractMiscInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubcontractMiscInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSubcontractMiscInvoiceMutation, UpdateSubcontractMiscInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSubcontractMiscInvoiceMutation, UpdateSubcontractMiscInvoiceMutationVariables>(UpdateSubcontractMiscInvoiceDocument, baseOptions);
      }
export type UpdateSubcontractMiscInvoiceMutationHookResult = ReturnType<typeof useUpdateSubcontractMiscInvoiceMutation>;
export type UpdateSubcontractMiscInvoiceMutationResult = ApolloReactCommon.MutationResult<UpdateSubcontractMiscInvoiceMutation>;
export type UpdateSubcontractMiscInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSubcontractMiscInvoiceMutation, UpdateSubcontractMiscInvoiceMutationVariables>;
export const GetSubcontractDnDocument = gql`
    query getSubcontractDN($ID: String, $subcontractID: String, $subcontractorID: String) {
  getSubcontractDN(ID: $ID, subcontractID: $subcontractID, subcontractorID: $subcontractorID) {
    ID
    isGlExport
    modBy
    docNo
    docRef
    cancellationDate
    cancellationRemarks
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    transactionStatus
    subcontractorID
    contractID
    subcontractID
    outstandingAmt
    deductionTypeID
    deductionType {
      ID
      code
      description
    }
    subconAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    subcontract {
      title
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
  }
  subconDNInfo(subcontractID: $subcontractID, subcontractorID: $subcontractorID)
}
    `;

/**
 * __useGetSubcontractDnQuery__
 *
 * To run a query within a React component, call `useGetSubcontractDnQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractDnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractDnQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *   },
 * });
 */
export function useGetSubcontractDnQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractDnQuery, GetSubcontractDnQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractDnQuery, GetSubcontractDnQueryVariables>(GetSubcontractDnDocument, baseOptions);
      }
export function useGetSubcontractDnLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractDnQuery, GetSubcontractDnQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractDnQuery, GetSubcontractDnQueryVariables>(GetSubcontractDnDocument, baseOptions);
        }
export type GetSubcontractDnQueryHookResult = ReturnType<typeof useGetSubcontractDnQuery>;
export type GetSubcontractDnLazyQueryHookResult = ReturnType<typeof useGetSubcontractDnLazyQuery>;
export type GetSubcontractDnQueryResult = ApolloReactCommon.QueryResult<GetSubcontractDnQuery, GetSubcontractDnQueryVariables>;
export const CreateSubcontractDnDocument = gql`
    mutation createSubcontractDN($input: SubcontractTransactionInput!) {
  createSubcontractDN(input: $input) {
    accountID
    ID
  }
}
    `;
export type CreateSubcontractDnMutationFn = ApolloReactCommon.MutationFunction<CreateSubcontractDnMutation, CreateSubcontractDnMutationVariables>;

/**
 * __useCreateSubcontractDnMutation__
 *
 * To run a mutation, you first call `useCreateSubcontractDnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubcontractDnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubcontractDnMutation, { data, loading, error }] = useCreateSubcontractDnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubcontractDnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubcontractDnMutation, CreateSubcontractDnMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubcontractDnMutation, CreateSubcontractDnMutationVariables>(CreateSubcontractDnDocument, baseOptions);
      }
export type CreateSubcontractDnMutationHookResult = ReturnType<typeof useCreateSubcontractDnMutation>;
export type CreateSubcontractDnMutationResult = ApolloReactCommon.MutationResult<CreateSubcontractDnMutation>;
export type CreateSubcontractDnMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubcontractDnMutation, CreateSubcontractDnMutationVariables>;
export const UpdateSubcontractDnDocument = gql`
    mutation updateSubcontractDN($input: SubcontractTransactionInput!) {
  updateSubcontractDN(input: $input) {
    accountID
    ID
  }
}
    `;
export type UpdateSubcontractDnMutationFn = ApolloReactCommon.MutationFunction<UpdateSubcontractDnMutation, UpdateSubcontractDnMutationVariables>;

/**
 * __useUpdateSubcontractDnMutation__
 *
 * To run a mutation, you first call `useUpdateSubcontractDnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubcontractDnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubcontractDnMutation, { data, loading, error }] = useUpdateSubcontractDnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubcontractDnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSubcontractDnMutation, UpdateSubcontractDnMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSubcontractDnMutation, UpdateSubcontractDnMutationVariables>(UpdateSubcontractDnDocument, baseOptions);
      }
export type UpdateSubcontractDnMutationHookResult = ReturnType<typeof useUpdateSubcontractDnMutation>;
export type UpdateSubcontractDnMutationResult = ApolloReactCommon.MutationResult<UpdateSubcontractDnMutation>;
export type UpdateSubcontractDnMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSubcontractDnMutation, UpdateSubcontractDnMutationVariables>;
export const GetSubcontractCnDocument = gql`
    query getSubcontractCN($ID: String, $subcontractID: String, $subcontractorID: String) {
  getSubcontractCN(ID: $ID, subcontractID: $subcontractID, subcontractorID: $subcontractorID) {
    ID
    isGlExport
    modBy
    cancellationDate
    cancellationRemarks
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    transactionStatus
    subcontractorID
    contractID
    subcontractID
    outstandingAmt
    deductionTypeID
    deductionType {
      ID
      code
      description
    }
    subcontract {
      title
    }
    subconAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
  }
  subconCNInfo(subcontractID: $subcontractID, subcontractorID: $subcontractorID)
}
    `;

/**
 * __useGetSubcontractCnQuery__
 *
 * To run a query within a React component, call `useGetSubcontractCnQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractCnQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractCnQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *   },
 * });
 */
export function useGetSubcontractCnQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractCnQuery, GetSubcontractCnQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractCnQuery, GetSubcontractCnQueryVariables>(GetSubcontractCnDocument, baseOptions);
      }
export function useGetSubcontractCnLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractCnQuery, GetSubcontractCnQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractCnQuery, GetSubcontractCnQueryVariables>(GetSubcontractCnDocument, baseOptions);
        }
export type GetSubcontractCnQueryHookResult = ReturnType<typeof useGetSubcontractCnQuery>;
export type GetSubcontractCnLazyQueryHookResult = ReturnType<typeof useGetSubcontractCnLazyQuery>;
export type GetSubcontractCnQueryResult = ApolloReactCommon.QueryResult<GetSubcontractCnQuery, GetSubcontractCnQueryVariables>;
export const CreateSubcontractCnDocument = gql`
    mutation createSubcontractCN($input: SubcontractTransactionInput!) {
  createSubcontractCN(input: $input) {
    accountID
    ID
  }
}
    `;
export type CreateSubcontractCnMutationFn = ApolloReactCommon.MutationFunction<CreateSubcontractCnMutation, CreateSubcontractCnMutationVariables>;

/**
 * __useCreateSubcontractCnMutation__
 *
 * To run a mutation, you first call `useCreateSubcontractCnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubcontractCnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubcontractCnMutation, { data, loading, error }] = useCreateSubcontractCnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubcontractCnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubcontractCnMutation, CreateSubcontractCnMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubcontractCnMutation, CreateSubcontractCnMutationVariables>(CreateSubcontractCnDocument, baseOptions);
      }
export type CreateSubcontractCnMutationHookResult = ReturnType<typeof useCreateSubcontractCnMutation>;
export type CreateSubcontractCnMutationResult = ApolloReactCommon.MutationResult<CreateSubcontractCnMutation>;
export type CreateSubcontractCnMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubcontractCnMutation, CreateSubcontractCnMutationVariables>;
export const UpdateSubcontractCnDocument = gql`
    mutation updateSubcontractCN($input: SubcontractTransactionInput!) {
  updateSubcontractCN(input: $input) {
    accountID
    ID
  }
}
    `;
export type UpdateSubcontractCnMutationFn = ApolloReactCommon.MutationFunction<UpdateSubcontractCnMutation, UpdateSubcontractCnMutationVariables>;

/**
 * __useUpdateSubcontractCnMutation__
 *
 * To run a mutation, you first call `useUpdateSubcontractCnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubcontractCnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubcontractCnMutation, { data, loading, error }] = useUpdateSubcontractCnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubcontractCnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSubcontractCnMutation, UpdateSubcontractCnMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSubcontractCnMutation, UpdateSubcontractCnMutationVariables>(UpdateSubcontractCnDocument, baseOptions);
      }
export type UpdateSubcontractCnMutationHookResult = ReturnType<typeof useUpdateSubcontractCnMutation>;
export type UpdateSubcontractCnMutationResult = ApolloReactCommon.MutationResult<UpdateSubcontractCnMutation>;
export type UpdateSubcontractCnMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSubcontractCnMutation, UpdateSubcontractCnMutationVariables>;
export const GetDNtoSubconDocument = gql`
    query getDNtoSubcon($ID: String, $subcontractID: String, $subcontractorID: String) {
  getDNtoSubcon(ID: $ID, subcontractID: $subcontractID, subcontractorID: $subcontractorID) {
    ID
    modBy
    isGlExport
    cancellationDate
    cancellationRemarks
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    transactionStatus
    contractID
    subcontractID
    outstandingAmt
    deductionTypeID
    deductionType {
      ID
      code
      description
    }
    subcontract {
      title
      siteID
    }
    subconAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
  }
  DNtoSubconInfo(subcontractID: $subcontractID, subcontractorID: $subcontractorID)
}
    `;

/**
 * __useGetDNtoSubconQuery__
 *
 * To run a query within a React component, call `useGetDNtoSubconQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDNtoSubconQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDNtoSubconQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *   },
 * });
 */
export function useGetDNtoSubconQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDNtoSubconQuery, GetDNtoSubconQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDNtoSubconQuery, GetDNtoSubconQueryVariables>(GetDNtoSubconDocument, baseOptions);
      }
export function useGetDNtoSubconLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDNtoSubconQuery, GetDNtoSubconQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDNtoSubconQuery, GetDNtoSubconQueryVariables>(GetDNtoSubconDocument, baseOptions);
        }
export type GetDNtoSubconQueryHookResult = ReturnType<typeof useGetDNtoSubconQuery>;
export type GetDNtoSubconLazyQueryHookResult = ReturnType<typeof useGetDNtoSubconLazyQuery>;
export type GetDNtoSubconQueryResult = ApolloReactCommon.QueryResult<GetDNtoSubconQuery, GetDNtoSubconQueryVariables>;
export const CreateDNtoSubconDocument = gql`
    mutation createDNtoSubcon($input: SubcontractTransactionInput!) {
  createDNtoSubcon(input: $input) {
    accountID
    ID
    docNo
  }
}
    `;
export type CreateDNtoSubconMutationFn = ApolloReactCommon.MutationFunction<CreateDNtoSubconMutation, CreateDNtoSubconMutationVariables>;

/**
 * __useCreateDNtoSubconMutation__
 *
 * To run a mutation, you first call `useCreateDNtoSubconMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDNtoSubconMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDNtoSubconMutation, { data, loading, error }] = useCreateDNtoSubconMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDNtoSubconMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDNtoSubconMutation, CreateDNtoSubconMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDNtoSubconMutation, CreateDNtoSubconMutationVariables>(CreateDNtoSubconDocument, baseOptions);
      }
export type CreateDNtoSubconMutationHookResult = ReturnType<typeof useCreateDNtoSubconMutation>;
export type CreateDNtoSubconMutationResult = ApolloReactCommon.MutationResult<CreateDNtoSubconMutation>;
export type CreateDNtoSubconMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDNtoSubconMutation, CreateDNtoSubconMutationVariables>;
export const UpdateDNtoSubconDocument = gql`
    mutation updateDNtoSubcon($input: SubcontractTransactionInput!) {
  updateDNtoSubcon(input: $input) {
    accountID
    ID
    docNo
  }
}
    `;
export type UpdateDNtoSubconMutationFn = ApolloReactCommon.MutationFunction<UpdateDNtoSubconMutation, UpdateDNtoSubconMutationVariables>;

/**
 * __useUpdateDNtoSubconMutation__
 *
 * To run a mutation, you first call `useUpdateDNtoSubconMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDNtoSubconMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDNtoSubconMutation, { data, loading, error }] = useUpdateDNtoSubconMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDNtoSubconMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDNtoSubconMutation, UpdateDNtoSubconMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDNtoSubconMutation, UpdateDNtoSubconMutationVariables>(UpdateDNtoSubconDocument, baseOptions);
      }
export type UpdateDNtoSubconMutationHookResult = ReturnType<typeof useUpdateDNtoSubconMutation>;
export type UpdateDNtoSubconMutationResult = ApolloReactCommon.MutationResult<UpdateDNtoSubconMutation>;
export type UpdateDNtoSubconMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDNtoSubconMutation, UpdateDNtoSubconMutationVariables>;
export const GetCNtoSubconDocument = gql`
    query getCNtoSubcon($ID: String, $subcontractID: String, $subcontractorID: String) {
  getCNtoSubcon(ID: $ID, subcontractID: $subcontractID, subcontractorID: $subcontractorID) {
    ID
    modBy
    isGlExport
    cancellationDate
    cancellationRemarks
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    transactionStatus
    contractID
    subcontractID
    outstandingAmt
    deductionTypeID
    deductionType {
      ID
      code
      description
    }
    subcontract {
      title
    }
    subconAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
  }
  CNtoSubconInfo(subcontractID: $subcontractID, subcontractorID: $subcontractorID)
}
    `;

/**
 * __useGetCNtoSubconQuery__
 *
 * To run a query within a React component, call `useGetCNtoSubconQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCNtoSubconQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCNtoSubconQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *   },
 * });
 */
export function useGetCNtoSubconQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCNtoSubconQuery, GetCNtoSubconQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCNtoSubconQuery, GetCNtoSubconQueryVariables>(GetCNtoSubconDocument, baseOptions);
      }
export function useGetCNtoSubconLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCNtoSubconQuery, GetCNtoSubconQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCNtoSubconQuery, GetCNtoSubconQueryVariables>(GetCNtoSubconDocument, baseOptions);
        }
export type GetCNtoSubconQueryHookResult = ReturnType<typeof useGetCNtoSubconQuery>;
export type GetCNtoSubconLazyQueryHookResult = ReturnType<typeof useGetCNtoSubconLazyQuery>;
export type GetCNtoSubconQueryResult = ApolloReactCommon.QueryResult<GetCNtoSubconQuery, GetCNtoSubconQueryVariables>;
export const CreateCNtoSubconDocument = gql`
    mutation createCNtoSubcon($input: SubcontractTransactionInput!) {
  createCNtoSubcon(input: $input) {
    accountID
    ID
    docNo
  }
}
    `;
export type CreateCNtoSubconMutationFn = ApolloReactCommon.MutationFunction<CreateCNtoSubconMutation, CreateCNtoSubconMutationVariables>;

/**
 * __useCreateCNtoSubconMutation__
 *
 * To run a mutation, you first call `useCreateCNtoSubconMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCNtoSubconMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCNtoSubconMutation, { data, loading, error }] = useCreateCNtoSubconMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCNtoSubconMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCNtoSubconMutation, CreateCNtoSubconMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCNtoSubconMutation, CreateCNtoSubconMutationVariables>(CreateCNtoSubconDocument, baseOptions);
      }
export type CreateCNtoSubconMutationHookResult = ReturnType<typeof useCreateCNtoSubconMutation>;
export type CreateCNtoSubconMutationResult = ApolloReactCommon.MutationResult<CreateCNtoSubconMutation>;
export type CreateCNtoSubconMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCNtoSubconMutation, CreateCNtoSubconMutationVariables>;
export const UpdateCNtoSubconDocument = gql`
    mutation updateCNtoSubcon($input: SubcontractTransactionInput!) {
  updateCNtoSubcon(input: $input) {
    accountID
    ID
    docNo
  }
}
    `;
export type UpdateCNtoSubconMutationFn = ApolloReactCommon.MutationFunction<UpdateCNtoSubconMutation, UpdateCNtoSubconMutationVariables>;

/**
 * __useUpdateCNtoSubconMutation__
 *
 * To run a mutation, you first call `useUpdateCNtoSubconMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCNtoSubconMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCNtoSubconMutation, { data, loading, error }] = useUpdateCNtoSubconMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCNtoSubconMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCNtoSubconMutation, UpdateCNtoSubconMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCNtoSubconMutation, UpdateCNtoSubconMutationVariables>(UpdateCNtoSubconDocument, baseOptions);
      }
export type UpdateCNtoSubconMutationHookResult = ReturnType<typeof useUpdateCNtoSubconMutation>;
export type UpdateCNtoSubconMutationResult = ApolloReactCommon.MutationResult<UpdateCNtoSubconMutation>;
export type UpdateCNtoSubconMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCNtoSubconMutation, UpdateCNtoSubconMutationVariables>;
export const GetSubconCnAllocationDocument = gql`
    query getSubconCNAllocation($debitID: String!) {
  getSubconCNAllocation(debitID: $debitID)
}
    `;

/**
 * __useGetSubconCnAllocationQuery__
 *
 * To run a query within a React component, call `useGetSubconCnAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubconCnAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubconCnAllocationQuery({
 *   variables: {
 *      debitID: // value for 'debitID'
 *   },
 * });
 */
export function useGetSubconCnAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubconCnAllocationQuery, GetSubconCnAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubconCnAllocationQuery, GetSubconCnAllocationQueryVariables>(GetSubconCnAllocationDocument, baseOptions);
      }
export function useGetSubconCnAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubconCnAllocationQuery, GetSubconCnAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubconCnAllocationQuery, GetSubconCnAllocationQueryVariables>(GetSubconCnAllocationDocument, baseOptions);
        }
export type GetSubconCnAllocationQueryHookResult = ReturnType<typeof useGetSubconCnAllocationQuery>;
export type GetSubconCnAllocationLazyQueryHookResult = ReturnType<typeof useGetSubconCnAllocationLazyQuery>;
export type GetSubconCnAllocationQueryResult = ApolloReactCommon.QueryResult<GetSubconCnAllocationQuery, GetSubconCnAllocationQueryVariables>;
export const GetSubconDnAllocationDocument = gql`
    query getSubconDNAllocation($creditID: String!) {
  getSubconDNAllocation(creditID: $creditID)
}
    `;

/**
 * __useGetSubconDnAllocationQuery__
 *
 * To run a query within a React component, call `useGetSubconDnAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubconDnAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubconDnAllocationQuery({
 *   variables: {
 *      creditID: // value for 'creditID'
 *   },
 * });
 */
export function useGetSubconDnAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubconDnAllocationQuery, GetSubconDnAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubconDnAllocationQuery, GetSubconDnAllocationQueryVariables>(GetSubconDnAllocationDocument, baseOptions);
      }
export function useGetSubconDnAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubconDnAllocationQuery, GetSubconDnAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubconDnAllocationQuery, GetSubconDnAllocationQueryVariables>(GetSubconDnAllocationDocument, baseOptions);
        }
export type GetSubconDnAllocationQueryHookResult = ReturnType<typeof useGetSubconDnAllocationQuery>;
export type GetSubconDnAllocationLazyQueryHookResult = ReturnType<typeof useGetSubconDnAllocationLazyQuery>;
export type GetSubconDnAllocationQueryResult = ApolloReactCommon.QueryResult<GetSubconDnAllocationQuery, GetSubconDnAllocationQueryVariables>;
export const GetDNtoSubconAllocationDocument = gql`
    query getDNtoSubconAllocation($debitID: String!) {
  getDNtoSubconAllocation(debitID: $debitID)
}
    `;

/**
 * __useGetDNtoSubconAllocationQuery__
 *
 * To run a query within a React component, call `useGetDNtoSubconAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDNtoSubconAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDNtoSubconAllocationQuery({
 *   variables: {
 *      debitID: // value for 'debitID'
 *   },
 * });
 */
export function useGetDNtoSubconAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDNtoSubconAllocationQuery, GetDNtoSubconAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDNtoSubconAllocationQuery, GetDNtoSubconAllocationQueryVariables>(GetDNtoSubconAllocationDocument, baseOptions);
      }
export function useGetDNtoSubconAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDNtoSubconAllocationQuery, GetDNtoSubconAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDNtoSubconAllocationQuery, GetDNtoSubconAllocationQueryVariables>(GetDNtoSubconAllocationDocument, baseOptions);
        }
export type GetDNtoSubconAllocationQueryHookResult = ReturnType<typeof useGetDNtoSubconAllocationQuery>;
export type GetDNtoSubconAllocationLazyQueryHookResult = ReturnType<typeof useGetDNtoSubconAllocationLazyQuery>;
export type GetDNtoSubconAllocationQueryResult = ApolloReactCommon.QueryResult<GetDNtoSubconAllocationQuery, GetDNtoSubconAllocationQueryVariables>;
export const GetCNtoSubconAllocationDocument = gql`
    query getCNtoSubconAllocation($creditID: String!) {
  getCNtoSubconAllocation(creditID: $creditID)
}
    `;

/**
 * __useGetCNtoSubconAllocationQuery__
 *
 * To run a query within a React component, call `useGetCNtoSubconAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCNtoSubconAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCNtoSubconAllocationQuery({
 *   variables: {
 *      creditID: // value for 'creditID'
 *   },
 * });
 */
export function useGetCNtoSubconAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCNtoSubconAllocationQuery, GetCNtoSubconAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCNtoSubconAllocationQuery, GetCNtoSubconAllocationQueryVariables>(GetCNtoSubconAllocationDocument, baseOptions);
      }
export function useGetCNtoSubconAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCNtoSubconAllocationQuery, GetCNtoSubconAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCNtoSubconAllocationQuery, GetCNtoSubconAllocationQueryVariables>(GetCNtoSubconAllocationDocument, baseOptions);
        }
export type GetCNtoSubconAllocationQueryHookResult = ReturnType<typeof useGetCNtoSubconAllocationQuery>;
export type GetCNtoSubconAllocationLazyQueryHookResult = ReturnType<typeof useGetCNtoSubconAllocationLazyQuery>;
export type GetCNtoSubconAllocationQueryResult = ApolloReactCommon.QueryResult<GetCNtoSubconAllocationQuery, GetCNtoSubconAllocationQueryVariables>;
export const CancelSubcontractMiscInvoiceDocument = gql`
    mutation cancelSubcontractMiscInvoice($ID: String!, $input: CancelInput!) {
  cancelSubcontractMiscInvoice(ID: $ID, input: $input)
}
    `;
export type CancelSubcontractMiscInvoiceMutationFn = ApolloReactCommon.MutationFunction<CancelSubcontractMiscInvoiceMutation, CancelSubcontractMiscInvoiceMutationVariables>;

/**
 * __useCancelSubcontractMiscInvoiceMutation__
 *
 * To run a mutation, you first call `useCancelSubcontractMiscInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubcontractMiscInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubcontractMiscInvoiceMutation, { data, loading, error }] = useCancelSubcontractMiscInvoiceMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSubcontractMiscInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelSubcontractMiscInvoiceMutation, CancelSubcontractMiscInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelSubcontractMiscInvoiceMutation, CancelSubcontractMiscInvoiceMutationVariables>(CancelSubcontractMiscInvoiceDocument, baseOptions);
      }
export type CancelSubcontractMiscInvoiceMutationHookResult = ReturnType<typeof useCancelSubcontractMiscInvoiceMutation>;
export type CancelSubcontractMiscInvoiceMutationResult = ApolloReactCommon.MutationResult<CancelSubcontractMiscInvoiceMutation>;
export type CancelSubcontractMiscInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelSubcontractMiscInvoiceMutation, CancelSubcontractMiscInvoiceMutationVariables>;
export const CancelSubconDnDocument = gql`
    mutation cancelSubconDN($ID: String!, $input: CancelInput!) {
  cancelSubconDN(ID: $ID, input: $input)
}
    `;
export type CancelSubconDnMutationFn = ApolloReactCommon.MutationFunction<CancelSubconDnMutation, CancelSubconDnMutationVariables>;

/**
 * __useCancelSubconDnMutation__
 *
 * To run a mutation, you first call `useCancelSubconDnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubconDnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubconDnMutation, { data, loading, error }] = useCancelSubconDnMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSubconDnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelSubconDnMutation, CancelSubconDnMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelSubconDnMutation, CancelSubconDnMutationVariables>(CancelSubconDnDocument, baseOptions);
      }
export type CancelSubconDnMutationHookResult = ReturnType<typeof useCancelSubconDnMutation>;
export type CancelSubconDnMutationResult = ApolloReactCommon.MutationResult<CancelSubconDnMutation>;
export type CancelSubconDnMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelSubconDnMutation, CancelSubconDnMutationVariables>;
export const CancelSubconCnDocument = gql`
    mutation cancelSubconCN($ID: String!, $input: CancelInput!) {
  cancelSubconCN(ID: $ID, input: $input)
}
    `;
export type CancelSubconCnMutationFn = ApolloReactCommon.MutationFunction<CancelSubconCnMutation, CancelSubconCnMutationVariables>;

/**
 * __useCancelSubconCnMutation__
 *
 * To run a mutation, you first call `useCancelSubconCnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubconCnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubconCnMutation, { data, loading, error }] = useCancelSubconCnMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSubconCnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelSubconCnMutation, CancelSubconCnMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelSubconCnMutation, CancelSubconCnMutationVariables>(CancelSubconCnDocument, baseOptions);
      }
export type CancelSubconCnMutationHookResult = ReturnType<typeof useCancelSubconCnMutation>;
export type CancelSubconCnMutationResult = ApolloReactCommon.MutationResult<CancelSubconCnMutation>;
export type CancelSubconCnMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelSubconCnMutation, CancelSubconCnMutationVariables>;
export const CancelDNtoSubconDocument = gql`
    mutation cancelDNtoSubcon($ID: String!, $input: CancelInput!) {
  cancelDNtoSubcon(ID: $ID, input: $input)
}
    `;
export type CancelDNtoSubconMutationFn = ApolloReactCommon.MutationFunction<CancelDNtoSubconMutation, CancelDNtoSubconMutationVariables>;

/**
 * __useCancelDNtoSubconMutation__
 *
 * To run a mutation, you first call `useCancelDNtoSubconMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelDNtoSubconMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelDNtoSubconMutation, { data, loading, error }] = useCancelDNtoSubconMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelDNtoSubconMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelDNtoSubconMutation, CancelDNtoSubconMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelDNtoSubconMutation, CancelDNtoSubconMutationVariables>(CancelDNtoSubconDocument, baseOptions);
      }
export type CancelDNtoSubconMutationHookResult = ReturnType<typeof useCancelDNtoSubconMutation>;
export type CancelDNtoSubconMutationResult = ApolloReactCommon.MutationResult<CancelDNtoSubconMutation>;
export type CancelDNtoSubconMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelDNtoSubconMutation, CancelDNtoSubconMutationVariables>;
export const CancelCNtoSubconDocument = gql`
    mutation cancelCNtoSubcon($ID: String!, $input: CancelInput!) {
  cancelCNtoSubcon(ID: $ID, input: $input)
}
    `;
export type CancelCNtoSubconMutationFn = ApolloReactCommon.MutationFunction<CancelCNtoSubconMutation, CancelCNtoSubconMutationVariables>;

/**
 * __useCancelCNtoSubconMutation__
 *
 * To run a mutation, you first call `useCancelCNtoSubconMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelCNtoSubconMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelCNtoSubconMutation, { data, loading, error }] = useCancelCNtoSubconMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelCNtoSubconMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelCNtoSubconMutation, CancelCNtoSubconMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelCNtoSubconMutation, CancelCNtoSubconMutationVariables>(CancelCNtoSubconDocument, baseOptions);
      }
export type CancelCNtoSubconMutationHookResult = ReturnType<typeof useCancelCNtoSubconMutation>;
export type CancelCNtoSubconMutationResult = ApolloReactCommon.MutationResult<CancelCNtoSubconMutation>;
export type CancelCNtoSubconMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelCNtoSubconMutation, CancelCNtoSubconMutationVariables>;
export const DeleteSubcontractMiscInvoiceDocument = gql`
    mutation deleteSubcontractMiscInvoice($ID: String!) {
  deleteSubcontractMiscInvoice(ID: $ID)
}
    `;
export type DeleteSubcontractMiscInvoiceMutationFn = ApolloReactCommon.MutationFunction<DeleteSubcontractMiscInvoiceMutation, DeleteSubcontractMiscInvoiceMutationVariables>;

/**
 * __useDeleteSubcontractMiscInvoiceMutation__
 *
 * To run a mutation, you first call `useDeleteSubcontractMiscInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubcontractMiscInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubcontractMiscInvoiceMutation, { data, loading, error }] = useDeleteSubcontractMiscInvoiceMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteSubcontractMiscInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSubcontractMiscInvoiceMutation, DeleteSubcontractMiscInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSubcontractMiscInvoiceMutation, DeleteSubcontractMiscInvoiceMutationVariables>(DeleteSubcontractMiscInvoiceDocument, baseOptions);
      }
export type DeleteSubcontractMiscInvoiceMutationHookResult = ReturnType<typeof useDeleteSubcontractMiscInvoiceMutation>;
export type DeleteSubcontractMiscInvoiceMutationResult = ApolloReactCommon.MutationResult<DeleteSubcontractMiscInvoiceMutation>;
export type DeleteSubcontractMiscInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSubcontractMiscInvoiceMutation, DeleteSubcontractMiscInvoiceMutationVariables>;
export const DeleteSubcontractCnDocument = gql`
    mutation deleteSubcontractCN($ID: String!) {
  deleteSubcontractCN(ID: $ID)
}
    `;
export type DeleteSubcontractCnMutationFn = ApolloReactCommon.MutationFunction<DeleteSubcontractCnMutation, DeleteSubcontractCnMutationVariables>;

/**
 * __useDeleteSubcontractCnMutation__
 *
 * To run a mutation, you first call `useDeleteSubcontractCnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubcontractCnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubcontractCnMutation, { data, loading, error }] = useDeleteSubcontractCnMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteSubcontractCnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSubcontractCnMutation, DeleteSubcontractCnMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSubcontractCnMutation, DeleteSubcontractCnMutationVariables>(DeleteSubcontractCnDocument, baseOptions);
      }
export type DeleteSubcontractCnMutationHookResult = ReturnType<typeof useDeleteSubcontractCnMutation>;
export type DeleteSubcontractCnMutationResult = ApolloReactCommon.MutationResult<DeleteSubcontractCnMutation>;
export type DeleteSubcontractCnMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSubcontractCnMutation, DeleteSubcontractCnMutationVariables>;
export const DeleteSubcontractDnDocument = gql`
    mutation deleteSubcontractDN($ID: String!) {
  deleteSubcontractDN(ID: $ID)
}
    `;
export type DeleteSubcontractDnMutationFn = ApolloReactCommon.MutationFunction<DeleteSubcontractDnMutation, DeleteSubcontractDnMutationVariables>;

/**
 * __useDeleteSubcontractDnMutation__
 *
 * To run a mutation, you first call `useDeleteSubcontractDnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubcontractDnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubcontractDnMutation, { data, loading, error }] = useDeleteSubcontractDnMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteSubcontractDnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSubcontractDnMutation, DeleteSubcontractDnMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSubcontractDnMutation, DeleteSubcontractDnMutationVariables>(DeleteSubcontractDnDocument, baseOptions);
      }
export type DeleteSubcontractDnMutationHookResult = ReturnType<typeof useDeleteSubcontractDnMutation>;
export type DeleteSubcontractDnMutationResult = ApolloReactCommon.MutationResult<DeleteSubcontractDnMutation>;
export type DeleteSubcontractDnMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSubcontractDnMutation, DeleteSubcontractDnMutationVariables>;
export const DeleteDNtoSubconDocument = gql`
    mutation deleteDNtoSubcon($ID: String!) {
  deleteDNtoSubcon(ID: $ID)
}
    `;
export type DeleteDNtoSubconMutationFn = ApolloReactCommon.MutationFunction<DeleteDNtoSubconMutation, DeleteDNtoSubconMutationVariables>;

/**
 * __useDeleteDNtoSubconMutation__
 *
 * To run a mutation, you first call `useDeleteDNtoSubconMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDNtoSubconMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDNtoSubconMutation, { data, loading, error }] = useDeleteDNtoSubconMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteDNtoSubconMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDNtoSubconMutation, DeleteDNtoSubconMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteDNtoSubconMutation, DeleteDNtoSubconMutationVariables>(DeleteDNtoSubconDocument, baseOptions);
      }
export type DeleteDNtoSubconMutationHookResult = ReturnType<typeof useDeleteDNtoSubconMutation>;
export type DeleteDNtoSubconMutationResult = ApolloReactCommon.MutationResult<DeleteDNtoSubconMutation>;
export type DeleteDNtoSubconMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDNtoSubconMutation, DeleteDNtoSubconMutationVariables>;
export const DeleteCNtoSubconDocument = gql`
    mutation deleteCNtoSubcon($ID: String!) {
  deleteCNtoSubcon(ID: $ID)
}
    `;
export type DeleteCNtoSubconMutationFn = ApolloReactCommon.MutationFunction<DeleteCNtoSubconMutation, DeleteCNtoSubconMutationVariables>;

/**
 * __useDeleteCNtoSubconMutation__
 *
 * To run a mutation, you first call `useDeleteCNtoSubconMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCNtoSubconMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCNtoSubconMutation, { data, loading, error }] = useDeleteCNtoSubconMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteCNtoSubconMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCNtoSubconMutation, DeleteCNtoSubconMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCNtoSubconMutation, DeleteCNtoSubconMutationVariables>(DeleteCNtoSubconDocument, baseOptions);
      }
export type DeleteCNtoSubconMutationHookResult = ReturnType<typeof useDeleteCNtoSubconMutation>;
export type DeleteCNtoSubconMutationResult = ApolloReactCommon.MutationResult<DeleteCNtoSubconMutation>;
export type DeleteCNtoSubconMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCNtoSubconMutation, DeleteCNtoSubconMutationVariables>;
export const GetSubconAdjustmentDocument = gql`
    query getSubconAdjustment($contractID: String, $subcontractID: String, $subcontractorID: String, $transactionStatus: TransactionStatus) {
  getCNtoSubcon(contractID: $contractID, subcontractID: $subcontractID, subcontractorID: $subcontractorID, transactionStatus: $transactionStatus) {
    ID
    docAmt
    baseAmt
    docDate
    docNo
    docRef
    taxAmt
    description
    deductionTypeID
    deductionType {
      ID
      code
    }
  }
  getDNtoSubcon(contractID: $contractID, subcontractID: $subcontractID, subcontractorID: $subcontractorID, transactionStatus: $transactionStatus) {
    ID
    docAmt
    baseAmt
    docDate
    docNo
    docRef
    taxAmt
    description
    deductionTypeID
    deductionType {
      ID
      code
    }
  }
  getSubcontractDN(contractID: $contractID, subcontractID: $subcontractID, subcontractorID: $subcontractorID, transactionStatus: $transactionStatus) {
    ID
    docAmt
    baseAmt
    docDate
    docNo
    docRef
    taxAmt
    description
    deductionTypeID
    deductionType {
      ID
      code
    }
  }
  getSubcontractCN(contractID: $contractID, subcontractID: $subcontractID, subcontractorID: $subcontractorID, transactionStatus: $transactionStatus) {
    ID
    docAmt
    baseAmt
    docDate
    docNo
    docRef
    taxAmt
    description
    deductionTypeID
    deductionType {
      ID
      code
    }
  }
}
    `;

/**
 * __useGetSubconAdjustmentQuery__
 *
 * To run a query within a React component, call `useGetSubconAdjustmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubconAdjustmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubconAdjustmentQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *      transactionStatus: // value for 'transactionStatus'
 *   },
 * });
 */
export function useGetSubconAdjustmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubconAdjustmentQuery, GetSubconAdjustmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubconAdjustmentQuery, GetSubconAdjustmentQueryVariables>(GetSubconAdjustmentDocument, baseOptions);
      }
export function useGetSubconAdjustmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubconAdjustmentQuery, GetSubconAdjustmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubconAdjustmentQuery, GetSubconAdjustmentQueryVariables>(GetSubconAdjustmentDocument, baseOptions);
        }
export type GetSubconAdjustmentQueryHookResult = ReturnType<typeof useGetSubconAdjustmentQuery>;
export type GetSubconAdjustmentLazyQueryHookResult = ReturnType<typeof useGetSubconAdjustmentLazyQuery>;
export type GetSubconAdjustmentQueryResult = ApolloReactCommon.QueryResult<GetSubconAdjustmentQuery, GetSubconAdjustmentQueryVariables>;
export const GetSubcontractDocument = gql`
    query getSubcontract($ID: String, $subcontractorID: String, $contractID: String, $subcontractStatus: ContractStatus) {
  getSubcontract(ID: $ID, subcontractorID: $subcontractorID, contractID: $contractID, subcontractStatus: $subcontractStatus) {
    ID
    title
    accountID
    contractID
    subcontractorID
    costCategoryID
    subcontractNo
    title
    description
    subcontractDate
    taxSchemeID
    subcontractSum
    revisedSubcontractSum
    startDate
    endDate
    progressRetentionPerc
    maxRetentionPerc
    maxRetentionSum
    revisedSubcontractSum
    targetProfitPerc
    cmgdDate
    cpcDate
    dlpDate
    latestRunNum
    docNumChecking
    ladAmt
    ladRate
    creditTerm
    subcontractStatus
    contactPerson
    siteID
    site {
      ID
      name
      contractID
      address
    }
    subcontractorDetail {
      associatedStatus
      name
      ID
      address
      contactNo
    }
    mainContractDetail {
      title
      ID
    }
    extensionDate
    closureRemarks
    closureDate
    modBy
    VO {
      submissionAmt
      approvedAmt
    }
    taxDetail {
      ID
      code
      description
      taxCategory
      taxTypeID
      taxClass
      createdTs
      commonStatus
      latestTax {
        taxRate
        taxDate
      }
      taxEffective {
        date
        taxRate
        createdTs
      }
    }
    tradeDetail {
      ID
      name
    }
    nominatedSC
  }
}
    `;

/**
 * __useGetSubcontractQuery__
 *
 * To run a query within a React component, call `useGetSubcontractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      subcontractorID: // value for 'subcontractorID'
 *      contractID: // value for 'contractID'
 *      subcontractStatus: // value for 'subcontractStatus'
 *   },
 * });
 */
export function useGetSubcontractQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractQuery, GetSubcontractQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractQuery, GetSubcontractQueryVariables>(GetSubcontractDocument, baseOptions);
      }
export function useGetSubcontractLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractQuery, GetSubcontractQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractQuery, GetSubcontractQueryVariables>(GetSubcontractDocument, baseOptions);
        }
export type GetSubcontractQueryHookResult = ReturnType<typeof useGetSubcontractQuery>;
export type GetSubcontractLazyQueryHookResult = ReturnType<typeof useGetSubcontractLazyQuery>;
export type GetSubcontractQueryResult = ApolloReactCommon.QueryResult<GetSubcontractQuery, GetSubcontractQueryVariables>;
export const GetSubcontractSubmenuDocument = gql`
    query getSubcontractSubmenu($ID: String) {
  getSubcontract(ID: $ID) {
    ID
    title
    accountID
    subcontractNo
    subcontractorID
    subcontractStatus
    cmgdDate
    cpcDate
    dlpDate
    subcontractSum
    revisedSubcontractSum
  }
  getSubcontractRelated(subcontractID: $ID) {
    ID
    title
    accountID
    subcontractNo
    subcontractorID
  }
}
    `;

/**
 * __useGetSubcontractSubmenuQuery__
 *
 * To run a query within a React component, call `useGetSubcontractSubmenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractSubmenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractSubmenuQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetSubcontractSubmenuQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractSubmenuQuery, GetSubcontractSubmenuQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractSubmenuQuery, GetSubcontractSubmenuQueryVariables>(GetSubcontractSubmenuDocument, baseOptions);
      }
export function useGetSubcontractSubmenuLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractSubmenuQuery, GetSubcontractSubmenuQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractSubmenuQuery, GetSubcontractSubmenuQueryVariables>(GetSubcontractSubmenuDocument, baseOptions);
        }
export type GetSubcontractSubmenuQueryHookResult = ReturnType<typeof useGetSubcontractSubmenuQuery>;
export type GetSubcontractSubmenuLazyQueryHookResult = ReturnType<typeof useGetSubcontractSubmenuLazyQuery>;
export type GetSubcontractSubmenuQueryResult = ApolloReactCommon.QueryResult<GetSubcontractSubmenuQuery, GetSubcontractSubmenuQueryVariables>;
export const GetContractSubcontractListingDocument = gql`
    query getContractSubcontractListing($orderByAsc: String, $orderByDesc: String, $contractStatus: ContractStatus) {
  getContract(orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, contractStatus: $contractStatus) {
    ID
    title
    accountID
    customerID
    contractNo
    description
    contractDate
    contractSum
    voSum
    revisedContractSum
    customerDetail {
      name
      contactPerson {
        ID
        name
        designation
        email
        contactNo
      }
      address
      contactNo
    }
    subcontractSummary
    subcontractDetail {
      ID
      title
      accountID
      contractID
      subcontractorID
      costCategoryID
      subcontractNo
      description
      subcontractDate
      taxSchemeID
      subcontractSum
      revisedSubcontractSum
      startDate
      endDate
      progressRetentionPerc
      maxRetentionPerc
      targetProfitPerc
      creditTerm
      tradeDetail {
        ID
        name
      }
      subcontractorDetail {
        ID
        name
        associatedStatus
      }
      subcontractStatus
    }
  }
}
    `;

/**
 * __useGetContractSubcontractListingQuery__
 *
 * To run a query within a React component, call `useGetContractSubcontractListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractSubcontractListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractSubcontractListingQuery({
 *   variables: {
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      contractStatus: // value for 'contractStatus'
 *   },
 * });
 */
export function useGetContractSubcontractListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractSubcontractListingQuery, GetContractSubcontractListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractSubcontractListingQuery, GetContractSubcontractListingQueryVariables>(GetContractSubcontractListingDocument, baseOptions);
      }
export function useGetContractSubcontractListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractSubcontractListingQuery, GetContractSubcontractListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractSubcontractListingQuery, GetContractSubcontractListingQueryVariables>(GetContractSubcontractListingDocument, baseOptions);
        }
export type GetContractSubcontractListingQueryHookResult = ReturnType<typeof useGetContractSubcontractListingQuery>;
export type GetContractSubcontractListingLazyQueryHookResult = ReturnType<typeof useGetContractSubcontractListingLazyQuery>;
export type GetContractSubcontractListingQueryResult = ApolloReactCommon.QueryResult<GetContractSubcontractListingQuery, GetContractSubcontractListingQueryVariables>;
export const CreateSubcontractDocument = gql`
    mutation createSubcontract($input: SubcontractInput!) {
  createSubcontract(input: $input) {
    ID
    title
  }
}
    `;
export type CreateSubcontractMutationFn = ApolloReactCommon.MutationFunction<CreateSubcontractMutation, CreateSubcontractMutationVariables>;

/**
 * __useCreateSubcontractMutation__
 *
 * To run a mutation, you first call `useCreateSubcontractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubcontractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubcontractMutation, { data, loading, error }] = useCreateSubcontractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubcontractMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubcontractMutation, CreateSubcontractMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubcontractMutation, CreateSubcontractMutationVariables>(CreateSubcontractDocument, baseOptions);
      }
export type CreateSubcontractMutationHookResult = ReturnType<typeof useCreateSubcontractMutation>;
export type CreateSubcontractMutationResult = ApolloReactCommon.MutationResult<CreateSubcontractMutation>;
export type CreateSubcontractMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubcontractMutation, CreateSubcontractMutationVariables>;
export const UpdateSubcontractDocument = gql`
    mutation updateSubcontract($input: SubcontractInput!) {
  updateSubcontract(input: $input)
}
    `;
export type UpdateSubcontractMutationFn = ApolloReactCommon.MutationFunction<UpdateSubcontractMutation, UpdateSubcontractMutationVariables>;

/**
 * __useUpdateSubcontractMutation__
 *
 * To run a mutation, you first call `useUpdateSubcontractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubcontractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubcontractMutation, { data, loading, error }] = useUpdateSubcontractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubcontractMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSubcontractMutation, UpdateSubcontractMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSubcontractMutation, UpdateSubcontractMutationVariables>(UpdateSubcontractDocument, baseOptions);
      }
export type UpdateSubcontractMutationHookResult = ReturnType<typeof useUpdateSubcontractMutation>;
export type UpdateSubcontractMutationResult = ApolloReactCommon.MutationResult<UpdateSubcontractMutation>;
export type UpdateSubcontractMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSubcontractMutation, UpdateSubcontractMutationVariables>;
export const GetSubcontractGuaranteeDocument = gql`
    query getSubcontractGuarantee($ID: String, $subcontractID: String!) {
  getSubcontractGuarantee(ID: $ID, subcontractID: $subcontractID) {
    ID
    modBy
    accountID
    subcontractID
    docRef
    guaranteeTypeID
    description
    docAmt
    startDate
    expiryDate
    issuer
    collateralAmt
    remarks
    cancellationDate
    guaranteeStatus
    guaranteeType {
      name
    }
  }
  subconGuarantee(subcontractID: $subcontractID)
}
    `;

/**
 * __useGetSubcontractGuaranteeQuery__
 *
 * To run a query within a React component, call `useGetSubcontractGuaranteeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractGuaranteeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractGuaranteeQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      subcontractID: // value for 'subcontractID'
 *   },
 * });
 */
export function useGetSubcontractGuaranteeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractGuaranteeQuery, GetSubcontractGuaranteeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractGuaranteeQuery, GetSubcontractGuaranteeQueryVariables>(GetSubcontractGuaranteeDocument, baseOptions);
      }
export function useGetSubcontractGuaranteeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractGuaranteeQuery, GetSubcontractGuaranteeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractGuaranteeQuery, GetSubcontractGuaranteeQueryVariables>(GetSubcontractGuaranteeDocument, baseOptions);
        }
export type GetSubcontractGuaranteeQueryHookResult = ReturnType<typeof useGetSubcontractGuaranteeQuery>;
export type GetSubcontractGuaranteeLazyQueryHookResult = ReturnType<typeof useGetSubcontractGuaranteeLazyQuery>;
export type GetSubcontractGuaranteeQueryResult = ApolloReactCommon.QueryResult<GetSubcontractGuaranteeQuery, GetSubcontractGuaranteeQueryVariables>;
export const CreateSubcontractGuaranteeDocument = gql`
    mutation createSubcontractGuarantee($input: SubcontractGuaranteeInput!) {
  createSubcontractGuarantee(input: $input) {
    ID
  }
}
    `;
export type CreateSubcontractGuaranteeMutationFn = ApolloReactCommon.MutationFunction<CreateSubcontractGuaranteeMutation, CreateSubcontractGuaranteeMutationVariables>;

/**
 * __useCreateSubcontractGuaranteeMutation__
 *
 * To run a mutation, you first call `useCreateSubcontractGuaranteeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubcontractGuaranteeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubcontractGuaranteeMutation, { data, loading, error }] = useCreateSubcontractGuaranteeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubcontractGuaranteeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubcontractGuaranteeMutation, CreateSubcontractGuaranteeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubcontractGuaranteeMutation, CreateSubcontractGuaranteeMutationVariables>(CreateSubcontractGuaranteeDocument, baseOptions);
      }
export type CreateSubcontractGuaranteeMutationHookResult = ReturnType<typeof useCreateSubcontractGuaranteeMutation>;
export type CreateSubcontractGuaranteeMutationResult = ApolloReactCommon.MutationResult<CreateSubcontractGuaranteeMutation>;
export type CreateSubcontractGuaranteeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubcontractGuaranteeMutation, CreateSubcontractGuaranteeMutationVariables>;
export const UpdateSubcontractGuaranteeDocument = gql`
    mutation updateSubcontractGuarantee($input: SubcontractGuaranteeInput!) {
  updateSubcontractGuarantee(input: $input) {
    ID
  }
}
    `;
export type UpdateSubcontractGuaranteeMutationFn = ApolloReactCommon.MutationFunction<UpdateSubcontractGuaranteeMutation, UpdateSubcontractGuaranteeMutationVariables>;

/**
 * __useUpdateSubcontractGuaranteeMutation__
 *
 * To run a mutation, you first call `useUpdateSubcontractGuaranteeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubcontractGuaranteeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubcontractGuaranteeMutation, { data, loading, error }] = useUpdateSubcontractGuaranteeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubcontractGuaranteeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSubcontractGuaranteeMutation, UpdateSubcontractGuaranteeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSubcontractGuaranteeMutation, UpdateSubcontractGuaranteeMutationVariables>(UpdateSubcontractGuaranteeDocument, baseOptions);
      }
export type UpdateSubcontractGuaranteeMutationHookResult = ReturnType<typeof useUpdateSubcontractGuaranteeMutation>;
export type UpdateSubcontractGuaranteeMutationResult = ApolloReactCommon.MutationResult<UpdateSubcontractGuaranteeMutation>;
export type UpdateSubcontractGuaranteeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSubcontractGuaranteeMutation, UpdateSubcontractGuaranteeMutationVariables>;
export const GetSubcontractInsuranceDocument = gql`
    query getSubcontractInsurance($ID: String, $subcontractID: String!) {
  getSubcontractInsurance(ID: $ID, subcontractID: $subcontractID) {
    ID
    modBy
    accountID
    subcontractID
    docRef
    insuranceTypeID
    description
    docAmt
    startDate
    expiryDate
    issuer
    remarks
    cancellationDate
    insuranceStatus
    insuranceType {
      name
    }
  }
  subconInsurance(subcontractID: $subcontractID)
}
    `;

/**
 * __useGetSubcontractInsuranceQuery__
 *
 * To run a query within a React component, call `useGetSubcontractInsuranceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractInsuranceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractInsuranceQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      subcontractID: // value for 'subcontractID'
 *   },
 * });
 */
export function useGetSubcontractInsuranceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractInsuranceQuery, GetSubcontractInsuranceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractInsuranceQuery, GetSubcontractInsuranceQueryVariables>(GetSubcontractInsuranceDocument, baseOptions);
      }
export function useGetSubcontractInsuranceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractInsuranceQuery, GetSubcontractInsuranceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractInsuranceQuery, GetSubcontractInsuranceQueryVariables>(GetSubcontractInsuranceDocument, baseOptions);
        }
export type GetSubcontractInsuranceQueryHookResult = ReturnType<typeof useGetSubcontractInsuranceQuery>;
export type GetSubcontractInsuranceLazyQueryHookResult = ReturnType<typeof useGetSubcontractInsuranceLazyQuery>;
export type GetSubcontractInsuranceQueryResult = ApolloReactCommon.QueryResult<GetSubcontractInsuranceQuery, GetSubcontractInsuranceQueryVariables>;
export const CreateSubcontractInsuranceDocument = gql`
    mutation createSubcontractInsurance($input: SubcontractInsuranceInput!) {
  createSubcontractInsurance(input: $input) {
    ID
  }
}
    `;
export type CreateSubcontractInsuranceMutationFn = ApolloReactCommon.MutationFunction<CreateSubcontractInsuranceMutation, CreateSubcontractInsuranceMutationVariables>;

/**
 * __useCreateSubcontractInsuranceMutation__
 *
 * To run a mutation, you first call `useCreateSubcontractInsuranceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubcontractInsuranceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubcontractInsuranceMutation, { data, loading, error }] = useCreateSubcontractInsuranceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubcontractInsuranceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubcontractInsuranceMutation, CreateSubcontractInsuranceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubcontractInsuranceMutation, CreateSubcontractInsuranceMutationVariables>(CreateSubcontractInsuranceDocument, baseOptions);
      }
export type CreateSubcontractInsuranceMutationHookResult = ReturnType<typeof useCreateSubcontractInsuranceMutation>;
export type CreateSubcontractInsuranceMutationResult = ApolloReactCommon.MutationResult<CreateSubcontractInsuranceMutation>;
export type CreateSubcontractInsuranceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubcontractInsuranceMutation, CreateSubcontractInsuranceMutationVariables>;
export const UpdateSubcontractInsuranceDocument = gql`
    mutation updateSubcontractInsurance($input: SubcontractInsuranceInput!) {
  updateSubcontractInsurance(input: $input) {
    ID
  }
}
    `;
export type UpdateSubcontractInsuranceMutationFn = ApolloReactCommon.MutationFunction<UpdateSubcontractInsuranceMutation, UpdateSubcontractInsuranceMutationVariables>;

/**
 * __useUpdateSubcontractInsuranceMutation__
 *
 * To run a mutation, you first call `useUpdateSubcontractInsuranceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubcontractInsuranceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubcontractInsuranceMutation, { data, loading, error }] = useUpdateSubcontractInsuranceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubcontractInsuranceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSubcontractInsuranceMutation, UpdateSubcontractInsuranceMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSubcontractInsuranceMutation, UpdateSubcontractInsuranceMutationVariables>(UpdateSubcontractInsuranceDocument, baseOptions);
      }
export type UpdateSubcontractInsuranceMutationHookResult = ReturnType<typeof useUpdateSubcontractInsuranceMutation>;
export type UpdateSubcontractInsuranceMutationResult = ApolloReactCommon.MutationResult<UpdateSubcontractInsuranceMutation>;
export type UpdateSubcontractInsuranceMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSubcontractInsuranceMutation, UpdateSubcontractInsuranceMutationVariables>;
export const CancelSubcontractGuaranteeDocument = gql`
    mutation cancelSubcontractGuarantee($input: SubcontractGuaranteeCancellationInput!) {
  cancelSubcontractGuarantee(input: $input)
}
    `;
export type CancelSubcontractGuaranteeMutationFn = ApolloReactCommon.MutationFunction<CancelSubcontractGuaranteeMutation, CancelSubcontractGuaranteeMutationVariables>;

/**
 * __useCancelSubcontractGuaranteeMutation__
 *
 * To run a mutation, you first call `useCancelSubcontractGuaranteeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubcontractGuaranteeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubcontractGuaranteeMutation, { data, loading, error }] = useCancelSubcontractGuaranteeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSubcontractGuaranteeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelSubcontractGuaranteeMutation, CancelSubcontractGuaranteeMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelSubcontractGuaranteeMutation, CancelSubcontractGuaranteeMutationVariables>(CancelSubcontractGuaranteeDocument, baseOptions);
      }
export type CancelSubcontractGuaranteeMutationHookResult = ReturnType<typeof useCancelSubcontractGuaranteeMutation>;
export type CancelSubcontractGuaranteeMutationResult = ApolloReactCommon.MutationResult<CancelSubcontractGuaranteeMutation>;
export type CancelSubcontractGuaranteeMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelSubcontractGuaranteeMutation, CancelSubcontractGuaranteeMutationVariables>;
export const CancelSubcontractInsuranceDocument = gql`
    mutation cancelSubcontractInsurance($input: SubcontractInsuranceCancellationInput!) {
  cancelSubcontractInsurance(input: $input)
}
    `;
export type CancelSubcontractInsuranceMutationFn = ApolloReactCommon.MutationFunction<CancelSubcontractInsuranceMutation, CancelSubcontractInsuranceMutationVariables>;

/**
 * __useCancelSubcontractInsuranceMutation__
 *
 * To run a mutation, you first call `useCancelSubcontractInsuranceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubcontractInsuranceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubcontractInsuranceMutation, { data, loading, error }] = useCancelSubcontractInsuranceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSubcontractInsuranceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelSubcontractInsuranceMutation, CancelSubcontractInsuranceMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelSubcontractInsuranceMutation, CancelSubcontractInsuranceMutationVariables>(CancelSubcontractInsuranceDocument, baseOptions);
      }
export type CancelSubcontractInsuranceMutationHookResult = ReturnType<typeof useCancelSubcontractInsuranceMutation>;
export type CancelSubcontractInsuranceMutationResult = ApolloReactCommon.MutationResult<CancelSubcontractInsuranceMutation>;
export type CancelSubcontractInsuranceMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelSubcontractInsuranceMutation, CancelSubcontractInsuranceMutationVariables>;
export const GetSubcontractSummaryDocument = gql`
    query getSubcontractSummary($subcontractID: String!, $type: String) {
  subcontractInfo(subcontractID: $subcontractID) {
    subcontractSum
    subcontractDate
  }
  subconGuarantee(subcontractID: $subcontractID)
  subconInsurance(subcontractID: $subcontractID)
  submittedVOSubconNotification(subcontractID: $subcontractID)
  subcontractVO(subcontractID: $subcontractID) {
    voSum
    voOmission
    voSumCount
    voOmissionCount
    totalVoAmt
  }
  subconOutstandingAdvances(subcontractID: $subcontractID) {
    docNo
    docDate
    docAmt
    baseAmt
    outstandingAmt
  }
  subconOutstandingClaims(subcontractID: $subcontractID) {
    docNo
    docDate
    docAmt
    baseAmt
    outstandingAmt
  }
  subconLastExtensionDate(subcontractID: $subcontractID)
  subconClosureDate(subcontractID: $subcontractID)
  retention(subcontractID: $subcontractID) {
    retentionSum
    retentionBalance
    releasedSum
  }
  totalDigitalDocuments(associateID: $subcontractID, type: $type)
}
    `;

/**
 * __useGetSubcontractSummaryQuery__
 *
 * To run a query within a React component, call `useGetSubcontractSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractSummaryQuery({
 *   variables: {
 *      subcontractID: // value for 'subcontractID'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetSubcontractSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractSummaryQuery, GetSubcontractSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractSummaryQuery, GetSubcontractSummaryQueryVariables>(GetSubcontractSummaryDocument, baseOptions);
      }
export function useGetSubcontractSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractSummaryQuery, GetSubcontractSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractSummaryQuery, GetSubcontractSummaryQueryVariables>(GetSubcontractSummaryDocument, baseOptions);
        }
export type GetSubcontractSummaryQueryHookResult = ReturnType<typeof useGetSubcontractSummaryQuery>;
export type GetSubcontractSummaryLazyQueryHookResult = ReturnType<typeof useGetSubcontractSummaryLazyQuery>;
export type GetSubcontractSummaryQueryResult = ApolloReactCommon.QueryResult<GetSubcontractSummaryQuery, GetSubcontractSummaryQueryVariables>;
export const GetIpcSummaryDocument = gql`
    query getIPCSummary($subcontractID: String!) {
  retention(subcontractID: $subcontractID) {
    retentionSum
    retentionBalance
    releasedSum
  }
}
    `;

/**
 * __useGetIpcSummaryQuery__
 *
 * To run a query within a React component, call `useGetIpcSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIpcSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIpcSummaryQuery({
 *   variables: {
 *      subcontractID: // value for 'subcontractID'
 *   },
 * });
 */
export function useGetIpcSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetIpcSummaryQuery, GetIpcSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetIpcSummaryQuery, GetIpcSummaryQueryVariables>(GetIpcSummaryDocument, baseOptions);
      }
export function useGetIpcSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetIpcSummaryQuery, GetIpcSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetIpcSummaryQuery, GetIpcSummaryQueryVariables>(GetIpcSummaryDocument, baseOptions);
        }
export type GetIpcSummaryQueryHookResult = ReturnType<typeof useGetIpcSummaryQuery>;
export type GetIpcSummaryLazyQueryHookResult = ReturnType<typeof useGetIpcSummaryLazyQuery>;
export type GetIpcSummaryQueryResult = ApolloReactCommon.QueryResult<GetIpcSummaryQuery, GetIpcSummaryQueryVariables>;
export const ActionOnSubcontractStatusDocument = gql`
    mutation actionOnSubcontractStatus($input: SubcontractActionInput!, $ID: String!) {
  actionOnSubcontractStatus(input: $input, ID: $ID)
}
    `;
export type ActionOnSubcontractStatusMutationFn = ApolloReactCommon.MutationFunction<ActionOnSubcontractStatusMutation, ActionOnSubcontractStatusMutationVariables>;

/**
 * __useActionOnSubcontractStatusMutation__
 *
 * To run a mutation, you first call `useActionOnSubcontractStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionOnSubcontractStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionOnSubcontractStatusMutation, { data, loading, error }] = useActionOnSubcontractStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useActionOnSubcontractStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActionOnSubcontractStatusMutation, ActionOnSubcontractStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<ActionOnSubcontractStatusMutation, ActionOnSubcontractStatusMutationVariables>(ActionOnSubcontractStatusDocument, baseOptions);
      }
export type ActionOnSubcontractStatusMutationHookResult = ReturnType<typeof useActionOnSubcontractStatusMutation>;
export type ActionOnSubcontractStatusMutationResult = ApolloReactCommon.MutationResult<ActionOnSubcontractStatusMutation>;
export type ActionOnSubcontractStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<ActionOnSubcontractStatusMutation, ActionOnSubcontractStatusMutationVariables>;
export const CreateSubcontractExtensionDateDocument = gql`
    mutation createSubcontractExtensionDate($input: SubcontractExtensionInput!, $ID: String!) {
  createSubcontractExtensionDate(input: $input, ID: $ID)
}
    `;
export type CreateSubcontractExtensionDateMutationFn = ApolloReactCommon.MutationFunction<CreateSubcontractExtensionDateMutation, CreateSubcontractExtensionDateMutationVariables>;

/**
 * __useCreateSubcontractExtensionDateMutation__
 *
 * To run a mutation, you first call `useCreateSubcontractExtensionDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubcontractExtensionDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubcontractExtensionDateMutation, { data, loading, error }] = useCreateSubcontractExtensionDateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useCreateSubcontractExtensionDateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubcontractExtensionDateMutation, CreateSubcontractExtensionDateMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubcontractExtensionDateMutation, CreateSubcontractExtensionDateMutationVariables>(CreateSubcontractExtensionDateDocument, baseOptions);
      }
export type CreateSubcontractExtensionDateMutationHookResult = ReturnType<typeof useCreateSubcontractExtensionDateMutation>;
export type CreateSubcontractExtensionDateMutationResult = ApolloReactCommon.MutationResult<CreateSubcontractExtensionDateMutation>;
export type CreateSubcontractExtensionDateMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubcontractExtensionDateMutation, CreateSubcontractExtensionDateMutationVariables>;
export const CreateSubcontractCertificateDocument = gql`
    mutation createSubcontractCertificate($input: SubcontractCertificateInput!, $ID: String!) {
  createSubcontractCertificate(input: $input, ID: $ID)
}
    `;
export type CreateSubcontractCertificateMutationFn = ApolloReactCommon.MutationFunction<CreateSubcontractCertificateMutation, CreateSubcontractCertificateMutationVariables>;

/**
 * __useCreateSubcontractCertificateMutation__
 *
 * To run a mutation, you first call `useCreateSubcontractCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubcontractCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubcontractCertificateMutation, { data, loading, error }] = useCreateSubcontractCertificateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useCreateSubcontractCertificateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubcontractCertificateMutation, CreateSubcontractCertificateMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubcontractCertificateMutation, CreateSubcontractCertificateMutationVariables>(CreateSubcontractCertificateDocument, baseOptions);
      }
export type CreateSubcontractCertificateMutationHookResult = ReturnType<typeof useCreateSubcontractCertificateMutation>;
export type CreateSubcontractCertificateMutationResult = ApolloReactCommon.MutationResult<CreateSubcontractCertificateMutation>;
export type CreateSubcontractCertificateMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubcontractCertificateMutation, CreateSubcontractCertificateMutationVariables>;
export const GetSubcontractExtensionDocument = gql`
    query getSubcontractExtension($ID: String!, $orderByAsc: String, $orderByDesc: String) {
  getSubcontract(ID: $ID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    ID
    title
    subcontractNo
    description
    subcontractDate
    subcontractSum
    taxSchemeID
    startDate
    endDate
    subcontractStatus
    mainContractDetail {
      title
      ID
    }
    extensionDate
  }
  subconLastExtensionDate(subcontractID: $ID)
}
    `;

/**
 * __useGetSubcontractExtensionQuery__
 *
 * To run a query within a React component, call `useGetSubcontractExtensionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractExtensionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractExtensionQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetSubcontractExtensionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractExtensionQuery, GetSubcontractExtensionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractExtensionQuery, GetSubcontractExtensionQueryVariables>(GetSubcontractExtensionDocument, baseOptions);
      }
export function useGetSubcontractExtensionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractExtensionQuery, GetSubcontractExtensionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractExtensionQuery, GetSubcontractExtensionQueryVariables>(GetSubcontractExtensionDocument, baseOptions);
        }
export type GetSubcontractExtensionQueryHookResult = ReturnType<typeof useGetSubcontractExtensionQuery>;
export type GetSubcontractExtensionLazyQueryHookResult = ReturnType<typeof useGetSubcontractExtensionLazyQuery>;
export type GetSubcontractExtensionQueryResult = ApolloReactCommon.QueryResult<GetSubcontractExtensionQuery, GetSubcontractExtensionQueryVariables>;
export const GetSubcontractTitleDocument = gql`
    query getSubcontractTitle($ID: String, $subcontractorID: String, $contractID: String) {
  getSubcontract(ID: $ID, subcontractorID: $subcontractorID, contractID: $contractID) {
    ID
    title
    subcontractStatus
    cmgdDate
    cpcDate
    dlpDate
  }
}
    `;

/**
 * __useGetSubcontractTitleQuery__
 *
 * To run a query within a React component, call `useGetSubcontractTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractTitleQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      subcontractorID: // value for 'subcontractorID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetSubcontractTitleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractTitleQuery, GetSubcontractTitleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractTitleQuery, GetSubcontractTitleQueryVariables>(GetSubcontractTitleDocument, baseOptions);
      }
export function useGetSubcontractTitleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractTitleQuery, GetSubcontractTitleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractTitleQuery, GetSubcontractTitleQueryVariables>(GetSubcontractTitleDocument, baseOptions);
        }
export type GetSubcontractTitleQueryHookResult = ReturnType<typeof useGetSubcontractTitleQuery>;
export type GetSubcontractTitleLazyQueryHookResult = ReturnType<typeof useGetSubcontractTitleLazyQuery>;
export type GetSubcontractTitleQueryResult = ApolloReactCommon.QueryResult<GetSubcontractTitleQuery, GetSubcontractTitleQueryVariables>;
export const GetSubcontractRelatedDocument = gql`
    query getSubcontractRelated($subcontractID: String) {
  getSubcontractRelated(subcontractID: $subcontractID) {
    ID
    title
  }
}
    `;

/**
 * __useGetSubcontractRelatedQuery__
 *
 * To run a query within a React component, call `useGetSubcontractRelatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractRelatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractRelatedQuery({
 *   variables: {
 *      subcontractID: // value for 'subcontractID'
 *   },
 * });
 */
export function useGetSubcontractRelatedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractRelatedQuery, GetSubcontractRelatedQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractRelatedQuery, GetSubcontractRelatedQueryVariables>(GetSubcontractRelatedDocument, baseOptions);
      }
export function useGetSubcontractRelatedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractRelatedQuery, GetSubcontractRelatedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractRelatedQuery, GetSubcontractRelatedQueryVariables>(GetSubcontractRelatedDocument, baseOptions);
        }
export type GetSubcontractRelatedQueryHookResult = ReturnType<typeof useGetSubcontractRelatedQuery>;
export type GetSubcontractRelatedLazyQueryHookResult = ReturnType<typeof useGetSubcontractRelatedLazyQuery>;
export type GetSubcontractRelatedQueryResult = ApolloReactCommon.QueryResult<GetSubcontractRelatedQuery, GetSubcontractRelatedQueryVariables>;
export const SubconBudgetCheckingDocument = gql`
    query subconBudgetChecking($amount: Float!, $contractID: String!, $costCategoryID: String!) {
  subconBudgetChecking(amount: $amount, contractID: $contractID, costCategoryID: $costCategoryID) {
    isExceeded
    isAllocated
  }
}
    `;

/**
 * __useSubconBudgetCheckingQuery__
 *
 * To run a query within a React component, call `useSubconBudgetCheckingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubconBudgetCheckingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubconBudgetCheckingQuery({
 *   variables: {
 *      amount: // value for 'amount'
 *      contractID: // value for 'contractID'
 *      costCategoryID: // value for 'costCategoryID'
 *   },
 * });
 */
export function useSubconBudgetCheckingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SubconBudgetCheckingQuery, SubconBudgetCheckingQueryVariables>) {
        return ApolloReactHooks.useQuery<SubconBudgetCheckingQuery, SubconBudgetCheckingQueryVariables>(SubconBudgetCheckingDocument, baseOptions);
      }
export function useSubconBudgetCheckingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SubconBudgetCheckingQuery, SubconBudgetCheckingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SubconBudgetCheckingQuery, SubconBudgetCheckingQueryVariables>(SubconBudgetCheckingDocument, baseOptions);
        }
export type SubconBudgetCheckingQueryHookResult = ReturnType<typeof useSubconBudgetCheckingQuery>;
export type SubconBudgetCheckingLazyQueryHookResult = ReturnType<typeof useSubconBudgetCheckingLazyQuery>;
export type SubconBudgetCheckingQueryResult = ApolloReactCommon.QueryResult<SubconBudgetCheckingQuery, SubconBudgetCheckingQueryVariables>;
export const GetSubcontractorForSubcontractDocument = gql`
    query getSubcontractorForSubcontract($associatedStatus: String) {
  getSubcontractor(associatedStatus: $associatedStatus) {
    name
    ID
    associatedStatus
    nominatedSC
  }
}
    `;

/**
 * __useGetSubcontractorForSubcontractQuery__
 *
 * To run a query within a React component, call `useGetSubcontractorForSubcontractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractorForSubcontractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractorForSubcontractQuery({
 *   variables: {
 *      associatedStatus: // value for 'associatedStatus'
 *   },
 * });
 */
export function useGetSubcontractorForSubcontractQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractorForSubcontractQuery, GetSubcontractorForSubcontractQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractorForSubcontractQuery, GetSubcontractorForSubcontractQueryVariables>(GetSubcontractorForSubcontractDocument, baseOptions);
      }
export function useGetSubcontractorForSubcontractLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractorForSubcontractQuery, GetSubcontractorForSubcontractQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractorForSubcontractQuery, GetSubcontractorForSubcontractQueryVariables>(GetSubcontractorForSubcontractDocument, baseOptions);
        }
export type GetSubcontractorForSubcontractQueryHookResult = ReturnType<typeof useGetSubcontractorForSubcontractQuery>;
export type GetSubcontractorForSubcontractLazyQueryHookResult = ReturnType<typeof useGetSubcontractorForSubcontractLazyQuery>;
export type GetSubcontractorForSubcontractQueryResult = ApolloReactCommon.QueryResult<GetSubcontractorForSubcontractQuery, GetSubcontractorForSubcontractQueryVariables>;
export const GetContractVoDocument = gql`
    query getContractVO($ID: String, $contractID: String, $orderByAsc: String) {
  getContractVO(ID: $ID, contractID: $contractID, orderByAsc: $orderByAsc) {
    ID
    accountID
    contractID
    docNo
    docRef
    startDate
    endDate
    description
    architectNo
    engineeringNo
    VOType
    VOStatus
    submissionAmt
    submittedDate
    rejectedDate
    approvedAmt
    docDate
    remarks
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
  }
}
    `;

/**
 * __useGetContractVoQuery__
 *
 * To run a query within a React component, call `useGetContractVoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractVoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractVoQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetContractVoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractVoQuery, GetContractVoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractVoQuery, GetContractVoQueryVariables>(GetContractVoDocument, baseOptions);
      }
export function useGetContractVoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractVoQuery, GetContractVoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractVoQuery, GetContractVoQueryVariables>(GetContractVoDocument, baseOptions);
        }
export type GetContractVoQueryHookResult = ReturnType<typeof useGetContractVoQuery>;
export type GetContractVoLazyQueryHookResult = ReturnType<typeof useGetContractVoLazyQuery>;
export type GetContractVoQueryResult = ApolloReactCommon.QueryResult<GetContractVoQuery, GetContractVoQueryVariables>;
export const CreateContractVoDocument = gql`
    mutation createContractVO($input: ContractVOInput!) {
  createContractVO(input: $input) {
    ID
  }
}
    `;
export type CreateContractVoMutationFn = ApolloReactCommon.MutationFunction<CreateContractVoMutation, CreateContractVoMutationVariables>;

/**
 * __useCreateContractVoMutation__
 *
 * To run a mutation, you first call `useCreateContractVoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractVoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractVoMutation, { data, loading, error }] = useCreateContractVoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContractVoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContractVoMutation, CreateContractVoMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContractVoMutation, CreateContractVoMutationVariables>(CreateContractVoDocument, baseOptions);
      }
export type CreateContractVoMutationHookResult = ReturnType<typeof useCreateContractVoMutation>;
export type CreateContractVoMutationResult = ApolloReactCommon.MutationResult<CreateContractVoMutation>;
export type CreateContractVoMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateContractVoMutation, CreateContractVoMutationVariables>;
export const UpdateContractVoDocument = gql`
    mutation updateContractVO($input: ContractVOInput!) {
  updateContractVO(input: $input) {
    ID
  }
}
    `;
export type UpdateContractVoMutationFn = ApolloReactCommon.MutationFunction<UpdateContractVoMutation, UpdateContractVoMutationVariables>;

/**
 * __useUpdateContractVoMutation__
 *
 * To run a mutation, you first call `useUpdateContractVoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractVoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractVoMutation, { data, loading, error }] = useUpdateContractVoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractVoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContractVoMutation, UpdateContractVoMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateContractVoMutation, UpdateContractVoMutationVariables>(UpdateContractVoDocument, baseOptions);
      }
export type UpdateContractVoMutationHookResult = ReturnType<typeof useUpdateContractVoMutation>;
export type UpdateContractVoMutationResult = ApolloReactCommon.MutationResult<UpdateContractVoMutation>;
export type UpdateContractVoMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateContractVoMutation, UpdateContractVoMutationVariables>;
export const ActionOnVoStatusDocument = gql`
    mutation actionOnVOStatus($input: ContractVOActionInput!, $ID: String!) {
  actionOnVOStatus(input: $input, ID: $ID)
}
    `;
export type ActionOnVoStatusMutationFn = ApolloReactCommon.MutationFunction<ActionOnVoStatusMutation, ActionOnVoStatusMutationVariables>;

/**
 * __useActionOnVoStatusMutation__
 *
 * To run a mutation, you first call `useActionOnVoStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionOnVoStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionOnVoStatusMutation, { data, loading, error }] = useActionOnVoStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useActionOnVoStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActionOnVoStatusMutation, ActionOnVoStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<ActionOnVoStatusMutation, ActionOnVoStatusMutationVariables>(ActionOnVoStatusDocument, baseOptions);
      }
export type ActionOnVoStatusMutationHookResult = ReturnType<typeof useActionOnVoStatusMutation>;
export type ActionOnVoStatusMutationResult = ApolloReactCommon.MutationResult<ActionOnVoStatusMutation>;
export type ActionOnVoStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<ActionOnVoStatusMutation, ActionOnVoStatusMutationVariables>;
export const GetContractVoSummaryDocument = gql`
    query getContractVOSummary($contractID: String!) {
  contractVO(contractID: $contractID) {
    voSum
    voOmission
    voSumCount
    voOmissionCount
    totalVoAmt
  }
}
    `;

/**
 * __useGetContractVoSummaryQuery__
 *
 * To run a query within a React component, call `useGetContractVoSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractVoSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractVoSummaryQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetContractVoSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractVoSummaryQuery, GetContractVoSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractVoSummaryQuery, GetContractVoSummaryQueryVariables>(GetContractVoSummaryDocument, baseOptions);
      }
export function useGetContractVoSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractVoSummaryQuery, GetContractVoSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractVoSummaryQuery, GetContractVoSummaryQueryVariables>(GetContractVoSummaryDocument, baseOptions);
        }
export type GetContractVoSummaryQueryHookResult = ReturnType<typeof useGetContractVoSummaryQuery>;
export type GetContractVoSummaryLazyQueryHookResult = ReturnType<typeof useGetContractVoSummaryLazyQuery>;
export type GetContractVoSummaryQueryResult = ApolloReactCommon.QueryResult<GetContractVoSummaryQuery, GetContractVoSummaryQueryVariables>;
export const GetSubcontractVoDocument = gql`
    query getSubcontractVO($ID: String, $subcontractID: String, $orderByAsc: String) {
  getSubcontractVO(ID: $ID, subcontractID: $subcontractID, orderByAsc: $orderByAsc) {
    ID
    accountID
    subcontractID
    docNo
    docRef
    startDate
    endDate
    description
    architectNo
    engineeringNo
    VOType
    VOStatus
    submissionAmt
    approvedAmt
    remarks
    docDate
    remarks
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
    }
  }
}
    `;

/**
 * __useGetSubcontractVoQuery__
 *
 * To run a query within a React component, call `useGetSubcontractVoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractVoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractVoQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      subcontractID: // value for 'subcontractID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetSubcontractVoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractVoQuery, GetSubcontractVoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractVoQuery, GetSubcontractVoQueryVariables>(GetSubcontractVoDocument, baseOptions);
      }
export function useGetSubcontractVoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractVoQuery, GetSubcontractVoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractVoQuery, GetSubcontractVoQueryVariables>(GetSubcontractVoDocument, baseOptions);
        }
export type GetSubcontractVoQueryHookResult = ReturnType<typeof useGetSubcontractVoQuery>;
export type GetSubcontractVoLazyQueryHookResult = ReturnType<typeof useGetSubcontractVoLazyQuery>;
export type GetSubcontractVoQueryResult = ApolloReactCommon.QueryResult<GetSubcontractVoQuery, GetSubcontractVoQueryVariables>;
export const CreateSubcontractVoDocument = gql`
    mutation createSubcontractVO($input: SubcontractVOInput!) {
  createSubcontractVO(input: $input) {
    ID
  }
}
    `;
export type CreateSubcontractVoMutationFn = ApolloReactCommon.MutationFunction<CreateSubcontractVoMutation, CreateSubcontractVoMutationVariables>;

/**
 * __useCreateSubcontractVoMutation__
 *
 * To run a mutation, you first call `useCreateSubcontractVoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubcontractVoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubcontractVoMutation, { data, loading, error }] = useCreateSubcontractVoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubcontractVoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSubcontractVoMutation, CreateSubcontractVoMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSubcontractVoMutation, CreateSubcontractVoMutationVariables>(CreateSubcontractVoDocument, baseOptions);
      }
export type CreateSubcontractVoMutationHookResult = ReturnType<typeof useCreateSubcontractVoMutation>;
export type CreateSubcontractVoMutationResult = ApolloReactCommon.MutationResult<CreateSubcontractVoMutation>;
export type CreateSubcontractVoMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSubcontractVoMutation, CreateSubcontractVoMutationVariables>;
export const UpdateSubcontractVoDocument = gql`
    mutation updateSubcontractVO($input: SubcontractVOInput!) {
  updateSubcontractVO(input: $input) {
    ID
  }
}
    `;
export type UpdateSubcontractVoMutationFn = ApolloReactCommon.MutationFunction<UpdateSubcontractVoMutation, UpdateSubcontractVoMutationVariables>;

/**
 * __useUpdateSubcontractVoMutation__
 *
 * To run a mutation, you first call `useUpdateSubcontractVoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubcontractVoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubcontractVoMutation, { data, loading, error }] = useUpdateSubcontractVoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubcontractVoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSubcontractVoMutation, UpdateSubcontractVoMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSubcontractVoMutation, UpdateSubcontractVoMutationVariables>(UpdateSubcontractVoDocument, baseOptions);
      }
export type UpdateSubcontractVoMutationHookResult = ReturnType<typeof useUpdateSubcontractVoMutation>;
export type UpdateSubcontractVoMutationResult = ApolloReactCommon.MutationResult<UpdateSubcontractVoMutation>;
export type UpdateSubcontractVoMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSubcontractVoMutation, UpdateSubcontractVoMutationVariables>;
export const ActionOnSubconVoStatusDocument = gql`
    mutation actionOnSubconVOStatus($input: SubcontractVOActionInput!, $ID: String!) {
  actionOnSubconVOStatus(input: $input, ID: $ID)
}
    `;
export type ActionOnSubconVoStatusMutationFn = ApolloReactCommon.MutationFunction<ActionOnSubconVoStatusMutation, ActionOnSubconVoStatusMutationVariables>;

/**
 * __useActionOnSubconVoStatusMutation__
 *
 * To run a mutation, you first call `useActionOnSubconVoStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionOnSubconVoStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionOnSubconVoStatusMutation, { data, loading, error }] = useActionOnSubconVoStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useActionOnSubconVoStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActionOnSubconVoStatusMutation, ActionOnSubconVoStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<ActionOnSubconVoStatusMutation, ActionOnSubconVoStatusMutationVariables>(ActionOnSubconVoStatusDocument, baseOptions);
      }
export type ActionOnSubconVoStatusMutationHookResult = ReturnType<typeof useActionOnSubconVoStatusMutation>;
export type ActionOnSubconVoStatusMutationResult = ApolloReactCommon.MutationResult<ActionOnSubconVoStatusMutation>;
export type ActionOnSubconVoStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<ActionOnSubconVoStatusMutation, ActionOnSubconVoStatusMutationVariables>;
export const GetSubcontractVoSummaryDocument = gql`
    query getSubcontractVOSummary($subcontractID: String!) {
  subcontractVO(subcontractID: $subcontractID) {
    voSum
    voOmission
    voSumCount
    voOmissionCount
    totalVoAmt
  }
}
    `;

/**
 * __useGetSubcontractVoSummaryQuery__
 *
 * To run a query within a React component, call `useGetSubcontractVoSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubcontractVoSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubcontractVoSummaryQuery({
 *   variables: {
 *      subcontractID: // value for 'subcontractID'
 *   },
 * });
 */
export function useGetSubcontractVoSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubcontractVoSummaryQuery, GetSubcontractVoSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubcontractVoSummaryQuery, GetSubcontractVoSummaryQueryVariables>(GetSubcontractVoSummaryDocument, baseOptions);
      }
export function useGetSubcontractVoSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubcontractVoSummaryQuery, GetSubcontractVoSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubcontractVoSummaryQuery, GetSubcontractVoSummaryQueryVariables>(GetSubcontractVoSummaryDocument, baseOptions);
        }
export type GetSubcontractVoSummaryQueryHookResult = ReturnType<typeof useGetSubcontractVoSummaryQuery>;
export type GetSubcontractVoSummaryLazyQueryHookResult = ReturnType<typeof useGetSubcontractVoSummaryLazyQuery>;
export type GetSubcontractVoSummaryQueryResult = ApolloReactCommon.QueryResult<GetSubcontractVoSummaryQuery, GetSubcontractVoSummaryQueryVariables>;
export const DocumentListingDocument = gql`
    query DocumentListing($refID: String, $refTable: String, $description: String, $notDescription: String, $hasDescription: Boolean) {
  DocumentListing(refID: $refID, refTable: $refTable, description: $description, notDescription: $notDescription, hasDescription: $hasDescription) {
    ID
    fileName
    fileURL
    mediaType
    createdTs
    description
    refID
  }
}
    `;

/**
 * __useDocumentListingQuery__
 *
 * To run a query within a React component, call `useDocumentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentListingQuery({
 *   variables: {
 *      refID: // value for 'refID'
 *      refTable: // value for 'refTable'
 *      description: // value for 'description'
 *      notDescription: // value for 'notDescription'
 *      hasDescription: // value for 'hasDescription'
 *   },
 * });
 */
export function useDocumentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DocumentListingQuery, DocumentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<DocumentListingQuery, DocumentListingQueryVariables>(DocumentListingDocument, baseOptions);
      }
export function useDocumentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DocumentListingQuery, DocumentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DocumentListingQuery, DocumentListingQueryVariables>(DocumentListingDocument, baseOptions);
        }
export type DocumentListingQueryHookResult = ReturnType<typeof useDocumentListingQuery>;
export type DocumentListingLazyQueryHookResult = ReturnType<typeof useDocumentListingLazyQuery>;
export type DocumentListingQueryResult = ApolloReactCommon.QueryResult<DocumentListingQuery, DocumentListingQueryVariables>;
export const UploadPdfDocumentDocument = gql`
    mutation uploadPDFDocument($input: LedgerPDFInput!) {
  uploadPDFDocument(input: $input)
}
    `;
export type UploadPdfDocumentMutationFn = ApolloReactCommon.MutationFunction<UploadPdfDocumentMutation, UploadPdfDocumentMutationVariables>;

/**
 * __useUploadPdfDocumentMutation__
 *
 * To run a mutation, you first call `useUploadPdfDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadPdfDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadPdfDocumentMutation, { data, loading, error }] = useUploadPdfDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadPdfDocumentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadPdfDocumentMutation, UploadPdfDocumentMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadPdfDocumentMutation, UploadPdfDocumentMutationVariables>(UploadPdfDocumentDocument, baseOptions);
      }
export type UploadPdfDocumentMutationHookResult = ReturnType<typeof useUploadPdfDocumentMutation>;
export type UploadPdfDocumentMutationResult = ApolloReactCommon.MutationResult<UploadPdfDocumentMutation>;
export type UploadPdfDocumentMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadPdfDocumentMutation, UploadPdfDocumentMutationVariables>;
export const TestSampleEnumDocument = gql`
    query testSampleEnum($SampleEnum: SampleEnum!) {
  testSampleEnum(SampleEnum: $SampleEnum)
}
    `;

/**
 * __useTestSampleEnumQuery__
 *
 * To run a query within a React component, call `useTestSampleEnumQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestSampleEnumQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestSampleEnumQuery({
 *   variables: {
 *      SampleEnum: // value for 'SampleEnum'
 *   },
 * });
 */
export function useTestSampleEnumQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TestSampleEnumQuery, TestSampleEnumQueryVariables>) {
        return ApolloReactHooks.useQuery<TestSampleEnumQuery, TestSampleEnumQueryVariables>(TestSampleEnumDocument, baseOptions);
      }
export function useTestSampleEnumLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TestSampleEnumQuery, TestSampleEnumQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TestSampleEnumQuery, TestSampleEnumQueryVariables>(TestSampleEnumDocument, baseOptions);
        }
export type TestSampleEnumQueryHookResult = ReturnType<typeof useTestSampleEnumQuery>;
export type TestSampleEnumLazyQueryHookResult = ReturnType<typeof useTestSampleEnumLazyQuery>;
export type TestSampleEnumQueryResult = ApolloReactCommon.QueryResult<TestSampleEnumQuery, TestSampleEnumQueryVariables>;
export const UploadCompanyLogoDocument = gql`
    mutation uploadCompanyLogo($file: Upload) {
  uploadCompanyLogo(file: $file)
}
    `;
export type UploadCompanyLogoMutationFn = ApolloReactCommon.MutationFunction<UploadCompanyLogoMutation, UploadCompanyLogoMutationVariables>;

/**
 * __useUploadCompanyLogoMutation__
 *
 * To run a mutation, you first call `useUploadCompanyLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadCompanyLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadCompanyLogoMutation, { data, loading, error }] = useUploadCompanyLogoMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadCompanyLogoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadCompanyLogoMutation, UploadCompanyLogoMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadCompanyLogoMutation, UploadCompanyLogoMutationVariables>(UploadCompanyLogoDocument, baseOptions);
      }
export type UploadCompanyLogoMutationHookResult = ReturnType<typeof useUploadCompanyLogoMutation>;
export type UploadCompanyLogoMutationResult = ApolloReactCommon.MutationResult<UploadCompanyLogoMutation>;
export type UploadCompanyLogoMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadCompanyLogoMutation, UploadCompanyLogoMutationVariables>;
export const LogoViewDocument = gql`
    query logoView($refID: String, $description: String) {
  logoView(refID: $refID, description: $description) {
    fileName
    fileURL
  }
}
    `;

/**
 * __useLogoViewQuery__
 *
 * To run a query within a React component, call `useLogoViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogoViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogoViewQuery({
 *   variables: {
 *      refID: // value for 'refID'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useLogoViewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LogoViewQuery, LogoViewQueryVariables>) {
        return ApolloReactHooks.useQuery<LogoViewQuery, LogoViewQueryVariables>(LogoViewDocument, baseOptions);
      }
export function useLogoViewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LogoViewQuery, LogoViewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LogoViewQuery, LogoViewQueryVariables>(LogoViewDocument, baseOptions);
        }
export type LogoViewQueryHookResult = ReturnType<typeof useLogoViewQuery>;
export type LogoViewLazyQueryHookResult = ReturnType<typeof useLogoViewLazyQuery>;
export type LogoViewQueryResult = ApolloReactCommon.QueryResult<LogoViewQuery, LogoViewQueryVariables>;
export const GetBankAccountDocument = gql`
    query getBankAccount($ID: String, $orderByAsc: String) {
  getBankAccount(ID: $ID, orderByAsc: $orderByAsc) {
    ID
    status
    accountID
    name
    accountHolder
    accountNo
    contactNo
    branch
    address
    swiftCode
    contactPerson
  }
}
    `;

/**
 * __useGetBankAccountQuery__
 *
 * To run a query within a React component, call `useGetBankAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankAccountQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetBankAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBankAccountQuery, GetBankAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBankAccountQuery, GetBankAccountQueryVariables>(GetBankAccountDocument, baseOptions);
      }
export function useGetBankAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBankAccountQuery, GetBankAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBankAccountQuery, GetBankAccountQueryVariables>(GetBankAccountDocument, baseOptions);
        }
export type GetBankAccountQueryHookResult = ReturnType<typeof useGetBankAccountQuery>;
export type GetBankAccountLazyQueryHookResult = ReturnType<typeof useGetBankAccountLazyQuery>;
export type GetBankAccountQueryResult = ApolloReactCommon.QueryResult<GetBankAccountQuery, GetBankAccountQueryVariables>;
export const CreateBankAccountDocument = gql`
    mutation createBankAccount($input: BankAccountInput!) {
  createBankAccount(input: $input) {
    ID
  }
}
    `;
export type CreateBankAccountMutationFn = ApolloReactCommon.MutationFunction<CreateBankAccountMutation, CreateBankAccountMutationVariables>;

/**
 * __useCreateBankAccountMutation__
 *
 * To run a mutation, you first call `useCreateBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBankAccountMutation, { data, loading, error }] = useCreateBankAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBankAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBankAccountMutation, CreateBankAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBankAccountMutation, CreateBankAccountMutationVariables>(CreateBankAccountDocument, baseOptions);
      }
export type CreateBankAccountMutationHookResult = ReturnType<typeof useCreateBankAccountMutation>;
export type CreateBankAccountMutationResult = ApolloReactCommon.MutationResult<CreateBankAccountMutation>;
export type CreateBankAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBankAccountMutation, CreateBankAccountMutationVariables>;
export const UpdateBankAccountDocument = gql`
    mutation updateBankAccount($input: BankAccountInput!) {
  updateBankAccount(input: $input) {
    ID
  }
}
    `;
export type UpdateBankAccountMutationFn = ApolloReactCommon.MutationFunction<UpdateBankAccountMutation, UpdateBankAccountMutationVariables>;

/**
 * __useUpdateBankAccountMutation__
 *
 * To run a mutation, you first call `useUpdateBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBankAccountMutation, { data, loading, error }] = useUpdateBankAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBankAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBankAccountMutation, UpdateBankAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBankAccountMutation, UpdateBankAccountMutationVariables>(UpdateBankAccountDocument, baseOptions);
      }
export type UpdateBankAccountMutationHookResult = ReturnType<typeof useUpdateBankAccountMutation>;
export type UpdateBankAccountMutationResult = ApolloReactCommon.MutationResult<UpdateBankAccountMutation>;
export type UpdateBankAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBankAccountMutation, UpdateBankAccountMutationVariables>;
export const CancelBankAccountDocument = gql`
    mutation cancelBankAccount($input: BankAccountCancellationInput!, $ID: String!) {
  cancelBankAccount(input: $input, ID: $ID)
}
    `;
export type CancelBankAccountMutationFn = ApolloReactCommon.MutationFunction<CancelBankAccountMutation, CancelBankAccountMutationVariables>;

/**
 * __useCancelBankAccountMutation__
 *
 * To run a mutation, you first call `useCancelBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelBankAccountMutation, { data, loading, error }] = useCancelBankAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useCancelBankAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelBankAccountMutation, CancelBankAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelBankAccountMutation, CancelBankAccountMutationVariables>(CancelBankAccountDocument, baseOptions);
      }
export type CancelBankAccountMutationHookResult = ReturnType<typeof useCancelBankAccountMutation>;
export type CancelBankAccountMutationResult = ApolloReactCommon.MutationResult<CancelBankAccountMutation>;
export type CancelBankAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelBankAccountMutation, CancelBankAccountMutationVariables>;
export const GetCostCategoryDocument = gql`
    query getCostCategory($ID: String, $costClass: CostClass, $orderByAsc: String, $orderByDesc: String, $commonStatus: CommonStatus) {
  getCostCategory(ID: $ID, costClass: $costClass, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, commonStatus: $commonStatus) {
    ID
    name
    modTs
    accountID
    commonStatus
    pnmCostCat
    costClass
    supplier {
      name
      ID
    }
    subcontractor {
      name
      ID
    }
    costItem {
      ID
      name
      unitPrice
      UOM {
        ID
        name
        code
      }
    }
    budget {
      ID
      createdTs
      budgetAmt
    }
  }
  costCategoryDates
}
    `;

/**
 * __useGetCostCategoryQuery__
 *
 * To run a query within a React component, call `useGetCostCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostCategoryQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      costClass: // value for 'costClass'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      commonStatus: // value for 'commonStatus'
 *   },
 * });
 */
export function useGetCostCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostCategoryQuery, GetCostCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostCategoryQuery, GetCostCategoryQueryVariables>(GetCostCategoryDocument, baseOptions);
      }
export function useGetCostCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostCategoryQuery, GetCostCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostCategoryQuery, GetCostCategoryQueryVariables>(GetCostCategoryDocument, baseOptions);
        }
export type GetCostCategoryQueryHookResult = ReturnType<typeof useGetCostCategoryQuery>;
export type GetCostCategoryLazyQueryHookResult = ReturnType<typeof useGetCostCategoryLazyQuery>;
export type GetCostCategoryQueryResult = ApolloReactCommon.QueryResult<GetCostCategoryQuery, GetCostCategoryQueryVariables>;
export const CreateCostCategoryDocument = gql`
    mutation createCostCategory($input: CostCategoryInput!) {
  createCostCategory(input: $input) {
    ID
  }
}
    `;
export type CreateCostCategoryMutationFn = ApolloReactCommon.MutationFunction<CreateCostCategoryMutation, CreateCostCategoryMutationVariables>;

/**
 * __useCreateCostCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCostCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCostCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCostCategoryMutation, { data, loading, error }] = useCreateCostCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCostCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCostCategoryMutation, CreateCostCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCostCategoryMutation, CreateCostCategoryMutationVariables>(CreateCostCategoryDocument, baseOptions);
      }
export type CreateCostCategoryMutationHookResult = ReturnType<typeof useCreateCostCategoryMutation>;
export type CreateCostCategoryMutationResult = ApolloReactCommon.MutationResult<CreateCostCategoryMutation>;
export type CreateCostCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCostCategoryMutation, CreateCostCategoryMutationVariables>;
export const UpdateCostCategoryDocument = gql`
    mutation updateCostCategory($input: CostCategoryInput!) {
  updateCostCategory(input: $input)
}
    `;
export type UpdateCostCategoryMutationFn = ApolloReactCommon.MutationFunction<UpdateCostCategoryMutation, UpdateCostCategoryMutationVariables>;

/**
 * __useUpdateCostCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCostCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCostCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCostCategoryMutation, { data, loading, error }] = useUpdateCostCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCostCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCostCategoryMutation, UpdateCostCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCostCategoryMutation, UpdateCostCategoryMutationVariables>(UpdateCostCategoryDocument, baseOptions);
      }
export type UpdateCostCategoryMutationHookResult = ReturnType<typeof useUpdateCostCategoryMutation>;
export type UpdateCostCategoryMutationResult = ApolloReactCommon.MutationResult<UpdateCostCategoryMutation>;
export type UpdateCostCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCostCategoryMutation, UpdateCostCategoryMutationVariables>;
export const DeleteCostCategoryDocument = gql`
    mutation deleteCostCategory($input: CostCategoryUpdateInput!) {
  deleteCostCategory(input: $input)
}
    `;
export type DeleteCostCategoryMutationFn = ApolloReactCommon.MutationFunction<DeleteCostCategoryMutation, DeleteCostCategoryMutationVariables>;

/**
 * __useDeleteCostCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCostCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCostCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCostCategoryMutation, { data, loading, error }] = useDeleteCostCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCostCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCostCategoryMutation, DeleteCostCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCostCategoryMutation, DeleteCostCategoryMutationVariables>(DeleteCostCategoryDocument, baseOptions);
      }
export type DeleteCostCategoryMutationHookResult = ReturnType<typeof useDeleteCostCategoryMutation>;
export type DeleteCostCategoryMutationResult = ApolloReactCommon.MutationResult<DeleteCostCategoryMutation>;
export type DeleteCostCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCostCategoryMutation, DeleteCostCategoryMutationVariables>;
export const AddSupplierInCCategoryDocument = gql`
    mutation addSupplierInCCategory($supplierID: [String!]!, $costCategoryID: String!) {
  addSupplierInCCategory(supplierID: $supplierID, costCategoryID: $costCategoryID)
}
    `;
export type AddSupplierInCCategoryMutationFn = ApolloReactCommon.MutationFunction<AddSupplierInCCategoryMutation, AddSupplierInCCategoryMutationVariables>;

/**
 * __useAddSupplierInCCategoryMutation__
 *
 * To run a mutation, you first call `useAddSupplierInCCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSupplierInCCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSupplierInCCategoryMutation, { data, loading, error }] = useAddSupplierInCCategoryMutation({
 *   variables: {
 *      supplierID: // value for 'supplierID'
 *      costCategoryID: // value for 'costCategoryID'
 *   },
 * });
 */
export function useAddSupplierInCCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddSupplierInCCategoryMutation, AddSupplierInCCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<AddSupplierInCCategoryMutation, AddSupplierInCCategoryMutationVariables>(AddSupplierInCCategoryDocument, baseOptions);
      }
export type AddSupplierInCCategoryMutationHookResult = ReturnType<typeof useAddSupplierInCCategoryMutation>;
export type AddSupplierInCCategoryMutationResult = ApolloReactCommon.MutationResult<AddSupplierInCCategoryMutation>;
export type AddSupplierInCCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<AddSupplierInCCategoryMutation, AddSupplierInCCategoryMutationVariables>;
export const GetCostItemDocument = gql`
    query getCostItem($ID: String, $costCategoryID: String, $orderByAsc: String, $orderByDesc: String) {
  getCostItem(ID: $ID, costCategoryID: $costCategoryID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    createdTs
    createdBy
    modTs
    modBy
    ID
    accountID
    commonStatus
    costCategoryID
    UOM {
      ID
      name
      code
    }
    costCategory {
      ID
      name
    }
    name
    unitPrice
    onBehalf
    markupPerc
  }
  getCostCategory(ID: $costCategoryID) {
    name
    supplier {
      name
      ID
    }
  }
}
    `;

/**
 * __useGetCostItemQuery__
 *
 * To run a query within a React component, call `useGetCostItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostItemQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      costCategoryID: // value for 'costCategoryID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetCostItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostItemQuery, GetCostItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostItemQuery, GetCostItemQueryVariables>(GetCostItemDocument, baseOptions);
      }
export function useGetCostItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostItemQuery, GetCostItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostItemQuery, GetCostItemQueryVariables>(GetCostItemDocument, baseOptions);
        }
export type GetCostItemQueryHookResult = ReturnType<typeof useGetCostItemQuery>;
export type GetCostItemLazyQueryHookResult = ReturnType<typeof useGetCostItemLazyQuery>;
export type GetCostItemQueryResult = ApolloReactCommon.QueryResult<GetCostItemQuery, GetCostItemQueryVariables>;
export const CreateCostItemDocument = gql`
    mutation createCostItem($input: CostItemInput!) {
  createCostItem(input: $input) {
    ID
    name
  }
}
    `;
export type CreateCostItemMutationFn = ApolloReactCommon.MutationFunction<CreateCostItemMutation, CreateCostItemMutationVariables>;

/**
 * __useCreateCostItemMutation__
 *
 * To run a mutation, you first call `useCreateCostItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCostItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCostItemMutation, { data, loading, error }] = useCreateCostItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCostItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCostItemMutation, CreateCostItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCostItemMutation, CreateCostItemMutationVariables>(CreateCostItemDocument, baseOptions);
      }
export type CreateCostItemMutationHookResult = ReturnType<typeof useCreateCostItemMutation>;
export type CreateCostItemMutationResult = ApolloReactCommon.MutationResult<CreateCostItemMutation>;
export type CreateCostItemMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCostItemMutation, CreateCostItemMutationVariables>;
export const CancelCostItemDocument = gql`
    mutation cancelCostItem($ID: String!) {
  cancelCostItem(ID: $ID)
}
    `;
export type CancelCostItemMutationFn = ApolloReactCommon.MutationFunction<CancelCostItemMutation, CancelCostItemMutationVariables>;

/**
 * __useCancelCostItemMutation__
 *
 * To run a mutation, you first call `useCancelCostItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelCostItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelCostItemMutation, { data, loading, error }] = useCancelCostItemMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useCancelCostItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelCostItemMutation, CancelCostItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelCostItemMutation, CancelCostItemMutationVariables>(CancelCostItemDocument, baseOptions);
      }
export type CancelCostItemMutationHookResult = ReturnType<typeof useCancelCostItemMutation>;
export type CancelCostItemMutationResult = ApolloReactCommon.MutationResult<CancelCostItemMutation>;
export type CancelCostItemMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelCostItemMutation, CancelCostItemMutationVariables>;
export const UpdateCostItemDocument = gql`
    mutation updateCostItem($input: CostItemInput!) {
  updateCostItem(input: $input) {
    ID
    name
  }
}
    `;
export type UpdateCostItemMutationFn = ApolloReactCommon.MutationFunction<UpdateCostItemMutation, UpdateCostItemMutationVariables>;

/**
 * __useUpdateCostItemMutation__
 *
 * To run a mutation, you first call `useUpdateCostItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCostItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCostItemMutation, { data, loading, error }] = useUpdateCostItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCostItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCostItemMutation, UpdateCostItemMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCostItemMutation, UpdateCostItemMutationVariables>(UpdateCostItemDocument, baseOptions);
      }
export type UpdateCostItemMutationHookResult = ReturnType<typeof useUpdateCostItemMutation>;
export type UpdateCostItemMutationResult = ApolloReactCommon.MutationResult<UpdateCostItemMutation>;
export type UpdateCostItemMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCostItemMutation, UpdateCostItemMutationVariables>;
export const UpdateNumberFormatDocument = gql`
    mutation updateNumberFormat($input: NumberFormatInput!) {
  editNumberFormat(input: $input)
}
    `;
export type UpdateNumberFormatMutationFn = ApolloReactCommon.MutationFunction<UpdateNumberFormatMutation, UpdateNumberFormatMutationVariables>;

/**
 * __useUpdateNumberFormatMutation__
 *
 * To run a mutation, you first call `useUpdateNumberFormatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNumberFormatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNumberFormatMutation, { data, loading, error }] = useUpdateNumberFormatMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNumberFormatMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateNumberFormatMutation, UpdateNumberFormatMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateNumberFormatMutation, UpdateNumberFormatMutationVariables>(UpdateNumberFormatDocument, baseOptions);
      }
export type UpdateNumberFormatMutationHookResult = ReturnType<typeof useUpdateNumberFormatMutation>;
export type UpdateNumberFormatMutationResult = ApolloReactCommon.MutationResult<UpdateNumberFormatMutation>;
export type UpdateNumberFormatMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateNumberFormatMutation, UpdateNumberFormatMutationVariables>;
export const GetGeneralSettingExecutiveSummaryDocument = gql`
    query getGeneralSettingExecutiveSummary {
  userDate
  costCategoryDates
  guaranteeTypeDate
  insuranceTypeDate
  bankAccountDate
  docNumDate
  uomDate
  taxSchemeDate
  roleDate
  conAuthDate
}
    `;

/**
 * __useGetGeneralSettingExecutiveSummaryQuery__
 *
 * To run a query within a React component, call `useGetGeneralSettingExecutiveSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralSettingExecutiveSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralSettingExecutiveSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGeneralSettingExecutiveSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGeneralSettingExecutiveSummaryQuery, GetGeneralSettingExecutiveSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGeneralSettingExecutiveSummaryQuery, GetGeneralSettingExecutiveSummaryQueryVariables>(GetGeneralSettingExecutiveSummaryDocument, baseOptions);
      }
export function useGetGeneralSettingExecutiveSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGeneralSettingExecutiveSummaryQuery, GetGeneralSettingExecutiveSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGeneralSettingExecutiveSummaryQuery, GetGeneralSettingExecutiveSummaryQueryVariables>(GetGeneralSettingExecutiveSummaryDocument, baseOptions);
        }
export type GetGeneralSettingExecutiveSummaryQueryHookResult = ReturnType<typeof useGetGeneralSettingExecutiveSummaryQuery>;
export type GetGeneralSettingExecutiveSummaryLazyQueryHookResult = ReturnType<typeof useGetGeneralSettingExecutiveSummaryLazyQuery>;
export type GetGeneralSettingExecutiveSummaryQueryResult = ApolloReactCommon.QueryResult<GetGeneralSettingExecutiveSummaryQuery, GetGeneralSettingExecutiveSummaryQueryVariables>;
export const UpdateGuaranteeTypeDocument = gql`
    mutation updateGuaranteeType($input: GuarInsTypeInput!) {
  updateGuaranteeType(input: $input) {
    ID
    name
  }
}
    `;
export type UpdateGuaranteeTypeMutationFn = ApolloReactCommon.MutationFunction<UpdateGuaranteeTypeMutation, UpdateGuaranteeTypeMutationVariables>;

/**
 * __useUpdateGuaranteeTypeMutation__
 *
 * To run a mutation, you first call `useUpdateGuaranteeTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGuaranteeTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGuaranteeTypeMutation, { data, loading, error }] = useUpdateGuaranteeTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGuaranteeTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateGuaranteeTypeMutation, UpdateGuaranteeTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateGuaranteeTypeMutation, UpdateGuaranteeTypeMutationVariables>(UpdateGuaranteeTypeDocument, baseOptions);
      }
export type UpdateGuaranteeTypeMutationHookResult = ReturnType<typeof useUpdateGuaranteeTypeMutation>;
export type UpdateGuaranteeTypeMutationResult = ApolloReactCommon.MutationResult<UpdateGuaranteeTypeMutation>;
export type UpdateGuaranteeTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateGuaranteeTypeMutation, UpdateGuaranteeTypeMutationVariables>;
export const CreateGuaranteeTypeDocument = gql`
    mutation createGuaranteeType($input: GuarInsTypeInput!) {
  createGuaranteeType(input: $input) {
    name
  }
}
    `;
export type CreateGuaranteeTypeMutationFn = ApolloReactCommon.MutationFunction<CreateGuaranteeTypeMutation, CreateGuaranteeTypeMutationVariables>;

/**
 * __useCreateGuaranteeTypeMutation__
 *
 * To run a mutation, you first call `useCreateGuaranteeTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGuaranteeTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGuaranteeTypeMutation, { data, loading, error }] = useCreateGuaranteeTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGuaranteeTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateGuaranteeTypeMutation, CreateGuaranteeTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateGuaranteeTypeMutation, CreateGuaranteeTypeMutationVariables>(CreateGuaranteeTypeDocument, baseOptions);
      }
export type CreateGuaranteeTypeMutationHookResult = ReturnType<typeof useCreateGuaranteeTypeMutation>;
export type CreateGuaranteeTypeMutationResult = ApolloReactCommon.MutationResult<CreateGuaranteeTypeMutation>;
export type CreateGuaranteeTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateGuaranteeTypeMutation, CreateGuaranteeTypeMutationVariables>;
export const GetGuaranteeTypeDocument = gql`
    query getGuaranteeType($ID: String) {
  getGuaranteeType(ID: $ID) {
    ID
    name
    commonStatus
    modTs
  }
  guaranteeTypeDate
}
    `;

/**
 * __useGetGuaranteeTypeQuery__
 *
 * To run a query within a React component, call `useGetGuaranteeTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGuaranteeTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGuaranteeTypeQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetGuaranteeTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGuaranteeTypeQuery, GetGuaranteeTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGuaranteeTypeQuery, GetGuaranteeTypeQueryVariables>(GetGuaranteeTypeDocument, baseOptions);
      }
export function useGetGuaranteeTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGuaranteeTypeQuery, GetGuaranteeTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGuaranteeTypeQuery, GetGuaranteeTypeQueryVariables>(GetGuaranteeTypeDocument, baseOptions);
        }
export type GetGuaranteeTypeQueryHookResult = ReturnType<typeof useGetGuaranteeTypeQuery>;
export type GetGuaranteeTypeLazyQueryHookResult = ReturnType<typeof useGetGuaranteeTypeLazyQuery>;
export type GetGuaranteeTypeQueryResult = ApolloReactCommon.QueryResult<GetGuaranteeTypeQuery, GetGuaranteeTypeQueryVariables>;
export const DeleteGuaranteeTypeDocument = gql`
    mutation deleteGuaranteeType($ID: GuarInsTypeDeleteInput!) {
  deleteGuaranteeType(input: $ID)
}
    `;
export type DeleteGuaranteeTypeMutationFn = ApolloReactCommon.MutationFunction<DeleteGuaranteeTypeMutation, DeleteGuaranteeTypeMutationVariables>;

/**
 * __useDeleteGuaranteeTypeMutation__
 *
 * To run a mutation, you first call `useDeleteGuaranteeTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGuaranteeTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGuaranteeTypeMutation, { data, loading, error }] = useDeleteGuaranteeTypeMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteGuaranteeTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteGuaranteeTypeMutation, DeleteGuaranteeTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteGuaranteeTypeMutation, DeleteGuaranteeTypeMutationVariables>(DeleteGuaranteeTypeDocument, baseOptions);
      }
export type DeleteGuaranteeTypeMutationHookResult = ReturnType<typeof useDeleteGuaranteeTypeMutation>;
export type DeleteGuaranteeTypeMutationResult = ApolloReactCommon.MutationResult<DeleteGuaranteeTypeMutation>;
export type DeleteGuaranteeTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteGuaranteeTypeMutation, DeleteGuaranteeTypeMutationVariables>;
export const UpdateInsuranceTypeDocument = gql`
    mutation updateInsuranceType($input: GuarInsTypeInput!) {
  updateInsuranceType(input: $input) {
    ID
    name
  }
}
    `;
export type UpdateInsuranceTypeMutationFn = ApolloReactCommon.MutationFunction<UpdateInsuranceTypeMutation, UpdateInsuranceTypeMutationVariables>;

/**
 * __useUpdateInsuranceTypeMutation__
 *
 * To run a mutation, you first call `useUpdateInsuranceTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInsuranceTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInsuranceTypeMutation, { data, loading, error }] = useUpdateInsuranceTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInsuranceTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateInsuranceTypeMutation, UpdateInsuranceTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateInsuranceTypeMutation, UpdateInsuranceTypeMutationVariables>(UpdateInsuranceTypeDocument, baseOptions);
      }
export type UpdateInsuranceTypeMutationHookResult = ReturnType<typeof useUpdateInsuranceTypeMutation>;
export type UpdateInsuranceTypeMutationResult = ApolloReactCommon.MutationResult<UpdateInsuranceTypeMutation>;
export type UpdateInsuranceTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateInsuranceTypeMutation, UpdateInsuranceTypeMutationVariables>;
export const CreateInsuranceTypeDocument = gql`
    mutation createInsuranceType($input: GuarInsTypeInput!) {
  createInsuranceType(input: $input) {
    name
  }
}
    `;
export type CreateInsuranceTypeMutationFn = ApolloReactCommon.MutationFunction<CreateInsuranceTypeMutation, CreateInsuranceTypeMutationVariables>;

/**
 * __useCreateInsuranceTypeMutation__
 *
 * To run a mutation, you first call `useCreateInsuranceTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInsuranceTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInsuranceTypeMutation, { data, loading, error }] = useCreateInsuranceTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInsuranceTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateInsuranceTypeMutation, CreateInsuranceTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateInsuranceTypeMutation, CreateInsuranceTypeMutationVariables>(CreateInsuranceTypeDocument, baseOptions);
      }
export type CreateInsuranceTypeMutationHookResult = ReturnType<typeof useCreateInsuranceTypeMutation>;
export type CreateInsuranceTypeMutationResult = ApolloReactCommon.MutationResult<CreateInsuranceTypeMutation>;
export type CreateInsuranceTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateInsuranceTypeMutation, CreateInsuranceTypeMutationVariables>;
export const GetInsuranceTypeDocument = gql`
    query getInsuranceType($ID: String) {
  getInsuranceType(ID: $ID) {
    ID
    name
    commonStatus
    modTs
  }
  insuranceTypeDate
}
    `;

/**
 * __useGetInsuranceTypeQuery__
 *
 * To run a query within a React component, call `useGetInsuranceTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInsuranceTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInsuranceTypeQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetInsuranceTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInsuranceTypeQuery, GetInsuranceTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInsuranceTypeQuery, GetInsuranceTypeQueryVariables>(GetInsuranceTypeDocument, baseOptions);
      }
export function useGetInsuranceTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInsuranceTypeQuery, GetInsuranceTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInsuranceTypeQuery, GetInsuranceTypeQueryVariables>(GetInsuranceTypeDocument, baseOptions);
        }
export type GetInsuranceTypeQueryHookResult = ReturnType<typeof useGetInsuranceTypeQuery>;
export type GetInsuranceTypeLazyQueryHookResult = ReturnType<typeof useGetInsuranceTypeLazyQuery>;
export type GetInsuranceTypeQueryResult = ApolloReactCommon.QueryResult<GetInsuranceTypeQuery, GetInsuranceTypeQueryVariables>;
export const DeleteInsuranceTypeDocument = gql`
    mutation deleteInsuranceType($ID: GuarInsTypeDeleteInput!) {
  deleteInsuranceType(input: $ID)
}
    `;
export type DeleteInsuranceTypeMutationFn = ApolloReactCommon.MutationFunction<DeleteInsuranceTypeMutation, DeleteInsuranceTypeMutationVariables>;

/**
 * __useDeleteInsuranceTypeMutation__
 *
 * To run a mutation, you first call `useDeleteInsuranceTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInsuranceTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInsuranceTypeMutation, { data, loading, error }] = useDeleteInsuranceTypeMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteInsuranceTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteInsuranceTypeMutation, DeleteInsuranceTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteInsuranceTypeMutation, DeleteInsuranceTypeMutationVariables>(DeleteInsuranceTypeDocument, baseOptions);
      }
export type DeleteInsuranceTypeMutationHookResult = ReturnType<typeof useDeleteInsuranceTypeMutation>;
export type DeleteInsuranceTypeMutationResult = ApolloReactCommon.MutationResult<DeleteInsuranceTypeMutation>;
export type DeleteInsuranceTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteInsuranceTypeMutation, DeleteInsuranceTypeMutationVariables>;
export const UserRoleIDsDocument = gql`
    query userRoleIDs($contractID: String!) {
  userRoleIDs(contractID: $contractID) {
    roleID
    userID
  }
}
    `;

/**
 * __useUserRoleIDsQuery__
 *
 * To run a query within a React component, call `useUserRoleIDsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRoleIDsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRoleIDsQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useUserRoleIDsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserRoleIDsQuery, UserRoleIDsQueryVariables>) {
        return ApolloReactHooks.useQuery<UserRoleIDsQuery, UserRoleIDsQueryVariables>(UserRoleIDsDocument, baseOptions);
      }
export function useUserRoleIDsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserRoleIDsQuery, UserRoleIDsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserRoleIDsQuery, UserRoleIDsQueryVariables>(UserRoleIDsDocument, baseOptions);
        }
export type UserRoleIDsQueryHookResult = ReturnType<typeof useUserRoleIDsQuery>;
export type UserRoleIDsLazyQueryHookResult = ReturnType<typeof useUserRoleIDsLazyQuery>;
export type UserRoleIDsQueryResult = ApolloReactCommon.QueryResult<UserRoleIDsQuery, UserRoleIDsQueryVariables>;
export const CreateEntityRoleUserDocument = gql`
    mutation createEntityRoleUser($input: EntityRoleUserAsgInput!) {
  CreateEntityRoleUser(input: $input)
}
    `;
export type CreateEntityRoleUserMutationFn = ApolloReactCommon.MutationFunction<CreateEntityRoleUserMutation, CreateEntityRoleUserMutationVariables>;

/**
 * __useCreateEntityRoleUserMutation__
 *
 * To run a mutation, you first call `useCreateEntityRoleUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEntityRoleUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEntityRoleUserMutation, { data, loading, error }] = useCreateEntityRoleUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEntityRoleUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEntityRoleUserMutation, CreateEntityRoleUserMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEntityRoleUserMutation, CreateEntityRoleUserMutationVariables>(CreateEntityRoleUserDocument, baseOptions);
      }
export type CreateEntityRoleUserMutationHookResult = ReturnType<typeof useCreateEntityRoleUserMutation>;
export type CreateEntityRoleUserMutationResult = ApolloReactCommon.MutationResult<CreateEntityRoleUserMutation>;
export type CreateEntityRoleUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateEntityRoleUserMutation, CreateEntityRoleUserMutationVariables>;
export const CreateListEntityRoleUserDocument = gql`
    mutation createListEntityRoleUser($contractID: String!, $roleUserIDs: [RoleUserInput!]!) {
  CreateListEntityRoleUser(contractID: $contractID, roleUserIDs: $roleUserIDs)
}
    `;
export type CreateListEntityRoleUserMutationFn = ApolloReactCommon.MutationFunction<CreateListEntityRoleUserMutation, CreateListEntityRoleUserMutationVariables>;

/**
 * __useCreateListEntityRoleUserMutation__
 *
 * To run a mutation, you first call `useCreateListEntityRoleUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateListEntityRoleUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createListEntityRoleUserMutation, { data, loading, error }] = useCreateListEntityRoleUserMutation({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      roleUserIDs: // value for 'roleUserIDs'
 *   },
 * });
 */
export function useCreateListEntityRoleUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateListEntityRoleUserMutation, CreateListEntityRoleUserMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateListEntityRoleUserMutation, CreateListEntityRoleUserMutationVariables>(CreateListEntityRoleUserDocument, baseOptions);
      }
export type CreateListEntityRoleUserMutationHookResult = ReturnType<typeof useCreateListEntityRoleUserMutation>;
export type CreateListEntityRoleUserMutationResult = ApolloReactCommon.MutationResult<CreateListEntityRoleUserMutation>;
export type CreateListEntityRoleUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateListEntityRoleUserMutation, CreateListEntityRoleUserMutationVariables>;
export const TestPermissionDocument = gql`
    query testPermission($conPerm: ContractPermission!) {
  testPermission(conPerm: $conPerm)
}
    `;

/**
 * __useTestPermissionQuery__
 *
 * To run a query within a React component, call `useTestPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestPermissionQuery({
 *   variables: {
 *      conPerm: // value for 'conPerm'
 *   },
 * });
 */
export function useTestPermissionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TestPermissionQuery, TestPermissionQueryVariables>) {
        return ApolloReactHooks.useQuery<TestPermissionQuery, TestPermissionQueryVariables>(TestPermissionDocument, baseOptions);
      }
export function useTestPermissionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TestPermissionQuery, TestPermissionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TestPermissionQuery, TestPermissionQueryVariables>(TestPermissionDocument, baseOptions);
        }
export type TestPermissionQueryHookResult = ReturnType<typeof useTestPermissionQuery>;
export type TestPermissionLazyQueryHookResult = ReturnType<typeof useTestPermissionLazyQuery>;
export type TestPermissionQueryResult = ApolloReactCommon.QueryResult<TestPermissionQuery, TestPermissionQueryVariables>;
export const CreateRolePermissionDocument = gql`
    mutation CreateRolePermission($input: RoleInput!, $permissionArr: [ContractPermission!]!) {
  CreateRolePermission(input: $input, permissionArr: $permissionArr)
}
    `;
export type CreateRolePermissionMutationFn = ApolloReactCommon.MutationFunction<CreateRolePermissionMutation, CreateRolePermissionMutationVariables>;

/**
 * __useCreateRolePermissionMutation__
 *
 * To run a mutation, you first call `useCreateRolePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRolePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRolePermissionMutation, { data, loading, error }] = useCreateRolePermissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      permissionArr: // value for 'permissionArr'
 *   },
 * });
 */
export function useCreateRolePermissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRolePermissionMutation, CreateRolePermissionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRolePermissionMutation, CreateRolePermissionMutationVariables>(CreateRolePermissionDocument, baseOptions);
      }
export type CreateRolePermissionMutationHookResult = ReturnType<typeof useCreateRolePermissionMutation>;
export type CreateRolePermissionMutationResult = ApolloReactCommon.MutationResult<CreateRolePermissionMutation>;
export type CreateRolePermissionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRolePermissionMutation, CreateRolePermissionMutationVariables>;
export const UpdateRolePermissionDocument = gql`
    mutation UpdateRolePermission($input: RoleInput!, $permissionArr: [ContractPermission!]!) {
  UpdateRolePermission(input: $input, permissionArr: $permissionArr)
}
    `;
export type UpdateRolePermissionMutationFn = ApolloReactCommon.MutationFunction<UpdateRolePermissionMutation, UpdateRolePermissionMutationVariables>;

/**
 * __useUpdateRolePermissionMutation__
 *
 * To run a mutation, you first call `useUpdateRolePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRolePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRolePermissionMutation, { data, loading, error }] = useUpdateRolePermissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      permissionArr: // value for 'permissionArr'
 *   },
 * });
 */
export function useUpdateRolePermissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRolePermissionMutation, UpdateRolePermissionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRolePermissionMutation, UpdateRolePermissionMutationVariables>(UpdateRolePermissionDocument, baseOptions);
      }
export type UpdateRolePermissionMutationHookResult = ReturnType<typeof useUpdateRolePermissionMutation>;
export type UpdateRolePermissionMutationResult = ApolloReactCommon.MutationResult<UpdateRolePermissionMutation>;
export type UpdateRolePermissionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateRolePermissionMutation, UpdateRolePermissionMutationVariables>;
export const GetRoleDocument = gql`
    query getRole($ID: String) {
  getRole(ID: $ID) {
    ID
    name
    rolePerm {
      permissionID
      roleID
      permName
    }
  }
  roleDate
}
    `;

/**
 * __useGetRoleQuery__
 *
 * To run a query within a React component, call `useGetRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetRoleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, baseOptions);
      }
export function useGetRoleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, baseOptions);
        }
export type GetRoleQueryHookResult = ReturnType<typeof useGetRoleQuery>;
export type GetRoleLazyQueryHookResult = ReturnType<typeof useGetRoleLazyQuery>;
export type GetRoleQueryResult = ApolloReactCommon.QueryResult<GetRoleQuery, GetRoleQueryVariables>;
export const GetContractListForProjectAndRoleAuthDocument = gql`
    query getContractListForProjectAndRoleAuth($ID: String) {
  getContract(ID: $ID) {
    ID
    title
    contractDate
  }
}
    `;

/**
 * __useGetContractListForProjectAndRoleAuthQuery__
 *
 * To run a query within a React component, call `useGetContractListForProjectAndRoleAuthQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractListForProjectAndRoleAuthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractListForProjectAndRoleAuthQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetContractListForProjectAndRoleAuthQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractListForProjectAndRoleAuthQuery, GetContractListForProjectAndRoleAuthQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractListForProjectAndRoleAuthQuery, GetContractListForProjectAndRoleAuthQueryVariables>(GetContractListForProjectAndRoleAuthDocument, baseOptions);
      }
export function useGetContractListForProjectAndRoleAuthLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractListForProjectAndRoleAuthQuery, GetContractListForProjectAndRoleAuthQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractListForProjectAndRoleAuthQuery, GetContractListForProjectAndRoleAuthQueryVariables>(GetContractListForProjectAndRoleAuthDocument, baseOptions);
        }
export type GetContractListForProjectAndRoleAuthQueryHookResult = ReturnType<typeof useGetContractListForProjectAndRoleAuthQuery>;
export type GetContractListForProjectAndRoleAuthLazyQueryHookResult = ReturnType<typeof useGetContractListForProjectAndRoleAuthLazyQuery>;
export type GetContractListForProjectAndRoleAuthQueryResult = ApolloReactCommon.QueryResult<GetContractListForProjectAndRoleAuthQuery, GetContractListForProjectAndRoleAuthQueryVariables>;
export const GetEntityRoleUserAssignmentDocument = gql`
    query getEntityRoleUserAssignment($ID: String, $entityID: String) {
  getEntityRoleUserAssignment(ID: $ID, entityID: $entityID) {
    userID
    roleID
  }
}
    `;

/**
 * __useGetEntityRoleUserAssignmentQuery__
 *
 * To run a query within a React component, call `useGetEntityRoleUserAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntityRoleUserAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntityRoleUserAssignmentQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      entityID: // value for 'entityID'
 *   },
 * });
 */
export function useGetEntityRoleUserAssignmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEntityRoleUserAssignmentQuery, GetEntityRoleUserAssignmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEntityRoleUserAssignmentQuery, GetEntityRoleUserAssignmentQueryVariables>(GetEntityRoleUserAssignmentDocument, baseOptions);
      }
export function useGetEntityRoleUserAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEntityRoleUserAssignmentQuery, GetEntityRoleUserAssignmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEntityRoleUserAssignmentQuery, GetEntityRoleUserAssignmentQueryVariables>(GetEntityRoleUserAssignmentDocument, baseOptions);
        }
export type GetEntityRoleUserAssignmentQueryHookResult = ReturnType<typeof useGetEntityRoleUserAssignmentQuery>;
export type GetEntityRoleUserAssignmentLazyQueryHookResult = ReturnType<typeof useGetEntityRoleUserAssignmentLazyQuery>;
export type GetEntityRoleUserAssignmentQueryResult = ApolloReactCommon.QueryResult<GetEntityRoleUserAssignmentQuery, GetEntityRoleUserAssignmentQueryVariables>;
export const DeleteRolePermissionDocument = gql`
    mutation DeleteRolePermission($roleID: String!) {
  DeleteRolePermission(roleID: $roleID)
}
    `;
export type DeleteRolePermissionMutationFn = ApolloReactCommon.MutationFunction<DeleteRolePermissionMutation, DeleteRolePermissionMutationVariables>;

/**
 * __useDeleteRolePermissionMutation__
 *
 * To run a mutation, you first call `useDeleteRolePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRolePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRolePermissionMutation, { data, loading, error }] = useDeleteRolePermissionMutation({
 *   variables: {
 *      roleID: // value for 'roleID'
 *   },
 * });
 */
export function useDeleteRolePermissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteRolePermissionMutation, DeleteRolePermissionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteRolePermissionMutation, DeleteRolePermissionMutationVariables>(DeleteRolePermissionDocument, baseOptions);
      }
export type DeleteRolePermissionMutationHookResult = ReturnType<typeof useDeleteRolePermissionMutation>;
export type DeleteRolePermissionMutationResult = ApolloReactCommon.MutationResult<DeleteRolePermissionMutation>;
export type DeleteRolePermissionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteRolePermissionMutation, DeleteRolePermissionMutationVariables>;
export const CreateUomDocument = gql`
    mutation createUOM($input: UOMInput!) {
  createUOM(input: $input) {
    name
    code
  }
}
    `;
export type CreateUomMutationFn = ApolloReactCommon.MutationFunction<CreateUomMutation, CreateUomMutationVariables>;

/**
 * __useCreateUomMutation__
 *
 * To run a mutation, you first call `useCreateUomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUomMutation, { data, loading, error }] = useCreateUomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUomMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUomMutation, CreateUomMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUomMutation, CreateUomMutationVariables>(CreateUomDocument, baseOptions);
      }
export type CreateUomMutationHookResult = ReturnType<typeof useCreateUomMutation>;
export type CreateUomMutationResult = ApolloReactCommon.MutationResult<CreateUomMutation>;
export type CreateUomMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUomMutation, CreateUomMutationVariables>;
export const UpdateUomDocument = gql`
    mutation updateUOM($input: UOMInput!) {
  updateUOM(input: $input) {
    ID
    name
    code
  }
}
    `;
export type UpdateUomMutationFn = ApolloReactCommon.MutationFunction<UpdateUomMutation, UpdateUomMutationVariables>;

/**
 * __useUpdateUomMutation__
 *
 * To run a mutation, you first call `useUpdateUomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUomMutation, { data, loading, error }] = useUpdateUomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUomMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUomMutation, UpdateUomMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUomMutation, UpdateUomMutationVariables>(UpdateUomDocument, baseOptions);
      }
export type UpdateUomMutationHookResult = ReturnType<typeof useUpdateUomMutation>;
export type UpdateUomMutationResult = ApolloReactCommon.MutationResult<UpdateUomMutation>;
export type UpdateUomMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUomMutation, UpdateUomMutationVariables>;
export const DeleteUomDocument = gql`
    mutation deleteUOM($ID: UOMDeleteInput!) {
  deleteUOM(input: $ID)
}
    `;
export type DeleteUomMutationFn = ApolloReactCommon.MutationFunction<DeleteUomMutation, DeleteUomMutationVariables>;

/**
 * __useDeleteUomMutation__
 *
 * To run a mutation, you first call `useDeleteUomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUomMutation, { data, loading, error }] = useDeleteUomMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteUomMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUomMutation, DeleteUomMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteUomMutation, DeleteUomMutationVariables>(DeleteUomDocument, baseOptions);
      }
export type DeleteUomMutationHookResult = ReturnType<typeof useDeleteUomMutation>;
export type DeleteUomMutationResult = ApolloReactCommon.MutationResult<DeleteUomMutation>;
export type DeleteUomMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUomMutation, DeleteUomMutationVariables>;
export const GetUserDocument = gql`
    query getUser($ID: String!) {
  getUser(ID: $ID) {
    ID
    name
    department
    email
    jobTitle
    contactNo
    superUser
    commonStatus
    userName
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
      }
export function useGetUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = ApolloReactCommon.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUsersByAccountAndSoftwareDocument = gql`
    query getUsersByAccountAndSoftware {
  getUsersByAccountAndSoftware {
    ID
    name
    department
    email
    contactNo
    superUser
    commonStatus
    jobTitle
    userName
  }
}
    `;

/**
 * __useGetUsersByAccountAndSoftwareQuery__
 *
 * To run a query within a React component, call `useGetUsersByAccountAndSoftwareQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByAccountAndSoftwareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByAccountAndSoftwareQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersByAccountAndSoftwareQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUsersByAccountAndSoftwareQuery, GetUsersByAccountAndSoftwareQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUsersByAccountAndSoftwareQuery, GetUsersByAccountAndSoftwareQueryVariables>(GetUsersByAccountAndSoftwareDocument, baseOptions);
      }
export function useGetUsersByAccountAndSoftwareLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUsersByAccountAndSoftwareQuery, GetUsersByAccountAndSoftwareQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUsersByAccountAndSoftwareQuery, GetUsersByAccountAndSoftwareQueryVariables>(GetUsersByAccountAndSoftwareDocument, baseOptions);
        }
export type GetUsersByAccountAndSoftwareQueryHookResult = ReturnType<typeof useGetUsersByAccountAndSoftwareQuery>;
export type GetUsersByAccountAndSoftwareLazyQueryHookResult = ReturnType<typeof useGetUsersByAccountAndSoftwareLazyQuery>;
export type GetUsersByAccountAndSoftwareQueryResult = ApolloReactCommon.QueryResult<GetUsersByAccountAndSoftwareQuery, GetUsersByAccountAndSoftwareQueryVariables>;
export const CreateUserDocument = gql`
    mutation createUser($input: UserInput!) {
  createUser(input: $input) {
    ID
    email
  }
}
    `;
export type CreateUserMutationFn = ApolloReactCommon.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, baseOptions);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = ApolloReactCommon.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($input: UserInput!) {
  updateUser(input: $input) {
    ID
  }
}
    `;
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const GetHomeSummaryDocument = gql`
    query getHomeSummary {
  activeContractCount
  activeClientCount
  activeSubcontractCount
  activeSubcontractorCount
  activeSupplierCount
  lastMiscExpDate
  lastPurchaseDate
  loggedInUserProfile {
    ID
    accountID
    name
    email
    contactNo
    department
    superUser
    jobTitle
  }
}
    `;

/**
 * __useGetHomeSummaryQuery__
 *
 * To run a query within a React component, call `useGetHomeSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomeSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomeSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHomeSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetHomeSummaryQuery, GetHomeSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetHomeSummaryQuery, GetHomeSummaryQueryVariables>(GetHomeSummaryDocument, baseOptions);
      }
export function useGetHomeSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetHomeSummaryQuery, GetHomeSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetHomeSummaryQuery, GetHomeSummaryQueryVariables>(GetHomeSummaryDocument, baseOptions);
        }
export type GetHomeSummaryQueryHookResult = ReturnType<typeof useGetHomeSummaryQuery>;
export type GetHomeSummaryLazyQueryHookResult = ReturnType<typeof useGetHomeSummaryLazyQuery>;
export type GetHomeSummaryQueryResult = ApolloReactCommon.QueryResult<GetHomeSummaryQuery, GetHomeSummaryQueryVariables>;
export const GetRolePermissionDocument = gql`
    query getRolePermission {
  getRolePermission
}
    `;

/**
 * __useGetRolePermissionQuery__
 *
 * To run a query within a React component, call `useGetRolePermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolePermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolePermissionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRolePermissionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRolePermissionQuery, GetRolePermissionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRolePermissionQuery, GetRolePermissionQueryVariables>(GetRolePermissionDocument, baseOptions);
      }
export function useGetRolePermissionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRolePermissionQuery, GetRolePermissionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRolePermissionQuery, GetRolePermissionQueryVariables>(GetRolePermissionDocument, baseOptions);
        }
export type GetRolePermissionQueryHookResult = ReturnType<typeof useGetRolePermissionQuery>;
export type GetRolePermissionLazyQueryHookResult = ReturnType<typeof useGetRolePermissionLazyQuery>;
export type GetRolePermissionQueryResult = ApolloReactCommon.QueryResult<GetRolePermissionQuery, GetRolePermissionQueryVariables>;
export const GetContractPermissionDocument = gql`
    query getContractPermission {
  getContractPermission
}
    `;

/**
 * __useGetContractPermissionQuery__
 *
 * To run a query within a React component, call `useGetContractPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractPermissionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContractPermissionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractPermissionQuery, GetContractPermissionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractPermissionQuery, GetContractPermissionQueryVariables>(GetContractPermissionDocument, baseOptions);
      }
export function useGetContractPermissionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractPermissionQuery, GetContractPermissionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractPermissionQuery, GetContractPermissionQueryVariables>(GetContractPermissionDocument, baseOptions);
        }
export type GetContractPermissionQueryHookResult = ReturnType<typeof useGetContractPermissionQuery>;
export type GetContractPermissionLazyQueryHookResult = ReturnType<typeof useGetContractPermissionLazyQuery>;
export type GetContractPermissionQueryResult = ApolloReactCommon.QueryResult<GetContractPermissionQuery, GetContractPermissionQueryVariables>;