import React, { lazy } from 'react';

const AccountingSubMenu = lazy(() =>
  import('./AccountingSubMenu').then(module => ({
    default: module.AccountingSubMenu,
  })),
);

const GeneralContractLedgerListing = lazy(() =>
  import('./ContractLedgerListing/GeneralContractLedgerListing').then(
    module => ({
      default: module.GeneralContractLedgerListing,
    }),
  ),
);

const ContractLedgerListingWithAlloc = lazy(() =>
  import('./ContractLedgerListing/ContractLedgerListingWithAlloc').then(
    module => ({
      default: module.ContractLedgerListingWithAlloc,
    }),
  ),
);

const ContractRefundListing = lazy(() =>
  import('./ContractLedgerListing/ContractRefundListing').then(module => ({
    default: module.ContractRefundListing,
  })),
);

const AccountingRoutes = [
  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/accounts/submenu',
    },
    component: <AccountingSubMenu />,
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/accounts/submenu/advances',
    },
    component: (
      <GeneralContractLedgerListing
        transactionType="advances"
        accountType="contract"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/accounts/submenu/debit-notes',
    },
    component: (
      <GeneralContractLedgerListing
        transactionType="debit-notes"
        accountType="contract"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/accounts/submenu/credit-notes',
    },
    component: (
      <ContractLedgerListingWithAlloc
        transactionType="credit-notes"
        accountType="contract"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/accounts/submenu/receipts',
    },
    component: (
      <ContractLedgerListingWithAlloc
        transactionType="receipts"
        accountType="contract"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/accounts/submenu/refunds',
    },
    component: <ContractRefundListing accountType="contract" />,
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/accounts/submenu/debit-notes-maincon',
    },
    component: (
      <GeneralContractLedgerListing
        transactionType="client-debit-notes"
        accountType="contract"
      />
    ),
  },
];

export default AccountingRoutes;
