import React, { lazy } from 'react';
import { userRoutes } from './UserModule/UserRoutes';
import { projectRoleAuthRoutes } from './ProjectRoleAuthModule/ProjectRoleAuthRoutes';
// import { rolesRoutes } from './RolesModule/RolesRoutes';

// Access Security //
// const AccessSecurityList = lazy(() =>
//   import('./AccessSecurityList').then(module => ({
//     default: module.AccessSecurityList,
//   })),
// );

export const accessSecurityRoutes = [
  // {
  //   props: { exact: true, path: '/:mode/general-settings/access-security' },
  //   component: <AccessSecurityList />,
  // },
  ...userRoutes,
  // ...rolesRoutes,
  ...projectRoleAuthRoutes,
];
