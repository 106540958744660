import React, { lazy } from 'react';

const ContractListing = lazy(() =>
  import('./ContractListing').then(module => ({
    default: module.ContractListing,
  })),
);

const ContractSubMenu = lazy(() =>
  import('./ContractSubMenu').then(module => ({
    default: module.ContractSubMenu,
  })),
);

const ProgressClaimListing = lazy(() =>
  import('./ProgressClaimModule/ProgressClaimListing').then(module => ({
    default: module.ProgressClaimListing,
  })),
);

const ProgressClaimDetail = lazy(() =>
  import('./ProgressClaimModule/ProgressClaimDetail').then(module => ({
    default: module.ProgressClaimDetail,
  })),
);

const VOListing = lazy(() =>
  import('./VO/VOListing').then(module => ({
    default: module.VOListing,
  })),
);

const VOForm = lazy(() =>
  import('./VO/VOForm').then(module => ({
    default: module.VOForm,
  })),
);

const VODetail = lazy(() =>
  import('./VO/VODetail').then(module => ({
    default: module.VODetail,
  })),
);

const VOProjectHealth = lazy(() =>
  import('./VO/VOProjectHealth').then(module => ({
    default: module.VOProjectHealth,
  })),
);

const SiteProgressListing = lazy(() =>
  import('./SiteProgress/SiteProgressListing').then(module => ({
    default: module.SiteProgressListing,
  })),
);

const SiteProgressForm = lazy(() =>
  import('./SiteProgress/SiteProgressForm').then(module => ({
    default: module.SiteProgressForm,
  })),
);

const ContractRoutes = [
  {
    props: { exact: true, path: '/home' },
    component: <ContractListing />,
  },
  {
    props: { exact: true, path: '/' },
    component: <ContractListing />,
  },
  {
    props: { exact: true, path: '/contract/submenu/:ID' },
    component: <ContractSubMenu />,
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/progress-claim',
    },
    component: <ProgressClaimListing />,
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/progress-claim/ClaimListDummy',
    },
    component: <ProgressClaimDetail />,
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/vo',
    },
    component: <VOListing consubcon="contract" />,
  },

  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/vo/add',
    },
    component: <VOForm consubcon="contract" mode="add" />,
  },

  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/vo/draft/:voId',
    },
    component: <VOForm consubcon="contract" mode="draft" />,
  },

  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/vo/resubmit/:voId',
    },
    component: <VOForm consubcon="contract" mode="resubmit" />,
  },

  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/vo/detail/:voId',
    },
    component: <VODetail consubcon="contract" />,
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/vo-project-health',
    },
    component: <VOProjectHealth />,
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/site-progress',
    },
    component: <SiteProgressListing />,
  },
  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/site-progress/add',
    },
    component: <SiteProgressForm mode="add" />,
  },

  {
    props: {
      exact: true,
      path: '/contract/submenu/:ID/site-progress/edit/:siteProgressID',
    },
    component: <SiteProgressForm mode="edit" />,
  },
];

export default ContractRoutes;
