import React, { lazy } from 'react';
import { accessSecurityRoutes } from './AccessSecurityModule/AccessSecurityRoutes';

//General Settings//
const GeneralSettingList = lazy(() =>
  import('./GeneralSettingList').then(module => ({
    default: module.GeneralSettingList,
  })),
);

const generalSettingRoutes = [
  {
    props: { exact: true, path: '/:mode/general-settings' },
    component: <GeneralSettingList />,
  },
  {
    props: { exact: true, path: '/general-settings' },
    component: <GeneralSettingList />,
  },
  ...accessSecurityRoutes,
];

export default generalSettingRoutes;
