export const SystemMsgs = {
  name: () => {
    return 'Name is required';
  },

  requestedBy: () => {
    return 'Requested by is required';
  },
  contactPerson: () => {
    return 'Contact Person is required';
  },
  contactNo: () => {
    return 'Contact No is required';
  },
  mobileNo: () => {
    return 'Mobile No is required';
  },
  email: () => {
    return 'Email is required';
  },
  password: () => {
    return 'Password is required';
  },
  supplierSelection: () => {
    return 'Select a Supplier';
  },
  contractSelection: () => {
    return 'Select a Contract';
  },
  subcontractSelection: () => {
    return 'Select a Subcontract';
  },
  specialInstruction: () => {
    return 'Special instruction is required';
  },
  outstandingQty: () => {
    return 'Qty exceeded outstanding Qty';
  },
  allocAmtExceeded: () => {
    return 'Allocated amount exceeded document amount';
  },
  remainderAmtExceeded: () => {
    return 'Exceed remainder amount';
  },
  amtExceed: () => {
    return 'Payment amount exceeded the outstanding amount';
  },
  approvedAmtExceed: () => {
    return 'Approved amount exceeded the submission amount';
  },
  approvedAmtNegative: () => {
    return 'Approved amount must be a positive value';
  },
  exceed: () => {
    return 'Exceeded';
  },
  item: () => {
    return 'Item is required';
  },
  doNo: () => {
    return 'DO No is required';
  },
  price: () => {
    return 'Price cannot be zero';
  },
  qty: () => {
    return 'Qty cannot be zero';
  },
  uom: () => {
    return 'UOM is required';
  },

  remarks: () => {
    return 'Remarks is required';
  },
  deliveryAddressSelection: () => {
    return 'Select delivery address';
  },
  createNewRecord: () => {
    return 'Record added successfully.';
  },
  updateRecord: () => {
    return 'Record updated successfully.';
  },
  cancelRecord: () => {
    return 'Record Cancelled successfully.';
  },
  deleteRecord: () => {
    return 'Record deleted successfully.';
  },
  approveRecord: () => {
    return 'Record approved successfully.';
  },
  rejectRecord: () => {
    return 'Record rejected successfully.';
  },
  activationEmail: () => {
    return 'An activation email has been sent to ';
  },
  guaranteeSelection: () => {
    return 'Select a guarantee type';
  },
  insuranceSelection: () => {
    return 'Select an insurance type';
  },
  issuer: () => {
    return 'Issuer is required';
  },
  dateSelection: () => {
    return 'Select a date';
  },
  docAmt: () => {
    return 'Amount is required';
  },
  docRef: () => {
    return 'Reference No is required';
  },
  VOSelection: () => {
    return 'Select a VO type';
  },
  architectNo: () => {
    return 'Architect Instruction No is required';
  },
  engineeringNo: () => {
    return 'Engineering Instruction No is required';
  },
  guaranteeType: () => {
    return 'Guarantee Name is required';
  },
  insuranceType: () => {
    return 'Insurance Name is required';
  },
  code: () => {
    return 'Code is required';
  },
  description: () => {
    return 'Description is required';
  },
  taxClass: () => {
    return 'Select a tax class';
  },
  taxCode: () => {
    return 'Select a tax code';
  },
  taxRate: () => {
    return 'Tax Rate is required';
  },
  taxType: () => {
    return 'Select a tax type';
  },
  taxRefNo: () => {
    return 'Tax Reference No. is required';
  },
  forgotPassword: () => {
    return 'Please check your inbox for an email we just sent you with instructions on how to reset your password and log into your account.';
  },
  accountHolder: () => {
    return 'Account Holder Name is required';
  },
  accountNo: () => {
    return 'Account No is required';
  },
  bankName: () => {
    return 'Bank Name is required';
  },
  branch: () => {
    return 'Bank Branch is required';
  },
  siteName: () => {
    return 'Site Name is required';
  },
  address: () => {
    return 'Address is required';
  },
  city: () => {
    return 'City is required';
  },
  state: () => {
    return 'State is required';
  },
  country: () => {
    return 'Country is required';
  },
  postCode: () => {
    return 'Postcode is required';
  },
  swiftCode: () => {
    return 'Bank Swift Code is required';
  },
  designation: () => {
    return 'Designation is required';
  },
  userName: () => {
    return 'Username is required';
  },
  department: () => {
    return 'Department is required';
  },
  regNo: () => {
    return 'Company Registration No. is required';
  },
  officeNo: () => {
    return 'Office No. is required';
  },
  currency: () => {
    return 'Currency is required';
  },

  cidbReg: () => {
    return 'CIDB Registration No. is required';
  },
  cidbGrade: () => {
    return 'CIDB Grade is required';
  },
  cidbCategory: () => {
    return 'CIDB Category is required';
  },
  cidbExpiryDate: () => {
    return 'CIDB Expired Date is required';
  },
  pkkRegNo: () => {
    return 'PKK Registration No. is required';
  },
  pkkClass: () => {
    return 'PKK Class is required';
  },
  pkkExpiryDate: () => {
    return 'PKK Expiration Date is required';
  },
  rating: () => {
    return 'Select a Rating';
  },
  creditTerm: () => {
    return 'Credit Term  is required';
  },
  client: () => {
    return 'Select a client';
  },
  maincontract: () => {
    return 'Select a main contract';
  },
  contractNo: () => {
    return 'Contract No. is required';
  },
  subcontractNo: () => {
    return 'Subcontract No. is required';
  },
  title: () => {
    return 'Title is required';
  },
  costClassification: () => {
    return 'Select a cost classification';
  },
  costCategory: () => {
    return 'Select a cost category';
  },
  progressRetentionPerc: () => {
    return 'Progress Retention is required';
  },
  maxRetentionPerc: () => {
    return 'Max Retention is required';
  },
  tradeSpec: () => {
    return 'Select a trade specification';
  },
  statusSelection: () => {
    return 'Select a status';
  },
};
