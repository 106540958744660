import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink, Observable } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { createUploadLink } from 'apollo-upload-client';
import jwtDecode from 'jwt-decode';
import { getAccessToken, setAccessToken } from './AccessToken';

const cache = new InMemoryCache({});

let contractNodeUrl;
console.log('PROCESS REACT_APP_LOCAL_DB:', process.env.REACT_APP_LOCAL_DB);
console.log('NODE_ENV:', process.env.NODE_ENV);

switch (process.env.REACT_APP_LOCAL_DB) {
  case 'local':
    contractNodeUrl = 'http://localhost:4000/contract-graphql';
    break;
  case 'report':
    contractNodeUrl = 'http://localhost:4000/contract-graphql';
    break;
  case 'azure':
    contractNodeUrl = 'https://contractx-dev-api.ifca.io/contract-graphql';
    break;
  case 'uat':
    contractNodeUrl = 'https://contractx-api.ifca.io/contract-graphql';
    break;
  default:
    contractNodeUrl = 'https://platform-api.contractx.asia/contract-graphql';
    break;
}

export const contractNodeRefreshUrl =
  process.env.REACT_APP_LOCAL_DB === 'report'
    ? 'http://localhost:4000/refresh_token_contract'
    : process.env.REACT_APP_LOCAL_DB === 'local'
    ? 'http://localhost:4000/refresh_token_contract'
    : process.env.REACT_APP_LOCAL_DB === 'azure'
    ? 'https://contractx-dev-api.ifca.io/refresh_token_contract'
    : process.env.REACT_APP_LOCAL_DB === 'uat'
    ? 'https://contractx-api.ifca.io/refresh_token_contract'
    : 'https://platform-api.contractx.asia/refresh_token_contract';

export const contractReportUrl =
  process.env.REACT_APP_LOCAL_DB === 'report'
    ? 'http://localhost:61094'
    : process.env.REACT_APP_LOCAL_DB === 'local'
    ? 'https://contractxdevreporting.ifca.asia'
    : process.env.REACT_APP_LOCAL_DB === 'azure'
    ? 'https://contractxdevreporting.ifca.asia'
    : process.env.REACT_APP_LOCAL_DB === 'uat'
    ? 'https://contractxuatreporting.ifca.io'
    : 'https://contractxreporting.contractx.asia';

//Getting access Token and passing it in request headers
const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle: any;
      Promise.resolve(operation)
        .then(operation => {
          const accessToken = getAccessToken();
          if (accessToken) {
            operation.setContext({
              headers: {
                authorization: `bearer ${accessToken}`,
              },
            });
          } //accessToken is defined
        }) //then operation ends here
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          }); //handle ends here
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    }),
);

const uploadLink = createUploadLink({
  uri: contractNodeUrl,
  credentials: 'include',
});

const httplink = new HttpLink({
  uri: contractNodeUrl,
  credentials: 'include',
}); //new HttpLink ends here

export const ContractClient = new ApolloClient({
  link: ApolloLink.from([
    new TokenRefreshLink({
      accessTokenField: 'accessToken',
      isTokenValidOrUndefined: () => {
        const token = getAccessToken();

        if (!token) {
          return true;
        }

        try {
          const { exp } = jwtDecode(token);
          if (Date.now() >= exp * 1000) {
            return false;
          } else {
            return true;
          }
        } catch (err) {
          console.log(err);
          return false;
        }
      },
      fetchAccessToken: () => {
        return fetch(contractNodeRefreshUrl, {
          method: 'POST',
          credentials: 'include',
        });
      },
      handleFetch: accessToken => {
        setAccessToken(accessToken);
      },
      handleResponse: () => {},
      handleError: err => {
        console.warn('Your refresh token is invalid. Try to relogin');
        console.error(err);
      },
    }),
    onError(() => {}),
    requestLink,
    uploadLink,
    //httplink,
    // new HttpLink({
    //   uri: contractNodeUrl,
    //   credentials: 'include',
    // }), //new HttpLink ends here
  ]),
  cache,
});

// export const Uploadclient = new ApolloClient({
//   cache,
//   link: ApolloLink.from([requestLink, uploadLink]),
// });
