import { ApolloProvider } from '@apollo/react-hooks';
import DateFnsUtils from '@date-io/date-fns';
import '@ifca-root/react-component/src/assets/styles/app.scss';
import theme from '@ifca-root/react-component/src/assets/theme';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers/';
import { setAccessToken } from 'AccessToken';
import 'assets/styles/contractx.scss';
import Layout from 'components/Layout/Layout';
import { ContractClient, contractNodeRefreshUrl } from 'ContractClient';
import { createBrowserHistory } from 'history';
import React, { Suspense, useEffect, useReducer, useState } from 'react';
import { Router } from 'react-router-dom';
import Routes from './Router/Routes';
import { ServiceWorkerWrapper } from './ServiceWorkerWrapper';
import AppContext from './Store/AppContext';
import { GlobalInitialState, RootReducer } from './Store/RootReducer';

export const history = createBrowserHistory();

const App = () => {
  const [globalState, dispatch] = useReducer(RootReducer, GlobalInitialState);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(contractNodeRefreshUrl, {
      method: 'POST',
      credentials: 'include',
    }).then(async x => {
      const { accessToken } = await x.json();
      setAccessToken(accessToken);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <AppContext.Provider value={{ globalState, dispatch }}>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={ContractClient}>
          {/* <ApolloProvider client={client}> */}
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Router history={history}>
              <Layout>
                <Suspense fallback={<Loading />}>
                  <Routes />
                  <ServiceWorkerWrapper />
                </Suspense>
              </Layout>
            </Router>
          </MuiPickersUtilsProvider>
        </ApolloProvider>
        {/* </ApolloProvider> */}
      </ThemeProvider>
    </AppContext.Provider>
  );
};
export default App;
