import { Button } from '@material-ui/core';
import Logo from 'assets/images/contractxWhite.png';
import Background from 'assets/images/HR-Background.jpg';
import { AuthLayout } from 'components/AuthLayout/AuthLayout';
import React from 'react';
import { useParams, useHistory, Redirect } from 'react-router';
import jwtDecode from 'jwt-decode';
import { useActivateUserMutation } from 'generated/graphql';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { Link } from 'react-router-dom';

export const ActivatedUser = () => {
  const { token }: any = useParams();
  // const jwtDecodeToken = jwtDecode(token);
  // const handleRedirectPath = jwtDecodeToken => {
  //   let path;
  //   switch (jwtDecodeToken?.softwareCode[0]) {
  //     case SoftwareCode.Contract:
  //       path = 'https://contractx-dev.ifca.io/login';
  //       break;
  //     case SoftwareCode.Hr:
  //       path = 'https://hrx-dev.ifca.io/login';
  //       break;
  //     case SoftwareCode.Property:
  //       path = 'https://propertyx-dev.ifca.io/login';
  //       break;
  //     case SoftwareCode.Hotel:
  //       path = 'https://hotelx-dev.ifca.io/login';
  //       break;
  //     case SoftwareCode.Rent:
  //       path = 'https://rentx-dev.ifca.io/login';
  //       break;
  //     case SoftwareCode.Account:
  //       path = 'https://accountx-dev.ifca.io/login';
  //       break;
  //     default:
  //       path = null;
  //       break;
  //   }
  //   return window.location.assign(path);
  // };
  let history = useHistory();
  const [
    ActivatedUser,
    { loading: mutationUpdateLoading, error: mutationUpdateError },
  ] = useActivateUserMutation({
    onError: error => {
      console.log('error', error);
    },
    onCompleted: data => {
      console.log('data', data);
      if (data.activateUser) {
        history.push(`https://contractx-dev.ifca.io/login`);
        // handleRedirectPath(jwtDecodeToken);
        // history.replace(`${handleRedirectPath(jwtDecodeToken)}`)
        // return <Redirect to={`${handleRedirectPath(jwtDecodeToken)}`} />
      }
    },
  });
  const activatedUser = () => {
    ActivatedUser({
      variables: {
        token: token,
      },
    });
  };

  return (
    <AuthLayout logo={Logo} image={Background}>
      {mutationUpdateLoading && <Loading />}
      <div className="form-box">
        <span className="page-title">Your account has been created</span>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="login-btn"
          onClick={activatedUser}
        >
          Proceed to login
        </Button>
      </div>
    </AuthLayout>
  );
};
