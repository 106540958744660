import { ContPermission } from 'generated/graphql';
import localForage from 'localforage';
import { useEffect, useState } from 'react';
/**
 * @param handlePermDisabled : {contractID: '' | contractID , permEnum: ContractPermission.HomeContractView}
 * @param handlePermHidden : {contractID: '' | contractID , permEnum: ContractPermission.HomeContractView}
 * @param permission : general permission list
 * @param contractPermission : contract permission list
 */
interface PermProps {
  contractID?: string;
  permEnum: ContPermission;
}
export const usePermissionChecker = () => {
  const [permission, setPermMatch] = useState({});
  const [contractPermission, setContractPerm] = useState({});
  const user = JSON.parse(localStorage.getItem('loggedInUser'));

  useEffect(() => {
    localForage?.getItem('permission', (err, value) => {
      setPermMatch(value);
      // console.log(value);
    });
  }, [setPermMatch]);
  useEffect(() => {
    localForage?.getItem('contractPermission', (err, value) => {
      setContractPerm(value);
      // console.log(value);
    });
  }, [setContractPerm]);

  // const handlePermDisabled = ({ contractID, permEnum }: PermProps) => {
  //   return !!contractID
  //     ? contractPermission[contractID]?.hasOwnProperty(permEnum) ||
  //         user?.superUser
  //     : permission?.hasOwnProperty(permEnum) || user?.superUser;
  // };
  const handlePermDisabled = ({ contractID, permEnum }: PermProps) => {
    // console.log('here', contractPermission);
    return !!contractID && !!contractPermission
      ? contractPermission[contractID]?.hasOwnProperty(permEnum) === false &&
          !user?.superUser
      : permission?.hasOwnProperty(permEnum) === false && !user?.superUser;
  };

  const handlePermHidden = ({ contractID, permEnum }: PermProps) => {
    return contractID
      ? contractPermission[contractID]?.hasOwnProperty(permEnum) ||
          user?.superUser
      : permission?.hasOwnProperty(permEnum) || user?.superUser;
  };
  return {
    permission,
    contractPermission,
    handlePermDisabled,
    handlePermHidden,
  };
};
